export const LOGIN_MUTATION = `
  mutation Login (
    $username: String!
    $password: String!
    $environment: String!
    $projectSlug: String
    $recaptcha: String
    $savePassword: Boolean!
  ) {
    login (
      username: $username
      password: $password
      environment: $environment
      projectSlug: $projectSlug
      recaptcha: $recaptcha
      savePassword: $savePassword
    )
  }
`;

export default {};
