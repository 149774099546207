import { TextField } from '@material-ui/core'
import React from 'react'
import validateCPF from 'utils/validateCpf'

export default function CPFTextField(props) {
    const handleValue = () => {
        if (!props.value) return ""

        return String(props.value)
            .replace(/([0-9]{0,3})([0-9]{0,3})([0-9]{0,3})([0-9]{0,2})/, '$1.$2.$3-$4')
            .replace(/[^0-9]*$/, '')
    }

    const handleChange = (e) => {
        const value = e.target.value
        const rawValue = value.replace(/[^0-9]/g, '').slice(0, 11)
        if (props.onChange) props.onChange(e, rawValue)
    }

    const handleError = () => {
        if (props.error) return true
        if (!props.value) return false

        return !validateCPF(props.value)
    }

    return <TextField {...props}
        value={handleValue()}
        onChange={handleChange}
        error={handleError()}
    />
}