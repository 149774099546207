import axios from 'axios';
import { LOGIN_MUTATION } from 'queries/loginQuery';

const GraphqlApi = (serverUrl) => {
  const GRAPHQL_API_ENDPOINT = serverUrl.appendPath('/graphql').toString();

  const axiosCall = (query, variables, headers) =>
    axios
      .post(
        GRAPHQL_API_ENDPOINT,
        { query, variables },
        {
          withCredentials: true,
          headers: { ...headers },
        }
      )
      .then((response) => response.data)
      .then(({ data, errors }) => {
        if (Array.isArray(errors) && errors.length > 0) {
          throw new Error(errors[0].message);
        }
        return data;
      });

  return {
    call(query, variables = {}, headers = {}, redirect = true) {
      return axiosCall(query, variables, headers).catch((err) => {
        if (redirect && err.message === 'NOT_AUTHENTICATED') {
          // eslint-disable-next-line
          location.href = '/login';
        }
        throw err;
      });
    },

    login(variables) {
      return axiosCall(LOGIN_MUTATION, variables)
        .then(() => true)
        .catch((err) => {
          throw err;
        });
    },
  };
};
export default GraphqlApi;
