import { Box, Button, Grid, TextField } from '@material-ui/core';
import PasswordTextField from 'components/TextFields/PasswordTextField';
import acceptSalesInviteQuery from 'queries/acceptSalesInviteQuery';
import React, { useState } from 'react';
import { BsArrowRight } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import api from 'services/api';
import Swal from 'sweetalert2';
import useThemeClasses from 'theme/useThemeClasses';
import handlerError from 'utils/handleError';
import PropTypes from 'prop-types';
import validateInputs from './helpers/validate-inputs';

export default function SalesUserForm({ inviteId, email, onDecline }) {
  const history = useHistory();

  const themeClasses = useThemeClasses;
  const buttonClasses = themeClasses.button();

  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    const validate = validateInputs({ password, password2 });

    if (!validate.success) {
      Swal.fire('', validate.message, 'error');
      return;
    }

    const variables = {
      id: inviteId,
      password,
    };

    Swal.fire({
      text: 'Aguarde...',
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
    });

    api
      .call(acceptSalesInviteQuery, variables)
      .then(({ acceptSalesInvite: result }) => {
        if (!result) throw new Error('FAILED');

        Swal.fire('', 'Convite aceito com sucesso!', 'success');
        localStorage.removeItem('authToken');
        history.push('/');
      })
      .catch((error) => {
        handlerError(
          {
            INVALID_INVITATION: () => {
              Swal.fire('', 'Convite inválido.', 'error');
            },
            EMAIL_ALREADY_VERIFIED: () => {
              Swal.fire(
                '',
                'Esse e-mail já tem acesso ao empreendimento.',
                'error'
              );
            },
            DEFAULT: () => {
              Swal.fire('', 'Não foi possível aceitar o convite.', 'error');
            },
          },
          error.message
        );
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            autoComplete="email"
            type="email"
            label="E-mail"
            value={email}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <PasswordTextField
            label="Senha"
            name="if-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Confirme a senha"
            name="if-password2"
            type="password"
            value={password2}
            onChange={(e) => setPassword2(e.target.value)}
            error={password2 && password2 !== password}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Box mt={2.5} display="flex" justifyContent="flex-end">
            <Button
              name="if-reject"
              className={buttonClasses.linkButton}
              onClick={() => onDecline()}
              color="secondary"
            >
              Recusar
            </Button>

            <Button
              className={buttonClasses.buttonWithIcon}
              endIcon={<BsArrowRight />}
              variant="contained"
              name="if-submit"
              color="primary"
              type="submit"
              style={{ width: 260 }}
            >
              Aceitar
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
}

SalesUserForm.propTypes = {
  inviteId: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  onDecline: PropTypes.func.isRequired,
};
