import { Dialog, Menu, useMediaQuery, useTheme } from '@material-ui/core'
import useStyles from './styles'
import Content from './Content'

export default function UserProfile({ access, projectTitle, open, anchor, onClose }) {
  const theme = useTheme()
  const classes = useStyles()
  const isMobileDevice = useMediaQuery(theme.breakpoints.down('sm'))

  if (anchor && !isMobileDevice) {
    return (
      <Menu className={classes.userModal}
        keepMounted
        anchorEl={anchor}
        PopoverClasses={{ paper: classes.userProfilePaper }}
        open={open}
        onClose={onClose}
      >
        <Content access={access} projectTitle={projectTitle} onClose={onClose} />
      </Menu >
    )
  }

  return (
    <Dialog
      className={classes.userModal}
      open={open}
      onClose={onClose}
      PaperProps={{ className: classes.userProfileMobilePaper }}
      fullScreen
    >
      <Content access={access} projectTitle={projectTitle} onClose={onClose} />
    </Dialog>
  )
}
