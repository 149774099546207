const { makeStyles } = require('@material-ui/core');

const useStyles = makeStyles((theme) => ({
  header: {
    borderBottom: '2px solid rgba(134, 139, 167, 0.1)',
  },

  links: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 16,
    paddingRight: 16,
  },

  link: {
    color: theme.palette.text.primary,
    justifyContent: 'center',
    paddingTop: 0,
    paddingBottom: 0,
    marginTop: 16,

    '& .MuiListItemIcon-root': {
      minWidth: 40,
      color: 'inherit',

      '& svg': {
        height: 24,
        width: 24,
      },
    },

    '& .MuiTypography-root': {
      fontSize: 14,
    },

    '&:hover': {
      color: theme.palette.secondary.dark,
      background: 'none',
    },

    '&.active': {
      color: theme.palette.primary.main,
    },
  },

  section: {
    color: theme.palette.text.primary,
    justifyContent: 'center',
    paddingTop: 0,
    paddingBottom: 0,
    marginTop: 16,
    cursor: 'default',

    '& .MuiListItemIcon-root': {
      minWidth: 40,
      color: 'inherit',

      '& svg': {
        height: 24,
        width: 24,
      },
    },

    '& .MuiTypography-root': {
      fontSize: 14,
    },
  },

  sectionLink: {
    color: theme.palette.grey[300],
    fontWeight: 500,
    justifyContent: 'center',
    paddingTop: 0,
    paddingBottom: 0,

    '& .MuiListItemIcon-root': {
      minWidth: 24,
      height: 35,
      borderLeft: `2px solid ${theme.palette.grey[300]}`,
      marginLeft: 10,
    },

    '& .MuiTypography-root': {
      fontSize: 14,
    },

    '&:hover': {
      color: theme.palette.secondary.dark,
      background: 'none',
    },

    '&.active': {
      color: theme.palette.primary.main,

      '& .MuiListItemIcon-root': {
        borderColor: theme.palette.primary.main,
      },
    },
  },

  projectTitle: {
    fontSize: 12,
    letterSpacing: 1,
    color: theme.palette.text.secondary,
  },

  profileButton: {
    color: theme.palette.text.primary,

    '&:hover': {
      color: theme.palette.secondary.dark,
    },

    '& .MuiTypography-root': {
      marginLeft: 8,
      fontSize: 12,
    },
  },
}));

export default useStyles;
