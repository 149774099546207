import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { BsArrowRight } from 'react-icons/bs';
import { useHistory, Link } from 'react-router-dom';

import Captcha from 'components/Captcha';
import resendVerificationCodeQuery from 'queries/resendVerificationCodeQuery';
import api from 'services/api';
import useThemeClasses from 'theme/useThemeClasses';
import checkLoginAttemptsExceededQuery from 'queries/checkLoginAttemptsExceededQuery';
import handlerError from 'utils/handleError';
import Alert from 'modules/AlertModule';
import Links from './Links';

function LoginForm({ environment }) {
  const history = useHistory();
  const themeClasses = useThemeClasses;
  const linkClasses = themeClasses.link();
  const buttonClasses = themeClasses.button();
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [resetCaptcha, setResetCaptcha] = useState(false);

  const [login, setLogin] = useState({
    username: '',
    password: '',
    environment,
    projectSlug: '',
    recaptcha: '',
    savePassword: false,
  });

  function checkLoginAttemptsExceeded() {
    return api
      .call(checkLoginAttemptsExceededQuery)
      .then((data) => {
        setShowCaptcha(data.checkLoginAttemptsExceeded);
      })
      .catch(() => {
        setShowCaptcha(true);
      });
  }

  const verifyEmail = () => {
    Alert.confirmation(
      'Você precisa verificar sua conta para realizar a autenticação. Deseja receber um novo e-mail de verificação?'
    ).then((result) => {
      if (result.isConfirmed) {
        Alert.loading('Aguarde enquanto enviamos o e-mail de verificação...');

        const params = {
          username: login.username,
          environment: login.environment,
          projectSlug: login.projectSlug,
        };

        api
          .call(resendVerificationCodeQuery(params))
          .then(
            Alert.onSuccess('O e-mail de verificação foi enviado com sucesso!')
          )
          .catch(
            Alert.onError('Não foi possível enviar o código de verificação.')
          );
      }
    });
  };

  const handleLoginSubmit = () => {
    api
      .login(login)
      .then(() => {
        api.resetSocket();
        history.push('/');
      })
      .catch((err) => {
        checkLoginAttemptsExceeded();
        setResetCaptcha(true);

        handlerError(
          {
            INVALID_LOGIN: Alert.onError('Email ou senha incorretos.'),
            UNVERIFIED_EMAIL: verifyEmail,
            INVALID_RECAPTCHA: Alert.onError(
              'É obrigatório resolver o CAPTCHA.'
            ),
            INVALID_PROJECT: Alert.onError('Empreendimento inválido.'),
            DEFAULT: Alert.onError('Não foi possível realizar o login.'),
          },
          err.message
        );
      });
  };

  function handleRecoverPassword() {
    if (login.environment === 'sales') {
      history.push('/recover', {
        environment: 'sales',
        username: login.username,
        projectSlug: login.projectSlug,
      });
    } else {
      history.push('/recover', { username: login.username });
    }
  }

  useEffect(() => {
    if (login.environment !== environment) {
      setLogin({ ...login, environment });
    }

    // eslint-disable-next-line
  }, [login, environment]);

  useEffect(() => {
    if (resetCaptcha) {
      setLogin({ ...login, recaptcha: '' });
      setResetCaptcha(false);
    }

    // eslint-disable-next-line
  }, [resetCaptcha]);

  useEffect(() => {
    checkLoginAttemptsExceeded();
  }, []);

  return (
    <Box>
      <Box mb={3}>
        <Link className={linkClasses.loginLink} to="/login">
          VOLTAR
        </Link>
      </Box>

      <Box mb={5}>
        <Typography variant="h4" color="textSecondary">
          {'Logue como '}
          {login.environment === 'administration' ? 'Coordenação' : ''}
          {login.environment === 'sales' ? 'Corretor ou Imobiliária' : ''}
        </Typography>
      </Box>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleLoginSubmit();
        }}
      >
        <Box mb={3}>
          <TextField
            autoFocus
            name="lf-username"
            label="Email"
            type="email"
            value={login.username}
            onChange={(e) => setLogin({ ...login, username: e.target.value })}
            autoComplete="username"
            required
            fullWidth
          />
        </Box>

        <Box mb={3}>
          <TextField
            name="lf-password"
            label="Senha"
            type="password"
            value={login.password}
            onChange={(e) => setLogin({ ...login, password: e.target.value })}
            autoComplete="password"
            required
            fullWidth
          />
        </Box>

        {login.environment === 'sales' ? (
          <Box mb={3}>
            <TextField
              name="lf-project"
              label="Empreendimento"
              type="text"
              value={login.projectSlug}
              onChange={(e) =>
                setLogin({
                  ...login,
                  projectSlug: e.target.value.replace(' ', '-'),
                })
              }
              autoComplete="projectSlug"
              required
              fullWidth
            />
          </Box>
        ) : null}

        <Box display="flex" justifyContent="space-between" alignItems="center">
          <FormControlLabel
            control={
              <Checkbox
                checked={login.savePassword}
                color="primary"
                onChange={(e) =>
                  setLogin({ ...login, savePassword: e.target.checked })
                }
              />
            }
            label="Salvar senha"
          />

          <Link
            className={linkClasses.loginLink}
            href="/recover"
            onClick={(e) => {
              e.preventDefault();
              handleRecoverPassword();
            }}
          >
            Esqueci minha senha
          </Link>
        </Box>

        {showCaptcha && !resetCaptcha && (
          <Captcha
            onChange={(value) => setLogin({ ...login, recaptcha: value })}
          />
        )}

        <Box mt={3} maxWidth={260}>
          <Button
            className={buttonClasses.buttonWithIcon}
            endIcon={<BsArrowRight />}
            name="lf-submit"
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            fullWidth
          >
            Entrar
          </Button>
        </Box>

        <Links mt={10} padding={1} />
      </form>
    </Box>
  );
}

LoginForm.propTypes = {
  environment: PropTypes.string.isRequired,
};

export default LoginForm;
