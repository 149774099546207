/* eslint-disable react-hooks/exhaustive-deps */
import { useCustomersListContext } from 'providers/ListProviders/CustomersListProvider';
import React from 'react';
import BaseList from '../BaseList';

import useColumns from './hooks/useColumns';

function CustomersList({ onRowClick }) {
  const { variables, pages, customers, setPage } = useCustomersListContext();
  const columns = useColumns();

  return (
    <BaseList
      columns={columns}
      data={customers}
      currentPage={variables.page}
      pages={pages}
      onRowClick={onRowClick}
      onPagination={setPage}
      changedAt={variables.changedAt}
    />
  );
}

export default CustomersList;
