import { ButtonBase, makeStyles, Tooltip } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  exportButton: {
    height: 30,
    width: 30,
    color: theme.palette.secondary.main,
  },
}));

export default function ExportListButton({ onClick }) {
  const classes = useStyles();

  return (
    <Tooltip title="Exportar">
      <ButtonBase className={classes.exportButton} onClick={onClick}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="16"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 17 16"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M8.31 11.333V2M14.31 11.333A2.667 2.667 0 0111.643 14H4.976a2.667 2.667 0 01-2.666-2.667"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M11.643 8l-3.334 3.334L4.976 8"
          />
        </svg>
      </ButtonBase>
    </Tooltip>
  );
}

ExportListButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};
