/* eslint-disable import/no-anonymous-default-export */
import { makeStyles } from "@material-ui/core/styles";

const desktopHeight = 'calc(100vh - 156px)'
const mobileHeight = 'calc(100vh - 106px)'
export const useStyles = makeStyles(theme => ({
  root: {
    '& > .MuiGrid-container': {
      height: desktopHeight
    },

    '& .dialog': {
      borderRight: '2px solid rgba(134, 139, 167, 0.1)',
      overflowY: 'auto',

      '&.open': {
        height: desktopHeight,
        [theme.breakpoints.down('xs')]: {
          height: 'auto',
          maxHeight: '100%',
          border: '0',
          borderBottom: '2px solid rgba(134, 139, 167, 0.1)',
          position: 'absolute',
          inset: 0,
          zIndex: 2,
          background: '#fff'
        }
      }
    },
    '& .map': {
      height: desktopHeight,
      [theme.breakpoints.down('sm')]: {
        height: mobileHeight
      }
    },
    '& .overlay': {
      position: "absolute",
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)'
    }
  }
}))
