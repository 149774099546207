import { Box, Tooltip, Typography, useTheme } from '@material-ui/core';
import React from 'react';
import formatPercent from 'utils/formatPercent';
import formatPrice from 'utils/formatPrice';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';
import ICON_TYPES from '@constants/iconsPaths';

function TooltipInvoiceValues({ salesContractInvoice }) {
  const theme = useTheme();

  const innerComponent = (
    <Box>
      <Typography component="p" variant="caption">
        Valor: {formatPrice(salesContractInvoice.value)}
      </Typography>
      <Typography component="p" variant="caption">
        Valor pago: {formatPrice(salesContractInvoice.paidValue)}
      </Typography>
      <Typography component="p" variant="caption">
        Juros: {formatPrice(salesContractInvoice.fees)}
      </Typography>
      <Typography component="p" variant="caption">
        Desconto: {formatPercent(salesContractInvoice.discount)}
      </Typography>
    </Box>
  );

  return (
    <Tooltip
      title={innerComponent}
      TransitionProps={{ style: { backgroundColor: theme.palette.grey[200] } }}
    >
      <Box
        display="inline-flex"
        alignItems="center"
        ml={1}
        style={{ transform: 'translateY(2px) scale(1.25)' }}
      >
        <Icon src={ICON_TYPES.LIST.info} size={18} />
      </Box>
    </Tooltip>
  );
}

TooltipInvoiceValues.propTypes = {
  salesContractInvoice: PropTypes.shape({
    value: PropTypes.number,
    discount: PropTypes.number,
    paidValue: PropTypes.number,
    fees: PropTypes.number,
  }).isRequired,
};

export default TooltipInvoiceValues;
