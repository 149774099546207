import validateDueDate from './validate-due-date';

const validateFields = (invoice) => {
  const validateValueField = [
    (value) => (!value && value !== 0 ? 'REQUIRED' : null),
    (value) => (value === 0 ? 'INVALID' : null),
  ];

  const validateDiscountField = [
    (discount) => (discount && Number(discount) < 0 ? 'INVALID' : null),
  ];

  const validateDueDateField = [
    (dueDate) => (!dueDate ? 'REQUIRED' : null),
    (dueDate) => (!validateDueDate(dueDate) ? 'INVALID' : null),
  ];

  const validateDescriptionField = [
    (description) => (!description ? 'REQUIRED' : null),
  ];

  const valueErr = validateValueField.reduce(
    (prev, fn) => prev || fn(invoice.value),
    null
  );

  const discountErr = validateDiscountField.reduce(
    (prev, fn) => prev || fn(invoice.discount),
    null
  );

  const dueDateErr = validateDueDateField.reduce(
    (prev, fn) => prev || fn(invoice.dueDate),
    null
  );

  const descriptionErr = validateDescriptionField.reduce(
    (prev, fn) => prev || fn(invoice.description),
    null
  );

  const errors = {
    valueErr,
    discountErr,
    dueDateErr,
    descriptionErr,
  };

  const any = Object.values(errors).reduce(
    (prev, value) => prev || value,
    null
  );

  return {
    ...errors,
    any,
  };
};

export default validateFields;
