import { makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
  mainGrid: {
    minHeight: '100vh'
  },

  banner: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(3),

    '& .brand': {
      width: 212,
    },

    '& > .wrapper': {
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: theme.spacing(5),
    },

    [theme.breakpoints.down('md')]: {
      '& h2': {
        fontSize: '20px',
        lineHeight: '26px'
      },

      '& h4': {
        fontSize: '16px',
        lineHeight: '20px'
      },

      '& h5': {
        fontSize: '14px',
        lineHeight: '18px'
      },
    },

    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(10)}px ${theme.spacing(10)}px`
    },

    [theme.breakpoints.up('lg')]: {
      padding: `${theme.spacing(20)}px ${theme.spacing(10)}px`
    }
  },

  content: {
    height: '100%',
    padding: theme.spacing(3),

    '& .actions': {
      marginBottom: theme.spacing(3),
      minHeight: 33,
    },

    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(10)}px ${theme.spacing(10)}px`
    },

    [theme.breakpoints.up('lg')]: {
      padding: `${theme.spacing(20)}px ${theme.spacing(10)}px`
    }
  },
}))
