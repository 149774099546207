import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import PercentField from 'components/Fields/PercentField';

export default function Discount({ configuration, setDiscount }) {
  const { discount } = configuration;
  return (
    <Grid>
      <Typography className="fw700" variant="h5">
        Descontos por forma de pagamento
      </Typography>

      <Grid item lg={6}>
        <Box my={2}>
          <PercentField
            label="À vista"
            value={discount.cash * 100}
            onChange={setDiscount('cash')}
            fullWidth
          />
          <Box my={2}>
            <PercentField
              label="Mensal"
              value={discount.monthly * 100}
              onChange={setDiscount('monthly')}
              fullWidth
            />
          </Box>
          <Box my={2}>
            <PercentField
              label="Anual"
              value={discount.annually * 100}
              onChange={setDiscount('annually')}
              fullWidth
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

Discount.propTypes = {
  setDiscount: PropTypes.func.isRequired,
  configuration: PropTypes.shape({
    discount: PropTypes.shape({
      cash: PropTypes.number,
      monthly: PropTypes.number,
      annually: PropTypes.number,
    }).isRequired,
  }).isRequired,
};
