import api from 'services/api';
import AlertModule from 'modules/AlertModule';
import { useCallback } from 'react';
import handlerError from 'utils/handleError';
import { useHistory, useParams } from 'react-router-dom';

export default function usePrintInvoices(projectId, contractNumberId) {
  const history = useHistory();
  const { projectSlug } = useParams();

  const pendingInvoicesMessage = (count) =>
    count === 1
      ? 'Esse boleto não foi emitido. Nosso sistema irá emitir pra você.'
      : 'Alguns boleto não foram emitido. Nosso sistema irá emitir pra você.';

  const downloadErrorMessage = (count) =>
    count === 1
      ? 'Não foi possível baixar o boleto.'
      : 'Não foi possível baixar os boletos.';

  const execute = useCallback(
    (invoicesIds) => {
      api
        .downloadTickets(projectId, contractNumberId, invoicesIds)
        .catch((err) => {
          console.log(err);

          handlerError(
            {
              MISSING_BANK_ACCOUNT: () => {
                AlertModule.error(
                  'Você precisa adicionar uma conta bancária para imprimir boletos.',
                  {
                    confirmButtonText: 'Ir para o financeiro',
                    cancelButtonText: 'Fechar',
                    reverseButtons: true,
                  }
                ).then(({ isConfirmed }) => {
                  if (isConfirmed) history.push(`/${projectSlug}/financeiro`);
                });
              },
              NOT_EMITTED_INVOICES: () => {
                AlertModule.info(pendingInvoicesMessage(invoicesIds.length))
                  .then(() =>
                    api.downloadTickets(
                      projectId,
                      contractNumberId,
                      invoicesIds
                    )
                  )
                  .catch(() => {
                    AlertModule.error(downloadErrorMessage(invoicesIds.length));
                  });
              },
              DEFAULT: AlertModule.onError(
                downloadErrorMessage(invoicesIds.length)
              ),
            },
            err.message
          );
        });
    },
    [projectId, contractNumberId, history, projectSlug]
  );

  return execute;
}
