export default `
  query GetSalesContractsQuery (
      $projectId: ID!
      $page: Int
      $itemsPerPage: Int
      $search: String
      $filterOptions: SalesContractFilterInput
      $sortOptions:SalesContractSortInput
      ) {
      getSalesContracts(
          projectId: $projectId
          page: $page
          itemsPerPage: $itemsPerPage
          search: $search
          filterOptions:$filterOptions
          sortOptions:$sortOptions
      ) {
          items {
              _id
              numberId
              reservationId
              property {
                  _id
                  unity
              }
              customer {
                  _id
                  name
              }
              status
              price
              entrance
              paymentMethod
              contractTemplateId
              createdAt
              firstMonthlyInstallmentDate
          }
          numberOfPages
          page
      }
  }
`;
