const CHANGE_PROPERTIES_STATUS_MUTATION = `
  mutation ChangePropertiesStatus (
    $projectId: ID!
    $propertiesIds: [ID]!
    $status: String!
  ) {
    changePropertiesStatus (
      projectId: $projectId
      propertiesIds: $propertiesIds
      status: $status
    )
  }
`;

export default CHANGE_PROPERTIES_STATUS_MUTATION;
