import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Box,
  Button,
  Paper,
  Popper,
  useTheme,
  Grid,
  Typography,
  Backdrop,
  Portal,
} from '@material-ui/core';
import useThemeClasses from 'theme/useThemeClasses';
import PropTypes from 'prop-types';
import Calendar from './Calendar';
import generatePredefinedDates from './helper/generate-predefined-dates';
import { useStyles } from './styles';
import MonthSelect from './MonthSelect';
import YearSelect from './YearSelect';

function RangeDateFilter({ title, open, anchorRef, onClose, onApply }) {
  const classes = useStyles();
  const theme = useTheme();
  const themeClasses = useThemeClasses;
  const buttonClasses = themeClasses.button();

  const startDateRef = useRef(null);
  const [startDate, setStartDate] = useState({
    day: 1,
    month: 'none',
    year: 'none',
    date: null,
  });

  const endDateRef = useRef(null);
  const [endDate, setEndDate] = useState({
    day: 1,
    month: 'none',
    year: 'none',
    date: null,
  });

  const [currentDate, setCurrentDate] = useState(null);

  const handleCalendarChange = (dates) => {
    setStartDate({
      day: dates.startDate?.getDate() || 1,
      month: !Number.isNaN(dates.startDate?.getMonth())
        ? dates.startDate?.getMonth()
        : 'none',
      year: dates.startDate?.getFullYear() || 'none',
      date: dates.startDate,
    });

    setEndDate({
      day: dates.endDate?.getDate() || 1,
      month: !Number.isNaN(dates.endDate?.getMonth())
        ? dates.endDate?.getMonth()
        : 'none',
      year: dates.endDate?.getFullYear() || 'none',
      date: dates.endDate,
    });
  };

  const handlePredefinedDate = (date) => {
    const startMonth = !Number.isNaN(date.startDate?.getMonth())
      ? date.startDate?.getMonth()
      : 'none';

    setStartDate({
      day: date.startDate?.getDate() || 1,
      month: startMonth,
      year: date.startDate?.getFullYear() || 'none',
      date: date.startDate,
    });

    const endMonth = !Number.isNaN(date.endDate?.getMonth())
      ? date.endDate?.getMonth()
      : 'none';

    setEndDate({
      day: date.endDate?.getDate() || 1,
      month: endMonth,
      year: date.endDate?.getFullYear() || 'none',
      date: date.endDate,
    });
  };

  const predefinedDates = useMemo(
    () =>
      generatePredefinedDates().map((date) => {
        let className = classes.predefinedDateButton;

        if (date.isSelected(startDate.date, endDate.date)) {
          className += ' selected';
        }

        return {
          className,
          date,
        };
      }),
    [classes, startDate, endDate]
  );

  const handleReset = () => {
    setStartDate({
      day: 1,
      month: 'none',
      year: 'none',
      date: null,
    });

    setEndDate({
      day: 1,
      month: 'none',
      year: 'none',
      date: null,
    });
  };

  const handleApply = () => {
    if (onApply) {
      onApply({
        startDate: startDate.date,
        endDate: endDate.date,
      });
    }

    onClose();
  };

  useEffect(() => {
    let date = new Date(
      startDate.year,
      startDate.month,
      startDate.day,
      0,
      0,
      0,
      0
    );

    if (Number.isNaN(date.getTime())) date = null;

    if (startDateRef.current?.getTime() !== date?.getTime()) {
      startDateRef.current = date;

      setStartDate({
        ...startDate,
        date,
      });

      if (date) {
        setCurrentDate(date);
      } else if (endDateRef.current) {
        date = new Date(endDateRef.current.getTime());
        date.setDate(1);
        setCurrentDate(date);
      } else {
        date = new Date();
        date.setDate(1);
        setCurrentDate(date);
      }
    }
  }, [startDate]);

  useEffect(() => {
    let date = new Date(endDate.year, endDate.month, endDate.day, 0, 0, 0, 0);
    if (Number.isNaN(date.getTime())) date = null;

    if (endDateRef.current?.getTime() !== date?.getTime()) {
      endDateRef.current = date;

      setEndDate({
        ...endDate,
        date,
      });

      if (date && !startDateRef.current) {
        if (endDateRef.current) {
          date = new Date(endDateRef.current.getTime());
          date.setDate(1);
          setCurrentDate(date);
        } else {
          date = new Date();
          date.setDate(1);
          setCurrentDate(date);
        }
      }
    }
  }, [endDate]);

  const currentMonth = useMemo(() => {
    if (!Number.isNaN(endDate.month)) {
      if (endDate.day === 0) {
        return endDate.month - 1;
      }
    }
    return endDate.month;
  }, [endDate]);

  return (
    <Portal>
      <Backdrop open={open} onClick={onClose} style={{ zIndex: 1250 }}>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          style={{ zIndex: 1300 }}
        >
          <Paper onClick={(e) => e.stopPropagation()}>
            <Box width="100vw" maxWidth={650} p={2}>
              <Box mb={1.5}>
                <Typography
                  variant="h5"
                  style={{ color: theme.palette.secondary.dark }}
                >
                  {title}
                </Typography>
              </Box>

              <Grid container spacing={3}>
                <Grid item xs={12} md="auto">
                  <Typography
                    component="p"
                    variant="caption"
                    style={{ color: theme.palette.secondary.main }}
                  >
                    De:
                  </Typography>

                  <Box display="flex">
                    <MonthSelect
                      value={startDate.month}
                      onChange={(_, child) => {
                        setStartDate({
                          ...startDate,
                          day: 1,
                          month: child.props.value,
                        });
                      }}
                    />

                    <Box ml={1}>
                      <YearSelect
                        value={startDate.year}
                        onChange={(_, child) =>
                          setStartDate({
                            ...startDate,
                            year: child.props.value,
                          })
                        }
                      />
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12} md="auto">
                  <Typography
                    component="p"
                    variant="caption"
                    style={{ color: theme.palette.secondary.main }}
                  >
                    Até:
                  </Typography>

                  <Box display="flex">
                    <MonthSelect
                      value={currentMonth}
                      onChange={(_, child) => {
                        setEndDate({
                          ...endDate,
                          day: 0,
                          month: !Number.isNaN(child.props.value)
                            ? child.props.value + 1
                            : child.props.value,
                        });
                      }}
                    />

                    <Box ml={1}>
                      <YearSelect
                        value={endDate.year}
                        onChange={(_, child) =>
                          setEndDate({ ...endDate, year: child.props.value })
                        }
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} md="auto">
                  <Calendar
                    currentDate={currentDate}
                    startDate={startDate.date}
                    endDate={endDate.date}
                    onChange={handleCalendarChange}
                  />
                </Grid>
                <Grid item xs={12} md>
                  <Box
                    display="flex"
                    flexDirection="column"
                    height="100%"
                    borderLeft={`1px solid ${theme.palette.grey[600]}`}
                    pl={3}
                  >
                    <Box mb={2}>
                      <Typography
                        variant="body2"
                        style={{ color: theme.palette.secondary.dark }}
                      >
                        Sugestões
                      </Typography>
                    </Box>

                    <Box flex={1} mx={-0.5}>
                      {predefinedDates.map((options) => (
                        <Button
                          key={options.date.id}
                          variant="outlined"
                          className={options.className}
                          onClick={() => handlePredefinedDate(options.date)}
                        >
                          {options.date.label}
                        </Button>
                      ))}
                    </Box>

                    <Box mt={5} textAlign="right">
                      <Button
                        className={buttonClasses.linkButton}
                        onClick={handleReset}
                      >
                        Limpar
                      </Button>

                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleApply}
                        style={{ width: 120 }}
                      >
                        Aplicar
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Popper>
      </Backdrop>
    </Portal>
  );
}

RangeDateFilter.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  anchorRef: PropTypes.shape({
    current: PropTypes.instanceOf(HTMLElement),
  }),
  onClose: PropTypes.func.isRequired,
  onApply: PropTypes.func.isRequired,
};

RangeDateFilter.defaultProps = {
  anchorRef: null,
};

export default RangeDateFilter;
