import Alert from 'modules/AlertModule';

export default (customerName) =>
  Alert.warning(
    `Para emitir os títulos, os dados do cliente ${customerName} devem estar preenchidos`,
    {
      cancelButtonText: 'Agora não',
      confirmButtonText: 'Sim, preencher',
      reverseButtons: true,
    }
  );
