import { useCallback, useEffect, useRef, useState } from 'react';
import debounce from 'lodash.debounce';

import api from 'services/api';

const useFetch = (query, variables, redirect = true) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const debounced = useRef(
    debounce((resolve, reject, method, ...args) => {
      method(...args)
        .then(resolve)
        .catch(reject);
    }, 300)
  ).current;

  // eslint-disable-next-line
  const execute = (query, variables) => {
    setIsLoading(true);

    return api
      .call(query, variables, null, redirect)
      .then((result) => {
        const keys = Object.keys(result);

        if (keys.length > 0) {
          setData(result[keys[0]]);
        } else {
          setData(null);
        }
      })
      .catch((e) => {
        console.error(e);
        setError(e.message || 'ERROR');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  execute.debounced = (...args) =>
    new Promise((resolve, reject) => {
      debounced(resolve, reject, execute, ...args);
    });

  const refetch = useCallback(
    () => execute.debounced(query, variables),
    [query, variables]
  );

  useEffect(() => {
    execute.debounced(query, variables);
  }, [query, variables]);

  return [data, error, isLoading, refetch];
};
export default useFetch;
