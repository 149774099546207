import React from 'react';

function SettingsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M13.909 10.09a2.7 2.7 0 11-3.819 3.819 2.7 2.7 0 013.819-3.818"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M5.25 12c0 .297.027.594.063.882l-1.588 1.242A.898.898 0 003.5 15.28l1.412 2.443a.898.898 0 001.112.384l1.422-.571a.912.912 0 01.846.099c.22.146.448.28.684.4.27.137.466.382.509.682l.217 1.513c.063.442.442.77.888.77h2.817a.897.897 0 00.888-.77l.217-1.512a.918.918 0 01.512-.683c.235-.118.462-.251.68-.396a.915.915 0 01.85-.102l1.42.57a.899.899 0 001.112-.384l1.412-2.443a.898.898 0 00-.224-1.156l-1.588-1.242c.036-.288.063-.585.063-.882 0-.297-.027-.594-.063-.882l1.588-1.242a.898.898 0 00.224-1.156l-1.412-2.443a.898.898 0 00-1.112-.384l-1.42.57a.918.918 0 01-.85-.102 6.597 6.597 0 00-.68-.396.918.918 0 01-.512-.683l-.216-1.512a.897.897 0 00-.888-.77h-2.817a.897.897 0 00-.888.77l-.218 1.514a.92.92 0 01-.51.682c-.235.12-.463.255-.683.4a.917.917 0 01-.847.098l-1.421-.571a.898.898 0 00-1.112.384L3.5 8.72a.898.898 0 00.224 1.156l1.588 1.242A7.138 7.138 0 005.25 12v0z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SettingsIcon;
