/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from '@material-ui/core';

function PaginationLink({ selected, page, ...props }) {
  const className = useMemo(
    () => (selected ? `${props.className} active` : props.className),
    [props.className, selected]
  );

  const text = String(page + 1).padStart(2, '0');

  return (
    <Link {...props} className={className} href="# ">
      {text}
    </Link>
  );
}

PaginationLink.propTypes = {
  selected: PropTypes.bool,
  page: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired,
};

PaginationLink.defaultProps = {
  selected: false,
};

export default PaginationLink;
