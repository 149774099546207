export default function convertDateToTimeLeft(value) {

    const now = new Date().getTime()
    const date = new Date(value).getTime()
    const timeleft = Math.floor((date - now) / 1000)

    if (isNaN(timeleft)) return 'Inválido'

    if (timeleft <= 0) return 'Expirado'

    if (timeleft < 60) {
        return `Em breve`
    }

    if (timeleft < 3600) {
        const minutos = Math.floor(timeleft / 60)

        if (minutos === 1) {
            return '1 minuto'
        } else {
            return `${minutos} minutos`
        }
    }

    if (timeleft < 86400) {
        const horas = Math.floor(timeleft / 3600)

        if (horas === 1) {
            return '1 hora'
        } else {
            return `${horas} minutos`
        }
    }

    if (timeleft >= 86400) {
        const dias = Math.floor(timeleft / 86400)

        if (dias === 1) {
            return '1 dia'
        } else {
            return `${dias} dias`
        }
    }
}