import React from 'react';

function TeamZeroStateImage() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="175"
      height="160"
      fill="none"
      viewBox="0 0 175 160"
    >
      <g clipPath="url(#clip0_3599_42083)">
        <path
          fill="#F8F9FA"
          d="M173.547 83.109c-2.139-16.972-8.854-33.859-17.598-47.663-11.36-17.962-29.525-32.372-51.081-35.043-15.251-1.89-32.638 2.925-44.432 13.253a45.93 45.93 0 00-13.054 18.849c-2.886 7.927-4.118 17.007-10.336 23.23-7.477 7.476-17.797 11.05-25.52 18.188-17.121 15.822-12.124 44.405 2.299 60.192 12.99 14.211 32.255 11.583 49.59 14.27 13.148 2.033 24.528 9.448 37.969 10.848 19.44 2.03 39.497-4.478 53.771-17.918 16.585-15.659 21.063-36.999 18.392-58.206z"
        />
        <path
          fill="#263238"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M110.471 159.574l2.035-30.196-2.071-18.942s-17.283 2.353-38.432-2.765l-.553 26.684 1.247 15.655c10.139 3.445 19.943 8.036 30.88 9.179 2.294.217 4.592.349 6.894.385z"
        />
        <path
          fill="#707070"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M110.435 110.414s-17.283 2.352-38.432-2.765l-.068 3.123c1.736 1.283 6.264 3.821 15.964 5.036 10.107 1.264 19.168-.839 22.917-1.918l-.381-3.476z"
        />
        <path
          fill="#263238"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M99.022 116.007c.78-.063 1.541-.14 2.266-.227v-4.831c-8.016.077-16.02-.636-23.896-2.131l.53 1.596v3.259c.603.199 1.256.399 1.954.594l.122-3.713 19.214 1.519-.19 3.934z"
        />
        <path
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M84.49 115.291c1.058.19 2.194.362 3.409.516 1.418.177 2.819.286 4.183.345l.105-2.974-7.466-1.241-.23 3.354z"
        />
        <path
          stroke="#858585"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M79.468 114.837s-2.905 5.671-6.636 6.346M104.125 125.14c.286.271.589.524.907.757M100.341 117.466a14.897 14.897 0 002.647 6.409M91.788 153.586l.004 2.375M86.24 135.297l5.53 4.841.013 11.721M86.267 121.623l.385 9.944 2.906 2.212-.277-10.371M86.1 117.466l.072 1.786"
        />
        <path
          fill="#FF6C4E"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M92.613 56.082s-15.701.49-16.807 1.228c-1.106.739-10.303 5.276-10.303 5.276s3.173-6.382 3.173-8.956c0-2.575-1.963-4.17-5.52-5.521-3.559-1.35-8.467.612-9.447 3.313-.979 2.702-5.643 19.381-6.132 24.163-.49 4.782 5.892 8.957 8.585 8.712 2.692-.245 16.44-5.276 16.44-5.276s-.984 26.987-.984 27.726c0 .739 0 1.84 1.473 2.452 1.473.612 8.462 4.17 16.929 4.909 8.467.738 18.031-.984 19.259-1.106 1.228-.123 2.33-2.697 2.33-3.803 0-1.106 1.01-9.568 1.01-9.568l1.813 7.483s1.224 1.36 5.149 1.718c3.926.358 6.994-1.596 7.851-3.558.857-1.963.861-2.452.861-2.452s-7.968-30.423-8.571-33.125c-.603-2.701-.49-2.72-2.207-4.659-1.718-1.94-12.882-6.377-15.946-7.252s-8.956-1.704-8.956-1.704z"
        />
        <path
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M65.503 62.581L60.35 76.936M63.418 68.718c.132 2.333.05 4.672-.245 6.99M72.62 79.021l.367-13.371M72.62 76.691s-2.086-5.03-2.457-9.568M112.61 99.63l4.12-24.512M114.219 90.32l3.78-11.712"
        />
        <path
          fill="#263238"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M90.246 66.316l1.94 4.56-9.264 31.384 4.7 7.048 5.67-6.636.966-31.102 5.806-3.318-4.56-6.083-5.258 4.147z"
        />
        <path
          fill="#FF6C4E"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M92.876 53.734s-2.212.417-2.348 1.246c-.136.83-2.076 2.348-2.489 5.53-.412 3.182.136 7.601.136 7.601s4.288-1.935 5.118-2.071c.83-.136 3.172.136 3.594.83.421.693 2.624 4.985 3.73 4.283 1.106-.703 6.346-6.496 6.636-11.196.29-4.7-4.283-4.288-4.836-4.564-.553-.277-9.541-1.66-9.541-1.66z"
        />
        <path
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M92.876 49.31s-.413 4.011-.69 7.33c-.276 3.317-.693 6.77.69 8.57 1.382 1.8 2.905 2.072 6.223-.417 3.318-2.488 5.253-4.7 5.815-7.03.562-2.33 1.36-4.84 1.36-4.84s-6.763 1.78-13.398-3.613z"
        />
        <path
          fill="#263238"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M92.875 49.31s-.349 3.39-.62 6.518a6.844 6.844 0 003.689 2.062c4.469.943 8.503-2.416 10.144-4.374.122-.38.199-.612.199-.612s-6.776 1.8-13.412-3.594z"
        />
        <path
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M92.676 39.12s-.553-1.812-1.659-.416c-1.106 1.396-.553 4.84.14 5.67.694.83 1.519-5.253 1.519-5.253z"
        />
        <path
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M94.63 30.658s-4.243 13.884-3.604 16.843c.64 2.96 1.813 7.973 6.686 8.998 4.872 1.024 9.26-3.083 10.547-4.882 0 0 3.34.385 4.242-1.813.902-2.198 2.185-4.496.907-5.013a5.799 5.799 0 00-1.813-.512s3.599-5.657 3.857-7.588c.258-1.93-1.029-8.358-5.144-11.445-4.116-3.086-11.055-3.467-10.67-2.053.385 1.414 1.287 1.541 1.287 1.541s-8.1-.906-8.743 1.287c-.644 2.194 2.828 3.473 2.828 3.473l-.38 1.164z"
        />
        <path
          fill="#263238"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M111.6 44.288s3.598-5.657 3.857-7.587c.258-1.931-1.029-8.359-5.145-11.445-4.115-3.087-11.055-3.468-10.67-2.053.386 1.414 1.288 1.54 1.288 1.54s-8.1-.906-8.744 1.288c-.643 2.194 2.829 3.472 2.829 3.472l-.385 1.156s-.816 2.66-1.673 5.892h.258s.386-1.16.907-2.96 1.546-3.857 6.685-3.34c5.14.516 10.289 3.213 10.543 5.14.254 1.926-1.926 2.72-1.156 3.603.771.884 1.813 1.283 1.156 1.926-.657.644-1.926 3.345-1.156 3.73.771.386 2.058-.258 2.058-.258h.036a3.055 3.055 0 00-.688-.104z"
        />
        <path
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M99.258 39.655L96.94 45.83l2.444.77M97.458 48.53a4.806 4.806 0 005.525-1.029"
        />
        <path
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M94.63 36.315s3.214.512 4.11 1.029M103.373 37.47s2.312-.77 3.857 1.161"
        />
        <path
          fill="#263238"
          d="M96.552 39.605c-.073.563-.39.984-.707.943-.318-.04-.513-.53-.454-1.097.06-.566.39-.983.707-.942.318.04.53.535.454 1.096zM104.007 41.723c-.077.566-.394.988-.711.947-.318-.04-.512-.535-.453-1.097.058-.562.389-.988.707-.943.317.046.539.53.457 1.093z"
        />
        <path
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M61.7 45.29l-2.207 4.315s0 1.962 3.313 2.574c3.314.612 4.415-1.36 4.415-1.36l2.339-6.39s1.84-1.229 2.085-2.575c.245-1.346 0-5.398.367-7.252s3.069-4.66 1.718-5.031c-1.35-.372-2.82.616-3.173 2.334-.353 1.718-.983 3.676-.983 3.676s-1.229-1.469-1.36-2.72c-.132-1.25.494-1.84-.367-2.085-.861-.244-6.5 3.925-6.745 5.276-.244 1.35.599 9.237.599 9.237z"
        />
        <path
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
          d="M69.769 25.405v10.606"
        />
        <path
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M67.588 43.816s-1.106 2.208-2.819 2.575c-1.713.367-2.212-.612-2.212-.612"
        />
        <path
          fill="#fff"
          d="M62.988 33.473s-.476-.272.068-.675c.544-.404 2.443-1.292 3.05-1.627.607-.336 1.564 2.914 1.9 4.079.335 1.165.475 1.428 0 1.428-.477 0-.88 0-1.492-1.224a9.466 9.466 0 00-.947-1.627l-1.156.204"
        />
        <path
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M62.988 33.473s-.476-.272.068-.675c.544-.404 2.443-1.292 3.05-1.627.607-.336 1.564 2.914 1.9 4.079.335 1.165.475 1.428 0 1.428-.477 0-.88 0-1.492-1.224a9.466 9.466 0 00-.947-1.627l-1.156.204"
        />
        <path
          fill="#fff"
          d="M61.496 34.647s-.476-.272.068-.68c.544-.408 2.443-1.287 3.05-1.627.608-.34 1.56 2.914 1.9 4.079.34 1.165.476 1.423 0 1.423s-.88 0-1.491-1.219a9.846 9.846 0 00-.947-1.632l-1.156.204"
        />
        <path
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M61.496 34.647s-.476-.272.068-.68c.544-.408 2.443-1.287 3.05-1.627.608-.34 1.56 2.914 1.9 4.079.34 1.165.476 1.423 0 1.423s-.88 0-1.491-1.219a9.846 9.846 0 00-.947-1.632l-1.156.204"
        />
        <path
          fill="#fff"
          d="M61.823 36.823l1.36 1.228s.49 1.36 1.223 1.473c.735.113.74-.616 0-1.963-.738-1.346-1.1-3.435-1.59-3.558-.49-.122-2.457 1.591-2.457 1.591l.983 3.173"
        />
        <path
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M61.823 36.823l1.36 1.228s.49 1.36 1.223 1.473c.735.113.74-.616 0-1.963-.738-1.346-1.1-3.435-1.59-3.558-.49-.122-2.457 1.591-2.457 1.591l.983 3.173"
        />
        <path
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M68.05 30.006s-1.237 0-1.359 1.06c-.122 1.061.648 1.36 1.772 1.12s1.65-1.296 1.238-1.886c-.413-.589-1.65-.294-1.65-.294z"
        />
        <path
          fill="#FF6C4E"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M67.221 50.806l1.124-3.083c-4.532 1.36-6.627.114-7.488-.793l-1.36 2.652s0 1.962 3.314 2.574c3.313.612 4.41-1.35 4.41-1.35z"
        />
        <path
          fill="#FF6C4E"
          d="M120.252 104.449s-3.943.104-5.19 1.559c-1.246 1.455-1.359 8.612-1.359 9.763 0 1.151 2.266 4.569 6.436 5.403 4.17.834 7.705-1.664 7.791-4.261.087-2.597 1.868-9.659 1.455-12.36-.412-2.702-2.18-3.626-2.18-3.626"
        />
        <path
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M120.252 104.449s-3.943.104-5.19 1.559c-1.246 1.455-1.359 8.612-1.359 9.763 0 1.151 2.266 4.569 6.436 5.403 4.17.834 7.705-1.664 7.791-4.261.087-2.597 1.868-9.659 1.455-12.36-.412-2.702-2.18-3.626-2.18-3.626"
        />
        <path
          fill="#FF6C4E"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M119.772 121.546c2.698 0 4.886-2.137 4.886-4.773 0-2.636-2.188-4.773-4.886-4.773-2.699 0-4.886 2.137-4.886 4.773 0 2.636 2.187 4.773 4.886 4.773z"
        />
        <path
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M121.191 121.156s.516-2.289 0-3.626c-.517-1.337-3.948-3.119-4.986-2.72-1.038.399-.522 1.143-1.455 1.56-.934.417-4.469.933-5.195 1.867-.725.934-1.246 2.72-2.388 4.777-1.142 2.058-2.493 3.014-.934 3.119a3.408 3.408 0 001.907-.503 3.406 3.406 0 001.311-1.473 3.907 3.907 0 011.559-1.56s-2.805 6.346-2.388 6.962c.184.329.475.585.825.726 0 0-.313 1.246.417 1.559a1.503 1.503 0 001.359-.104s.934.725 1.455.725a1.76 1.76 0 00.934-.308s1.763.203 2.266-.626c.503-.829 2.806-3.531 3.948-5.711a13.943 13.943 0 001.365-4.664z"
        />
        <path
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M109.451 130.294l4.465-6.649M111.219 131.748l4.986-6.44M113.607 132.161l3.944-4.465M111.01 122.606l.83-2.801"
        />
      </g>
      <defs>
        <clipPath id="clip0_3599_42083">
          <path
            fill="#fff"
            d="M0 0H173.598V160H0z"
            transform="translate(.701)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default TeamZeroStateImage;
