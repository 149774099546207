import { createTheme } from '@material-ui/core';

export const withOverrides = (baseTheme) =>
  createTheme({
    ...baseTheme,

    overrides: {
      MuiCssBaseline: {
        '@global': {
          body: {
            color: baseTheme.palette.text.primary,
          },

          '.swal2-popup': {
            fontFamily: `${baseTheme.typography.fontFamily} !important`,
          },

          '.swal2-styled.swal2-confirm': {
            backgroundColor: `${baseTheme.palette.primary.main} !important`,
          },

          '.swal2-styled.swal2-cancel': {
            backgroundColor: `${baseTheme.palette.secondary.main} !important`,
          },
        },
      },

      MuiButton: {
        text: {
          fontSize: '14px',
          fontWeight: '500',
          textTransform: 'none',
          borderRadius: '25px',
          padding: '0px 20px',
          height: 40,
        },

        textSizeSmall: {
          fontSize: '14px',
          fontWeight: '500',
          textTransform: 'none',
          borderRadius: '20px',
          padding: '0px 10px',
          height: 30,
        },

        textSizeLarge: {
          fontSize: '16px',
          fontWeight: '500',
          textTransform: 'none',
          borderRadius: '30px',
          padding: '0px 30px',
          height: 50,
        },

        contained: {
          fontSize: '14px',
          fontWeight: '500',
          textTransform: 'none',
          borderRadius: '25px',
          padding: '0px 20px',
          height: 40,
          boxShadow: 'none',

          '&:hover': {
            boxShadow: 'none',
          },

          '&:active': {
            boxShadow: 'none',
          },
        },

        containedPrimary: {
          color: 'white !important',
        },

        containedSizeSmall: {
          fontSize: '12px',
          fontWeight: '500',
          textTransform: 'none',
          borderRadius: '20px',
          padding: '0px 16px',
          height: 30,
        },

        containedSizeLarge: {
          fontSize: '16px',
          fontWeight: '500',
          textTransform: 'none',
          borderRadius: '30px',
          padding: '0px 30px',
          height: 50,
        },

        outlined: {
          fontSize: '14px',
          fontWeight: '500',
          textTransform: 'none',
          borderRadius: '25px',
          padding: '0px 20px',
          height: 40,
        },

        outlinedSizeSmall: {
          fontSize: '14px',
          fontWeight: '500',
          textTransform: 'none',
          borderRadius: '20px',
          padding: '0px 10px',
          height: 30,
        },

        outlinedSizeLarge: {
          fontSize: '16px',
          fontWeight: '500',
          textTransform: 'none',
          borderRadius: '30px',
          padding: '0px 30px',
          height: 50,
        },
      },

      MuiFormControlLabel: {
        root: {
          color: baseTheme.palette.text.secondary,
        },
      },

      MuiTableCell: {
        root: {
          padding: 8,
        },
        head: {
          fontSize: 12,
          fontWeight: '700',
          letterSpacing: '1px',
          lineHeight: '18px',
          textTransform: 'uppercase',
          color: '#B9BDD1',
        },
      },

      MuiSlider: {
        rail: {
          margin: '0 -4px',
          width: 'calc(100% + 8px)',
          backgroundColor: baseTheme.palette.secondary.main,
          opacity: 0.24,
          height: 5,
        },
        track: {
          height: 5,
        },
        thumb: {
          '& span > span > span': {
            color: 'white',
          },
        },
      },

      MuiTooltip: {
        tooltip: {
          backgroundColor: baseTheme.palette.secondary.dark,
          color: 'white',
        },
      },

      MuiFormGroup: {
        root: {
          '&[role=radiogroup]': {
            '& .MuiFormControlLabel-root .MuiTypography-root': {
              ...baseTheme.typography.body2,
              color: baseTheme.palette.grey[200],
            },
          },
        },
      },
    },
  });

export default null;
