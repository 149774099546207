export default `
  mutation emitSalesContractInvoices (
    $projectId: ID!
    $invoices: [ID]!
  ) {
    emitSalesContractInvoices (
      projectId: $projectId
      invoices: $invoices
    ) 
  }
`;
