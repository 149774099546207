import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';

import SearchListTextField from 'components/SearchListTextField';
import { useReturnFilesListContext } from 'providers/ListProviders/ReturnFilesListProvider';

export default function ReturnFilesListFilters() {
  const { search, setSearch } = useReturnFilesListContext();

  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      justifyContent="space-between"
    >
      <Grid item xs="6">
        <Box alignItems="center" display="flex">
          <Typography className="fw700" variant="h5" color="textPrimary">
            Retorno
          </Typography>
          <Box minWidth={285} ml={2}>
            <SearchListTextField
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              value={search}
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
