import React from 'react';

function SalesContractsZeroStateImage() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="174"
      height="133"
      fill="none"
      viewBox="0 0 174 133"
    >
      <path fill="#fff" d="M35.67 10.981H1.05v49.565H35.67V10.98z" />
      <path
        fill="#FF6C4E"
        d="M35.67 10.981H1.05v49.565H35.67V10.98z"
        opacity="0.4"
      />
      <path
        stroke="#3D4657"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M35.67 10.981H1.05v49.565H35.67V10.98z"
      />
      <path
        fill="#fff"
        stroke="#3D4657"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M34.536 3.982L.584 10.76l9.704 48.605 33.952-6.778-9.704-48.605z"
      />
      <path
        stroke="#3D4657"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M3.124 10.008a3.366 3.366 0 013.104-2.075v0a3.348 3.348 0 013.348 3.347v6.984a2.238 2.238 0 01-2.23 2.238 2.238 2.238 0 01-2.237-2.238v-6.632a1.047 1.047 0 011.047-1.047v0a1.047 1.047 0 011.046 1.047v3.564"
      />
      <path
        fill="#fff"
        d="M56.704 85.576c0 4.854-7.219 6.316-7.219 6.316-13.21-12.118-16.187-27.007-22.612-29.37a5.059 5.059 0 01-1.579-.966 12 12 0 01-2.87-4.593l8.546-1.705a18.949 18.949 0 012.3-6.317c.623-.902-.279-2.923-1.335-5.215a2.852 2.852 0 01.442-2.842c2.001 1.633 3.582 3.721 4.611 6.09 1.309 3.492 0 11.73 3.853 15.773 6.903 7.273 7.94 6.858 14.437 12.452a53.666 53.666 0 011.426 10.377z"
      />
      <path
        fill="#FF6C4E"
        d="M25.285 61.53a12.001 12.001 0 01-2.87-4.593l8.546-1.706a11.667 11.667 0 000 4.07c.27 1.056-3.077 1.065-5.676 2.229z"
        opacity="0.4"
      />
      <path
        stroke="#3D4657"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M56.704 85.576c0 4.854-7.219 6.316-7.219 6.316-13.21-12.118-16.187-27.007-22.612-29.37a5.059 5.059 0 01-1.579-.966 12 12 0 01-2.87-4.593l8.546-1.705a18.949 18.949 0 012.3-6.317c.623-.902-.279-2.923-1.335-5.215a2.852 2.852 0 01.442-2.842c2.001 1.633 3.582 3.721 4.611 6.09 1.309 3.492 0 11.73 3.853 15.773 6.903 7.273 7.94 6.858 14.437 12.452a53.667 53.667 0 011.426 10.377z"
      />
      <path
        fill="#FF6C4E"
        d="M80.525 48.925v31.653s-4.115 4.06-8.581 8.338c-4.773 4.574-9.925 9.393-10.927 9.78-1.904.74-5.712-2.174-5.712-2.174-2.138 1.426-4.286-1.606-4.286-1.606-2.138 2.193-4.286-1.489-1.66-5.297 2.626-3.807 5.946-14.437 5.946-14.437a1.65 1.65 0 012.382 0S74.064 52.02 80.525 48.925z"
      />
      <path
        fill="#000"
        fillOpacity="0.7"
        d="M80.525 62.468v18.11s-4.115 4.06-8.581 8.337a15.177 15.177 0 01-3.79-7.11c-.532-3.772 3.925-1.047 3.925-1.047-3.212-3.23.542-18.047.731-15.53.163 2.031.686 6.443.903 8.04-.434-5.684 5.27-10.16 6.812-10.8z"
        opacity="0.2"
      />
      <path
        stroke="#3D4657"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M80.525 48.925v31.653s-4.115 4.06-8.581 8.338c-4.773 4.574-9.925 9.393-10.927 9.78-1.904.74-5.712-2.174-5.712-2.174-2.138 1.426-4.286-1.606-4.286-1.606-2.138 2.193-4.286-1.489-1.66-5.297 2.626-3.807 5.946-14.437 5.946-14.437a1.65 1.65 0 012.382 0S74.064 52.02 80.525 48.925z"
      />
      <path
        fill="#FF6C4E"
        d="M124.315 123.672c1.759 2.861-46.686 4.088-50.025 1.805a3.31 3.31 0 01-.993-1.02c-1.516-2.463.993-6.126.993-6.126s-.424-50.377 6.235-69.407a154.935 154.935 0 0119.355-.902c4.322.063 9.104.306 14.049.902 3.609.397 7.994 1.137 12.353 1.95 7.281 1.37 14.437 2.977 17.225 3.608l1.272.298s-22.224 66.041-20.464 68.892z"
      />
      <path
        fill="#000"
        fillOpacity="0.7"
        d="M96.514 59.301s-3.239-5.477 2.157-10.376a7.422 7.422 0 011.209-.902c4.322.063 9.104.306 14.049.902 3.609.397 7.995 1.137 12.353 1.949l-19.391 10.25-4.024-4.105-6.353 2.282zM124.315 123.673c1.759 2.86-46.686 4.088-50.025 1.805a3.31 3.31 0 01-.992-1.02c9.618 1.751 33.385-.451 39.837-3.059 7.67-3.086 12.136-17.081 9.691-15.655-2.445 1.426-10.584.541-4.43-2.499 6.153-3.041 12.993-13.003 12.993-13.003s-4.331-12.515-2.905-15.06c1.037-1.867 2.373 1.588 3.013 3.61-1.092-5.144 2.806-10.278 2.806-10.278l9.24-14.022 1.273.298s-22.261 66.032-20.501 68.883z"
        opacity="0.2"
      />
      <path
        stroke="#3D4657"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M124.315 123.672c1.759 2.861-46.686 4.088-50.025 1.805a3.31 3.31 0 01-.993-1.02c-1.516-2.463.993-6.126.993-6.126s-.424-50.377 6.235-69.407a154.935 154.935 0 0119.355-.902c4.322.063 9.104.306 14.049.902 3.609.397 7.994 1.137 12.353 1.95 7.281 1.37 14.437 2.977 17.225 3.608l1.272.298s-22.224 66.041-20.464 68.892z"
      />
      <path
        fill="#fff"
        stroke="#3D4657"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M121.608 49.763s-18.047 7.219-23.939-.325a5.981 5.981 0 005.198-2.48 9.085 9.085 0 001.452-4.81c.081-1.598-.022-3.2-.307-4.774-.694-4.231 17.072-1.497 17.072-1.497s-3.816 11.757.524 13.886z"
      />
      <path
        fill="#3D4657"
        d="M110.924 40.47s-3.104 5.35-8.058 6.487a9.078 9.078 0 001.453-4.809 112.058 112.058 0 016.605-1.678z"
      />
      <path
        fill="#fff"
        d="M121.084 34.902c5.026-7.444 12.687-36.824-12.028-31.085-12.29 2.851-10.701 18.949-10.701 18.949l-4.232 5.242 3.501 2.969-.144 3.492 1.19.424s-1.046 8.49 0 9.023c1.914.975 14.483-.56 14.483-3.609"
      />
      <path
        stroke="#3D4657"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M121.084 34.902c5.026-7.444 12.687-36.824-12.028-31.085-12.29 2.851-10.701 18.949-10.701 18.949l-4.232 5.242 3.501 2.969-.144 3.492 1.19.424s-1.046 8.49 0 9.023c1.914.975 14.483-.56 14.483-3.609M103.189 35.137c-1.696-.949-3.173-.525-4.688-.212M100.448 16.324s4.196-.415 6.181 2.41"
      />
      <path
        fill="#3D4657"
        d="M102.939 22.766c0 .84-.343 1.516-.758 1.516s-.758-.677-.758-1.516.334-1.525.758-1.525.758.686.758 1.525zM128.727 8.293c-3.979-4.106-21.015-10.124-25.86-7.643-7.11 2.707-5.198 10.476-5.198 10.476l1.002-2.707s.523 2.292 4.196 3.673v-1.715a16.043 16.043 0 007.336 1.146l-.776-1.155c.959.219 1.903.502 2.824.848-2.707 5.054.352 8.627.352 8.627v6.84s3.609 3.527 4.629 7.083a5.291 5.291 0 003.88 4.006c7.832-4.8 11.802-25.157 7.615-29.479z"
      />
      <path
        fill="#fff"
        d="M111.763 27.693s3.871-5.216 5.865-4.386c1.995.83 2.509 5.64 1.462 7.841-1.046 2.202-4.818 6.696-5.648 5.026"
      />
      <path
        stroke="#3D4657"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M111.763 27.693s3.871-5.216 5.865-4.386c1.995.83 2.509 5.64 1.462 7.841-1.046 2.202-4.818 6.696-5.648 5.026M57.688 75.183a33.097 33.097 0 010 18.245M51.02 94.915s3.609-5.531 4.285-9.339"
      />
      <path
        fill="#3D4657"
        d="M98.67 52.876l1.805 6.425s-10.638 35.56-11.829 45.792l2.382 3.61 4.512-3.402s4.457-35.01 7.345-44.782l5.477-7.417-3.456-.343-6.235.117z"
      />
      <path
        fill="#FF6C4E"
        stroke="#3D4657"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M101.45 46.957l2.094 4.35 18.046-4.35 4.674 3.916-19.652 8.428-3.086-6.172-5.874 4.133s-4.323-9.664 3.798-10.305z"
      />
      <path
        fill="#fff"
        stroke="#3D4657"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M143.299 107.168a69.838 69.838 0 00-12.316 14.816c-4.819 8.211-5.116 8.87-8.87 11.008 0 0 8.572-.306 13.535 0 0 0 14.96-8.093 18.732-16.539 3.772-8.446-11.081-9.285-11.081-9.285z"
      />
      <path
        fill="#FF6C4E"
        d="M144.779 54.79c5.712 2.707 13.535 50.99 13.77 57.676.063 2.139-4.25 6.768-4.25 6.768s.072 4.15-2.581 4.511c0 0-7.886-8.121-14.554-11.243a2.252 2.252 0 011.232-2.924 2.25 2.25 0 011.15-.152l-5.17-22.558c-5.541-18.588 6.126-27.972 6.126-27.972"
      />
      <path
        stroke="#3D4657"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M144.779 54.79c5.712 2.707 13.535 50.99 13.77 57.676.063 2.139-4.25 6.768-4.25 6.768s.072 4.15-2.581 4.511c0 0-7.886-8.121-14.554-11.243a2.252 2.252 0 011.232-2.924 2.25 2.25 0 011.15-.152l-5.17-22.558c-5.541-18.588 6.126-27.972 6.126-27.972M139.546 109.435s7.85.595 12.136 7.02"
      />
      <path
        fill="#757575"
        stroke="#3D4657"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M103.968 132.091c4.844-.172 9.669-.7 14.437-1.579l-.379-4.34a137.633 137.633 0 01-14.528 1.407c.136 1.228.343 3.177.47 4.512zM120.164 130.179a97.52 97.52 0 002.707-.613l-.65-4.106s-.848.189-2.436.46l.379 4.259zM101.468 127.651c-7.08.224-14.168.01-21.222-.64l-.515 3.907a142.874 142.874 0 0021.954 1.272l-.217-4.539zM78.802 126.866l-1.805-.189-.478 3.744s.65.118 1.804.289l.479-3.844z"
      />
      <path
        fill="#BFBFBF"
        stroke="#3D4657"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M92.96 132.506l-7.851-.271.595-4.99 7.85.271-.595 4.99z"
      />
      <path
        stroke="#3D4657"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M109.643 71.283l13.183 1.345-3.086 13.841-6.966.885-5.883-3.736 1.453-10.99M142.406 72.629s-5.657 2.21-6.253 10.828M72.08 67.728s.64 9.177 3.356 14.14M77.674 119.097s23.027-1.75 29.776-7.994M129.729 78.033s1.443 5.757 4.295 7.544M13.014 43.798l13.498-2.689M12.373 40.361l23.505-4.692M11.741 36.922l23.497-4.691M11.1 33.485l23.497-4.692M10.46 30.039l23.496-4.692M9.82 26.601l23.496-4.692M9.179 23.163l23.496-4.692M14.81 18.471l17.225-3.438M40.287 132.993h132.641"
      />
    </svg>
  );
}

export default SalesContractsZeroStateImage;
