import React from 'react';
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { BsArrowRight } from 'react-icons/bs';

import Icon from 'components/Icon';
import useThemeClasses from 'theme/useThemeClasses';
import formatPrice from 'utils/formatPrice';
import formatCpf from 'utils/formatCpf';

import ICON_TYPES from '@constants/iconsPaths';
import { PAYMENT_METHODS_LABELS } from '@constants/reservations/paymentMethods';
import useStyles from './styles';

export default function ResumeStep({
  property,
  customer,
  payment,
  onBack,
  onSubmit,
  observation,
  onObservationChange,
}) {
  const theme = useTheme();
  const classes = useStyles();
  const themeClasses = useThemeClasses;
  const buttonClasses = themeClasses.button();

  const handleBack = (event) => {
    if (event) event.preventDefault();
    if (onBack) onBack();
  };

  const propertyPrice = formatPrice(
    property.price - property.price * payment.discount
  );

  const paymentPriceLabels = {
    monthly: `Parcela mensal: ${payment.monthlyInstallment?.formattedValue}`,
    annually: `Parcela mensal: ${payment.monthlyInstallment?.formattedValue}`,
  };

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box mb={3}>
            <Box mb={1}>
              <Typography>Pagamento</Typography>
            </Box>

            <Grid container spacing={1}>
              <Grid item xs="auto">
                <Box className={classes.iconBox}>
                  <Icon
                    src={ICON_TYPES.RESERVATION.paymentInformation}
                    size={24}
                  />
                </Box>
              </Grid>
              <Grid item xs style={{ alignSelf: 'center' }}>
                <Typography
                  variant="body2"
                  style={{ color: theme.palette.grey[200] }}
                >
                  {PAYMENT_METHODS_LABELS[payment.paymentMethod]}
                </Typography>

                <Typography
                  variant="body2"
                  style={{ color: theme.palette.grey[300] }}
                >
                  {paymentPriceLabels[payment.paymentMethod]}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box mb={3}>
            <Box mb={1}>
              <Typography>Informações do imóvel</Typography>
            </Box>

            <Grid container spacing={1}>
              <Grid item xs="auto">
                <Box className={classes.iconBox}>
                  <Icon
                    src={ICON_TYPES.RESERVATION.propertyInformation}
                    size={24}
                  />
                </Box>
              </Grid>
              <Grid item xs style={{ alignSelf: 'center' }}>
                <Typography
                  variant="body2"
                  style={{ color: theme.palette.grey[200] }}
                >
                  {property.unity}
                </Typography>
                <Typography
                  variant="body2"
                  style={{ color: theme.palette.grey[300] }}
                >
                  {propertyPrice}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box mb={3}>
            <Box mb={1}>
              <Typography>Informações do cliente</Typography>
            </Box>

            <Grid container spacing={1}>
              <Grid item xs="auto">
                <Box className={classes.iconBox}>
                  <Icon
                    src={ICON_TYPES.RESERVATION.customerInformation}
                    size={24}
                  />
                </Box>
              </Grid>
              <Grid item xs style={{ alignSelf: 'center' }}>
                <Typography
                  variant="body2"
                  style={{ color: theme.palette.grey[200] }}
                >
                  {customer.name}
                </Typography>
                <Typography
                  variant="body2"
                  style={{ color: theme.palette.grey[300] }}
                >
                  {formatCpf(customer.cpf)}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>

      <Box mb={1}>
        <Typography
          className="fw500"
          style={{ color: theme.palette.secondary.dark }}
        >
          Ficou faltando alguma informação? Adicione aqui.
        </Typography>
      </Box>

      <TextField
        multiline
        maxRows={10}
        value={observation}
        placeholder="Observações"
        onChange={(e) => onObservationChange(e.target.value)}
        fullWidth
      />

      <Box display="flex" justifyContent="flex-end" mt={5}>
        <Button
          className={buttonClasses.linkButton}
          onClick={handleBack}
          color="secondary"
        >
          Voltar
        </Button>

        <Button
          className={buttonClasses.buttonWithIcon}
          endIcon={<BsArrowRight />}
          variant="contained"
          color="primary"
          onClick={onSubmit}
          style={{ width: 260, marginLeft: 8 }}
        >
          Finalizar reserva
        </Button>
      </Box>
    </Box>
  );
}

ResumeStep.propTypes = {
  property: PropTypes.shape({
    m2price: PropTypes.number,
    totalArea: PropTypes.number,
    unity: PropTypes.string,
  }).isRequired,
  payment: PropTypes.shape({
    monthlyInstallment: PropTypes.objectOf({
      formattedValue: PropTypes.string,
    }),
    entrance: PropTypes.number,
    paymentMethod: PropTypes.string,
  }).isRequired,
  customer: PropTypes.shape({
    name: PropTypes.string,
    cpf: PropTypes.string,
  }).isRequired,
  onBack: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  observation: PropTypes.string,
};

ResumeStep.defaultProps = {
  observation: '',
};
