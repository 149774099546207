import React, {
  Box,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import useStyles from './styles';
import getSummarySettings from './utils/getSummarySettings';

export default function ({ label, data }) {
  const theme = useTheme();
  const mediaQuerySmDown = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const settings = getSummarySettings({ data, label });

  if (!settings) return null;
  if (mediaQuerySmDown) return null;

  return (
    <Box className={classes.root}>
      {settings.map((item, index, array) => {
        const hasDivider = index < array.length - 1;
        return (
          <Box key={item.label} className={classes.grouped}>
            <Box className={classes.item}>
              {item.icon}
              <Box ml={1}>
                <Typography variant="h6" className={classes.label}>
                  {item.label}
                </Typography>
                <Typography variant="body1" className={classes.value}>
                  {item.value}
                </Typography>
              </Box>
            </Box>
            {hasDivider ? <Box className={classes.divider} /> : null}
          </Box>
        );
      })}
    </Box>
  );
}
