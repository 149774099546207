import React, { useContext, useState } from 'react';
import {
  Box,
  Button,
  Container,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { ProjectContext } from 'providers/ProjectProvider';
import PropertiesListProvider from 'providers/ListProviders/PropertiesListProvider';

import MakeReservationDialog from 'components/Dialogs/CreateDialogs/MakeReservationDialog';
import ReadPropertyDialog from 'components/Dialogs/ReadDialogs/ReadPropertyDialog';
import ReadReceiptDialog from 'components/Dialogs/ReadDialogs/ReadReceiptDialog';
import PropertiesListZeroState from 'components/ZeroState/PropertiesListZeroState';
import PropertiesListFilters from 'components/ListFilters/PropertiesListFilters';
import PropertiesList from 'components/Lists/PropertiesList';

import { AuthContext } from 'providers/AuthProvider';
import AlertModule from 'modules/AlertModule';
import { useStyles } from './styles';
import useDialogController from './hooks/useDialogController';

function Properties() {
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  const { project } = useContext(ProjectContext);
  const { auth } = useContext(AuthContext);
  const [readPropertyDialogData, setReadPropertyDialogData] = useState({
    open: false,
    propertyId: null,
  });

  const [makeReservationDialogData, setMakeReservationDialogData] = useState({
    open: false,
    propertyId: null,
  });
  const [propertyReservedDialogData, setPropertyReservedDialogData] = useState({
    open: false,
    reservation: null,
  });

  const {
    onCloseMakeReservationDialog,
    onCloseReadPropertyDialog,
    onReadProperty,
    onPropertyReserved,
    onReserveProperty,
    onClosePropertyReservedDialog,
  } = useDialogController(
    readPropertyDialogData,
    setReadPropertyDialogData,
    setMakeReservationDialogData,
    setPropertyReservedDialogData
  );

  const onReserve = () => {
    const isReservationLimitExceeded =
      auth.realEstate &&
      auth.realEstate.pendingReservations >= auth.realEstate.reservationLimit;
    if (isReservationLimitExceeded) {
      AlertModule.error('Limite de reservas alcançado!');
      return;
    }
    onReserveProperty();
  };

  return (
    <Container className={classes.container}>
      <Box
        display={!isSm ? 'flex' : 'none'}
        justifyContent="space-between"
        alignItems="center"
        mb={5}
        minHeight={40}
      >
        <Box ml="auto">
          <Button
            variant="contained"
            name="interactive-map-button"
            color="primary"
            onClick={() => history.push(`/${project.slug}/mapa`)}
          >
            Mapa interativo
          </Button>
        </Box>
      </Box>
      <PropertiesListProvider>
        <PropertiesListFilters />
        <PropertiesListZeroState>
          <PropertiesList onDetails={onReadProperty} projectId={project?._id} />
        </PropertiesListZeroState>
      </PropertiesListProvider>

      <ReadPropertyDialog
        open={readPropertyDialogData.open}
        propertyId={readPropertyDialogData.propertyId}
        options={{ canEdit: true }}
        onClose={onCloseReadPropertyDialog}
        onReserve={onReserve}
      />

      <MakeReservationDialog
        open={makeReservationDialogData.open}
        propertyId={makeReservationDialogData.propertyId}
        onClose={onCloseMakeReservationDialog}
        onPropertyReserved={onPropertyReserved}
      />
      <ReadReceiptDialog
        open={propertyReservedDialogData.open}
        reservation={propertyReservedDialogData?.reservation}
        onClose={onClosePropertyReservedDialog}
      />
    </Container>
  );
}

export default Properties;
