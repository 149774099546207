import React from 'react';
import { Box, Typography } from '@material-ui/core';
import ProjectZeroStateImage from 'assets/images/ZeroStates/ProjectZeroStateImage';

function ProjectsZeroState({ length, children }) {
  if (length > 0) {
    return children;
  }
  return (
    <Box width="100%" mt={20}>
      <Box display="flex" alignItems="center" justifyContent="center" mr={15}>
        <ProjectZeroStateImage />
      </Box>
      <Box justifyContent="center" alignItems="center" display="flex">
        <Box
          mr={15}
          color="#495058"
          justifyContent="center"
          alignItems="center"
          display="flex"
          mt={3}
          width="25%"
        >
          <Typography align="center" display="block" variant="h3">
            Não encontramos nenhum empreendimento
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default ProjectsZeroState;
