import { Box, Button, Typography, useTheme } from '@material-ui/core';
import BaseDialog from 'components/Dialogs/BaseDialog';
import PercentField from 'components/Fields/PercentField';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import formatDate from 'utils/formatDate';
import api from 'services/api';
import { ADJUST_SALES_CONTRACT_INVOICES_MUTATION } from 'queries/adjustSalesContractInvoicesQuery';
import handlerError from 'utils/handleError';
import swalFire from 'utils/swalFire';

function PriceAdjustmentModal({ open, contract, onClose }) {
  const theme = useTheme();

  const [percent, setPercent] = useState(0);

  const handleSubmit = (e) => {
    e.preventDefault();

    const variables = {
      projectId: contract.projectId,
      contractNumberId: contract.numberId,
      percent: percent / 100,
    };

    api
      .call(ADJUST_SALES_CONTRACT_INVOICES_MUTATION, variables)
      .then(() => {
        swalFire('Reajustado com sucesso', '', 'success').then(() => onClose());
      })
      .catch((err) => {
        console.log(err);
        handlerError(
          {
            DEFAULT: () => {
              swalFire('', 'Não foi possível reajustar os títulos.', 'error');
            },
          },
          err.message
        );
      });
  };

  useEffect(() => {
    if (!open) {
      setPercent(0);
    }
  }, [open]);

  if (!contract) return null;

  return (
    <BaseDialog
      open={open}
      title="Qual será o valor de reajuste?"
      maxWidth="xs"
      onClose={onClose}
    >
      <form onSubmit={(e) => handleSubmit(e)}>
        <Box mb={4}>
          <Typography
            variant="caption"
            style={{ color: theme.palette.secondary.main }}
          >
            A partir de
          </Typography>

          <Typography
            variant="body2"
            style={{ color: theme.palette.grey[200] }}
          >
            {formatDate(contract.nextAdjustment)}
          </Typography>
        </Box>

        <Box mb={4}>
          <PercentField
            label="Valor (%)"
            value={percent}
            onChange={(e) => setPercent(e.target.value)}
            InputLabelProps={{ shrink: true }}
            fullWidth
          />
        </Box>

        <Box display="flex" justifyContent="flex-end">
          <Button type="submit" variant="contained" color="primary">
            Salvar
          </Button>
        </Box>
      </form>
    </BaseDialog>
  );
}

PriceAdjustmentModal.propTypes = {
  open: PropTypes.bool.isRequired,
  contract: PropTypes.shape({
    installments: PropTypes.number,
    projectId: PropTypes.string,
    numberId: PropTypes.number,
    nextAdjustment: PropTypes.string,
  }),
  onClose: PropTypes.func.isRequired,
};

PriceAdjustmentModal.defaultProps = {
  contract: null,
};

export default PriceAdjustmentModal;
