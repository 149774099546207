import React, {
  useEffect,
  useRef,
  useState,
  useContext,
  useCallback,
} from 'react';
import BaseDialog from 'components/Dialogs/BaseDialog';
import { useHistory } from 'react-router-dom';
import { ProjectContext } from 'providers/ProjectProvider';
import GET_RESERVATION_QUERY from 'queries/getReservationQuery';
import PropTypes from 'prop-types';
import AlertModule from 'modules/AlertModule';
import useQuery from 'hooks/useQuery';
import CREATE_SALES_CONTRACT_MUTATION from 'queries/createSalesContractMutation';
import Form from './components/Form';
import useNewContractWebSocket from './helpers/useNewContractWebSocket';

export default function NewContractModal({ open, reservationId, onClose }) {
  const isCreatingRef = useRef(false);
  const history = useHistory();
  const [reservation, setReservation] = useState(null);
  const { project } = useContext(ProjectContext);

  const [executeGetReservation] = useQuery(GET_RESERVATION_QUERY);
  const [executeCreateSalesContract] = useQuery(CREATE_SALES_CONTRACT_MUTATION);

  const getReservation = useCallback(() => {
    executeGetReservation({ id: reservationId })
      .then(({ getReservation: data }) => {
        if (data.status !== 'accepted' || data.isContractCreated) {
          throw new Error('INVALID_RESERVATION');
        }

        setReservation(data);
      })
      .catch(
        AlertModule.onError(
          'Essa reserva não está disponível para criação de contrato.'
        )
      );
  }, [executeGetReservation, reservationId]);

  const handleSubmit = async (variables) => {
    isCreatingRef.current = true;

    executeCreateSalesContract(variables)
      .then(({ createSalesContract: data }) => {
        onClose();
        history.push(`/${project.slug}/contratos/${data.numberId}`);
      })
      .catch(AlertModule.onError('Não foi possível criar o contrato de venda.'))
      .finally(() => {
        isCreatingRef.current = false;
      });
  };

  useNewContractWebSocket({
    reservationId,
    onUpdate() {
      if (!isCreatingRef.current) {
        getReservation(reservationId);
      }
    },
  });

  useEffect(() => {
    if (open && reservationId) {
      getReservation(reservationId);
    }

    if (!open) {
      setReservation(null);
    }
  }, [open, reservationId]);

  return (
    <BaseDialog
      open={open}
      title="Novo contrato"
      onClose={onClose}
      maxWidth="md"
    >
      <Form reservation={reservation} onSubmit={handleSubmit} />
    </BaseDialog>
  );
}

NewContractModal.propTypes = {
  open: PropTypes.bool.isRequired,
  reservationId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

NewContractModal.defaultProps = {
  reservationId: null,
};
