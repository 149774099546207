import { useEffect, useState } from 'react';
import WebSocketController from 'utils/WebSocketController';

const socket = new WebSocketController();

export default function useWebHooks(reservations, onUpdate) {
  const [createdReservationId, setCreatedReservationId] = useState(null);
  const [updatedReservationId, setUpdatedReservationId] = useState(null);
  const [updatedPropertyId, setUpdatedPropertyId] = useState(null);

  useEffect(() => {
    socket.onReservationCreated((reservation) => {
      setCreatedReservationId(reservation._id);
    });

    socket.onReservationUpdated((reservation) => {
      setUpdatedReservationId(reservation._id);
    });

    socket.onPropertyUpdated((property) => {
      setUpdatedPropertyId(property._id);
    });

    socket.onPropertyDeleted((propertyId) => {
      setUpdatedPropertyId(propertyId);
    });

    return () => socket.destroy();
  }, []);

  useEffect(() => {
    if (createdReservationId) {
      onUpdate();
      setCreatedReservationId(null);
    }
  }, [createdReservationId]);

  useEffect(() => {
    if (updatedReservationId) {
      if (
        reservations.find(
          (reservation) => reservation._id === updatedReservationId
        )
      ) {
        onUpdate();
      }

      setUpdatedReservationId(null);
    }
  }, [updatedReservationId]);

  useEffect(() => {
    if (updatedPropertyId) {
      if (
        reservations.find(
          (reservation) => reservation.propertyId === updatedPropertyId
        )
      ) {
        onUpdate();
      }

      setUpdatedPropertyId(null);
    }
  }, [updatedPropertyId]);
}
