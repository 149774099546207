import { useCallback, useEffect, useRef, useState } from 'react';

import AlertModule from 'modules/AlertModule';

import useQuery from 'hooks/useQuery';
import GET_SALES_CONTRACTS_INVOICES_QUERY from 'queries/getSalesContractsInvoicesQuery';

export default function useGetInvoices(variables, search) {
  const [pages, setPages] = useState(1);
  const [invoices, setInvoices] = useState([]);
  const [executeGetInvoices, isLoading] = useQuery(
    GET_SALES_CONTRACTS_INVOICES_QUERY
  );
  const isFirstRender = useRef(true);

  const onResolve = ({ getSalesContractsInvoices: contractsInvoices }) => {
    setPages(contractsInvoices.numberOfPages);
    setInvoices(contractsInvoices.items);
  };

  const onReject = AlertModule.onError(
    'Não foi possível carregar a lista de títulos.'
  );

  const createGetter = (executeQuery) => () =>
    executeQuery({ ...variables, search })
      .then(onResolve)
      .catch(onReject);

  const getInvoicesBySearch = useCallback(
    createGetter(executeGetInvoices.debounced),
    [variables, search]
  );
  const getInvoices = useCallback(createGetter(executeGetInvoices), [
    variables,
    search,
  ]);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    getInvoicesBySearch();
  }, [search]);

  useEffect(() => {
    getInvoices();
  }, [variables]);

  return { invoices, pages, isLoading };
}
