import React from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';

export default function FieldSet({ label, children }) {
  const theme = useTheme();
  return (
    <>
      <Box mb={1}>
        <Typography
          style={{ color: theme.palette.secondary.dark, fontWeight: '450' }}
        >
          {label}
        </Typography>
      </Box>
      {children}
    </>
  );
}
