import { useEffect, useState } from 'react';

import WebSocketController from 'utils/WebSocketController';

const socket = new WebSocketController();

export default (onUpdate) => {
  const [needUpdate, setNeedUpdate] = useState(false);

  useEffect(() => {
    socket.onReturnFileCreated(() => {
      setNeedUpdate(true);
    });

    return () => {
      socket.destroy();
    };
  }, []);

  useEffect(() => {
    if (needUpdate) {
      onUpdate();
      setNeedUpdate(false);
    }
  }, [needUpdate]);
};
