import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import PropertyStatusLabel from 'components/StatusLabel/PropertyStatusLabel';
import Icon from 'components/Icon';

import useQuery from 'hooks/useQuery';

import formatArea from 'utils/formatArea';
import formatPrice from 'utils/formatPrice';

import GET_PROPERTY_QUERY from 'queries/getPropertyQuery';

import ICON_TYPES from '@constants/iconsPaths';
import PROPERTY_STATUS from '@constants/propertyStatus';

import ErrorHandler from 'modules/ErrorHandlerModule';
import InstallmentCalculatorModule from 'modules/InstallmentCalculatorModule';
import { ProjectContext } from 'providers/ProjectProvider';

import Discount from 'components/Discount';
import InformationBlock from 'components/InformationBlock';
import Loading from 'components/Loading';
import useStyles from './styles';

export default function PropertyResume({
  propertyId,
  open,
  onClose,
  onReserve,
}) {
  const { project } = useContext(ProjectContext);
  const [executeGetProperty] = useQuery(GET_PROPERTY_QUERY);
  const [property, setProperty] = useState(null);
  const classes = useStyles();

  const installmentCalculator = useMemo(() => {
    if (!property) return null;
    return new InstallmentCalculatorModule({
      paymentMethod: 'monthly',
      totalPrice: property.price,
      entrance: (property.price * project.entranceFee) / 100,
      annualQuota: project.annualQuota,
    });
  }, [property]);

  const monthlyInstallment = useMemo(() => {
    if (!property) return null;
    return installmentCalculator.calculateMonthlyInstallment(
      Math.max(1, ...project.installments)
    ).value;
  }, [property]);

  const getPropertyError = ErrorHandler(
    'Erro ao abrir resumo do imóvel',
    onClose
  );

  const getProperty = useCallback(() => {
    if (!propertyId) return;

    executeGetProperty({ id: propertyId })
      .then(async (data) => {
        const { getProperty: selectedProperty } = data;
        setProperty(selectedProperty);
      })
      .catch(getPropertyError);
  }, [propertyId]);

  useEffect(() => {
    getProperty();
    return () => {
      setProperty(null);
    };
  }, [getProperty]);

  if (!open) return null;

  return (
    <Box className={classes.dialogContainer}>
      {property ? (
        <Box paddingX={8} paddingY={5}>
          <Box className={classes.close} onClick={onClose}>
            <Icon src={ICON_TYPES.BASE.close} size={20} />
            <Box marginLeft={1}>
              <Typography>Fechar</Typography>
            </Box>
          </Box>
          <Box marginBottom={3} display="flex" justifyContent="space-between">
            <Typography className={classes.propertyUnity}>
              {property.unity}
            </Typography>
            <PropertyStatusLabel status={property.status} />
          </Box>
          <Grid container spacing={3}>
            <InformationBlock fullWidth>
              <Discount
                price={property.price}
                discount={project.discount.cash}
              />
            </InformationBlock>
            <InformationBlock fullWidth>
              <Typography variant="h5" color="textPrimary">
                {formatArea(property.totalArea)} -{' '}
                {formatPrice(property.m2price)} por m²
              </Typography>
            </InformationBlock>
            <InformationBlock fullWidt>
              <Box marginBottom={1}>
                <Typography variant="body2" color="secondary">
                  Ou partir de
                </Typography>
              </Box>
              <Box>
                <Typography className={classes.monthlyInstallmentValue}>
                  {formatPrice(monthlyInstallment)} /mês
                </Typography>
              </Box>
            </InformationBlock>
          </Grid>

          <Box
            display="flex"
            justifyContent="flex-end"
            alignContent="center"
            mt={5}
          >
            {property.status === PROPERTY_STATUS.AVAILABLE && (
              <Button
                style={{ width: 200 }}
                name="delete-mark-button"
                variant="contained"
                color="primary"
                onClick={onReserve}
              >
                Reservar
              </Button>
            )}
          </Box>
        </Box>
      ) : (
        <Loading />
      )}
    </Box>
  );
}

PropertyResume.propTypes = {
  onClose: PropTypes.func.isRequired,
  onReserve: PropTypes.func.isRequired,
  open: PropTypes.bool,
  propertyId: PropTypes.string,
};

PropertyResume.defaultProps = {
  open: false,
  propertyId: '',
};
