import { Box, Container, IconButton, Typography } from '@material-ui/core';
import { ProjectContext } from 'providers/ProjectProvider';
import React, { useContext, useState } from 'react';
import { FaBars } from 'react-icons/fa';
import PropTypes from 'prop-types';
import MobileMenu from './menu';
import useStyles from './styles';

export default function MobileHeader({ onShowUserProfile }) {
  const classes = useStyles();
  const { project } = useContext(ProjectContext);

  const [showMenu, setShowMenu] = useState(false);

  return (
    <Box
      component="header"
      position="relative"
      borderBottom="2px solid rgba(134, 139, 167, 0.1)"
      height={93}
    >
      <Container>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          py={2.5}
        >
          <Box>
            <img src="/assets/images/logo.svg" alt="Quadra A" width="86" />

            <Typography className={classes.projectTitle}>
              {project?.title}
            </Typography>
          </Box>

          <IconButton onClick={() => setShowMenu(!showMenu)}>
            <FaBars />
          </IconButton>
        </Box>
      </Container>

      <MobileMenu
        show={showMenu}
        onClose={() => setShowMenu(false)}
        onShowUserProfile={onShowUserProfile}
      />
    </Box>
  );
}

MobileHeader.propTypes = {
  onShowUserProfile: PropTypes.func.isRequired,
};
