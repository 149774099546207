const GET_VISIBLE_PROPERTY_MARKS_QUERY = `
    query GetVisiblePropertyMarks(
      $projectId: ID!
    ){
      getVisiblePropertyMarks(projectId: $projectId) {
        propertyId,
        propertyStatus,
        posX,
        posY,
      }
    }
  `;

export default GET_VISIBLE_PROPERTY_MARKS_QUERY;
