import useQuery from 'hooks/useQuery';
import AlertModule from 'modules/AlertModule';
import UPDATE_REAL_ESTATE_MUTATION from 'queries/updateRealEstateMutation';
import ErrorHandler from 'modules/ErrorHandlerModule';

export default function useEditRealEstate(onSuccess) {
  const [executeEdit] = useQuery(UPDATE_REAL_ESTATE_MUTATION);

  const onEditError = ErrorHandler('Não foi possível salvar as alterações.');

  const edit = (realEstate) => {
    const data = {
      ...realEstate,
      id: realEstate._id,
      reservationLimit: Number(realEstate.reservationLimit),
      active: Boolean(realEstate.active),
    };

    AlertModule.wait();

    return executeEdit(data)
      .then(AlertModule.onSuccess('Imobiliária atualizada com sucesso!'))
      .then(onSuccess)
      .catch(onEditError);
  };

  return edit;
}
