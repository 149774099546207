import Swal from 'sweetalert2';

const fire = (title, text, icon, options = {}) =>
  Swal.fire({
    title,
    text,
    icon,
    showCancelButton: !!options.cancelButtonText,
    reverseButtons: true,
    ...options,
  });

const AlertModule = {
  wait: () => {
    fire(null, null, null, {
      text: 'Aguarde...',
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
    });
  },
  close: () => {
    Swal.close();
  },
  raw: (html, options = {}) => fire(null, null, null, { ...options, html }),
  info: (message, options = {}) => fire(null, message, 'info', options),
  success: (message, options = {}) =>
    fire('Pronto!', message, 'success', options),
  warning: (message, options = {}) => fire('Ops!', message, 'warning', options),
  error: (message, options = {}) => fire('Ops!', message, 'error', options),
  confirmation: (message, options = {}) =>
    fire(null, message, 'question', {
      showConfirmButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',

      ...options,
    }),
  successWithConfirmation: (title, message, options = {}) =>
    fire(title, message, 'success', {
      showConfirmButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      reverseButtons: true,
      ...options,
    }),
  loading: (message) =>
    fire(null, message, null, {
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    }),
  onSuccess:
    (...args) =>
    () =>
      AlertModule.success(...args),
  onError:
    (...args) =>
    (error) => {
      const message = args[0] || error.message;
      return AlertModule.error(message, ...args);
    },
};

export default AlertModule;
