import React from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';

import RealEstateAgentAutoComplete from 'components/Autocompletes/RealEstateAgentAutoComplete';
import RealEstateAutoComplete from 'components/Autocompletes/RealEstateAutoComplete';

export default function RealEstateAgentInformation({
  reservation,
  setReservation,
}) {
  const theme = useTheme();

  return (
    <Box mb={5}>
      <Box mb={1}>
        <Typography
          className="fw700"
          style={{ color: theme.palette.secondary.dark }}
        >
          Informações do corretor
        </Typography>
      </Box>

      <Box mb={3}>
        <Typography
          variant="body2"
          style={{ color: theme.palette.secondary.main }}
        >
          Imobiliária
        </Typography>

        <RealEstateAutoComplete
          placeholder="Nenhuma imobiliária selecionada"
          projectId={reservation.projectId}
          value={reservation.realEstate}
          onChange={(value) =>
            setReservation({ ...reservation, realEstate: value })
          }
        />
      </Box>

      <Box mb={3}>
        <Typography
          variant="body2"
          style={{ color: theme.palette.secondary.main }}
        >
          Corretor
        </Typography>

        <RealEstateAgentAutoComplete
          placeholder="Nenhum corretor selecionado"
          projectId={reservation.projectId}
          value={reservation.realEstateAgent}
          onChange={(value) =>
            setReservation({
              ...reservation,
              realEstateAgent: value,
            })
          }
        />
      </Box>
    </Box>
  );
}

RealEstateAgentInformation.propTypes = {
  reservation: PropTypes.shape({
    projectId: PropTypes.string,
    realEstateAgent: PropTypes.shape({}),
    realEstate: PropTypes.shape({}),
  }),
  setReservation: PropTypes.func.isRequired,
};

RealEstateAgentInformation.defaultProps = {
  reservation: null,
};
