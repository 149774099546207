import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, TextField, Typography } from '@material-ui/core';
import { BsArrowRight } from 'react-icons/bs';

import AlertModule from 'modules/AlertModule';
import api from 'services/api';

import PropertyAutoComplete from 'components/Autocompletes/PropertyAutoComplete';

import createPropertyMarkQuery from 'queries/createPropertyMarkQuery';

import useThemeClasses from 'theme/useThemeClasses';

import WebSocketController from 'utils/WebSocketController';

const socket = new WebSocketController();

function NewMarkDialog({ projectId, posX, posY, onUpdate, onClose }) {
  const themeClasses = useThemeClasses;
  const buttonClasses = themeClasses.button();

  const [property, setProperty] = useState('');
  const [resetPropertyList, setResetPropertyList] = useState(false);

  useEffect(() => {
    if (resetPropertyList) {
      setResetPropertyList(false);
    }
  }, [resetPropertyList]);

  useEffect(() => {
    const handleMarkCreated = (mark) => {
      setResetPropertyList(true);

      if (property._id === mark.propertyId) {
        setProperty(null);
      }
    };

    const handleMarkDeleted = () => {
      setResetPropertyList(true);
    };

    socket.onPropertyMarkCreated(handleMarkCreated);
    socket.onPropertyMarkDeleted(handleMarkDeleted);

    return () => socket.destroy();
  }, [property]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!property) {
      AlertModule.error('Selecione um imóvel');
      return;
    }

    const variables = {
      propertyId: property._id,
      posX,
      posY,
    };

    AlertModule.wait();

    api
      .call(createPropertyMarkQuery(variables))
      .then((data) => {
        if (data && data.createPropertyMark) {
          AlertModule.success('Imóvel adicionado com sucesso!');
          onUpdate();
          onClose();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Box padding={5}>
      <Typography className="fw700" variant="h5" color="textPrimary">
        Adicionar Imóvel
      </Typography>
      <form onSubmit={handleSubmit}>
        <Box mt={3}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Coord. X"
                value={Number(posX)}
                fullWidth
                required
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Coord. Y"
                value={Number(posY)}
                fullWidth
                required
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              {!resetPropertyList && (
                <PropertyAutoComplete
                  projectId={projectId}
                  value={property || null}
                  onChange={(_, value) => setProperty(value)}
                />
              )}
            </Grid>
          </Grid>

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mt={3}
          >
            <Button
              className={buttonClasses.linkButton}
              onClick={() => onClose()}
              color="secondary"
            >
              Fechar
            </Button>

            <Button
              className={buttonClasses.buttonWithIcon}
              endIcon={<BsArrowRight />}
              variant="contained"
              color="primary"
              type="submit"
              style={{ width: 260 }}
            >
              Adicionar
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
}

export default NewMarkDialog;
