import { useHistory } from 'react-router-dom';
import React, { useCallback, useMemo, useState } from 'react';

import ME_QUERY from 'queries/meQuery';
import useQuery from 'hooks/useQuery';

export const AuthContext = React.createContext(null);

export default function AuthProvider({ children }) {
  const history = useHistory();
  const [auth, setAuth] = useState(null);
  const [authError, setAuthError] = useState(null);
  const [redirectLogin, setRedirectLogin] = useState(false);
  const [execute, isLoading] = useQuery(ME_QUERY);

  const authenticate = useCallback(
    (redirect = false) => {
      setRedirectLogin(redirect);
      execute()
        .then((data) => setAuth(data))
        .catch((error) => {
          setAuthError(error);
          if (redirectLogin) {
            history.push('/login');
          }
        });
    },
    [redirectLogin]
  );

  const value = useMemo(
    () => ({ auth: auth?.me, authError, isLoading, authenticate }),
    [auth, authError, isLoading, authenticate]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
