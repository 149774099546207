import React from 'react';
import { Box, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import ReservationInformation from './ReservationInformation';
import RealEstateAgentInformation from './RealEstateAgentInformation';
import PropertyInformation from './PropertyInformation';
import CostumerInformation from './CostumerInformation';

export default function GeneralTab({ reservation, setReservation, tabIndex }) {
  return (
    <Box hidden={tabIndex !== 0}>
      <Grid container spacing={4}>
        <PropertyInformation reservation={reservation} />

        <Grid item xs={6}>
          <ReservationInformation
            reservation={reservation}
            setReservation={setReservation}
          />
        </Grid>

        <Grid item xs={6}>
          <CostumerInformation
            reservation={reservation}
            setReservation={setReservation}
          />
          <RealEstateAgentInformation
            reservation={reservation}
            setReservation={setReservation}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

GeneralTab.propTypes = {
  reservation: PropTypes.shape({}),
  setReservation: PropTypes.func.isRequired,
  tabIndex: PropTypes.number.isRequired,
};

GeneralTab.defaultProps = {
  reservation: null,
};
