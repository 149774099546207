/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useContext, useState } from 'react';

import { ProjectContext } from 'providers/ProjectProvider';
import useGetReturnFiles from './hooks/useGetReturnFiles';
import useWebHooks from './hooks/useWebHooks';

const ReturnFilesListContext = createContext();

export default function ReturnFilesListProvider({ children }) {
  const { project } = useContext(ProjectContext);
  const [search, setSearch] = useState('');
  const [variables, setVariables] = useState({
    projectId: project._id,
    page: 0,
    changedAt: Date.now(),
  });
  const { returnFiles, pages, isLoading } = useGetReturnFiles(
    variables,
    search
  );

  const setPage = (value) => setVariables({ ...variables, page: value });

  const updateList = () =>
    setVariables({ ...variables, changedAt: Date.now() });

  useWebHooks(updateList);

  return (
    <ReturnFilesListContext.Provider
      value={{
        returnFiles,
        pages,
        isLoading,
        search,
        variables,
        updateList,
        setPage,
        setSearch,
      }}
    >
      {children}
    </ReturnFilesListContext.Provider>
  );
}

export function useReturnFilesListContext() {
  return useContext(ReturnFilesListContext);
}
