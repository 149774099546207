import React, { useContext, useMemo } from 'react';
import { Typography } from '@material-ui/core';

import { AuthContext } from 'providers/AuthProvider';

import SYSTEM_ACCESS_ENTITY_TYPES from '@constants/systemAccess/entityTypes';

import PropertyStatusLabel from 'components/StatusLabel/PropertyStatusLabel';
import PropertyListActions from '../components/PropertyListActions';

export default function useColumns({ onEdit, onDelete }) {
  const { auth } = useContext(AuthContext);

  const column4 = useMemo(() => {
    const isUser = auth.access.entityType === SYSTEM_ACCESS_ENTITY_TYPES.USER;

    if (!auth || !isUser) {
      return {
        title: 'Dimensões',
        field: 'terrainDimension',
      };
    }

    return {
      title: 'Observação',
      field: 'observation',
    };
  }, [auth]);

  return [
    {
      title: 'Unidade',
      field: 'unity',
      render: (rowData) => (
        <Typography color="primary">{rowData.unity}</Typography>
      ),
    },

    {
      title: 'Situação',
      field: 'status',
      render: (rowData) => <PropertyStatusLabel status={rowData.status} />,
    },

    {
      title: 'Preço',
      field: 'price',
      render: (rowData) =>
        Number(rowData.price).toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        }),
    },

    column4,

    {
      title: '',
      field: '_actions',
      align: 'center',
      fit: true,
      render: (rowData) => (
        <PropertyListActions
          data={rowData}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      ),
    },
  ];
}
