import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Box, Container, useMediaQuery, useTheme } from '@material-ui/core';

import SalesContractsListProvider from 'providers/ListProviders/SalesContractsListProvider';
import { ProjectContext } from 'providers/ProjectProvider';
import AlertModule from 'modules/AlertModule';

import ProjectsLink from 'components/ProjectsLink';
import SalesContractsList from 'components/Lists/SalesContractsList';
import EditCustomerDialog from 'components/Dialogs/EditDialogs/EditCustomerDialog';
import ReadNewRemittanceDialog from 'components/Dialogs/ReadDialogs/ReadNewRemittanceDialog';
import ChangeSalesContractStatusDialog from 'components/Dialogs/EditDialogs/ChangeSalesContractStatusDialog';

import GET_RESERVATIONS_WITHOUT_CONTRACT_QUERY from 'queries/getReservationsWithoutContractQuery';
import GET_EMITTED_SALES_CONTRACT_INVOICES_QUERY from 'queries/getEmittedSalesContractInvoicesQuery';
import useQuery from 'hooks/useQuery';

import SalesContractsListFilters from 'components/ListFilters/SalesContractsListFilters';
import SalesContractsZeroState from 'components/ZeroState/SalesContractsZeroState';
import SalesContractsPopup from './components/SalesContractsPopup';
import { useStyles } from './styles';
import NewReservationModal from './NewReservationModal';
import NewContractModal from './NewContractModal';
import validateCostumerData from './helpers/validateCostumerData';
import useSalesContractsWebsocket from './hooks/useSalesContractsWebsocket';

function SalesContracts() {
  const classes = useStyles();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));

  const { project } = useContext(ProjectContext);
  const [reservationId, setReservationId] = useState(null);
  const [customerId, setCustomerId] = useState(null);

  const [openEditCustomerDialog, setOpenEditCustomerDialog] = useState(false);
  const [showNewReservationModal, setShowNewReservationModal] = useState(false);
  const [showNewContractModal, setShowNewContractModal] = useState(false);
  const [reservationsWithoutContract, setReservationsWithoutContract] =
    useState([]);
  const [hasEmittedInvoices, setHasEmittedInvoices] = useState(false);

  const [newRemittance, setNewRemittance] = useState(null);
  const [editSalesContractStatusData, setEditSalesContractStatusData] =
    useState({
      openDialog: false,
      contractNumberId: null,
      status: null,
    });
  const [executeGetEmittedInvoices] = useQuery(
    GET_EMITTED_SALES_CONTRACT_INVOICES_QUERY
  );
  const [executeGetReservationsWithoutContract] = useQuery(
    GET_RESERVATIONS_WITHOUT_CONTRACT_QUERY
  );

  const variables = useMemo(
    () => ({
      projectId: project._id,
    }),
    []
  );

  const getEmittedInvoices = () => {
    executeGetEmittedInvoices(variables).then(
      ({ getEmittedSalesContractInvoices: emittedInvoices }) => {
        setHasEmittedInvoices(emittedInvoices.length > 0);
      }
    );
  };

  const getReservationsWithoutContract = () => {
    executeGetReservationsWithoutContract(variables)
      .then(({ getReservationsWithoutContract: reservations }) => {
        setReservationsWithoutContract(reservations);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const openNewContractModal = (selectedReservationId) => {
    setShowNewReservationModal(false);
    setReservationId(selectedReservationId);
    setShowNewContractModal(true);
  };

  const handleSelect = (reservation) => {
    const { customer } = reservation;

    if (!validateCostumerData(customer)) {
      AlertModule.confirmation(
        'Os dados do cliente estão incompletos, deseja preencher agora?'
      ).then((result) => {
        if (result.isConfirmed) {
          setCustomerId(customer._id);
          setReservationId(reservation._id);
          setOpenEditCustomerDialog(true);
        } else {
          openNewContractModal(reservation._id);
        }
      });
    } else {
      openNewContractModal(reservation._id);
    }
  };

  useSalesContractsWebsocket({
    onRemittanceCreated: getEmittedInvoices,
    onInvoiceEmitted: getEmittedInvoices,
    onReservationAccepted: getReservationsWithoutContract,
  });

  const handleEdit = (contract) => {
    setEditSalesContractStatusData({
      openDialog: true,
      contractNumberId: contract.numberId,
      status: contract.status,
    });
  };

  const handleCloseEditStatusDialog = () => {
    setEditSalesContractStatusData({
      openDialog: false,
      contractNumberId: null,
      status: null,
    });
  };

  useEffect(() => {
    getReservationsWithoutContract(project._id);
    getEmittedInvoices();
  }, []);

  if (!project) return null;

  return (
    <Container className={classes.container}>
      <Box
        display={!isSm ? 'flex' : 'none'}
        justifyContent="space-between"
        alignItems="center"
        mb={5}
        minHeight={40}
      >
        <ProjectsLink mb={0} />
      </Box>

      <SalesContractsListProvider>
        <SalesContractsListFilters />
        <SalesContractsZeroState>
          <SalesContractsList
            hasDocumentTemplate={project?.hasDocumentTemplate}
            projectSlug={project?.slug}
            onEdit={handleEdit}
          />
        </SalesContractsZeroState>
      </SalesContractsListProvider>

      <SalesContractsPopup
        hideNewReservationPopup={reservationsWithoutContract.length === 0}
        hideRemittancePopup={!hasEmittedInvoices}
        openNewReservationModal={() => setShowNewReservationModal(true)}
        onCreateRemittance={setNewRemittance}
      />
      <ReadNewRemittanceDialog
        open={Boolean(newRemittance)}
        projectId={project._id}
        remittance={newRemittance}
        onClose={() => setNewRemittance(null)}
      />

      <NewReservationModal
        open={showNewReservationModal}
        data={reservationsWithoutContract}
        projectId={project._id}
        onSelect={handleSelect}
        onClose={() => setShowNewReservationModal(false)}
      />

      <NewContractModal
        open={showNewContractModal}
        reservationId={reservationId}
        onClose={() => setShowNewContractModal(false)}
      />

      <EditCustomerDialog
        open={openEditCustomerDialog}
        onClose={() => {
          setOpenEditCustomerDialog(false);
          setShowNewContractModal(true);
        }}
        customerId={customerId}
        origin="contract"
      />

      <ChangeSalesContractStatusDialog
        open={editSalesContractStatusData.openDialog}
        contractNumberId={editSalesContractStatusData.contractNumberId}
        defaultStatus={editSalesContractStatusData.status}
        onClose={handleCloseEditStatusDialog}
      />
    </Container>
  );
}

export default SalesContracts;
