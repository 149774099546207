const validateInputs = (property) => {
  const checklist = [
    {
      step: 0,
      message: 'O campo Unidade é obrigatório.',
      validate: () => property.unity,
    },
    {
      step: 0,
      message: 'O campo Preço por m² é obrigatório.',
      validate: () => property.m2price,
    },
    {
      step: 0,
      message: 'Preço por m² inválido.',
      validate: () =>
        !Number.isNaN(property.m2price) && Number(property.m2price) > 0,
    },
    {
      step: 0,
      message: 'O campo Área total é obrigatório.',
      validate: () => property.totalArea,
    },
    {
      step: 0,
      message: 'Área total inválida.',
      validate: () =>
        !Number.isNaN(property.totalArea) && Number(property.totalArea) > 0,
    },
    {
      step: 0,
      message: 'Área privativa inválida.',
      validate: () =>
        !property.privateArea ||
        (!Number.isNaN(property.privateArea) &&
          Number(property.privateArea) > 0),
    },
  ];

  const validate = checklist.reduce((prev, item) => {
    if (prev) return prev;
    if (!item.validate()) return item;
    return null;
  }, null);

  return validate ? { success: false, ...validate } : { success: true };
};

export default validateInputs;
