import WebSocketController from 'utils/WebSocketController';
import { useEffect, useRef, useState } from 'react';
import reservationStatus from '@constants/reservations/status';

const socket = new WebSocketController();

export default ({
  onRemittanceCreated,
  onInvoiceEmitted,
  onReservationAccepted,
}) => {
  const isMountedRef = useRef(false);

  const [remittanceCreated, setRemittanceCreated] = useState(false);
  const [newEmittedInvoices, setNewEmittedInvoices] = useState(false);

  useEffect(() => {
    isMountedRef.current = true;

    socket.onRemittanceCreated(() => {
      setRemittanceCreated(true);
    });

    socket.onInvoiceEmitted(() => {
      setNewEmittedInvoices(true);
    });

    socket.onReservationUpdated((reservation) => {
      if (reservation.status === reservationStatus.ACCEPTED) {
        onReservationAccepted();
      }
    });

    return () => {
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (remittanceCreated) {
      onRemittanceCreated();
      setRemittanceCreated(false);
    }
  }, [remittanceCreated, onRemittanceCreated]);

  useEffect(() => {
    if (newEmittedInvoices) {
      onInvoiceEmitted();
      setNewEmittedInvoices(false);
    }
  }, [newEmittedInvoices, onInvoiceEmitted]);

  return null;
};
