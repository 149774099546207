const propertyStatusTexts = {
  available: 'Disponível',
  unavailable: 'Indisponível',
  reserved: 'Reservado',
  sold: 'Vendido',
};

const reservationStatusTexts = {
  pending: 'Pendente',
  accepted: 'Aprovado',
  rejected: 'Rejeitado',
  expired: 'Expirado',
};

const inviteStatusTexts = {
  pending: 'Pendente',
  accepted: 'Aceito',
  rejected: 'Recusado',
  expired: 'Expirado',
  canceled: 'Cancelado',
  error: 'Falhou',
};

const salesContractStatusTexts = {
  pending: 'Assinatura pendente',
  in_progress: 'Em andamento',
  canceled: 'Cancelado',
  suspended: 'Distratado',
};

const returnFileStatusTexts = {
  pending: 'Processando',
  processed: 'Processado',
  failed: 'Falha no envio',
};

const occurrenceTypesStatusTexts = {
  paid: 'Pago',
  registered: 'Registrado',
  failed: 'Falha no registro',
  error: 'Erro',
};
const salesContractInvoiceStatusTexts = {
  pending: 'Em andamento',
  paid: 'Pago',
  expired: 'Vencido',
};

export {
  propertyStatusTexts,
  reservationStatusTexts,
  inviteStatusTexts,
  salesContractStatusTexts,
  salesContractInvoiceStatusTexts,
  returnFileStatusTexts,
  occurrenceTypesStatusTexts,
};
