import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import BaseDialog from 'components/Dialogs/BaseDialog';
import useQuery from 'hooks/useQuery';
import AlertModule from 'modules/AlertModule';
import { ProjectContext } from 'providers/ProjectProvider';
import CHANGE_SALES_CONTRACT_STATUS_MUTATION from 'queries/changeSalesContractStatusMutation';
import React, { useContext, useEffect, useState } from 'react';

export default function ChangeSalesContractStatusDialog({
  open,
  contractNumberId,
  defaultStatus,
  onClose,
}) {
  const { project } = useContext(ProjectContext);

  const [executeChangeSalesContractStatus] = useQuery(
    CHANGE_SALES_CONTRACT_STATUS_MUTATION
  );

  const [status, setStatus] = useState(null);

  const changeStatus = () => {
    executeChangeSalesContractStatus({
      projectId: project._id,
      numberId: Number(contractNumberId),
      status,
    })
      .then(async () => {
        await AlertModule.success(
          'A situação do contrato foi alterada com sucesso!'
        );

        onClose();
      })
      .catch(
        AlertModule.onError('Não foi possível alterar a situação do contrato.')
      );
  };

  useEffect(() => {
    if (open) {
      setStatus(defaultStatus);
    } else {
      setStatus('');
    }
  }, [open, defaultStatus]);

  return (
    <BaseDialog
      open={open}
      title="Alterar situação"
      maxWidth="xs"
      onClose={onClose}
    >
      <Box mb={4}>
        <FormControl fullWidth required>
          <InputLabel>Situações</InputLabel>
          <Select value={status} onChange={(e) => setStatus(e.target.value)}>
            <MenuItem value="in_progress">Em andamento</MenuItem>
            <MenuItem value="canceled">Cancelado</MenuItem>
            <MenuItem value="suspended">Distratado</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Box display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          color="primary"
          sx={{ width: 208 }}
          onClick={changeStatus}
        >
          Salvar
        </Button>
      </Box>
    </BaseDialog>
  );
}
