import React from 'react';

import { useRealEstatesListContext } from 'providers/ListProviders/RealEstatesListProvider';

import BaseList from '../BaseList';
import useColumns from './hooks/useColumns';

export default function RealEstatesList({ onRowClick }) {
  const { variables, realEstates, pages, setPage } =
    useRealEstatesListContext();

  const columns = useColumns();

  return (
    <BaseList
      columns={columns}
      data={realEstates}
      currentPage={variables.page}
      pages={pages}
      onPagination={setPage}
      onRowClick={onRowClick}
      changedAt={variables.changedAt}
    />
  );
}
