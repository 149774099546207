/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  DialogActions,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import Swal from 'sweetalert2';
import BaseDialog from 'components/Dialogs/BaseDialog';
import api from 'services/api';
import getPropertyQuery from 'queries/getPropertyQuery';
import CurrencyTextField from 'components/TextFields/CurrencyTextField';
import generateObjectChecksum from 'utils/generateObjectChecksum';
import NumberTextField from 'components/TextFields/NumberTextField';
import swalFire from 'utils/swalFire';
import WebSocketController from 'utils/WebSocketController';
import formatPrice from 'utils/formatPrice';
import { useHistory } from 'react-router-dom';
import handlerError from 'utils/handleError';
import PropTypes from 'prop-types';
import UPDATE_PROPERTY_MUTATION from 'queries/updatePropertyQuery';
import AlertModule from 'modules/AlertModule';
import validateInputs from './helpers/validate-inputs';

const socket = new WebSocketController();

function EditPropertyDialog({ open, propertyId, onClose }) {
  const history = useHistory();

  const isEditing = useRef(false);

  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);

  const [property, setProperty] = useState(null);
  const [propertyChecksum, setPropertyChecksum] = useState(null);

  const [updatedPropertyId, setUpdatedPropertyId] = useState(null);
  const [deletedPropertyId, setDeletedPropertyId] = useState(null);

  const getProperty = (propertyIdParams) => {
    setLoading(true);

    if (!propertyIdParams) return;

    api
      .call(getPropertyQuery, { id: propertyIdParams })
      .then(({ getProperty: data }) => {
        setProperty(data);
        setPropertyChecksum(generateObjectChecksum(data));
        setLoading(false);
      })
      .catch((err) => {
        onClose();

        handlerError(
          {
            NOT_AUTHENTICATED: () => {
              history.push('/login');
            },
            DEFAULT: () => {
              swalFire('', 'Não foi possível encontrar o imóvel.', 'error');
            },
          },
          err.message
        );
      });
  };

  const handleClose = () => {
    const checksum = generateObjectChecksum(property);

    if (checksum !== propertyChecksum) {
      AlertModule.confirmation(
        'Deseja realmente fechar sem salvar as alterações da propriedade?'
      ).then((result) => {
        if (result.isConfirmed) {
          onClose();
        }
      });
    } else {
      onClose();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const validate = validateInputs(property);

    if (!validate.success) {
      setStep(validate.step);
      Swal.fire('', validate.message, 'error');
      return;
    }

    Swal.fire({
      text: 'Aguarde...',
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
    });

    const variables = {
      ...property,
      id: property._id,
      m2price: property.m2price && Number(property.m2price),
      totalArea: property.totalArea && Number(property.totalArea),
      privateArea: property.privateArea && Number(property.privateArea),
    };

    isEditing.current = true;

    api
      .call(UPDATE_PROPERTY_MUTATION, variables)
      .then(() => {
        swalFire('Pronto!', 'Imóvel atualizado com sucesso!', 'success').then(
          () => {
            onClose();
          }
        );
      })
      .catch((err) => {
        isEditing.current = false;

        handlerError(
          {
            PROPERTY_UNITY_IN_USE: () => {
              Swal.fire('Ops!', 'Já existe um imóvel com esse nome.', 'error');
            },
            DEFAULT: () => {
              Swal.fire(
                'Ops!',
                'Não foi possível salvar as alterações.',
                'error'
              );
            },
          },
          err.message
        );
      });
  };

  useEffect(() => {
    if (open) {
      getProperty(propertyId);
    } else {
      setStep(0);
      setProperty(null);
      setPropertyChecksum(null);
    }
  }, [open]);

  useEffect(() => {
    socket.onPropertyUpdated((data) => {
      setUpdatedPropertyId(data._id);
    });

    socket.onPropertyDeleted((data) => {
      setDeletedPropertyId(data);
    });

    return () => socket.destroy();
  }, []);

  useEffect(() => {
    if (updatedPropertyId) {
      if (property?._id === updatedPropertyId && !isEditing) {
        Swal.fire({
          title: 'Aviso',
          text: 'Houve mudanças nesse imóvel, deseja recarregar as informações?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            getProperty();
            setStep(0);
          }
        });
      }

      setUpdatedPropertyId(null);
    }
  }, [property, updatedPropertyId, isEditing]);

  useEffect(() => {
    if (deletedPropertyId) {
      if (property?._id === deletedPropertyId) {
        Swal.fire('Ops!', 'Esse imóvel não está mais disponível!', 'error');
        onClose();
      }

      setDeletedPropertyId(null);
    }
  }, [property, deletedPropertyId]);

  return (
    <BaseDialog
      loading={loading}
      open={open}
      title="Imóvel"
      maxWidth="sm"
      onClose={handleClose}
    >
      {property && (
        <form onSubmit={handleSubmit}>
          <Box mb={5}>
            <Tabs
              variant="fullWidth"
              value={step}
              onChange={(_, value) => setStep(value)}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tab label="1. Geral" style={{ marginRight: 24 }} />
              <Tab label="2. Medidas" />
            </Tabs>
          </Box>

          {step === 0 && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  autoFocus
                  margin="dense"
                  name="ep-unity"
                  label="Unidade"
                  type="text"
                  value={property.unity || ''}
                  onChange={(e) =>
                    setProperty({ ...property, unity: e.target.value })
                  }
                  InputLabelProps={{ required: true }}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  multiline
                  margin="dense"
                  name="ep-description"
                  label="Descrição"
                  type="text"
                  value={property.description || ''}
                  onChange={(e) =>
                    setProperty({ ...property, description: e.target.value })
                  }
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  multiline
                  margin="dense"
                  name="ep-observation"
                  label="Observação"
                  type="text"
                  value={property.observation || ''}
                  onChange={(e) =>
                    setProperty({ ...property, observation: e.target.value })
                  }
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <FormControl margin="dense" fullWidth>
                  <InputLabel id="state-select-label" required>
                    Situação
                  </InputLabel>
                  <Select
                    labelId="state-select-label"
                    name="ep-status"
                    value={property.status || ''}
                    onChange={(e) =>
                      setProperty({ ...property, status: e.target.value })
                    }
                  >
                    <MenuItem value="unavailable">Indisponível</MenuItem>
                    <MenuItem value="available">Disponível</MenuItem>
                    <MenuItem value="reserved" style={{ display: 'none' }}>
                      Reservado
                    </MenuItem>
                    <MenuItem value="sold" style={{ display: 'none' }}>
                      Vendido
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <CurrencyTextField
                  margin="dense"
                  name="ep-price"
                  label="Preço do m²"
                  value={property.m2price || ''}
                  onChange={(_, value) =>
                    setProperty({ ...property, m2price: value })
                  }
                  InputLabelProps={{ required: true }}
                  fullWidth
                />
                <Typography variant="caption" color="primary">
                  {'Total: '}
                  {formatPrice(property.m2price * property.totalArea) ||
                    'R$ 0,00'}
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  margin="dense"
                  name="ep-terrainDimension"
                  label="Dimensões do Terreno"
                  type="text"
                  value={property.terrainDimension || ''}
                  onChange={(e) =>
                    setProperty({
                      ...property,
                      terrainDimension: e.target.value,
                    })
                  }
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <NumberTextField
                  margin="dense"
                  name="ep-totalArea"
                  label="Área Total (m²)"
                  value={property.totalArea || ''}
                  onChange={(_, value) =>
                    setProperty({
                      ...property,
                      totalArea: value,
                    })
                  }
                  InputLabelProps={{ required: true }}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <NumberTextField
                  margin="dense"
                  name="ep-privateArea"
                  label="Área Privativa (m²)"
                  value={property.privateArea || ''}
                  onChange={(_, value) =>
                    setProperty({ ...property, privateArea: value })
                  }
                  fullWidth
                />
              </Grid>
            </Grid>
          )}

          {step === 1 && (
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextField
                  margin="dense"
                  name="ep-comparisonFront"
                  label="Confronto - Frente"
                  type="text"
                  value={property.comparisonFront || ''}
                  onChange={(e) =>
                    setProperty({
                      ...property,
                      comparisonFront: e.target.value,
                    })
                  }
                  fullWidth
                  autoFocus
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  margin="dense"
                  name="ep-comparisonBottom"
                  label="Confronto - Fundo"
                  type="text"
                  value={property.comparisonBottom || ''}
                  onChange={(e) =>
                    setProperty({
                      ...property,
                      comparisonBottom: e.target.value,
                    })
                  }
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  margin="dense"
                  name="ep-comparisonLeft"
                  label="Confronto - Esquerda"
                  type="text"
                  value={property.comparisonLeft || ''}
                  onChange={(e) =>
                    setProperty({
                      ...property,
                      comparisonLeft: e.target.value,
                    })
                  }
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  margin="dense"
                  name="ep-comparisonRight"
                  label="Confronto - Direita"
                  type="text"
                  value={property.comparisonRight || ''}
                  onChange={(e) =>
                    setProperty({
                      ...property,
                      comparisonRight: e.target.value,
                    })
                  }
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  multiline
                  margin="dense"
                  name="cp-comparisonExtra"
                  label="Confronto Adicional"
                  type="text"
                  value={property.comparisonExtra || ''}
                  onChange={(e) =>
                    setProperty({
                      ...property,
                      comparisonExtra: e.target.value,
                    })
                  }
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  margin="dense"
                  name="ep-measureFront"
                  label="Medida - Frente"
                  step={0.001}
                  min={0}
                  value={property.measureFront || ''}
                  onChange={(e) =>
                    setProperty({ ...property, measureFront: e.target.value })
                  }
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  margin="dense"
                  name="ep-measureBottom"
                  label="Medida - Fundo"
                  step={0.001}
                  min={0}
                  value={property.measureBottom || ''}
                  onChange={(e) =>
                    setProperty({
                      ...property,
                      measureBottom: e.target.value,
                    })
                  }
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  margin="dense"
                  name="ep-measureLeft"
                  label="Medida - Esquerda"
                  step={0.001}
                  min={0}
                  value={property.measureLeft || ''}
                  onChange={(e) =>
                    setProperty({ ...property, measureLeft: e.target.value })
                  }
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  margin="dense"
                  name="ep-measureRight"
                  label="Medida - Direita"
                  step={0.001}
                  min={0}
                  value={property.measureRight || ''}
                  onChange={(e) =>
                    setProperty({ ...property, measureRight: e.target.value })
                  }
                  fullWidth
                />
              </Grid>
            </Grid>
          )}

          <DialogActions>
            <Button onClick={handleSubmit} variant="contained" color="primary">
              Salvar
            </Button>
          </DialogActions>
        </form>
      )}
    </BaseDialog>
  );
}

EditPropertyDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  propertyId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

EditPropertyDialog.defaultProps = {
  propertyId: null,
};

export default EditPropertyDialog;
