import React from 'react';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import api from 'services/api';
import useQuery from 'hooks/useQuery';
import Alert from 'modules/AlertModule';
import LOGOUT_QUERY from 'queries/logoutQuery';

function LogoutButton() {
  const history = useHistory();
  const [logout, isLoggingOut] = useQuery(LOGOUT_QUERY);

  function handleLogout() {
    api.resetSocket();
    logout()
      .then(() => history.push('/login'))
      .catch(Alert.onError('Falha ao sair :('));
  }

  return (
    <Button
      variant="outlined"
      onClick={() => handleLogout()}
      disabled={isLoggingOut}
    >
      Sair
    </Button>
  );
}

export default LogoutButton;
