import getDateLabel from './get-date-label';

export default function generateYearlyDueDates(firstMonthlyInstallmentDate) {
  if (firstMonthlyInstallmentDate) {
    const currentMonth = Number(firstMonthlyInstallmentDate.split('-')[1]);
    const currentYear = Number(firstMonthlyInstallmentDate.split('-')[0]);

    const dates = [];

    for (let i = 0; i < 12; i += 1) {
      const month = ((currentMonth + i) % 12) + 1;
      const monthStr = String(month).padStart(2, '0');

      const year = currentYear + Math.floor((currentMonth + i) / 12);

      const value = `${year}-${monthStr}`;
      const label = getDateLabel(value);

      dates.push({
        value,
        label,
      });
    }

    return dates;
  }

  return [];
}
