import React from 'react';
import {
  Checkbox,
  TableCell,
  TableRow as BaseTableRow,
} from '@material-ui/core';
import useStyles from './styles';

function TableRow({
  data,
  onClick,
  canSelect,
  isSelected,
  columns,
  toggleSelection,
}) {
  const classes = useStyles({ isClickable: Boolean(onClick), isSelected });

  const handleClick = () => {
    if (onClick) onClick();
  };

  return (
    <BaseTableRow className={classes.row} onClick={handleClick}>
      {canSelect ? (
        <TableCell align="left" width="1px">
          <Checkbox
            className={classes.checkbox}
            checked={isSelected}
            onClick={(e) => {
              e.stopPropagation();
              toggleSelection(data._id);
            }}
          />
        </TableCell>
      ) : null}

      {columns.map((column) => (
        <TableCell
          key={column.field}
          align={column.align || 'left'}
          width={column.fit ? '1px' : 'auto'}
        >
          {column.render ? column.render(data) : data[column.field]}
        </TableCell>
      ))}
    </BaseTableRow>
  );
}

export default TableRow;
