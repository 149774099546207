export default `
    query getCustomer(
      $id: ID!
    ){
      getCustomer(
        id: $id
      ) {
        _id
        projectId
        name
        cpf
        rg
        maritalStatus
        occupation
        postalCode
        address
        addressNumber
        addressComplement
        district
        city
        state
        email
        phone
        phone2
        birthday
      }
    }
  `;
