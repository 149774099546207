import useQuery from 'hooks/useQuery';
import AlertModule from 'modules/AlertModule';
import GET_INVOICES_SUMMARY_QUERY from 'queries/getInvoicesSummaryQuery';

import { useCallback, useEffect, useRef, useState } from 'react';

export default function useGetSummary(variables, search) {
  const [summaryData, setSummaryData] = useState(null);
  const isFirstRender = useRef(true);
  const [executeGetInvoicesSummaryData] = useQuery(GET_INVOICES_SUMMARY_QUERY);

  const onResolve = ({ getInvoicesSummary: data }) => {
    setSummaryData(data);
  };

  const onReject = AlertModule.onError(
    'Não foi possível carregar o sumário de títulos'
  );

  const createGetter = (executeQuery) => () =>
    executeQuery({ ...variables, search })
      .then(onResolve)
      .catch(onReject);

  const getSummaryBySearch = useCallback(
    createGetter(executeGetInvoicesSummaryData.debounced),
    [variables, search]
  );

  const getSummary = useCallback(createGetter(executeGetInvoicesSummaryData), [
    variables,
    search,
  ]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    getSummaryBySearch();
  }, [search]);

  useEffect(() => {
    getSummary();
  }, [variables]);

  return summaryData;
}
