export const SALES_CONTRACT_INVOICE_TYPES = {
  SINGLE: 'single',
  MONTHLY: 'monthly',
  YEARLY: 'yearly',
};

export default [
  { value: 'single', label: 'Parcela única' },
  { value: 'monthly', label: 'Parcela mensal' },
  { value: 'yearly', label: 'Parcela anual' },
];
