/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useContext, useState } from 'react';

import { ProjectContext } from 'providers/ProjectProvider';

import useWebHooks from './hooks/useWebHooks';
import useGetSalesContracts from './hooks/useGetSalesContracts';

const SalesContractsListContext = createContext();

export default function SalesContractsListProvider({ children }) {
  const { project } = useContext(ProjectContext);
  const [search, setSearch] = useState('');
  const [variables, setVariables] = useState({
    projectId: project._id,
    search,
    page: 0,
    sortOptions: { field: 'createdAt', direction: 'desc' },
    filterOptions: { status: null },
    changedAt: Date.now(),
  });

  const { salesContracts, pages, isLoading } = useGetSalesContracts(
    variables,
    search
  );

  const setPage = (value) => setVariables({ ...variables, page: value });

  const setFilter = (value) => {
    const newFilterOptions = { ...variables.filterOptions, ...value };
    setVariables({ ...variables, page: 0, filterOptions: newFilterOptions });
  };

  const updateList = () => {
    setVariables({ ...variables, changedAt: Date.now() });
  };

  useWebHooks(updateList);

  return (
    <SalesContractsListContext.Provider
      value={{
        salesContracts,
        pages,
        isLoading,
        search,
        variables,
        setFilter,
        setSearch,
        setPage,
      }}
    >
      {children}
    </SalesContractsListContext.Provider>
  );
}

export function useSalesContractsContext() {
  return useContext(SalesContractsListContext);
}
