/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useState, useContext } from 'react';

import { ProjectContext } from 'providers/ProjectProvider';

import useGetInvoices from './hooks/useGetInvoices';
import useWebHooks from './hooks/useWebHooks';
import useGetSummary from './hooks/useGetSummary';

const InvoicesListContext = createContext();

export default function InvoicesListProvider({ children }) {
  const { project } = useContext(ProjectContext);
  const [search, setSearch] = useState('');
  const [variables, setVariables] = useState({
    projectId: project._id,
    page: 0,
    search,
    sortOptions: {
      field: 'dueDate',
      direction: 'asc',
    },
    filterOptions: {
      status: 'all',
      type: null,
      dueDateStart: null,
      dueDateEnd: null,
      paidAtStart: null,
      paidAtEnd: null,
    },
    changedAt: Date.now(),
  });
  const summaryData = useGetSummary(variables, search);
  const { invoices, pages, isLoading } = useGetInvoices(variables, search);

  const setPage = (value) => setVariables({ ...variables, page: value });

  const setFilter = (value) => {
    const newFilterOptions = { ...variables.filterOptions, ...value };
    setVariables({ ...variables, page: 0, filterOptions: newFilterOptions });
  };

  const setDueDateFilter = (value) => {
    setFilter({
      dueDateStart: value.startDate,
      dueDateEnd: value.endDate,
    });
  };

  const setPaymentDateFilter = (value) =>
    setFilter({
      paidAtStart: value.startDate,
      paidAtEnd: value.endDate,
    });

  const setSort = (option) =>
    setVariables({
      ...variables,
      page: 0,
      sortOptions: {
        field: option.value,
        direction: option.reverse ? 'desc' : 'asc',
      },
    });

  const updateList = () =>
    setVariables({ ...variables, changedAt: Date.now() });

  useWebHooks(updateList);

  return (
    <InvoicesListContext.Provider
      value={{
        invoices,
        pages,
        isLoading,
        search,
        variables,
        summaryData,
        updateList,
        setPage,
        setFilter,
        setPaymentDateFilter,
        setDueDateFilter,
        setSort,
        setSearch,
      }}
    >
      {children}
    </InvoicesListContext.Provider>
  );
}

export function useInvoicesListContext() {
  return useContext(InvoicesListContext);
}
