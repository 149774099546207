import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';

export default function PercentField({
  label,
  value,
  onChange,
  InputLabelProps,
  fullWidth,
  required,
}) {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleType = (() => (isFocused ? 'number' : 'text'))();

  const handleValue = (() => {
    const formattedValue = Number(value).toLocaleString('PT-BR');
    const onFocusValue = formattedValue === '0' ? '' : formattedValue;
    if (isFocused) return onFocusValue;
    return `${formattedValue}%`;
  })();

  return (
    <TextField
      required={required}
      fullWidth={fullWidth}
      InputLabelProps={InputLabelProps}
      onChange={onChange}
      label={label}
      type={handleType}
      value={handleValue}
      onFocus={handleFocus}
      onBlur={handleBlur}
    />
  );
}

PercentField.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

PercentField.defaultProps = {
  fullWidth: false,
  required: false,
};
