import React, { useState } from 'react';
import BaseDialog from 'components/Dialogs/BaseDialog';
import MakeReservationBase from './base';

export default function MakeReservationDialog({
  open,
  propertyId,
  onPropertyReserved,
  onClose,
}) {
  const [step, setStep] = useState(0);

  const getTitle = () => {
    switch (step) {
      case 1:
        return 'Qual será a forma de pagamento?';
      case 2:
        return 'Escolha a data de vencimento das parcelas';
      case 3:
        return 'Qual o CPF do cliente?';
      case 4:
        return 'Dados do cliente';
      case 5:
        return 'Verifique se as informações estão corretas';
      default:
        return '';
    }
  };

  const title = getTitle();

  return (
    <BaseDialog open={open} title={title} maxWidth="sm" onClose={onClose}>
      <MakeReservationBase
        open={open}
        propertyId={propertyId}
        onStepChange={(currentStep) => setStep(currentStep)}
        onClose={onClose}
        onPropertyReserved={onPropertyReserved}
      />
    </BaseDialog>
  );
}
