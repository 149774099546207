import { useCallback, useEffect, useRef, useState } from 'react';

import useQuery from 'hooks/useQuery';
import GET_SALES_CONTRACTS_QUERY from 'queries/getSalesContractsQuery';
import AlertModule from 'modules/AlertModule';

export default function useGetSalesContracts(variables, search) {
  const [salesContracts, setSalesContracts] = useState([]);
  const [pages, setPages] = useState(1);
  const isFirstRender = useRef(true);
  const [executeGetSalesContracts, isLoading] = useQuery(
    GET_SALES_CONTRACTS_QUERY
  );

  const onResolve = (data) => {
    setPages(data.getSalesContracts.numberOfPages);
    setSalesContracts(data.getSalesContracts.items);
  };

  const onReject = AlertModule.onError(
    'Não foi possível carregar a lista de contratos'
  );

  const createGetter = (executeQuery) => () =>
    executeQuery({ ...variables, search })
      .then(onResolve)
      .catch(onReject);

  const getSalesContractsBySearch = useCallback(
    createGetter(executeGetSalesContracts.debounced),
    [variables, search]
  );
  const getSalesContracts = useCallback(
    createGetter(executeGetSalesContracts),
    [variables, search]
  );

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    getSalesContractsBySearch();
  }, [search]);

  useEffect(() => {
    getSalesContracts();
  }, [variables]);

  return { salesContracts, pages, isLoading };
}
