const GET_RESERVATION_QUERY = `
  query GetReservation ($id: ID!) {
    getReservation(id: $id) {
      _id
      numberId
      projectId
      propertyId
      property {
        _id
        unity
        description
        m2price
        totalArea
      }
      customerId
      customer {
        _id
        name
        cpf
        rg
      }
      realEstateId
      realEstate {
        _id
        name
        cnpj
        creci
      }
      realEstateAgentId
      realEstateAgent {
        _id
        name
        cpf
        creci
      }
      price
      paymentMethod
      entrance
      entranceFee
      annualQuota
      installments
      installmentOptions
      firstMonthlyInstallmentDate
      firstAnnuallyInstallmentDate
      status
      discount
      expiration
      observation
      isContractCreated
      createdAt
    }
  }
`;

export default GET_RESERVATION_QUERY;
