import { useEffect, useState } from 'react';
import WebSocketController from 'utils/WebSocketController';

const socket = new WebSocketController();

export default function useWebHooks(propertyId, onUpdate) {
  const [updatedPropertyId, setUpdatedPropertyId] = useState(null);

  useEffect(() => {
    socket.onPropertyUpdated((data) => {
      setUpdatedPropertyId(data._id);
    });

    socket.onPropertyDeleted((data) => {
      setUpdatedPropertyId(data);
    });

    socket.onReservationCreated((reservation) => {
      setUpdatedPropertyId(reservation.propertyId);
    });

    return () => socket.destroy();
  }, []);

  useEffect(() => {
    if (updatedPropertyId) {
      if (propertyId === updatedPropertyId) {
        onUpdate(propertyId);
      }

      setUpdatedPropertyId(null);
    }
  }, [updatedPropertyId]);
}
