import React, { useContext, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import NotFound from 'components/NotFound';
import { AuthContext } from 'providers/AuthProvider';
import ProjectProvider from 'providers/ProjectProvider';
import Sales from './Sales';
import Administration from './Administration';

function ProjectMain() {
  const { projectSlug } = useParams();
  const location = useLocation();

  const { auth, authenticate } = useContext(AuthContext);
  const environment = auth?.environment;

  const views = {
    administration: <Administration projectSlug={projectSlug} />,
    sales: <Sales projectSlug={projectSlug} />,
  };

  useEffect(() => {
    authenticate(true);
  }, [location.key]);

  return (
    <ProjectProvider slug={projectSlug}>
      {views[environment] || <NotFound />}
    </ProjectProvider>
  );
}

export default ProjectMain;
