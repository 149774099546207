/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Link,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import Header from 'components/Header';
import UserProfile from 'components/UserProfile';
import recoverPasswordQuery from 'queries/recoverPasswordQuery';
import updateUserQuery from 'queries/updateUserQuery';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import api from 'services/api';
import ICON_TYPES from '@constants/iconsPaths';
import Swal from 'sweetalert2';
import useThemeClasses from 'theme/useThemeClasses';
import { AuthContext } from 'providers/AuthProvider';
import handlerError from 'utils/handleError';
import Icon from 'components/Icon';

export default function Account() {
  const history = useHistory();

  const themeClasses = useThemeClasses;
  const linkClasses = themeClasses.link();

  const theme = useTheme();
  const mediaQueryDownSm = useMediaQuery(theme.breakpoints.down('sm'));

  const [tabIndex, setTabIndex] = useState(0);

  const { auth, authenticate } = useContext(AuthContext);

  const access = auth?.access;

  const entity = useMemo(() => {
    if (!auth) return null;

    return {
      ...(auth.user || auth.realEstate || auth.realEstateAgent),
      username: auth.access.username,
      entityType: auth.access.entityType,
    };
  }, [auth]);

  const [userProfileAnchor, setUserProfileAnchor] = useState(null);

  useEffect(() => {
    authenticate(true);
  }, []);

  function handleBack(e) {
    e?.preventDefault();

    if (access.project) {
      history.push(`/${access.project.slug}`);
    } else {
      history.push('/');
    }
  }
  // eslint-disable-next-line
  if (!access) return <></>;

  return (
    <Box component="main">
      <Box mb={2.5}>
        <Header onShowUserProfile={(elem) => setUserProfileAnchor(elem)} />

        <UserProfile
          access={access}
          open={Boolean(userProfileAnchor)}
          anchor={userProfileAnchor}
          onClose={() => setUserProfileAnchor(null)}
        />
      </Box>

      <Container>
        <Box my={5}>
          <Box display="flex" alignItens="center">
            <Icon src={ICON_TYPES.BASE.back} />

            <Link
              href="# "
              className={linkClasses.tableLink}
              onClick={() => handleBack()}
            >
              Voltar
            </Link>
          </Box>
        </Box>

        <Grid container spacing={5}>
          <Grid item xs={12} md={2}>
            <Box mb={2.5}>
              <Typography className="fw700" variant="h4" color="textPrimary">
                Minha Conta
              </Typography>
            </Box>

            <Divider />

            <Box display="flex" flexDirection="column" my={2.5}>
              <Box mb={2.5}>
                <Link
                  className={
                    linkClasses.teamPaperLink +
                    (tabIndex === 0 ? ' active' : '')
                  }
                  href="# "
                  onClick={(e) => {
                    e.preventDefault();
                    setTabIndex(0);
                  }}
                >
                  <Typography className="fw700" variant="h6">
                    Perfil
                  </Typography>
                </Link>
              </Box>

              <Box mb={2.5}>
                <Link
                  className={
                    linkClasses.teamPaperLink +
                    (tabIndex === 1 ? ' active' : '')
                  }
                  href="# "
                  onClick={(e) => {
                    e.preventDefault();
                    setTabIndex(1);
                  }}
                >
                  <Typography className="fw700" variant="h6">
                    Segurança
                  </Typography>
                </Link>
              </Box>
            </Box>

            <Divider />
          </Grid>

          {!mediaQueryDownSm && (
            <Grid item md="auto">
              <Box height="100%" borderLeft="1px solid rgba(0, 0, 0, 0.12)" />
            </Grid>
          )}

          <Grid item xs={12} md>
            <Box minHeight={400}>
              {tabIndex === 0 && (
                <>
                  {access.entityType === 'User' && (
                    <UserForm user={entity} onUpdate={() => authenticate()} />
                  )}
                  {access.entityType === 'RealEstate' && (
                    <RealEstateForm realEstate={entity} />
                  )}
                  {access.entityType === 'RealEstateAgent' && (
                    <RealEstateAgentForm realEstateAgent={entity} />
                  )}
                </>
              )}

              {tabIndex === 1 && (
                <ChangePasswordForm
                  entity={entity}
                  projectSlug={access.project?.slug}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

function UserForm({ onUpdate, ...props }) {
  const history = useHistory();

  const [user, setUser] = useState(null);

  useEffect(() => {
    setUser(props.user);
  }, [props.user]);

  function handleSubmit(e) {
    e.preventDefault();

    const params = {
      data: {
        name: user.name,
      },
    };

    api
      .call(updateUserQuery(params))
      .then((data) => {
        if (data && data.updateUser) {
          Swal.fire(
            'Pronto!',
            'Informações foram salvas com sucesso!',
            'success'
          );
          onUpdate();
        }
      })
      .catch((error) => {
        handlerError(
          {
            NOT_AUTHENTICATED: () => {
              history.push('/login');
            },
            INVALID_ENVIRONMENT: () => {
              Swal.fire(
                'Ops!',
                'Você não está autenticado como usuário da coordenação.',
                'error'
              );
            },
            DEFAULT: () => {
              Swal.fire(
                'Ops!',
                'Não foi possível salvar as informações.',
                'error'
              );
            },
          },
          error.message
        );
      });
  }
  // eslint-disable-next-line
  if (!user) return <></>;

  return (
    <form onSubmit={handleSubmit}>
      <Box mb={2.5}>
        <Typography className="fw700" variant="h5">
          Perfil
        </Typography>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            label="Nome completo"
            InputLabelProps={{ shrink: true }}
            value={user.name || ''}
            onChange={(e) => setUser({ ...user, name: e.target.value })}
            fullWidth
            required
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="E-mail"
            InputProps={{ disableUnderline: true }}
            InputLabelProps={{ shrink: true }}
            value={user.username || ''}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <Button variant="contained" color="primary" type="submit">
            Salvar
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

function RealEstateForm(props) {
  const [realState, setRealState] = useState(null);
  const { realEstate } = props;
  useEffect(() => {
    setRealState(realEstate);
  }, [realEstate]);

  // eslint-disable-next-line
  if (!realState) return <></>;

  return (
    <form>
      <Box mb={2.5}>
        <Typography className="fw700" variant="h5">
          Perfil
        </Typography>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            label="Razão Social / Nome Fantasia"
            InputLabelProps={{ shrink: true }}
            InputProps={{ disableUnderline: true }}
            value={realState.name || ''}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="CNPJ"
            InputLabelProps={{ shrink: true }}
            InputProps={{ disableUnderline: true }}
            value={realState.cnpj || ''}
            placeholder="000.000.000-00"
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="E-mail"
            InputLabelProps={{ shrink: true }}
            InputProps={{ disableUnderline: true }}
            value={realState.username || ''}
            fullWidth
          />
        </Grid>
      </Grid>
    </form>
  );
}

function RealEstateAgentForm(props) {
  const [realStateAgent, setRealStateAgent] = useState(null);
  const { realEstateAgent } = props;
  useEffect(() => {
    setRealStateAgent(realEstateAgent);
  }, [realEstateAgent]);

  // eslint-disable-next-line
  if (!realStateAgent) return <></>;

  return (
    <form>
      <Box mb={2.5}>
        <Typography className="fw700" variant="h5">
          Perfil
        </Typography>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            label="Nome completo"
            InputLabelProps={{ shrink: true }}
            InputProps={{ disableUnderline: true }}
            value={realStateAgent.name || ''}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="CPF"
            InputLabelProps={{ shrink: true }}
            InputProps={{ disableUnderline: true }}
            value={realStateAgent.cpf || ''}
            placeholder="000.000.000-00"
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="E-mail"
            InputLabelProps={{ shrink: true }}
            InputProps={{ disableUnderline: true }}
            value={realStateAgent.username || ''}
            fullWidth
          />
        </Grid>
      </Grid>
    </form>
  );
}

function ChangePasswordForm({ entity, projectSlug }) {
  function handleResetPassword() {
    let environment = 'administration';

    if (['RealEstate', 'RealEstateAgent'].includes(entity.entityType)) {
      environment = 'sales';
    }

    const params = {
      username: entity.username,
      environment,
      projectSlug,
    };

    api
      .call(recoverPasswordQuery(params))
      .then((data) => {
        if (data && data.recoverPassword) {
          Swal.fire(
            'Pronto!',
            'O e-mail de recuperação foi enviado com sucesso!',
            'success'
          );
        }
      })
      .catch((error) => {
        handlerError(
          {
            DEFAULT: () => {
              Swal.fire(
                'Ops!',
                'Não foi possível enviar a solicitação.',
                'error'
              );
            },
          },
          error.message
        );
      });
  }

  return (
    <form>
      <Box mb={2.5}>
        <Typography className="fw700" variant="h5">
          Segurança
        </Typography>
      </Box>

      <Box mb={2.5}>
        <Typography>
          Ao clicar em <strong>Resetar Senha</strong> será enviado um e-mail de
          recuperação de senha com o link para você alterar a senha.
        </Typography>
        <Typography />
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleResetPassword()}
          >
            Resetar Senha
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
