import React, { useContext, useState } from 'react';
import { Box, Grid, Typography, TextField } from '@material-ui/core';

import ICON_TYPES from '@constants/iconsPaths';
import Icon from 'components/Icon';
import SortSelect from 'components/SortSelect';
import useThemeClasses from 'theme/useThemeClasses';
import { AuthContext } from 'providers/AuthProvider';
import ProjectsZeroState from 'components/ZeroState/ProjectsZeroState';

import BaseList from '../BaseList';
import sortOptions from './utils/sortOptions';
import useColumns from './utils/useColumns';
import getFilteredProjects from './utils/getFilteredProjects';

export default function ProjectsList({ onSelect }) {
  const themeClasses = useThemeClasses;
  const textFieldClasses = themeClasses.textField();

  const { auth } = useContext(AuthContext);
  const columns = useColumns({ auth });

  const [search, setSearch] = useState('');
  const [sort, setSort] = useState(sortOptions[2]);

  const data = getFilteredProjects({ auth, search, sort });

  const handleRowClick = (rowData) => {
    onSelect(rowData);
  };

  return (
    <>
      <Box mb={5}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs md>
            <Typography className="fw700" variant="h5" color="textPrimary">
              Empreendimentos
            </Typography>
          </Grid>

          <Grid item xs={12} md={5}>
            <Box position="relative">
              <TextField
                className={textFieldClasses.textFieldOutlined1}
                variant="outlined"
                placeholder="Pesquisar"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                fullWidth
              />

              <Box position="absolute" top={10} right={36}>
                <Icon src={ICON_TYPES.BASE.search} />
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md="auto">
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              <SortSelect
                value={sort}
                options={sortOptions}
                onChange={(option) => setSort(option)}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <ProjectsZeroState length={data.length} search={search}>
        <BaseList
          columns={columns}
          data={data}
          currentPage={0}
          pages={0}
          onPagination={() => {}}
          onRowClick={handleRowClick}
        />
      </ProjectsZeroState>
    </>
  );
}
