import React from 'react';

function ParcelFilterIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      fill="none"
      viewBox="0 0 16 17"
    >
      <path
        stroke="#3D4657"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M11.334 8.5H4.667M11.334 5.833H4.667M14 8.5v5.333a.667.667 0 01-.667.667H2.667A.667.667 0 012 13.833V8.5M11.334 11.166H4.667M11.334 8.5H4.667M11.334 5.833H4.667M11.334 3.166H4.667"
      />
    </svg>
  );
}

export default ParcelFilterIcon;
