import userFragments from 'queries/fragments/user';
import projectFragments from 'queries/fragments/project';
import systemAccessFragments from 'queries/fragments/systemAccess';

export default `
  ${projectFragments}
  ${systemAccessFragments}
  ${userFragments}
  query Me {
    me {
      isLoggedAs
      access {
        ...CoreSystemAccessFields
        project {
          ...CoreProjectFields
        }
      }
      user {
        ...CoreUserFields
      }
      realEstate { 
        _id
        name 
        cnpj
        creci
        reservationLimit
        pendingReservations
      }
      realEstateAgent { 
        _id
        name
        cpf
        rg
        creci
        reservationLimit
        pendingReservations
      }
      projects { 
        _id
        title
        slug
        ownerId
        lastAccess
        createdAt
      }
      environment
    }
  }
`;
