import React from 'react';
import { Box, Button } from '@material-ui/core';
import PropTypes from 'prop-types';

function EditButton({ isContractCreated, onEdit }) {
  if (isContractCreated || !onEdit) return null;
  return (
    <Box display="flex" justifyContent="flex-end" mt={3}>
      <Button variant="contained" color="primary" onClick={onEdit}>
        Editar
      </Button>
    </Box>
  );
}

export default EditButton;

EditButton.propTypes = {
  isContractCreated: PropTypes.bool.isRequired,
  onEdit: PropTypes.func.isRequired,
};
