export default {
  info: '/assets/icons/popup/info.svg',
  close: '/assets/icons/popup/close.svg',
  emit: '/assets/icons/popup/emit.svg',
  print: '/assets/icons/popup/print.svg',
  export: '/assets/icons/popup/export.svg',
  priceAdjustment: '/assets/icons/popup/price-adjustment.svg',
  editPrice: '/assets/icons/popup/edit-price.svg',
  updateStatus: '/assets/icons/popup/update-status.svg',
};
