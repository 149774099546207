import React, { useContext } from 'react';

import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import api from 'services/api';
import useQuery from 'hooks/useQuery';
import Alert from 'modules/AlertModule';
import LOGOUT_AS_QUERY from 'queries/logoutAsQuery';
import { AuthContext } from 'providers/AuthProvider';
import ICON_TYPES from '@constants/iconsPaths';
import Icon from 'components/Icon';

function LogoutAsButton() {
  const { auth, authenticate } = useContext(AuthContext);
  const history = useHistory();
  const [logout, isLoggingOut] = useQuery(LOGOUT_AS_QUERY);

  function handleLogout() {
    api.resetSocket();
    logout()
      .then(() => history.push('/konoha'))
      .catch(Alert.onError('Falha ao sair :('))
      .finally(() => authenticate());
  }

  if (!auth?.isLoggedAs) return null;

  return (
    <Button
      variant="contained"
      onClick={() => handleLogout()}
      disabled={isLoggingOut}
      startIcon={<Icon src={ICON_TYPES.BASE.userProfile} size={24} />}
    >
      Sair
    </Button>
  );
}

export default LogoutAsButton;
