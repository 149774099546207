import React from 'react';
import { Container } from '@material-ui/core';

import ProjectConfigurationProvider from 'providers/ProjectConfigurationProvider';

import ProjectsLink from 'components/ProjectsLink';
import Configuration from './components/Configuration';
import useStyles from './styles';

export default function ProjectConfiguration() {
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <ProjectsLink mb={4} />
      <ProjectConfigurationProvider>
        <Configuration />
      </ProjectConfigurationProvider>
    </Container>
  );
}
