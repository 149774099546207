import React from 'react';
import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

export default function ValidationFields({ origin }) {
  if (!origin) return null;
  const message =
    origin === 'contract'
      ? '* Preencha os campos RG, E-mail e todos os campos de endereço'
      : '* Preencha todos os campos de endereço';

  return (
    <Box mb={2}>
      <Typography>{message}</Typography>
    </Box>
  );
}

ValidationFields.propTypes = {
  origin: PropTypes.string,
};

ValidationFields.defaultProps = {
  origin: '',
};
