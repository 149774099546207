import { makeStyles } from '@material-ui/core';
import typography from 'theme/lightTheme/typography';

const useStyles = makeStyles(() => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 16,
  },
  title: {
    ...typography.title5,
  },

  close: {
    display: 'flex',
    cursor: 'pointer',

    '&:hover': {
      filter: 'brightness(1.5)',
    },
  },
}));

export default useStyles;
