import React from 'react';

function CustomerZeroStateImage() {
  return (
    <svg
      width="170"
      height="200"
      viewBox="0 0 145 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M133.651 31.3869C133.204 30.6121 132.734 29.847 132.242 29.0916C125.71 19.1118 114.464 12.2871 103.402 9.20164C98.5484 7.87107 93.5623 7.12139 88.5422 6.96742C55.6655 5.80529 42.5707 32.5345 31.7057 39.4114C20.8408 46.2884 4.11208 58.0754 5.23973 82.7679C6.36738 107.46 23.6261 130.706 57.0582 132.159C90.4902 133.611 92.7202 118.091 111.386 112.347C123.739 108.547 132.529 95.7112 136.814 83.8487C142.672 67.6775 142.396 46.6806 133.651 31.3869Z"
        fill="#F0F2F4"
      />
      <path
        opacity="0.7"
        d="M133.651 31.3869C133.204 30.6121 132.734 29.847 132.242 29.0916C125.71 19.1118 114.464 12.2871 103.402 9.20164C98.5484 7.87107 93.5623 7.12139 88.5422 6.96742C55.6655 5.80529 42.5707 32.5345 31.7057 39.4114C20.8408 46.2884 4.11208 58.0754 5.23973 82.7679C6.36738 107.46 23.6261 130.706 57.0582 132.159C90.4902 133.611 92.7202 118.091 111.386 112.347C123.739 108.547 132.529 95.7112 136.814 83.8487C142.672 67.6775 142.396 46.6806 133.651 31.3869Z"
        fill="#F8F9FA"
      />
      <path
        d="M70.0946 72.1585C70.0946 72.1585 67.3466 87.4476 66.7444 90.0327C66.1423 92.6179 64.6679 105.797 64.6679 109.071C64.6679 112.346 64.924 122.24 64.924 122.24C64.924 122.24 63.8062 122.756 63.8062 123.186C63.8062 123.615 64.6679 126.374 64.6679 126.374L69.7416 128.269C69.7416 128.269 70.7798 125.844 70.8594 124.995C70.939 124.146 70.2572 123.789 70.2572 122.323C70.2572 120.857 72.8391 103.804 73.6143 100.962C74.3896 98.1208 76.6253 89.5926 77.0544 88.8164C77.4836 88.0401 79.6363 82.357 79.6363 82.357L80.8407 82.9599C80.8407 82.9599 81.6124 100.876 82.045 101.995C82.4777 103.114 88.247 121.356 88.247 121.356C88.247 121.356 87.3021 121.786 87.9009 122.822C88.4996 123.858 89.5344 126.18 89.5344 126.18C89.5344 126.18 90.4827 124.887 91.6109 124.631C92.7392 124.374 94.6219 125.49 94.6219 125.49C94.6219 125.49 95.7398 122.905 95.4802 121.703C95.2207 120.5 94.0959 118.428 94.0197 117.825C93.9436 117.222 91.5971 99.8535 91.5971 99.8535C91.5971 99.8535 92.1993 75.5614 92.1993 73.9258C92.1671 72.8098 91.964 71.7053 91.5971 70.651C87.9879 71.8911 84.2225 72.6167 80.4115 72.8065C74.303 73.0629 70.9494 70.5679 70.9494 70.5679L70.0946 72.1585Z"
        fill="#263238"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M64.6822 126.385C64.6822 126.385 61.8408 128.966 61.3251 129.313C60.8094 129.659 57.1963 131.045 57.2828 131.738C57.3693 132.432 58.321 132.858 58.321 132.858H70.0223C70.0223 132.858 70.1054 132.511 70.2784 130.876C70.3611 129.815 70.1841 128.751 69.7627 127.774L64.6822 126.385Z"
        fill="white"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M70.1915 131.599L57.3447 131.946C57.5659 132.337 57.9051 132.649 58.3138 132.836H70.015C70.015 132.836 70.0739 132.604 70.1915 131.599Z"
        fill="white"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M89.5412 126.18C89.5412 126.18 89.4546 128.332 89.4546 129.454C89.4546 130.577 89.2851 131.88 89.3681 132.123C89.4512 132.365 89.6277 132.816 89.6277 132.816H96.4249C96.4249 132.816 96.681 132.123 96.3384 130.834C95.9957 129.545 95.3901 128.855 95.2205 127.715C95.089 126.952 94.8874 126.202 94.6183 125.476C94.6183 125.476 91.2612 122.822 89.5412 126.18Z"
        fill="white"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M96.5152 131.998C93.9299 131.998 90.7113 131.915 89.3443 131.88C89.3438 131.962 89.3519 132.043 89.3685 132.123C89.455 132.383 89.628 132.816 89.628 132.816H96.4252C96.5001 132.55 96.5305 132.274 96.5152 131.998Z"
        fill="white"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M67.6549 39.7264C67.6549 39.7264 60.3455 46.5981 60.6639 47.2392C60.9823 47.8803 62.9827 53.0818 62.9827 53.0818C62.9827 53.0818 63.5572 56.5472 63.2388 58.7338C63.0442 60.1166 62.9793 61.5146 63.045 62.9095C63.7167 63.7574 64.5378 64.475 65.4676 65.0268C66.8797 65.7996 67.9699 67.1476 68.8697 64.9645C69.7695 62.7813 69.3819 58.862 69.3819 58.862C69.3819 58.862 70.5967 63.6165 70.8009 64.4551C71.0051 65.2937 70.1087 72.162 70.1087 72.162C70.1087 72.162 74.5179 75.8907 82.0176 75.0452C89.5174 74.1996 91.895 71.5798 91.895 71.5798C91.895 71.5798 91.1232 63.166 91.1232 62.2026C91.116 61.5366 91.0732 60.8715 90.9952 60.21C90.9952 60.21 93.6254 62.9095 94.9094 62.7813C96.1934 62.6531 98.4361 57.3858 97.8581 56.5437C97.2801 55.7016 95.2936 52.2986 94.0753 49.859C93.341 48.3887 92.7298 46.8598 92.248 45.2882C92.248 45.2882 90.1299 38.0942 89.4793 37.5813C88.8286 37.0684 81.6231 37.1481 80.3391 36.8293C78.6437 36.4951 76.9298 36.2637 75.2066 36.1363C74.7774 36.1293 69.067 37.4773 67.6549 39.7264Z"
        fill="#FF6C4E"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M89.2163 49.3429C89.1817 47.4994 89.1748 46.0647 89.1748 46.0647"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M91.0158 60.2102C90.5261 59.2106 90.0984 58.1818 89.7352 57.1295C89.4791 56.3637 89.3442 53.6295 89.2715 51.0686"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M65.727 52.6038C65.0729 51.4602 64.5745 50.5662 64.5122 50.386"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M69.3885 58.8616C69.3885 58.8616 67.748 56.0893 66.3706 53.719"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M77.7261 37.0271C77.9903 39.2881 78.0123 41.5708 77.7918 43.8365"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M82.0415 68.7244C82.8133 68.8803 83.6404 69.0224 84.5229 69.1367"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M72.7217 64.5798C72.7217 64.5798 74.7982 66.86 80.3356 68.3224"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M78.3662 69.459C80.7441 70.5276 83.3293 71.0543 85.9352 71.0011"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M63.0376 29.32C63.0376 29.32 63.8059 32.917 63.8717 33.6898C63.9374 34.4626 64.1278 35.4883 65.0899 37.0269C66.052 38.5655 68.5508 41.972 69.0665 42.166C69.5821 42.3601 71.5687 41.8438 71.5687 41.8438C71.5687 41.8438 73.6833 46.0195 74.2613 45.8913C74.8392 45.7631 76.3136 41.0086 76.5074 39.0819C76.6296 38.1234 76.6505 37.1547 76.5697 36.1918L75.6733 32.9829C75.6733 32.9829 74.905 28.4779 74.905 28.166C74.905 27.8542 75.3514 25.5324 72.8527 24.569C70.3539 23.6056 67.2703 23.5294 65.218 25.917C63.1656 28.3046 63.0376 29.32 63.0376 29.32Z"
        fill="white"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M69.9043 25.2759C69.0047 26.6431 67.825 27.8031 66.4435 28.6789C64.0727 30.221 65.2252 28.5507 65.2252 28.5507C65.2252 28.5507 63.5571 29.5903 62.2108 30.2833C60.8645 30.9764 61.3767 31.1843 60.8645 30.9764C60.3523 30.7685 58.4419 25.3002 62.7299 22.6596C67.018 20.019 70.1466 20.7155 70.1466 20.7155C70.1466 20.7155 68.2847 19.8804 69.9528 19.8804C71.6209 19.8804 72.327 20.7918 72.327 20.7918C72.327 20.7918 73.2233 20.5353 73.6075 21.0482C73.7756 21.2314 73.9859 21.3706 74.2201 21.4536C76.2966 22.3338 77.0199 24.3022 77.411 26.3571C77.6671 27.7051 76.5769 30.9175 76.5769 30.9175L74.7773 31.8809C74.3411 31.483 73.9882 31.0024 73.739 30.467C73.5751 29.9478 73.4888 29.4071 73.4829 28.8626C73.4829 28.8626 72.3927 30.2729 72.1989 29.2472C72.0051 28.2215 71.5067 25.5878 71.5067 25.5878C71.5067 25.5878 71.7005 26.6274 71.0603 26.6274C70.42 26.6274 69.9043 25.2759 69.9043 25.2759Z"
        fill="#263238"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M74.646 32.3383C74.646 32.3383 74.5837 30.1551 75.8677 29.4482C77.1517 28.7413 78.0481 29.514 77.6604 31.569C77.2728 33.6239 74.9679 34.3932 74.9679 34.3932"
        fill="white"
      />
      <path
        d="M74.646 32.3383C74.646 32.3383 74.5837 30.1551 75.8677 29.4482C77.1517 28.7413 78.0481 29.514 77.6604 31.569C77.2728 33.6239 74.9679 34.3932 74.9679 34.3932"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M71.5688 41.8436L74.9674 38.4407L72.7213 43.6422L71.5688 41.8436Z"
        fill="#263238"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M69.9976 38.4025C70.0697 38.5284 70.0915 38.6769 70.0588 38.8183C70.0261 38.9597 69.9412 39.0835 69.8211 39.1648C69.5893 39.2584 69.2951 39.0678 69.1636 38.7386C69.0321 38.4094 69.1117 38.0663 69.3401 37.9727C69.5685 37.8792 69.8627 38.0732 69.9976 38.4025Z"
        fill="#263238"
      />
      <path
        d="M70.8836 32.4564C71.0082 32.8653 70.9182 33.2534 70.6864 33.3262C70.4545 33.3989 70.1568 33.1252 70.0323 32.7197C69.9077 32.3143 69.9976 31.9262 70.2295 31.8534C70.4614 31.7806 70.759 32.0509 70.8836 32.4564Z"
        fill="#263238"
      />
      <path
        d="M66.6719 33.17C66.7965 33.5789 66.7065 33.967 66.4712 34.0398C66.2358 34.1126 65.9451 33.8423 65.8205 33.4334C65.6959 33.0244 65.7825 32.6398 66.0178 32.567C66.2531 32.4942 66.5439 32.7645 66.6719 33.17Z"
        fill="#263238"
      />
      <path
        d="M66.8242 31.5688C67.1087 31.8018 67.3371 32.0961 67.4923 32.4297C67.6475 32.7632 67.7255 33.1276 67.7206 33.4956C67.7206 34.7812 67.5268 36.708 67.5268 36.708L69.8386 36.3857"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M68.3608 30.7337C68.3608 30.7337 69.5168 28.6787 71.313 29.0634"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M66.3087 31.1184C66.3087 31.1184 64.9001 30.6714 63.938 31.2466"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M105.552 47.6241C105.552 47.6241 106.964 47.1112 107.476 46.931C107.988 46.7508 108.514 47.0592 109.4 46.6122C110.286 46.1652 111.324 45.2607 111.39 45.9191C111.456 46.5775 111.131 46.8166 110.618 47.1389C110.106 47.4612 109.272 48.1023 109.272 48.1023L109.016 48.7434L105.167 48.4246C105.167 48.4246 105.24 47.8216 105.552 47.6241Z"
        fill="white"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M113.287 49.2839C113.287 49.2839 115.055 47.8804 115.311 47.2393C115.567 46.5983 115.117 46.5463 114.671 46.7889C114.224 47.0314 112.819 48.2027 112.819 48.2027L113.287 49.2839Z"
        fill="white"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M111.84 49.5885C111.84 49.5885 113.155 49.7271 113.286 49.2836C113.418 48.84 114.456 46.0469 114.287 45.6969C114.117 45.3469 113.788 45.3781 113.425 45.9568C113.036 46.7502 112.701 47.5687 112.421 48.4068C112.421 48.4068 111.542 48.6043 111.321 49.2073C111.099 49.8103 111.84 49.5885 111.84 49.5885Z"
        fill="white"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M110.179 49.9353C110.179 49.9353 111.4 50.4516 111.657 50.0635C111.913 49.6754 113.709 47.2912 113.643 46.917C113.577 46.5427 113.259 46.47 112.747 46.917C112.144 47.563 111.587 48.2496 111.079 48.9719C111.079 48.9719 110.179 48.9096 109.795 49.4224C109.41 49.9353 110.179 49.9353 110.179 49.9353Z"
        fill="white"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M92.2859 60.4699C92.7926 58.4151 94.0819 56.6403 95.8783 55.5248C98.955 53.5322 103.129 49.9352 103.129 49.9352C103.129 49.9352 104.541 47.9461 105.551 47.6238C106.562 47.3015 109.912 48.2649 109.912 48.2649C109.912 48.2649 112.608 45.5689 112.681 46.1476C112.754 46.7263 111.4 48.7812 110.757 49.5506C110.113 50.3199 108.126 50.5139 107.68 50.5139C106.886 50.5378 106.093 50.6026 105.306 50.708C105.05 50.7704 100.807 56.5506 98.8927 59.3713C96.9788 62.1921 96.124 62.7085 94.9161 62.7743C93.7083 62.8402 92.4139 61.8733 92.0921 61.4263C91.7702 60.9793 92.2859 60.4699 92.2859 60.4699Z"
        fill="white"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M52.1742 49.8384L56.2165 58.6196C56.2788 58.7447 56.3799 58.8461 56.5048 58.9086C56.6296 58.9711 56.7714 58.9913 56.9087 58.9661L60.2623 58.3458C60.3554 58.3278 60.4433 58.2893 60.5198 58.2331C60.5962 58.1769 60.6592 58.1044 60.7043 58.0208C60.7493 57.9373 60.7752 57.8447 60.7802 57.7499C60.7852 57.6551 60.769 57.5604 60.733 57.4725L57.099 48.9027C57.0465 48.7762 56.9543 48.6702 56.8363 48.6008C56.7183 48.5314 56.5809 48.5023 56.4449 48.5181L52.676 48.9374C52.5762 48.9494 52.4807 48.9849 52.3973 49.0409C52.3138 49.097 52.2448 49.172 52.1959 49.2599C52.1469 49.3478 52.1195 49.446 52.1157 49.5465C52.1119 49.6471 52.132 49.7471 52.1742 49.8384Z"
        fill="#263238"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M54.8285 57.3547C54.8285 57.3547 53.3784 55.13 53.3784 54.631C53.3784 54.132 54.2229 52.9503 54.7178 51.883C55.2127 50.8156 55.2888 50.1018 55.7145 50.2473C56.1402 50.3929 56.0606 50.8156 55.9256 51.526C55.6654 52.4423 55.3582 53.3445 55.005 54.229L55.1469 55.0849C55.4898 54.3924 55.9189 53.7462 56.424 53.1617C57.0643 52.5241 57.7737 51.4567 58.2721 51.6681C58.7705 51.8795 58.9816 52.0147 58.5559 52.5241C58.0215 53.165 57.5422 53.8502 57.1231 54.5721C56.7473 55.1882 56.4242 55.835 56.1575 56.5057C56.6329 56.0497 57.1382 55.626 57.6699 55.2374C58.3621 54.7384 59.3381 54.0176 59.7465 54.3018C60.1548 54.5859 60.2448 54.8701 59.8191 55.227C59.3934 55.584 59.0058 55.8265 58.4348 56.3255C57.8391 56.9209 57.2798 57.5516 56.7597 58.2142C57.267 57.8218 57.8321 57.5109 58.4348 57.2924C59.6426 56.7968 60.7467 56.8523 60.7916 57.2335C60.8366 57.6147 59.5215 57.9612 59.5215 57.9612C58.5801 58.176 58.5351 58.4983 58.5351 58.4983C58.5351 58.4983 59.3173 60.0646 59.1754 60.5775C59.1754 60.5775 62.3317 60.082 63.5396 59.7285C64.7474 59.375 66.6336 60.075 67.2047 60.6468C68.4125 61.8562 68.9801 63.7067 68.3433 65.2731C67.7065 66.8394 67.703 66.3404 62.6571 64.9265C57.6111 63.5127 57.708 63.0067 56.4759 60.9414C56.0295 60.1374 54.8285 57.3547 54.8285 57.3547Z"
        fill="white"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M59.1962 60.5637L59.0889 61.8667"
        stroke="#263238"
        strokeMiterlimit="10"
      />
    </svg>
  );
}

export default CustomerZeroStateImage;
