import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, Button } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';

import { ProjectContext } from 'providers/ProjectProvider';
import ICON_TYPES from '@constants/iconsPaths';

import Map from 'components/InteractiveMap';
import Icon from 'components/Icon';

import GET_VISIBLE_PROPERTY_MARKS_QUERY from 'queries/getVisiblePropertyMarksQuery';

import WebSocketController from 'utils/WebSocketController';
import useQuery from 'hooks/useQuery';

import MakeReservation from 'pages/ProjectMain/Administration/InteractiveMap/MakeReservation';
import ReadReceiptDialog from 'components/Dialogs/ReadDialogs/ReadReceiptDialog';
import useStyles from './styles';
import PropertyResume from './PropertyResume';

const socket = new WebSocketController();

export default function InteractiveMap() {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const containerRef = useRef(null);

  const { project } = useContext(ProjectContext);

  const [propertyMarks, setPropertyMarks] = useState([]);

  const [selectedPropertyId, setSelectedPropertyId] = useState(null);

  const [executeGetVisiblePropertyMarks] = useQuery(
    GET_VISIBLE_PROPERTY_MARKS_QUERY
  );
  const [showPropertyResume, setShowPropertyResume] = useState(false);
  const [showMakeReservation, setShowMakeReservation] = useState(false);
  const [reservation, setReservation] = useState(null);
  const [openReceiptDialog, setOpenReceiptDialog] = useState(false);
  const [hasUpdate, setHasUpdate] = useState(false);

  const loadPropertyMarks = (projectId) => {
    executeGetVisiblePropertyMarks({ projectId })
      .then((data) => {
        const { getVisiblePropertyMarks: selectedPropertyMarks } = data;
        setPropertyMarks(selectedPropertyMarks);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    loadPropertyMarks(project._id);
  }, []);

  const handleMarkClick = (mark) => {
    if (mark.propertyStatus !== 'available') return;
    setSelectedPropertyId(mark.propertyId);
    setShowPropertyResume(true);
  };

  useEffect(() => {
    const triggerReload = () => {
      setHasUpdate(true);
    };

    socket.onPropertyUpdated(triggerReload);
    socket.onPropertyDeleted(triggerReload);
    socket.onPropertyMarkCreated(triggerReload);
    socket.onPropertyMarkDeleted(triggerReload);
    socket.onReservationCreated(triggerReload);
    socket.onReservationUpdated(triggerReload);

    return () => socket.destroy();
  }, []);

  useEffect(() => {
    if (hasUpdate) {
      loadPropertyMarks(project?._id);
      setHasUpdate(false);
    }
  }, [project, hasUpdate]);

  if (!project) return null;

  return (
    <Box className={classes.root} ref={containerRef}>
      <Box className={classes.mapContainer}>
        <Map
          source={project.mapUrl}
          mapDimension={{ width: project.mapWidth, height: project.mapHeight }}
          defaultZoom={location.state?.zoom}
          defaultPosition={location.state?.position}
          marks={propertyMarks}
          selectedPropertyId={selectedPropertyId}
          onMarkClick={handleMarkClick}
          animated
        />

        <Box className={classes.header}>
          <Button
            variant="text"
            color="secondary"
            name="edit-map-button"
            onClick={() => history.push(`/${project.slug}`)}
            startIcon={<Icon src={ICON_TYPES.BASE.back} size={18} />}
          >
            Imóveis
          </Button>
        </Box>

        <Box
          className={classes.overlay}
          hidden={!(showMakeReservation || showPropertyResume)}
        />
      </Box>
      <PropertyResume
        onClose={() => {
          setSelectedPropertyId(null);
          setShowPropertyResume(false);
        }}
        onReserve={() => {
          setShowMakeReservation(true);
        }}
        open={showPropertyResume}
        propertyId={selectedPropertyId}
      />
      <MakeReservation
        open={showMakeReservation}
        propertyId={selectedPropertyId}
        onPropertyReserved={(newReservation) => {
          setOpenReceiptDialog(true);
          setReservation(newReservation);
        }}
        onClose={() => {
          setShowMakeReservation(false);
        }}
      />
      <ReadReceiptDialog
        open={openReceiptDialog}
        reservation={reservation}
        onClose={() => {
          setOpenReceiptDialog(false);
          setReservation(null);
        }}
      />
    </Box>
  );
}
