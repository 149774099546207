import React from 'react';
import { Grid, Typography, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';

import formatArea from 'utils/formatArea';
import Discount from 'components/Discount';

export default function PropertyInformation({ reservation }) {
  const theme = useTheme();
  return (
    <Grid item xs={12}>
      <Typography variant="h5" style={{ color: theme.palette.grey[300] }}>
        {reservation.property.unity}
      </Typography>
      <Discount
        price={reservation.price}
        discount={reservation.discount}
        paymentMethod={reservation.paymentMethod}
      />
      <Typography style={{ color: theme.palette.grey[200] }}>
        {formatArea(reservation.property.totalArea)}
      </Typography>
    </Grid>
  );
}

PropertyInformation.propTypes = {
  reservation: PropTypes.shape({
    property: PropTypes.shape({
      unity: PropTypes.string,
      totalArea: PropTypes.number,
    }).isRequired,
    price: PropTypes.number,
  }),
};

PropertyInformation.defaultProps = {
  reservation: null,
};
