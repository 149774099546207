import { Box, Tooltip, Typography, useTheme } from '@material-ui/core'
import React from 'react'
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa'
import validatePassword from 'utils/validatePassword'

export default function PasswordValidateTooltip({ value, children }) {
  const { length, notWhitespace, numeric, lowercase, uppercase, specialChar } = validatePassword(value)

  const title = (
    <Box px={3} py={2}>
      <PasswordRequirement conditional={length} text="Ter no mínimo 8 caracteres" />
      <PasswordRequirement conditional={numeric} text="Ter um caractere númerico" />
      <PasswordRequirement conditional={lowercase && uppercase} text="Ter um caractere mínusculo e maiúsculo" />
      <PasswordRequirement conditional={specialChar} text="Ter um símbolo como: ! @ # $ %" />
      <PasswordRequirement conditional={notWhitespace} text="Não utilizar espaços" />
    </Box>
  )

  return <Tooltip disableHoverListener
    placement="bottom-start"
    title={title}
    TransitionProps={{ style: { backgroundColor: 'rgba(25, 25, 25)' } }}
  >
    {children}
  </Tooltip>
}

function PasswordRequirement({ conditional, text }) {
  const theme = useTheme()

  return (
    <Box display="flex" alignItems="center" minHeight={32}>
      <Box marginRight={1}>
        {conditional
          ? <FaCheckCircle size={16} color={theme.palette.success?.main} />
          : <FaTimesCircle size={16} color={theme.palette.error?.main} />
        }
      </Box >
      <Typography variant="caption">{text}</Typography>
    </Box >
  )
}