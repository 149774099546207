/* eslint-disable react-hooks/exhaustive-deps */
import {
  Avatar,
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import Copyright from 'components/Copyright';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import api from 'services/api';
import Swal from 'sweetalert2';
import recoverPassword2Query from 'queries/recoverPassword2Query';
import checkRecoverCodeQuery from 'queries/checkRecoverCodeQuery';
import { BsArrowRight } from 'react-icons/bs';
import useThemeClasses from 'theme/useThemeClasses';
import PasswordTextField from 'components/TextFields/PasswordTextField';
import validatePassword from 'utils/validatePassword';
import handlerError from 'utils/handleError';
import style from './styles';

export default function Recover2({ match }) {
  const classes = style();
  const themeClasses = useThemeClasses;
  const buttonClasses = themeClasses.button();

  const history = useHistory();

  const [securityData, setSecurityData] = useState({
    recoverCode: '',
    password: '',
    confirmPassword: '',
  });

  useEffect(() => {
    const { recoverCode } = match.params;

    api
      .call(checkRecoverCodeQuery(recoverCode))
      .then((data) => {
        if (data && data.checkRecoverCode) {
          setSecurityData({
            ...data,
            recoverCode,
          });
        }
      })
      .catch((error) => {
        handlerError(
          {
            DEFAULT: () => {
              Swal.fire(
                'Ops!',
                'Código de recuperação de senha inválido.',
                'error'
              );
              history.push('/login');
            },
          },
          error.message
        );
      });
  }, []);

  function handleSubmit(e) {
    e.preventDefault();

    if (!securityData.password) {
      Swal.fire('Ops!', 'Preencha o campo Nova senha corretamente.', 'error');
      return;
    }
    if (!securityData.confirmPassword) {
      Swal.fire(
        'Ops!',
        'Preencha o campo Confirme a senha corretamente.',
        'error'
      );
      return;
    }
    if (securityData.password !== securityData.confirmPassword) {
      Swal.fire('Ops!', 'As senha não coincidem.', 'error');
      return;
    }
    let validate = validatePassword(securityData.password);
    validate = Object.keys(validate).reduce(
      (obj, value) => obj && validate[value],
      true
    );

    if (!validate) {
      Swal.fire('Ops!', 'Preencha o campo Nova senha corretamente.', 'error');
      return;
    }
    Swal.fire({
      text: 'Aguarde...',
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
    });

    api
      .call(
        recoverPassword2Query(securityData.recoverCode, securityData.password)
      )
      .then((data) => {
        if (data?.recoverPassword2) {
          Swal.fire('Pronto!', 'Senha alterada com successo!', 'success');
          history.push('/login');
        }
      })
      .catch((error) => {
        handlerError(
          {
            DEFAULT: () => {
              Swal.fire('Ops!', 'Não foi possível alterar a senha.', 'error');
            },
          },
          error.message
        );
      });
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />

      <Box maxWidth={400} mx="auto" my={10}>
        <Box display="flex" flexDirection="column" alignItems="center" mb={5}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography variant="h5" color="textPrimary">
            Recuperar senha
          </Typography>
        </Box>

        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <PasswordTextField
                name="rp-password"
                label="Nova senha"
                value={securityData.password}
                onChange={(e) =>
                  setSecurityData({ ...securityData, password: e.target.value })
                }
                autoComplete="password"
                autoFocus
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="rp-password2"
                label="Confirme a senha"
                type="password"
                value={securityData.confirmPassword}
                onChange={(e) =>
                  setSecurityData({
                    ...securityData,
                    confirmPassword: e.target.value,
                  })
                }
                autoComplete="password"
                error={
                  securityData.confirmPassword &&
                  securityData.password !== securityData.confirmPassword
                }
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  className={buttonClasses.linkButton}
                  onClick={() => history.push('/login')}
                  color="secondary"
                >
                  Voltar
                </Button>

                <Button
                  className={buttonClasses.buttonWithIcon}
                  endIcon={<BsArrowRight />}
                  variant="contained"
                  name="rp-submit"
                  color="primary"
                  type="submit"
                  style={{ width: 260 }}
                >
                  Confirmar
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>

      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
