import React from 'react';
import { Box, Grid, Typography, useTheme } from '@material-ui/core';

export default function InputField({ label, children, fullWidth }) {
  const theme = useTheme();
  const size = fullWidth ? 12 : 6;
  return (
    <Grid item xs={size} md={size}>
      <Box marginBottom={2}>
        <Typography
          variant="body2"
          style={{ color: theme.palette.secondary.main }}
        >
          {label}
        </Typography>
        {children}
      </Box>
    </Grid>
  );
}
