import React from 'react';
import { Box, ButtonBase, Tooltip, useTheme } from '@material-ui/core';

import BaseStatusLabel from 'components/StatusLabel/BaseStatusLabel';
import Icon from 'components/Icon';

import ICON_TYPES from '@constants/iconsPaths';

import OCCURRENCE_ERROR_MESSAGES from '@constants/walletManagement/occurrenceErrorMessages';

import { getOccurrenceTypesColors } from './BaseStatusLabel/colors';
import { occurrenceTypesStatusTexts } from './BaseStatusLabel/texts';

export default function OccurrenceTypesStatusLabel({
  status,
  size = 'small',
  message,
}) {
  const theme = useTheme();
  const hasStatus = Boolean(status);

  const statusText = status
    ? occurrenceTypesStatusTexts[status]
    : occurrenceTypesStatusTexts.error;

  const color = hasStatus
    ? getOccurrenceTypesColors(theme)[status]
    : getOccurrenceTypesColors(theme).error;

  const hasWarningMessage = Boolean(message);
  const messageText = OCCURRENCE_ERROR_MESSAGES[message];

  return (
    <Box display="flex" alignItems="center">
      <BaseStatusLabel size={size} label={statusText} color={color} />
      {hasWarningMessage && (
        <Box display="flex" ml={1}>
          <Tooltip
            title={messageText}
            TransitionProps={{
              style: { backgroundColor: theme.palette.grey[200] },
            }}
            placement="top"
          >
            <ButtonBase disableRipple>
              <Icon src={ICON_TYPES.LIST.warning} size={19} />
            </ButtonBase>
          </Tooltip>
        </Box>
      )}
    </Box>
  );
}
