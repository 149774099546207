import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';

function BaseAutoComplete({
  onQuery,
  label,
  required,
  filterOptions,
  getOptionLabel,
  getOptionSelected,
  placeholder,
  onChange,
  value,
  disabled,
}) {
  const isMounted = useRef(false);

  const [items, setItems] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);
  const autoCompleteError = required && !inputValue;
  const renderInput = useCallback(
    (params) => (
      <TextField
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...params}
        label={label}
        placeholder={placeholder}
        inputProps={{ ...params.inputProps, required }}
        error={autoCompleteError}
      />
    ),
    [required, label, autoCompleteError]
  );

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (onQuery) {
      setLoading(true);

      onQuery(inputValue)
        .then((_value) => {
          if (!isMounted.current) return;
          setItems(_value);
        })
        .finally(() => setLoading(false));
    }
  }, [onQuery, inputValue]);

  return (
    <Autocomplete
      filterOptions={filterOptions}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      onChange={onChange}
      options={items}
      inputValue={inputValue}
      onInputChange={(_, _value) => setInputValue(_value)}
      loading={loading}
      loadingText="Carregando ..."
      noOptionsText="Nenhum resultado"
      renderInput={renderInput}
      value={value}
      disabled={disabled}
    />
  );
}

BaseAutoComplete.propTypes = {
  label: PropTypes.string,
  value: PropTypes.shape({}),
  required: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onQuery: PropTypes.func.isRequired,
  getOptionLabel: PropTypes.func.isRequired,
  filterOptions: PropTypes.func.isRequired,
  getOptionSelected: PropTypes.func,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};

BaseAutoComplete.defaultProps = {
  label: '',
  value: null,
  required: false,
  getOptionSelected: undefined,
  placeholder: '',
  disabled: false,
};

export default BaseAutoComplete;
