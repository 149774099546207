import React from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import AlertModule from 'modules/AlertModule';

import api from 'services/api';

import DocumentButton from './DocumentButton';
import DocumentDescription from './DocumentDescription';

export default function DocumentTab({ tabIndex, reservation }) {
  const downloadReceipt = () => {
    AlertModule.wait();
    api
      .downloadReceipt(reservation)
      .then(() => AlertModule.close())
      .catch(AlertModule.onError('Erro ao baixar recibo'));
  };

  if (tabIndex !== 2) return null;

  return (
    <Grid container spacing={1}>
      <Grid item xs={6} md={6}>
        <DocumentDescription description="Recibo da reserva" />
        <DocumentButton
          name={`Recibo_reserva_#${reservation.numberId}.pdf`}
          onClick={downloadReceipt}
        />
      </Grid>
    </Grid>
  );
}

DocumentTab.propTypes = {
  tabIndex: PropTypes.number.isRequired,
  reservation: PropTypes.shape({
    numberId: PropTypes.number,
  }).isRequired,
};
