import { createTheme } from '@material-ui/core/styles';
import typography from './typography';
import palette from './palette';
import overrides from './overrides';

const theme = createTheme({
  typography,
  palette,
  overrides,
});

export default theme;
