import { TextField } from '@material-ui/core'
import React from 'react'
import validateCNPJ from 'utils/validateCnpj'

export default function CNPJTextField(props) {
    const handleValue = () => {
        let value = String(props.value)

        value = value
            .replace(/[^0-9]/g, '')
            .replace(/^([0-9]{0,2})([0-9]{0,3})([0-9]{0,3})([0-9]{0,4})([0-9]{0,2})/g, '$1.$2.$3/$4-$5')
            .replace(/[^0-9]+$/, '')
            .slice(0, 18)

        return value
    }

    const handleChange = (e) => {
        let value = String(e.target.value)

        value = value.replace(/[^0-9]/g, '')
            .slice(0, 14)

        props.onChange(e, value)
    }

    const handleError = () => {
        if (props.error) return true
        if (!props.value) return false

        return !validateCNPJ(props.value)
    }

    return <TextField {...props}
        value={handleValue()}
        onChange={handleChange}
        error={handleError()}
    />
}