import AlertModule from 'modules/AlertModule';
import errorMessages from './errorMessages';

const ErrorHandlerModule =
  (message, callback = () => {}) =>
  (error) => {
    const errorCode = error.response?.data
      ? error.response.data
      : error.message;
    const errorMessage = errorMessages[errorCode] || message;
    AlertModule.error(errorMessage);
    callback();
  };

export default ErrorHandlerModule;
