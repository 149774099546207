import React from 'react';

import BaseList from 'components/Lists/BaseList';

import { useRealEstateAgentsListContext } from 'providers/ListProviders/RealEstateAgentsListProvider';

import useColumns from './hooks/useColumns';

export default function RealEstateAgentsList({ onRowClick }) {
  const { realEstateAgents, pages, variables, setPage } =
    useRealEstateAgentsListContext();

  const columns = useColumns();

  return (
    <BaseList
      columns={columns}
      data={realEstateAgents}
      currentPage={variables.page}
      pages={pages}
      onRowClick={onRowClick}
      onPagination={setPage}
      changedAt={variables.changedAt}
    />
  );
}
