const REGISTER_AND_ACCEPT_INVITE_MUTATION = `
    mutation RegisterAndAcceptInvite (
      $id: ID!
      $name: String!
      $password: String!
    ) {
      registerAndAcceptInvite (
        id: $id,
        name: $name,
        password: $password
      )
    }
  `;

export default REGISTER_AND_ACCEPT_INVITE_MUTATION;
