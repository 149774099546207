import { Box, Typography, useTheme } from '@material-ui/core';
import React from 'react';
import SuccessSvg from './SuccessSvg';

export default function ReservationCode({ numberId }) {
  const theme = useTheme();
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <SuccessSvg />
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography
          style={{
            fontSize: 18,
            fontWeight: 400,
            color: theme.palette.secondary.dark,
          }}
        >
          Código da reserva
        </Typography>
        <Typography
          variant="h2"
          style={{ fontWeight: 700, color: theme.palette.primary.main }}
        >
          #{numberId}
        </Typography>
      </Box>
    </Box>
  );
}
