export default `
    query getReservationsWithoutContract($projectId: ID!,$limit:Int,$search: String) {
        getReservationsWithoutContract(projectId: $projectId,limit:$limit,search: $search) {
            _id
            numberId
            property {
                _id
                unity
            }
            customer {
              _id
              projectId
              name
              cpf
              rg
              maritalStatus
              occupation
              postalCode
              address
              addressNumber
              addressComplement
              district
              city
              state
              email
              phone
              phone2
              createdAt
              active
            }
        }
    }
`;
