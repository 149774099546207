import { Box, Button, Grid, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { BsArrowRight } from 'react-icons/bs';
import useThemeClasses from 'theme/useThemeClasses';
import PropTypes from 'prop-types';

export default function LoginForm({ email, onLogin, onDecline }) {
  const themeClasses = useThemeClasses;
  const buttonClasses = themeClasses.button();

  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    onLogin(password);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            autoComplete="email"
            label="E-mail"
            type="email"
            value={email}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            autoComplete="password"
            name="if-password"
            label="Senha"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <Box mt={2.5} display="flex" justifyContent="flex-end">
            <Button
              name="if-reject"
              className={buttonClasses.linkButton}
              onClick={() => onDecline()}
              color="secondary"
            >
              Rejeitar
            </Button>

            <Button
              className={buttonClasses.buttonWithIcon}
              endIcon={<BsArrowRight />}
              variant="contained"
              name="if-submit"
              color="primary"
              type="submit"
              style={{ width: 260 }}
            >
              Aceitar
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
}

LoginForm.propTypes = {
  email: PropTypes.string,
  onLogin: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired,
};

LoginForm.defaultProps = {
  email: '',
};
