import { useCallback, useEffect, useState } from 'react';

import AlertModule from 'modules/AlertModule';

import useQuery from 'hooks/useQuery';
import LIST_BANK_ACCOUNT_QUERY from 'queries/listBankAccountQuery';

export default function useGetBankAccounts(variables) {
  const [bankAccounts, setBankAccounts] = useState([]);
  const [executeGetBankAccounts, isLoading] = useQuery(LIST_BANK_ACCOUNT_QUERY);

  const onResolve = ({ listBankAccount: data }) => {
    setBankAccounts(data);
  };

  const onReject = AlertModule.onError(
    'Não foi possível carregar a lista de contas bancárias.'
  );

  const getBankAccounts = useCallback(
    () => executeGetBankAccounts(variables).then(onResolve).catch(onReject),
    [variables]
  );

  useEffect(() => {
    getBankAccounts();
  }, [variables]);

  return { bankAccounts, isLoading };
}
