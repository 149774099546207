import validateCNPJ from 'utils/validateCnpj';
import validateEmail from 'utils/validateEmail';
import validatePhone from 'utils/validatePhone';
import validatePostalCode from 'utils/validatePostalCode';

const validateInputs = (realEstate) => {
  const checklist = [
    {
      step: 0,
      message: 'O campo Nome é obrigatório.',
      validate: () => realEstate.name,
    },
    {
      step: 0,
      message: 'O campo CNPJ é obrigatório.',
      validate: () => realEstate.cnpj,
    },
    {
      step: 0,
      message: 'CNPJ inválido.',
      validate: () => validateCNPJ(realEstate.cnpj),
    },
    {
      step: 0,
      message: 'O campo CRECI é obrigatório.',
      validate: () => realEstate.creci,
    },
    {
      step: 0,
      message: 'O campo Limite de reservas é obrigatório.',
      validate: () => realEstate.reservationLimit,
    },
    {
      step: 0,
      message: 'Limite de reservas inválido.',
      validate: () =>
        !Number.isNaN(realEstate.reservationLimit) &&
        realEstate.reservationLimit > 0 &&
        realEstate.reservationLimit % 1 === 0,
    },
    {
      step: 0,
      message: 'O campo E-mail é obrigatório.',
      validate: () => !realEstate.active || realEstate.username,
    },
    {
      step: 0,
      message: 'E-mail inválido.',
      validate: () =>
        !realEstate.username || validateEmail(realEstate.username),
    },
    {
      step: 0,
      message: 'Telefone inválido.',
      validate: () => !realEstate.phone || validatePhone(realEstate.phone),
    },
    {
      step: 0,
      message: 'Telefone 2 inválido.',
      validate: () => !realEstate.phone2 || validatePhone(realEstate.phone2),
    },
    {
      step: 0,
      message: 'Telefone 2 inválido.',
      validate: () => !realEstate.phone2 || validatePhone(realEstate.phone2),
    },
    {
      step: 1,
      message: 'CEP inválido.',
      validate: () =>
        !realEstate.postalCode || validatePostalCode(realEstate.postalCode),
    },
  ];

  const invalidField = checklist.find((item) => !item.validate());

  const error = invalidField
    ? { message: invalidField.message, step: invalidField.step }
    : null;

  return error;
};

export default validateInputs;
