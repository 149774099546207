import React from 'react';
import { Typography } from '@material-ui/core';

import ReservationStatusLabel from 'components/StatusLabel/ReservationStatusLabel';
import convertDateToTimeLeft from 'utils/convertDateToTimeLeft';
import ReservationActions from '../ReservationActions';

export default function useColumns(onUpdate, isAdmin, onEdit) {
  const columns = [
    {
      title: '',
      field: 'numberId',
      render: (rowData) => (
        <Typography color="primary">{`#${rowData.numberId}`}</Typography>
      ),
    },
    {
      title: 'Imóvel',
      field: 'property',
      render: (rowData) => rowData.property.unity,
    },

    {
      title: 'Situação',
      field: 'status',
      render: (rowData) => <ReservationStatusLabel status={rowData.status} />,
    },

    {
      title: 'Cliente',
      field: 'customer',
      render: (rowData) => (rowData.customer ? rowData.customer.name : '-'),
    },

    {
      title: 'Vendedor',
      field: 'seller',
      render: (rowData) => {
        if (rowData.realEstate) {
          return rowData.realEstate.name;
        }

        if (rowData.realEstateAgent) {
          return rowData.realEstateAgent.name;
        }

        return '-';
      },
    },

    {
      title: 'Válido até',
      field: 'expiration',
      render: (rowData) =>
        rowData.expiration
          ? convertDateToTimeLeft(rowData.expiration)
          : 'Não expira',
    },

    {
      title: '',
      field: '_actions',
      align: 'center',
      fit: true,
      render: (rowData) => (
        <ReservationActions
          reservation={rowData}
          isAdmin={isAdmin}
          onEdit={onEdit}
          onUpdate={onUpdate}
        />
      ),
    },
  ];

  return columns;
}
