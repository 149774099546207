import {
  Box,
  Modal,
  Typography,
  Dialog,
  DialogContent,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import React from 'react';
import ICON_TYPES from '@constants/iconsPaths';
import PropTypes from 'prop-types';
import useThemeClasses from 'theme/useThemeClasses';
import Icon from 'components/Icon';
import Loading from 'components/Loading';
import useStyles from './styles';

export default function BaseDialog({
  open,
  title,
  maxWidth,
  onClose,
  children,
  subtitle,
  loading,
}) {
  const theme = useTheme();
  const classes = useStyles();
  const dialogClass = useThemeClasses.dialog();
  const isMobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Modal open={open} onClose={onClose}>
      <Dialog
        className={dialogClass.dialog1}
        open={open}
        onClose={onClose}
        fullScreen={isMobileDevice}
        fullWidth={!isMobileDevice}
        maxWidth={maxWidth}
      >
        <DialogContent style={{ overflow: 'visible' }}>
          <Box className={classes.header}>
            <Typography className={classes.title}>{title}</Typography>

            <Box className={classes.close} onClick={onClose}>
              <Icon src={ICON_TYPES.BASE.close} />
            </Box>
          </Box>
          {subtitle && (
            <Box mb={2}>
              <Typography color="textSecondary">{subtitle}</Typography>
            </Box>
          )}

          {loading ? <Loading height={200} /> : children}
        </DialogContent>
      </Dialog>
    </Modal>
  );
}

BaseDialog.propTypes = {
  maxWidth: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  children: PropTypes.node,
  open: PropTypes.bool,
  title: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

BaseDialog.defaultProps = {
  subtitle: '',
  open: false,
  title: '',
  loading: false,
  children: null,
};
