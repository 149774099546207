import { Box, Grid, Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { useBankAccountsListContext } from 'providers/ListProviders/BankAccountsListProvider';
import BaseList from '../BaseList';
import useColumns from './hooks/useColumns';

export default function BankAccountList({ onEdit }) {
  const { bankAccounts } = useBankAccountsListContext();
  const columns = useColumns(onEdit);

  return (
    <>
      <Box mb={5}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs>
            <Typography className="fw700" variant="body2">
              Contas bancárias
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <BaseList columns={columns} onRowClick={onEdit} data={bankAccounts} />
    </>
  );
}

BankAccountList.propTypes = {
  options: PropTypes.shape({
    canCreate: PropTypes.bool,
    canEdit: PropTypes.bool,
  }),
  onEdit: PropTypes.func,
};

BankAccountList.defaultProps = {
  options: null,
  onEdit: () => {},
};
