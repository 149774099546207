const lastSixMonths = () => {
    const startDate = new Date()
    startDate.setDate(startDate.getDate() - 180)
    startDate.setHours(0, 0, 0, 0)

    const endDate = new Date()
    endDate.setDate(endDate.getDate() - 1)
    endDate.setHours(0, 0, 0, 0)

    return {
        id: 'last-six-months',
        label: 'Últimos 6 meses',
        startDate,
        endDate
    }
}

export default lastSixMonths