export default function validateDueDate(dueDate) {
  if (Number.isNaN(Date.parse(dueDate))) return false;

  const date = new Date(dueDate);
  const today = new Date(dueDate);

  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);

  return date.getTime() >= today.getTime();
}
