export default function optionalStringQueryField(name, value, config = { hidden: false }) {
    if (!value) {
        if (config.hidden) {
            return ''
        } else {
            return `${name}: null`
        }
    }

    return `${name}: "${value}"`
}