import React from 'react';
import { Box, Link } from '@material-ui/core';
import useThemeClasses from 'theme/useThemeClasses';

export default function Links({ mt, padding = 0 }) {
  const themeClasses = useThemeClasses;
  const linkClasses = themeClasses.link();

  return (
    <Box display="flex" mt={mt} padding={padding}>
      <Box mr={3}>
        <Link
          className={linkClasses.privacyPolicyLink}
          href="https://www.quadraa.com.br/ajuda"
        >
          Ajuda
        </Link>
      </Box>
      <Box mr={3}>
        <Link
          className={linkClasses.privacyPolicyLink}
          href="https://quadraa.com.br/legal/politicas-de-privacidade"
        >
          Políticas de Privacidade
        </Link>
      </Box>
      <Box>
        <Link
          className={linkClasses.privacyPolicyLink}
          href="https://quadraa.com.br/legal/termos-e-condicoes"
        >
          Termos de uso
        </Link>
      </Box>
    </Box>
  );
}
