export default (bankAccount) => {
  const checklist = [
    {
      step: 0,
      message: 'O Código da empresa é obrigatório.',
      isInvalid: !bankAccount.companyCode,
    },
    {
      step: 0,
      message: 'O campo Agência é obrigatório.',
      isInvalid: !bankAccount.branchNumber,
    },
    {
      step: 0,
      message: 'O campo Conta é obrigatório.',
      isInvalid: !bankAccount.accountNumber,
    },
    {
      step: 0,
      message: 'O campo Dígito da conta é obrigatório.',
      isInvalid: !bankAccount.accountDigit,
    },
    {
      step: 0,
      message: 'O campo Documento do beneficiário é obrigatório.',
      isInvalid: !bankAccount.document,
    },
    {
      step: 0,
      message: 'O campo Razão social é obrigatório.',
      isInvalid: !bankAccount.document,
    },
    {
      step: 1,
      message: 'O campo CEP é obrigatório.',
      isInvalid: !bankAccount.postalCode,
    },
    {
      step: 1,
      message: 'O campo Estado é obrigatório.',
      isInvalid: !bankAccount.state,
    },
    {
      step: 1,
      message: 'O campo Cidade é obrigatório.',
      isInvalid: !bankAccount.city,
    },
    {
      step: 1,
      message: 'O campo Bairro é obrigatório.',
      isInvalid: !bankAccount.district,
    },
    {
      step: 1,
      message: 'O campo Logradouro é obrigatório.',
      isInvalid: !bankAccount.address,
    },
  ];

  const error = checklist.find((item) => item.isInvalid);
  return error;
};
