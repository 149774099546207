import React from 'react';
import {
  Grid,
  Typography,
  useTheme,
  Box,
  FormControlLabel,
  Radio,
  FormControl,
  Select,
} from '@material-ui/core';
import { monthlyDueDays } from '@constants/monthlyDueDays';
import { annuallyDueDays } from '@constants/annuallyDueDays';
import PropTypes from 'prop-types';
import { PAYMENT_METHODS } from '@constants/reservations/paymentMethods';
import isEditionDisabled from '../utils/isEditionDisabled';

export default function InstallmentsInformation({
  reservation,
  setReservation,
  monthlyDueDates,
  installmentsDates,
  annuallyDueDates,
}) {
  const theme = useTheme();
  const editionDisabled = isEditionDisabled(reservation);

  if (reservation.paymentMethod === PAYMENT_METHODS.CASH) return null;

  return (
    <Grid container spacing={4}>
      <Grid item md={12} style={{ paddingBottom: 0 }}>
        <Typography
          className="fw700"
          style={{ color: theme.palette.secondary.dark }}
        >
          Informações da parcela
        </Typography>
      </Grid>

      <Grid item md={6}>
        <Box mb={1.5}>
          <Typography
            className="fw700"
            style={{ color: theme.palette.secondary.main }}
          >
            1ª prestação mensal
          </Typography>
        </Box>

        <Typography
          variant="body2"
          style={{ color: theme.palette.secondary.main }}
        >
          Qual o dia da 1ª prestação mensal?
        </Typography>

        <Box display="flex" flexDirection="column" mt={1}>
          {monthlyDueDays.map((dueDay) => (
            <FormControlLabel
              key={dueDay}
              value={dueDay}
              control={<Radio color="primary" />}
              label={`Dia ${dueDay}`}
              checked={reservation.monthlyDueDay === dueDay}
              onClick={() => {
                if (!editionDisabled) {
                  setReservation({
                    ...reservation,
                    monthlyDueDay: dueDay,
                  });
                }
              }}
              ref={(component) => {
                if (component) {
                  component.lastChild.classList.remove('MuiTypography-body1');
                  component.lastChild.classList.add('MuiTypography-body2');
                }
              }}
              disabled={editionDisabled}
            />
          ))}
        </Box>

        <Box mt={3}>
          <Typography
            variant="body2"
            style={{ color: theme.palette.secondary.main }}
          >
            Mês e ano
          </Typography>
        </Box>

        <Box mt={1}>
          <FormControl fullWidth disabled={editionDisabled}>
            <Select
              native
              value={reservation.firstMonthlyInstallmentDate}
              onChange={(e) => {
                setReservation({
                  ...reservation,
                  firstMonthlyInstallmentDate: e.target.value,
                });
              }}
            >
              <option disabled value="">
                Mês da 1ª prestação mensal
              </option>
              {monthlyDueDates.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
          </FormControl>
        </Box>

        {!installmentsDates.firstMonthlyInstallmentFulldate ? (
          <Box style={{ marginTop: 8 }}>
            <Typography variant="caption" style={{ color: 'red' }}>
              {'Selecione uma data igual ou superior a '}
              {new Date(reservation.createdAt).toLocaleString('pt-BR', {
                month: 'long',
                day: '2-digit',
                year: 'numeric',
              })}
              .
            </Typography>
          </Box>
        ) : null}
      </Grid>

      {reservation.paymentMethod === PAYMENT_METHODS.ANNUALLY ? (
        <Grid item md={6}>
          <Box mb={1.5}>
            <Typography
              className="fw700"
              style={{ color: theme.palette.secondary.main }}
            >
              1ª prestação anual
            </Typography>
          </Box>

          <Typography
            variant="body2"
            style={{ color: theme.palette.secondary.main }}
          >
            Qual o dia da 1ª prestação anual?
          </Typography>

          <Box display="flex" flexDirection="column" mt={1}>
            {annuallyDueDays.map((dueDay) => (
              <FormControlLabel
                key={dueDay}
                control={<Radio color="primary" />}
                label={`Dia ${dueDay}`}
                checked={reservation.annuallyDueDay === dueDay}
                onClick={() => {
                  if (!editionDisabled) {
                    setReservation({
                      ...reservation,
                      annuallyDueDay: dueDay,
                    });
                  }
                }}
                ref={(component) => {
                  if (component) {
                    component.lastChild.classList.remove('MuiTypography-body1');
                    component.lastChild.classList.add('MuiTypography-body2');
                  }
                }}
                disabled={editionDisabled}
              />
            ))}
          </Box>

          <Box mt={3}>
            <Typography
              variant="body2"
              style={{ color: theme.palette.secondary.main }}
            >
              Mês e ano
            </Typography>
          </Box>

          <Box mt={1}>
            <FormControl fullWidth disabled={editionDisabled}>
              <Select
                native
                value={reservation.firstAnnuallyInstallmentDate}
                onChange={(e) => {
                  setReservation({
                    ...reservation,
                    firstAnnuallyInstallmentDate: e.target.value,
                  });
                }}
              >
                <option disabled value="">
                  Mês da 1ª prestação anual
                </option>
                {annuallyDueDates.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Box>

          {!installmentsDates.firstAnnuallyInstallmentFulldate ? (
            <Box style={{ marginTop: 8 }}>
              <Typography variant="caption" style={{ color: 'red' }}>
                Selecione uma data válida.
              </Typography>
            </Box>
          ) : null}
        </Grid>
      ) : null}
    </Grid>
  );
}

InstallmentsInformation.propTypes = {
  reservation: PropTypes.shape({
    firstAnnuallyInstallmentDate: PropTypes.string,
    annuallyDueDay: PropTypes.number,
    paymentMethod: PropTypes.string,
    createdAt: PropTypes.string,
    firstMonthlyInstallmentDate: PropTypes.string,
    monthlyDueDay: PropTypes.number,
  }),
  installmentsDates: PropTypes.shape({
    firstAnnuallyInstallmentFulldate: PropTypes.string,
    firstMonthlyInstallmentFulldate: PropTypes.string,
  }).isRequired,
  setReservation: PropTypes.func.isRequired,
  monthlyDueDates: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  annuallyDueDates: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

InstallmentsInformation.defaultProps = {
  reservation: null,
};
