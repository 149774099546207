import React, { useContext, useState } from 'react';
import { Box, Container, useMediaQuery, useTheme } from '@material-ui/core';

import { ProjectContext } from 'providers/ProjectProvider';

import ReservationsList from 'components/Lists/ReservationsList';
import ProjectsLink from 'components/ProjectsLink';
import ReadReservationDialog from 'components/Dialogs/ReadDialogs/ReadReservationDialog';
import EditReservationDialog from 'components/Dialogs/EditDialogs/EditReservationDialog';
import ReservationsListProvider from 'providers/ListProviders/ReservationsListProvider';
import ReservationsListFilters from 'components/ListFilters/ReservationsListFilters';
import ReservationsListZeroState from 'components/ZeroState/ReservationsListZeroState';

import { useStyles } from './styles';

function Reservations() {
  const classes = useStyles();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));

  const { project } = useContext(ProjectContext);

  const [selectedReservationId, setSelectedReservationId] = useState(null);

  const [openReadReservationDialog, setOpenReadReservationDialog] =
    useState(false);

  const [openEditReservationDialog, setOpenEditReservationDialog] =
    useState(false);

  const [showReadReservationDialog, setShowReadReservationDialog] =
    useState(false);

  if (!project) return null;

  return (
    <Container className={classes.container}>
      <Box
        display={!isSm ? 'flex' : 'none'}
        justifyContent="space-between"
        alignItems="center"
        mb={5}
        minHeight={40}
      >
        <ProjectsLink mb={0} />
      </Box>

      <ReservationsListProvider>
        <ReservationsListFilters />
        <ReservationsListZeroState>
          <ReservationsList
            isAdmin
            onRead={(reservationId) => {
              setSelectedReservationId(reservationId);
              setOpenReadReservationDialog(true);
            }}
            onEdit={(reservationId) => {
              setSelectedReservationId(reservationId);
              setOpenEditReservationDialog(true);
            }}
          />
        </ReservationsListZeroState>
      </ReservationsListProvider>

      <ReadReservationDialog
        open={openReadReservationDialog}
        reservationId={selectedReservationId}
        onEdit={() => {
          setOpenReadReservationDialog(false);
          setOpenEditReservationDialog(true);
          setShowReadReservationDialog(true);
        }}
        onClose={() => {
          setOpenReadReservationDialog(false);
          setSelectedReservationId(null);
        }}
      />

      <EditReservationDialog
        open={openEditReservationDialog}
        reservationId={selectedReservationId}
        onClose={() => {
          setOpenEditReservationDialog(false);

          if (showReadReservationDialog) {
            setOpenReadReservationDialog(true);
            setShowReadReservationDialog(false);
          } else {
            setSelectedReservationId(null);
          }
        }}
      />
    </Container>
  );
}

export default Reservations;
