const GET_PROPERTIES_QUERY = `
  query GetProperties (
    $projectId: ID!
    $page: Int
    $itemsPerPage: Int
    $search: String
    $sortOptions: PropertySort
    $filterOptions: PropertyFilter
  ) {
    getProperties (
      projectId: $projectId
      page: $page
      itemsPerPage: $itemsPerPage
      search: $search
      sortOptions: $sortOptions
      filterOptions: $filterOptions
    ) {
      properties {
        _id
        projectId
        unity
        description
        observation
        status
        price
        m2price
        terrainDimension
        totalArea
        privateArea
        comparisonFront
        comparisonBottom
        comparisonLeft
        comparisonRight
        measureFront
        measureBottom
        measureLeft
        measureRight
        mark {
          posX,
          posY
        }
        createdAt
        active
      }
      page
      pages
      maxPrice
    }
  }
`;

export default GET_PROPERTIES_QUERY;
