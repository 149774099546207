import React, { useContext } from 'react';
import { Grid } from '@material-ui/core';

import { ProjectContext } from 'providers/ProjectProvider';

import Discount from 'components/Discount';
import PropertyStatusLabel from 'components/StatusLabel/PropertyStatusLabel';
import InformationBlock from 'components/InformationBlock';
import formatArea from 'utils/formatArea';
import formatPrice from 'utils/formatPrice';

export default function GeneralTab({ property }) {
  const { discount } = useContext(ProjectContext);
  return (
    <Grid container spacing={3}>
      <InformationBlock fullWidth>
        <Discount price={property.price} discount={discount.cash} />
      </InformationBlock>

      <InformationBlock label="Unidade:">{property.unity}</InformationBlock>

      <InformationBlock label="Situação:">
        <PropertyStatusLabel status={property.status} />
      </InformationBlock>

      <InformationBlock label="Preço:">
        {formatPrice(property.price)}
      </InformationBlock>

      <InformationBlock label="Preço por m²:">
        {formatPrice(property.m2price)}
      </InformationBlock>

      <InformationBlock label="Área Total:">
        {formatArea(property.totalArea)}
      </InformationBlock>

      <InformationBlock label="Dimensões do Terreno:">
        {property.terrainDimension}
      </InformationBlock>

      <InformationBlock label="Descrição:">
        {property.description}
      </InformationBlock>

      <InformationBlock label="Observação:">
        {property.observation}
      </InformationBlock>

      <InformationBlock label="Área Privativa:">
        {formatArea(property.privateArea)}
      </InformationBlock>
    </Grid>
  );
}
