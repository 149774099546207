import WebSocketController from 'utils/WebSocketController';
import { useEffect, useRef, useState } from 'react';

const socket = new WebSocketController();

export default ({
  project,
  contract,
  onRemittanceCreated,
  onSalesContractUpdated,
  onInvoiceEmitted,
}) => {
  const isMountedRef = useRef(false);

  const [remittanceCreated, setRemittanceCreated] = useState(false);
  const [updatedSalesContract, setUpdatedSalesContract] = useState(null);
  const [newEmittedInvoices, setNewEmittedInvoices] = useState(false);

  useEffect(() => {
    isMountedRef.current = true;

    socket.onSalesContractUpdated((updatedContract) => {
      setUpdatedSalesContract(updatedContract);
    });

    socket.onRemittanceCreated(() => {
      setRemittanceCreated(true);
    });

    socket.onInvoiceEmitted(() => {
      setNewEmittedInvoices(true);
    });

    return () => {
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (remittanceCreated) {
      onRemittanceCreated();
      setRemittanceCreated(false);
    }
  }, [remittanceCreated, onRemittanceCreated, project]);

  useEffect(() => {
    if (updatedSalesContract) {
      if (updatedSalesContract.numberId === contract?.numberId) {
        onSalesContractUpdated();
      }

      setUpdatedSalesContract(null);
    }
  }, [updatedSalesContract, contract, onSalesContractUpdated]);

  useEffect(() => {
    if (newEmittedInvoices) {
      onInvoiceEmitted();
      setNewEmittedInvoices(false);
    }
  }, [newEmittedInvoices, onInvoiceEmitted, project]);

  return null;
};
