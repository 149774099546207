import formatPrice from 'utils/formatPrice';
import { PAYMENT_METHODS } from '@constants/reservations/paymentMethods';

class InstallmentCalculatorModule {
  constructor({ paymentMethod, totalPrice, entrance, annualQuota }) {
    this.paymentMethod = paymentMethod;
    this.totalPrice = totalPrice;
    this.entrance = entrance;
    this.annualQuota =
      this.paymentMethod === PAYMENT_METHODS.ANNUALLY ? annualQuota : 0;
  }

  calculateMonthlyInstallment(installmentsQuantity) {
    const monthlyInstallmentsTotalValue = this.totalPrice - this.entrance;

    const annualQuotaPercent =
      (monthlyInstallmentsTotalValue * this.annualQuota) / 100;

    const monthlyInstallmentValue =
      (monthlyInstallmentsTotalValue - annualQuotaPercent) /
      installmentsQuantity;

    return {
      value: monthlyInstallmentValue,
      formattedValue: `${installmentsQuantity}x de ${formatPrice(
        monthlyInstallmentValue
      )}`,
    };
  }

  calculateAnnuallyInstallment(installmentsQuantity) {
    const annuallyInstallmentsTotalValue =
      ((this.totalPrice - this.entrance) * this.annualQuota) / 100;

    const years = Math.ceil(installmentsQuantity / 12);

    const annuallyInstallmentValue = annuallyInstallmentsTotalValue / years;

    const formattedValue = `${years}x de ${formatPrice(
      annuallyInstallmentValue
    )}`;

    return {
      value: annuallyInstallmentValue,
      formattedValue,
    };
  }
}

export default InstallmentCalculatorModule;
