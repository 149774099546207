import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import BaseList from 'components/Lists/BaseList';

import { usePropertiesListContext } from 'providers/ListProviders/PropertiesListProvider';
import useColumns from './hooks/useColumns';

function PropertiesList({
  onDetails,
  onEdit,
  onDelete,
  onSelect,
  getSelectMethods,
}) {
  const { properties, variables, pages, setPage } = usePropertiesListContext();

  const columnsParams = useMemo(
    () => ({
      onEdit,
      onDelete,
    }),
    [onEdit, onDelete]
  );

  const columns = useColumns(columnsParams);

  const handlePagination = (newPage) => setPage(newPage);

  const handleRowClick = (rowData) => {
    onDetails(rowData);
  };

  return (
    <BaseList
      columns={columns}
      data={properties}
      currentPage={variables.page}
      pages={pages}
      onPagination={handlePagination}
      onRowClick={handleRowClick}
      changedAt={variables.changedAt}
      options={{ canSelect: Boolean(onSelect) }}
      onSelect={onSelect}
      getSelectMethods={getSelectMethods}
    />
  );
}

PropertiesList.propTypes = {
  onDetails: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onSelect: PropTypes.func,
  getSelectMethods: PropTypes.func,
};

PropertiesList.defaultProps = {
  onDetails: null,
  onEdit: null,
  onDelete: null,
  onSelect: null,
  getSelectMethods: null,
};

export default PropertiesList;
