import React from 'react';
import { Box, IconButton, Grid } from '@material-ui/core';
import preventDefault from 'utils/preventDefault';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';
import ICON_TYPES from '@constants/iconsPaths';

export default function EditButton({ invoice, onSelect }) {
  return (
    <Grid item xs={12} lg="auto">
      <Box display="flex" justifyContent="flex-end">
        <IconButton
          title="Editar"
          size="small"
          onClick={preventDefault(() => {
            onSelect(invoice);
          })}
        >
          <Icon src={ICON_TYPES.LIST.edit} size={21} />
        </IconButton>
      </Box>
    </Grid>
  );
}

EditButton.propTypes = {
  onSelect: PropTypes.func.isRequired,
  invoice: PropTypes.shape({
    projectId: PropTypes.string,
    isEmitted: PropTypes.bool,
    _id: PropTypes.string,
  }).isRequired,
};
