import { createTheme } from '@material-ui/core/styles';
import typography from './typography';
import palette from './palette';
import { withOverrides } from './overrides';

const baseTheme = createTheme({
  typography,
  palette,
});

export default withOverrides(baseTheme);
