import { Box, Typography, IconButton } from '@material-ui/core';
import React from 'react';
import ICON_TYPES from '@constants/iconsPaths';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';
import useStyles from './styles';

export default function SelectedPropertiesPopup({
  length,
  onPriceAdjustment,
  onEditPrice,
  onChangeStatus,
  onClose,
}) {
  const classes = useStyles();

  if (length === 0) return null;

  return (
    <Box className={classes.card}>
      <Typography className={classes.text} variant="body3">
        {length} imóvel selecionados
      </Typography>

      <Box className={classes.actionsContainer}>
        <Box marginRight={3}>
          <IconButton
            title="Reajustar preço"
            size="small"
            onClick={onPriceAdjustment}
          >
            <Icon src={ICON_TYPES.POPUP.priceAdjustment} size={24} />
          </IconButton>
        </Box>

        <Box marginRight={3}>
          <IconButton title="Editar preço" size="small" onClick={onEditPrice}>
            <Icon src={ICON_TYPES.POPUP.editPrice} size={24} />
          </IconButton>
        </Box>

        <Box marginRight={3}>
          <IconButton
            title="Editar situação"
            size="small"
            onClick={onChangeStatus}
          >
            <Icon src={ICON_TYPES.POPUP.updateStatus} size={24} />
          </IconButton>
        </Box>

        <Box>
          <IconButton onClick={onClose} title="Fechar" size="small">
            <Icon src={ICON_TYPES.POPUP.close} size={18} />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
}

SelectedPropertiesPopup.propTypes = {
  length: PropTypes.number.isRequired,
  onPriceAdjustment: PropTypes.func.isRequired,
  onEditPrice: PropTypes.func.isRequired,
  onChangeStatus: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
