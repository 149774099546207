import { Box, Button } from '@material-ui/core';
import BaseDialog from 'components/Dialogs/BaseDialog';
import PercentField from 'components/Fields/PercentField';
import React, { useState } from 'react';

export default function PropertiesPriceAdjustmentDialog({
  open,
  onSubmit,
  onClose,
}) {
  const [percent, setPercent] = useState(0);

  const handleSubmit = (e) => {
    e.preventDefault();

    const numberPercent = Number(percent) / 100;

    onSubmit(numberPercent);
  };

  return (
    <BaseDialog
      open={open}
      title="Reajuste de preço"
      maxWidth="xs"
      onClose={onClose}
    >
      <form onSubmit={handleSubmit}>
        <Box mb={6}>
          <PercentField
            label="Adicionar reajuste em:"
            value={percent}
            onChange={(e) => setPercent(e.target.value)}
            fullWidth
            required
          />
        </Box>

        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            size="large"
            style={{ width: 208 }}
          >
            Salvar
          </Button>
        </Box>
      </form>
    </BaseDialog>
  );
}
