/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useContext, useState } from 'react';

import { ProjectContext } from 'providers/ProjectProvider';
import useGetRealEstates from './hooks/useGetRealEstates';

const RealEstatesListContext = createContext();

export default function RealEstatesListProvider({ children }) {
  const { project } = useContext(ProjectContext);
  const [search, setSearch] = useState('');
  const [variables, setVariables] = useState({
    projectId: project._id,
    page: 0,
    sortOptions: { field: 'name', direction: 'asc' },
    changedAt: Date.now(),
  });
  const { realEstates, pages, isLoading } = useGetRealEstates(
    variables,
    search
  );

  const setPage = (value) => setVariables({ ...variables, page: value });

  const setSort = (option) =>
    setVariables({
      ...variables,
      page: 0,
      sortOptions: {
        field: option.value,
        direction: option.reverse ? 'desc' : 'asc',
      },
    });

  const updateList = () =>
    setVariables({ ...variables, changedAt: Date.now() });

  return (
    <RealEstatesListContext.Provider
      value={{
        realEstates,
        pages,
        isLoading,
        search,
        variables,
        updateList,
        setPage,
        setSort,
        setSearch,
      }}
    >
      {children}
    </RealEstatesListContext.Provider>
  );
}

export function useRealEstatesListContext() {
  return useContext(RealEstatesListContext);
}
