import { Box, Button, Typography, useTheme } from '@material-ui/core'
import React, { useState } from 'react'
import validateCpf from 'utils/validateCpf'
import CPFTextField from 'components/TextFields/CPFTextField'
import useThemeClasses from 'theme/useThemeClasses'
import { BsArrowRight } from 'react-icons/bs'

export default function DocumentStep({ onBack, onSubmit }) {
    const theme = useTheme()
    const themeClasses = useThemeClasses
    const buttonClasses = themeClasses.button()

    const [focused, setFocused] = useState('')
    const [document, setDocument] = useState('')
    const [documentErr, setDocumentErr] = useState(false)

    const handleSubmit = (event) => {
        if (event) event.preventDefault()

        if (!document || !validateCpf(document)) {
            setDocumentErr(true)
            return
        }

        onSubmit(document)
    }

    const handleBack = (event) => {
        if (event) event.preventDefault()
        if (onBack) onBack()
    }

    return (
        <Box>
            <CPFTextField
                margin="dense"
                label="CPF"
                type="text"
                value={document}
                placeholder="000.000.000-00"
                onChange={(_, value) => setDocument(value)}
                onFocus={() => setFocused('document')}
                onBlur={() => {
                    setFocused(null)
                    setDocumentErr(!document || !validateCpf(document))
                }}
                InputLabelProps={{ shrink: true }}
                error={documentErr}
                fullWidth
            />

            <Box mt={0.5}>
                {documentErr && !focused && !document ? (
                    <Typography variant="caption" style={{ color: theme.palette.error.main }}>
                        Campo obrigatório
                    </Typography>
                ) : null}

                {documentErr && !focused && document && !validateCpf(document) ? (
                    <Typography variant="caption" style={{ color: theme.palette.error.main }}>
                        Documento inválido
                    </Typography>
                ) : null}
            </Box>

            <Box display="flex" justifyContent="flex-end" mt={5}>
                <Button
                    className={buttonClasses.linkButton}
                    onClick={handleBack}
                    color="secondary"
                >Voltar</Button>

                <Button
                    className={buttonClasses.buttonWithIcon}
                    endIcon={<BsArrowRight />}
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    style={{ width: 260, marginLeft: 8 }}
                >Próximo</Button>
            </Box>
        </Box>
    )
}