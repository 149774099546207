import React from 'react';
import { Grid } from '@material-ui/core';

import ContentCell from './ContentCell';

export default function InformationBlock({ children, fullWidth, label }) {
  const size = fullWidth ? 12 : 6;
  return (
    <Grid item xs={size}>
      <ContentCell label={label}>{children || '-'}</ContentCell>
    </Grid>
  );
}
