const EnvironmentModule = () => ({
    get(key) {
      const value = process.env[`REACT_APP_${key}`]
      if (value) return String(value)
    },

    isTruphy(key) {
      const value = this.get(key)
      return value && value.toLowerCase() === 'true'
    },

    isFalsy(key) {
      return !this.isTruphy(key)
    },
    
    isPropertySelectionEnabled() {
      return this.isTruphy('ENABLE_PROPERTY_SELECTION')
    }
})

export default EnvironmentModule()
