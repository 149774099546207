class FeatureFlags {
  constructor(project, me) {
    this.enabledFeatures = [
      ...(project.enabledFeatures || []),
      ...(me?.enabledFeatures || []),
    ];
  }

  isEnabled(flag) {
    return this.enabledFeatures.indexOf(flag) > -1;
  }

  isDisabled(flag) {
    return !this.isEnabled(flag);
  }
}

export default FeatureFlags;
