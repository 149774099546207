/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable */
import React, { useContext, useEffect, useRef, useState } from 'react';
import Map from 'components/InteractiveMap';
import { Box, Grid, Typography, useTheme } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import api from 'services/api';
import Loading from 'components/Loading';
import getPropertyMarksQuery from 'queries/getPropertyMarksQuery';
import urlBuilder from 'utils/urlBuilder';
import WebSocketController from 'utils/WebSocketController';
import { ProjectContext } from 'providers/ProjectProvider';
import NewMarkDialog from './components/NewMarkDialog';
import PropertyDialog from './components/PropertyDialog';
import UploadButton from './components/UploadButton';
import { useStyles } from './styles';
import handlerError from 'utils/handleError';

const socket = new WebSocketController();

export default function EditMap({ match }) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();

  const { project } = useContext(ProjectContext);

  const [propertyMarks, setPropertyMarks] = useState([]);

  const [newMarkPosition, setNewMarkPosition] = useState({ x: 0, y: 0 });
  const [newMarkPositionOffset, setNewMarkPositionOffset] = useState({
    x: 0,
    y: 0,
  });
  const [showNewMarkDialog, setShowNewMarkDialog] = useState(false);
  const [showNewMarkController, setShowNewMarkController] = useState(false);

  const [selectedPropertyId, setSelectedPropertyId] = useState(null);
  const [showPropertyDialog, setShowPropertyDialog] = useState(false);
  const [showPropertyController, setShowPropertyController] = useState(false);

  const [needReload, setNeedReload] = useState(false);

  const showDialog = showNewMarkDialog || showPropertyDialog;

  const loadPropertyMarks = (projectId) => {
    api
      .call(getPropertyMarksQuery(projectId))
      .then((data) => {
        if (data?.getPropertyMarks) {
          setPropertyMarks(data.getPropertyMarks);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const selectPropertyMark = (propertyId) => {
    setPropertyMarks(
      propertyMarks.map((mark) =>
        mark.propertyId === propertyId ? { ...mark, selected: true } : mark
      )
    );
  };

  useEffect(() => {
    loadPropertyMarks(project._id);
  }, []);

  function openNewMarkDialog(coordinates) {
    setNewMarkPosition(coordinates);
    setNewMarkPositionOffset({ x: 0, y: 0 });
    setShowNewMarkDialog(true);
    setShowNewMarkController(true);

    setShowPropertyDialog(false);
    setShowPropertyController(false);
  }

  const openPropertyDialog = (propertyId) => {
    setSelectedPropertyId(propertyId);
    selectPropertyMark(propertyId);
    setShowPropertyDialog(true);
    setShowPropertyController(true);

    setShowNewMarkDialog(false);
    setShowNewMarkController(false);
  };

  const deselectPropertyMark = (propertyId) => {
    setPropertyMarks(
      propertyMarks.map((mark) =>
        mark.propertyId === propertyId ? { ...mark, selected: false } : mark
      )
    );
  };

  function closeEditMode(e) {
    e.preventDefault();
    if (project.mapFileId) {
      history.push(`/${match.params.projectSlug}/mapa`);
    } else {
      history.push(`/${match.params.projectSlug}/imoveis`);
    }
  }

  useEffect(() => {
    const reload = () => {
      setNeedReload(true);
    };

    socket.onPropertyUpdated(reload);
    socket.onPropertyDeleted(reload);
    socket.onPropertyMarkCreated(reload);
    socket.onPropertyMarkDeleted(reload);
    socket.onReservationCreated(reload);
    socket.onReservationUpdated(reload);

    return () => socket.destroy();
  }, []);

  useEffect(() => {
    if (needReload) {
      loadPropertyMarks(project?._id);

      setNeedReload(false);
    }
  }, [project, needReload]);

  if (!project) return <Loading />;

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={50}
        bgcolor={theme.palette.secondary.main}
      >
        <Typography
          variant="body1"
          align="center"
          style={{ color: '#FFFFFFAA' }}
        >
          {'Você está no modo de edição. '}
          <a href="# " onClick={closeEditMode} style={{ color: '#FFF' }}>
            Sair
          </a>
        </Typography>
      </Box>

      <Box className={classes.root}>
        <Grid container>
          <Grid item xs={12} sm={7} md={5}>
            <Box className={`dialog ${showDialog && 'open'}`}>
              <Box>
                {showNewMarkDialog && (
                  <NewMarkDialog
                    projectId={project._id}
                    posX={newMarkPosition.x + newMarkPositionOffset.x}
                    posY={newMarkPosition.y + newMarkPositionOffset.y}
                    onUpdate={() => loadPropertyMarks(project._id)}
                    onClose={() => {
                      setShowNewMarkDialog(false);
                      setShowNewMarkController(false);
                    }}
                  />
                )}

                {showPropertyDialog && (
                  <PropertyDialog
                    propertyId={selectedPropertyId}
                    onUpdate={() => loadPropertyMarks(project._id)}
                    onClose={() => {
                      deselectPropertyMark(selectedPropertyId);
                      setShowPropertyDialog(false);
                      setShowPropertyController(false);
                    }}
                  />
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={showDialog ? 5 : 12} md={showDialog ? 7 : 12}>
            <Box className="map" position="relative">
              {project.mapFileId && (
                <Map
                  source={project.mapUrl}
                  mapDimension={{
                    width: project.mapWidth,
                    height: project.mapHeight,
                  }}
                  defaultZoom={location.state?.zoom}
                  defaultPosition={location.state?.position}
                  onMapClick={openNewMarkDialog}
                  marks={propertyMarks}
                  onMarkClick={(mark) => openPropertyDialog(mark.propertyId)}
                />
              )}

              <NewMarkController
                show={showNewMarkController}
                onMove={(pos) => {
                  setNewMarkPositionOffset(pos);
                }}
              />

              {showPropertyController && <Box className="overlay" />}

              <Box
                position="absolute"
                top={0}
                left={0}
                width="100%"
                display="flex"
                justifyContent="flex-end"
                p={1}
              >
                <UploadButton />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

function NewMarkController({ show, onMove }) {
  const containerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [dragStartPosition, setDragStartPosition] = useState({ x: 0, y: 0 });
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const size = 24;

  useEffect(() => {
    if (!show) {
      setPosition({ x: 0, y: 0 });
    }
  }, [show]);

  function handleMouseDown(e) {
    if (!isDragging) {
      setDragStartPosition({
        x: e.clientX,
        y: e.clientY,
      });

      setIsDragging(true);
    }
  }

  function handleMouseMove(e) {
    if (isDragging) {
      const offset = {
        x: dragStartPosition.x - e.clientX,
        y: dragStartPosition.y - e.clientY,
      };

      setDragStartPosition({
        x: e.clientX,
        y: e.clientY,
      });

      const rangeX = containerRef.current.clientWidth / 2;
      const rangeY = containerRef.current.clientHeight / 2;

      const newPosition = {
        x: Math.min(Math.max(-rangeX, position.x - offset.x), rangeX),
        y: Math.min(Math.max(-rangeY, position.y - offset.y), rangeY),
      };

      setPosition(newPosition);
      if (onMove) onMove(newPosition);
    }
  }

  function stopDragging() {
    setIsDragging(false);
  }

  if (!show) return null;

  return (
    <Box
      position="absolute"
      top={0}
      height="100%"
      left={0}
      width="100%"
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={stopDragging}
      onMouseLeave={stopDragging}
      overflow="hidden"
      bgcolor="rgba(0, 0, 0, 0.5)"
      style={{ cursor: 'all-scroll' }}
      ref={containerRef}
    >
      <Box position="absolute" top="50%" left="50%" width="50%" height="50%">
        <Box
          width={size}
          height={size}
          borderRadius="50%"
          bgcolor="#000"
          style={{
            marginLeft: position.x - size / 2,
            marginTop: position.y - size / 2,
            border: '2px solid white',
            boxShadow: '0 1px 0 rgba(0,0,0,.25) , 0 1px 2px rgba(0,0,0,.4)',
          }}
        />
      </Box>
    </Box>
  );
}
