import INVITE_STATUS from '@constants/invites/status';

const FILTER_OPTIONS = [
  { id: 1, value: null, label: 'Todos os convites' },
  {
    id: 2,
    value: INVITE_STATUS.REJECTED.value,
    label: INVITE_STATUS.REJECTED.label,
  },
  {
    id: 3,
    value: INVITE_STATUS.PENDING.value,
    label: INVITE_STATUS.PENDING.label,
  },
  {
    id: 4,
    value: INVITE_STATUS.ACCEPTED.value,
    label: INVITE_STATUS.ACCEPTED.label,
  },
  {
    id: 5,
    value: INVITE_STATUS.EXPIRED.value,
    label: INVITE_STATUS.EXPIRED.label,
  },
  {
    id: 6,
    value: INVITE_STATUS.CANCELED.value,
    label: INVITE_STATUS.CANCELED.label,
  },
];

export default FILTER_OPTIONS;
