import WebSocketController from 'utils/WebSocketController';
import { useEffect, useState } from 'react';

const socket = new WebSocketController();

export default (onUpdate) => {
  const [hasUpdate, setHasUpdate] = useState(false);

  useEffect(() => {
    socket.onSalesContractUpdated(() => {
      setHasUpdate(true);
    });

    socket.onSalesContractInvoiceCreated(() => {
      setHasUpdate(true);
    });

    socket.onSalesContractInvoiceUpdated(() => {
      setHasUpdate(true);
    });

    socket.onInvoiceEmitted(() => {
      setHasUpdate(true);
    });

    return () => {
      socket.destroy();
    };
  }, []);

  useEffect(() => {
    if (hasUpdate) {
      onUpdate();

      setHasUpdate(false);
    }
  }, [hasUpdate]);

  return null;
};
