import { Chip, withStyles } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

function BaseStatusLabel({ label, color, size = 'small' }) {
  const StyledChip = withStyles({
    root: {
      ...color,
    },
  })(Chip);

  return <StyledChip size={size} label={label} />;
}

BaseStatusLabel.propTypes = {
  label: PropTypes.string,
  color: PropTypes.shape({}),
  size: PropTypes.string,
};

BaseStatusLabel.defaultProps = {
  label: '',
  color: {},
  size: 'small',
};

export default BaseStatusLabel;
