import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Box, Button } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';

import ICON_TYPES from '@constants/iconsPaths';

import api from 'services/api';
import AlertModule from 'modules/AlertModule';
import { ProjectContext } from 'providers/ProjectProvider';

import Map from 'components/InteractiveMap';
import MapsZeroState from 'components/ZeroState/MapsZeroState';
import Icon from 'components/Icon';
import ReadReceiptDialog from 'components/Dialogs/ReadDialogs/ReadReceiptDialog';

import GET_VISIBLE_PROPERTY_MARKS_QUERY from 'queries/getVisiblePropertyMarksQuery';

import WebSocketController from 'utils/WebSocketController';

import useQuery from 'hooks/useQuery';

import PropertyResume from './PropertyResume';
import MakeReservation from './MakeReservation';

import useStyles from './styles';

const socket = new WebSocketController();

export default function InteractiveMap() {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const { project } = useContext(ProjectContext);

  const [propertyMarks, setPropertyMarks] = useState([]);
  const [mapIntegrity, setMapIntegrity] = useState(null);
  const [isPropertyMarkLoaded, setPropertyMarkLoaded] = useState(false);
  const [reservation, setReservation] = useState(null);
  const [openReceiptDialog, setOpenReceiptDialog] = useState(false);
  const [selectedPropertyId, setSelectedPropertyId] = useState(null);
  const [showMakeReservation, setShowMakeReservation] = useState(false);
  const [hasUpdate, setHasUpdate] = useState(false);
  const [executeGetVisiblePropertyMarks] = useQuery(
    GET_VISIBLE_PROPERTY_MARKS_QUERY
  );
  const [showPropertyResume, setShowPropertyResume] = useState(false);
  const hasMap = Boolean(project.mapFileId);

  const isLoading = useMemo(() => {
    if (!project) return true;
    if (mapIntegrity === null) return true;
    if (!isPropertyMarkLoaded) return true;
    return false;
  }, [mapIntegrity, isPropertyMarkLoaded]);

  const checkMapIntegrity = (projectId) => {
    api
      .getMap(projectId)
      .then(() => {
        setMapIntegrity(true);
      })
      .catch(() => {
        if (hasMap) {
          setMapIntegrity(false);
        } else {
          setMapIntegrity(true);
        }
      });
  };

  const loadPropertyMarks = (projectId) => {
    executeGetVisiblePropertyMarks({ projectId })
      .then(({ getVisiblePropertyMarks: data }) => {
        setPropertyMarks(data);

        if (!isPropertyMarkLoaded) {
          setPropertyMarkLoaded(true);
        }
      })
      .catch(
        AlertModule.onError('Não foi possível carregar os imóveis no mapa.')
      );
  };

  useEffect(() => {
    if (project) {
      checkMapIntegrity(project._id);
      loadPropertyMarks(project._id);
    } else {
      setMapIntegrity(null);
      setPropertyMarkLoaded(false);
    }
  }, [project]);

  const handleMarkClick = (mark) => {
    setSelectedPropertyId(mark.propertyId);
    setShowPropertyResume(true);
  };

  useEffect(() => {
    const triggerReload = () => {
      setHasUpdate(true);
    };

    socket.onPropertyUpdated(triggerReload);
    socket.onPropertyDeleted(triggerReload);
    socket.onPropertyMarkCreated(triggerReload);
    socket.onPropertyMarkDeleted(triggerReload);
    socket.onReservationCreated(triggerReload);
    socket.onReservationUpdated(triggerReload);

    return () => socket.destroy();
  }, []);

  useEffect(() => {
    if (hasUpdate) {
      loadPropertyMarks(project?._id);
      setHasUpdate(false);
    }
  }, [project, hasUpdate]);

  return (
    <Box className={classes.root}>
      <MapsZeroState
        mapIntegrity={mapIntegrity}
        isLoading={isLoading}
        onReload={() => {
          setMapIntegrity(null);

          setTimeout(() => {
            checkMapIntegrity(project._id);
          }, 1000);
        }}
      >
        <Box className={classes.mapContainer}>
          <Map
            source={project.mapUrl}
            mapDimension={{
              width: project.mapWidth,
              height: project.mapHeight,
            }}
            defaultZoom={location.state?.zoom}
            defaultPosition={location.state?.position}
            marks={propertyMarks}
            selectedPropertyId={selectedPropertyId}
            onMarkClick={handleMarkClick}
            animated
          />
          <Box className={classes.header}>
            <Button
              variant="text"
              color="secondary"
              name="edit-map-button"
              onClick={() => history.push(`/${project.slug}`)}
              startIcon={<Icon src={ICON_TYPES.BASE.back} size={18} />}
            >
              Imóveis
            </Button>
            <Box>
              <Button
                variant="contained"
                color="primary"
                name="edit-map-button"
                onClick={() => history.push(`/${project.slug}/mapa/editar`)}
                style={{ paddingTop: 0, paddingBottom: 0 }}
              >
                Editar mapa
              </Button>
            </Box>
          </Box>

          <Box
            className={classes.overlay}
            hidden={!(showMakeReservation || showPropertyResume)}
          />
        </Box>
      </MapsZeroState>

      <PropertyResume
        onClose={() => {
          setSelectedPropertyId(null);
          setShowPropertyResume(false);
        }}
        onReserve={() => {
          setShowMakeReservation(true);
        }}
        open={showPropertyResume}
        propertyId={selectedPropertyId}
      />
      <MakeReservation
        open={showMakeReservation}
        propertyId={selectedPropertyId}
        onPropertyReserved={(newReservation) => {
          setOpenReceiptDialog(true);
          setReservation(newReservation);
        }}
        onClose={() => {
          setShowMakeReservation(false);
        }}
      />
      <ReadReceiptDialog
        open={openReceiptDialog}
        reservation={reservation}
        onClose={() => {
          setOpenReceiptDialog(false);
          setReservation(null);
        }}
      />
    </Box>
  );
}
