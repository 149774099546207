const formats = {
  numeric: {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  },
  longDate: {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  },
};

export default function formatDate(dateStr, format = 'numeric') {
  if (dateStr) {
    return new Date(dateStr).toLocaleDateString('pt-BR', formats[format]);
  }
  return null;
}
