import React from 'react';

import { Grid, Typography, useTheme } from '@material-ui/core';
import InformationBlock from 'components/InformationBlock';

import formatDate from 'utils/formatDate';
import formatPrice from 'utils/formatPrice';

export default function Annualy({ reservation, annuallyInstallment }) {
  const theme = useTheme();
  if (!annuallyInstallment) return null;
  return (
    <>
      <Grid item xs={12}>
        <Typography
          variant="body2"
          style={{ color: theme.palette.secondary.dark }}
        >
          Anual
        </Typography>
      </Grid>
      <InformationBlock label="Parcela">
        {reservation.installments} x {formatPrice(annuallyInstallment)}
      </InformationBlock>
      <InformationBlock label="1° prestação">
        {formatDate(reservation.firstAnnuallyInstallmentDate)}
      </InformationBlock>
    </>
  );
}
