const updateReservationQuery = `
    mutation UpdateReservation (
        $id: ID!
        $status: String!
        $customerId: ID
        $realEstateId: ID
        $realEstateAgentId: ID
        $expiration: String
        $paymentMethod: String!
        $entrance: Float!
        $installments: Int!
        $firstMonthlyInstallmentDate: String
        $firstAnnuallyInstallmentDate: String
        $observation: String
        $discount:Float
    ) {
        updateReservation(
            id: $id
            data: {
                status: $status
                customerId: $customerId
                realEstateId: $realEstateId
                realEstateAgentId: $realEstateAgentId
                paymentMethod: $paymentMethod
                entrance: $entrance
                installments: $installments
                firstMonthlyInstallmentDate: $firstMonthlyInstallmentDate
                firstAnnuallyInstallmentDate: $firstAnnuallyInstallmentDate
                expiration: $expiration
                observation: $observation
                discount:$discount
            }
        )
    }
  `;

export default updateReservationQuery;
