import { useCallback, useEffect, useRef, useState } from 'react';

import useQuery from 'hooks/useQuery';
import AlertModule from 'modules/AlertModule';
import GET_RETURN_FILES_QUERY from 'queries/getReturnFilesQuery';

export default function useGetReturnFiles(variables, search) {
  const [pages, setPages] = useState(1);
  const [returnFiles, setReturnFiles] = useState([]);
  const [executeGetReturnFiles, isLoading] = useQuery(GET_RETURN_FILES_QUERY);

  const isFirstRender = useRef(true);

  const onResolve = ({ getReturnFiles: data }) => {
    setPages(data.pages);
    setReturnFiles(data.returnFiles);

    return data;
  };
  const onReject = AlertModule.onError(
    'Não foi possível carregar a lista de retorno.'
  );

  const getReturnFilesBySearch = useCallback(
    () =>
      executeGetReturnFiles
        .debounced({ ...variables, search })
        .then(onResolve)
        .catch(onReject),
    [variables, search]
  );
  const getReturnFiles = useCallback(
    () =>
      executeGetReturnFiles({ ...variables, search })
        .then(onResolve)
        .catch(onReject),
    [variables, search]
  );
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    getReturnFilesBySearch();
  }, [search]);

  useEffect(() => {
    getReturnFiles();
  }, [variables]);

  return { returnFiles, pages, isLoading };
}
