import React, { useContext } from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ProjectContext } from 'providers/ProjectProvider';
import { useInvoicesListContext } from 'providers/ListProviders/InvoicesListProvider';

import Loading from 'components/Loading';

import InvoicesListZeroStateSvg from 'assets/images/ZeroStates/InvoicesListZeroStateSvg';

function InvoicesListZeroState({ children }) {
  const history = useHistory();
  const { project } = useContext(ProjectContext);
  const { invoices, variables, search, isLoading } = useInvoicesListContext();
  const { dueDateStart, dueDateEnd, paidAtStart, paidAtEnd, status, type } =
    variables.filterOptions;

  const hasData = Boolean(invoices.length);
  const hasSearchFilter = search;
  const hasStatusFilter = status !== 'all';
  const hasPaidAtFilter = paidAtStart || paidAtEnd;
  const hasDueDateFilter = dueDateStart || dueDateEnd;
  const hasTypeFilter = type !== null;

  const hasSomeFilter =
    hasSearchFilter ||
    hasStatusFilter ||
    hasPaidAtFilter ||
    hasDueDateFilter ||
    hasTypeFilter;

  const isZeroState = !hasData && !hasSomeFilter && !search;

  const mainMessage = !isZeroState
    ? 'Não encontramos nenhum título, tente uma nova pesquisa'
    : 'Nenhum título foi criado, comece gerando os contratos';

  if (isLoading) {
    return <Loading height={100} />;
  }
  if (hasData) {
    return children;
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="100%"
      flexDirection="column"
      mt={10}
    >
      <Box>
        <InvoicesListZeroStateSvg />
      </Box>
      <Box
        color="#495058"
        justifyContent="center"
        alignItems="center"
        display="flex"
        mt={3}
        width="28%"
      >
        <Typography align="center" display="block" variant="h3">
          {mainMessage}
        </Typography>
      </Box>{' '}
      {isZeroState && (
        <Box
          color="#767C81"
          justifyContent="center"
          alignItems="center"
          display="flex"
          mt={5}
        >
          <Button
            onClick={() => history.push(`/${project.slug}/contratos`)}
            color="primary"
            variant="contained"
            size="large"
          >
            Ir para contratos
          </Button>
        </Box>
      )}
    </Box>
  );
}

export default InvoicesListZeroState;

InvoicesListZeroState.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};
