import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  Select,
  Typography,
  useTheme,
} from '@material-ui/core';
import { BsArrowRight } from 'react-icons/bs';

import InstallmentCalculatorModule from 'modules/InstallmentCalculatorModule';
import { ProjectContext } from 'providers/ProjectProvider';

import CurrencyTextField from 'components/TextFields/CurrencyTextField';
import RealEstateAgentAutoComplete from 'components/Autocompletes/RealEstateAgentAutoComplete';

import formatPrice from 'utils/formatPrice';
import useThemeClasses from 'theme/useThemeClasses';
import { PAYMENT_METHODS } from '@constants/reservations/paymentMethods';
import Discount from 'components/Discount';
import InputField from 'components/InputField';
import getPaymentOptions from './utils/getPaymentOptions';

export default function PaymentMethodStep({
  price,
  entranceFee,
  annualQuota,
  onBack,
  onSubmit,
  projectId,
  isRealEstateAccess,
  ...props
}) {
  const theme = useTheme();
  const themeClasses = useThemeClasses;
  const buttonClasses = themeClasses.button();
  const { discount } = useContext(ProjectContext);
  const [paymentMethod, setPaymentMethod] = useState(props.paymentMethod);
  const priceWithDiscount = useMemo(
    () => price - price * discount[paymentMethod],
    [paymentMethod]
  );
  const minimumEntrance = (priceWithDiscount * entranceFee) / 100;
  const [entrance, setEntrance] = useState(props.entrance || minimumEntrance);
  const [installments, setInstallments] = useState(props.installments);
  const [installmentOptions, setInstallmentOptions] = useState([]);
  const [realEstateAgent, setRealEstateAgent] = useState(props.realEstateAgent);

  const paymentMethodOptions = getPaymentOptions(discount);

  const isEntranceInvalid = entrance < minimumEntrance || entrance > price;

  const installmentCalculator = new InstallmentCalculatorModule({
    paymentMethod,
    totalPrice: priceWithDiscount,
    entrance,
    annualQuota,
  });

  const monthlyInstallment =
    installmentCalculator.calculateMonthlyInstallment(installments);
  const annuallyInstallment =
    installmentCalculator.calculateAnnuallyInstallment(installmentCalculator);

  const handleSubmit = () => {
    if (entrance < minimumEntrance || entrance > price) return;
    if (isRealEstateAccess && !realEstateAgent) return;

    const hasMonthlyInstallment = paymentMethod !== PAYMENT_METHODS.CASH;
    const hasAnnuallyInstallment = paymentMethod === PAYMENT_METHODS.ANNUALLY;

    const data = {
      paymentMethod,
      entrance,
      installments,
      monthlyInstallment: hasMonthlyInstallment ? monthlyInstallment : null,
      annuallyInstallment: hasAnnuallyInstallment ? annuallyInstallment : null,
      realEstateAgent,
      discount: discount[paymentMethod],
    };

    onSubmit(data);
  };

  const handleBack = (event) => {
    if (event) event.preventDefault();
    if (onBack) onBack();
  };

  useEffect(() => {
    if (paymentMethod !== PAYMENT_METHODS.CASH) {
      const newInstallmentOptions = props.installmentOptions.map((value) => {
        const installment =
          installmentCalculator.calculateMonthlyInstallment(value);

        return {
          value,
          label: installment.formattedValue,
        };
      });

      if (!installments) {
        setInstallments(newInstallmentOptions[0].value);
      }

      setInstallmentOptions(newInstallmentOptions);
    }
  }, [price, annualQuota, props.installmentOptions, paymentMethod, entrance]);

  return (
    <Box>
      {isRealEstateAccess && (
        <InputField label="Corretor">
          <RealEstateAgentAutoComplete
            placeholder="Nenhum corretor selecionado"
            projectId={projectId}
            value={realEstateAgent}
            onChange={(value) => setRealEstateAgent(value)}
            required
          />
        </InputField>
      )}

      <InputField fullWidth label="Forma de pagamento">
        <FormControl fullWidth>
          <Select
            native
            value={paymentMethod}
            onChange={(e) => setPaymentMethod(e.target.value)}
          >
            {paymentMethodOptions}
          </Select>
        </FormControl>
      </InputField>

      {paymentMethod !== PAYMENT_METHODS.CASH && (
        <>
          <InputField fullWidth label="Entrada">
            <CurrencyTextField
              value={entrance}
              onChange={(_, value) => setEntrance(value)}
              error={isEntranceInvalid}
              fullWidth
            />

            <Typography
              variant="caption"
              style={{
                color: isEntranceInvalid ? 'red' : theme.palette.secondary.main,
              }}
            >
              Valor mínimo: {formatPrice(minimumEntrance)}
            </Typography>
          </InputField>
          <InputField fullWidth label="Parcelas Mensais">
            <FormControl fullWidth>
              <Select
                native
                value={installments}
                onChange={(e) => setInstallments(e.target.value)}
              >
                {installmentOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Select>
            </FormControl>
          </InputField>
        </>
      )}

      {paymentMethod === PAYMENT_METHODS.ANNUALLY ? (
        <InputField fullWidth label="Parcelas anuais">
          <Typography variant="body1">
            {annuallyInstallment.formattedValue}
          </Typography>
        </InputField>
      ) : null}

      {discount[paymentMethod] > 0 && (
        <Discount price={price} discount={discount[paymentMethod]} />
      )}

      <Box display="flex" justifyContent="flex-end" mt={5}>
        <Button
          className={buttonClasses.linkButton}
          onClick={handleBack}
          color="secondary"
        >
          Voltar
        </Button>

        <Button
          className={buttonClasses.buttonWithIcon}
          endIcon={<BsArrowRight />}
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          style={{ width: 260, marginLeft: 8 }}
        >
          Próximo
        </Button>
      </Box>
    </Box>
  );
}
