import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  loginLink: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: '700',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    letterSpacing: 1,
  },
  privacyPolicyLink: {
    fontSize: '12px',
    lineHeight: '18px',
    color: theme.palette.grey[700],
  },
  tableLink: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: '700',
    textTransform: 'uppercase',
    textDecoration: 'underline',
    color: theme.palette.text.primary,
    letterSpacing: 1,
  },

  tableLink2: {
    fontFamily: theme.typography.fontFamily,
    textDecoration: 'underline',
    color: theme.palette.primary.main,
    letterSpacing: 1,
  },

  tableLink3: {
    fontFamily: theme.typography.fontFamily,
    textDecoration: 'underline',
    color: theme.palette.text.primary,
    letterSpacing: 1,
  },

  teamPaperLink: {
    fontFamily: theme.typography.fontFamily,
    display: 'inline-block',
    textDecoration: 'none',
    textTransform: 'uppercase',
    color: theme.palette.text.secondary,
    letterSpacing: 1,

    '&:hover': {
      textDecoration: 'underline',
      color: theme.palette.text.primary,
    },

    '&.active': {
      textDecoration: 'underline',
      color: theme.palette.text.primary,
    },
  },

  paginationLink: {
    display: 'inline-block',
    color: theme.palette.text.secondary,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize,
    letterSpacing: '1px',
    textDecoration: 'none !important',
    margin: '0 8px',

    '&:hover': {
      textDecoration: 'none !important',
      color: theme.palette.text.primary,
      borderBottom: `2px solid ${theme.palette.text.primary}`,

      '& svg': {
        color: theme.palette.text.primary,
      },
    },

    '&.active': {
      color: theme.palette.text.primary,
      borderBottom: `2px solid ${theme.palette.text.primary}`,

      '& svg': {
        color: theme.palette.text.primary,
      },
    },
  },

  sortLink: {
    color: theme.palette.text.primary,
  },

  filterLink: {
    color: theme.palette.text.primary,
  },
}));
