const CREATE_REAL_ESTATE_AGENT_MUTATION = `
  mutation CreateRealEstateAgent (
    $projectId: ID!
    $name: String!
    $cpf: String!
    $rg: String
    $creci: String!
    $active: Boolean!
    $gender: String
    $realEstateId: ID
    $username: String
    $phone: String
    $phone2: String
    $email: String
    $postalCode: String
    $address: String
    $addressNumber: String
    $addressComplement: String
    $district: String
    $city: String
    $state: String
  ) {
    createRealEstateAgent (
      projectId: $projectId
      data: {
        name: $name
        cpf: $cpf
        rg: $rg
        creci: $creci
        active: $active
        gender: $gender
        realEstateId: $realEstateId
        username: $username
        phone: $phone
        phone2: $phone2
        email: $email
        postalCode: $postalCode
        address: $address
        addressNumber: $addressNumber
        addressComplement: $addressComplement
        district: $district
        city: $city
        state: $state
      } 
    ) {
      _id
      invited
    }
  }
`;

export default CREATE_REAL_ESTATE_AGENT_MUTATION;
