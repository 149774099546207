import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';

import { useInvoicesListContext } from 'providers/ListProviders/InvoicesListProvider';
import api from 'services/api';

import SearchListTextField from 'components/SearchListTextField';
import FilterSelect from 'components/FilterSelect';
import ExportListButton from 'components/ExportListButton';
import SortSelect from 'components/SortSelect';
import PaymentDateFilter from './components/PaymentDateFilter';
import DueDateFilter from './components/DueDateFilter';

import {
  TYPE_FILTER_OPTIONS,
  STATUS_FILTER_OPTIONS,
} from './constants/filterOptions';
import SORT_OPTIONS from './constants/sortOptions';

import useStyles from '../styles';
import getSelectedFilterOption from '../utils/getSelectedFilterOption';
import getSelectedSortOption from '../utils/getSelectedSortOption';

export default function InvoicesListFilters() {
  const {
    variables,
    setFilter,
    setDueDateFilter,
    setPaymentDateFilter,
    setSort,
    search,
    setSearch,
  } = useInvoicesListContext();

  const classes = useStyles();
  const typeFilterOption = getSelectedFilterOption(
    TYPE_FILTER_OPTIONS,
    variables.filterOptions.type
  );
  const statusFilterOption = getSelectedFilterOption(
    STATUS_FILTER_OPTIONS,
    variables.filterOptions.status
  );

  const sortOption = getSelectedSortOption(SORT_OPTIONS, variables.sortOptions);

  const handleExport = () => {
    const exportVariables = { ...variables };
    delete exportVariables.page;
    api.exportInvoices(exportVariables);
  };

  return (
    <Box mt={1} mb={5}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs="auto">
          <Typography className="fw700" variant="h5" color="textPrimary">
            Títulos
          </Typography>
        </Grid>
        <Grid item xs="auto">
          <Box minWidth={285}>
            <SearchListTextField
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md>
          <Box className={classes.listFilters}>
            <FilterSelect
              value={typeFilterOption}
              options={TYPE_FILTER_OPTIONS}
              icon="parcel"
              onChange={(option) => setFilter({ type: option.value })}
            />

            <FilterSelect
              value={statusFilterOption}
              options={STATUS_FILTER_OPTIONS}
              onChange={(option) => setFilter({ status: option.value })}
            />

            <PaymentDateFilter onApply={setPaymentDateFilter} />

            <DueDateFilter onApply={setDueDateFilter} />

            <SortSelect
              value={sortOption}
              options={SORT_OPTIONS}
              onChange={setSort}
            />

            <ExportListButton onClick={handleExport} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
