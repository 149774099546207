import { Typography } from '@material-ui/core';
import React from 'react';

export default function ContentCell({ label, children }) {
  return (
    <>
      <Typography variant="h6" color="textSecondary">
        {label}
      </Typography>
      <Typography variant="body1">{children}</Typography>
    </>
  );
}
