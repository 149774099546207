const EDIT_PROPERTIES_PRICE_MUTATION = `
  mutation EditPropertiesPrice (
    $projectId: ID!
    $propertiesIds: [ID]!
    $type: String!
    $value: Float!
  ) {
    editPropertiesPrice (
      projectId: $projectId
      propertiesIds: $propertiesIds
      type: $type
      value: $value
    )
  }
`;

export default EDIT_PROPERTIES_PRICE_MUTATION;
