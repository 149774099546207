import {
  Box,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import SideTabItem from './link';

export default function SideTab({ title, items, value, onChange, children }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid container spacing={5}>
      <Grid item xs={12} md={2}>
        <Box mb={2.5}>
          <Typography className="fw700" color="textPrimary">
            {title}
          </Typography>
        </Box>

        <Divider />

        <Box display="flex" flexDirection="column" my={2.5}>
          {items.map((item) => (
            <Box mb={2.5} key={item.id}>
              <SideTabItem
                label={item.label}
                selected={value === item.id}
                onClick={(e) => {
                  e.preventDefault();
                  onChange(item.id);
                }}
              />
            </Box>
          ))}
        </Box>
      </Grid>

      {!isMobile && (
        <Grid item md="auto">
          <Box height="100%" borderLeft="1px solid rgba(0, 0, 0, 0.12)" />
        </Grid>
      )}

      <Grid item xs={12} md>
        {children}
      </Grid>
    </Grid>
  );
}

SideTab.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
    }).isRequired
  ).isRequired,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};
