/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Link } from '@material-ui/core';
import React, { useMemo } from 'react';
import useThemeClasses from 'theme/useThemeClasses';
import PropTypes from 'prop-types';
import PaginationLink from './paginationLink';

function Pagination({
  currentPage,
  pages,
  options = { offset: 4 },
  onPagination,
}) {
  const themeClasses = useThemeClasses;
  const linkClasses = themeClasses.link();

  const visiblePages = useMemo(() => {
    const value = [];
    const { offset } = options;

    let start = currentPage - offset;
    const leftOffset = start < 0 ? start * -1 : 0;

    let end = currentPage + offset;
    const rightOffset = end >= pages ? end - pages + 1 : 0;

    start -= rightOffset;
    end += leftOffset;

    for (let i = start; i <= end; i += 1) {
      if (i >= 0 && i < pages) {
        value.push(i);
      }
    }

    return value;
  }, [currentPage, pages]);

  const showLeftArrow = pages <= 0;
  const showRightArrow = currentPage >= pages - 1;

  const onLinkClick = (page) => {
    onPagination(page);
  };

  return (
    <Box display="flex" justifyContent="center" p={2.5}>
      <Box hidden={showLeftArrow}>
        <Link
          className={linkClasses.paginationLink}
          href="# "
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onLinkClick(currentPage - 1);
          }}
        >
          <svg
            width="6"
            height="10"
            viewBox="0 0 6 10"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M5.66665 0.833307C5.34127 0.50792 4.81371 0.507919 4.48832 0.833307L1.02876 4.29287C0.638237 4.68339 0.638238 5.31656 1.02876 5.70708L4.48832 9.16664C4.81371 9.49203 5.34127 9.49203 5.66665 9.16664C5.99204 8.84125 5.99204 8.31369 5.66665 7.98831L3.38543 5.70708C2.9949 5.31656 2.9949 4.68339 3.38543 4.29287L5.66665 2.01164C5.99204 1.68625 5.99204 1.15869 5.66665 0.833307Z" />
          </svg>
        </Link>
      </Box>

      {visiblePages.map((page) => (
        <Box key={page}>
          <PaginationLink
            className={linkClasses.paginationLink}
            page={page}
            selected={page === currentPage}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onLinkClick(page);
              window.scrollTo({ x: 0, y: 0 });
            }}
          />
        </Box>
      ))}

      <Box hidden={showRightArrow}>
        <Link
          className={linkClasses.paginationLink}
          href="# "
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onLinkClick(currentPage + 1);
          }}
        >
          <svg
            width="6"
            height="10"
            viewBox="0 0 6 10"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0.333345 0.833307C0.658733 0.50792 1.18629 0.507919 1.51168 0.833307L4.97124 4.29287C5.36176 4.68339 5.36176 5.31656 4.97124 5.70708L1.51168 9.16664C1.18629 9.49203 0.658733 9.49203 0.333345 9.16664C0.00795746 8.84125 0.00795794 8.31369 0.333346 7.98831L2.61457 5.70708C3.0051 5.31656 3.0051 4.68339 2.61457 4.29287L0.333346 2.01164C0.00795889 1.68625 0.00795794 1.15869 0.333345 0.833307Z" />
          </svg>
        </Link>
      </Box>
    </Box>
  );
}

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  options: PropTypes.shape({
    offset: PropTypes.number,
  }),
  onPagination: PropTypes.func,
};

Pagination.defaultProps = {
  options: {
    offset: 4,
  },
  onPagination: () => {},
};

export default Pagination;
