import { SALES_CONTRACT_INVOICE_TYPES } from '@constants/salesContract/invoice/types';

export const STATUS_FILTER_OPTIONS = [
  { id: 1, value: 'all', label: 'Todos as situações' },
  { id: 2, value: 'pending', label: 'Em andamento' },
  { id: 3, value: 'paid', label: 'Pagos' },
  { id: 4, value: 'expired', label: 'Vencidos' },
];

export const TYPE_FILTER_OPTIONS = [
  { id: 1, value: null, label: 'Todos as parcelas' },
  {
    id: 2,
    value: SALES_CONTRACT_INVOICE_TYPES.SINGLE,
    label: 'Parcelas únicas',
  },
  {
    id: 3,
    value: SALES_CONTRACT_INVOICE_TYPES.MONTHLY,
    label: 'Parcelas mensais',
  },
  {
    id: 4,
    value: SALES_CONTRACT_INVOICE_TYPES.YEARLY,
    label: 'Parcelas anuais',
  },
];
