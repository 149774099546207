import React from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';

export default function DocumentDescription({ description }) {
  const theme = useTheme();
  return (
    <Box mb={1} ml={1}>
      <Typography style={{ color: theme.palette.secondary.dark }}>
        {description}
      </Typography>
    </Box>
  );
}

DocumentDescription.propTypes = {
  description: PropTypes.string.isRequired,
};
