import React from 'react';
import { Box, Grid, Typography, useTheme } from '@material-ui/core';

import ReservationStatusLabel from 'components/StatusLabel/ReservationStatusLabel';
import Icon from 'components/Icon';
import Discount from 'components/Discount';

import formatArea from 'utils/formatArea';
import formatCpf from 'utils/formatCpf';
import ICON_TYPES from '@constants/iconsPaths';

import formatCreci from './utils/formatCreci';

export default function GeneralInformation({ reservation, tabIndex }) {
  const theme = useTheme();

  if (tabIndex !== 0) return null;

  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <Typography variant="h5" style={{ color: theme.palette.grey[300] }}>
          {reservation.property.unity}
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs>
            <Discount
              paymentMethod={reservation.paymentMethod}
              price={reservation.price}
              discount={reservation.discount}
            />
          </Grid>
          <Grid item xs>
            <ReservationStatusLabel status={reservation.status} size="medium" />
          </Grid>
        </Grid>
        <Typography style={{ color: theme.palette.grey[200] }}>
          {formatArea(reservation.property.totalArea)}
        </Typography>

        <Box mt={3} hidden={!reservation.observation}>
          <Typography
            variant="caption"
            style={{ color: theme.palette.grey[300] }}
          >
            Observação:
          </Typography>
          <Typography
            variant="body2"
            style={{ color: theme.palette.secondary.dark }}
          >
            {reservation.observation}
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={12} md={6}>
        <Box mb={3}>
          <Box mb={1}>
            <Typography style={{ color: theme.palette.secondary.dark }}>
              Informações do cliente
            </Typography>
          </Box>

          <Grid container spacing={3}>
            <Grid item xs="auto">
              <Icon src={ICON_TYPES.DETAILS.customer} size={24} />
            </Grid>
            <Grid item xs style={{ alignSelf: 'center' }}>
              <Typography
                variant="body2"
                style={{ color: theme.palette.grey[200] }}
              >
                {reservation.customer?.name}
              </Typography>

              <Typography
                variant="body2"
                style={{ color: theme.palette.grey[300] }}
              >
                {formatCpf(reservation.customer?.cpf)}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>

      <Grid item xs={12} md={6}>
        {reservation.realEstateAgent ? (
          <Box mb={3}>
            <Box mb={1}>
              <Typography style={{ color: theme.palette.secondary.dark }}>
                Informações do corretor
              </Typography>
            </Box>

            <Grid container spacing={3}>
              <Grid item xs="auto">
                <Icon src={ICON_TYPES.DETAILS.realEstateAgent} size={32} />
              </Grid>
              <Grid item xs style={{ alignSelf: 'center' }}>
                <Typography
                  variant="body2"
                  style={{ color: theme.palette.grey[200] }}
                >
                  {reservation.realEstate?.name}
                </Typography>

                <Typography
                  variant="body2"
                  style={{ color: theme.palette.grey[200] }}
                >
                  {reservation.realEstateAgent?.name}
                </Typography>

                <Typography
                  variant="body2"
                  style={{ color: theme.palette.grey[300] }}
                >
                  {formatCreci(reservation.realEstateAgent?.creci)}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        ) : null}

        {reservation.realEstate && !reservation.realEstateAgent ? (
          <Box mb={3}>
            <Box mb={1}>
              <Typography
                style={{
                  color: theme.palette.secondary.dark,
                  fontWeight: '450',
                }}
              >
                Informações da imobiliária
              </Typography>
            </Box>

            <Grid container spacing={3}>
              <Grid item xs="auto">
                <Icon src={ICON_TYPES.DETAILS.realEstateAgent} size={32} />
              </Grid>
              <Grid item xs style={{ alignSelf: 'center' }}>
                <Typography
                  variant="body2"
                  style={{ color: theme.palette.grey[200] }}
                >
                  {reservation.realEstate?.name}
                </Typography>

                <Typography
                  variant="body2"
                  style={{ color: theme.palette.grey[300] }}
                >
                  {formatCreci(reservation.realEstate?.creci)}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        ) : null}
      </Grid>
    </Grid>
  );
}
