const CANCEL_INVITE_MUTATION = `
    mutation CancelInvite(
      $id:ID!
    ){
      cancelInvite(
        id: $id
      )
    }
  `;

export default CANCEL_INVITE_MUTATION;
