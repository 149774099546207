import React from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import PaymentInformation from './PaymentInformation';
import InstallmentsInformation from './InstallmentsInformation';
import useGetPaymentData from './hooks/useGetPaymentData';

export default function PaymentTab({
  reservation,
  setReservation,
  installmentsDates,
  tabIndex,
}) {
  const paymentData = useGetPaymentData(reservation);

  return (
    <Box hidden={tabIndex !== 1}>
      <PaymentInformation
        reservation={reservation}
        setReservation={setReservation}
        payment={paymentData}
      />

      <InstallmentsInformation
        reservation={reservation}
        setReservation={setReservation}
        monthlyDueDates={paymentData.monthlyDueDates}
        installmentsDates={installmentsDates}
        annuallyDueDates={paymentData.annuallyDueDates}
      />
    </Box>
  );
}

PaymentTab.propTypes = {
  reservation: PropTypes.shape({}),
  setReservation: PropTypes.func.isRequired,
  installmentsDates: PropTypes.objectOf(PropTypes.string).isRequired,
  tabIndex: PropTypes.number.isRequired,
};

PaymentTab.defaultProps = {
  reservation: null,
};
