export default (err) => {
  if (err.response) {
    const message = String.fromCharCode.apply(
      null,
      new Uint8Array(err.response.data)
    );

    throw new Error(message);
  }

  throw err;
};
