import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { getPropertyColors } from './BaseStatusLabel/colors';
import { propertyStatusTexts } from './BaseStatusLabel/texts';
import BaseStatusLabel from './BaseStatusLabel';

export default function PropertyStatusLabel({ status }) {
  const theme = useTheme();
  const color = getPropertyColors(theme)[status];

  return (
    <BaseStatusLabel
      size="small"
      label={propertyStatusTexts[status]}
      color={color}
    />
  );
}

PropertyStatusLabel.propTypes = {
  status: PropTypes.string.isRequired,
};
