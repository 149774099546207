import { Box, Link, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import useThemeClasses from 'theme/useThemeClasses';
import PropTypes from 'prop-types';
import ICON_TYPES from '@constants/iconsPaths';
import Icon from './Icon';

export default function ProjectsLink({ hideOnMobile, mb }) {
  const history = useHistory();

  const theme = useTheme();
  const themeClasses = useThemeClasses;
  const linkClasses = themeClasses.link();
  const mediaQueryDownSm = useMediaQuery(theme.breakpoints.down('sm'));

  if (mediaQueryDownSm && hideOnMobile) return null;

  return (
    <Box mb={mb}>
      <Box display="flex" alignItems="center">
        <Box display="flex">
          <Icon src={ICON_TYPES.BASE.back} size={22} />
        </Box>

        <Link
          href="/"
          className={linkClasses.tableLink}
          onClick={(e) => {
            e.preventDefault();
            history.push('/');
          }}
        >
          Empreendimentos
        </Link>
      </Box>
    </Box>
  );
}

ProjectsLink.propTypes = {
  hideOnMobile: PropTypes.bool,
  mb: PropTypes.number,
};

ProjectsLink.defaultProps = {
  hideOnMobile: true,
  mb: 5,
};
