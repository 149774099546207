export default `
        query getEmittedSalesContractInvoices (
            $projectId: ID!
            $search : String
        ) {
          getEmittedSalesContractInvoices (
                  projectId: $projectId
                  search: $search
            ) {
                _id
                contractNumberId
                projectId
                type
                description
                paymentMethod
                value
                fees
                discount
                dueDate
                status
                paidAt
                paidValue
                createdAt
                isEmitted
              } 
          }
    `;
