const currentMonth = () => {
    const startDate = new Date()
    startDate.setDate(1)
    startDate.setHours(0, 0, 0, 0)

    const endDate = new Date()
    endDate.setDate(1)
    endDate.setMonth(endDate.getMonth() + 1)
    endDate.setDate(0)
    endDate.setHours(0, 0, 0, 0)

    return {
        id: 'current-month',
        label: 'Esse mês',
        startDate,
        endDate
    }
}

export default currentMonth