import axios from 'axios'

async function buscar(input) {
  let cep = String(input).replace(/[^0-9]/g, '')
  if (cep.length !== 8) return false;

  try {
    const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`)
    return response.data
  } catch (err) {
    return false;
  }
}

const viacep = { buscar }

export default viacep