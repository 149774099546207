import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export default function ReservationCard({ reservation, onClick }) {
    const classes = useStyles()

    return (
        <Box className={classes.root}>
            <Typography component="p" variant="h3" className={classes.numberId}>#{reservation.numberId}</Typography>
            <Typography component="p" variant="body2" className={classes.propertyText}>{reservation.property.unity}</Typography>
            <Typography component="p" variant="body2" className={classes.customerText}>{reservation.customer.name}</Typography>

            <Box mt={2}>
                <Button variant="outlined" color="secondary" onClick={onClick} fullWidth>Criar contrato</Button>
            </Box>
        </Box>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        border: `1px solid ${theme.palette.grey[600]}`,
        borderRadius: 8,
        padding: 20,
    },

    numberId: {
        color: theme.palette.primary.main
    },

    propertyText: {
        color: theme.palette.grey[300],
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },

    customerText: {
        color: theme.palette.grey[300],
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },

    button: {

    }
}))