import React from 'react';
import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import api from 'services/api';
import { useRealEstateAgentsListContext } from 'providers/ListProviders/RealEstateAgentsListProvider';

import SearchListTextField from 'components/SearchListTextField';
import SortSelect from 'components/SortSelect';
import ExportListButton from 'components/ExportListButton';

import SORT_OPTIONS from './constants/sortOptions';
import useStyles from '../styles';
import getSelectedSortOption from './utils/getSelectedSortOption';

export default function RealEstateAgentListFilters({ onCreate }) {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'));
  const classes = useStyles();
  const { variables, search, setSearch, setSort } =
    useRealEstateAgentsListContext();

  const sortOption = getSelectedSortOption(variables, SORT_OPTIONS);

  const handleExport = () => {
    const exportVariables = { ...variables, search };
    delete exportVariables.pages;
    api.exportRealEstateAgents(exportVariables);
  };

  return (
    <Box mb={5}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs md="auto">
          <Typography className="fw700" variant="h5" color="textPrimary">
            Corretores
          </Typography>
        </Grid>

        <Grid item xs="auto">
          <Button
            variant="contained"
            color="primary"
            id="create-rs-agent-button"
            size="small"
            onClick={onCreate}
          >
            Cadastrar
          </Button>
        </Grid>

        {isMd ? <Grid item md /> : null}

        <Grid item xs={12} md="auto">
          <Box width={isMd ? 285 : '100%'}>
            <SearchListTextField
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              fullWidth
            />
          </Box>
        </Grid>

        <Grid item xs={12} md="auto">
          <Box className={classes.listFilters}>
            <SortSelect
              value={sortOption}
              options={SORT_OPTIONS}
              onChange={setSort}
            />

            <ExportListButton onClick={handleExport} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
