import React, { useContext, useState } from 'react';
import {
  Box,
  Container,
  Divider,
  Grid,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import { ProjectContext } from 'providers/ProjectProvider';

import ProjectsLink from 'components/ProjectsLink';

import useThemeClasses from 'theme/useThemeClasses';
import { useStyles } from './styles';
import UserTab from './components/UserTab';

import RealEstateAgentsTab from './components/RealEstateAgentTab';
import RealEstateTabProvider from './providers/RealEstateTabProvider';
import RealEstatesTab from './components/RealEstateTab';
import InvitesTab from './components/InvitesTab';

export default function Team() {
  const classes = useStyles();

  const theme = useTheme();
  const themeClasses = useThemeClasses;
  const linkClasses = themeClasses.link();

  const mediaQueryDownSm = useMediaQuery(theme.breakpoints.down('sm'));

  const { project } = useContext(ProjectContext);

  const [tabIndex, setTabIndex] = useState(0);

  if (!project) return null;

  return (
    <Container className={classes.container}>
      <ProjectsLink />

      <Grid container spacing={5}>
        <Grid item xs={12} md={2}>
          <Box mb={2.5}>
            <Typography className="fw700" variant="h4" color="textPrimary">
              Equipe
            </Typography>
          </Box>
          <Divider />
          <Box display="flex" flexDirection="column" my={2.5}>
            <Box mb={2.5}>
              <Link
                className={
                  linkClasses.teamPaperLink + (tabIndex === 0 ? ' active' : '')
                }
                href="# "
                onClick={(e) => {
                  e.preventDefault();
                  setTabIndex(0);
                }}
              >
                <Typography className="fw700" variant="h6">
                  Coordenação
                </Typography>
              </Link>
            </Box>
            <Box mb={2.5}>
              <Link
                className={
                  linkClasses.teamPaperLink + (tabIndex === 1 ? ' active' : '')
                }
                href="# "
                onClick={(e) => {
                  e.preventDefault();
                  setTabIndex(1);
                }}
              >
                <Typography className="fw700" variant="h6">
                  Imobiliárias
                </Typography>
              </Link>
            </Box>
            <Link
              className={
                linkClasses.teamPaperLink + (tabIndex === 2 ? ' active' : '')
              }
              href="# "
              onClick={(e) => {
                e.preventDefault();
                setTabIndex(2);
              }}
            >
              <Typography className="fw700" variant="h6">
                Corretores
              </Typography>
            </Link>
          </Box>
          <Divider />
          <Box my={2.5}>
            <Link
              className={
                linkClasses.teamPaperLink + (tabIndex === 3 ? ' active' : '')
              }
              href="# "
              onClick={(e) => {
                e.preventDefault();
                setTabIndex(3);
              }}
            >
              <Typography className="fw700" variant="h6">
                Convites
              </Typography>
            </Link>
          </Box>
          <Divider />
        </Grid>

        {!mediaQueryDownSm && (
          <Grid item md="auto">
            <Box height="100%" borderLeft="1px solid rgba(0, 0, 0, 0.12)" />
          </Grid>
        )}

        <Grid item xs={12} md>
          {tabIndex === 0 && <UserTab />}
          {tabIndex === 1 && (
            <RealEstateTabProvider>
              <RealEstatesTab />
            </RealEstateTabProvider>
          )}
          {tabIndex === 2 && <RealEstateAgentsTab />}
          {tabIndex === 3 && <InvitesTab />}
        </Grid>
      </Grid>
    </Container>
  );
}
