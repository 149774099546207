export default `
  mutation UpdateCustomerMutation (
    $id: ID!
    $name: String!
    $maritalStatus: String!
    $occupation: String!
    $phone: String!
    $rg: String
    $postalCode: String
    $address: String
    $addressNumber: String
    $addressComplement: String
    $district: String
    $city: String
    $state: String
    $email: String
    $phone2: String
    $birthday: String
  ) {
    updateCustomer (
      id: $id
      data: {
        name: $name
        rg: $rg
        maritalStatus: $maritalStatus
        occupation: $occupation
        email: $email
        phone: $phone
        phone2: $phone2
        postalCode: $postalCode
        address: $address
        addressNumber: $addressNumber
        addressComplement: $addressComplement
        district: $district
        city: $city
        state: $state
        birthday: $birthday
      }
    ) {
      _id
    }
  }
`;
