export default `
    mutation FreezePropertyPrice (
        $projectId: ID!
        $propertyId: ID!
    ) {
        freezePropertyPrice (
            projectId: $projectId
            propertyId: $propertyId
        ) {
            price
            entranceFee
            annualQuota
            installments
            token
        }
    }
`;
