import PropTypes from 'prop-types';
import React from 'react';

export default function Icon({ src, size }) {
  return <img alt="" src={src} height={size} width={size} />;
}

Icon.defaultProps = {
  size: 16,
};

Icon.propTypes = {
  src: PropTypes.string.isRequired,
  size: PropTypes.number,
};
