import {
  Box,
  Button,
  DialogActions,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import api from 'services/api';
import CurrencyTextField from 'components/TextFields/CurrencyTextField';
import NumberTextField from 'components/TextFields/NumberTextField';
import swalFire from 'utils/swalFire';
import formatPrice from 'utils/formatPrice';
import handlerError from 'utils/handleError';
import PropTypes from 'prop-types';
import CREATE_PROPERTY_QUERY from 'queries/createPropertyQuery';
import BaseDialog from 'components/Dialogs/BaseDialog';
import validateInputs from './helpers/validate-inputs';

export default function CreatePropertyDialog({ projectId, show, onClose }) {
  const [step, setStep] = useState(0);
  const [property, setProperty] = useState({ status: 'available' });
  const [resetFocus, setResetFocus] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    const validate = validateInputs(property);

    if (!validate.success) {
      setStep(validate.step);
      Swal.fire('', validate.message, 'error');
      return;
    }

    Swal.fire({
      text: 'Aguarde...',
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
    });

    const variables = {
      ...property,
      projectId,
      m2price: property.m2price && Number(property.m2price),
      totalArea: property.totalArea && Number(property.totalArea),
      privateArea: property.privateArea && Number(property.privateArea),
    };

    api
      .call(CREATE_PROPERTY_QUERY, variables)
      .then((data) => {
        if (data?.createProperty) {
          swalFire('Pronto!', 'Imóvel cadastrado com sucesso!', 'success').then(
            () => {
              Swal.fire({
                icon: 'info',
                title: 'Deseja cadastrar um novo imóvel?',
                showCancelButton: true,
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
              }).then((result) => {
                if (result.isConfirmed) {
                  setResetFocus(true);

                  setStep(0);
                  setProperty({
                    ...property,
                    unity: '',
                    description: '',
                  });
                } else {
                  onClose();
                }
              });
            }
          );
        }
      })
      .catch((error) => {
        handlerError(
          {
            PROPERTY_UNITY_IN_USE: () => {
              Swal.fire('Ops!', 'Já existe um imóvel com esse nome.', 'error');
            },
            DEFAULT: () => {
              Swal.fire(
                'Ops!',
                'Não foi possível efetuar o cadastro.',
                'error'
              );
            },
          },
          error.message
        );
      });
  };

  const handleClose = (event) => {
    if (event) event.preventDefault();

    Swal.fire({
      icon: 'warning',
      title: 'Confirmação',
      text: 'Deseja realmente fechar a janela? As informações preenchidas serão perdidas.',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then((result) => {
      if (result.isConfirmed) {
        onClose();
      }
    });
  };

  useEffect(() => {
    if (!show) {
      setProperty({ status: 'available' });
      setStep(0);
    }
  }, [show]);

  useEffect(() => {
    if (resetFocus) {
      setResetFocus(false);

      setTimeout(() => {
        document.getElementById('unityInput')?.focus();
      }, 333);
    }
  }, [resetFocus]);

  return (
    <BaseDialog
      title="Novo Imóvel"
      subtitle="Digite os dados necessários para inserir um novo imóvel."
      open={show}
      onClose={handleClose}
      maxWidth="sm"
    >
      <form onSubmit={handleSubmit}>
        <Box mb={5}>
          <Tabs
            variant="fullWidth"
            value={step}
            onChange={(_, value) => setStep(value)}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="1. Geral" style={{ marginRight: 24 }} />
            <Tab label="2. Medidas" />
          </Tabs>
        </Box>

        {step === 0 && (
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <TextField
                autoFocus
                id="unityInput"
                margin="dense"
                name="cp-unity"
                label="Unidade"
                type="text"
                value={property.unity}
                onChange={(e) =>
                  setProperty({ ...property, unity: e.target.value })
                }
                InputLabelProps={{ required: true }}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                multiline
                margin="dense"
                name="cp-description"
                label="Descrição"
                type="text"
                value={property.description || ''}
                onChange={(e) =>
                  setProperty({ ...property, description: e.target.value })
                }
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                multiline
                margin="dense"
                name="cp-observation"
                label="Observação"
                type="text"
                value={property.observation || ''}
                onChange={(e) =>
                  setProperty({ ...property, observation: e.target.value })
                }
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl margin="dense" fullWidth>
                <InputLabel id="state-select-label" required>
                  Situação
                </InputLabel>
                <Select
                  labelId="state-select-label"
                  name="cp-status"
                  value={property.status || ''}
                  onChange={(e) =>
                    setProperty({ ...property, status: e.target.value })
                  }
                >
                  <MenuItem value="unavailable">Indisponível</MenuItem>
                  <MenuItem value="available">Disponível</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <CurrencyTextField
                margin="dense"
                name="cp-price"
                label="Preço do m²"
                value={property.m2price || ''}
                onChange={(_, value) =>
                  setProperty({ ...property, m2price: value })
                }
                InputLabelProps={{ required: true }}
                fullWidth
              />
              <Typography variant="caption" color="primary">
                {'Total: '}
                {formatPrice(property.m2price * property.totalArea) ||
                  'R$ 0,00'}
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                margin="dense"
                name="cp-terrainDimension"
                label="Dimensões do Terreno"
                type="text"
                value={property.terrainDimension || ''}
                onChange={(e) =>
                  setProperty({
                    ...property,
                    terrainDimension: e.target.value,
                  })
                }
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <NumberTextField
                margin="dense"
                name="cp-totalArea"
                label="Área Total (m²)"
                value={property.totalArea || ''}
                onChange={(_, value) =>
                  setProperty({ ...property, totalArea: value })
                }
                InputLabelProps={{ required: true }}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <NumberTextField
                margin="dense"
                name="cp-privateArea"
                label="Área Privativa (m²)"
                value={property.privateArea || ''}
                onChange={(_, value) =>
                  setProperty({ ...property, privateArea: value })
                }
                fullWidth
              />
            </Grid>
          </Grid>
        )}

        {step === 1 && (
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                autoFocus
                margin="dense"
                name="cp-comparisonFront"
                label="Confronto - Frente"
                type="text"
                value={property.comparisonFront || ''}
                onChange={(e) =>
                  setProperty({
                    ...property,
                    comparisonFront: e.target.value,
                  })
                }
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                margin="dense"
                name="cp-comparisonBottom"
                label="Confronto - Fundo"
                type="text"
                value={property.comparisonBottom || ''}
                onChange={(e) =>
                  setProperty({
                    ...property,
                    comparisonBottom: e.target.value,
                  })
                }
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                margin="dense"
                name="cp-comparisonLeft"
                label="Confronto - Esquerda"
                type="text"
                value={property.comparisonLeft || ''}
                onChange={(e) =>
                  setProperty({
                    ...property,
                    comparisonLeft: e.target.value,
                  })
                }
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                margin="dense"
                name="cp-comparisonRight"
                label="Confronto - Direita"
                type="text"
                value={property.comparisonRight || ''}
                onChange={(e) =>
                  setProperty({
                    ...property,
                    comparisonRight: e.target.value,
                  })
                }
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                multiline
                margin="dense"
                name="cp-comparisonExtra"
                label="Confronto Adicional"
                type="text"
                value={property.comparisonExtra || ''}
                onChange={(e) =>
                  setProperty({
                    ...property,
                    comparisonExtra: e.target.value,
                  })
                }
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                margin="dense"
                name="cp-measureFront"
                label="Medida - Frente"
                step={0.001}
                min={0}
                value={property.measureFront || ''}
                onChange={(e) =>
                  setProperty({ ...property, measureFront: e.target.value })
                }
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                margin="dense"
                name="cp-measureBottom"
                label="Medida - Fundo"
                step={0.001}
                min={0}
                value={property.measureBottom || ''}
                onChange={(e) =>
                  setProperty({
                    ...property,
                    measureBottom: e.target.value,
                  })
                }
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                margin="dense"
                name="cp-measureLeft"
                label="Medida - Esquerda"
                step={0.001}
                min={0}
                value={property.measureLeft || ''}
                onChange={(e) =>
                  setProperty({ ...property, measureLeft: e.target.value })
                }
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                margin="dense"
                name="cp-measureRight"
                label="Medida - Direita"
                step={0.001}
                min={0}
                value={property.measureRight || ''}
                onChange={(e) =>
                  setProperty({ ...property, measureRight: e.target.value })
                }
                fullWidth
              />
            </Grid>
          </Grid>
        )}

        <DialogActions>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            Cadastrar
          </Button>
        </DialogActions>
      </form>
    </BaseDialog>
  );
}

CreatePropertyDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  projectId: PropTypes.string,
};

CreatePropertyDialog.defaultProps = {
  projectId: '',
};
