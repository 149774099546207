const CREATE_PROPERTY_QUERY = `
  mutation CreateProperty (
    $projectId: ID!
    $unity: String!
    $status: String!
    $m2price: Float!
    $totalArea: Float!
    $description: String
    $observation: String
    $terrainDimension: String
    $privateArea: Float
    $comparisonFront: String
    $comparisonBottom: String
    $comparisonLeft: String
    $comparisonRight: String
    $comparisonExtra: String
    $measureFront: String
    $measureBottom: String
    $measureLeft: String
    $measureRight: String
  ) {
    createProperty (
      projectId: $projectId
      data: {
        unity: $unity
        status: $status
        m2price: $m2price
        totalArea: $totalArea
        description: $description
        observation: $observation
        terrainDimension: $terrainDimension
        privateArea: $privateArea
        comparisonFront: $comparisonFront
        comparisonBottom: $comparisonBottom
        comparisonLeft: $comparisonLeft
        comparisonRight: $comparisonRight
        comparisonExtra: $comparisonExtra
        measureFront: $measureFront
        measureBottom: $measureBottom
        measureLeft: $measureLeft
        measureRight: $measureRight          
      }
    ) {
      _id
    }
  }
`;

export default CREATE_PROPERTY_QUERY;
