import { TextField } from '@material-ui/core'
import React from 'react'
import validateEmail from 'utils/validateEmail'

export default function EmailTextField(props) {
    const handleError = () => {
        if (props.error) return true
        if (!props.value) return false

        return !validateEmail(props.value)
    }

    return <TextField {...props}
        error={handleError()}
    />
}