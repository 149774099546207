import { useEffect, useState } from 'react';

import WebSocketController from 'utils/WebSocketController';

const socket = new WebSocketController();

export default function useWebHooks(onUpdate) {
  const [isRefreshNeeded, setIsRefreshNeeded] = useState(false);

  useEffect(() => {
    socket.onSalesContractCreated(() => {
      setIsRefreshNeeded(true);
    });

    socket.onSalesContractUpdated(() => {
      setIsRefreshNeeded(true);
    });
    return () => socket.destroy();
  }, []);

  useEffect(() => {
    if (isRefreshNeeded) {
      onUpdate();
      setIsRefreshNeeded(false);
    }
  }, [isRefreshNeeded]);
}
