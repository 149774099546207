import React from 'react';
import { TextField } from '@material-ui/core';

import InputField from 'components/InputField';
import PostalCodeTextField from 'components/TextFields/PostalCodeTextField';
import validatePostalCode from 'utils/validatePostalCode';
import viacep from 'services/viacep';

export default function AddressInformation({ bankAccount, setBankAccount }) {
  const handleAddressSearch = () => {
    if (validatePostalCode(bankAccount.postalCode)) {
      viacep.buscar(bankAccount.postalCode).then((data) => {
        setBankAccount({
          ...bankAccount,
          address: data.logradouro,
          addressComplement: data.complemento,
          district: data.bairro,
          city: data.localidade,
          state: data.uf,
        });
      });
    }
  };
  return (
    <>
      <InputField>
        <PostalCodeTextField
          label="CEP"
          type="text"
          value={bankAccount.postalCode || ''}
          onChange={(_, value) =>
            setBankAccount({ ...bankAccount, postalCode: value })
          }
          onBlur={handleAddressSearch}
          autoFocus
          fullWidth
        />
      </InputField>
      <InputField>
        <TextField
          label="Estado"
          value={bankAccount.state}
          onChange={(e) => {
            setBankAccount((value) => ({
              ...value,
              state: e.target.value,
            }));
          }}
          InputLabelProps={{ shrink: true, required: true }}
          fullWidth
        />
      </InputField>
      <InputField>
        <TextField
          label="Cidade"
          value={bankAccount.city}
          onChange={(e) => {
            setBankAccount((value) => ({
              ...value,
              city: e.target.value,
            }));
          }}
          InputLabelProps={{ shrink: true, required: true }}
          fullWidth
        />
      </InputField>
      <InputField>
        <TextField
          label="Bairro"
          value={bankAccount.district}
          onChange={(e) => {
            setBankAccount((value) => ({
              ...value,
              district: e.target.value,
            }));
          }}
          InputLabelProps={{ shrink: true, required: true }}
          fullWidth
        />
      </InputField>
      <InputField>
        <TextField
          label="Complemento"
          value={bankAccount.addressComplement}
          onChange={(e) => {
            setBankAccount((value) => ({
              ...value,
              addressComplement: e.target.value,
            }));
          }}
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
      </InputField>
      <InputField>
        <TextField
          label="Logradouro"
          value={bankAccount.address}
          onChange={(e) => {
            setBankAccount((value) => ({
              ...value,
              address: e.target.value,
            }));
          }}
          InputLabelProps={{ shrink: true, required: true }}
          fullWidth
        />
      </InputField>
      <InputField>
        <TextField
          label="Número"
          value={bankAccount.addressNumber}
          onChange={(e) => {
            setBankAccount((value) => ({
              ...value,
              addressNumber: e.target.value,
            }));
          }}
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
      </InputField>
    </>
  );
}
