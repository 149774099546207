import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  useTheme,
} from '@material-ui/core';
import BaseDialog from 'components/Dialogs/BaseDialog';
import CurrencyTextField from 'components/TextFields/CurrencyTextField';
import React, { useEffect, useState } from 'react';

export default function EditPropertiesPriceDialog({ open, onSubmit, onClose }) {
  const theme = useTheme();

  const [type, setType] = useState('price');
  const [value, setValue] = useState(0);

  const handleSubmit = (e) => {
    e.preventDefault();

    onSubmit({
      type,
      value,
    });
  };

  useEffect(() => {
    setValue(0);
  }, [type]);

  return (
    <BaseDialog
      open={open}
      title="Editar preço"
      maxWidth="xs"
      onClose={onClose}
    >
      <form onSubmit={handleSubmit}>
        <Box>
          <FormControl component="fieldset">
            <Typography
              component="legend"
              variant="body2"
              style={{ color: theme.palette.secondary.main }}
            >
              Como você quer editar o preço
            </Typography>
            <RadioGroup
              value={type}
              onChange={(_, newValue) => setType(newValue)}
            >
              <FormControlLabel
                value="price"
                control={<Radio color="primary" />}
                label="Valor em real"
              />
              <FormControlLabel
                value="m2price"
                control={<Radio color="primary" />}
                label="Preço por m²"
              />
            </RadioGroup>
          </FormControl>
        </Box>

        <Box mt={3} mb={6}>
          <CurrencyTextField
            label="Editar para:"
            value={value}
            placeholder="R$ 0,00"
            onChange={(_, newValue) => setValue(newValue)}
            fullWidth
            InputLabelProps={{ shrink: true, required: false }}
            required
          />
        </Box>

        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            size="large"
            style={{ width: 208 }}
          >
            Salvar
          </Button>
        </Box>
      </form>
    </BaseDialog>
  );
}
