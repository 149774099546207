export default `
  mutation AcceptSalesInvite(
    $id: ID!
    $password: String!
  ){
    acceptSalesInvite(
      data :{
        id: $id,
        password: $password
      }
    )
  }
`;
