import buttonClasses from "./classes/buttonClasses";
import dialogClasses from "./classes/dialogClasses";
import linkClasses from "./classes/linkClasses";
import paperClasses from "./classes/paperClasses";
import tableClasses from "./classes/tableClasses";
import textFieldClasses from "./classes/textFieldClasses";
import textClasses from "./classes/textClasses";

const useThemeClasses = {
  button: buttonClasses,
  dialog: dialogClasses,
  link: linkClasses,
  paper: paperClasses,
  table: tableClasses,
  text: textClasses,
  textField: textFieldClasses,
}

export default useThemeClasses