const { makeStyles } = require('@material-ui/core');

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    ...theme.typography.body1,
    color: theme.palette.secondary.dark,
    fontWeight: 'bold',
  },
}));

export default useStyles;
