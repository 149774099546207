import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

import AlertModule from 'modules/AlertModule';
import { useUsersListContext } from 'providers/ListProviders/UsersListProvider';
import useQuery from 'hooks/useQuery';
import REMOVE_USER_FROM_PROJECT_MUTATION from 'queries/removeUserFromProjectMutation';

import UserSwitch from './UserSwitch';
import UserDelete from './UserDelete';

export default function ListActions({ project, rowData }) {
  const [executeRemoveUserFromProject] = useQuery(
    REMOVE_USER_FROM_PROJECT_MUTATION
  );
  const { updateList } = useUsersListContext();

  const hideActions = rowData.user._id === project.ownerId;

  const handleDeleteUser = () => {
    AlertModule.confirmation(
      `Deseja realmente remover o usuário "${rowData.user.name}"?`
    ).then((result) => {
      if (result.isConfirmed) {
        executeRemoveUserFromProject({
          projectId: project._id,
          userId: rowData.user._id,
        })
          .then(() => updateList())
          .catch(AlertModule.onError('Não foi possível remover o usuário.'));
      }
    });
  };

  if (hideActions) return null;

  return (
    <Box display="flex" flexWrap="no-wrap">
      <UserSwitch
        projectId={project._id}
        active={rowData.active}
        user={rowData.user}
      />

      <UserDelete onClick={handleDeleteUser} />
    </Box>
  );
}

ListActions.propTypes = {
  user: PropTypes.shape({
    active: PropTypes.bool,
  }).isRequired,
  actions: PropTypes.shape({
    onToggle: PropTypes.func,
    onDelete: PropTypes.func,
  }).isRequired,
};
