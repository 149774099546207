import React from 'react';
import PropTypes from 'prop-types';

import useQuery from 'hooks/useQuery';
import GET_REMITTANCE_QUERY from 'queries/getRemittanceQuery';
import BaseList from 'components/Lists/BaseList';
import { useRemittancesListContext } from 'providers/ListProviders/RemittancesListProvider';
import useColumns from './hooks/useColumns';

export default function RemittancesList({ onDetails }) {
  const { remittances } = useRemittancesListContext();

  const [executeGetRemittance] = useQuery(GET_REMITTANCE_QUERY);

  const columns = useColumns();

  const handleRowClick = (rowData) => {
    executeGetRemittance({
      remittanceId: rowData._id,
    }).then(({ getRemittance: remittance }) => {
      onDetails(remittance);
    });
  };

  return (
    <BaseList
      columns={columns}
      onRowClick={handleRowClick}
      data={remittances}
    />
  );
}

RemittancesList.propTypes = {
  options: PropTypes.shape({
    canCreate: PropTypes.bool,
    canEdit: PropTypes.bool,
  }),
  onDetails: PropTypes.func,
};

RemittancesList.defaultProps = {
  options: null,
  onDetails: () => {},
};
