import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  brandText: {
    fontSize: 36,
    fontWeight: '900',
    color: '#3E475B'
  },

  inputValidationText: {
    fontSize: 12,
    fontWeight: '400',
    lineHeight: '15px'
  },

  projectTitleText: {
    fontWeight: '900',
    fontSize: 20,
    lineHeight: '36px',
    color: '#5F697D'
  },

  teamPaperTitle: {
    fontWeight: '900',
    fontSize: 24,
    color: '#B9BDD1',
  },

  sortLabel: {
    textTransform: 'uppercase',
    fontSize: theme.typography.h6.fontSize,
    letterSpacing: '1px',
    fontWeight: '700',
    color: theme.palette.text.secondary,
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px'
    }
  },

  filterLabel: {
    textTransform: 'uppercase',
    fontSize: theme.typography.h6.fontSize,
    letterSpacing: '1px',
    fontWeight: '700',
    color: theme.palette.text.secondary,
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px'
    }
  }
}))
