import useQuery from 'hooks/useQuery';
import AlertModule from 'modules/AlertModule';
import GET_PROPERTIES_SUMMARY_QUERY from 'queries/getPropertiesSummaryQuery';
import { useCallback, useEffect, useRef, useState } from 'react';

export default function useGetSummary(variables, search) {
  const [summaryData, setSummaryData] = useState(null);
  const isFirstRender = useRef(true);
  const [executeGetPropertiesSummaryData] = useQuery(
    GET_PROPERTIES_SUMMARY_QUERY
  );

  const onResolve = ({ getPropertiesSummary: data }) => {
    setSummaryData(data);
  };

  const onReject = AlertModule.onError(
    'Não foi possível carregar o sumário de imóveis'
  );

  const createGetter = (executeQuery) => () =>
    executeQuery({ ...variables, search })
      .then(onResolve)
      .catch(onReject);

  const getSummaryBySearch = useCallback(
    createGetter(executeGetPropertiesSummaryData.debounced),
    [variables, search]
  );

  const getSummary = useCallback(
    createGetter(executeGetPropertiesSummaryData),
    [variables, search]
  );

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    getSummaryBySearch();
  }, [search]);

  useEffect(() => {
    getSummary();
  }, [variables]);

  return summaryData;
}
