export default function useDialogController(
  readPropertyDialogData,
  setReadPropertyDialogData,
  editPropertyDialogData,
  setEditPropertyDialogData,
  setMakeReservationDialogData,
  setReadPropertyReservedDialogData
) {
  const onReadProperty = (property) => {
    setReadPropertyDialogData({
      open: true,
      propertyId: property._id,
    });
  };

  const onEditProperty = () => {
    setEditPropertyDialogData({
      open: true,
      propertyId: readPropertyDialogData.propertyId,
    });

    setReadPropertyDialogData({
      open: false,
      propertyId: null,
    });
  };

  const onReserveProperty = () => {
    setMakeReservationDialogData({
      open: true,
      propertyId: readPropertyDialogData.propertyId,
    });

    setReadPropertyDialogData({
      open: false,
      propertyId: null,
    });
  };

  const onPropertyReserved = (reservation) => {
    setReadPropertyReservedDialogData({
      open: true,
      reservation,
    });
  };
  const onCloseReadPropertyDialog = () => {
    setReadPropertyDialogData({
      open: false,
      propertyId: null,
    });
  };

  const onCloseEditPropertyDialog = () => {
    setEditPropertyDialogData({
      open: false,
      propertyId: null,
    });
  };

  const onClosePropertyReservedDialog = () => {
    setReadPropertyReservedDialogData({
      open: false,
      reservation: null,
    });
  };
  const onCancelEditPropertyDialog = () => {
    setReadPropertyDialogData({
      open: true,
      propertyId: editPropertyDialogData.propertyId,
    });

    setEditPropertyDialogData({
      open: false,
      propertyId: null,
    });
  };

  const onCloseMakeReservationDialog = () => {
    setMakeReservationDialogData({
      open: false,
      propertyId: null,
    });
  };

  return {
    onReadProperty,
    onEditProperty,
    onReserveProperty,
    onPropertyReserved,
    onClosePropertyReservedDialog,
    onCloseReadPropertyDialog,
    onCloseEditPropertyDialog,
    onCancelEditPropertyDialog,
    onCloseMakeReservationDialog,
  };
}
