import React from 'react';
import PropTypes from 'prop-types';

import { useSalesContractInvoicesListContext } from 'providers/ListProviders/SalesContractInvoicesListProvider';

import BaseList from '../BaseList';

import useColumns from './hooks/useColumns';

function SalesContractInvoicesList({
  contractNumberId,
  getSelectMethods,
  onSelect,
  emitInvoice,
  setEditInvoiceId,
  printInvoice,
}) {
  const { invoices, pages, setPage, variables } =
    useSalesContractInvoicesListContext();
  const columns = useColumns({
    contractNumberId,
    emitInvoice,
    printInvoice,
    setEditInvoiceId,
  });

  return (
    <BaseList
      columns={columns}
      data={invoices}
      currentPage={variables.page}
      pages={pages}
      onPagination={setPage}
      options={{ canSelect: true }}
      changedAt={variables.changedAt}
      onRowClick={(rowData) => setEditInvoiceId(rowData._id)}
      onSelect={onSelect}
      getSelectMethods={getSelectMethods}
    />
  );
}

SalesContractInvoicesList.propTypes = {
  contractNumberId: PropTypes.number,
  getSelectMethods: PropTypes.func,
  emitInvoice: PropTypes.func,
  onSelect: PropTypes.func,
  setEditInvoiceId: PropTypes.func,
  printInvoice: PropTypes.func,
};

SalesContractInvoicesList.defaultProps = {
  contractNumberId: null,
  getSelectMethods: () => {},
  emitInvoice: () => {},
  onSelect: () => {},
  setEditInvoiceId: () => {},
  printInvoice: () => {},
};

export default SalesContractInvoicesList;
