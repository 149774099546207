import React, { createContext, useContext, useMemo, useState } from 'react';

import AlertModule from 'modules/AlertModule';

import useQuery from 'hooks/useQuery';
import UPDATE_PROJECT_CONFIGURATION_MUTATION from 'queries/updateProjectConfigurationMutation';

import { AuthContext } from '../AuthProvider';
import { ProjectContext } from '../ProjectProvider';
import formatVariables from './utils/formatVariables';

const ProjectConfigurationContext = createContext();

export default function ProjectConfigurationProvider({ children }) {
  const { authenticate } = useContext(AuthContext);
  const { project, discount } = useContext(ProjectContext);
  const [variables, setVariables] = useState({
    entranceFee: project.entranceFee,
    annualQuota: project.annualQuota,
    installments: project?.installments,
    discount,
    cnpj: project?.cnpj,
    companyName: project.companyName,
    projectId: project._id,
  });

  const [executeUpdateProjectConfiguration] = useQuery(
    UPDATE_PROJECT_CONFIGURATION_MUTATION
  );

  const changeVariables = (value) => {
    setVariables({ ...variables, ...value });
  };

  const changeDiscount = (type) => (e) => {
    const percentToDecimal = (value) => value / 100;
    const newDiscount = variables.discount;
    newDiscount[type] = percentToDecimal(e.target.value);
    setVariables({ ...variables, discount: newDiscount });
  };

  const updateConfiguration = (e) => {
    authenticate(true);
    e.preventDefault();
    const formattedVariables = formatVariables(variables);
    return executeUpdateProjectConfiguration(formattedVariables)
      .then(AlertModule.onSuccess('As configurações foram salvas com sucesso!'))
      .catch(AlertModule.onError('Não foi possível salvar as configurações.'));
  };

  const value = useMemo(
    () => ({
      variables,
      setVariables: changeVariables,
      changeDiscount,
      onUpdate: updateConfiguration,
    }),
    [variables]
  );
  return (
    <ProjectConfigurationContext.Provider value={value}>
      {children}
    </ProjectConfigurationContext.Provider>
  );
}

export function useProjectConfigurationContext() {
  return useContext(ProjectConfigurationContext);
}
