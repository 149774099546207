export default function getPropertyMarksQuery(projectId) {
  return `
    query {
      getPropertyMarks(projectId: "${projectId}") {
        propertyId,
        propertyStatus,
        posX,
        posY,
      }
    }
  `
}