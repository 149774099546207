import React from 'react';
import { useTheme } from '@material-ui/core';

import { getReservationColors } from './BaseStatusLabel/colors';
import { reservationStatusTexts } from './BaseStatusLabel/texts';
import BaseStatusLabel from './BaseStatusLabel';

export default function ReservationStatusLabel({ status, size = 'small' }) {
  const theme = useTheme();
  const color = getReservationColors(theme)[status];

  return (
    <BaseStatusLabel
      size={size}
      label={reservationStatusTexts[status]}
      color={color}
    />
  );
}
