export const UPDATE_SALES_CONTRACT_INVOICE_MUTATION = `
  mutation UpdateSalesContractInvoice (
    $id: ID!
    $paymentMethod: String!
    $fees: Float
    $paidValue: Float
    $paidAt: String
    $discount: Float
  ) {
    updateSalesContractInvoice (
      id: $id
      paymentMethod: $paymentMethod
      fees: $fees
      paidValue: $paidValue
      paidAt: $paidAt
      discount: $discount
    )
  }
`;

export default {};
