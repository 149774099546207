const GET_INVITES_QUERY = `
    query  GetInvites(
      $projectId:ID!
      $page:Int
      $itemsPerPage:Int
      $search:String
      $sortOptions: InviteSort
      $filterOptions: InviteFilter
    ){
      getInvites(
        projectId: $projectId
        page: $page
        itemsPerPage: $itemsPerPage
        search: $search
        sortOptions : $sortOptions
        filterOptions: $filterOptions
      ) {
        invites {
          _id,
          email, 
          type,
          entityId,
          expiration,
          status,
          createdAt
        }
        page
        pages
      }
    }
  `;
export default GET_INVITES_QUERY;
