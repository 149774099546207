import api from 'services/api';
import WebSocketMessages from 'services/api/websocket/messages';

class WebSocketController {
  constructor() {
    this.listeners = [];
    this.getSocket = () => api.openSocket();
  }

  addListener(message, handler) {
    if (message === 'reconnect') {
      this.getSocket().io.on(message, handler);
    } else {
      this.getSocket().on(message, handler);
    }

    this.listeners.push([message, handler]);
  }

  joinProject(projectId) {
    this.getSocket().emit(WebSocketMessages.JOIN_PROJECT, projectId);
  }

  onPropertyCreated(handler) {
    this.addListener(WebSocketMessages.PROPERTY_CREATED, handler);
  }

  onPropertyUpdated(handler) {
    this.addListener(WebSocketMessages.PROPERTY_UPDATED, handler);
  }

  onPropertyDeleted(handler) {
    this.addListener(WebSocketMessages.PROPERTY_DELETED, handler);
  }

  onPropertyMarkCreated(handler) {
    this.addListener(WebSocketMessages.PROPERTY_MARK_CREATED, handler);
  }

  onPropertyMarkDeleted(handler) {
    this.addListener(WebSocketMessages.PROPERTY_MARK_DELETED, handler);
  }

  onReservationCreated(handler) {
    this.addListener(WebSocketMessages.RESERVATION_CREATED, handler);
  }

  onReservationUpdated(handler) {
    this.addListener(WebSocketMessages.RESERVATION_UPDATED, handler);
  }

  onSalesContractCreated(handler) {
    this.addListener(WebSocketMessages.SALES_CONTRACT_CREATED, handler);
  }

  onSalesContractInvoiceCreated(handler) {
    this.addListener(WebSocketMessages.SALES_CONTRACT_INVOICE_CREATED, handler);
  }

  onSalesContractInvoiceUpdated(handler) {
    this.addListener(WebSocketMessages.SALES_CONTRACT_INVOICE_UPDATED, handler);
  }

  onInvoiceEmitted(handler) {
    this.addListener(WebSocketMessages.INVOICE_EMITTED, handler);
  }

  onCustomerCreated(handler) {
    this.addListener(WebSocketMessages.CUSTOME_CREATED, handler);
  }

  onCustomerUpdated(handler) {
    this.addListener(WebSocketMessages.CUSTOMER_UPDATED, handler);
  }

  onSalesContractUpdated(handler) {
    this.addListener(WebSocketMessages.SALES_CONTRACT_UPDATED, handler);
  }

  onRemittanceCreated(handler) {
    this.addListener(WebSocketMessages.REMITTANCE_CREATED, handler);
  }

  onReturnFileCreated(handler) {
    this.addListener(WebSocketMessages.RETURN_FILE_CREATED, handler);
  }

  onReconnect(handler) {
    this.addListener('reconnect', handler);
  }

  destroy() {
    this.listeners.forEach((listener) => {
      const eventName = listener[0];
      const handler = listener[1];

      if (eventName === 'reconnect') {
        this.getSocket().io.off(eventName, handler);
      } else {
        this.getSocket().off(eventName, handler);
      }
    });
    this.listeners = [];
  }
}

export default WebSocketController;
