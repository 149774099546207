import React, { useContext, useState } from 'react';

import AlertModule from 'modules/AlertModule';
import { useRealEstatesListContext } from 'providers/ListProviders/RealEstatesListProvider';

import BaseDialog from 'components/Dialogs/BaseDialog';
import { ProjectContext } from 'providers/ProjectProvider';
import Form from './components/Form';

import validateInputs from './helpers/validate-inputs';
import useCreateRealEstate from './hooks/useCreateRealEstate';

export default function CreateRealEstateDialog({ open, onClose }) {
  const { updateList } = useRealEstatesListContext();
  const { project } = useContext(ProjectContext);
  const [step, setStep] = useState(0);
  const [realEstate, setRealEstate] = useState({
    projectId: project._id,
    reservationLimit: 10,
    active: true,
    reuseEmail: false,
  });
  const setRealEstateField = (field) =>
    setRealEstate({ ...realEstate, ...field });

  const onCreateSuccess = () => {
    updateList();
    onClose();
  };

  const createRealEstate = useCreateRealEstate(onCreateSuccess);
  const onCloseDialog = () => {
    setStep(0);
    setRealEstate({
      projectId: project._id,
      reservationLimit: 10,
      active: true,
      reuseEmail: false,
    });
    onClose();
  };

  const handleSubmit = async () => {
    const error = validateInputs(realEstate);
    if (error) {
      setStep(error.step);
      AlertModule.error(error.message);
      return;
    }
    createRealEstate(realEstate);
  };

  return (
    <BaseDialog
      open={open}
      onClose={onCloseDialog}
      title="Nova Imobiliária"
      maxWidth="sm"
    >
      <Form
        realEstate={realEstate}
        setRealEstate={setRealEstateField}
        submit={handleSubmit}
        step={step}
        setStep={setStep}
        action="create"
      />
    </BaseDialog>
  );
}
