import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import AlertModule from 'modules/AlertModule';
import { ProjectContext } from 'providers/ProjectProvider';
import { useUsersListContext } from 'providers/ListProviders/UsersListProvider';

import EmailTextField from 'components/TextFields/EmailTextField';
import BaseDialog from 'components/Dialogs/BaseDialog';

import validateEmail from 'utils/validateEmail';
import handlerError from 'utils/handleError';
import INVITE_TYPES from '@constants/invites/types';
import CREATE_MANAGER_INVITE_QUERY from 'queries/createManagerInviteQuery';

import useQuery from 'hooks/useQuery';

export default function CreateInvitationDialog({ open, onClose }) {
  const history = useHistory();

  const { project } = useContext(ProjectContext);
  const { updateList } = useUsersListContext();
  const [email, setEmail] = useState('');
  const [type, setType] = useState(INVITE_TYPES.MANAGER.value);
  const [executeCreateManagerInvite] = useQuery(CREATE_MANAGER_INVITE_QUERY);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!email) {
      AlertModule.error('O campo E-mail é obrigatório.');
      return;
    }
    if (!validateEmail(email)) {
      AlertModule.error('Preencha o campo E-mail corretamente.');
      return;
    }

    AlertModule.wait();

    const variables = {
      projectId: project._id,
      email,
      type,
    };

    executeCreateManagerInvite(variables)
      .then(() => {
        setEmail('');
        setType(INVITE_TYPES.MANAGER.value);
        updateList();
        AlertModule.success('Convite enviado com sucesso!').then(() => {
          onClose();
        });
      })
      .catch((error) => {
        handlerError(
          {
            NOT_AUTHENTICATED: () => {
              history.push('/login');
            },
            USER_IN_PROJECT: AlertModule.onError(
              'O usuário já está no projeto.'
            ),
            SEND_EMAIL_ERROR: AlertModule.onError(
              'Não foi possível enviar o e-mail.'
            ),
            DEFAULT: AlertModule.onError('Não foi possível criar o convite.'),
          },
          error.message
        );
      });
  };

  useEffect(() => {
    if (!open) {
      setEmail('');
      setType(INVITE_TYPES.MANAGER.value);
    }
  }, [open]);

  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      title=" Enviar convite"
      maxWidth="sm"
    >
      <form onSubmit={handleSubmit}>
        <Box>
          <Box mb={6}>
            <EmailTextField
              autoFocus
              autoComplete="email"
              margin="dense"
              name="ci-email"
              label="E-mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              InputLabelProps={{ required: true }}
              fullWidth
            />
          </Box>
          <Box mb={2.5}>
            <FormControl fullWidth>
              <InputLabel htmlFor="invitation-type" required>
                Tipo do Convite
              </InputLabel>
              <Select
                native
                name="ci-type"
                value={type}
                onChange={(e) => setType(e.target.value)}
                inputProps={{ id: 'invitation-type' }}
              >
                <option value={INVITE_TYPES.MANAGER.value}>
                  {INVITE_TYPES.MANAGER.label}
                </option>
              </Select>
            </FormControl>
          </Box>
        </Box>
        <DialogActions>
          <Button variant="contained" color="primary" type="submit">
            Enviar
          </Button>
        </DialogActions>
      </form>
    </BaseDialog>
  );
}

CreateInvitationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
