export default function useModalController(
  readPropertyDialogData,
  setReadPropertyDialogData,
  setMakeReservationDialogData,
  setPropertyReservedDialogData
) {
  const onReadProperty = (property) => {
    setReadPropertyDialogData({
      open: true,
      propertyId: property._id,
    });
  };

  const onReserveProperty = () => {
    setMakeReservationDialogData({
      open: true,
      propertyId: readPropertyDialogData.propertyId,
    });

    setReadPropertyDialogData({
      open: false,
      propertyId: null,
    });
  };

  const onPropertyReserved = (reservation) => {
    setPropertyReservedDialogData({
      open: true,
      reservation,
    });
  };
  const onCloseReadPropertyDialog = () => {
    setReadPropertyDialogData({
      open: false,
      propertyId: null,
    });
  };

  const onCloseMakeReservationDialog = () => {
    setMakeReservationDialogData({
      open: false,
      propertyId: null,
    });
  };
  const onClosePropertyReservedDialog = () => {
    setPropertyReservedDialogData({
      open: false,
      reservation: {},
    });
  };

  return {
    onReadProperty,
    onReserveProperty,
    onPropertyReserved,
    onCloseReadPropertyDialog,
    onClosePropertyReservedDialog,
    onCloseMakeReservationDialog,
  };
}
