import React, { useMemo } from 'react';
import FeatureFlags from 'utils/FeatureFlags';
import BackIcon from './icons/BackIcon';
import CoinsIcon from './icons/CoinsIcon';
import WalletIcon from './icons/WalletIcon';
import SettingsIcon from './icons/SettingsIcon';
import GroupIcon from './icons/GroupIcon';

export default (environment, project, isMobile = false) => {
  const featureFlags = useMemo(() => new FeatureFlags(project), [project]);

  const administration = useMemo(() => {
    const value = [
      {
        id: 1,
        label: 'Empreendimentos',
        type: 'link',
        href: '/',
        icon: <BackIcon />,
        display: 'mobile',
      },
    ];

    if (featureFlags.isEnabled('customer-management')) {
      return [
        ...value,
        {
          id: 2,
          icon: <CoinsIcon />,
          label: 'Gestão de Vendas',
          type: 'dropdown',
          items: [
            { id: 1, label: 'Imóveis', href: `/${project.slug}/imoveis` },
            { id: 2, label: 'Mapa', href: `/${project.slug}/mapa` },
            { id: 3, label: 'Reservas', href: `/${project.slug}/reservas` },
          ],
        },
        {
          id: 3,
          icon: <WalletIcon />,
          label: 'Gestão de Carteiras',
          type: 'dropdown',
          items: [
            { id: 1, label: 'Contratos', href: `/${project.slug}/contratos` },
            { id: 2, label: 'Títulos', href: `/${project.slug}/titulos` },
            { id: 3, label: 'Financeiro', href: `/${project.slug}/financeiro` },
          ],
        },
        {
          id: 4,
          icon: <GroupIcon />,
          label: 'Clientes',
          type: 'link',
          href: `/${project.slug}/clientes`,
        },
        {
          id: 5,
          icon: <SettingsIcon />,
          label: 'Ajustes',
          type: 'dropdown',
          items: [
            {
              id: 1,
              label: 'Empreendimento',
              href: `/${project.slug}/configuracoes`,
            },
            { id: 2, label: 'Equipe', href: `/${project.slug}/equipe` },
          ],
        },
      ];
    }

    return [
      ...value,
      {
        id: 2,
        icon: <CoinsIcon />,
        label: 'Gestão de Vendas',
        type: 'dropdown',
        items: [
          { id: 1, label: 'Imóveis', href: `/${project.slug}/imoveis` },
          { id: 2, label: 'Mapa', href: `/${project.slug}/mapa` },
          { id: 3, label: 'Reservas', href: `/${project.slug}/reservas` },
        ],
        display: 'mobile',
      },
      {
        id: 3,
        label: 'Imóveis',
        type: 'link',
        href: `/${project.slug}/imoveis`,
        display: 'desktop',
      },
      {
        id: 4,
        label: 'Reservas',
        type: 'link',
        href: `/${project.slug}/reservas`,
        display: 'desktop',
      },
      {
        id: 5,
        icon: <GroupIcon />,
        label: 'Clientes',
        type: 'link',
        href: `/${project.slug}/clientes`,
      },
      {
        id: 6,
        icon: <SettingsIcon />,
        label: 'Ajustes',
        type: 'dropdown',
        items: [
          {
            id: 1,
            label: 'Empreendimento',
            href: `/${project.slug}/configuracoes`,
          },
          { id: 2, label: 'Equipe', href: `/${project.slug}/equipe` },
        ],
        display: 'mobile',
      },
      {
        id: 7,
        label: 'Equipe',
        type: 'link',
        href: `/${project.slug}/equipe`,
        display: 'desktop',
      },
      {
        id: 8,
        label: 'Configurações',
        type: 'link',
        href: `/${project.slug}/configuracoes`,
        display: 'desktop',
      },
    ];
  }, [project.slug, featureFlags]);

  const sales = useMemo(
    () => [
      {
        id: 1,
        type: 'link',
        label: 'Imóveis',
        href: `/${project.slug}/imoveis`,
        display: 'desktop',
      },
      {
        id: 2,
        type: 'link',
        label: 'Reservas',
        href: `/${project.slug}/reservas`,
        display: 'desktop',
      },
    ],
    [project.slug]
  );

  const links = useMemo(() => {
    const environments = {
      administration,
      sales,
    };

    return environments[environment]
      .filter(
        (link) =>
          link.display === undefined ||
          (isMobile && link.display === 'mobile') ||
          (!isMobile && link.display === 'desktop')
      )
      .filter((link) => !link.disabled)
      .map((link, i) => ({
        id: i,
        ...link,
      }));
  }, [environment, isMobile, administration, sales]);

  return links;
};
