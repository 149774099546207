const LIST_BANK_ACCOUNT_QUERY = `
  query ListBankAccount ($projectId: ID!) {
    listBankAccount (projectId: $projectId) {
      _id
      projectId
      companyName
      companyCode
      document
      bankCode
      bankName
      branchNumber
      branchDigit
      accountNumber
      accountDigit
      createdAt
      postalCode
      address
      addressNumber
      addressComplement
      district
      city
      state
    }
  }
`;

export default LIST_BANK_ACCOUNT_QUERY;
