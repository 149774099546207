import React, { useRef, useState } from 'react';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';

import FilterButton from 'components/FilterButton';
import RangeDateFilter from 'components/RangeDateFilter';

export default function PaymentDateFilter({ onApply }) {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const definitions = {
    sm: {
      size: 'small',
      label: 'Data de pagamento',
      iconSize: 18,
    },
    default: {
      size: 'medium',
      label: 'Data de pagamento',
      iconSize: 24,
    },
  };

  const def = isSm ? definitions.sm : definitions.default;

  return (
    <Box display="inline">
      <FilterButton
        icon="date"
        label={def.label}
        onClick={handleOpen}
        ref={anchorRef}
      />
      <RangeDateFilter
        title="Data de pagamento"
        open={open}
        anchorRef={anchorRef}
        onClose={handleClose}
        onApply={onApply}
      />
    </Box>
  );
}

PaymentDateFilter.propTypes = {
  onApply: PropTypes.func.isRequired,
};
