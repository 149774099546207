import React from 'react';

import InvitesListFilters from 'components/ListFilters/InvitesListFilters';
import InvitesList from 'components/Lists/InvitesList';
import InvitesListZeroState from 'components/ZeroState/InvitesListZeroState';
import InvitesListProvider from 'providers/ListProviders/InvitesListProvider.js';

export default function InvitesTab() {
  return (
    <InvitesListProvider>
      <InvitesListFilters />
      <InvitesListZeroState>
        <InvitesList />
      </InvitesListZeroState>
    </InvitesListProvider>
  );
}
