const CREATE_SALES_CONTRACT_MUTATION = `
    mutation CreateSalesContract (
        $reservationId: ID!
        $newFinancedValue: Float
        $observation: String
    ) {
        createSalesContract (
            reservationId: $reservationId            
            observation: $observation
            newFinancedValue: $newFinancedValue
        ) {
            _id
            numberId
        }
    }
`;

export default CREATE_SALES_CONTRACT_MUTATION;
