import React from 'react';
import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import { PAYMENT_METHODS_LABELS } from '@constants/reservations/paymentMethods';
import formatPrice from 'utils/formatPrice';
import useStyles from './styles';

export default function Discount({ price, discount, paymentMethod }) {
  const classes = useStyles();

  const finalPrice = price - price * discount;
  const percentageDiscount = discount * 100;
  const discountMessage = `-${percentageDiscount}% ${PAYMENT_METHODS_LABELS[paymentMethod]}`;

  return (
    <Box className={classes.root}>
      {percentageDiscount > 0 && (
        <Box className={classes.discountContainer}>
          <Typography color="textSecondary" className={classes.dash}>
            {formatPrice(price)}
          </Typography>
          <Box ml={1}>
            <Typography variant="h6" className={classes.discountLabel}>
              {discountMessage}
            </Typography>
          </Box>
        </Box>
      )}

      <Typography variant="h4" className={classes.priceWithDiscount}>
        {formatPrice(finalPrice)}
      </Typography>
    </Box>
  );
}

Discount.propTypes = {
  price: PropTypes.number.isRequired,
  discount: PropTypes.number.isRequired,
  paymentMethod: PropTypes.string,
};

Discount.defaultProps = {
  paymentMethod: 'cash',
};
