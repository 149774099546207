import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  card: {
    background: theme.palette.secondary.dark,
    height: 72,
    borderRadius: 36,
    padding: '0px 24px',
    display: 'flex',
    alignItems: 'center',
    maxWidth: 510,
    width: '100%',
  },

  icon: {
    color: 'white',
  },

  text: {
    flex: 1,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(3),
    color: 'white',
  },
}));

export default useStyles;
