/* eslint-disable react-hooks/exhaustive-deps */
import { TextField } from '@material-ui/core';
import React from 'react';

export default function NumberTextField({
  name,
  value,
  onChange,
  fullWidth,
  label,
  margin,
  InputLabelProps,
}) {
  const handleValue = () => {
    if (!value) return '';
    return Number(value).toLocaleString('pt-BR', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
  };

  const handleChange = (e) => {
    let onChangeValue = String(e.target.value);

    onChangeValue = onChangeValue.replace(/[^0-9]/g, '');
    onChangeValue = Number(onChangeValue) / 100;

    if (Number.isNaN(onChangeValue)) {
      onChangeValue = 0;
    }

    onChange(e, onChangeValue);
  };

  return (
    <TextField
      value={handleValue()}
      label={label}
      fullWidth={fullWidth}
      onChange={handleChange}
      margin={margin}
      InputLabelProps={InputLabelProps}
      name={name}
    />
  );
}
