import React, { useContext } from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { ProjectContext } from 'providers/ProjectProvider';
import UploadButton from 'pages/ProjectMain/Administration/EditMap/components/UploadButton';
import PropTypes from 'prop-types';
import Loading from 'components/Loading';
import useThemeClasses from 'theme/useThemeClasses';
import MapZeroStateImage from 'assets/images/ZeroStates/MapZeroStateImage';

function MapsZeroState({ isLoading, mapIntegrity, onReload, children }) {
  const history = useHistory();
  const themeClasses = useThemeClasses;
  const buttonClasses = themeClasses.button();

  const { project } = useContext(ProjectContext);

  const hasMap = Boolean(project.mapFileId);

  if (isLoading) {
    return <Loading />;
  }

  if (hasMap && mapIntegrity) {
    return children;
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="calc(100vh - 106px)"
      padding={5}
    >
      <Box mb={3}>
        <MapZeroStateImage />
      </Box>

      <Typography align="center" display="block" variant="h3">
        {mapIntegrity
          ? 'O mapa ainda não foi cadastrado'
          : 'Não foi possível carregar o mapa'}
      </Typography>

      <Box mt={5} hidden={!mapIntegrity}>
        <UploadButton />
      </Box>

      <Box mt={5} hidden={mapIntegrity}>
        <Button variant="contained" color="primary" onClick={() => onReload()}>
          Tentar novamente
        </Button>
      </Box>

      <Box mt={3}>
        <Button
          className={buttonClasses.linkButton}
          color="secondary"
          onClick={() => history.push(`/${project.slug}/imoveis`)}
        >
          Voltar para imóveis
        </Button>
      </Box>
    </Box>
  );
}

MapsZeroState.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  mapIntegrity: PropTypes.bool.isRequired,
  children: PropTypes.oneOf([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  onReload: PropTypes.func.isRequired,
};

MapsZeroState.defaultProps = {
  children: null,
};

export default MapsZeroState;
