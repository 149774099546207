import createRemittanceQuery from 'queries/createRemittanceQuery';
import api from 'services/api';
import handlerError from 'utils/handleError';
import Alert from 'modules/AlertModule';

export default (projectId, onRedirect) =>
  api
    .call(createRemittanceQuery, { projectId })
    .then(async ({ createRemittance: data }) => {
      const { remittance } = data;
      const failedSalesContracts = data.failedSalesContracts?.join(', #');

      const invalidClientsMessage = `Por favor, preencha os dados de endereço do cliente dos Contratos: #${failedSalesContracts}`;

      if (!remittance) {
        await Alert.info(invalidClientsMessage);
        return null;
      }

      await Alert.success('Remessa gerada com sucesso');
      return remittance;
    })
    .catch((e) => {
      handlerError(
        {
          BANK_ACCOUNT_IS_NULL: () => {
            Alert.error(
              'Você precisa adicionar uma conta bancária para gerar uma remessa.',
              {
                confirmButtonText: 'Ir para o financeiro',
                cancelButtonText: 'Fechar',
                reverseButtons: true,
              }
            ).then((result) => {
              if (result.isConfirmed) {
                onRedirect();
              }
            });
          },
          DEFAULT: Alert.onError('Erro ao gerar remessa'),
        },
        e.message
      );

      throw e;
    });
