import { BsArrowRight } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import React, { useState } from 'react';
import { Box, Button, Grid, TextField } from '@material-ui/core';

import api from 'services/api';
import useQuery from 'hooks/useQuery';
import Alert from 'modules/AlertModule';
import LOGIN_AS_QUERY from 'queries/loginAsQuery';
import useThemeClasses from 'theme/useThemeClasses';

export default function LoginAsForm() {
  const history = useHistory();
  const themeClasses = useThemeClasses;
  const buttonClasses = themeClasses.button();

  const [username, setUsername] = useState('');
  const [projectSlug, setProjectSlug] = useState('');
  const [loginAs] = useQuery(LOGIN_AS_QUERY);

  const handleSubmit = (e) => {
    e.preventDefault();

    loginAs({ username, projectSlug })
      .then(() => {
        api.resetSocket();
        history.push('/');
      })
      .catch(Alert.onError(`Falha ao logar como ${username}`));
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            autoComplete="email"
            label="Email do Usuário"
            type="email"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            autoComplete="projectSlug"
            name="if-password"
            label="Empreendimento (slug)"
            type="text"
            value={projectSlug}
            onChange={(e) => setProjectSlug(e.target.value)}
            helperText="Apenas para Imobiliáras/Corretores"
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <Box mt={2.5} display="flex" justifyContent="flex-end">
            <Button
              className={buttonClasses.buttonWithIcon}
              endIcon={<BsArrowRight />}
              variant="contained"
              name="if-submit"
              color="primary"
              type="submit"
              style={{ width: 260 }}
            >
              Logar Como
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
}
