import React from 'react';
import { Box, Button } from '@material-ui/core';
import PropTypes from 'prop-types';

export default function ExtraActions({
  showNewInvoiceModal,
  showPriceAdjustmentModal,
}) {
  return (
    <Box display="flex" justifyContent="flex-end">
      <Button
        variant="contained"
        color="secondary"
        size="small"
        onClick={() => showNewInvoiceModal(true)}
      >
        Criar título único
      </Button>

      <Box ml={1}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => showPriceAdjustmentModal(true)}
        >
          Reajuste
        </Button>
      </Box>
    </Box>
  );
}

ExtraActions.propTypes = {
  showNewInvoiceModal: PropTypes.func,
  showPriceAdjustmentModal: PropTypes.func,
};

ExtraActions.defaultProps = {
  showNewInvoiceModal: () => {},
  showPriceAdjustmentModal: () => {},
};
