/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Container } from '@material-ui/core';
import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Header from 'components/Header';
import Loading from 'components/Loading';
import ProjectsList from 'components/Lists/ProjectsList';
import UserProfile from 'components/UserProfile';
import { AuthContext } from 'providers/AuthProvider';
import SYSTEM_ACCESS_ENTITY_TYPES from '@constants/systemAccess/entityTypes';

function Home() {
  const history = useHistory();

  const { auth, authenticate } = useContext(AuthContext);
  const access = auth?.access;
  const environment = auth?.environment;

  const [userProfileAnchor, setUserProfileAnchor] = useState(null);

  useEffect(() => {
    authenticate(true);
  }, []);

  useEffect(() => {
    const salesEntityTypes = [
      SYSTEM_ACCESS_ENTITY_TYPES.REAL_ESTATE,
      SYSTEM_ACCESS_ENTITY_TYPES.REAL_ESTATE_AGENT,
    ];

    if (auth && salesEntityTypes.includes(auth.access.entityType)) {
      const projectSlug = auth.access.project.slug;
      history.push(`/${projectSlug}`);
    }
  }, [auth]);

  const handleSelectProject = (project) => {
    history.push(`/${project.slug}`);
  };

  if (!auth) return <Loading />;
  if (environment !== 'administration') return null;

  return (
    <Box component="main">
      <Box mb={2.5}>
        <Header onShowUserProfile={(elem) => setUserProfileAnchor(elem)} />

        <UserProfile
          access={access}
          open={Boolean(userProfileAnchor)}
          anchor={userProfileAnchor}
          onClose={() => setUserProfileAnchor(null)}
        />
      </Box>

      <Container>
        <ProjectsList onSelect={handleSelectProject} />
      </Container>
    </Box>
  );
}

export default Home;
