const { makeStyles } = require('@material-ui/core');

export default makeStyles((theme) => ({
  listFilters: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
    paddingTop: ({ extraActions }) => (extraActions ? 24 : 0),
    [theme.breakpoints.up('md')]: {
      justifyContent: ({ extraActions }) =>
        extraActions ? 'flex-start' : 'flex-end',
    },
  },
}));
