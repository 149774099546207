/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useState, useContext } from 'react';

import { ProjectContext } from 'providers/ProjectProvider';
import useGetInvites from './hooks/useGetInvites';

const InvitesListContext = createContext();

export default function InvitesListProvider({ children }) {
  const { project } = useContext(ProjectContext);
  const [search, setSearch] = useState('');
  const [variables, setVariables] = useState({
    projectId: project._id,
    page: 0,
    search,
    sortOptions: {
      field: 'createdAt',
      direction: 'desc',
    },
    filterOptions: {
      status: null,
    },
    changedAt: Date.now(),
  });
  const { invites, pages, isLoading } = useGetInvites(variables, search);

  const setPage = (value) => setVariables({ ...variables, page: value });

  const setFilter = (value) => {
    const newFilterOptions = { ...variables.filterOptions, ...value };
    setVariables({ ...variables, page: 0, filterOptions: newFilterOptions });
  };

  const setSort = (option) =>
    setVariables({
      ...variables,
      page: 0,
      sortOptions: {
        field: option.value,
        direction: option.reverse ? 'desc' : 'asc',
      },
    });

  const updateList = () =>
    setVariables({ ...variables, changedAt: Date.now() });

  return (
    <InvitesListContext.Provider
      value={{
        invites,
        pages,
        isLoading,
        search,
        variables,
        updateList,
        setPage,
        setFilter,
        setSort,
        setSearch,
      }}
    >
      {children}
    </InvitesListContext.Provider>
  );
}

export function useInvitesListContext() {
  return useContext(InvitesListContext);
}
