import AlertModule from 'modules/AlertModule';
import handlerError from 'utils/handleError';

export default function errorHandler(error, type) {
  const projectErrorHandlers = {
    USER_IN_PROJECT: AlertModule.onError(
      'O usuário vínculado ao e-mail já participa do projeto.'
    ),
    DEFAULT: AlertModule.onError('Não foi possível reenviar o convite.'),
  };

  const inviteErrorHandlers = {
    NOT_AUTHENTICATED: AlertModule.onError('Você precisa estar autenticado.'),

    INVALID_INVITE: AlertModule.onError('Convite inválido.'),

    DEFAULT: AlertModule.onError('Não foi possível cancelar o convite.'),
  };

  const errorHandlerTypes = {
    resendInvite: projectErrorHandlers,
    cancelInvite: inviteErrorHandlers,
  };

  handlerError(errorHandlerTypes[type], error.message);
}
