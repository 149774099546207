import React from 'react';
import { Box, Grid, Typography, useTheme } from '@material-ui/core';
import DescriptionData from './DescriptionData';

export default function RealEstateAgentData({ reservation }) {
  const theme = useTheme();
  if (!reservation.realEstateAgent && !reservation.observation) return null;
  return (
    <Grid item md={6}>
      <Box marginLeft={5}>
        {reservation.realEstateAgent && (
          <>
            <Box marginTop={5} marginBottom={1}>
              <Typography
                style={{
                  fontWeight: '600',
                  color: theme.palette.secondary.dark,
                }}
              >
                Informações do corretor
              </Typography>
            </Box>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h6" color="textSecondary">
                  Imobiliária
                </Typography>
                <Typography variant="body2">
                  {reservation.realEstate.name}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6" color="textSecondary">
                  Nome
                </Typography>

                <Typography variant="body2">
                  {reservation.realEstateAgent.name}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="h6" color="textSecondary">
                  CRECI
                </Typography>
                <Typography variant="body2">
                  {reservation.realEstateAgent.creci}
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
        <DescriptionData observation={reservation.observation} />
      </Box>
    </Grid>
  );
}
