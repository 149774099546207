import crypto from 'crypto'

export default function generateObjectChecksum(object) {
    if (!object) return null

    const checksum = crypto
        .createHash('md5')
        .update(JSON.stringify(object))
        .digest('hex')

    return checksum
}