import { Box, Button, Grid, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { BsArrowRight } from 'react-icons/bs';
import useThemeClasses from 'theme/useThemeClasses';
import PropTypes from 'prop-types';
import PasswordTextField from 'components/TextFields/PasswordTextField';

export default function RegisterForm({ email, onSubmit, onDecline }) {
  const themeClasses = useThemeClasses;
  const buttonClasses = themeClasses.button();

  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    onSubmit({
      name,
      password,
      password2,
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            autoComplete="email"
            type="email"
            label="E-mail"
            value={email}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            autoComplete="name"
            name="if-name"
            label="Nome completo"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <PasswordTextField
            label="Senha"
            name="if-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Confirme a senha"
            name="if-password2"
            type="password"
            value={password2}
            onChange={(e) => setPassword2(e.target.value)}
            error={Boolean(password2 && password2 !== password)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Box mt={2.5} display="flex" justifyContent="flex-end">
            <Button
              name="if-reject"
              className={buttonClasses.linkButton}
              onClick={() => onDecline()}
              color="secondary"
            >
              Rejeitar
            </Button>

            <Button
              className={buttonClasses.buttonWithIcon}
              endIcon={<BsArrowRight />}
              variant="contained"
              name="if-submit"
              color="primary"
              type="submit"
              style={{ width: 260 }}
            >
              Aceitar
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
}

RegisterForm.propTypes = {
  email: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired,
};

RegisterForm.defaultProps = {
  email: '',
};
