import useQuery from 'hooks/useQuery';
import AlertModule from 'modules/AlertModule';
import ErrorHandler from 'modules/ErrorHandlerModule';
import CREATE_REAL_ESTATE_MUTATION from 'queries/createRealEstateMutation';

export default function useCreateRealEstate(onSuccess) {
  const [executeCreate] = useQuery(CREATE_REAL_ESTATE_MUTATION);

  const onCreateError = ErrorHandler(
    'Não foi possível cadastrar a imobiliária.'
  );

  const onResolveCreate =
    (realEstate) =>
    ({ createRealEstate: data }) => {
      const message =
        realEstate.active && data.invited
          ? 'Imobiliária cadastrada com sucesso! O convite foi enviado automaticamente para o email cadastrado.'
          : 'Imobiliária cadastrada com sucesso, mas não foi possível enviar o convite.';

      return AlertModule.success(message);
    };

  return (realEstate) =>
    AlertModule.confirmation(
      'Será enviado um convite para o e-mail da imobiliária. Deseja continuar?'
    ).then((result) => {
      if (result.isConfirmed) {
        executeCreate(realEstate)
          .then(onResolveCreate(realEstate))
          .then(onSuccess)
          .catch(onCreateError);
      }
    });
}
