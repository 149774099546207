import AlertModule from 'modules/AlertModule';
import CREATE_REAL_ESTATE_AGENT_MUTATION from 'queries/createRealEstateAgentMutation';
import { useCallback } from 'react';
import api from 'services/api';
import handlerError from 'utils/handleError';

export default function useCreateRealEstateAgent(projectId, realEstateAgent) {
  const execute = useCallback(() => {
    const newRealEstateAgent = {
      ...realEstateAgent,
      projectId,
    };

    AlertModule.wait();

    return api
      .call(CREATE_REAL_ESTATE_AGENT_MUTATION, newRealEstateAgent)
      .then(({ createRealEstateAgent: data }) => {
        if (realEstateAgent.active) {
          const { invited } = data;

          if (invited) {
            return AlertModule.success(
              'Corretor cadastrado com sucesso! O convite foi enviado automaticamente para o email cadastrado.'
            );
          }
          return AlertModule.success(
            'Corretor cadastrado com sucesso, mas não foi possível enviar o convite.'
          );
        }

        return AlertModule.success('Corretor cadastrado com sucesso!');
      })
      .catch((error) => {
        handlerError(
          {
            CPF_IN_USE: AlertModule.onError(
              'Já existe uma corretor cadastrado com esse CPF.'
            ),
            INVALID_EMAIL: AlertModule.onError('E-mail inválido.'),
            INVALID_USERNAME: AlertModule.onError('E-mail de acesso inválido.'),
            USERNAME_IN_USE: AlertModule.onError(
              'Esse e-mail de acesso já está em uso.'
            ),
            DEFAULT: AlertModule.onError(
              'Não foi possível cadastrar o corretor.'
            ),
          },
          error.message
        );

        throw error;
      });
  }, [projectId, realEstateAgent]);

  return execute;
}
