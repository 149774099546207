import React from 'react';
import Icon from 'components/Icon';
import ICON_TYPES from '@constants/iconsPaths';
import formatPrice from 'utils/formatPrice';

export default function getSummarySettings({ data, label }) {
  if (!data) return null;

  const summary = {
    properties: [
      {
        label: 'Total de imóveis',
        value: formatPrice(data.totalProperties, {
          maximumFractionDigits: 2,
          minimunFractionDigits: 2,
        }),
        icon: <Icon src={ICON_TYPES.SUMMARY.totalProperties} size={37} />,
      },
      {
        label: 'Área total (m²)',
        value: formatPrice(data.totalArea, {
          maximumFractionDigits: 2,
          minimunFractionDigits: 2,
        }),
        icon: <Icon src={ICON_TYPES.SUMMARY.totalArea} size={43} />,
      },
      {
        label: 'Preço total (R$)',
        value: formatPrice(data.totalPrice, {
          maximumFractionDigits: 2,
          minimunFractionDigits: 2,
        }),
        icon: <Icon src={ICON_TYPES.SUMMARY.totalPrice} size={42} />,
      },
    ],
    invoices: [
      {
        label: 'Total de títulos',
        value: formatPrice(data.totalSaleItens, {
          maximumFractionDigits: 2,
          minimunFractionDigits: 2,
        }),
        icon: <Icon src={ICON_TYPES.SUMMARY.totalInvoices} size={42} />,
      },
      {
        label: 'Valor total (R$)',
        value: formatPrice(data.totalPrice, {
          maximumFractionDigits: 2,
          minimunFractionDigits: 2,
        }),
        icon: <Icon src={ICON_TYPES.SUMMARY.totalPrice} size={42} />,
      },
      {
        label: 'Valor total pago (R$)',
        value: formatPrice(data.totalAmountPaid, {
          maximumFractionDigits: 2,
          minimunFractionDigits: 2,
        }),
        icon: <Icon src={ICON_TYPES.SUMMARY.totalPaid} size={42} />,
      },
    ],
  };
  return summary[label];
}
