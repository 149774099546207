const PROPERTIES_PRICE_ADJUSTMENT_MUTATION = `
  mutation PropertiesPriceAdjustment (
    $projectId: ID!
    $propertiesIds: [ID]!
    $percent: Float!
  ) {
    propertiesPriceAdjustment (
      projectId: $projectId
      propertiesIds: $propertiesIds
      percent: $percent
    )
  }
`;

export default PROPERTIES_PRICE_ADJUSTMENT_MUTATION;
