import React from 'react';
import { Box, IconButton, useTheme } from '@material-ui/core';
import { IoMdRemoveCircleOutline } from 'react-icons/io';
import { RiMailSendLine } from 'react-icons/ri';

import INVITE_STATUS from '@constants/invites/status';
import useActions from '../hooks/useActions';

export default function ListActions({ rowData }) {
  const theme = useTheme();
  const { cancel, resend } = useActions();

  const canResendEmail = [
    INVITE_STATUS.PENDING.value,
    INVITE_STATUS.EXPIRED.value,
  ].includes(rowData.status);

  return (
    <Box display="flex" justifyContent="flex-end">
      {rowData.status === INVITE_STATUS.PENDING.value && (
        <>
          <IconButton
            title="Cancelar"
            size="small"
            onClick={() => cancel(rowData)}
          >
            <IoMdRemoveCircleOutline color={theme.palette.error.main} />
          </IconButton>

          <Box width={4} />
        </>
      )}

      {canResendEmail && (
        <IconButton
          title="Reenviar"
          size="small"
          onClick={() => resend(rowData)}
        >
          <RiMailSendLine color={theme.palette.grey[400]} />
        </IconButton>
      )}
    </Box>
  );
}
