import {
  Box,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React, { useState } from 'react';
import useThemeClasses from 'theme/useThemeClasses';
import PropTypes from 'prop-types';
import FilterButton from './FilterButton';

export default function SortSelect({ value, options, onChange }) {
  const theme = useTheme();
  const themeClasses = useThemeClasses;
  const textClasses = themeClasses.text();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));

  const [sortAnchorEl, setSortAnchorEl] = useState(null);

  const getValueLabel = () => (value ? value.label : 'undefined');
  const handleChange = (option) => {
    setSortAnchorEl(null);
    onChange(option);
  };

  const isSelected = (option) => option.id === value.id;
  const definitions = {
    sm: {
      size: 'small',
      tooltipTitle: getValueLabel(),
      label: getValueLabel(),
      iconSize: 18,
    },
    default: {
      size: 'medium',
      tooltipTitle: 'Ordenação',
      label: getValueLabel(),
      iconSize: 24,
    },
  };
  const def = isSm ? definitions.sm : definitions.default;

  return (
    <Box>
      <Typography component="div" className={textClasses.sortLabel}>
        <Box display="flex" alignItems="center">
          <Tooltip title={def.tooltipTitle}>
            <FilterButton
              icon="sort"
              label={def.label}
              onClick={(e) => setSortAnchorEl(e.currentTarget)}
            />
          </Tooltip>
        </Box>
      </Typography>

      <Menu
        keepMounted
        anchorEl={sortAnchorEl}
        open={Boolean(sortAnchorEl)}
        onClose={() => setSortAnchorEl(null)}
      >
        {options.map((option) => (
          <MenuItem
            key={option.id}
            selected={isSelected(option)}
            onClick={() => handleChange(option)}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}

SortSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.any]).isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
};
