const GET_CUSTOMERS_QUERY = `
    query GetCustomers(
      $projectId:ID!
      $page: Int
      $search: String
      $sortOptions : CustomerSort
    ){
      getCustomers(
        projectId: $projectId
        page: $page 
        search: $search
        sortOptions : $sortOptions
      ) {
        customers {
          _id
          projectId
          name
          cpf
          rg
          maritalStatus
          occupation
          postalCode
          address
          addressNumber
          addressComplement
          district
          city
          state
          email
          phone
          phone2
          createdAt          
          active
        }
        page
        pages
      }
    }
  `;
export default GET_CUSTOMERS_QUERY;
