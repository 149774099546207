import React from 'react';
import PropTypes from 'prop-types';

import { useReservationsListContext } from 'providers/ListProviders/ReservationsListProvider';

import BaseList from '../BaseList';

import useColumns from './hooks/useColumns';

export default function ReservationsList({ isAdmin, onRead, onEdit }) {
  const { reservations, pages, variables, setPage, handleUpdate } =
    useReservationsListContext();

  const columns = useColumns(handleUpdate, isAdmin, onEdit);

  const handleRowClick = (rowData) => {
    onRead(rowData._id);
  };

  return (
    <BaseList
      columns={columns}
      data={reservations}
      currentPage={variables.page}
      pages={pages}
      onPagination={setPage}
      onRowClick={handleRowClick}
      changedAt={variables.changedAt}
    />
  );
}

ReservationsList.propTypes = {
  isAdmin: PropTypes.bool,
  onRead: PropTypes.func,
  onEdit: PropTypes.func,
};

ReservationsList.defaultProps = {
  isAdmin: false,
  onRead: () => {},
  onEdit: () => {},
};
