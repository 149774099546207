/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import api from 'services/api';
import getCustomersACQuery from 'queries/getCustomersACQuery';
import formatCpf from 'utils/formatCpf';
import { createFilterOptions } from '@material-ui/lab';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import BaseAutoComplete from './BaseAutoComplete';

const debouncedGetCustomers = debounce(
  (variables, resolve, reject) =>
    api.call(getCustomersACQuery(variables)).then(resolve).catch(reject),
  300
);

export default function CustomerAutoComplete({
  id,
  projectId,
  label,
  value,
  onChange,
  required,
  ...props
}) {
  const query = (search) => {
    const variables = {
      projectId,
      search,
    };

    return new Promise((resolve, reject) => {
      debouncedGetCustomers(
        variables,
        ({ getCustomers: data }) => {
          resolve(data.customers);
        },
        reject
      );
    });
  };

  const getOptionLabel = (customer) =>
    `${customer.name} - ${formatCpf(customer.cpf)}`;

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option) => `${option.name} - ${option.cpf}`,
  });

  return (
    <BaseAutoComplete
      {...props}
      id={id}
      label={label}
      value={value}
      required={required}
      onQuery={query}
      onChange={onChange}
      getOptionLabel={getOptionLabel}
      filterOptions={filterOptions}
    />
  );
}

CustomerAutoComplete.propTypes = {
  id: PropTypes.string,
  projectId: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
};

CustomerAutoComplete.defaultProps = {
  id: '',
  label: '',
  value: null,
  required: false,
};
