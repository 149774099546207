/* eslint-disable react/prop-types */
import { monthlyDueDays } from '@constants/monthlyDueDays';
import { annuallyDueDays } from '@constants/annuallyDueDays';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  Select,
  Typography,
  useTheme,
} from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { BsArrowRight } from 'react-icons/bs';
import useThemeClasses from 'theme/useThemeClasses';
import generateMonthlyDueDates from './generate-monthly-due-dates';
import generateYearlyDueDates from './generate-yearly-due-dates';
import validateFirstMonthlyInstallmentDate from './validate-first-monthly-installment-date';
import validateFirstYearlyInstallmentDate from './validate-first-yearly-installment-date';

export default function DueDateStep({
  paymentMethod,
  onBack,
  onSubmit,
  ...props
}) {
  const theme = useTheme();
  const themeClasses = useThemeClasses;
  const buttonClasses = themeClasses.button();

  const [monthlyDueDay, setMonthlyDueDay] = useState(props.monthlyDueDay);

  const [firstMonthlyInstallmentDate, setFirstMonthlyInstallmentDate] =
    useState(props.firstMonthlyInstallmentDate);

  const [firstMonthlyInstallmentDateErr, setFirstMonthlyInstallmentDateErr] =
    useState(false);

  const [annuallyDueDay, setAnnuallyDueDay] = useState(props.annuallyDueDay);

  const [firstAnnuallyInstallmentDate, setFirstAnnuallyInstallmentDate] =
    useState(props.firstAnnuallyInstallmentDate);

  const [firstAnnuallyInstallmentDateErr, setFirstAnnuallyInstallmentDateErr] =
    useState(false);

  const monthlyDueDates = useMemo(() => generateMonthlyDueDates(), []);

  const annuallyDueDates = useMemo(
    () => generateYearlyDueDates(firstMonthlyInstallmentDate),
    [firstMonthlyInstallmentDate]
  );

  const firstMonthlyInstallmentFulldate = useMemo(
    () =>
      validateFirstMonthlyInstallmentDate(
        firstMonthlyInstallmentDate,
        monthlyDueDay
      ),
    [monthlyDueDay, firstMonthlyInstallmentDate]
  );

  const firstAnnuallyInstallmentFulldate = useMemo(
    () =>
      validateFirstYearlyInstallmentDate(
        firstAnnuallyInstallmentDate,
        annuallyDueDay,
        firstMonthlyInstallmentFulldate
      ),
    [
      annuallyDueDay,
      firstAnnuallyInstallmentDate,
      firstMonthlyInstallmentFulldate,
    ]
  );

  const handleSubmit = () => {
    if (!firstMonthlyInstallmentFulldate) {
      setFirstMonthlyInstallmentDateErr(true);
      return;
    }

    if (paymentMethod === 'annually' && !firstAnnuallyInstallmentFulldate) {
      setFirstAnnuallyInstallmentDateErr(true);
      return;
    }

    onSubmit({
      monthlyDueDay,
      firstMonthlyInstallmentDate,
      annuallyDueDay,
      firstAnnuallyInstallmentDate,
    });
  };

  const handleBack = (event) => {
    if (event) event.preventDefault();
    if (onBack) {
      onBack({
        monthlyDueDay,
        firstMonthlyInstallmentDate,
        annuallyDueDay,
        firstAnnuallyInstallmentDate,
      });
    }
  };

  useEffect(() => {
    if (
      !monthlyDueDates.find(
        (item) => item.value === firstMonthlyInstallmentDate
      )
    ) {
      setFirstMonthlyInstallmentDate(monthlyDueDates[0]?.value);
    }
  }, [monthlyDueDates, firstMonthlyInstallmentDate]);

  useEffect(() => {
    if (
      !annuallyDueDates.find(
        (item) => item.value === firstAnnuallyInstallmentDate
      )
    ) {
      setFirstAnnuallyInstallmentDate(annuallyDueDates[0]?.value);
    }
  }, [annuallyDueDates, firstAnnuallyInstallmentDate]);

  return (
    <Box>
      <Box mb={5}>
        <Box mb={2}>
          <Typography
            variant="h5"
            style={{ color: theme.palette.secondary.dark }}
          >
            1ª prestação mensal
          </Typography>
        </Box>

        <Box mb={3}>
          <Typography
            variant="body2"
            style={{ color: theme.palette.secondary.main }}
          >
            Qual o dia da 1ª prestação mensal?
          </Typography>

          <Box display="flex" flexDirection="column" mt={1}>
            {monthlyDueDays.map((dueDay) => (
              <FormControlLabel
                key={dueDay}
                value={dueDay}
                control={<Radio color="primary" />}
                label={`Dia ${dueDay}`}
                checked={monthlyDueDay === dueDay}
                onClick={() => setMonthlyDueDay(dueDay)}
                ref={(component) => {
                  if (component) {
                    component.lastChild.classList.remove('MuiTypography-body1');
                    component.lastChild.classList.add('MuiTypography-body2');
                  }
                }}
              />
            ))}
          </Box>
        </Box>

        <Box mb={3}>
          <Typography
            variant="body2"
            style={{ color: theme.palette.secondary.main }}
          >
            Mês da 1ª prestação mensal
          </Typography>

          <Box mt={1}>
            <FormControl fullWidth>
              <Select
                native
                value={firstMonthlyInstallmentDate}
                onChange={(e) => {
                  setFirstMonthlyInstallmentDate(e.target.value);
                  setFirstMonthlyInstallmentDateErr(false);
                }}
                error={firstMonthlyInstallmentDateErr}
              >
                {monthlyDueDates.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Box>

          {!firstMonthlyInstallmentFulldate ? (
            <Box style={{ marginTop: 8 }}>
              <Typography variant="caption" style={{ color: 'red' }}>
                {'Selecione uma data igual ou superior a '}
                {new Date().toLocaleString('pt-BR', {
                  month: 'long',
                  day: '2-digit',
                  year: 'numeric',
                })}
                .
              </Typography>
            </Box>
          ) : null}
        </Box>
      </Box>

      {paymentMethod === 'annually' ? (
        <Box mb={5}>
          <Box mb={2}>
            <Typography
              variant="h5"
              style={{ color: theme.palette.secondary.dark }}
            >
              1ª prestação anual
            </Typography>
          </Box>

          <Box mb={3}>
            <Typography
              variant="body2"
              style={{ color: theme.palette.secondary.main }}
            >
              Qual o dia da 1ª prestação anual?
            </Typography>

            <Box display="flex" flexDirection="column" mt={1}>
              {annuallyDueDays.map((dueDay) => (
                <FormControlLabel
                  key={dueDay}
                  control={<Radio color="primary" />}
                  label={`Dia ${dueDay}`}
                  checked={annuallyDueDay === dueDay}
                  onClick={() => setAnnuallyDueDay(dueDay)}
                  ref={(component) => {
                    if (component) {
                      component.lastChild.classList.remove(
                        'MuiTypography-body1'
                      );
                      component.lastChild.classList.add('MuiTypography-body2');
                    }
                  }}
                />
              ))}
            </Box>
          </Box>

          <Box mb={3}>
            <Typography
              variant="body2"
              style={{ color: theme.palette.secondary.main }}
            >
              Mês da 1ª prestação anual
            </Typography>

            <Box mt={1}>
              <FormControl fullWidth>
                <Select
                  native
                  value={firstAnnuallyInstallmentDate}
                  onChange={(e) => {
                    setFirstAnnuallyInstallmentDate(e.target.value);
                    setFirstAnnuallyInstallmentDateErr(false);
                  }}
                  error={firstAnnuallyInstallmentDateErr}
                >
                  {annuallyDueDates.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Box>

            {!firstAnnuallyInstallmentFulldate ? (
              <Box style={{ marginTop: 8 }}>
                <Typography variant="caption" style={{ color: 'red' }}>
                  Selecione uma data válida.
                </Typography>
              </Box>
            ) : null}
          </Box>
        </Box>
      ) : null}

      <Typography variant="body3" style={{ color: theme.palette.grey[400] }}>
        * As demais parcelas serão conforme periodicidade e plano de pagamento
        escolhido
      </Typography>

      <Box display="flex" justifyContent="flex-end" mt={5}>
        <Button
          className={buttonClasses.linkButton}
          onClick={handleBack}
          color="secondary"
        >
          Voltar
        </Button>

        <Button
          className={buttonClasses.buttonWithIcon}
          endIcon={<BsArrowRight />}
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          style={{ width: 260, marginLeft: 8 }}
        >
          Próximo
        </Button>
      </Box>
    </Box>
  );
}
