/* eslint-disable react/jsx-props-no-spreading */
import { TextField } from '@material-ui/core';
import React, { useMemo } from 'react';
import validateCPF from 'utils/validateCpf';

import PropTypes from 'prop-types';
import validateCNPJ from 'utils/validateCnpj';

export default function DocumentTextField({
  value,
  onChange,
  error,
  ...props
}) {
  const currentValue = useMemo(() => {
    const document = String(value);
    if (!document) return '';

    if (document.length <= 11) {
      return document
        .replace(
          /([0-9]{0,3})([0-9]{0,3})([0-9]{0,3})([0-9]{0,2})/,
          '$1.$2.$3-$4'
        )
        .replace(/[^0-9]+$/, '');
    }

    return document
      .replace(
        /^([0-9]{0,2})([0-9]{0,3})([0-9]{0,3})([0-9]{0,4})([0-9]{0,2})/g,
        '$1.$2.$3/$4-$5'
      )
      .replace(/[^0-9]+$/, '');
  }, [value]);

  const handleChange = (e) => {
    const rawValue = e.target.value.replace(/[^0-9]/g, '').slice(0, 14);
    if (onChange) onChange(e, rawValue);
  };

  const currentError = useMemo(() => {
    if (error) return true;
    if (!value) return false;

    return !validateCPF(value) && !validateCNPJ(value);
  }, [error, value]);

  return (
    <TextField
      {...props}
      value={currentValue}
      onChange={handleChange}
      error={currentError}
    />
  );
}

DocumentTextField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
};

DocumentTextField.defaultProps = {
  value: '',
  onChange: undefined,
  error: undefined,
};
