import { Box, Typography, IconButton } from '@material-ui/core';
import React from 'react';
import ICON_TYPES from '@constants/iconsPaths';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';
import useStyles from './styles';

export default function SelectedInvoicesPopup({
  selectedInvoices,
  hide,
  onEmitInvoices,
  onDeselectInvoices,
  onPrintInvoices,
  projectId,
}) {
  const classes = useStyles();

  if (hide) return null;

  return (
    <Box className={classes.card}>
      <Typography className={classes.text} variant="body3">
        {selectedInvoices.length} título selecionados
      </Typography>

      <Box className={classes.actionsContainer}>
        {/* To do, remove visual */}
        {/* <Box marginRight={3}>
          <IconButton title="Baixar" size="small">
            <Icon src={ICON_TYPES.POPUP.export} />
          </IconButton>
        </Box> */}
        <Box marginRight={3}>
          <IconButton
            title="Emitir"
            size="small"
            onClick={() => onEmitInvoices(selectedInvoices, projectId)}
          >
            <Icon src={ICON_TYPES.POPUP.emit} size={20} />
          </IconButton>
        </Box>
        <Box marginRight={3}>
          <IconButton
            title="Imprimir"
            size="small"
            onClick={() => onPrintInvoices(selectedInvoices)}
          >
            <Icon src={ICON_TYPES.POPUP.print} size={24} />
          </IconButton>
        </Box>
        <Box>
          <IconButton onClick={onDeselectInvoices} title="Fechar" size="small">
            <Icon src={ICON_TYPES.POPUP.close} size={18} />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
}

SelectedInvoicesPopup.propTypes = {
  selectedInvoices: PropTypes.arrayOf(PropTypes.string).isRequired,
  hide: PropTypes.bool.isRequired,
  onEmitInvoices: PropTypes.func.isRequired,
  onDeselectInvoices: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  onPrintInvoices: PropTypes.func.isRequired,
};
