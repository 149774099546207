/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import BaseList from 'components/Lists/BaseList';
import { useInvitesListContext } from 'providers/ListProviders/InvitesListProvider.js';
import useColumns from './hooks/useColumns';

function InvitesList() {
  const { invites, variables, pages, setPage } = useInvitesListContext();

  const columns = useColumns();

  return (
    <BaseList
      columns={columns}
      data={invites}
      currentPage={variables.page}
      pages={pages}
      onPagination={setPage}
      changedAt={variables.changedAt}
    />
  );
}
export default InvitesList;
