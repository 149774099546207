import React from 'react';
import { Box, Typography, Grid, TextField } from '@material-ui/core';
import CNPJTextField from 'components/TextFields/CNPJTextField';
import PropTypes from 'prop-types';

export default function General({ configuration, setConfiguration }) {
  return (
    <Grid>
      <Typography className="fw700" variant="h5">
        Informações do empreendimento
      </Typography>

      <Box my={2}>
        <CNPJTextField
          margin="dense"
          name="crs-cnpj"
          label="CNPJ"
          type="text"
          value={configuration.cnpj || ''}
          onChange={(e) => setConfiguration({ cnpj: e.target.value })}
          fullWidth
        />
      </Box>
      <Box my={2}>
        <TextField
          label="Nome ou Razão Social"
          value={configuration.companyName || ''}
          onChange={(e) => setConfiguration({ companyName: e.target.value })}
          fullWidth
        />
      </Box>
    </Grid>
  );
}

General.propTypes = {
  setConfiguration: PropTypes.func.isRequired,
  configuration: PropTypes.shape({
    companyName: PropTypes.string,
    cnpj: PropTypes.string,
  }).isRequired,
};
