import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  link: {
    fontSize: 12,
    color: theme.palette.text.primary,
    textTransform: 'uppercase',
    display: 'inline-block',
    margin: '16px 20px',

    '&.active': {
      color: theme.palette.secondary.dark,
      textDecoration: 'underline',
    },

    '&:hover': {
      color: theme.palette.secondary.dark,
      cursor: 'default',
    },
  },

  dropdown: {
    position: 'relative',
  },
  menu: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    top: 30,
    left: 5,
    right: 0,
    borderWidth: 1,
    width: 150,
    backgroundColor: 'white',
    borderRadius: 10,
    boxShadow: '0px 5px 10px -3px',
    overflow: 'visible',
    zIndex: 1,
  },
  menuItemContainer: { width: '100%', marginRight: 38, marginBottom: 5 },
  menuItem: {
    fontSize: 12,
    display: 'flex',
    color: theme.palette.text.primary,
    flex: 1,
    justifyContent: 'center',
    '&:hover': {
      color: theme.palette.secondary.dark,
      background: 'none',
    },
  },
  menuItemDivider: {
    borderBottom: `1px solid ${theme.palette.grey[600]}`,
  },
}));
