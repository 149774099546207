const getReturnFileQuery = `
  query GetReturnFIleQuery ($id: ID!) {
    getReturnFile(id: $id) {
      _id
      name
      fileId
      createdAt
      status
      result {
        contractNumberId
        customerName
        description
        nossoNumero
        invoiceId
        occurrenceType
        createdAt
        status
        message
      }
    }
  }
`;

export default getReturnFileQuery;
