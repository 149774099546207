import Swal from "sweetalert2";

export default function swalFire(title, text, icon) {
    const confirmWhenPressEnter = (event) => {
        event.preventDefault()

        if (event.keyCode === 13) {
            document.querySelector('.swal2-container button.swal2-confirm')?.click()
        }
    }

    return Swal.fire({
        title,
        text,
        icon,
        allowEnterKey: true,
        didOpen: () => {
            setTimeout(() => {
                document.addEventListener('keyup', confirmWhenPressEnter)
            }, 500)
        },
        didClose: () => {
            document.removeEventListener('keyup', confirmWhenPressEnter)
        }
    })
}