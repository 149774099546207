import { io } from 'socket.io-client';

const WebsocketApi = (serverUrl) => {
  const wsProtocol = serverUrl.isSecure() ? 'wss' : 'ws';
  const WS_API_ENDPOINT = serverUrl
    .withPath('')
    .withProtocol(wsProtocol)
    .toString();

  let socket;

  return {
    openSocket() {
      if (!socket) {
        socket = io(WS_API_ENDPOINT, {
          path: `${serverUrl.path}/socket.io/`,
          transports: ['websocket'],
          withCredentials: true,
        });

        socket.on('connect', () => {
          console.log(`Socket ${socket.id} connected`);
        });

        socket.on('disconnect', () => console.log('Socket disconnected'));

        socket.io.on('reconnect_attempt', (attempt) => {
          console.log(`Trying to reconnect to socket [${attempt}]`);
        });

        socket.io.on('reconnect', () => {
          console.log('Socket was successfully reconnected');
        });
      }
      return socket;
    },
    resetSocket() {
      if (socket) {
        socket.disconnect();
        socket = null;
      }

      socket = this.openSocket();

      return true;
    },
  };
};
export default WebsocketApi;
