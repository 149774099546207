import React from 'react';
import { IconButton } from '@material-ui/core';
import Icon from 'components/Icon';
import preventDefault from 'utils/preventDefault';
import ICON_TYPES from '@constants/iconsPaths';

export default function useColumns(onEdit) {
  const columns = [
    { title: 'Banco', field: 'bankName' },
    {
      title: 'Agência',
      field: 'branchNumber',
      render: ({ branchNumber, branchDigit }) => {
        const Agency = branchDigit
          ? `${branchNumber}-${branchDigit}`
          : `${branchNumber}-0`;
        return Agency;
      },
    },
    {
      title: 'Conta',
      field: 'accountNumber',
      render: ({ accountNumber, accountDigit }) =>
        `${accountNumber}-${accountDigit}`,
    },
    { title: 'Razão social', field: 'companyName' },
    { title: 'Descrição', field: 'description' },
    {
      title: '',
      field: '_actions',
      fit: true,
      render: (rowData) => (
        <IconButton
          title="Editar"
          size="small"
          onClick={preventDefault(() => onEdit(rowData))}
        >
          <Icon src={ICON_TYPES.LIST.edit} size={21} />
        </IconButton>
      ),
    },
  ];

  return columns;
}
