import React, { useMemo } from 'react';
import { ButtonBase, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { BsCaretDownFill } from 'react-icons/bs';
import DateFilterIcon from './icons/DateFilterIcon';
import FilterIcon from './icons/FilterIcon';
import ParcelFilterIcon from './icons/ParcelFilterIcon';
import SortIcon from './icons/SortIcon';
import PriceFilterIcon from './icons/PriceFilterIcon';
import useStyles from './styles';

const FilterButton = React.forwardRef((props, ref) => {
  const classes = useStyles({ fullWidth: props.fullWidth });

  const icon = useMemo(() => {
    const icons = {
      date: <DateFilterIcon />,
      filter: <FilterIcon />,
      parcel: <ParcelFilterIcon />,
      sort: <SortIcon />,
      price: <PriceFilterIcon />,
    };

    return icons[props.icon] || icons.filter;
  }, [props.icon]);

  return (
    <ButtonBase
      className={classes.filterButton}
      onClick={props.onClick}
      ref={ref}
    >
      <div className="icon">{icon}</div>
      <Typography>{props.label}</Typography>
      <div className="caret">
        <BsCaretDownFill />
      </div>
    </ButtonBase>
  );
});

FilterButton.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
};

FilterButton.defaultProps = {
  icon: 'filter',
  fullWidth: false,
};

export default FilterButton;
