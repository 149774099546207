export default function formatConfigurationVariables(variables) {
  return {
    projectId: variables.projectId,
    entranceFee: Number(variables.entranceFee),
    annualQuota: Number(variables.annualQuota),
    installments: variables.installments.map((installment) =>
      Number(installment)
    ),
    discount: variables.discount,
    cnpj: variables.cnpj,
    companyName: variables.companyName,
  };
}
