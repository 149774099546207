import React from 'react';

export default function PaymentIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#FF6C4E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M14.5 19L12 20l-2.5-1L7 20V7h10v13l-2.5-1z"
        clipRule="evenodd"
      />
      <path
        stroke="#FF6C4E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M10 10h4m0 3h-4m0 3h3m4-5h2a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2"
      />
    </svg>
  );
}
