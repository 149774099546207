import React, { useContext, useState } from 'react';

import CreateInvitationDialog from 'components/Dialogs/CreateDialogs/CreateInvitationDialog';
import UsersListFilters from 'components/ListFilters/UsersListFilters';
import UsersList from 'components/Lists/UsersList';
import UsersListZeroState from 'components/ZeroState/UsersListZeroState';
import UsersListProvider from 'providers/ListProviders/UsersListProvider';
import { ProjectContext } from 'providers/ProjectProvider';

export default function UserTab() {
  const { project } = useContext(ProjectContext);
  const [showInviteModal, setShowInviteModal] = useState(false);

  const openCreateInviteModal = () => setShowInviteModal(true);
  const closeCreateInviteModal = () => setShowInviteModal(false);

  return (
    <UsersListProvider>
      <UsersListFilters onClick={openCreateInviteModal} />
      <UsersListZeroState>
        <UsersList />
      </UsersListZeroState>

      <CreateInvitationDialog
        projectId={project._id}
        open={showInviteModal}
        onClose={closeCreateInviteModal}
      />
    </UsersListProvider>
  );
}
