import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';
import { Box, Container, Typography } from '@material-ui/core';

import { AuthContext } from 'providers/AuthProvider';
import { ProjectContext } from 'providers/ProjectProvider';
import UserProfileButton from 'components/UserProfile/buttons/ProfileButton';

import useLinks from '../useLinks';
import useStyles from './styles';
import HeaderLink from './link';
import Dropdown from './Dropdown';

export default function DesktopHeader({ onShowUserProfile }) {
  const classes = useStyles();

  const { project } = useContext(ProjectContext);
  const { auth } = useContext(AuthContext);

  const environment = auth?.environment;
  const links = useLinks(environment, project);

  const headerLinks = useMemo(
    () =>
      links.map((link) => {
        const types = {
          link: <HeaderLink value={link} key={link.id} />,
          dropdown: <Dropdown value={link} key={link.id} />,
        };

        return types[link.type] || null;
      }),
    [links]
  );

  return (
    <Box component="header" className={classes.header}>
      <Container>
        <Box display="flex" alignItems="center" height={104}>
          <Box>
            <img src="/assets/images/logo.svg" alt="Quadra A" width="95" />

            <Typography className={classes.projectTitle}>
              {project?.title}
            </Typography>
          </Box>

          <Box className={classes.links}>{headerLinks}</Box>

          <Box display="flex" justifyContent="flex-end">
            <UserProfileButton onShowUserProfile={onShowUserProfile} />
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

DesktopHeader.propTypes = {
  onShowUserProfile: PropTypes.func.isRequired,
};
