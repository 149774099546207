import { useMemo } from 'react';

export default function useFilter({ users, search, page, sortOptions }) {
  const data = useMemo(
    () =>
      users
        .filter(({ user }) => {
          if (!search) return true;
          const searchTarget = `${user.name} ${user.email}`.toUpperCase();
          return searchTarget.includes(search.toUpperCase());
        })
        .sort((a, b) => {
          if (
            !sortOptions ||
            a.user[sortOptions.field] === b.user[sortOptions.field]
          ) {
            return 0;
          }
          const result =
            a.user[sortOptions.field] > b.user[sortOptions.field] ? 1 : -1;
          return sortOptions.direction === 'desc' ? result * -1 : result;
        })
        .slice(page * 50, 50),
    [users, search, page, sortOptions]
  );
  return data;
}
