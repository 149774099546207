import React, { useState } from 'react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';

import CNPJTextField from 'components/TextFields/CNPJTextField';
import EmailTextField from 'components/TextFields/EmailTextField';
import PhoneTextField from 'components/TextFields/PhoneTextField';

import useStyles from './styles';

export default function GeneralTab({ realEstate, setRealEstate, action }) {
  const classes = useStyles();
  const [reuseContactEmail, setReuseContactEmail] = useState(false);
  const isContactEmailSet = Boolean(realEstate.email);
  const isAccessEmailSet = Boolean(realEstate.username);
  const isAccessEnabled = realEstate.active;

  const onContactEmailChange = (e) => {
    const { value } = e.target;
    setRealEstate({
      email: value,
      username: reuseContactEmail ? value : realEstate.username,
    });
  };

  const onReuseContactEmail = (e) => {
    const reuseEmail = e.target.checked;
    setReuseContactEmail(reuseEmail);
    setRealEstate({
      email:
        isAccessEmailSet && !isContactEmailSet && reuseEmail
          ? realEstate.username
          : realEstate.email,
      username:
        isContactEmailSet && reuseEmail
          ? realEstate.email
          : realEstate.username,
    });
  };

  return (
    <Grid container spacing={3}>
      <Box width="100%" px={1.5} mb={-1}>
        <Typography className={classes.sectionTitle}>
          Informações Gerais
        </Typography>
      </Box>

      <Grid item xs={12}>
        <TextField
          autoFocus
          name="crs-name"
          margin="dense"
          label="Nome"
          type="text"
          value={realEstate.name || ''}
          onChange={(e) => setRealEstate({ name: e.target.value })}
          fullWidth
          required
        />
      </Grid>

      <Grid item xs={12}>
        <CNPJTextField
          margin="dense"
          name="crs-cnpj"
          label="CNPJ"
          type="text"
          value={realEstate.cnpj || ''}
          onChange={(_, value) => setRealEstate({ cnpj: value })}
          fullWidth
          required
          disabled={action === 'edit'}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          margin="dense"
          name="crs-creci"
          label="CRECI"
          type="text"
          value={realEstate.creci || ''}
          onChange={(e) => setRealEstate({ creci: e.target.value })}
          fullWidth
          required
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          margin="dense"
          name="crs-reservation-limit"
          label="Limite de Reservas"
          type="number"
          value={realEstate.reservationLimit || 0}
          onChange={(e) =>
            setRealEstate({
              reservationLimit: parseInt(e.target.value, 10),
            })
          }
          fullWidth
          inputProps={{ min: 1, step: 1 }}
          required
        />
      </Grid>

      <Box width="100%" px={1.5} pt={3} mb={-1}>
        <Typography className={classes.sectionTitle}>Contato</Typography>
      </Box>

      <Grid item xs={6}>
        <PhoneTextField
          margin="dense"
          name="crs-phone"
          label="Telefone 1"
          type="text"
          value={realEstate.phone || ''}
          onChange={(_, value) => setRealEstate({ phone: value })}
          fullWidth
        />
      </Grid>

      <Grid item xs={6}>
        <PhoneTextField
          margin="dense"
          name="crs-phone2"
          label="Telefone 2"
          value={realEstate.phone2 || ''}
          onChange={(_, value) => setRealEstate({ phone2: value })}
          type="text"
          fullWidth
        />
      </Grid>

      <Grid item xs={12}>
        <EmailTextField
          type="email"
          margin="dense"
          name="crs-email"
          label="E-mail de contato"
          value={realEstate.email || ''}
          onChange={onContactEmailChange}
          fullWidth
        />
      </Grid>

      <Box
        display="flex"
        alignItems="center"
        width="100%"
        px={1.5}
        pt={1.5}
        mb={-1}
      >
        <Typography className={classes.sectionTitle}>
          Permitir acesso do sistema
        </Typography>

        <Box ml={3}>
          <FormControlLabel
            control={
              <Switch
                name="crs-active"
                checked={realEstate.active}
                color="primary"
                onChange={(e) =>
                  setRealEstate({
                    active: e.target.checked,
                  })
                }
              />
            }
          />
        </Box>
      </Box>
      <Box px={1.5} pt={1.0}>
        <Typography variant="h6" color="textSecondary">
          Será enviado um convite para o e-mail da imobiliária.
        </Typography>
      </Box>
      {isAccessEnabled ? (
        <Grid item xs={12}>
          <EmailTextField
            type="email"
            margin="dense"
            name="crs-email"
            label="E-mail de acesso"
            value={realEstate.username || ''}
            onChange={(e) => setRealEstate({ username: e.target.value })}
            fullWidth
            disabled={reuseContactEmail}
            required
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={reuseContactEmail}
                onChange={onReuseContactEmail}
                name="checkedB"
                color="primary"
              />
            }
            label="Usar o mesmo e-mail de contato"
          />
        </Grid>
      ) : null}
    </Grid>
  );
}
