// https://javascript.info/js-animation

const base = ({timing, draw, duration}) => {
  let start = performance.now();

  requestAnimationFrame(function animate(time) {
    // timeFraction goes from 0 to 1
    let timeFraction = (time - start) / duration;
    if (timeFraction > 1) timeFraction = 1;

    // calculate the current animation state
    let progress = timing(timeFraction)

    draw(progress); // draw it

    if (timeFraction < 1) {
      requestAnimationFrame(animate);
    }

  });
}

const animate = {
  base,
  linear(duration, values, onDraw) {
    base({
      duration,
      timing: timeFraction => Math.pow(timeFraction, 2),
      draw(progress) {
        const getProgressValue = (fromValue, toValue) =>
          ((toValue - fromValue) * progress) + fromValue

        const progressValues = Object
          .keys(values)
          .reduce((result, index) => {
            const value = values[index]
            result[index] = getProgressValue(value.from, value.to)
            return result
          },{})
        onDraw(progressValues)
      }
    })
  }
}

export default animate
