import React from 'react';
import { Typography } from '@material-ui/core';

import { PAYMENT_METHODS_LABELS } from '@constants/reservations/paymentMethods';

import formatPrice from 'utils/formatPrice';
import formatDate from 'utils/formatDate';

import SalesContractStatusLabel from 'components/StatusLabel/SalesContractStatusLabel';
import SalesContractsListActions from '../components/SalesContractsListActions';

export default (hasDocumentTemplate, onEdit) => [
  {
    title: '',
    field: 'numberId',
    render: (rowData) => (
      <Typography color="primary">{`#${rowData.numberId}`}</Typography>
    ),
  },
  {
    title: 'Unidade',
    field: 'property',
    render: (rowData) => rowData.property.unity,
  },

  {
    title: 'Situação',
    field: 'status',
    render: (rowData) => <SalesContractStatusLabel status={rowData.status} />,
  },

  {
    title: 'Cliente',
    field: 'customer',
    render: (rowData) => (rowData.customer ? rowData.customer.name : '-'),
  },

  {
    title: 'Valor da venda',
    field: 'price',
    render: (rowData) => formatPrice(rowData.price - rowData.entrance),
  },

  {
    title: 'Tipo de pagamento',
    field: 'paymentMethod',
    render: (rowData) => PAYMENT_METHODS_LABELS[rowData.paymentMethod],
  },

  {
    title: 'Data da 1° parcela',
    field: 'paidAt',
    render: (rowData) => formatDate(rowData.firstMonthlyInstallmentDate),
  },

  {
    title: '',
    field: '_actions',
    align: 'center',
    fit: true,
    render: (rowData) => (
      <SalesContractsListActions
        showPrintContractButton={hasDocumentTemplate}
        onEdit={onEdit}
        contract={rowData}
      />
    ),
  },
];
