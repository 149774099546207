const GET_PROJECT_BY_SLUG_QUERY = `
  query GetProjectBySlug ($slug: String!) {
    getProjectBySlug(slug: $slug) {
      _id
      mapFileId
      title
      slug
      discount{
        cash,
        monthly,
        annually
      }
      mapWidth
      mapHeight
      ownerId
      entranceFee
      annualQuota
      installments
      hasDocumentTemplate
      enabledFeatures
      mapUrl
      createdAt
      cnpj
      companyName
    }
  } 
`;

export default GET_PROJECT_BY_SLUG_QUERY;
