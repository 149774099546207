import React from 'react';

function InvoicesListZeroStateSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="142"
      height="153"
      fill="none"
      viewBox="0 0 142 153"
    >
      <path
        fill="#FF6C4E"
        stroke="#232935"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M29.997 51.624s-4.642 1.678-6.085 7.992C22.469 65.93 19.41 85 17.815 93.976c-1.594 8.979-10.153 38.138-10.153 38.138l4.502 2.947s11.021-15.565 14.102-22.855c3.08-7.289 6.085-12.345 6.508-17.956.423-5.612-.282-36.459-.282-38.808 0-2.35-1.182-4.101-2.495-3.818z"
      />
      <path
        fill="#FF6C4E"
        stroke="#232935"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M51.031 45.036s-19.287 5.747-21.034 6.587c-1.746.839-2.896 5.328-2.896 10.94 0 5.61 2.17 33.374 1.887 36.71-.282 3.335-2.028 7.572-.585 9.953 1.443 2.381 20.61 3.923 30.894 3.503 10.284-.419 16.098.567 17.552-2.244 1.453-2.811 2.321-37.434 3.764-45.437 1.443-8.003 2.473-12.198-.434-14.443-2.907-2.244-18.268-4.908-22.043-5.748l-7.105.179z"
      />
      <path
        fill="#fff"
        stroke="#232935"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M50.391 41.502s-3.2 1.05-4.263 3.367c-1.063 2.318-4.252 13.856-3.092 14.412 1.161.555 10.165-3.65 11.52-3.367 1.357.283 6.867 7.205 8.516 6.293 1.65-.913 5.522-10.761 4.068-15.817-1.454-5.056-15.306-6.199-16.749-4.888z"
      />
      <path
        fill="#B6895F"
        stroke="#232935"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M48.634 38.325s-.098 5.14-.098 7.761c0 2.623 2.332 11.979 4.34 11.611 2.006-.367 10.457-9.544 11.813-11.988 1.356-2.444 1.638-7.479 1.638-7.479s-6 4.112-10.544 2.8a40.436 40.436 0 01-7.15-2.705z"
      />
      <path
        fill="#232935"
        stroke="#232935"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M55.815 41.031a40.438 40.438 0 01-7.17-2.706s0 2.025-.066 4.196c.662 1.374 1.291 2.528 1.736 3.346a5.533 5.533 0 002.986 2.85 5.797 5.797 0 004.195.055 23.2 23.2 0 008.201-5.863c.339-1.535.56-3.091.662-4.657 0 0-6.01 4.09-10.544 2.78z"
      />
      <path
        fill="#B6895F"
        stroke="#232935"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M46.356 23.26s-.543-3.733-2.365-2.212c-1.822 1.52-1.497 5.339-.63 7.174.869 1.835 1.812 1.605 2.051 1.52.239-.083.944-6.481.944-6.481z"
      />
      <path
        fill="#3D4657"
        stroke="#232935"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M71.61 26.85c.238-.378 3.156-9.838 3.319-14.957.162-5.118-7.496-9.912-12.942-10.908-5.445-.996-13.494.304-15.186 3.283-1.693 2.979-1.899 14.684-1.899 16.635 0 1.95 1.584 2.287 1.974 1.825.391-.462 1.584-11.978 2.29-12.817.705-.84 13.331-.451 15.306.073 1.974.525 3.08 2.528 2.69 5.423-.39 2.895.076 2.748.868 4.426s.78 2.287.78 4.573c0 2.287 1.107 5.035 2.8 2.444z"
      />
      <path
        fill="#B6895F"
        stroke="#232935"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M48.102 9.533a123.76 123.76 0 00-2.842 17.013c-.401 6.786 3.471 13.803 5.044 16.708 1.573 2.906 4.426 3.892 7.182 2.895a24.137 24.137 0 009.546-7.342c2.918-3.66 4.426-12.44 4.426-12.44s-1.194.609-1.27-.377c-.076-.986.716-4.426-.076-6.64-.791-2.213-2.527-2.747-1.497-4.887a13.968 13.968 0 001.422-3.807c0-.389-3.95-2.675-11.13-3.283-7.181-.609-10.414.251-10.805 2.16z"
      />
      <path
        stroke="#232935"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M48.883 15.783s2.116-1.772 5.055.273M59.557 16.593s5.055-1.961 7.084 1.51"
      />
      <path
        fill="#B6895F"
        d="M70.666 27.459s1.573-3.43 3.861-3.588c2.29-.157 1.975 4.196-.151 7.028-2.127 2.832-5.207 3.964-5.294 1.898"
      />
      <path
        stroke="#232935"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M70.666 27.459s1.573-3.43 3.861-3.588c2.29-.157 1.975 4.196-.151 7.028-2.127 2.832-5.207 3.964-5.294 1.898M55.197 21.281s-2.604 7.248-2.05 8.244c.553.997 2.679 2.098 5.282.913M53.843 35.64a7.371 7.371 0 013.541-.27c1.186.2 2.3.686 3.238 1.413"
      />
      <path
        fill="#232935"
        d="M52.56 21.543c0 .829-.303 1.49-.672 1.49-.369 0-.672-.661-.672-1.49 0-.828.303-1.479.672-1.479.369 0 .673.62.673 1.48zM61.792 24.028c.372 0 .673-.667.673-1.49 0-.822-.301-1.49-.673-1.49-.371 0-.672.668-.672 1.49 0 .823.3 1.49.672 1.49z"
      />
      <path
        stroke="#232935"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M55.273 21.091c0 .867-.266 1.715-.765 2.436a4.505 4.505 0 01-2.035 1.615 4.679 4.679 0 01-2.62.25 4.585 4.585 0 01-2.321-1.2 4.342 4.342 0 01-1.241-2.245 4.25 4.25 0 01.258-2.534 4.42 4.42 0 011.67-1.967 4.646 4.646 0 012.52-.739c1.202 0 2.355.462 3.206 1.284a4.312 4.312 0 011.328 3.1v0zM67.043 22.036a4.508 4.508 0 01-.81 2.56 4.743 4.743 0 01-2.144 1.696 4.926 4.926 0 01-2.757.258 4.825 4.825 0 01-2.441-1.265 4.569 4.569 0 01-1.304-2.363 4.474 4.474 0 01.274-2.665 4.652 4.652 0 011.758-2.07 4.917 4.917 0 014.48-.425c.58.233 1.106.574 1.55 1.004.443.43.794.94 1.033 1.5.24.562.362 1.163.36 1.77v0zM55.273 20.285c.355-.175.76-.232 1.153-.162.392.07.75.264 1.016.55M66.956 22.498l5.684 2.297M8.822 128.895l6.097 2.38"
      />
      <path
        fill="#B6895F"
        stroke="#232935"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3.203 146.851s.857 4.846 1.443 5.108c.586.262.792-2.36.597-4.017l-.195-1.657s.922 4.331 1.844 4.72c.922.388.401-1.532.13-2.497l-.26-.954s2.31 2.36 2.505 1.783c.196-.577-1.388-3.44-1.453-3.629A22.92 22.92 0 017 142.351c-.065-.891-1.377-4.468-2.7-2.234-1.324 2.234-1.097 6.734-1.097 6.734z"
      />
      <path
        fill="#B6895F"
        stroke="#232935"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7.662 132.115s-4.34 6.734-5.51 8.979c-1.172 2.244-2.03 10.656-1.01 11.222 1.02.567 1.291-1.489 1.53-2.905a21.629 21.629 0 011.844-4.856s-.618 5.37.25 5.244c.867-.126 1.594-5.045 2.169-6.293.575-1.248 4.068-3.084 5.229-8.391l-4.502-3z"
      />
      <path fill="#fff" d="M4.516 144.553s0-.713.532-3.377l-.532 3.377z" />
      <path
        stroke="#232935"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4.516 144.553s0-.713.532-3.377"
      />
      <path
        fill="#FF6C4E"
        d="M79.604 50.365c3.288 2.4 5.802 5.657 7.246 9.388 2.756 6.597 12.183 35.346 12.475 35.902.293.556 0 6.168-4.642 7.993-4.643 1.825-6.813-2.098-9.568-6.587-2.755-4.49-9.134-24.407-9.134-24.407"
      />
      <path
        stroke="#232935"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M79.604 50.365c3.288 2.4 5.802 5.657 7.246 9.388 2.756 6.597 12.183 35.346 12.475 35.902.293.556 0 6.168-4.642 7.993-4.643 1.825-6.813-2.098-9.568-6.587-2.755-4.49-9.134-24.407-9.134-24.407"
      />
      <path
        fill="#FF6C4E"
        d="M91.493 87.527l22.52-10.699 3.916 4.353s-16.553 19.519-20.198 21.764c-3.645 2.244-8.125 1.689-9.86-2.098"
      />
      <path
        stroke="#232935"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M91.493 87.527l22.52-10.699 3.916 4.353s-16.553 19.519-20.198 21.764c-3.645 2.244-8.125 1.689-9.86-2.098M111.551 78.371l4.198 4.909"
      />
      <path
        fill="#B6895F"
        stroke="#232935"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M114.013 76.83s4.936-7.426 6.379-7.992c1.443-.567 5.51-1.395 7.832-3.084 2.321-1.689 3.623-1.259 2.321.566-1.301 1.826-2.755 2.098-4.057 2.665a10.41 10.41 0 00-1.887.975s3.623.147 5.944.283c1.41.081 2.825-.014 4.209-.283 0 0 5.945-2.517 4.491.566-1.453 3.084-1.302 5.045-7.105 6.724a43.289 43.289 0 01-11.596 1.552l-2.615 2.38-3.916-4.352z"
      />
      <path
        fill="#B6895F"
        stroke="#232935"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M136.783 75.148a6.422 6.422 0 002.43-1.52 6.153 6.153 0 001.486-2.403c1.02-3.094.434-3.23-.868-2.097a14.981 14.981 0 00-3.048 4.353c-.694 1.384 0 1.667 0 1.667z"
      />
      <path
        fill="#B6895F"
        stroke="#232935"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M135.481 75.285s1.736 1.123 3.048-2.244c1.313-3.367 1.15-6.031 0-5.465-1.15.567-1.453.703-2.907 3.65-1.454 2.948-2.755 4.196-1.888 4.479.305.062.621.057.924-.016.302-.072.584-.21.823-.404z"
      />
      <path
        fill="#B6895F"
        d="M118.797 76.558s2.17-3.514 4.643-3.514c3.017.217 6.019.591 8.993 1.123 0 0 4.784-6.723 5.803-5.748 1.02.975-1.594 5.611-2.755 6.87-1.161 1.259-1.312 1.406-2.321 1.689"
      />
      <path
        stroke="#232935"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M118.797 76.558s2.17-3.514 4.643-3.514c3.017.217 6.019.591 8.993 1.123 0 0 4.784-6.723 5.803-5.748 1.02.975-1.594 5.611-2.755 6.87-1.161 1.259-1.312 1.406-2.321 1.689"
      />
      <path
        fill="#3D4657"
        d="M31.03 114.209a.499.499 0 00-.607.36.502.502 0 00.36.609l.248-.969zm43.854 3.671a.501.501 0 00-.289-.958l.289.958zm-44.1-2.702c4.76 1.225 12.665 2.828 20.927 3.664 8.245.834 16.916.912 23.173-.962l-.29-.958c-6.058 1.814-14.563 1.757-22.785.925-8.206-.83-16.06-2.424-20.778-3.638l-.247.969z"
      />
    </svg>
  );
}

export default InvoicesListZeroStateSvg;
