const UPDATE_REAL_ESTATE_MUTATION = `
  mutation UpdateRealEstate (
    $id: ID!
    $name: String!
    $creci: String!
    $reservationLimit: Int!
    $active: Boolean!
    $username: String
    $phone: String
    $phone2: String
    $email: String
    $postalCode: String
    $address: String
    $addressNumber: String
    $addressComplement: String
    $district: String
    $city: String
    $state: String
  ) {
    updateRealEstate (
      id: $id
      data: {
        name: $name
        creci: $creci
        reservationLimit: $reservationLimit
        active: $active
        username: $username
        phone: $phone
        phone2: $phone2
        email: $email
        postalCode: $postalCode
        address: $address
        addressNumber: $addressNumber
        addressComplement: $addressComplement
        district: $district
        city: $city
        state: $state
      }
    ) {
      _id
    }
  }
`;

export default UPDATE_REAL_ESTATE_MUTATION;
