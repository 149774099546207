import React, { useMemo, useState } from 'react';
import { Box, TextField } from '@material-ui/core';
import BaseList from 'components/Lists/BaseList';
import BaseDialog from 'components/Dialogs/BaseDialog';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';
import ICON_TYPES from '@constants/iconsPaths';
import useThemeClasses from 'theme/useThemeClasses';
import useColumns from './hooks/useColumns';
import getTitleAndSubtitle from './utils/getTitleAndSubtitle';
import filterInvoicesBySearch from './utils/filterInvoicesBySearch';

export default function ReadReturnFileDialog({ open, returnFile, onClose }) {
  const textFieldClasses = useThemeClasses.textField();
  const columns = useColumns();
  const [title, subtitle] = getTitleAndSubtitle(returnFile, open);
  const [search, setSearch] = useState('');

  const data = useMemo(() => {
    if (!returnFile) return [];
    if (search) {
      return filterInvoicesBySearch(returnFile.result, search);
    }
    return returnFile.result;
  }, [search, returnFile]);

  return (
    <BaseDialog
      open={open}
      title={title}
      onClose={() => onClose(false)}
      maxWidth="md"
      subtitle={subtitle}
    >
      <Box position="relative" mb={3} mt={3}>
        <TextField
          className={textFieldClasses.textFieldOutlined1}
          variant="outlined"
          placeholder="Pesquise por nome do cliente, código do contrato"
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          fullWidth
        />

        <Box position="absolute" top={10} right={36}>
          <Icon src={ICON_TYPES.BASE.search} size={24} />
        </Box>
      </Box>
      <Box height={400} style={{ overflowY: 'auto' }}>
        <BaseList data={data} columns={columns} />
      </Box>
    </BaseDialog>
  );
}

ReadReturnFileDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
