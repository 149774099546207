import { Typography, useTheme } from '@material-ui/core';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

function ValueError({ message }) {
  const theme = useTheme();

  const RequiredError = useMemo(
    () => (
      <Typography variant="caption" style={{ color: theme.palette.error.main }}>
        Campo obrigatório
      </Typography>
    ),
    [theme]
  );

  const InvalidError = useMemo(
    () => (
      <Typography variant="caption" style={{ color: theme.palette.error.main }}>
        O valor do título deve ser maior que zero
      </Typography>
    ),
    [theme]
  );

  const errors = {
    REQUIRED: RequiredError,
    INVALID: InvalidError,
  };

  const Error = errors[message];
  return Error || null;
}

ValueError.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

ValueError.defaultProps = {
  message: '',
};

export default ValueError;
