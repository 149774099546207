const nextMonth = () => {
    const startDate = new Date()
    startDate.setDate(1)
    startDate.setMonth(startDate.getMonth() + 1)
    startDate.setHours(0, 0, 0, 0)

    const endDate = new Date()
    endDate.setDate(1)
    endDate.setMonth(endDate.getMonth() + 2)
    endDate.setDate(0)
    endDate.setHours(0, 0, 0, 0)

    return {
        id: 'next-month',
        label: 'Próximo mês',
        startDate,
        endDate
    }
}

export default nextMonth