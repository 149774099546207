import React from 'react';
import { Link } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import useStyles from './styles';

export default function HeaderLink({ value }) {
  const classes = useStyles();
  const history = useHistory();

  const handleClick = (e) => {
    e.preventDefault();
    history.push(value.href);
  };

  return (
    <Link className={classes.link} href={value.href} onClick={handleClick}>
      {value.label}
    </Link>
  );
}

HeaderLink.propTypes = {
  value: PropTypes.shape({
    href: PropTypes.string,
    label: PropTypes.string,
  }).isRequired,
};
