import React from 'react';
import { Grid } from '@material-ui/core';

import InformationBlock from 'components/InformationBlock';

export default function MeasurementTab({ property }) {
  return (
    <Grid container spacing={3}>
      <InformationBlock label="Confronto - Frente">
        {property.comparisonFront}
      </InformationBlock>

      <InformationBlock label="Confronto - Fundo">
        {property.comparisonBottom}
      </InformationBlock>

      <InformationBlock label="Confronto - Esquerda">
        {property.comparisonLeft}
      </InformationBlock>

      <InformationBlock label="Confronto - Direita">
        {property.comparisonRight}
      </InformationBlock>

      <InformationBlock fullWidth label="Confronto Adicional:">
        {property.comparisonExtra}
      </InformationBlock>

      <InformationBlock label="Medida - Frente">
        {property.measureFront}
      </InformationBlock>

      <InformationBlock label="Medida - Fundo">
        {property.measureBottom}
      </InformationBlock>

      <InformationBlock label="Medida - Esquerda">
        {property.measureLeft}
      </InformationBlock>

      <InformationBlock label="Medida - Direita">
        {property.measureRight}
      </InformationBlock>
    </Grid>
  );
}
