import api from 'services/api';
import AlertModule from 'modules/AlertModule';
import { useCallback } from 'react';
import handlerError from 'utils/handleError';
import { useHistory, useParams } from 'react-router-dom';
import emitSalesContractInvoicesQuery from 'queries/emitSalesContractInvoicesQuery';

export default function useEmitInvoices(contractNumberId) {
  const history = useHistory();
  const { projectSlug } = useParams();

  const confirmationTitle = (count) =>
    count === 1 ? 'Título emitido' : 'Títulos emitidos';

  const confirmationText = (count) =>
    count === 1
      ? 'Você deseja imprimir o título?'
      : 'Você deseja imprimir os títulos?';

  const downloadErrorMessage = (count) =>
    count === 1
      ? 'Não foi possível baixar o boleto.'
      : 'Não foi possível baixar os boletos.';

  const execute = useCallback(
    (invoicesIds, projectId) => {
      const variables = {
        projectId,
        invoices: invoicesIds,
      };

      const count = invoicesIds.length;

      return api
        .call(emitSalesContractInvoicesQuery, variables)
        .then(() => {
          AlertModule.successWithConfirmation(
            confirmationTitle(count),
            confirmationText(count),
            {
              confirmButtonText: 'Sim, imprimir',
            }
          ).then(async (result) => {
            if (result.isConfirmed) {
              await api
                .downloadTickets(projectId, contractNumberId, invoicesIds)
                .catch((err) => {
                  handlerError(
                    {
                      MISSING_BANK_ACCOUNT: () => {
                        AlertModule.error(
                          'Você precisa adicionar uma conta bancária para imprimir boletos.',
                          {
                            confirmButtonText: 'Ir para o financeiro',
                            cancelButtonText: 'Fechar',
                            reverseButtons: true,
                          }
                        ).then(({ isConfirmed }) => {
                          if (isConfirmed) {
                            history.push(`/${projectSlug}/financeiro`);
                          }
                        });
                      },
                      DEFAULT: AlertModule.onError(downloadErrorMessage(count)),
                    },
                    err.message
                  );
                });
            }
          });
        })
        .catch(AlertModule.onError());
    },
    [history, projectSlug]
  );

  return execute;
}
