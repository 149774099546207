/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';

import { ProjectContext } from 'providers/ProjectProvider';
import { useUsersListContext } from 'providers/ListProviders/UsersListProvider';
import BaseList from '../BaseList';
import useColumns from './hooks/useColumns';

export default function UsersList() {
  const { project } = useContext(ProjectContext);
  const { data, variables, setPage, pages } = useUsersListContext();
  const columns = useColumns(project);

  return (
    <BaseList
      columns={columns}
      data={data}
      currentPage={variables.page}
      pages={pages}
      changedAt={variables.changedAt}
      onPagination={setPage}
    />
  );
}
