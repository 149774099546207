import React, { useState } from 'react';

import RealEstateAgentsListProvider from 'providers/ListProviders/RealEstateAgentsListProvider';

import RealEstateAgentListFilters from 'components/ListFilters/RealEstateAgentsListFilters';
import CreateRealEstateAgentDialog from 'components/Dialogs/RealEstateAgentDialog/Create';
import EditRealEstateAgentDialog from 'components/Dialogs/RealEstateAgentDialog/Edit';
import RealEstateAgentsList from 'components/Lists/RealEstateAgentsList';
import RealEstateAgentsListZeroState from 'components/ZeroState/RealEstateAgentsListZeroState';

export default function RealEstateAgentsTab() {
  const [showCreateRealEstateAgentDialog, setShowCreateRealEstateAgentDialog] =
    useState(false);
  const [showEditRealEstateAgentDialog, setShowEditRealEstateAgentDialog] =
    useState(false);
  const [selectedRealEstateAgent, setSelectedRealEstateAgent] = useState(null);

  const openEditRealEstateAgentDialog = () =>
    setShowEditRealEstateAgentDialog(true);

  const closeEditRealEstateAgentDialog = () =>
    setShowEditRealEstateAgentDialog(false);

  const openCreateRealEstateAgentDialog = () =>
    setShowCreateRealEstateAgentDialog(true);

  const closeCreateRealEstateDialog = () =>
    setShowCreateRealEstateAgentDialog(false);

  const selectRealEstateAgent = (realEstateAgent) => {
    setSelectedRealEstateAgent(realEstateAgent);
    openEditRealEstateAgentDialog(true);
  };
  return (
    <RealEstateAgentsListProvider>
      <RealEstateAgentListFilters onCreate={openCreateRealEstateAgentDialog} />
      <RealEstateAgentsListZeroState>
        <RealEstateAgentsList onRowClick={selectRealEstateAgent} />
      </RealEstateAgentsListZeroState>

      <CreateRealEstateAgentDialog
        open={showCreateRealEstateAgentDialog}
        onClose={closeCreateRealEstateDialog}
      />

      <EditRealEstateAgentDialog
        open={showEditRealEstateAgentDialog}
        onClose={closeEditRealEstateAgentDialog}
        realEstateAgentId={selectedRealEstateAgent?._id}
      />
    </RealEstateAgentsListProvider>
  );
}
