import { Box } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import SelectedInvoicesPopup from 'components/Popups/SelectedInvoicesPopup';
import GenerateRemittancePopup from 'components/Popups/GenerateRemittancePopup';
import useStyles from './styles';

export default function SalesContractPopup({
  selectedInvoices,
  hideSelectedInvoicesPopup,
  onDeselectInvoices,
  projectId,
  hideRemittancePopup,
  emitInvoices,
  printInvoices,
  onCreate,
}) {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.selectedInvoicesContainer}>
        <SelectedInvoicesPopup
          selectedInvoices={selectedInvoices}
          onEmitInvoices={emitInvoices}
          onPrintInvoices={printInvoices}
          hide={hideSelectedInvoicesPopup}
          onDeselectInvoices={onDeselectInvoices}
          projectId={projectId}
        />
      </Box>
      <GenerateRemittancePopup hide={hideRemittancePopup} onCreate={onCreate} />
    </Box>
  );
}

SalesContractPopup.propTypes = {
  hideSelectedInvoicesPopup: PropTypes.bool.isRequired,
  hideRemittancePopup: PropTypes.bool.isRequired,
  onDeselectInvoices: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  selectedInvoices: PropTypes.arrayOf(PropTypes.string).isRequired,
  onCreate: PropTypes.func.isRequired,
  emitInvoices: PropTypes.func.isRequired,
  printInvoices: PropTypes.func.isRequired,
};

SalesContractPopup.defaultProps = {};
