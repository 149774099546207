export default function getRealEstateAgentQuery(id) {
  return `
    query {
      getRealEstateAgent(id: "${id}") {
        _id
        projectId
        name
        cpf
        rg
        creci
        gender
        reservationLimit
        realEstateId
        realEstate {
          _id
          name
          cnpj
          creci
        }
        postalCode
        address
        addressNumber
        addressComplement
        district
        city
        state
        username
        phone
        phone2
        email
        active
      }
    }
  `;
}
