import React, { useContext, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import PropTypes from 'prop-types';

import viacep from 'services/viacep';
import generateObjectChecksum from 'utils/generateObjectChecksum';
import { ProjectContext } from 'providers/ProjectProvider';
import { useRealEstateAgentsListContext } from 'providers/ListProviders/RealEstateAgentsListProvider';

import BaseDialog from 'components/Dialogs/BaseDialog';
import validateInputs from './helpers/validate-inputs';
import Form from './components/Form';
import useGetRealEstateAgent from './hooks/useGetRealEstateAgent';
import useEditRealEstateAgent from './hooks/useEditRealEstateAgent';

function EditRealEstateAgentDialog({ open, realEstateAgentId, onClose }) {
  const { project } = useContext(ProjectContext);
  const { updateList } = useRealEstateAgentsListContext();
  const [step, setStep] = useState(0);

  const getRealEstateAgent = useGetRealEstateAgent(realEstateAgentId);

  const [realEstateAgent, setRealEstateAgent] = useState(null);
  const [realEstateAgentChecksum, setRealEstateAgentChecksum] = useState(null);

  const editRealEstateAgent = useEditRealEstateAgent(realEstateAgent);

  function handleClose() {
    const checksum = generateObjectChecksum(realEstateAgent);

    if (checksum !== realEstateAgentChecksum) {
      Swal.fire({
        icon: 'warning',
        title: 'Confirmação',
        text: 'Deseja realmente fechar sem salvar as alterações da imobiliária?',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then((result) => {
        if (result.isConfirmed) {
          onClose();
        }
      });
    } else {
      onClose();
    }
  }

  const handleSubmit = () => {
    const validate = validateInputs(realEstateAgent);

    if (!validate.success) {
      setStep(validate.step);
      Swal.fire('', validate.message, 'error');
      return;
    }

    Swal.fire({
      text: 'Aguarde...',
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
    });

    editRealEstateAgent().then(() => {
      updateList();
      onClose();
    });
  };

  const searchAddress = () => {
    viacep.buscar(realEstateAgent.postalCode).then((data) => {
      setRealEstateAgent({
        ...realEstateAgent,
        address: data.logradouro,
        addressComplement: data.complemento,
        district: data.bairro,
        city: data.localidade,
        state: data.uf,
      });
    });
  };

  useEffect(() => {
    if (open) {
      getRealEstateAgent()
        .then((data) => {
          setRealEstateAgent(data);
          setRealEstateAgentChecksum(generateObjectChecksum(data));
        })
        .catch(() => {
          onClose();
        });
    } else {
      setStep(0);
      setRealEstateAgent(null);
    }
  }, [open]);

  useEffect(() => {
    if (
      realEstateAgent &&
      realEstateAgent.reuseEmail &&
      realEstateAgent.active &&
      realEstateAgent.email !== realEstateAgent.username
    ) {
      setRealEstateAgent({
        ...realEstateAgent,
        username: realEstateAgent.email,
      });
    }
  }, [realEstateAgent]);

  return (
    <BaseDialog
      open={open}
      onClose={() => handleClose()}
      title="Corretor"
      maxWidth="sm"
      loading={!realEstateAgent}
    >
      <Form
        projectId={project._id}
        realEstateAgent={realEstateAgent}
        setRealEstateAgent={setRealEstateAgent}
        submit={handleSubmit}
        searchAddress={searchAddress}
        step={step}
        setStep={setStep}
        action="edit"
      />
    </BaseDialog>
  );
}

EditRealEstateAgentDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  realEstateAgentId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

EditRealEstateAgentDialog.defaultProps = {
  realEstateAgentId: '',
};

export default EditRealEstateAgentDialog;
