const SALES_CONTRACT_INVOICE_STATUS = {
  pending: 'pending',
  paid: 'paid',
  expired: 'expired',
};

export const SALES_CONTRACT_INVOICE_STATUS_LABELS = {
  pending: 'Em andamento',
  paid: 'Pago',
  expired: 'Expirado',
};

export default SALES_CONTRACT_INVOICE_STATUS;
