import React from 'react';

function ReturnListZeroStateImage() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="180"
      height="160"
      fill="none"
      viewBox="0 0 180 160"
    >
      <path
        fill="#F0F2F4"
        d="M166.53 44.034v-.056c-2.321-8.009-9.324-13.21-16.238-17.178-13.951-8.008-26.889-2.915-47.008 2.157-20.118 5.071-32.345-.138-50.605-5.758-27.281-8.388-47.831 17.488-44.1 43.183 1.763 12.106 7.223 23.258 9.435 35.265 3.24 17.6-9.518 25.729.6 40.573 10.117 14.844 37.404 18.234 55.607 15.366 11.028-1.725 20.576-7.896 31-11.493 9.777-3.373 20.403-2.639 30.188.298 9.079 2.721 19.839 5.399 27.532-1.95 8.751-8.353 11.675-18.238 7.384-29.58-3.718-9.82-10.696-18.17-13.865-28.21-2.674-8.448 2.415-13.157 6.348-19.993 3.804-6.611 5.869-15.138 3.722-22.624z"
      />
      <path
        fill="#F0F2F4"
        d="M166.53 44.034v-.056c-2.321-8.009-9.324-13.21-16.238-17.178-13.951-8.008-26.889-2.915-47.008 2.157-20.118 5.071-32.345-.138-50.605-5.758-27.281-8.388-47.831 17.488-44.1 43.183 1.763 12.106 7.223 23.258 9.435 35.265 3.24 17.6-9.518 25.729.6 40.573 10.117 14.844 37.404 18.234 55.607 15.366 11.028-1.725 20.576-7.896 31-11.493 9.777-3.373 20.403-2.639 30.188.298 9.079 2.721 19.839 5.399 27.532-1.95 8.751-8.353 11.675-18.238 7.384-29.58-3.718-9.82-10.696-18.17-13.865-28.21-2.674-8.448 2.415-13.157 6.348-19.993 3.804-6.611 5.869-15.138 3.722-22.624z"
        opacity="0.7"
      />
      <path
        fill="#000"
        d="M97.435 97.337H85.36V81.16h8.478l3.597 3.295v12.882z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M98.142 96.79H86.067V80.616h8.479l3.596 3.295v12.877z"
      />
      <path
        fill="#000"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M94.546 80.617l-.885 3.868 4.481-.573-3.596-3.295z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M94.546 80.617v3.295h3.596l-3.596-3.295z"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M88.374 86.288h6.883M88.374 87.974h6.883M88.374 89.66h6.883M88.374 91.35h6.883M88.374 93.037h6.883"
      />
      <path
        fill="#000"
        d="M155.514 97.337h-12.076V81.16h8.479l3.597 3.295v12.882z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M156.217 96.79h-12.076V80.616h8.479l3.597 3.295v12.877z"
      />
      <path
        fill="#000"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M152.62 80.617l-.88 3.868 4.477-.573-3.597-3.295z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M152.62 80.617v3.295h3.597l-3.597-3.295z"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M146.453 86.288h6.883M146.453 87.974h6.883M146.453 89.66h6.883M146.453 91.35h6.883M146.453 93.037h6.883"
      />
      <path
        fill="#000"
        d="M38.347 97.337H26.272V81.16h8.478l3.597 3.295v12.882z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M39.054 96.79H26.98V80.616h8.479l3.596 3.295v12.877z"
      />
      <path
        fill="#000"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M35.458 80.617l-.884 3.868 4.48-.573-3.596-3.295z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M35.458 80.617v3.295h3.596l-3.596-3.295z"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M29.29 86.288h6.884M29.29 87.974h6.884M29.29 89.66h6.884M29.29 91.35h6.884M29.29 93.037h6.884"
      />
      <path
        fill="#000"
        d="M97.435 137.756H85.36v-16.177h8.478l3.597 3.295v12.882z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M98.142 137.207H86.067v-16.172h8.479l3.596 3.295v12.877z"
      />
      <path
        fill="#000"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M94.546 121.035l-.885 3.868 4.481-.573-3.596-3.295z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M94.546 121.035v3.295h3.596l-3.596-3.295z"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M88.374 126.707h6.883M88.374 128.392h6.883M88.374 130.083h6.883M88.374 131.769h6.883M88.374 133.456h6.883"
      />
      <path
        fill="#000"
        d="M155.514 137.756h-12.076v-16.177h8.479l3.597 3.295v12.882z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M156.217 137.207h-12.076v-16.172h8.479l3.597 3.295v12.877z"
      />
      <path
        fill="#000"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M152.62 121.035l-.88 3.868 4.477-.573-3.597-3.295z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M152.62 121.035v3.295h3.597l-3.597-3.295z"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M146.453 126.707h6.883M146.453 128.392h6.883M146.453 130.083h6.883M146.453 131.769h6.883M146.453 133.456h6.883"
      />
      <path
        fill="#000"
        d="M38.347 137.756H26.272v-16.177h8.478l3.597 3.295v12.882z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M39.054 137.207H26.98v-16.172h8.479l3.596 3.295v12.877z"
      />
      <path
        fill="#000"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M35.458 121.035l-.884 3.868 4.48-.573-3.596-3.295z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M35.458 121.035v3.295h3.596l-3.596-3.295z"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M29.29 126.707h6.884M29.29 128.392h6.884M29.29 130.083h6.884M29.29 131.769h6.884M29.29 133.456h6.884"
      />
      <path
        fill="#000"
        d="M67.384 116.765H55.31v-16.172h8.483l3.592 3.295v12.877z"
      />
      <path
        fill="#FF6C4E"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M68.091 116.222H56.016v-16.177h8.479l3.596 3.295v12.882z"
      />
      <path
        fill="#000"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M64.495 100.045l-.88 3.869 4.477-.574-3.597-3.295z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M64.495 100.045v3.295h3.597l-3.597-3.295z"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M58.328 105.72h6.883M58.328 107.406h6.883M58.328 109.094h6.883M58.328 110.78h6.883M58.328 112.466h6.883"
      />
      <path
        fill="#000"
        d="M97.435 56.918H85.36V40.741h8.478l3.597 3.295v12.882z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M98.142 56.371H86.067V40.2h8.479l3.596 3.295V56.37z"
      />
      <path
        fill="#000"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M94.546 40.199l-.885 3.868 4.481-.573-3.596-3.295z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M94.546 40.199v3.295h3.596l-3.596-3.295z"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M88.374 45.869h6.883M88.374 47.556h6.883M88.374 49.242h6.883M88.374 50.928h6.883M88.374 52.62h6.883"
      />
      <path
        fill="#000"
        d="M126.472 35.928h-12.075V19.756h8.483l3.592 3.29V35.93z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M127.179 35.385h-12.075V19.21h8.479l3.596 3.294v12.882z"
      />
      <path
        fill="#000"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M123.583 19.209l-.88 3.868 4.477-.574-3.597-3.294z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M123.583 19.209v3.294h3.597l-3.597-3.294z"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M117.416 24.88h6.883M117.416 26.57h6.883M117.416 28.257h6.883M117.416 29.943h6.883M117.416 31.628h6.883"
      />
      <path
        fill="#000"
        d="M155.514 56.918h-12.076V40.741h8.479l3.597 3.295v12.882z"
      />
      <path
        fill="#FF6C4E"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M156.217 56.371h-12.076V40.2h8.479l3.597 3.295V56.37z"
      />
      <path
        fill="#000"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M152.62 40.199l-.88 3.868 4.477-.573-3.597-3.295z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M152.62 40.199v3.295h3.597l-3.597-3.295z"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M146.453 45.869h6.883M146.453 47.556h6.883M146.453 49.242h6.883M146.453 50.928h6.883M146.453 52.62h6.883"
      />
      <path
        fill="#000"
        d="M38.347 56.918H26.272V40.741h8.478l3.597 3.295v12.882z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M39.054 56.371H26.98V40.2h8.479l3.596 3.295V56.37z"
      />
      <path
        fill="#000"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M35.458 40.199l-.884 3.868 4.48-.573-3.596-3.295z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M35.458 40.199v3.295h3.596l-3.596-3.295z"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M29.29 45.869h6.884M29.29 47.556h6.884M29.29 49.242h6.884M29.29 50.928h6.884M29.29 52.62h6.884"
      />
      <path
        fill="#FF6C4E"
        d="M120.68 137.208c11.848 0 21.452-1.846 21.452-4.123s-9.604-4.123-21.452-4.123c-11.847 0-21.45 1.846-21.45 4.123s9.603 4.123 21.45 4.123z"
      />
      <path
        fill="#fff"
        d="M120.68 137.208c11.848 0 21.452-1.846 21.452-4.123s-9.604-4.123-21.452-4.123c-11.847 0-21.45 1.846-21.45 4.123s9.603 4.123 21.45 4.123z"
        opacity="0.5"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M104.409 76.554a.368.368 0 01-.583-.112l-.862-1.816a.372.372 0 01.668-.315l.863 1.816a.364.364 0 01-.086.427z"
      />
      <path
        fill="#FF6C4E"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M105.185 81.036a5.032 5.032 0 012.519 1.096c1.207.987 3.838 2.302 7.241 3.07 3.402.768 4.273.863 4.273.863l-.862-4.714s-4.826.108-6.9-.22c-2.075-.328-4.499-2.19-5.607-3.178-1.108-.988-.664 3.083-.664 3.083z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M106.699 81.242a.659.659 0 01-.138.095.557.557 0 01-.738-.263l-2.156-4.55a.564.564 0 01-.021-.423.543.543 0 01.284-.314.554.554 0 01.738.263l2.156 4.55a.564.564 0 01-.125.642z"
      />
      <path
        fill="#000"
        d="M105.12 73.527a5.753 5.753 0 01-6.315 1.02 5.757 5.757 0 116.609-9.158 5.767 5.767 0 01-.294 8.138zm-7.09-7.624a4.654 4.654 0 106.337 6.818 4.654 4.654 0 00-6.337-6.819z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M105.94 76.654s.599-.32.862.172.915 1.514.79 1.885c-.125.37-.824.431-1.165.047a7.168 7.168 0 01-.811-1.587l.324-.517z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M103.637 77.387l2.303-.733a.66.66 0 01.302.483c.047.345 1.017 2.264.91 2.747-.108.483-2.213 1.367-2.424 1.104-.211-.263-1.453-3.26-1.453-3.26s-.035-.23.362-.341z"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M106.289 77.482l-2.078.647M106.591 78.379l-1.893.733M106.94 79.21l-1.794 1.014"
      />
      <path
        fill="#FF6C4E"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M115.492 68.425s-1.867 3.506-2.083 7.017c-.215 3.51 2.959 3.946 3.399 4.274.44.328 2.083 14.145 2.083 14.145s-11.515 14.806-11.515 16.449c0 1.643-1.635 17.777-1.635 17.777l2.411.112s3.627-10.213 4.49-12.619c.862-2.407 1.203-6.141 1.203-6.141l10.639-9.98s1.204 9.76 2.303 11.843c1.1 2.083 8.66 17.108 8.66 17.108.648.166 1.309.275 1.975.328a3.436 3.436 0 001.316-.328l-6.469-18.544s.327-16.889-1.096-19.632c-1.423-2.743-5.046-6.689-5.046-6.689s-3.07-12.718-3.51-14.693c-.44-1.975-2.959-3.618-4.166-3.618-1.208 0-2.959 3.191-2.959 3.191z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M113.504 66.23l1.535 2.41s-.862 3.73-.107 2.855c.754-.875 1.97-4.058 2.63-4.718.407-.435.703-.961.863-1.535l-3.265-1.846-1.656 2.834z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M106.695 58.187s-.195 2.014.461 3.018c.655 1.005.862 1.316.66 1.971-.203.656-1.208.66-.988 1.208.22.547 1.428.767 1.428.767s1.315 3.071 2.518 3.071c1.204 0 4.287-3.019 4.723-4.118.435-1.1-4.063-3.998-4.831-4.654-.767-.655-3.096-2.471-3.971-1.264z"
      />
      <path
        fill="#000"
        d="M109.377 62.007c.121.366.082.711-.09.768-.173.056-.432-.195-.531-.561-.099-.367-.086-.712.086-.772.173-.06.41.194.535.565z"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M109.041 65.431s1.061 0 1.414-1.53"
      />
      <path
        fill="#000"
        d="M107.238 53.072h-2.268c-1.458 0-1.618 2.91 1.457 6.15 3.075 3.239 9.225 7.12 12.787 7.12 3.563 0 6.637.97 7.931 4.532 1.294 3.563 3.399 6.797 9.712 5.504 6.314-1.294 7.931-9.872 3.399-17.156-4.533-7.284-10.523-5.99-13.598-4.533-3.075 1.458-8.255 1.94-9.225 0-.97-1.94-3.265-3.247-5.693-3.247-2.428 0-4.502 1.63-4.502 1.63z"
      />
      <path
        fill="#535B6A"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M138.738 128.405l-6.469-18.544s.327-16.889-1.096-19.632c-1.225-2.35-4.054-5.58-4.834-6.469l-8.414 2.493c.5 3.71.966 7.612.966 7.612s-11.515 14.805-11.515 16.449c0 1.643-1.635 17.772-1.635 17.772l2.411.112s3.627-10.212 4.49-12.619c.862-2.406 1.203-6.141 1.203-6.141l10.639-9.98s1.204 9.76 2.303 11.843c1.1 2.083 8.66 17.109 8.66 17.109.648.165 1.309.275 1.975.327a3.414 3.414 0 001.316-.332z"
      />
      <path
        fill="#FF6C4E"
        d="M120.098 68.533a2.048 2.048 0 00-2.415 1.315c-.655 1.756 2.083 3.291 4.166 5.607 2.083 2.316 8.117 7.017 8.117 7.017l3.51 11.187 1.643-.332s-2.208-12.731-2.208-13.06c0-.327-9.207-9.431-9.54-9.759-.332-.328-1.643-1.755-1.643-1.755"
      />
      <path
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M120.098 68.533a2.048 2.048 0 00-2.415 1.315c-.655 1.756 2.083 3.291 4.166 5.607 2.083 2.316 8.117 7.017 8.117 7.017l3.51 11.187 1.643-.332s-2.208-12.731-2.208-13.06c0-.327-9.207-9.431-9.54-9.759-.332-.328-1.643-1.755-1.643-1.755"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M133.476 93.645l-.66 2.851 1.096 2.083s.862.328 1.1-.112l.215-.431s1.1-.112 1.208-1.1c.108-.988-1.316-3.618-1.316-3.618l-1.643.327z"
      />
      <path
        fill="#CC563E"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M105.741 128.087s-5.373 3.95-5.606 4.606c-.233.655 7.237-1.096 7.896-1.208.66-.112.108-3.286.108-3.286l-2.398-.112z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M100.687 132.058a2.39 2.39 0 00-.548.621c-.22.66 7.237-1.095 7.897-1.207.224-.035.306-.432.319-.945l-7.668 1.531z"
      />
      <path
        fill="#CC563E"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M135.447 128.405s-4.494.768-3.946 1.423a4.66 4.66 0 003.071 1.294c1.095 0 1.535.328 2.522.863.988.535 1.644.547 1.971-.332.328-.88-.327-3.287-.327-3.287s-2.631.583-3.291.039z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M137.095 132.024c.983.548 1.643.548 1.97-.332.037-.1.06-.205.069-.311-1.634.824-3.139-1.134-4.511-1.134-.862 0-2.065-.479-2.863-.862-.259.146-.38.293-.259.431a4.67 4.67 0 003.071 1.294c1.095.034 1.535.362 2.523.914z"
      />
      <path
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="0.401"
        d="M110.308 58.41c.863 1.726 2.109 3.783 3.55 4.624M109.079 55.525s.195.565.548 1.398"
      />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0.401"
        d="M140.376 61.965c.385.308.724.668 1.01 1.07M106.272 54.995s1.518 5.175 6.598 7.582c5.08 2.406 7.129 1.897 13.572-.906 4.934-2.156 9.781-2.027 12.77-.461M131.371 72.13c-.081-1.67 1.626-1.427 1.825-1.363"
      />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0.401"
        d="M122.807 63.185s2.958-.53 8.72-.457c5.761.074 9.328 5.995 8.194 9.557-1.044 3.27-6.426 3.286-7.892 1.294"
      />
      <path
        fill="#FF6C4E"
        d="M61.316 86.862c11.848 0 21.452-1.846 21.452-4.123s-9.604-4.123-21.452-4.123c-11.847 0-21.45 1.846-21.45 4.123s9.603 4.123 21.45 4.123z"
      />
      <path
        fill="#fff"
        d="M61.316 86.862c11.848 0 21.452-1.846 21.452-4.123s-9.604-4.123-21.452-4.123c-11.847 0-21.45 1.846-21.45 4.123s9.603 4.123 21.45 4.123z"
        opacity="0.5"
      />
      <path
        fill="#000"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M71.96 16.721v-3.528c0-1.371 1.954-3.76 4.908-3.881 2.954-.12 7.763 3.743 6.995 5.813-.768 2.07-1.872.863-1.872.863s.79.888-.293 1.38c-1.082.492-1.833-1.531-1.833-1.531s-1.479-1.38-2.86-1.38c-1.38 0-.982.1-1.97 1.475-.988 1.376-1.872.987-1.872 1.48 0 .49.69 1.293-.392 1.293-1.083 0-.81-1.984-.81-1.984z"
      />
      <path
        fill="#CC563E"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M29.704 64.886s-3.221-.919-3.683-.643c-.461.276-1.013 7.37-.828 8.013.186.642 3.02-4.791 3.02-4.791l1.38-.182.111-2.397z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M26.505 64.195a.966.966 0 00-.483.047c-.462.276-1.014 7.37-.829 8.013.044.15.233-.035.5-.405l.811-7.655z"
      />
      <path
        fill="#535B6A"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M56.331 41.488a23.965 23.965 0 00-2.212 3.882c-.828 1.932-1.751 3.959-1.751 3.959l-5.434 11.976s-10.135 2.398-11.424 2.764c-1.29.367-5.805.828-5.805.828l-.09 2.398s12.618.367 15.568.367c2.95 0 3.502.552 4.606-.276 1.104-.828 9.302-14.771 9.302-14.771l3.226-.181s9.121 10.501 9.859 11.605c.737 1.104 3.221 8.474 4.51 10.872a23.423 23.423 0 002.765 4.05l2.027-2.303S77.165 62.75 76.596 59.8c-.569-2.95-7.922-12.434-7.922-12.434l.737-4.882s-11.885.384-13.08-.996z"
      />
      <path
        fill="#FF6C4E"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M72.348 23s-6.68-.384-7.642-.319c-.962.065-1.863.19-1.988.768-.125.578-6.806 17.846-6.806 19.001 0 1.156 3.722 2.7 7.19 2.7 2.23-.015 4.395-.76 6.163-2.122.319-.254 8.987-16.496 9.181-16.884.194-.388.125-.961-.513-1.54-.638-.577-5.585-1.604-5.585-1.604z"
      />
      <path
        fill="#FF6C4E"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M72.732 21.996s-1.725.383-2.182.961c-.457.578-1.798 5.78-1.798 5.78l3.45-.708 1.988 3.019s1.35-4.364 1.414-6.228c.065-1.863-1.91-3.04-2.872-2.824zM62.778 22.971a24.975 24.975 0 00-5.434 2.394c-2.307 1.475-9.03 5.528-9.03 5.528l4.312 1.104 7.923-3.13s1.677-3.502 2.23-5.896z"
      />
      <path
        fill="#FF6C4E"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M60.937 31.816s-4.24-.462-6.176-1.014-3.774-1.66-5.71-.461c-1.936 1.199-1.75 2.587-.552 3.502 1.199.914 8.108.09 9.488 0a4.96 4.96 0 002.303-.738l.647-1.29z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M59.923 31.915s4.236-1.566 4.511-1.566c.276 0 .833 0 .923.643.091.642.462 2.212.091 2.303-.37.09-2.303.832-2.764.832a2.84 2.84 0 01-.919-.185 2.179 2.179 0 01-2.118-.367c-1.013-.84-.28-1.393.276-1.66z"
      />
      <path
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M61.765 33.933l-.371-1.104"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M62.964 31.54l.642 2.393M64.068 30.802l.642 2.484M62.04 31.996l.643 2.122"
      />
      <path
        fill="#FF6C4E"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M78.14 24.881a8.48 8.48 0 012.036 2.786c.987 1.975 3.881 8.306 3.881 8.306s.927 2.157-.987 2.847c-1.915.69-2.157-1.16-2.963-2.208-.807-1.048-3.774-6.448-3.774-6.448l1.807-5.283z"
      />
      <path
        fill="#FF6C4E"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M80.245 35.507a3.019 3.019 0 012.38-.694c1.51.172 2.036 2.497 1.454 3.718-.583 1.22-2.157 1.681-2.674 1.449-.518-.233-2.204-1.165-1.16-4.473z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M80.965 35.206a.315.315 0 01-.108 0 .366.366 0 01-.241-.461l.603-1.92a.362.362 0 01.462-.237.368.368 0 01.241.462l-.603 1.919a.375.375 0 01-.354.237z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M79.434 40.2a.557.557 0 01-.526-.72l1.514-4.81a.552.552 0 011.052.332l-1.514 4.809a.547.547 0 01-.526.388z"
      />
      <path
        fill="#000"
        d="M83.553 33.472a5.758 5.758 0 115.757-5.758 5.766 5.766 0 01-5.757 5.758zm0-10.411a4.654 4.654 0 10-.009 9.308 4.654 4.654 0 00.009-9.308z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M79.55 35.046s-.642-.233-.815.29c-.172.521-.698 1.625-.522 1.975.177.349.863.288 1.16-.117a7.257 7.257 0 00.583-1.686l-.406-.462z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M81.93 35.45l-2.38-.405a.67.67 0 00-.233.522c0 .35-.698 2.381-.526 2.847.173.465 2.385 1.043 2.558.754.172-.288.987-3.428.987-3.428s0-.233-.405-.29z"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M79.318 35.917l2.147.345M79.14 36.845l1.976.465M78.908 37.715l1.919.755"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M72.534 20.624s-.833 3.338-.833 4.818c0 1.479 0 2.695.513 2.587.514-.108 2.247-3.45 2.566-4.744.32-1.294-.828-3.817-2.246-2.66z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M72.352 16.92s-.69-1.182-1.082-.397c-.392.785-.79 2.66-.198 3.252.59.59.789-.294.789-.294s.099 2.661 1.082 3.644c.984.984 2.07 1.48 3.649.79 1.578-.69 3.545-3.55 4.136-5.516a17.93 17.93 0 00.69-2.959s-.393-1.574-2.661-2.07c-2.269-.496-4.628.397-4.628 1.38 0 .984.492 1.087-.099 1.38-.304.169-.64.27-.988.298 0 0-.392 2.066-.69.492z"
      />
      <path
        fill="#000"
        d="M78.619 18.977c-.1.345-.298.587-.432.543-.133-.043-.185-.353-.086-.694.1-.34.298-.586.431-.543.134.043.186.35.087.694z"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M75.043 17.67l1.41.858M78.23 17.304s.98-.919 1.295.185M76.88 17.183s-.185-1.35-1.35-.983M77.864 18.283a1.725 1.725 0 00-.677 1.229c0 .733.612 1.41.06 1.53-.552.121-1.41-.487-1.41-.487M76.329 22.087s-1.596.245-1.777-.919"
      />
      <path
        fill="#CC563E"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M79.434 78.98s.923 2.587 1.565 2.587c.643 0 5.805-5.067 5.99-5.607.186-.539-4.79 1.294-4.79 1.294l-.738-.556-2.027 2.281z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M85.972 75.994l-5.39 5.261c.15.182.297.307.43.307.647 0 5.805-5.068 5.99-5.607.061-.19-.387-.125-1.03.039z"
      />
    </svg>
  );
}

export default ReturnListZeroStateImage;
