/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useContext, useState } from 'react';

import { ProjectContext } from 'providers/ProjectProvider';
import useGetInvoices from './hooks/useGetInvoices';
import useWebHooks from './hooks/useWebHooks';

const SalesContractInvoicesListContext = createContext();

export default function SalesContractInvoicesListProvider({
  contractNumberId,
  children,
}) {
  const { project } = useContext(ProjectContext);
  const [search, setSearch] = useState('');
  const [variables, setVariables] = useState({
    projectId: project._id,
    page: 0,
    search,
    sortOptions: {
      field: 'dueDate',
      direction: 'asc',
    },
    filterOptions: {
      status: 'all',
      type: null,
      dueDateStart: null,
      dueDateEnd: null,
      paidAtStart: null,
      paidAtEnd: null,
    },
    changedAt: Date.now(),
  });
  const { invoices, pages, isLoading } = useGetInvoices(
    variables,
    contractNumberId,
    search
  );

  const setPage = (value) => setVariables({ ...variables, page: value });

  const setFilter = (value) => {
    const newFilterOptions = { ...variables.filterOptions, ...value };
    setVariables({ ...variables, page: 0, filterOptions: newFilterOptions });
  };

  const setDueDateFilter = (value) => {
    setFilter({
      dueDateStart: value.startDate,
      dueDateEnd: value.endDate,
    });
  };

  const setPaymentDateFilter = (value) =>
    setFilter({
      paidAtStart: value.startDate,
      paidAtEnd: value.endDate,
    });

  const setSort = (option) =>
    setVariables({
      ...variables,
      page: 0,
      sortOptions: {
        field: option.value,
        direction: option.reverse ? 'desc' : 'asc',
      },
    });

  const updateList = () =>
    setVariables({ ...variables, changedAt: Date.now() });

  useWebHooks(updateList);

  return (
    <SalesContractInvoicesListContext.Provider
      value={{
        invoices,
        pages,
        isLoading,
        search,
        variables,
        updateList,
        setPage,
        setFilter,
        setPaymentDateFilter,
        setDueDateFilter,
        setSort,
        setSearch,
      }}
    >
      {children}
    </SalesContractInvoicesListContext.Provider>
  );
}
export function useSalesContractInvoicesListContext() {
  return useContext(SalesContractInvoicesListContext);
}
