const GET_PROPERTIES_SUMMARY_QUERY = `
  query GetPropertiesSummary (
    $projectId: ID!
    $search: String
    $filterOptions: PropertyFilter
  ) {
    getPropertiesSummary(
      projectId: $projectId
      search: $search
      filterOptions: $filterOptions
    ) {
      projectId
      totalProperties
      totalArea
      totalPrice
      maxPrice
    }
  }
`;

export default GET_PROPERTIES_SUMMARY_QUERY;
