import React from 'react';

function BackIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 32 32"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M18.667 10.667L13.333 16l5.334 5.334"
      />
    </svg>
  );
}

export default BackIcon;
