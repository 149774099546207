import getDateLabel from './get-date-label';

export default function generateMonthlyDueDates() {
  const today = new Date();
  const currentMonth = today.getMonth();
  const currentYear = today.getFullYear();

  const dates = [];

  for (let i = 0; i < 2; i += 1) {
    const month = ((currentMonth + i) % 12) + 1;
    const monthStr = String(month).padStart(2, '0');

    const year = currentYear + Math.floor((currentMonth + i) / 12);

    const value = `${year}-${monthStr}`;
    const label = getDateLabel(value);

    dates.push({
      value,
      label,
    });
  }

  return dates;
}
