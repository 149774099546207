import React, { useMemo, useState } from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';

import MakeReservationBase from 'components/Dialogs/CreateDialogs/MakeReservationDialog/base';

import useStyles from './styles';

function MakeReservation({ propertyId, open, onPropertyReserved, onClose }) {
  const classes = useStyles();
  const theme = useTheme();

  const [step, setStep] = useState(0);

  const title = useMemo(() => {
    const stepTitles = [
      '',
      'Qual será a forma de pagamento?',
      'Escolha a data de vencimento das parcelas',
      'Qual o CPF do cliente?',
      'Dados do cliente',
      'Verifique se as informações estão corretas',
    ];

    return stepTitles[step] || '';
  }, [step]);

  if (!open) return null;

  return (
    <Box className={classes.dialogContainer}>
      <Box p={3}>
        <Box mb={5}>
          <Typography
            className="fw700"
            variant="h5"
            style={{ color: theme.palette.secondary.dark }}
          >
            {title}
          </Typography>
        </Box>

        <MakeReservationBase
          open
          propertyId={propertyId}
          onPropertyReserved={onPropertyReserved}
          onStepChange={(value) => setStep(value)}
          onClose={onClose}
        />
      </Box>
    </Box>
  );
}

MakeReservation.propTypes = {
  propertyId: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  onPropertyReserved: PropTypes.func.isRequired,
};

MakeReservation.defaultProps = {
  propertyId: null,
  onClose: () => {},
};

export default MakeReservation;
