import { FormControl, MenuItem, Select } from '@material-ui/core';
import React, { useStyles } from './styles';

export default function MonthSelect({ onChange, ...props }) {
  const classes = useStyles();

  const value = props.value === undefined ? 'none' : props.value;

  return (
    <FormControl className={classes.dateSelect} style={{ width: 100 }}>
      <Select
        value={value}
        onChange={onChange}
        MenuProps={{
          className: classes.dateSelectMenuList,
          variant: 'selectedMenu',
          anchorReference: null,
          anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
        }}
        displayEmpty
      >
        <MenuItem value="none">Mês</MenuItem>
        <MenuItem value={0}>Janeiro</MenuItem>
        <MenuItem value={1}>Fevereiro</MenuItem>
        <MenuItem value={2}>Março</MenuItem>
        <MenuItem value={3}>Abril</MenuItem>
        <MenuItem value={4}>Maio</MenuItem>
        <MenuItem value={5}>Junho</MenuItem>
        <MenuItem value={6}>Julho</MenuItem>
        <MenuItem value={7}>Agosto</MenuItem>
        <MenuItem value={8}>Setembro</MenuItem>
        <MenuItem value={9}>Outubro</MenuItem>
        <MenuItem value={10}>Novembro</MenuItem>
        <MenuItem value={11}>Dezembro</MenuItem>
      </Select>
    </FormControl>
  );
}
