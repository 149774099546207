import getEmittedSalesContractInvoicesQuery from 'queries/getEmittedSalesContractInvoicesQuery';
import api from 'services/api';

export default (projectId) =>
  api
    .call(getEmittedSalesContractInvoicesQuery, { projectId })
    .then(({ getEmittedSalesContractInvoices: data }) => data)
    .catch((err) => {
      console.log(err);
      return [];
    });
