import { Box, Button, Tab, Tabs } from '@material-ui/core';
import React from 'react';
import AddressTab from './AddressTab';
import GeneralTab from './GeneralTab';

export default function Form({
  projectId,
  realEstateAgent,
  setRealEstateAgent,
  submit,
  searchAddress,
  step,
  setStep,
  hideReservationLimit,
  action,
}) {
  const handleSubmit = (e) => {
    e.preventDefault();
    submit();
  };

  if (!realEstateAgent) return null;

  return (
    <form onSubmit={handleSubmit}>
      <Box mb={5}>
        <Tabs
          variant="fullWidth"
          value={step}
          onChange={(_, value) => setStep(value)}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="1. Geral" style={{ marginRight: 24 }} />
          <Tab label="2. Endereço" />
        </Tabs>
      </Box>

      <Box hidden={step !== 0}>
        <GeneralTab
          projectId={projectId}
          realEstateAgent={realEstateAgent}
          setRealEstateAgent={setRealEstateAgent}
          hideReservationLimit={hideReservationLimit}
          action={action}
        />
      </Box>

      <Box hidden={step !== 1}>
        <AddressTab
          realEstateAgent={realEstateAgent}
          setRealEstateAgent={setRealEstateAgent}
          searchAddress={searchAddress}
        />
      </Box>

      <Box textAlign="right" mt={5}>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          style={{ width: 208 }}
        >
          Cadastrar
        </Button>
      </Box>
    </form>
  );
}
