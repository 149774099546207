/* eslint-disable import/no-anonymous-default-export */
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
});

export default useStyles;
