import { Box, IconButton, Tooltip, useTheme } from '@material-ui/core';
import handlerError from 'utils/handleError';
import changeReservationStatusQuery from 'queries/changeReservationStatusQuery';
import React, { useCallback } from 'react';
import { FaCheck, FaTimes } from 'react-icons/fa';
import api from 'services/api';
import Swal from 'sweetalert2';
import PropTypes from 'prop-types';

import ICON_TYPES from '@constants/iconsPaths';
import Icon from 'components/Icon';

export default function ReservationActions({
  reservation,
  isAdmin,
  onEdit,
  onUpdate,
}) {
  const theme = useTheme();

  const { isContractCreated } = reservation;
  const isPending = reservation.status === 'pending';

  const acceptReservation = useCallback(
    (e) => {
      e.stopPropagation();

      Swal.fire({
        icon: 'warning',
        title: 'Confirmar reserva?',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then((result) => {
        if (result.isConfirmed) {
          api
            .call(changeReservationStatusQuery(reservation._id, 'accepted'))
            .then(() => {
              Swal.fire('Reserva aceita com sucesso!', '', 'success');
              onUpdate();
            })
            .catch((error) => {
              console.log(error);

              handlerError(
                {
                  RESERVATION_EXPIRED: () => {
                    Swal.fire(
                      'Não é possível aceitar, a reserva expirou.',
                      '',
                      'error'
                    );
                  },
                  DEFAULT: () => {
                    Swal.fire('Algo de errado aconteceu.', '', 'error');
                  },
                },
                error.message
              );
            });
        }
      });
    },
    [onUpdate, reservation._id]
  );

  const rejectReservation = useCallback(
    (e) => {
      e.stopPropagation();

      Swal.fire({
        icon: 'warning',
        title: 'Recusar reserva?',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then((result) => {
        if (result.isConfirmed) {
          api
            .call(changeReservationStatusQuery(reservation._id, 'rejected'))
            .then(() => {
              Swal.fire('Reserva recusada com sucesso!', '', 'success');
              onUpdate();
            })
            .catch((error) => {
              console.log(error);

              handlerError(
                {
                  RESERVATION_EXPIRED: () => {
                    Swal.fire(
                      'Não é possível aceitar, a reserva expirou.',
                      '',
                      'error'
                    );
                  },
                  DEFAULT: () => {
                    Swal.fire('Algo de errado aconteceu.', '', 'error');
                  },
                },
                error.message
              );
            });
        }
      });
    },
    [onUpdate, reservation._id]
  );

  const handleEdit = (e) => {
    e.stopPropagation();
    onEdit(reservation._id);
  };

  if (!isAdmin) return null;

  return (
    <Box display="flex" justifyContent="flex-end">
      <Box display="flex" visibility={isPending ? 'visible' : 'hidden'}>
        <Tooltip title="Aceitar">
          <IconButton size="small" onClick={acceptReservation}>
            <FaCheck size={17} color={theme.palette.success.main} />
          </IconButton>
        </Tooltip>

        <Box width={8} />

        <Tooltip title="Recusar">
          <IconButton size="small" onClick={rejectReservation}>
            <FaTimes size={18} color={theme.palette.error.main} />
          </IconButton>
        </Tooltip>

        <Box width={8} />
      </Box>

      <Box visibility={isContractCreated ? 'hidden' : 'visible'}>
        <Tooltip title="Editar">
          <IconButton size="small" onClick={handleEdit}>
            <Icon src={ICON_TYPES.LIST.edit} size={21} />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
}

ReservationActions.propTypes = {
  reservation: PropTypes.shape({
    _id: PropTypes.string,
    status: PropTypes.string,
    isContractCreated: PropTypes.bool,
  }).isRequired,
  isAdmin: PropTypes.bool,
  onEdit: PropTypes.func,
  onUpdate: PropTypes.func,
};

ReservationActions.defaultProps = {
  isAdmin: false,
  onEdit: null,
  onUpdate: () => {},
};
