import { Box } from "@material-ui/core"
import EnvironmentModule from "modules/EnvironmentModule"
import ReCAPTCHA from "react-google-recaptcha"

const RECAPTCHA_KEY = EnvironmentModule.get('RECAPTCHA_KEY')

export default function Captcha({ onChange }) {
  const siteKey = RECAPTCHA_KEY
  if (!siteKey) return null

  return (
    <Box mt={3}>
      <ReCAPTCHA sitekey={siteKey}
        onChange={onChange}
      />
    </Box>
  )
}
