import userFragments from 'queries/fragments/user';
import projectFragments from 'queries/fragments/project';
import systemAccessFragments from 'queries/fragments/systemAccess';

export default `
  ${projectFragments}
  ${systemAccessFragments}
  ${userFragments}
  query Me {
    me {
      isLoggedAs
      access {
        ...CoreSystemAccessFields
        isSuperAdmin
        project {
          ...CoreProjectFields
        }
      }
      user {
        ...CoreUserFields
      }
    }
  }
`;
