import { Box, ButtonBase, Checkbox, Menu, MenuItem } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import Icon from 'components/Icon';
import ICON_TYPES from '@constants/iconsPaths';
import useStyles from './styles';

export default function HeaderCheckbox({
  areAllSelected,
  hasSomethingSelected,
  toggleSelectAll,
  selectAll,
  deselectAll,
}) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  const CheckBoxIcon = useMemo(
    () =>
      !areAllSelected && hasSomethingSelected ? (
        <IndeterminateCheckBoxIcon />
      ) : undefined,
    [areAllSelected, hasSomethingSelected]
  );

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectAll = () => {
    handleClose();
    selectAll();
  };

  const handleDeselectAll = () => {
    handleClose();
    deselectAll();
  };

  return (
    <Box display="flex" alignItems="center">
      <Checkbox
        className={classes.checkbox}
        checked={areAllSelected}
        onClick={toggleSelectAll}
        icon={CheckBoxIcon}
        color="primary"
      />

      <ButtonBase onClick={handleClick} disableRipple>
        <Icon src={ICON_TYPES.BASE.dropdownArrow} />
      </ButtonBase>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleSelectAll}>Todos</MenuItem>
        <MenuItem onClick={handleDeselectAll}>Nenhum</MenuItem>
      </Menu>
    </Box>
  );
}
