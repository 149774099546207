import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  textFieldOutlined1: {
    '& .MuiInputBase-root': {
      borderRadius: 20,

      '& .MuiInputBase-input': {
        fontSize: theme.typography.body1,
        padding: '10px 40px',
        paddingRight: 80
      }
    }
  },
}))


