import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  selectionButton: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    padding: theme.spacing(2),
  },
}));
