/* eslint-disable react/jsx-no-constructed-context-values */
import React from 'react';

import { ProjectContext } from 'providers/ProjectProvider';
import useGetReservations from './hooks/useGetReservations';
import useWebHooks from './hooks/useWebHooks';

const { createContext, useContext, useState } = require('react');

const ReservationsListContext = createContext();

export default function ReservationsListProvider({ children }) {
  const { project } = useContext(ProjectContext);
  const [search, setSearch] = useState('');
  const [variables, setVariables] = useState({
    projectId: project._id,
    page: 0,
    sortOptions: { field: 'updatedAt', direction: 'desc' },
    filterOptions: { status: null },
    changedAt: Date.now(),
  });
  const { reservations, pages, isLoading } = useGetReservations(
    variables,
    search
  );

  const setPage = (value) => setVariables({ ...variables, page: value });

  const setFilter = (value) => {
    const newFilterOptions = { ...variables.filterOptions, ...value };
    setVariables({ ...variables, page: 0, filterOptions: newFilterOptions });
  };

  const setSort = (option) =>
    setVariables({
      ...variables,
      page: 0,
      sortOptions: {
        field: option.value,
        direction: option.reverse ? 'desc' : 'asc',
      },
    });

  const updateList = () =>
    setVariables({ ...variables, changedAt: Date.now() });

  useWebHooks(reservations, updateList);

  return (
    <ReservationsListContext.Provider
      value={{
        reservations,
        pages,
        isLoading,
        search,
        variables,
        updateList,
        setPage,
        setFilter,
        setSort,
        setSearch,
      }}
    >
      {children}
    </ReservationsListContext.Provider>
  );
}

export function useReservationsListContext() {
  return useContext(ReservationsListContext);
}
