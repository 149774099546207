import React from 'react';

import Summary from 'components/Summary';
import { usePropertiesListContext } from 'providers/ListProviders/PropertiesListProvider';

export default function PropertiesListSummary() {
  const { summaryData } = usePropertiesListContext();

  return <Summary label="properties" data={summaryData} />;
}
