import { makeStyles } from '@material-ui/core';
import React from 'react';

export default function NotFound() {
  const classes = style()

  return (
    <div className={classes.root}>
      <h1>404</h1>
      <p>Página não encontrada!</p>
    </div>
  )
}

const style = makeStyles({
  root: {
    width: '100%',
    height: '100vh',
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    '& h1': {
      fontSize: 128,
      margin: 0,
    },

    '& p': {
      fontSize: 48
    }
  }
})