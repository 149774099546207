import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: 20,
    paddingBottom: 120,
  },

  contractDetails: {
    borderTop: '1px solid transparent',
    borderBottom: '1px solid transparent',
    padding: '16px 0px',

    [theme.breakpoints.up('lg')]: {
      borderTop: 0,
      borderBottom: 0,
      borderLeft: '1px solid transparent',
      borderRight: '1px solid transparent',
      padding: '8px 16px',
    },

    borderColor: `${theme.palette.grey[500]} !important`,
  },

  iconBox: {
    width: 48,
    height: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    backgroundColor: theme.palette.grey[600],
  },
}));

export default {};
