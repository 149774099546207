export default function updateUserQuery({ data }) {
  return `
    mutation {
      updateUser(
        data: {
          name: "${data.name}"
        }
      )
    }
  `
}