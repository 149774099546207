import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  Box,
} from '@material-ui/core';
import React, { useMemo, useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Pagination from './pagination';
import TableRow from './TableRow';
import HeaderTableRow from './HeaderTableRow';

function BaseList({
  data,
  columns,
  currentPage,
  pages,
  options,
  onPagination,
  getSelectMethods,
  onRowClick,
  onSelect,
}) {
  const isPaginationNeeded = useMemo(() => pages > 1, [pages]);

  const [itemsSelected, setItemsSelected] = useState([]);

  const isSelected = useCallback(
    (id) => itemsSelected.indexOf(id) >= 0,
    [itemsSelected]
  );

  const areAllSelected = useMemo(
    () => data.reduce((prev, item) => prev && isSelected(item._id), true),
    [data, isSelected]
  );

  const hasSomethingSelected = useMemo(
    () => itemsSelected.length > 0,
    [itemsSelected]
  );

  const handleToggleSelection = useCallback(
    (id) => {
      if (isSelected(id)) {
        setItemsSelected(itemsSelected.filter((item) => item !== id));
      } else {
        setItemsSelected([...itemsSelected, id]);
      }
    },
    [itemsSelected, isSelected]
  );

  const handleSelectAll = useCallback(() => {
    setItemsSelected(data.map((item) => item._id));
  }, [data]);

  const handleDeselectAll = useCallback(() => {
    setItemsSelected([]);
  }, []);

  const handleToggleSelectAll = useCallback(() => {
    if (hasSomethingSelected) {
      if (areAllSelected) {
        handleDeselectAll();
      } else {
        handleSelectAll();
      }
    } else {
      handleSelectAll();
    }
  }, [
    areAllSelected,
    hasSomethingSelected,
    handleDeselectAll,
    handleSelectAll,
  ]);

  useEffect(() => {
    if (getSelectMethods) {
      getSelectMethods({
        handleSelectAll,
        handleDeselectAll,
        handleToggleSelectAll,
        handleToggleSelection,
      });
    }
  }, [
    handleSelectAll,
    handleDeselectAll,
    handleToggleSelectAll,
    handleToggleSelection,
    getSelectMethods,
  ]);

  useEffect(() => {
    if (onSelect) onSelect(itemsSelected);
  }, [itemsSelected, onSelect]);

  return (
    <Box mb={5}>
      <TableContainer>
        <Table>
          <TableHead>
            <HeaderTableRow
              columns={columns}
              canSelect={options?.canSelect}
              areAllSelected={areAllSelected}
              hasSomethingSelected={hasSomethingSelected}
              toggleSelectAll={handleToggleSelectAll}
              selectAll={handleSelectAll}
              deselectAll={handleDeselectAll}
            />
            <tr className="MuiTableRow-root" style={{ height: 15 }} />
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow
                key={row._id}
                data={row}
                canSelect={options?.canSelect}
                columns={columns}
                isSelected={isSelected(row._id)}
                toggleSelection={() => handleToggleSelection(row._id)}
                onClick={() => {
                  onRowClick(row);
                }}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {isPaginationNeeded ? (
        <Pagination
          currentPage={currentPage}
          pages={pages}
          onPagination={onPagination}
        />
      ) : null}
    </Box>
  );
}

BaseList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  columns: PropTypes.arrayOf(PropTypes.any).isRequired,
  currentPage: PropTypes.number,
  pages: PropTypes.number,
  options: PropTypes.shape({
    canSelect: PropTypes.bool,
  }),
  onSelect: PropTypes.func,
  onPagination: PropTypes.func,
  onRowClick: PropTypes.func,
  getSelectMethods: PropTypes.func,
};

BaseList.defaultProps = {
  options: {
    canSelect: false,
  },
  pages: 1,
  currentPage: 0,
  onPagination: () => {},
  onRowClick: () => {},
  getSelectMethods: () => {},
  onSelect: () => {},
};

export default React.memo(
  BaseList,
  (previousProps, nextProps) => previousProps.data === nextProps.data
);
