// Regex to parse URL based on Uniform Resource Identifier (URI) - RFC 3986
// https://tools.ietf.org/html/rfc3986#appendix-B
const urlParseRegex = /^(([^:/?#]+):)?(\/\/([^/?#]*))?([^?#]*)(\?([^#]*))?(#(.*))?/
const parseUrl = url => {
  const results = urlParseRegex.exec(url)
  const fullDomain = results[3] && results[3].split(':')[0]
  const domain = results[4] && results[4].split(':')[0]
  const port = results[4] && results[4].split(':')[1]

  return {
    url,
    fullProtocol: results[1],
    protocol: results[2],
    fullDomain,
    domain,
    fullPort: port && `:${port}`,
    port,
    path: results[5],
    fullSearch: results[6],
    search: results[7],
    hash: results[9],
    fullHash: results[8]
  }
}

const Url = (url, test = false) => {
  const parsedUrl = parseUrl(url)
  const toString = (parsedUrl) => {
    const {
      fullProtocol,
      fullDomain,
      fullPort,
      path,
      fullSearch,
      fullHash
    } = parsedUrl
    return `${fullProtocol}${fullDomain}${fullPort || ''}${path || ''}${fullSearch || ''}${fullHash || ''}`
  }

  return {
    get path () { return parsedUrl.path },
    toString: () => toString(parsedUrl),
    isSecure() {
      const { protocol } = parsedUrl
      return protocol === 'https' || protocol === 'wss'
    },
    withPath: path => {
      return Url(toString({ ...parsedUrl, path }))
    },
    appendPath: newPath => {
      const path = parsedUrl.path + newPath
      return Url(toString({ ...parsedUrl, path }))
    },
    withProtocol: protocol => {
      return Url(toString({
        ...parsedUrl,
        protocol,
        fullProtocol: `${protocol}:`
      }))
    }
  }
}
export default Url
