export default `
    mutation CreateReservation (
        $projectId: ID!
        $propertyId: ID!
        $freezedPriceToken: String!
        $paymentMethod: String!
        $entrance: Float!
        $installments: Int!
        $firstMonthlyInstallmentDate: String
        $firstAnnuallyInstallmentDate: String
        $customerName: String!
        $customerCpf: String!
        $customerMaritalStatus: String!
        $customerOccupation: String!        
        $customerPhone: String!
        $observation: String
        $realEstateAgentId: ID
    ) {
        createReservation (
            projectId: $projectId
            data: {
                propertyId: $propertyId
                customer: {
                    name: $customerName
                    cpf: $customerCpf
                    maritalStatus: $customerMaritalStatus
                    occupation: $customerOccupation
                    phone: $customerPhone
                }
                freezedPriceToken: $freezedPriceToken
                paymentMethod: $paymentMethod
                entrance: $entrance
                installments: $installments
                firstMonthlyInstallmentDate: $firstMonthlyInstallmentDate
                firstAnnuallyInstallmentDate: $firstAnnuallyInstallmentDate
                observation: $observation
                realEstateAgentId: $realEstateAgentId
            }
        ) {
          _id
          numberId
          projectId
          entrance
          price
          installments
          paymentMethod
          annualQuota
          firstMonthlyInstallmentDate
          firstAnnuallyInstallmentDate
          observation
          discount
          customer {
            _id
            name
            cpf
            rg
          }
          realEstate {
            _id
            name
            cnpj
            creci
          }
          realEstateAgent {
            _id
            name
            cpf
            rg
            creci
          }

        }
    }
`;
