import AlertModule from 'modules/AlertModule';
import UPDATE_REAL_ESTATE_MUTATION from 'queries/updateRealEstateAgentQuery';
import { useCallback } from 'react';
import api from 'services/api';
import handlerError from 'utils/handleError';

export default function useEditRealEstateAgent(realEstateAgent) {
  const execute = useCallback(() => {
    const updatedRealEstateAgent = {
      ...realEstateAgent,
      id: realEstateAgent._id,
      reservationLimit: Number(realEstateAgent.reservationLimit),
      active: Boolean(realEstateAgent.active),
    };

    AlertModule.wait();

    return api
      .call(UPDATE_REAL_ESTATE_MUTATION, updatedRealEstateAgent)
      .then(() => AlertModule.success('Corretor atualizado com sucesso!'))
      .catch((error) => {
        handlerError(
          {
            INVALID_EMAIL: AlertModule.onError('E-mail inválido.'),
            INVALID_USERNAME: AlertModule.onError('E-mail de acesso inválido.'),
            USERNAME_IN_USE: AlertModule.onError(
              'Esse e-mail de acesso já está em uso.'
            ),
            DEFAULT: AlertModule.onError(
              'Não foi possível salvar as alterações.'
            ),
          },
          error.message
        );

        throw error;
      });
  }, [realEstateAgent]);

  return execute;
}
