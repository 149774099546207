import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {
    position: 'fixed',
    left: 0,
    bottom: 0,
    right: 0,
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  selectedInvoicesContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 8,
  },
}));
