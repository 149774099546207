const GET_SALES_CONTRACTS_INVOICES_QUERY = `
  query GetSalesContractsInvoices (
    $projectId: ID!
    $page: Int
    $search: String
    $sortOptions: SalesContractInvoiceSortInput
    $filterOptions: SalesContractInvoiceFilterInput
  ) {
    getSalesContractsInvoices (
      projectId: $projectId
      page: $page
      search: $search
      sortOptions: $sortOptions
      filterOptions: $filterOptions
    ) {
      items {
        _id
        projectId
        contractNumberId
        type
        description
        paymentMethod
        value
        fees
        discount
        dueDate
        status
        isEmitted
        paidAt
        paidValue
        createdAt  
      }
      numberOfPages
      page
    }
  }
`;

export default GET_SALES_CONTRACTS_INVOICES_QUERY;
