export default function createPropertyMarkQuery({ propertyId, posX, posY }) {
  return `
    mutation {
      createPropertyMark(
        propertyId: "${propertyId}"
        posX: ${posX.toFixed(2)}
        posY: ${posY.toFixed(2)}
      )
    }
  `
}