import { useEffect, useState } from 'react';

import WebSocketController from 'utils/WebSocketController';

const socket = new WebSocketController();

export default function useWebHooks(onUpdate) {
  const [needUpdate, setNeedUpdate] = useState(false);

  useEffect(() => {
    socket.onRemittanceCreated(() => {
      setNeedUpdate(true);
    });
  }, []);

  useEffect(() => {
    if (needUpdate) {
      onUpdate();
      setNeedUpdate(false);
    }
  }, [needUpdate]);
}
