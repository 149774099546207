import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  link: {
    color: `${theme.palette.text.primary}`,
    display: 'block',
    textDecoration: 'none',

    '&:hover': {
      textDecoration: 'underline',
      color: `${theme.palette.text.primary}`,
    },

    '&.active': {
      textDecoration: 'underline',
      color: `${theme.palette.text.primary}`,
    },
  },
}));

export default useStyles;
