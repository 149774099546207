import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';

import { useReservationsListContext } from 'providers/ListProviders/ReservationsListProvider';
import api from 'services/api';

import SearchListTextField from 'components/SearchListTextField';
import FilterSelect from 'components/FilterSelect';
import SortSelect from 'components/SortSelect';
import ExportListButton from 'components/ExportListButton';

import SORT_OPTIONS from './constants/sortOptions';
import FILTER_OPTIONS from './constants/filterOptions';

import useStyles from '../styles';
import getSelectedFilterOption from '../utils/getSelectedFilterOption';
import getSelectedSortOption from '../utils/getSelectedSortOption';

export default function ReservationsListFilters() {
  const classes = useStyles();
  const { search, setSearch, variables, setFilter, setSort } =
    useReservationsListContext();

  const filterOption = getSelectedFilterOption(
    FILTER_OPTIONS,
    variables.filterOptions.status
  );

  const sortOption = getSelectedSortOption(SORT_OPTIONS, variables.sortOptions);

  const handleExport = () => {
    const exportVariables = { ...variables, search };
    delete exportVariables.pages;
    api.exportReservations(exportVariables);
  };

  return (
    <Box mb={5}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs="auto">
          <Typography className="fw700" variant="h5" color="textPrimary">
            Reservas
          </Typography>
        </Grid>

        <Grid item xs={12} md="auto">
          <Box minWidth={285}>
            <SearchListTextField
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md>
          <Box className={classes.listFilters}>
            <FilterSelect
              value={filterOption}
              options={FILTER_OPTIONS}
              onChange={(option) => {
                setFilter({ status: option.value });
              }}
            />

            <SortSelect
              value={sortOption}
              options={SORT_OPTIONS}
              onChange={setSort}
            />

            <ExportListButton onClick={handleExport} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
