/* eslint-disable react/jsx-no-constructed-context-values */
import React from 'react';

import { ProjectContext } from 'providers/ProjectProvider';
import useGetCustomers from './hooks/useGetCustomers';
import useWebHooks from './hooks/useWebHooks';

const { createContext, useContext, useState } = require('react');

const CustomersListContext = createContext();

export default function CustomersListProvider({ children }) {
  const { project } = useContext(ProjectContext);
  const [search, setSearch] = useState('');
  const [variables, setVariables] = useState({
    projectId: project._id,
    page: 0,
    search,
    sortOptions: { field: 'name', direction: 'asc' },
    changedAt: Date.now(),
  });
  const { customers, pages, isLoading } = useGetCustomers(variables, search);

  const setPage = (value) => setVariables({ ...variables, page: value });

  const setSort = (option) =>
    setVariables({
      ...variables,
      page: 0,
      sortOptions: {
        field: option.value,
        direction: option.reverse ? 'desc' : 'asc',
      },
    });

  const updateList = () =>
    setVariables({ ...variables, changedAt: Date.now() });

  useWebHooks(updateList);

  return (
    <CustomersListContext.Provider
      value={{
        customers,
        pages,
        isLoading,
        search,
        variables,
        updateList,
        setPage,
        setSort,
        setSearch,
      }}
    >
      {children}
    </CustomersListContext.Provider>
  );
}

export function useCustomersListContext() {
  return useContext(CustomersListContext);
}
