import { useMemo } from 'react';

import { PAYMENT_METHODS } from '@constants/reservations/paymentMethods';
import InstallmentCalculatorModule from 'modules/InstallmentCalculatorModule';

export default function useGetPaymentData(reservation) {
  const {
    paymentMethod,
    entrance,
    annualQuota,
    entranceFee,
    installmentOptions,
    installments,
  } = reservation;

  const price = reservation.price - reservation.price * reservation.discount;

  const installmentCalculator = new InstallmentCalculatorModule({
    paymentMethod,
    totalPrice: price,
    entrance,
    annualQuota,
  });

  const minimumEntrance = useMemo(() => {
    if (!reservation) return 0;
    return (price * entranceFee) / 100;
  }, [reservation]);

  const monthlyInstallmentsOptions = useMemo(() => {
    if (!reservation) return [];

    if (paymentMethod !== PAYMENT_METHODS.CASH) {
      return installmentOptions.map((value) => {
        const installment =
          installmentCalculator.calculateMonthlyInstallment(value);

        return {
          value,
          label: installment.formattedValue,
        };
      });
    }

    return [];
  }, [reservation]);

  const annuallyInstallmentsOptions = useMemo(() => {
    if (!reservation) return null;
    return installmentCalculator.calculateAnnuallyInstallment(installments);
  }, [reservation]);

  const monthlyDueDates = useMemo(() => {
    if (!reservation) return [];

    const dateBase = new Date(reservation.createdAt);
    const currentMonth = dateBase.getUTCMonth();
    const currentYear = dateBase.getUTCFullYear();

    return Array(2)
      .fill(0)
      .map((_, index) => {
        const month = String((currentMonth + index + 1) % 12 || 12).padStart(
          2,
          '0'
        );
        const year = currentYear + Math.floor((currentMonth + index) / 12);

        return {
          value: `${year}-${month}`,
          label: `${month}/${year}`,
        };
      });
  }, [reservation]);

  const annuallyDueDates = useMemo(() => {
    if (!reservation) return [];

    const { firstMonthlyInstallmentDate } = reservation;
    if (!firstMonthlyInstallmentDate) return [];

    const currentMonth = Number(firstMonthlyInstallmentDate.split('-')[1]);
    const currentYear = Number(firstMonthlyInstallmentDate.split('-')[0]);

    return Array(12)
      .fill(0)
      .map((_, index) => {
        const month = String((currentMonth + index + 1) % 12 || 12).padStart(
          2,
          '0'
        );
        const year = currentYear + Math.floor((currentMonth + index) / 12);

        const date = {
          value: `${year}-${month}`,
          label: `${month}/${year}`,
        };

        return date;
      });
  }, [reservation]);

  return {
    price,
    minimumEntrance,
    monthlyInstallmentsOptions,
    annuallyInstallmentsOptions,
    monthlyDueDates,
    annuallyDueDates,
  };
}
