import { Box, Container } from '@material-ui/core';
import ProjectsLink from 'components/ProjectsLink';
import React, { useMemo, useState } from 'react';
import BankAccount from './BankAccount';
import Remittance from './Remittance';
import Return from './Return';
import SideTab from './SideTab';

export default function Financial() {
  const links = useMemo(
    () => [
      { id: 1, label: 'Contas bancárias' },
      { id: 2, label: 'Remessa' },
      { id: 3, label: 'Retorno' },
    ],
    []
  );

  const [tabIndex, setTabIndex] = useState(1);

  return (
    <Container>
      <Box py={2.5}>
        <ProjectsLink />

        <SideTab
          title="Financeiro"
          items={links}
          value={tabIndex}
          onChange={(value) => setTabIndex(value)}
        >
          {tabIndex === 1 ? <BankAccount /> : null}
          {tabIndex === 2 ? <Remittance /> : null}
          {tabIndex === 3 ? <Return /> : null}
        </SideTab>
      </Box>
    </Container>
  );
}
