const sortBy = (attr, inReverse) => {
  const sort = (a, b) => {
    if (a[attr] > b[attr]) return 1;
    if (a[attr] < b[attr]) return -1;
    return 0;
  };
  const reverseSort = (a, b) => sort(a, b) * -1;

  if (inReverse) return reverseSort;
  return sort;
};

export default ({ auth, search, sort }) =>
  auth?.projects
    .filter((project) => {
      const searchLower = String(search).toLowerCase();
      const title = String(project.title).toLowerCase();
      return title.indexOf(searchLower) >= 0;
    })
    .sort(sortBy(sort.value, sort.reverse));
