import React from 'react';
import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import { usePropertiesListContext } from 'providers/ListProviders/PropertiesListProvider';

import api from 'services/api';

import ExportListButton from 'components/ExportListButton';
import FilterSelect from 'components/FilterSelect';
import PriceSelect from 'components/PriceSelect';
import SearchListTextField from 'components/SearchListTextField';
import SortSelect from 'components/SortSelect';

import FILTER_OPTIONS from './constants/filterOptions';
import SORT_OPTIONS from './constants/sortOptions';
import useStyles from '../styles';
import getSelectedFilterOption from '../utils/getSelectedFilterOption';
import getSelectedSortOption from '../utils/getSelectedSortOption';

export default function PropertiesListFilters({ extraActions }) {
  const theme = useTheme();
  const classes = useStyles();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));

  const { variables, search, setSearch, maxPrice, setFilter, setSort } =
    usePropertiesListContext();

  const handleExport = () => {
    const exportPropertiesVariables = { ...variables, search };
    delete exportPropertiesVariables.page;
    api.exportProperties(exportPropertiesVariables);
  };

  const filterOption = getSelectedFilterOption(
    FILTER_OPTIONS,
    variables.filterOptions.status
  );

  const sortOption = getSelectedSortOption(SORT_OPTIONS, variables.sortOptions);

  return (
    <Box mb={5}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs="auto">
          <Typography className="fw700" variant="h5" color="textPrimary">
            Imóveis
          </Typography>
        </Grid>

        {isSm ? (
          <Grid item xs>
            {extraActions}
          </Grid>
        ) : null}

        <Grid item xs={12} md="auto">
          <Box minWidth={285}>
            <SearchListTextField
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              value={search}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md>
          <Box className={classes.listFilters}>
            <PriceSelect
              maxRange={maxPrice || 0}
              onApply={(value) => setFilter({ price: value })}
            />

            <FilterSelect
              value={filterOption}
              options={FILTER_OPTIONS}
              onChange={(option) => setFilter({ status: option.value })}
            />

            <SortSelect
              value={sortOption}
              options={SORT_OPTIONS}
              onChange={setSort}
            />

            <ExportListButton onClick={handleExport} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
