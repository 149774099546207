import { useCallback, useEffect, useRef, useState } from 'react';
import useQuery from 'hooks/useQuery';
import AlertModule from 'modules/AlertModule';
import GET_PROPERTIES_QUERY from 'queries/getPropertiesQuery';
import handlerError from 'utils/handleError';

export default function useGetProperties(variables, search) {
  const [executeGetProperties, isLoading] = useQuery(GET_PROPERTIES_QUERY);
  const [pages, setPages] = useState(1);
  const [properties, setProperties] = useState([]);
  const [maxPrice, setMaxPrice] = useState(null);
  const isFirstRender = useRef(true);

  const onResolve = ({ getProperties: data }) => {
    setPages(data.pages);
    setProperties(data.properties);
    setMaxPrice(data.maxPrice);
  };

  const onReject = (error) => {
    handlerError(
      {
        DEFAULT: AlertModule.onError(
          'Não foi possível carregar a lista de imóveis.'
        ),
      },
      error.message
    );
  };

  const createGetter = (executeQuery) => () =>
    executeQuery({ ...variables, search })
      .then(onResolve)
      .catch(onReject);

  const getPropertiesBySearch = useCallback(
    createGetter(executeGetProperties.debounced),
    [variables, search]
  );

  const getProperties = useCallback(createGetter(executeGetProperties), [
    variables,
    search,
  ]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    getPropertiesBySearch();
  }, [search]);

  useEffect(() => {
    getProperties();
  }, [variables]);

  return { properties, pages, maxPrice, isLoading };
}
