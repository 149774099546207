/* eslint-disable react/jsx-props-no-spreading */
import { useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';

export default function ProjectHeader(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (isMobile) return <MobileHeader {...props} />;
  return <DesktopHeader {...props} />;
}
