import React from 'react';
import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import Loading from 'components/Loading';
import ReturnListZeroStateImage from 'assets/images/ZeroStates/ReturnListZeroStateImage';
import { useReturnFilesListContext } from 'providers/ListProviders/ReturnFilesListProvider';

export default function ReturnFilesListZeroState({ children }) {
  const { returnFiles, search, isLoading } = useReturnFilesListContext();
  const hasData = Boolean(returnFiles.length);
  const hasSearchFilter = Boolean(search);

  const message =
    !hasData && !hasSearchFilter
      ? ' Você ainda não subiu nenhum arquivo de retorno'
      : 'Nenhum arquivo encontrado, tente uma nova pesquisa';

  if (isLoading) return <Loading height={100} />;
  if (hasData) return children;

  return (
    <Box
      mt={8}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Box mb={5}>
        <ReturnListZeroStateImage />
      </Box>

      <Box display="flex" justifyContent="center">
        <Box mb={1}>
          <Typography className="fw700" align="center" variant="h3">
            {message}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

ReturnFilesListZeroState.propTypes = {
  children: PropTypes.oneOf([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

ReturnFilesListZeroState.defaultProps = {
  children: null,
};
