import React from 'react';
import { Box, Typography, Grid } from '@material-ui/core';
// eslint-disable-next-line
import ChipInput from 'components/ChipInput';
import PercentField from 'components/Fields/PercentField';
import PropTypes from 'prop-types';

export default function Reservation({ configuration, setConfiguration }) {
  const handleAddInstallment = (value) => {
    const { installments } = configuration;

    if (Number.isNaN(value)) return;
    if (value % 1 !== 0) return;

    const hasValue = installments.find((installment) => installment === value);

    if (!hasValue) {
      setConfiguration({
        ...configuration,
        installments: [...installments, value],
      });
    }
  };

  const handleDeleteInstallment = (value) => {
    const { installments } = configuration;

    const newInstallments = installments.filter(
      (installment) => installment !== value
    );
    setConfiguration({
      installments: newInstallments,
    });
  };

  return (
    <Grid container spacing={0}>
      <Box marginTop={2}>
        <Typography className="fw700" variant="h5">
          Definições da reserva
        </Typography>
        <Grid container spacing={2}>
          <Grid item lg={6}>
            <Box my={2}>
              <PercentField
                label="Entrada (%)"
                value={configuration.entranceFee}
                onChange={(e) =>
                  setConfiguration({
                    entranceFee: e.target.value,
                  })
                }
                fullWidth
              />
            </Box>
          </Grid>
          <Grid item lg={6}>
            <Box my={2}>
              <PercentField
                label="Cota das Parcelas Anuais (%)"
                value={configuration.annualQuota}
                onChange={(e) =>
                  setConfiguration({
                    annualQuota: e.target.value,
                  })
                }
                fullWidth
              />

              <Box mt={1} />

              <Typography variant="h6" color="textSecondary">
                O valor tem que ser maior que 0%
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid>
          <Box mb={4}>
            <Typography
              variant="h6"
              color="textSecondary"
              style={{ fontSize: 12 }}
            >
              Opções de parcelas
            </Typography>

            <Box mt={1} />

            <ChipInput
              value={configuration.installments}
              onAdd={handleAddInstallment}
              onDelete={handleDeleteInstallment}
              fullWidth
            />
            <Box mt={1} />
            <Typography variant="h6" color="textSecondary">
              Quantidade máxima será a padrão
            </Typography>
          </Box>
        </Grid>
      </Box>
    </Grid>
  );
}

Reservation.propTypes = {
  setConfiguration: PropTypes.func.isRequired,
  configuration: PropTypes.shape({
    entranceFee: PropTypes.number,
    installments: PropTypes.arrayOf(PropTypes.number),
    annualQuota: PropTypes.number,
  }).isRequired,
};
