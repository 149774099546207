import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Tabs, Tab, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';

import api from 'services/api';

import WebSocketController from 'utils/WebSocketController';
import swalFire from 'utils/swalFire';

import InstallmentCalculatorModule from 'modules/InstallmentCalculatorModule';

import GET_RESERVATION_QUERY from 'queries/getReservationQuery';

import BaseDialog from 'components/Dialogs/BaseDialog';
import DocumentTab from './components/DocumentTab';
import PaymentTab from './components/PaymentTab';
import GeneralTab from './components/GeneralTab';
import EditButton from './components/EditButton';

const socket = new WebSocketController();

export default function ReadReservationDialog({
  open,
  reservationId,
  onClose,
  onEdit,
}) {
  const [tabIndex, setTabIndex] = useState(0);

  const [reservation, setReservation] = useState(null);
  const [updatedReservationId, setUpdatedReservationId] = useState(null);
  const [updatedPropertyId, setUpdatedPropertyId] = useState(null);

  const installmentCalculator = useMemo(() => {
    if (!reservation) return null;
    return new InstallmentCalculatorModule({
      paymentMethod: reservation.paymentMethod,
      totalPrice: reservation.price,
      entrance: reservation.entrance,
      annualQuota: reservation.annualQuota,
    });
  }, [reservation]);

  const loadReservation = useCallback(
    (id) => {
      if (!reservationId) return;

      api
        .call(GET_RESERVATION_QUERY, { id })
        .then((data) => {
          if (data) {
            setReservation(data.getReservation);
          }
        })
        .catch((error) => {
          console.log(error);
          swalFire('Não foi possível encontrar a reserva.', '', 'error');
          onClose();
        });
    },
    [onClose, reservationId]
  );

  const title = useMemo(() => {
    if (!reservation) return 'Reserva';
    return `Reserva #${reservation.numberId}`;
  }, [reservation]);

  const monthlyInstallment = useMemo(() => {
    if (!reservation) return null;
    return installmentCalculator.calculateMonthlyInstallment(
      reservation.installments
    );
  }, [reservation]);

  const annuallyInstallment = useMemo(() => {
    if (!reservation) return null;
    return installmentCalculator.calculateAnnuallyInstallment(
      reservation.entrance
    );
  }, [reservation]);

  useEffect(() => {
    if (open && reservationId) {
      loadReservation(reservationId);
    } else {
      setReservation(null);
    }
  }, [open, reservationId, loadReservation]);

  useEffect(() => {
    socket.onPropertyUpdated((property) => {
      setUpdatedPropertyId(property._id);
    });

    socket.onPropertyDeleted((propertyId) => {
      setUpdatedPropertyId(propertyId);
    });

    socket.onReservationUpdated((newReservation) => {
      setUpdatedReservationId(newReservation._id);
    });

    return () => socket.destroy();
  }, []);

  useEffect(() => {
    if (updatedReservationId) {
      if (reservation && reservation?._id === updatedReservationId) {
        loadReservation(reservationId);
      }

      setUpdatedReservationId(null);
    }
  }, [reservation, updatedReservationId, loadReservation, reservationId]);

  useEffect(() => {
    if (updatedPropertyId) {
      if (reservation && reservation?.propertyId === updatedPropertyId) {
        loadReservation(reservationId);
      }

      setUpdatedPropertyId(null);
    }
  }, [reservation, updatedPropertyId, loadReservation, reservationId]);

  if (!reservation) {
    return (
      <BaseDialog open={open} title="Reserva" onClose={onClose} maxWidth="sm">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height={200}
        >
          <CircularProgress />
        </Box>
      </BaseDialog>
    );
  }

  return (
    <BaseDialog open={open} title={title} onClose={onClose} maxWidth="sm">
      {reservation && (
        <>
          <Box mb={3}>
            <Tabs
              variant="fullWidth"
              value={tabIndex}
              indicatorColor="primary"
              textColor="primary"
              centered
              onChange={(e, value) => setTabIndex(value)}
            >
              <Tab label="Geral" />
              <Tab label="Pagamento" />
              <Tab label="Documentos " />
            </Tabs>
          </Box>

          <GeneralTab reservation={reservation} tabIndex={tabIndex} />

          <PaymentTab
            reservation={reservation}
            monthlyInstallment={monthlyInstallment}
            annuallyInstallment={annuallyInstallment}
            tabIndex={tabIndex}
          />

          <DocumentTab tabIndex={tabIndex} reservation={reservation} />

          {tabIndex !== 2 && (
            <EditButton
              isContractCreated={reservation.isContractCreated}
              onEdit={onEdit}
            />
          )}
        </>
      )}
    </BaseDialog>
  );
}

ReadReservationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  reservationId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onEdit: PropTypes.func,
};

ReadReservationDialog.defaultProps = {
  reservationId: null,
  onEdit: null,
};
