import React from 'react';
import { Box, IconButton, Grid } from '@material-ui/core';
import ICON_TYPES from '@constants/iconsPaths';
import RemittanceModule from 'modules/RemittanceModule';
import preventDefault from 'utils/preventDefault';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';

export default function RemittanceListActions({ remittance }) {
  const downloadRemittance = preventDefault(() => {
    RemittanceModule.downloadRemittance(remittance.projectId, remittance.name);
  });

  return (
    <Grid item xs={12} lg="auto">
      <Box display="flex" justifyContent="flex-end">
        <IconButton
          onClick={downloadRemittance}
          title="Baixar remessa"
          size="small"
        >
          <Icon src={ICON_TYPES.LIST.export} size={20} />
        </IconButton>
      </Box>
    </Grid>
  );
}

RemittanceListActions.propTypes = {
  remittance: PropTypes.shape({
    projectId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};
