const GET_INVOICES_SUMMARY_QUERY = `
  query getInvoicesSummary (
    $projectId: ID!
    $search: String
    $filterOptions: SalesContractInvoiceFilterInput
  ) {
    getInvoicesSummary (
      projectId: $projectId
      search: $search
      filterOptions: $filterOptions
    ) {
      projectId
      totalSaleItens
      totalAmountPaid
      totalPrice
    }
  }
`;

export default GET_INVOICES_SUMMARY_QUERY;
