import { Box, Container, Grid } from '@material-ui/core';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { ProjectContext } from 'providers/ProjectProvider';
import SalesContractInvoicesListProvider from 'providers/ListProviders/SalesContractInvoicesListProvider';
import Alert from 'modules/AlertModule';

import ProjectsLink from 'components/ProjectsLink';
import ReadNewRemittanceDialog from 'components/Dialogs/ReadDialogs/ReadNewRemittanceDialog';
import EditCustomerDialog from 'components/Dialogs/EditDialogs/EditCustomerDialog';
import SalesContractInvoicesList from 'components/Lists/SalesContractInvoicesList';
import ChangeSalesContractStatusDialog from 'components/Dialogs/EditDialogs/ChangeSalesContractStatusDialog';
import SalesContractInvoicesListFilters from 'components/ListFilters/SalesContractInvoicesListFilters';
import EditSalesContractInvoiceDialog from 'components/Dialogs/EditDialogs/EditSalesContractInvoiceDialog';
import SalesContractInvoicesListZeroState from 'components/ZeroState/SalesContractInvoicesListZeroState';

import getEmittedSalesContractInvoicesQuery from 'queries/getEmittedSalesContractInvoicesQuery';
import useQuery from 'hooks/useQuery';
import getSalesContractQuery from 'queries/getSalesContractQuery';

import SalesContractPopup from './components/SalesContractPopup';
import { useStyles } from './styles';
import NewInvoiceModal from './modals/NewInvoiceModal';
import PriceAdjustmentModal from './modals/PriceAdjustmentModal';
import validateCustomer from './helpers/validateCustomer';
import ContractDetails from './components/ContractDetails';
import ExtraActions from './components/ExtraActions';
import useSalesContractWebsocket from './hooks/useSalesContractWebsocket';
import validateCustomerAlert from './helpers/validateCustomerAlert';
import usePrintInvoices from './hooks/usePrintInvoices';
import useEmitInvoices from './hooks/useEmitInvoices';

export default function SalesContract() {
  const classes = useStyles();
  const { numberId } = useParams();
  const history = useHistory();
  const { project } = useContext(ProjectContext);

  const [executeGetEmittedInvoices] = useQuery(
    getEmittedSalesContractInvoicesQuery
  );
  const [executeGetSalesContract] = useQuery(getSalesContractQuery);
  const [contract, setContract] = useState(null);
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const selectMethods = useRef(null);
  const [hasEmittedInvoices, setHasEmittedInvoices] = useState(false);
  const [showNewInvoiceModal, setShowNewInvoiceModal] = useState(false);
  const [showPriceAdjustmentModal, setShowPriceAdjustmentModal] =
    useState(false);
  const [editInvoiceId, setEditInvoiceId] = useState(null);
  const [newRemittance, setNewRemittance] = useState(null);
  const [invalidCustomerId, setInvalidCustomerId] = useState(null);
  const [openEditCustomerDialog, setOpenEditCustomerDialog] = useState(false);
  const [editSalesContractStatusData, setEditSalesContractStatusData] =
    useState({
      openDialog: false,
      contractNumberId: null,
      status: null,
    });

  const getSalesContract = (numberIdParam) => {
    const variables = {
      projectId: project._id,
      numberId: Number(numberIdParam),
    };
    executeGetSalesContract(variables)
      .then(({ getSalesContract: salesContract }) => {
        if (salesContract.projectId !== project._id) {
          throw new Error('INVALID_PROJECT');
        }
        setContract(salesContract);
      })
      .catch(() => {
        Alert.error('Não foi possível localizar o contrato.').then(() => {
          history.push(`/${project.slug}/contratos`);
        });
      });
  };

  const handleOpenEditStatusDialog = () => {
    setEditSalesContractStatusData({
      openDialog: true,
      contractNumberId: contract.numberId,
      status: contract.status,
    });
  };

  const handleCloseEditStatusDialog = () => {
    setEditSalesContractStatusData({
      openDialog: false,
      contractNumberId: null,
      status: null,
    });
  };
  const getEmittedInvoices = () => {
    executeGetEmittedInvoices({ projectId: project._id }).then(
      ({ getEmittedSalesContractInvoices: emittedInvoices }) => {
        setHasEmittedInvoices(emittedInvoices.length > 0);
      }
    );
  };

  const deselectInvoices = () => {
    if (selectMethods.current) {
      selectMethods.current.handleDeselectAll();
    }
  };

  const isValidCustomer = useCallback(() => {
    const { customer } = contract;

    if (!validateCustomer(customer)) {
      validateCustomerAlert(customer.name).then(({ isConfirmed }) => {
        if (isConfirmed) {
          setInvalidCustomerId(customer._id);
          setOpenEditCustomerDialog(true);
        }
      });

      return false;
    }

    return true;
  }, [contract]);
  const executeEmitInvoices = useEmitInvoices(numberId);

  const emitInvoice = useCallback(
    (invoiceId, projectId) => {
      if (isValidCustomer()) {
        executeEmitInvoices([invoiceId], projectId).then(() =>
          deselectInvoices()
        );
      }
    },
    [isValidCustomer, executeEmitInvoices]
  );

  const emitInvoices = useCallback(
    (invoicesIds, projectId) => {
      if (isValidCustomer()) {
        executeEmitInvoices(invoicesIds, projectId).then(() =>
          deselectInvoices()
        );
      }
    },
    [isValidCustomer, executeEmitInvoices]
  );

  const executePrintInvoices = usePrintInvoices(
    project._id,
    contract?.numberId
  );

  const printInvoices = useCallback(
    (invoicesIds) => {
      if (isValidCustomer()) {
        executePrintInvoices(invoicesIds);
      }
    },
    [isValidCustomer, executePrintInvoices]
  );

  useSalesContractWebsocket({
    project,
    contract,
    onSalesContractUpdated: () => {
      getSalesContract(numberId);
    },
    onInvoiceEmitted: () => {
      getEmittedInvoices();
    },
    onRemittanceCreated: () => {
      getEmittedInvoices();
    },
  });

  useEffect(() => {
    if (project) {
      getSalesContract(numberId);
      getEmittedInvoices();
    }
  }, []);

  return (
    <Container className={classes.container}>
      <ProjectsLink />

      <Box mt={5}>
        <Grid container spacing={3}>
          <ContractDetails
            onEdit={handleOpenEditStatusDialog}
            contract={contract}
            project={project}
          />

          <Grid item xs={12} lg>
            <SalesContractInvoicesListProvider
              contractNumberId={Number(numberId)}
            >
              <SalesContractInvoicesListFilters
                extraActions={
                  <ExtraActions
                    showNewInvoiceModal={setShowNewInvoiceModal}
                    showPriceAdjustmentModal={setShowPriceAdjustmentModal}
                  />
                }
              />
              <SalesContractInvoicesListZeroState>
                <SalesContractInvoicesList
                  getSelectMethods={(methods) => {
                    selectMethods.current = methods;
                  }}
                  onSelect={(items) => {
                    setSelectedInvoices(() => [...items]);
                  }}
                  setEditInvoiceId={setEditInvoiceId}
                  emitInvoice={emitInvoice}
                  printInvoice={(invoice) => printInvoices([invoice._id])}
                />
              </SalesContractInvoicesListZeroState>
            </SalesContractInvoicesListProvider>
          </Grid>
        </Grid>
        <SalesContractPopup
          hideSelectedInvoicesPopup={!selectedInvoices.length}
          hideRemittancePopup={!hasEmittedInvoices}
          selectedInvoices={selectedInvoices}
          projectId={project._id}
          onDeselectInvoices={deselectInvoices}
          onCreate={setNewRemittance}
          emitInvoices={emitInvoices}
          printInvoices={printInvoices}
        />
        <ReadNewRemittanceDialog
          open={Boolean(newRemittance)}
          projectId={project._id}
          remittance={newRemittance}
          onClose={() => setNewRemittance(null)}
        />
        <NewInvoiceModal
          contract={contract}
          open={showNewInvoiceModal}
          onClose={() => setShowNewInvoiceModal(false)}
        />
        <EditSalesContractInvoiceDialog
          open={Boolean(editInvoiceId)}
          invoiceId={editInvoiceId}
          onClose={() => setEditInvoiceId(null)}
        />
        <EditCustomerDialog
          open={openEditCustomerDialog}
          onClose={() => {
            setOpenEditCustomerDialog(false);
            getSalesContract(numberId);
          }}
          customerId={invalidCustomerId}
          origin="remittance"
        />
        <PriceAdjustmentModal
          open={showPriceAdjustmentModal}
          contract={contract}
          onClose={() => setShowPriceAdjustmentModal(false)}
        />
        <ChangeSalesContractStatusDialog
          open={editSalesContractStatusData.openDialog}
          contractNumberId={editSalesContractStatusData.contractNumberId}
          defaultStatus={editSalesContractStatusData.status}
          onClose={handleCloseEditStatusDialog}
        />
      </Box>
    </Container>
  );
}
