import BaseDialog from 'components/Dialogs/BaseDialog';
import React from 'react';
import { Box, Button, Grid } from '@material-ui/core';
import api from 'services/api';
import AlertModule from 'modules/AlertModule';
import PaymentData from './components/PaymentData';
import RealEstateAgentData from './components/RealEstateAgent';
import ReservationCode from './components/ReservationCode';
import CustomerData from './components/CustomerData';

export default function ReadReceiptDialog({ open, reservation, onClose }) {
  if (!reservation) return null;
  const downloadReceipt = () => {
    AlertModule.wait();
    api
      .downloadReceipt(reservation)
      .then(() => AlertModule.close())
      .catch(AlertModule.onError('Erro ao baixar recibo'));
  };
  return (
    <BaseDialog maxWidth="md" open={open} onClose={onClose}>
      <ReservationCode numberId={reservation.numberId} />

      <Box marginLeft={10} marginBottom={4}>
        <Grid container>
          <PaymentData reservation={reservation} />
          <RealEstateAgentData reservation={reservation} />
          <CustomerData customer={reservation.customer} />
        </Grid>
      </Box>
      <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button variant="contained" color="primary" onClick={downloadReceipt}>
          Baixar recibo
        </Button>
      </Box>
    </BaseDialog>
  );
}
