import detailIcons from './detailIconsPaths';
import baseIcons from './baseIconsPaths';
import listIcons from './listIconsPaths';
import popUpIcons from './popUpIconsPaths';
import summaryIcons from './summaryIconsPaths';
import reservationIcons from './reservationPaths';
import fileTypesIcons from './fileTypesPaths';

const ICON_TYPES = {
  DETAILS: detailIcons,
  BASE: baseIcons,
  LIST: listIcons,
  POPUP: popUpIcons,
  SUMMARY: summaryIcons,
  RESERVATION: reservationIcons,
  FILE_TYPES: fileTypesIcons,
};

export default ICON_TYPES;
