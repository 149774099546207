import palette from './palette';
import typography from './typography';

const overrides = {
  MuiCssBaseline: {
    '@global': {
      body: {
        color: palette.text.primary,
      },

      '.swal2-popup': {
        fontFamily: `${typography.fontFamily} !important`,
      },

      '.swal2-styled.swal2-confirm': {
        backgroundColor: `${palette.primary.main} !important`,
      },

      '.swal2-styled.swal2-cancel': {
        backgroundColor: `${palette.secondary.main} !important`,
      },
    },
  },

  MuiButton: {
    root: {
      '&.MuiButton-primary': {
        color: 'white',
      },
    },

    text: {
      fontSize: '16px',
      fontWeight: '700',
      textTransform: 'none',
      borderRadius: '25px',
      padding: '11px 40px',
    },

    textSizeSmall: {
      fontSize: '14px',
      fontWeight: '700',
      textTransform: 'none',
      borderRadius: '20px',
      padding: '7px 30px',
    },

    textSizeLarge: {
      fontSize: '16px',
      fontWeight: '700',
      textTransform: 'none',
      borderRadius: '30px',
      padding: '16px 40px',
    },

    contained: {
      fontSize: '16px',
      fontWeight: '700',
      textTransform: 'none',
      borderRadius: '25px',
      padding: '11px 40px',
      boxShadow: 'none',

      '&:hover': {
        boxShadow: 'none',
      },

      '&:active': {
        boxShadow: 'none',
      },
    },

    containedPrimary: {
      color: 'white',
    },

    containedSizeSmall: {
      fontSize: '14px',
      fontWeight: '500',
      textTransform: 'none',
      borderRadius: '20px',
      padding: '0px 16px',
      height: 30,
    },

    containedSizeLarge: {
      fontSize: '12px',
      fontWeight: '500',
      textTransform: 'none',
      borderRadius: '30px',
      padding: '0px 30px',
      height: 50,
    },

    outlined: {
      fontSize: '14px',
      fontWeight: '500',
      textTransform: 'none',
      borderRadius: '25px',
      padding: '0px 20px',
      height: 40,
    },

    outlinedSizeSmall: {
      fontSize: '14px',
      fontWeight: '500',
      textTransform: 'none',
      borderRadius: '20px',
      padding: '0px 10px',
      height: 30,
    },

    outlinedSizeLarge: {
      fontSize: '16px',
      fontWeight: '500',
      textTransform: 'none',
      borderRadius: '30px',
      padding: '0px 30px',
      height: 50,
    },
  },

  MuiFormControlLabel: {
    root: {
      color: palette.text.secondary,
    },
  },

  MuiTooltip: {
    tooltip: {
      backgroundColor: palette.grey[700],
      color: palette.secondary.dark,
    },
  },
};

export default overrides;
