export const GET_SALES_CONTRACT_INVOICE_QUERY = `
  query GetSalesContractInvoice ($id: ID!) {
    getSalesContractInvoice (id: $id) {
      _id
      projectId
      contractNumberId
      type
      description
      paymentMethod
      value
      fees
      discount
      dueDate
      status
      paidAt
      paidValue
      createdAt  
    }
  }
`;

export default {};
