import React from 'react';
import { Typography } from '@material-ui/core';
import RemittanceListActions from '../components/RemittanceListActions';

export default () => [
  {
    title: 'Arquivo',
    field: 'fileName',
    render: (rowData) => (
      <Typography color="primary">{rowData.name}</Typography>
    ),
  },
  {
    title: 'Quant. de títulos',
    field: 'invoicesCount',
    render: (rowData) => rowData.invoicesIds.length,
  },
  {
    title: 'Data de criação',
    field: 'createdAt',
    render: (rowData) =>
      new Date(rowData.createdAt).toLocaleDateString('pt-BR', {
        hour: 'numeric',
        minute: 'numeric',
      }),
  },
  {
    title: '',
    field: '_actions',
    render: (rowData) => <RemittanceListActions remittance={rowData} />,
  },
];
