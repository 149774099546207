import React from 'react';

function ProjectZeroStateImage() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="175"
      height="160"
      fill="none"
      viewBox="0 0 175 160"
    >
      <g clipPath="url(#clip0_2048_37832)">
        <path
          fill="#F0F2F4"
          d="M138.541 12.015c-20.425-11.673-38.515-2.063-47.564 8.17-9.049 10.234-15.791 20.57-27.151 22.468-11.36 1.898-28.595-2.917-41.436 6.128-12.842 9.045-12.792 33.068-2.625 46.69 10.167 13.62 36.473 20.132 66.822 24.51a80.02 80.02 0 0032.643-1.812l13.786-5.005c6.734-3.074 12.379-6.536 17.157-9.346 7.271-4.292 9.833-14.752 11.224-22.414a92.323 92.323 0 00-.516-34.661c-3.045-14.385-9.99-27.676-22.34-34.728z"
        />
        <path
          fill="#FF6C4E"
          d="M92.29 153.037c46.106 0 83.483-1.389 83.483-3.103 0-1.714-37.377-3.103-83.484-3.103-46.106 0-83.483 1.389-83.483 3.103 0 1.714 37.377 3.103 83.483 3.103z"
        />
        <path
          fill="#fff"
          d="M92.29 153.037c46.106 0 83.483-1.389 83.483-3.103 0-1.714-37.377-3.103-83.484-3.103-46.106 0-83.483 1.389-83.483 3.103 0 1.714 37.377 3.103 83.483 3.103z"
          opacity="0.8"
        />
        <path
          fill="#535B6A"
          d="M132.413 33.295a.617.617 0 00-.619.619v3.173h-11.31v-3.173a.619.619 0 10-1.238 0v79.927a.619.619 0 001.238 0v-17.88h11.31v17.88a.619.619 0 001.238 0v-79.94a.62.62 0 00-.619-.606zm-.619 61.44h-11.31v-4.01h11.31v4.01zm0-5.252h-11.31v-4.007h11.31v4.007zm0-5.249h-11.31V80.24h11.31v3.994zm0-5.244h-11.31v-3.978h11.31v3.978zm0-5.24h-11.31v-3.933h11.31v3.932zm0-5.225h-11.31v-3.957h11.31v3.957zm0-5.199h-11.31v-3.994h11.31v3.994zm0-5.248h-11.31v-3.995h11.31v3.995zm0-5.237h-11.31v-4.006h11.31v4.006zm0-5.248h-11.31v-4.015h11.31v4.015zm0-5.253h-11.31v-4.015h11.31v4.015z"
        />
        <path
          fill="#FF6C4E"
          d="M25.278 97.733s9.263-12.68 4.745-15.68c-4.7-3.1-6.907 6.288-7.902 10.897-2.12 9.837-1.523 11.925-.099 9.763l3.256-4.98z"
        />
        <path
          fill="#fff"
          fillOpacity="0.3"
          d="M25.278 97.733s9.263-12.68 4.745-15.68c-4.7-3.1-6.907 6.288-7.902 10.897-2.12 9.837-1.523 11.925-.099 9.763l3.256-4.98z"
          opacity="0.5"
        />
        <path
          fill="#FF6C4E"
          d="M28.752 105.531s12.879-8.987 9.573-13.27c-3.437-4.46-8.574 3.713-11.013 7.741-5.203 8.615-5.31 10.786-3.264 9.201l4.704-3.672z"
        />
        <path
          fill="#fff"
          fillOpacity="0.3"
          d="M28.752 105.531s12.879-8.987 9.573-13.27c-3.437-4.46-8.574 3.713-11.013 7.741-5.203 8.615-5.31 10.786-3.264 9.201l4.704-3.672z"
          opacity="0.5"
        />
        <path
          fill="#FF6C4E"
          d="M29.033 115.955s11.14-5.005 9.238-8.76c-1.98-3.908-7.093 1.588-9.54 4.32-5.228 5.835-5.624 7.477-3.829 6.565l4.13-2.125z"
        />
        <path
          fill="#fff"
          fillOpacity="0.3"
          d="M29.033 115.955s11.14-5.005 9.238-8.76c-1.98-3.908-7.093 1.588-9.54 4.32-5.228 5.835-5.624 7.477-3.829 6.565l4.13-2.125z"
          opacity="0.5"
        />
        <path
          fill="#FF6C4E"
          d="M29.693 126.424s11.141-5.005 9.243-8.76c-1.985-3.904-7.093 1.588-9.544 4.324-5.224 5.835-5.624 7.477-3.825 6.561l4.126-2.125z"
        />
        <path
          fill="#fff"
          fillOpacity="0.3"
          d="M29.693 126.424s11.141-5.005 9.243-8.76c-1.985-3.904-7.093 1.588-9.544 4.324-5.224 5.835-5.624 7.477-3.825 6.561l4.126-2.125z"
          opacity="0.5"
        />
        <path
          fill="#FF6C4E"
          d="M16.625 103.031s-12.247-9.833-15.428-5.46c-3.302 4.539 5.966 7.193 10.526 8.398 9.73 2.57 11.842 2.063 9.746.544l-4.844-3.482z"
        />
        <path
          fill="#fff"
          fillOpacity="0.3"
          d="M16.625 103.031s-12.247-9.833-15.428-5.46c-3.302 4.539 5.966 7.193 10.526 8.398 9.73 2.57 11.842 2.063 9.746.544l-4.844-3.482z"
          opacity="0.5"
        />
        <path
          fill="#FF6C4E"
          d="M16.988 111.247S3.033 104.042.784 108.965c-2.34 5.121 7.275 5.863 11.966 6.136 10.048.586 12.02-.33 9.664-1.403l-5.426-2.451z"
        />
        <path
          fill="#fff"
          fillOpacity="0.3"
          d="M16.988 111.247S3.033 104.042.784 108.965c-2.34 5.121 7.275 5.863 11.966 6.136 10.048.586 12.02-.33 9.664-1.403l-5.426-2.451z"
          opacity="0.5"
        />
        <path
          fill="#FF6C4E"
          d="M18.676 120.455S6.709 114.678 4.923 118.9c-1.861 4.386 6.284 4.79 10.274 4.91 8.509.264 10.155-.561 8.137-1.386l-4.658-1.969z"
        />
        <path
          fill="#fff"
          fillOpacity="0.3"
          d="M18.676 120.455S6.709 114.678 4.923 118.9c-1.861 4.386 6.284 4.79 10.274 4.91 8.509.264 10.155-.561 8.137-1.386l-4.658-1.969z"
          opacity="0.5"
        />
        <path
          fill="#FF6C4E"
          d="M19.262 127.896s-11.29-7.015-13.514-3.008c-2.315 4.163 5.744 5.43 9.697 5.975 8.434 1.163 10.154.524 8.252-.537l-4.435-2.43z"
        />
        <path
          fill="#fff"
          fillOpacity="0.3"
          d="M19.262 127.896s-11.29-7.015-13.514-3.008c-2.315 4.163 5.744 5.43 9.697 5.975 8.434 1.163 10.154.524 8.252-.537l-4.435-2.43z"
          opacity="0.5"
        />
        <path
          fill="#FF6C4E"
          d="M18.193 90.573S16.072 72.158 9.115 74.04c-7.225 1.964-1.176 11.282 1.894 15.787 6.524 9.59 8.925 10.646 8.41 7.65l-1.226-6.903z"
        />
        <path
          fill="#fff"
          fillOpacity="0.3"
          d="M18.193 90.573S16.072 72.158 9.115 74.04c-7.225 1.964-1.176 11.282 1.894 15.787 6.524 9.59 8.925 10.646 8.41 7.65l-1.226-6.903z"
          opacity="0.5"
        />
        <path
          stroke="#FF6C4E"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.538"
          d="M10.539 80.748s10.588 19.839 12.23 29.049c1.89 10.58 2.195 22.398.891 31.657M21.098 103.671l5.186-15.887M22.769 109.797l9.763-12.275M22.146 107.566L8.01 101.628M23.994 118.113l10.63-8.145M23.429 114.617l-11.104-2.31M23.961 123.121l-8.599-1.361M24.688 128.626l5.785-5.183M24.572 130.958l-9.882-2.484"
        />
        <path
          fill="#FF6C4E"
          d="M17.252 133.981h11.682v2.418h-1.02l-2.108 13.192H20.38l-2.109-13.192h-1.019v-2.418z"
        />
        <path
          fill="#fff"
          fillOpacity="0.3"
          d="M17.252 133.981h11.682v2.418h-1.02l-2.108 13.192H20.38l-2.109-13.192h-1.019v-2.418z"
          opacity="0.5"
        />
        <path
          fill="#263238"
          d="M36.49 9.413a3.804 3.804 0 00-3.788-.755c-2.27.755-4.445 4.035-3.896 6.812.384 1.923 2.166 4.758 2.707 5.839.54 1.081-.219 2.909-1.935 6.705-1.717 3.796-2.381 7.357 2.595 10.493 4.977 3.136 10.61-1.84 13.497-5.41 2.889-3.569-2.166-11.788-2.6-15.267-.432-3.478-1.175-10.146-6.58-8.417z"
        />
        <path
          fill="#FF6C4E"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.43"
          d="M48.187 33.336a11.916 11.916 0 01-9.531 3.441l-1.193 4.972 2.27 11.352 14.817-7.68-3.355-8.664-3.008-3.421z"
        />
        <path
          fill="#263238"
          d="M48.187 33.336a11.916 11.916 0 01-9.531 3.441l-1.193 4.972 2.27 11.352 14.817-7.68-3.355-8.664-3.008-3.421z"
          opacity="0.3"
        />
        <path
          fill="#fff"
          d="M48.187 33.337l-2.27-15.036-6.725 1.378-3.355 2.162s-4.002 3.14-4.002 3.462c0 .322 6.77 11.653 6.77 11.653a12.552 12.552 0 009.582-3.619z"
        />
        <path
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.43"
          d="M48.187 33.337l-2.27-15.036-6.725 1.378-3.355 2.162s-4.002 3.14-4.002 3.462c0 .322 6.77 11.653 6.77 11.653a12.552 12.552 0 009.582-3.619z"
        />
        <path
          fill="#FF6C4E"
          d="M39.163 32.694s4.741-3.516 4.304-2.476c-.438 1.04-3.714 3.635-4.593 3.713-.879.079.29-1.237.29-1.237z"
          opacity="0.5"
        />
        <path
          fill="#263238"
          d="M39.307 26.017l.186.194 1.156 1.221.297 4.692 1.415-.223-1.118-4.395 1.79 3.722 1.49-1.49-3.202-2.904-.078-2.307-1.638-.82"
        />
        <path
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.43"
          d="M36.704 20.447s-1.407.648-1.407 1.943c0 1.296 1.522 4.378 2.475 5.026.953.648 2.587-2.319 2.587-2.319s2.476 1.188 3.136.107c.66-1.08-1.514-4.65-2.917-5.624-1.403-.974-3.874.867-3.874.867z"
        />
        <path
          fill="#fff"
          d="M35.516 19.147c.387.989.712 2 .973 3.029.215 1.08 3.136 2.921 3.458 3.029.322.107.54-3.462.54-3.462l-.862-4.218-4.11 1.622z"
        />
        <path
          fill="#FF6C4E"
          d="M39.716 17.974a4.187 4.187 0 00-.553-.116l-2.984 1.053-.392 1.023c.199.553.45 1.296.603 1.898.489.394 1.08.642 1.704.714.895.062 1.684-.631 2.24-1.482l-.618-3.09z"
          opacity="0.5"
        />
        <path
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.43"
          d="M35.516 19.147c.387.989.712 2 .973 3.029.215 1.08 3.136 2.921 3.458 3.029.322.107.54-3.462.54-3.462l-.862-4.218-4.11 1.622z"
        />
        <path
          fill="#fff"
          d="M33.568 17.306s-1.193-.97-1.193 0 1.085 1.514 1.948 1.514"
        />
        <path
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.43"
          d="M33.568 17.306s-1.193-.97-1.193 0 1.085 1.514 1.948 1.514"
        />
        <path
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.43"
          d="M39.947 14.171s.76-1.188.974.112a3.301 3.301 0 01-.648 2.377l-.326-2.489z"
        />
        <g opacity="0.5">
          <path
            fill="#FF6C4E"
            d="M33.568 17.306s-1.193-.97-1.193 0 1.085 1.514 1.948 1.514"
          />
          <path
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.43"
            d="M33.568 17.306s-1.193-.97-1.193 0 1.085 1.514 1.948 1.514"
          />
        </g>
        <path
          fill="#FF6C4E"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.43"
          d="M39.947 14.171s.76-1.188.974.112a3.301 3.301 0 01-.648 2.377l-.326-2.489z"
          opacity="0.5"
        />
        <path
          fill="#fff"
          d="M32.227 14.179s.928 3.866 2.92 5.777c1.994 1.91 4.127 1.927 4.866-1.478.606-2.806-.9-6.548-.9-6.548s-4.86-4.003-6.886 2.249z"
        />
        <path
          fill="#FF6C4E"
          d="M35.689 10.671l.755 1.898s1.188-.264 1.857 1.172c.668 1.436 1.34 3.54.957 5.17-.367 1.552-1.238 2.064-2.579 2.1 1.46.624 2.785 0 3.334-2.541.607-2.806-.9-6.548-.9-6.548s-1.646-1.35-3.424-1.25z"
          opacity="0.5"
        />
        <path
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.43"
          d="M32.227 14.179s.928 3.866 2.92 5.777c1.994 1.91 4.127 1.927 4.866-1.478.606-2.806-.9-6.548-.9-6.548s-4.86-4.003-6.886 2.249z"
        />
        <path
          fill="#263238"
          d="M35.916 16.22c.115.22.094.454-.046.529-.14.074-.346-.046-.458-.264-.111-.22-.095-.45.045-.524.14-.075.347.045.459.26zM38.07 15.553c.082.231.029.462-.12.512-.149.05-.338-.091-.413-.322-.074-.231-.029-.462.12-.512.149-.05.33.1.413.322z"
        />
        <path
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.215"
          d="M38.218 14.314a.928.928 0 00-1.123.066c-.594.462.529 2.777.529 2.777s.02.73-.916.392M38.218 18.3a1.064 1.064 0 01-1.65.413M35.705 14.93s-1.056-.995-1.518.924"
        />
        <path
          fill="#263238"
          d="M39.175 10.704c-1.16-1.007-2.096-.994-2.504-.862a2.77 2.77 0 00-1.267-.413c-3.243-.433-3.677 2.063-3.677 4.436 0 2.373-2.063 5.19-.647 5.517a1.692 1.692 0 001.947-.974s1.515-2.476 1.515-4.221 1.885-1.58 1.885-1.58a1.006 1.006 0 011.519-.174c.858.735.957 1.597 1.65 1.82.694.223 1.853-.891 1.853-.891a16.321 16.321 0 00-2.274-2.658z"
        />
        <path
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.215"
          d="M36.518 11.352s-1.304-1.048-2.476.128c-1.172 1.176-1.044 3.4-1.436 4.84-.392 1.44-1.44 1.7-1.44 1.7"
        />
        <path
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.215"
          d="M35.606 10.96s-1.407-1.394-3.177.372c-1.35 1.353-.747 2.24-1.01 4.464M37.174 10.695s2.476-1.176 3.532 1.832"
        />
        <path
          fill="#fff"
          d="M31.835 25.311l-3.743 15.062 10.976 3.11.54-1.947L32.4 38.21l2.447-8.665s-.322-1.622-2.063-3.458"
        />
        <path
          fill="#FF6C4E"
          d="M39.625 41.548l-7.209-3.326 2.447-8.666a6.65 6.65 0 00-.92-2.017l-.235-.178v2.394c0 1.53-1.915 6.412-2.587 7.559-.673 1.147-1.72 2.583-1.72 2.583l2.104-.957 7.976 3.194.144-.586z"
          opacity="0.5"
        />
        <path
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.43"
          d="M31.835 25.311l-3.743 15.062 10.976 3.11.54-1.947L32.4 38.21l2.447-8.665s-.322-1.622-2.063-3.458"
        />
        <path
          fill="#fff"
          d="M62.696 29.833l-8.31-1.745-8.489-9.809 1.255 7.89 5.207 4.96 10.465.478-.128-1.774z"
        />
        <path
          fill="#FF6C4E"
          d="M45.898 18.28l1.01 6.296.644 1.968.083.075c.243-.252 1.617-1.68 1.968-1.68.35 0 4.308 3.446 3.734 3.714l-.577.285 9.857 1.625.07-.755-8.293-1.741-8.496-9.788z"
          opacity="0.5"
        />
        <path
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.43"
          d="M62.696 29.833l-8.31-1.745-8.489-9.809 1.255 7.89 5.207 4.96 10.465.478-.128-1.774z"
        />
        <path
          fill="#535B6A"
          stroke="#263238"
          strokeMiterlimit="10"
          strokeWidth="0.43"
          d="M140.274 113.346L34.446 97.84a1.283 1.283 0 01-1.08-1.457l8.813-60.124a1.28 1.28 0 011.452-1.081l2.006.293a1.275 1.275 0 001.295-.628l2.662-4.6c.08-.145.19-.271.322-.372a2.95 2.95 0 012.158-.528l26.677 3.912a2.946 2.946 0 011.596.767c.118.118.21.258.273.413l2.372 5.876a1.275 1.275 0 001.003.788l65.464 9.598a1.283 1.283 0 011.085 1.452l-8.814 60.121a1.28 1.28 0 01-1.456 1.076z"
        />
        <path
          fill="#FF6C4E"
          d="M141.401 113.054H36.506a2.311 2.311 0 01-1.64-.679 2.324 2.324 0 01-.68-1.64V52.042a2.319 2.319 0 012.32-2.319h.284a2.332 2.332 0 002.158-1.46l1.597-4.016c.179-.463.505-.855.929-1.114a2.971 2.971 0 011.502-.412h26.961c.362 0 .722.065 1.06.194.471.19.864.532 1.115.974l2.744 4.675a2.319 2.319 0 002 1.147h64.545a2.32 2.32 0 012.319 2.319v58.705a2.31 2.31 0 01-.68 1.64 2.313 2.313 0 01-1.639.679z"
        />
        <path
          fill="#263238"
          d="M141.401 113.054H36.506a2.311 2.311 0 01-1.64-.679 2.324 2.324 0 01-.68-1.64V52.042a2.319 2.319 0 012.32-2.319h.284a2.332 2.332 0 002.158-1.46l1.597-4.016c.179-.463.505-.855.929-1.114a2.971 2.971 0 011.502-.412h26.961c.362 0 .722.065 1.06.194.471.19.864.532 1.115.974l2.744 4.675a2.319 2.319 0 002 1.147h64.545a2.32 2.32 0 012.319 2.319v58.705a2.31 2.31 0 01-.68 1.64 2.313 2.313 0 01-1.639.679z"
          opacity="0.1"
        />
        <path
          stroke="#263238"
          strokeMiterlimit="10"
          strokeWidth="0.43"
          d="M141.401 113.054H36.506a2.311 2.311 0 01-1.64-.679 2.324 2.324 0 01-.68-1.64V52.042a2.319 2.319 0 012.32-2.319h.284a2.332 2.332 0 002.158-1.46l1.597-4.016c.179-.463.505-.855.929-1.114a2.971 2.971 0 011.502-.412h26.961c.362 0 .722.065 1.06.194.471.19.864.532 1.115.974l2.744 4.675a2.319 2.319 0 002 1.147h64.545a2.32 2.32 0 012.319 2.319v58.705a2.31 2.31 0 01-.68 1.64 2.313 2.313 0 01-1.639.679v0z"
        />
        <path
          fill="#fff"
          d="M63.826 49.525H47.04a1.51 1.51 0 01-1.396-2.09 1.51 1.51 0 011.396-.935h16.786a1.514 1.514 0 011.07 2.584 1.509 1.509 0 01-1.07.44z"
        />
        <path
          fill="#535B6A"
          stroke="#263238"
          strokeMiterlimit="10"
          strokeWidth="0.43"
          d="M141.401 123.295H36.506a2.323 2.323 0 01-2.32-2.319V62.283a2.319 2.319 0 012.32-2.319h.284a2.328 2.328 0 002.158-1.46l1.597-4.011a2.24 2.24 0 01.929-1.114c.454-.27.973-.413 1.502-.413h26.961c.362 0 .722.065 1.06.194.472.19.865.535 1.115.978l2.744 4.679a2.32 2.32 0 002 1.147h64.545a2.32 2.32 0 012.319 2.319v58.693a2.32 2.32 0 01-2.319 2.319z"
        />
        <path
          fill="#FF6C4E"
          d="M71.868 37.422L55.214 35.36a1.518 1.518 0 01-1.316-1.687 1.514 1.514 0 011.691-1.312l16.65 2.063a1.514 1.514 0 011.13 2.247 1.509 1.509 0 01-1.501.752z"
        />
        <path
          fill="#fff"
          d="M71.868 37.422L55.214 35.36a1.518 1.518 0 01-1.316-1.687 1.514 1.514 0 011.691-1.312l16.65 2.063a1.514 1.514 0 011.13 2.247 1.509 1.509 0 01-1.501.752z"
          opacity="0.5"
        />
        <path
          fill="#FF6C4E"
          d="M63.826 59.77H47.04a1.51 1.51 0 01-1.51-1.514 1.514 1.514 0 011.51-1.514h16.786a1.514 1.514 0 110 3.029z"
        />
        <path
          fill="#fff"
          d="M63.826 59.77H47.04a1.51 1.51 0 01-1.51-1.514 1.514 1.514 0 011.51-1.514h16.786a1.514 1.514 0 110 3.029z"
          opacity="0.5"
        />
        <path
          fill="#fff"
          d="M144.83 77.616H33.073c-1.201 0-2.175.974-2.175 2.175v67.626c0 1.201.974 2.175 2.175 2.175H144.83a2.174 2.174 0 002.174-2.175V79.791a2.174 2.174 0 00-2.174-2.175z"
        />
        <path
          fill="#FF6C4E"
          d="M147.004 96.99a173.916 173.916 0 01-43.368 29.668c-23.458 11.22-50.27 18.953-65.893 22.935h107.082a2.191 2.191 0 001.541-.636 2.172 2.172 0 00.638-1.539V96.99z"
        />
        <path
          stroke="#263238"
          strokeMiterlimit="10"
          strokeWidth="0.43"
          d="M144.83 77.616H33.073c-1.201 0-2.175.974-2.175 2.175v67.626c0 1.201.974 2.175 2.175 2.175H144.83a2.174 2.174 0 002.174-2.175V79.791a2.174 2.174 0 00-2.174-2.175z"
        />
        <path
          stroke="#263238"
          strokeMiterlimit="10"
          strokeWidth="0.43"
          d="M32.577 83.938V81.05a2.179 2.179 0 012.179-2.175h4.725M32.577 127.805V87.458M145.321 141.57v4.568a2.191 2.191 0 01-.636 1.54 2.175 2.175 0 01-1.539.639h-3.809M145.321 82.923v55.251"
        />
        <path
          fill="#263238"
          stroke="#263238"
          strokeMiterlimit="10"
          strokeWidth="0.43"
          d="M106.211 103.934H71.686c-.622 0-1.126.504-1.126 1.126v17.615c0 .622.504 1.127 1.126 1.127h34.525c.622 0 1.127-.505 1.127-1.127V105.06c0-.622-.505-1.126-1.127-1.126z"
        />
        <path
          fill="#fff"
          stroke="#263238"
          strokeMiterlimit="10"
          strokeWidth="0.43"
          d="M106.211 103.934H71.686c-.622 0-1.126.504-1.126 1.126v3.788c0 .622.504 1.127 1.126 1.127h34.525c.622 0 1.127-.505 1.127-1.127v-3.788c0-.622-.505-1.126-1.127-1.126zM39.068 43.484l.755 1.407 1.605 1.155a.623.623 0 00.702.025l.14-.09a.491.491 0 00.194-.62l-.912-2.096.615.479a.475.475 0 00.656-.075.478.478 0 000-.598L41.39 41.26l-1.79.276-.533 1.948z"
        />
        <path
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.215"
          d="M42.286 45.98l-1.436-1.886M41.445 46.047l-1.127-1.494"
        />
        <path
          fill="#fff"
          stroke="#263238"
          strokeMiterlimit="10"
          strokeWidth="0.43"
          d="M62.696 29.834l2.244.132 2.732 1.745s.153.533-.227.607c-.38.074-2.43-.68-2.43-.68l-2.192-.03-.127-1.774z"
        />
        <path
          fill="#fff"
          d="M62.811 31.331l2.356 1.065s.38-.532-.153-.76l-.775-.531"
        />
        <path
          stroke="#263238"
          strokeLinejoin="round"
          strokeWidth="0.43"
          d="M62.811 31.331l2.356 1.065s.38-.532-.153-.76l-.775-.531"
        />
        <path
          fill="#fff"
          d="M146.051 71.572l-.974-2.913-1.093-1.498a.15.15 0 00-.248-.002.15.15 0 00-.025.092v4.316l.826 1.086 1.514-1.081z"
        />
        <path
          fill="#fff"
          d="M161.921 84.31l-8.17.288a1.588 1.588 0 01-1.469-.862l-6.231-12.165s-1.403.76-1.51 1.081c-.107.322 4.613 12.16 6.061 15.796a1.594 1.594 0 001.54.999l9.131-.388"
        />
        <path
          stroke="#263238"
          strokeMiterlimit="10"
          strokeWidth="0.43"
          d="M161.921 84.31l-8.17.288a1.588 1.588 0 01-1.469-.862l-6.231-12.165s-1.403.76-1.51 1.081c-.107.322 4.613 12.16 6.061 15.796a1.594 1.594 0 001.54.999l9.131-.388"
        />
        <path
          stroke="#263238"
          strokeMiterlimit="10"
          strokeWidth="0.322"
          d="M146.649 73.086l-1.279.528"
        />
        <g opacity="0.5">
          <g opacity="0.5">
            <path
              fill="#FF6C4E"
              d="M161.921 84.31l-8.17.288a1.588 1.588 0 01-1.469-.862l-6.231-12.165s-1.403.76-1.51 1.081c-.107.322 4.613 12.16 6.061 15.796a1.594 1.594 0 001.54.999l9.131-.388"
            />
            <path
              stroke="#263238"
              strokeMiterlimit="10"
              strokeWidth="0.43"
              d="M161.921 84.31l-8.17.288a1.588 1.588 0 01-1.469-.862l-6.231-12.165s-1.403.76-1.51 1.081c-.107.322 4.613 12.16 6.061 15.796a1.594 1.594 0 001.54.999l9.131-.388"
            />
          </g>
          <path
            stroke="#263238"
            strokeMiterlimit="10"
            strokeWidth="0.322"
            d="M146.65 73.084l-1.279.528"
            opacity="0.5"
          />
        </g>
        <path
          fill="#FF6C4E"
          d="M146.051 71.572l-.974-2.913-1.093-1.498a.15.15 0 00-.248-.002.15.15 0 00-.025.092v4.316l.826 1.086 1.514-1.081z"
        />
        <path
          fill="#fff"
          d="M146.051 71.572l-.974-2.913-1.093-1.498a.15.15 0 00-.248-.002.15.15 0 00-.025.092v4.316l.826 1.086 1.514-1.081z"
          opacity="0.5"
        />
        <path
          stroke="#263238"
          strokeMiterlimit="10"
          strokeWidth="0.43"
          d="M146.051 71.572l-.974-2.913-1.093-1.498a.15.15 0 00-.248-.002.15.15 0 00-.025.092v4.316l.826 1.086 1.514-1.081z"
        />
        <path
          fill="#fff"
          d="M164.45 76.622a4.436 4.436 0 00-.825 1.688c-.12.792.07 3.842.07 3.842l-4.25.478s.322-3.099.338-3.614a4.593 4.593 0 012.249-3.054c1.461-.718 2.703-.367 2.418.66z"
        />
        <path
          fill="#FF6C4E"
          d="M164.421 76.655a88.831 88.831 0 00-2.501-.636 4.62 4.62 0 00-2.15 2.992c0 .198-.057.776-.115 1.411.413-.095 1.069-.239 1.556-.326a6.82 6.82 0 001.134-.284l-.144 2.475h.095l1.386-.156s-.19-3.05-.07-3.842a4.331 4.331 0 01.809-1.634z"
          opacity="0.5"
        />
        <path
          stroke="#263238"
          strokeMiterlimit="10"
          strokeWidth="0.431"
          d="M164.45 76.622a4.436 4.436 0 00-.825 1.688c-.12.792.07 3.842.07 3.842l-4.25.478s.322-3.099.338-3.614a4.593 4.593 0 012.249-3.054c1.461-.718 2.703-.367 2.418.66z"
        />
        <path
          fill="#fff"
          d="M165.333 77.147c3.524-4.655-4.972-9.49-7.204-4.865a8.723 8.723 0 00-.735 1.799c-.338.206-1.238.76-1.403.912-.165.152.413.544.731.738-.607 1.49-.516 3.058-.343 3.413.302.61 1.787.619 2.765.437.978-.181 1.366-1.118 1.65-1.374.285-.256 1.705.07 2.204.219.499.148 1.65-.36 2.335-1.28z"
        />
        <path
          fill="#FF6C4E"
          d="M161.846 76.701s.074.35-.635.636c-.71.284-.85.07-1.061.565a1.762 1.762 0 01-1.415 1.275c-.672.128-1.352.214-2.034.26.561.314 1.675.289 2.475.144.974-.177 1.366-1.118 1.651-1.374.285-.256 1.704.07 2.203.219a.91.91 0 00.355 0 3.85 3.85 0 00.33-1.312l-1.869-.413z"
          opacity="0.5"
        />
        <path
          stroke="#263238"
          strokeMiterlimit="10"
          strokeWidth="0.43"
          d="M165.333 77.147c3.524-4.655-4.972-9.49-7.204-4.865a8.723 8.723 0 00-.735 1.799c-.338.206-1.238.76-1.403.912-.165.152.413.544.731.738-.607 1.49-.516 3.058-.343 3.413.302.61 1.787.619 2.765.437.978-.181 1.366-1.118 1.65-1.374.285-.256 1.705.07 2.204.219.499.148 1.65-.36 2.335-1.28z"
        />
        <path
          fill="#263238"
          d="M158.451 74.489c-.091.198-.244.322-.343.276-.099-.045-.107-.243 0-.441s.243-.322.343-.276c.099.045.09.243 0 .441z"
        />
        <path
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.215"
          d="M158.368 73.218s.413-.343.495.445M156.639 76.532s0 .413.743.413"
        />
        <path
          fill="#FF6C4E"
          stroke="#263238"
          strokeMiterlimit="10"
          strokeWidth="0.43"
          d="M155.632 103.534l-.326 4.036s-2.257 16.068-2.15 17.912c.108 1.845.351 20.9.351 20.9l2.719.433 3.054-21.135 4.287-21.477-7.935-.669z"
        />
        <path
          fill="#263238"
          d="M155.632 103.534l-.326 4.036s-2.257 16.068-2.15 17.912c.108 1.845.351 20.9.351 20.9l2.719.433 3.054-21.135 4.287-21.477-7.935-.669z"
          opacity="0.2"
        />
        <path
          stroke="#263238"
          strokeMiterlimit="10"
          strokeWidth="0.322"
          d="M159.074 118.212s-1.044 6.144-1.832 9.156c-.788 3.012-.784 6.016-.784 9.284 0 3.268-1.176 9.293-1.176 9.293"
        />
        <path
          fill="#FF6C4E"
          d="M161.149 102.545c1.791-.941 3.714-1.564 4.262-.314 1.09 2.501-.214 6.301-.214 6.301l.994 17.528 1.82 21.148-2.719-.438s-4.176-17.846-4.828-18.935c-.652-1.09-1.679-16.369-1.679-16.369l-2.064-1.878"
        />
        <path
          stroke="#263238"
          strokeMiterlimit="10"
          strokeWidth="0.43"
          d="M161.149 102.545c1.791-.941 3.714-1.564 4.262-.314 1.09 2.501-.214 6.301-.214 6.301l.994 17.528 1.82 21.148-2.719-.438s-4.176-17.846-4.828-18.935c-.652-1.09-1.679-16.369-1.679-16.369l-2.064-1.878"
        />
        <path
          fill="#263238"
          d="M161.149 102.545c1.791-.941 3.714-1.564 4.262-.314 1.09 2.501-.214 6.301-.214 6.301l.994 17.528 1.82 21.148-2.719-.438s-4.176-17.846-4.828-18.935c-.652-1.09-1.679-16.369-1.679-16.369l-2.064-1.878"
          opacity="0.2"
        />
        <path
          stroke="#263238"
          strokeMiterlimit="10"
          strokeWidth="0.322"
          d="M163.27 115.822c.062 4.065.474 6.021.796 7.093.392 1.308.26 3.792.26 4.952 0 1.159-.524-.26.656 5.104 1.18 5.364 1.96 12.817 1.96 12.817M163.27 106.964s.128-.265 0 5.884v.957"
        />
        <path
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.322"
          d="M157.233 105.795l-.087 1.84-.425 1.279.636.743M156.4 106.894s1.064 1.064 3.829.742"
        />
        <path
          fill="#263238"
          d="M162.932 105.972c2.063-.413 2.195-.982 2.909-1.461a5.952 5.952 0 00-.413-2.281c-.54-1.238-2.476-.628-4.262.313l-.867 1.37-4.65-.38-.149 1.824a15.192 15.192 0 007.432.615z"
        />
        <path
          fill="#fff"
          stroke="#263238"
          strokeMiterlimit="10"
          strokeWidth="0.322"
          d="M156.098 104.797l-.045.726 1.18.272.091-.953-1.226-.045z"
        />
        <path
          fill="#FF6C4E"
          d="M164.334 105.874l-.198-1.808.599-.198.301 1.605"
        />
        <path
          stroke="#263238"
          strokeMiterlimit="10"
          strokeWidth="0.43"
          d="M164.334 105.874l-.198-1.808.599-.198.301 1.605"
        />
        <path fill="#FF6C4E" d="M159.721 106.476l-.4-2.109h1.003l.4 1.808" />
        <path
          stroke="#263238"
          strokeMiterlimit="10"
          strokeWidth="0.43"
          d="M159.721 106.476l-.4-2.109h1.003l.4 1.808"
        />
        <g fill="#263238" opacity="0.2">
          <path
            d="M164.335 105.876l-.198-1.808.598-.198.301 1.605M159.721 106.478l-.4-2.109h1.003l.4 1.807"
            opacity="0.2"
          />
        </g>
        <path
          fill="#263238"
          stroke="#263238"
          strokeMiterlimit="10"
          strokeWidth="0.43"
          d="M155.1 87.54s-.343 2.29-1.37 6.19c-1.028 3.899-2.063 8.574-2.063 8.574l.573 2.402 1.713-2.518L155.1 87.54zM157.96 84.227s-2.402.57-2.629 1.712a9.502 9.502 0 00-.231 1.601l3.66-2.97 4.576-1.833-5.376 1.49z"
        />
        <path
          fill="#fff"
          d="M158.616 83.345s-1.952 2.207-2.728 3.115c-.776.908-.78 10.65-.78 12.082 0 1.432-.776 5.319.52 6.098 1.296.78 9.222.52 10.002-.779.78-1.3 1.296-16.881 1.168-18.309a3.965 3.965 0 00-2.212-2.987c-.903-.417-5.97.78-5.97.78z"
        />
        <path
          fill="#FF6C4E"
          d="M164.586 82.564a1.035 1.035 0 00-.338-.075l-.562.293a5.322 5.322 0 011.841 3.045 16.199 16.199 0 01-.07 5.237c-.281 1.91-4.812 0-3.962.71.85.71 4.032 1.91 4.176 2.545.145.636-1.576 1.696-2.195 1.77-.619.075.413 1.486 1.06 2.687.648 1.2.413 2.888-1.06 4.035-1.473 1.147-4.894 1.713-6.371 1.771-.479 0-.776.123-.867.264 2.27.507 8.695.177 9.392-.987.78-1.299 1.296-16.88 1.168-18.308a3.981 3.981 0 00-2.212-2.987z"
          opacity="0.5"
        />
        <path
          stroke="#263238"
          strokeMiterlimit="10"
          strokeWidth="0.43"
          d="M158.616 83.345s-1.952 2.207-2.728 3.115c-.776.908-.78 10.65-.78 12.082 0 1.432-.776 5.319.52 6.098 1.296.78 9.222.52 10.002-.779.78-1.3 1.296-16.881 1.168-18.309a3.965 3.965 0 00-2.212-2.987c-.903-.417-5.97.78-5.97.78z"
        />
        <path
          fill="#fff"
          d="M162.606 93.227c-2.092-.652-3.924-2.476-3.924-2.476a8.848 8.848 0 003.532 5.364"
        />
        <path
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.322"
          d="M162.606 93.227c-2.092-.652-3.924-2.476-3.924-2.476a8.848 8.848 0 003.532 5.364"
        />
        <path
          fill="#fff"
          stroke="#263238"
          strokeMiterlimit="10"
          strokeWidth="0.43"
          d="M164.198 81.136l-6.234 1.427-.388 1.82 7.01-1.82-.388-1.427z"
        />
        <path
          fill="#CC563E"
          stroke="#263238"
          strokeMiterlimit="10"
          strokeWidth="0.43"
          d="M156.589 149.539l-.363-2.74-2.719-.433-2.265 1.614-2.03 1.114v.445h7.377zM168.349 149.753l-.338-2.546-2.719-.437-2.266 1.613-2.03 1.118v.252h7.353z"
        />
        <path
          fill="#fff"
          d="M144.863 69.195l-1.676-.97s-.652-.078-.371.648l1.675 1.291"
        />
        <path
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.43"
          d="M144.863 69.195l-1.676-.97s-.652-.078-.371.648l1.675 1.291"
        />
        <path
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.43"
          d="M144.615 71.717a1.67 1.67 0 00-.343-.537l-.313-.338-1.704-2.113s-.528-.355-.413.413l1.056 2.063-1.877-1.762s-.355-.293-.355.12c0 .412 1.704 2.232 1.704 2.232l-1.881-.883s-.413.293-.116.586 1.878.883 1.878.883l-1.408-.058s-.412.351-.119.47c.293.12 1.939.467 1.939.467.209.178.448.317.706.413l.412.763 1.581-.796-.747-1.923z"
        />
        <path
          fill="#fff"
          d="M161.487 90.033l-9.416 2.208a2.117 2.117 0 01-2.339-1.316l-5.942-16.506 1.58-.796 6.565 12.16a1.468 1.468 0 001.585.747l7.2-2.034"
        />
        <path
          stroke="#263238"
          strokeMiterlimit="10"
          strokeWidth="0.43"
          d="M161.487 90.033l-9.416 2.208a2.117 2.117 0 01-2.339-1.316l-5.942-16.506 1.58-.796 6.565 12.16a1.468 1.468 0 001.585.747l7.2-2.034"
        />
        <path
          stroke="#263238"
          strokeMiterlimit="10"
          strokeWidth="0.322"
          d="M146.257 75.178l-1.832.916"
        />
        <path
          fill="#263238"
          stroke="#263238"
          strokeMiterlimit="10"
          strokeWidth="0.43"
          d="M160.316 72.331s-1.003 1.003-.504 1.754c.296.434.521.911.669 1.415 0 0-.413 1.003-.083 1.003.33 0 .165-.751.751-.582.586.17 1.168 1.168.669 1.502-.374.209-.77.375-1.181.495a.28.28 0 00-.196.237.28.28 0 00.139.275 3.97 3.97 0 001.819.66 6.383 6.383 0 001.585-.165s1.65-2.253 2.839-2.839c1.188-.586.668-3.256.668-3.59 0-.334-1.168-1.085-1.168-1.085s.335-.751-1.167-2.418c-1.502-1.667-4.341-2.005-4.424-1.42a.822.822 0 00.335.825s-2.505-1.084-3.673.083c-1.168 1.168 2.922 3.85 2.922 3.85z"
        />
        <path
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.215"
          d="M160.315 72.331s2.031 2.476 5.039 2.715M159.598 73.086s.945 2.748 4.852 3.532"
        />
      </g>
      <defs>
        <clipPath id="clip0_2048_37832">
          <path
            fill="#fff"
            d="M0 0H173.835V160H0z"
            transform="translate(.583)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ProjectZeroStateImage;
