import React, { useState } from 'react';
import { Box, Switch } from '@material-ui/core';
import PropTypes from 'prop-types';

import useQuery from 'hooks/useQuery';
import AlertModule from 'modules/AlertModule';
import TOGGLE_REAL_ESTATE_MUTATION from 'queries/toggleRealEstateMutation';

export default function RealEstateSwitch({ rowData }) {
  const [isChecked, setIsChecked] = useState(Boolean(rowData.active));
  const [executeToggleRealEstate] = useQuery(TOGGLE_REAL_ESTATE_MUTATION);

  const isDisabled = rowData.active === null;

  let actionTitle = isChecked ? 'Desativar acesso' : 'Ativar acesso';
  if (isDisabled) actionTitle = 'Adicione um e-mail para habilitar o acesso';

  const onChange = () => {
    setIsChecked((value) => !value);
    executeToggleRealEstate({ id: rowData._id }).catch(
      AlertModule.onError('Não foi possível modificar o acesso.')
    );
  };

  return (
    <Box title={actionTitle}>
      <Switch
        checked={isChecked}
        onClick={(e) => e.stopPropagation()}
        onChange={onChange}
        disabled={isDisabled}
        color="primary"
      />
    </Box>
  );
}

RealEstateSwitch.propTypes = {
  rowData: PropTypes.shape({
    active: PropTypes.bool,
    _id: PropTypes.string,
  }).isRequired,
};
