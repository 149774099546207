import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',

      '& > .div2': {
        position: 'relative',
        flex: 1,

        '& > .div3': {
          position: 'absolute',
          width: '100%',
          height: '100%',
          overflowY: 'auto',
          overflowX: 'hidden',
        },
      },
    },
  },
}));

export default {};
