import React from 'react';
import { Typography } from '@material-ui/core';

export default ({ auth }) => [
  {
    title: 'Título',
    field: 'title',
    render: (rowData) => (
      <Typography color="primary">{rowData.title}</Typography>
    ),
  },
  {
    title: 'Código',
    field: 'slug',
  },
  {
    title: 'Cargo',
    field: 'occupation',
    render: (rowData) =>
      rowData.ownerId === auth?.user?._id ? 'Proprietário' : 'Coordenador',
  },
  {
    title: 'Criado em',
    field: 'createdAt',
    align: 'center',
    fit: true,
    render: (rowData) =>
      new Date(rowData.createdAt).toLocaleDateString('pt-BR'),
  },
];
