import { Typography, useTheme } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';

function Copyright() {
  const theme = useTheme()

  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link to="#" style={{ color: theme.palette.primary.main }}>
        Quadra A
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default Copyright;