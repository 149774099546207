const exportFile = (name, data, contentType) => {
  const blob = new Blob([data], {
    type: `${contentType};charset=utf-8'`,
  });

  const [fileName, fileExt] = name.split('.');

  const url = URL.createObjectURL(blob);

  const elem = document.createElement('a');
  elem.style.display = 'hidden';
  elem.href = url;
  elem.download = `${fileName}.${fileExt}`;

  document.body.appendChild(elem);

  elem.click();

  document.body.removeChild(elem);
};

export const contentTypes = {
  TEXT: 'text/plan',
  CSV: 'text/csv',
  PDF: 'application/pdf',
};

export const exportCsvFile = (name, data) =>
  exportFile(name, data, contentTypes.CSV);

export const exportTextFile = (name, data) =>
  exportFile(name, data, contentTypes.TEXT);

export const exportPdfFile = (name, data) =>
  exportFile(name, data, contentTypes.PDF);

export default exportFile;
