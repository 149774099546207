import React from 'react';

import OccurrenceTypesStatusLabel from 'components/StatusLabel/OccurrenceTypesStatusLabel';

export default () => [
  {
    title: 'Contrato',
    field: 'contractNumberId',
    render: (rowData) =>
      rowData.contractNumberId ? `#${rowData.contractNumberId}` : '-',
  },
  {
    title: 'Cliente',
    field: 'customer',
    render: (rowData) =>
      rowData.customerName ? `#${rowData.customerName}` : '-',
  },
  {
    title: 'Detalhes',
    field: 'description',
    render: (rowData) =>
      rowData.description ? `#${rowData.description}` : '-',
  },

  {
    title: 'Situação',
    field: 'status',
    render: (rowData) => (
      <OccurrenceTypesStatusLabel
        status={rowData.status}
        message={rowData.message}
      />
    ),
  },
];
