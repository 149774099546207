import getCenterCoordinates from './get-center-coordinates'

const getScaledMapCenter = (container, map, scale) => {
    const center = getCenterCoordinates(container)
    const scaledMapWidth = map.width * scale
    const scaledMapHeight = map.height * scale

    return {
      x: center.x - (scaledMapWidth / 2),
      y: center.y - (scaledMapHeight / 2)
    }
}

export default getScaledMapCenter
