import React from 'react';
import { Box, Typography } from '@material-ui/core';
import Loading from 'components/Loading';
import PropertieZeroStateImage from 'assets/images/ZeroStates/PropertieZeroStateImage';
import PropTypes from 'prop-types';
import { usePropertiesListContext } from 'providers/ListProviders/PropertiesListProvider';

export default function PropertiesListZeroState({ children }) {
  const { properties, isLoading } = usePropertiesListContext();

  if (isLoading) {
    return <Loading height={100} />;
  }

  if (properties.length) {
    return children;
  }

  return (
    <Box width="100%" mt={15}>
      <Box display="flex" alignItems="center" justifyContent="center">
        <PropertieZeroStateImage />
      </Box>
      <Box justifyContent="center" alignItems="center" display="flex">
        <Box
          color="#495058"
          justifyContent="center"
          alignItems="center"
          display="flex"
          mt={3}
          width="20%"
        >
          <Typography align="center" display="block" variant="h3">
            Não encontramos nenhum imóvel
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

PropertiesListZeroState.propTypes = {
  children: PropTypes.node.isRequired,
};
