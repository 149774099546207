/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  DialogActions,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import constants from '@constants';
import UPDATE_CUSTOMER_MUTATION from 'queries/updateCustomerMutation';
import GET_CUSTOMER_QUERY from 'queries/getCustomerQuery';
import AlertModule from 'modules/AlertModule';
import viacep from 'services/viacep';
import { useHistory } from 'react-router-dom';

import PhoneTextField from 'components/TextFields/PhoneTextField';
import generateObjectChecksum from 'utils/generateObjectChecksum';
import CPFTextField from 'components/TextFields/CPFTextField';
import PostalCodeTextField from 'components/TextFields/PostalCodeTextField';
import EmailTextField from 'components/TextFields/EmailTextField';
import handlerError from 'utils/handleError';
import PropTypes from 'prop-types';
import validatePostalCode from 'utils/validatePostalCode';
import BaseDialog from 'components/Dialogs/BaseDialog';
import useQuery from 'hooks/useQuery';
import convertUTCtoLocal from 'utils/convertUTCtoLocal';
import validateInputs from './helpers/validate-inputs';
import ValidationFields from './ValidationFields';

function EditCustomerDialog({ open, customerId, onClose, onUpdate, origin }) {
  const history = useHistory();

  const [step, setStep] = useState(0);

  const [customer, setCustomer] = useState(null);
  const [customerChecksum, setCustomerChecksum] = useState(null);
  const [executeGetCustomer, loading] = useQuery(GET_CUSTOMER_QUERY);
  const [executeUpdateCustomer] = useQuery(UPDATE_CUSTOMER_MUTATION);
  function loadCustomer() {
    executeGetCustomer({ id: customerId })
      .then(({ getCustomer: data }) => {
        let birthday = '';
        if (data.birthday) {
          birthday = convertUTCtoLocal(data.birthday, 'UTC').split('T').shift();
        }
        setCustomer({ ...data, birthday });
        setCustomerChecksum(generateObjectChecksum({ ...data, birthday }));
      })
      .catch((err) => {
        handlerError(
          {
            NOT_AUTHENTICATED: () => {
              history.push('/login');
            },
            DEFAULT: AlertModule.onError(
              'Não foi possível encontrar o cliente.'
            ),
          },
          err.message
        );

        onClose();
      });
  }

  useEffect(() => {
    if (open) {
      setStep(0);
      setCustomer(null);
      loadCustomer();
    }
  }, [open]);

  function handleClose() {
    const checksum = generateObjectChecksum(customer);
    if (checksum !== customerChecksum) {
      AlertModule.confirmation(
        'Deseja realmente fechar sem salvar as alterações do cliente?'
      ).then((result) => {
        if (result.isConfirmed) {
          onClose();
        }
      });
    } else {
      onClose();
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    const validate = validateInputs(customer);

    if (!validate.success) {
      setStep(validate.step);
      AlertModule.error(validate.message);
      return;
    }

    const updatedCustomerData = {
      id: customerId,
      ...customer,
    };

    AlertModule.wait();

    executeUpdateCustomer(updatedCustomerData)
      .then(() => {
        onUpdate();
        AlertModule.success('Cliente atualizado com sucesso!').then(() => {
          onClose();
        });
      })
      .catch((err) => {
        handlerError(
          {
            NOT_AUTHENTICATED: () => {
              history.push('/login');
            },
            DEFAULT: AlertModule.onError(
              'Não foi possível atualizar as informações do cliente.'
            ),
          },
          err.message
        );
      });
  };

  const handleAddressSearch = () => {
    if (validatePostalCode(customer.postalCode)) {
      viacep.buscar(customer.postalCode).then((data) => {
        setCustomer({
          ...customer,
          address: data.logradouro,
          addressComplement: data.complemento,
          district: data.bairro,
          city: data.localidade,
          state: data.uf,
        });
      });
    }
  };

  return (
    <BaseDialog
      open={open}
      onClose={() => handleClose()}
      title="Cliente"
      maxWidth="sm"
      loading={loading}
    >
      {customer && (
        <form onSubmit={handleSubmit}>
          <Box style={{ overflow: 'visible' }}>
            <Box mb={5}>
              <Tabs
                variant="fullWidth"
                value={step}
                onChange={(_, value) => setStep(value)}
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                <Tab label="1. Geral" style={{ marginRight: 24 }} />
                <Tab label="2. Endereço" />
              </Tabs>
            </Box>

            <ValidationFields origin={origin} />

            {step === 0 && (
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    autoFocus
                    margin="dense"
                    name="ec-name"
                    label="Nome"
                    type="text"
                    value={customer.name || ''}
                    onChange={(e) =>
                      setCustomer({ ...customer, name: e.target.value })
                    }
                    InputLabelProps={{ required: true }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <CPFTextField
                    margin="dense"
                    name="ec-cpf"
                    label="CPF"
                    type="text"
                    value={customer.cpf || ''}
                    onChange={(_, value) =>
                      setCustomer({ ...customer, cpf: value })
                    }
                    InputLabelProps={{ required: true }}
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    margin="dense"
                    name="ec-rg"
                    label="RG com órgão expedidor"
                    placeholder="00.000.000-0 XXX/UF"
                    type="text"
                    value={customer.rg || ''}
                    onChange={(e) =>
                      setCustomer({ ...customer, rg: e.target.value })
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    margin="dense"
                    label="Data de nascimento"
                    type="date"
                    value={customer.birthday || ''}
                    onChange={(e) =>
                      setCustomer({ ...customer, birthday: e.target.value })
                    }
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl margin="dense" fullWidth>
                    <InputLabel id="rs-marital-status-select-label" required>
                      Estado Civil
                    </InputLabel>
                    <Select
                      name="cc-marital-status"
                      labelId="rs-marital-status-select-label"
                      value={customer.maritalStatus || ''}
                      onChange={(e) =>
                        setCustomer({
                          ...customer,
                          maritalStatus: e.target.value,
                        })
                      }
                    >
                      <MenuItem value="single">Solteiro(a)</MenuItem>
                      <MenuItem value="married">Casado(a)</MenuItem>
                      <MenuItem value="separate">Separado(a)</MenuItem>
                      <MenuItem value="divorced">Divorciado(a)</MenuItem>
                      <MenuItem value="widowed">Viúvo(a)</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    margin="dense"
                    name="cc-occupation"
                    label="Profissão"
                    type="text"
                    value={customer.occupation || ''}
                    onChange={(e) =>
                      setCustomer({ ...customer, occupation: e.target.value })
                    }
                    InputLabelProps={{ required: true }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <PhoneTextField
                    margin="dense"
                    name="ec-phone"
                    label="Telefone 1"
                    type="text"
                    value={customer.phone || ''}
                    onChange={(_, value) =>
                      setCustomer({ ...customer, phone: value })
                    }
                    InputLabelProps={{ required: true }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <PhoneTextField
                    margin="dense"
                    name="ec-phone2"
                    label="Telefone 2"
                    value={customer.phone2 || ''}
                    onChange={(_, value) =>
                      setCustomer({ ...customer, phone2: value })
                    }
                    type="text"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <EmailTextField
                    margin="dense"
                    name="ec-email"
                    label="E-mail"
                    value={customer.email || ''}
                    onChange={(e) =>
                      setCustomer({ ...customer, email: e.target.value })
                    }
                    fullWidth
                  />
                </Grid>
              </Grid>
            )}

            {step === 1 && (
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <PostalCodeTextField
                    margin="dense"
                    name="ec-postalcode"
                    label="CEP"
                    type="text"
                    value={customer.postalCode || ''}
                    onChange={(_, value) =>
                      setCustomer({ ...customer, postalCode: value })
                    }
                    onBlur={() => handleAddressSearch()}
                    autoFocus
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    name="ec-address"
                    label="Logradouro"
                    type="text"
                    value={customer.address || ''}
                    onChange={(e) =>
                      setCustomer({ ...customer, address: e.target.value })
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    name="ec-addressComplement"
                    label="Complemento"
                    type="text"
                    value={customer.addressComplement || ''}
                    onChange={(e) =>
                      setCustomer({
                        ...customer,
                        addressComplement: e.target.value,
                      })
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    margin="dense"
                    name="ec-addressNumber"
                    label="Número"
                    type="text"
                    value={customer.addressNumber || ''}
                    onChange={(e) =>
                      setCustomer({
                        ...customer,
                        addressNumber: e.target.value,
                      })
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    margin="dense"
                    name="ec-district"
                    label="Bairro"
                    type="text"
                    value={customer.district || ''}
                    onChange={(e) =>
                      setCustomer({ ...customer, district: e.target.value })
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    margin="dense"
                    name="ec-city"
                    label="Cidade"
                    type="text"
                    value={customer.city || ''}
                    onChange={(e) =>
                      setCustomer({ ...customer, city: e.target.value })
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl margin="dense" fullWidth>
                    <InputLabel id="rs-state-select-label">Estado</InputLabel>
                    <Select
                      name="ec-state"
                      labelId="rs-state-select-label"
                      value={customer.state || ''}
                      onChange={(e) =>
                        setCustomer({ ...customer, state: e.target.value })
                      }
                    >
                      {constants.BRASIL_STATES.map((state) => (
                        <MenuItem key={state.value} value={state.value}>
                          {state.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            )}
          </Box>
          <DialogActions>
            <Button
              variant="contained"
              name="ec-submit"
              color="primary"
              type="submit"
            >
              Salvar
            </Button>
          </DialogActions>
        </form>
      )}
    </BaseDialog>
  );
}

EditCustomerDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  customerId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onUpdate: PropTypes.func,
  origin: PropTypes.string,
};

EditCustomerDialog.defaultProps = {
  customerId: null,
  onUpdate: () => {},
  origin: '',
};

export default EditCustomerDialog;
