import React from 'react';
import { makeStyles, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  searchList: {
    position: 'relative',

    '& .icon': {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      width: 30,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingRight: 8,

      '& svg': {
        fontSize: 20,
      },
    },

    '& .MuiTextField-root': {
      '& input': {
        paddingTop: 0,
        paddingBottom: 0,
        paddingRight: 30,
        height: 30,
        ...theme.typography.caption2,
      },

      '& fieldset': {
        borderRadius: 15,
        paddingRight: 30,
      },
    },
  },
}));

export default function SearchListTextField({ value, onChange, placeholder }) {
  const classes = useStyles();

  return (
    <div className={classes.searchList}>
      <div className="icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="none"
          viewBox="0 0 20 20"
        >
          <path
            stroke="#5F697D"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M13.095 5.699A5.23 5.23 0 115.7 13.095 5.23 5.23 0 0113.095 5.7M15.834 15.834l-2.742-2.742"
          />
        </svg>
      </div>

      <TextField
        variant="outlined"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        fullWidth
      />
    </div>
  );
}

SearchListTextField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

SearchListTextField.defaultProps = {
  placeholder: 'Pesquisar',
  value: '',
};
