import React from 'react';

import RealEstatesListProvider from 'providers/ListProviders/RealEstatesListProvider';

import RealEstatesListFilters from 'components/ListFilters/RealEstatesListFilters';
import RealEstatesList from 'components/Lists/RealEstateList';
import RealEstatesListZeroState from 'components/ZeroState/RealEstatesListZeroState';
import CreateRealEstateDialog from 'components/Dialogs/RealEstateDialog/Create';
import EditRealEstateDialog from 'components/Dialogs/RealEstateDialog/Edit';
import { useRealEstateTabContext } from '../providers/RealEstateTabProvider';

export default function RealEstatesTab() {
  const {
    showEditDialog,
    showCreateDialog,
    openCreateDialog,
    closeCreateDialog,
    closeEditDialog,
    selectRealEstate,
    realEstateId,
  } = useRealEstateTabContext();

  return (
    <RealEstatesListProvider>
      <RealEstatesListFilters onClick={openCreateDialog} />
      <RealEstatesListZeroState>
        <RealEstatesList onRowClick={selectRealEstate} />
      </RealEstatesListZeroState>

      <CreateRealEstateDialog
        open={showCreateDialog}
        onClose={closeCreateDialog}
      />

      <EditRealEstateDialog
        realEstateId={realEstateId}
        open={showEditDialog}
        onClose={closeEditDialog}
      />
    </RealEstatesListProvider>
  );
}
