/* eslint-disable react-hooks/exhaustive-deps */
import Loading from 'components/Loading';
import NotFound from 'components/NotFound';
import React, { useContext, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Box } from '@material-ui/core';
import UserProfile from 'components/UserProfile';
import ProjectHeader from 'components/ProjectHeader';
import { AuthContext } from 'providers/AuthProvider';
import { ProjectContext } from 'providers/ProjectProvider';
import Router from './Router';
import { useStyles } from '../Administration/Team/styles';

export default function Sales() {
  const classes = useStyles();

  const { auth } = useContext(AuthContext);
  const { project } = useContext(ProjectContext);

  const access = auth?.access;

  const [userProfileAnchor, setUserProfileAnchor] = useState(null);

  if (!project) return <Loading />;

  return (
    <Switch>
      <Route exact path="/:projectSlug/404" component={NotFound} />

      <Route path="/:projectSlug">
        <Box className={classes.container}>
          <ProjectHeader
            username={access.username}
            project={project}
            environment="sales"
            onShowUserProfile={(elem) => setUserProfileAnchor(elem)}
          />

          <Box className="div2">
            <Box className="div3">
              <Router projectSlug={project.slug} />
            </Box>
          </Box>
        </Box>

        <UserProfile
          access={access}
          open={Boolean(userProfileAnchor)}
          anchor={userProfileAnchor}
          onClose={() => setUserProfileAnchor(null)}
        />
      </Route>
    </Switch>
  );
}
