import React from 'react';
import { Box } from '@material-ui/core';

import RemittancesListFilters from 'components/ListFilters/RemittancesListFilters';
import CreateRemittanceButton from './CreateRemittanceButton';

export default function RemittancesListHeader({ onCreate, disabled }) {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" mb={15}>
      <RemittancesListFilters />
      <CreateRemittanceButton disabled={disabled} onClick={onCreate} />
    </Box>
  );
}
