import React from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';

import Loading from 'components/Loading';
import RemittanceListZeroStateImage from 'assets/images/ZeroStates/RemittanceListZeroStateImage';
import { useRemittancesListContext } from 'providers/ListProviders/RemittancesListProvider';

export default function RemittancesListZeroState({ children }) {
  const theme = useTheme();
  const { search, remittances, isLoading } = useRemittancesListContext();

  const hasData = Boolean(remittances.length);
  const hasSearchFilter = Boolean(search);
  const showButton = !hasData && !hasSearchFilter;

  const message = hasSearchFilter
    ? 'Nenhuma remessa encontrada, tente uma nova pesquisa'
    : 'Você ainda não gerou nenhum arquivo de remessa';

  if (isLoading) return <Loading height={100} />;
  if (hasData > 0) return children;

  return (
    <Box
      mt={8}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Box mb={5}>
        <RemittanceListZeroStateImage />
      </Box>

      <Box display="flex" justifyContent="center">
        <Box mb={1} width={420}>
          <Typography className="fw700" align="center" variant="h3">
            {message}
          </Typography>
        </Box>
      </Box>
      {showButton && (
        <Box mb={4}>
          <Typography
            variant="body2"
            align="center"
            style={{ color: theme.palette.grey[300] }}
          >
            Comece acessando a pagina de títulos, para <br />
            gerar suas remessas
          </Typography>
        </Box>
      )}
    </Box>
  );
}

RemittancesListZeroState.propTypes = {
  children: PropTypes.node,
};

RemittancesListZeroState.defaultProps = {
  children: null,
};
