import React from 'react';
import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import FilterSelect from 'components/FilterSelect';
import SearchListTextField from 'components/SearchListTextField';
import SortSelect from 'components/SortSelect';
import { useInvitesListContext } from 'providers/ListProviders/InvitesListProvider.js';

import FILTER_OPTIONS from './constants/filterOptions';
import SORT_OPTIONS from './constants/sortOptions';
import useStyles from '../styles';
import getSelectedFilterOption from '../utils/getSelectedFilterOption';
import getSelectedSortOption from '../utils/getSelectedSortOption';

export default function InvitesListFilters() {
  const { variables, search, setSearch, setFilter, setSort } =
    useInvitesListContext();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'));
  const classes = useStyles();

  const filterOption = getSelectedFilterOption(
    FILTER_OPTIONS,
    variables.filterOptions.status
  );
  const sortOption = getSelectedSortOption(SORT_OPTIONS, variables.sortOptions);

  return (
    <Box mb={5}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs="auto">
          <Typography className="fw700" variant="h5" color="textPrimary">
            Convites
          </Typography>
        </Grid>

        <Grid item xs={12} md={5}>
          <Box width={isMd ? 285 : '100%'}>
            <SearchListTextField
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md>
          <Box className={classes.listFilters}>
            <FilterSelect
              value={filterOption}
              options={FILTER_OPTIONS}
              onChange={(option) => setFilter({ status: option.value })}
            />

            <SortSelect
              value={sortOption}
              options={SORT_OPTIONS}
              onChange={setSort}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
