import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  iconBox: {
    width: 48,
    height: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    backgroundColor: theme.palette.grey[600],
  },
}));

export default useStyles;
