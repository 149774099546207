const INVITE_TYPES = {
  MANAGER: {
    value: 'Manager',
    label: 'Coordenador',
  },
  REAL_ESTATE: {
    value: 'RealEstate',
    label: 'Imobiliária',
  },
  REAL_ESTATE_AGENT: {
    value: 'RealEstateAgent',
    label: 'Corretor',
  },
};

export const INVITE_TYPES_VALUES = Object.values(INVITE_TYPES);

export default INVITE_TYPES;
