import React, { useContext, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import { ProjectContext } from 'providers/ProjectProvider';
import AlertModule from 'modules/AlertModule';
import api from 'services/api';

import CreatePropertyDialog from 'components/Dialogs/CreateDialogs/CreatePropertyDialog';
import ProjectsLink from 'components/ProjectsLink';
import ReadPropertyDialog from 'components/Dialogs/ReadDialogs/ReadPropertyDialog';
import EditPropertyDialog from 'components/Dialogs/EditDialogs/EditPropertyDialog';
import MakeReservationDialog from 'components/Dialogs/CreateDialogs/MakeReservationDialog';
import SelectedPropertiesPopup from 'components/Popups/SelectedPropertiesPopup';
import PropertiesPriceAdjustmentDialog from 'components/Dialogs/EditDialogs/PropertiesPriceAdjustmentDialog';
import EditPropertiesPriceDialog from 'components/Dialogs/EditDialogs/EditPropertiesPriceDialog';
import ChangePropertiesStatusDialog from 'components/Dialogs/EditDialogs/ChangePropertiesStatusDialog';
import ReadReceiptDialog from 'components/Dialogs/ReadDialogs/ReadReceiptDialog';
import PropertiesListFilters from 'components/ListFilters/PropertiesListFilters';
import PropertiesListZeroState from 'components/ZeroState/PropertiesListZeroState';

import DELETE_PROPERTY_MUTATION from 'queries/deletePropertyQuery';
import PROPERTIES_PRICE_ADJUSTMENT_MUTATION from 'queries/propertiesPriceAdjustmentMutation';
import EDIT_PROPERTIES_PRICE_MUTATION from 'queries/editPropertiesPriceMutation';
import CHANGE_PROPERTIES_STATUS_MUTATION from 'queries/changePropertiesStatusMutation';

import handlerError from 'utils/handleError';
import useQuery from 'hooks/useQuery';
import PropertiesListProvider from 'providers/ListProviders/PropertiesListProvider';
import PropertiesList from 'components/Lists/PropertiesList';

import { useStyles } from './styles';
import useDialogController from './hooks/useDialogController';
import PropertiesListSummary from './components/PropertiesListSummary';

function Properties() {
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  const { project } = useContext(ProjectContext);

  const [showCreatePropertyDialog, setShowCreatePropertyDialog] =
    useState(false);

  const [readPropertyDialogData, setReadPropertyDialogData] = useState({
    open: false,
    propertyId: null,
  });

  const [editPropertyDialogData, setEditPropertyDialogData] = useState({
    open: false,
    propertyId: null,
  });

  const [makeReservationDialogData, setMakeReservationDialogData] = useState({
    open: false,
    propertyId: null,
  });

  const [propertyReservedDialogData, setPropertyReservedDialogData] = useState({
    open: false,
    reservation: null,
  });
  const {
    onCancelEditPropertyDialog,
    onCloseEditPropertyDialog,
    onCloseMakeReservationDialog,
    onCloseReadPropertyDialog,
    onEditProperty,
    onReadProperty,
    onReserveProperty,
    onPropertyReserved,
    onClosePropertyReservedDialog,
  } = useDialogController(
    readPropertyDialogData,
    setReadPropertyDialogData,
    editPropertyDialogData,
    setEditPropertyDialogData,
    setMakeReservationDialogData,
    setPropertyReservedDialogData
  );

  const [executeDeleteProperty] = useQuery(DELETE_PROPERTY_MUTATION);

  const handleDeleteProperty = (property) => {
    AlertModule.confirmation(
      `Deseja realmente deletar o imóvel "${property.unity}"?`
    ).then((result) => {
      if (result.isConfirmed) {
        executeDeleteProperty({ id: property._id })
          .then(() => {
            AlertModule.success('Imóvel deletado com sucesso!');
          })
          .catch((error) => {
            handlerError(
              {
                YOU_CANT_DO_THIS: AlertModule.onError(
                  'Você não pode fazer isso.'
                ),
                DEFAULT: AlertModule.onError(
                  'Não foi possível excluir o imóvel.'
                ),
              },
              error.message
            );
          });
      }
    });
  };

  const [selectedPropertiesIds, setSelectedPropertiesIds] = useState([]);
  const [selectMethods, setSelectMethods] = useState(null);

  const [
    showPropertiesPriceAdjustmentDialog,
    setShowPropertiesPriceAdjustmentDialog,
  ] = useState(false);

  const handlePriceAdjustment = (percent) => {
    api
      .call(PROPERTIES_PRICE_ADJUSTMENT_MUTATION, {
        projectId: project._id,
        propertiesIds: selectedPropertiesIds,
        percent,
      })
      .then(async () => {
        await AlertModule.success('O preço dos imóveis foram reajustado.');
        setShowPropertiesPriceAdjustmentDialog(false);
        selectMethods.handleDeselectAll();
      })
      .catch(() => {
        AlertModule.error('Não foi possível reajustar os preços.');
      });
  };

  const [showEditPropertiesPriceDialog, setShowEditPropertiesPriceDialog] =
    useState(false);

  const handleEditPrice = ({ type, value }) => {
    api
      .call(EDIT_PROPERTIES_PRICE_MUTATION, {
        projectId: project._id,
        propertiesIds: selectedPropertiesIds,
        type,
        value,
      })
      .then(async () => {
        await AlertModule.success('O preço dos imóveis foram alterados.');
        setShowEditPropertiesPriceDialog(false);
        selectMethods.handleDeselectAll();
      })
      .catch(AlertModule.onError('Não foi possível alterar os preços.'));
  };

  const [
    showChangePropertiesStatusDialog,
    setShowChangePropertiesStatusDialog,
  ] = useState(false);

  const handleChangeStatus = (status) => {
    api
      .call(CHANGE_PROPERTIES_STATUS_MUTATION, {
        projectId: project._id,
        propertiesIds: selectedPropertiesIds,
        status,
      })
      .then(async () => {
        await AlertModule.success('A situação dos imóveis foram alterados.');
        setShowChangePropertiesStatusDialog(false);
        selectMethods.handleDeselectAll();
      })
      .catch(() => {
        AlertModule.error('Não foi possível alterar a situação.');
      });
  };

  const extraActions = useMemo(
    () => (
      <Box display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          name="interactive-map-button"
          color="secondary"
          size="small"
          onClick={() => history.push(`/${project.slug}/mapa`)}
          style={{ marginRight: 8 }}
        >
          Mapa
        </Button>

        <Button
          variant="contained"
          name="create-property-button"
          color="primary"
          size="small"
          onClick={() => setShowCreatePropertyDialog(true)}
        >
          Cadastrar
        </Button>
      </Box>
    ),
    [project, history]
  );

  if (!project) return null;

  return (
    <Container className={classes.container}>
      <Box
        display={!isSm ? 'flex' : 'none'}
        justifyContent="space-between"
        alignItems="center"
        mb={5}
        minHeight={40}
      >
        <ProjectsLink mb={0} />

        <Box ml="auto">
          <Button
            variant="contained"
            name="interactive-map-button"
            color="secondary"
            onClick={() => history.push(`/${project.slug}/mapa`)}
            style={{ marginRight: 16 }}
          >
            Mapa
          </Button>

          <Button
            variant="contained"
            name="create-property-button"
            color="primary"
            onClick={() => setShowCreatePropertyDialog(true)}
          >
            Cadastrar
          </Button>
        </Box>
      </Box>
      <PropertiesListProvider>
        <PropertiesListFilters extraActions={extraActions} />
        <PropertiesListZeroState>
          <PropertiesListSummary />
          <PropertiesList
            onDelete={handleDeleteProperty}
            onDetails={onReadProperty}
            onSelect={setSelectedPropertiesIds}
            getSelectMethods={setSelectMethods}
          />
        </PropertiesListZeroState>
      </PropertiesListProvider>

      <CreatePropertyDialog
        show={showCreatePropertyDialog}
        projectId={project._id}
        onClose={() => setShowCreatePropertyDialog(false)}
      />
      <ReadPropertyDialog
        open={readPropertyDialogData.open}
        propertyId={readPropertyDialogData.propertyId}
        options={{ canEdit: true }}
        onClose={onCloseReadPropertyDialog}
        onEdit={onEditProperty}
        onReserve={onReserveProperty}
      />
      <EditPropertyDialog
        open={editPropertyDialogData.open}
        propertyId={editPropertyDialogData.propertyId}
        onClose={onCloseEditPropertyDialog}
        showPropertiesPriceAdjustmentDialog
        onCancel={onCancelEditPropertyDialog}
      />
      <MakeReservationDialog
        open={makeReservationDialogData.open}
        propertyId={makeReservationDialogData.propertyId}
        onClose={onCloseMakeReservationDialog}
        onPropertyReserved={onPropertyReserved}
      />

      <ReadReceiptDialog
        open={propertyReservedDialogData.open}
        reservation={propertyReservedDialogData?.reservation}
        onClose={onClosePropertyReservedDialog}
      />
      <Box
        position="fixed"
        bottom={0}
        left={0}
        right={0}
        display="flex"
        flexDirection="column"
        alignItems="center"
        padding={3}
      >
        <SelectedPropertiesPopup
          length={selectedPropertiesIds.length}
          onPriceAdjustment={() => setShowPropertiesPriceAdjustmentDialog(true)}
          onEditPrice={() => setShowEditPropertiesPriceDialog(true)}
          onChangeStatus={() => setShowChangePropertiesStatusDialog(true)}
          onClose={() => selectMethods?.handleDeselectAll()}
        />
      </Box>

      <PropertiesPriceAdjustmentDialog
        open={showPropertiesPriceAdjustmentDialog}
        onSubmit={handlePriceAdjustment}
        onClose={() => setShowPropertiesPriceAdjustmentDialog(false)}
      />

      <EditPropertiesPriceDialog
        open={showEditPropertiesPriceDialog}
        onSubmit={handleEditPrice}
        onClose={() => setShowEditPropertiesPriceDialog(false)}
      />

      <ChangePropertiesStatusDialog
        open={showChangePropertiesStatusDialog}
        onSubmit={handleChangeStatus}
        onClose={() => setShowChangePropertiesStatusDialog(false)}
      />
    </Container>
  );
}

export default Properties;
