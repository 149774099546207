import React, { useContext, useState, useEffect } from 'react';
import { Box } from '@material-ui/core';

import RemittancesList from 'components/Lists/RemittancesList';
import ReadRemittanceDialog from 'components/Dialogs/ReadDialogs/ReadRemittanceDialog.js';
import ReadNewRemittanceDialog from 'components/Dialogs/ReadDialogs/ReadNewRemittanceDialog';
import RemittancesListZeroState from 'components/ZeroState/RemittancesListZeroState';

import { ProjectContext } from 'providers/ProjectProvider';
import RemittanceModule from 'modules/RemittanceModule';
import RemittancesListProvider from 'providers/ListProviders/RemittancesListProvider';

import WebSocketController from 'utils/WebSocketController';
import AlertModule from 'modules/AlertModule';
import RemittancesListHeader from './components/RemittancesListHeader';

const socket = new WebSocketController();

export default function Remittance() {
  const { project } = useContext(ProjectContext);

  const [newRemittance, setNewRemittance] = useState(null);
  const [selectedRemittance, setSelectedRemittance] = useState(null);
  const [emittedInvoices, setEmittedInvoices] = useState([]);
  const hasEmittedInvoices = emittedInvoices.length > 0;
  const [newEmittedInvoices, setNewEmittedInvoices] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCreateRemittance = () => {
    setLoading(true);

    RemittanceModule.generateRemittance(project._id)
      .then((remittance) => {
        setNewRemittance(remittance);
        setEmittedInvoices([]);
      })
      .catch(AlertModule.onError('Não foi possivel gerar a remessa'))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (project) {
      RemittanceModule.getEmittedInvoices(project._id).then(setEmittedInvoices);
    }
  }, [project]);

  useEffect(() => {
    socket.onInvoiceEmitted(() => {
      setNewEmittedInvoices(true);
    });

    return () => {
      socket.destroy();
    };
  }, []);

  useEffect(() => {
    if (newEmittedInvoices) {
      RemittanceModule.getEmittedInvoices(project._id).then(setEmittedInvoices);
      setNewEmittedInvoices(false);
    }
  }, [newEmittedInvoices, project._id]);

  return (
    <Box>
      <RemittancesListProvider>
        <RemittancesListHeader
          onCreate={handleCreateRemittance}
          disabled={!hasEmittedInvoices || loading}
        />
        <RemittancesListZeroState>
          <RemittancesList onDetails={setSelectedRemittance} />
        </RemittancesListZeroState>
      </RemittancesListProvider>

      <ReadNewRemittanceDialog
        open={Boolean(newRemittance)}
        projectId={project._id}
        remittance={newRemittance}
        onClose={() => setNewRemittance(null)}
      />

      <ReadRemittanceDialog
        remittance={selectedRemittance}
        open={Boolean(selectedRemittance)}
        onClose={() => setSelectedRemittance(null)}
      />
    </Box>
  );
}
