import React from 'react';
import { useTheme } from '@material-ui/core';

import { getSalesContractColors } from './BaseStatusLabel/colors';
import { salesContractStatusTexts } from './BaseStatusLabel/texts';
import BaseStatusLabel from './BaseStatusLabel';

export default function SalesContractStatusLabel({ status, size = 'small' }) {
  const theme = useTheme();
  const color = getSalesContractColors(theme)[status];

  return (
    <BaseStatusLabel
      size={size}
      label={salesContractStatusTexts[status]}
      color={color}
    />
  );
}
