import React, { useEffect, useState } from 'react';
import { Box, TextField } from '@material-ui/core';
import BaseList from 'components/Lists/BaseList';
import BaseDialog from 'components/Dialogs/BaseDialog';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';
import ICON_TYPES from '@constants/iconsPaths';
import useThemeClasses from 'theme/useThemeClasses';
import useColumns from './hooks/useColumns';
import getTitleAndSubtitle from './utils/getTitleAndSubtitle';
import useGetInvoices from './hooks/useGetInvoices';

export default function ReadRemittanceDialog({ open, remittance, onClose }) {
  const textFieldClasses = useThemeClasses.textField();
  const [title, subtitle] = getTitleAndSubtitle(remittance, open);
  const [search, setSearch] = useState('');
  const [invoices, getInvoices] = useGetInvoices(remittance, search);

  const columns = useColumns();

  useEffect(() => {
    if (open) {
      getInvoices();
    }
  }, [open, getInvoices, search]);

  return (
    <BaseDialog
      open={open}
      title={title}
      subtitle={subtitle}
      onClose={() => onClose(false)}
      maxWidth="md"
    >
      <Box position="relative" mb={3} mt={3}>
        <TextField
          className={textFieldClasses.textFieldOutlined1}
          variant="outlined"
          placeholder="Pesquise por nome do cliente, código do contrato"
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          fullWidth
        />

        <Box position="absolute" top={10} right={36}>
          <Icon src={ICON_TYPES.BASE.search} size={24} />
        </Box>
      </Box>

      <Box height={400} style={{ overflowY: 'auto' }}>
        <BaseList data={invoices} columns={columns} />
      </Box>
    </BaseDialog>
  );
}

ReadRemittanceDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  remittance: PropTypes.shape({
    invoices: PropTypes.arrayOf(PropTypes.string),
    contracts: PropTypes.arrayOf(PropTypes.shape()),
  }),
};

ReadRemittanceDialog.defaultProps = {
  remittance: null,
};
