// eslint-disable-next-line import/no-anonymous-default-export
export default function getCustomersACQuery({
  projectId,
  search = null
}) {

  return `
    query {
      getCustomers(
        projectId: "${projectId}"
        page: 0
        itemsPerPage: 0
        ${search ? `search: "${search}"` : ''}
        sort: {
          field: "name"
          direction: "asc"
        }
      ) {
        customers {
          _id
          name
          cpf
        }
        page
        pages
      }
    }
  `
}