export default `
        mutation createRemittanceMutation (
            $projectId: ID!
        ) {
            createRemittance (
                projectId: $projectId
            ){
                remittance{
                  _id
                  projectId
                  name
                  fileId
                  createdAt
                  invoicesIds
                }
                failedSalesContracts
              }
          }
        
    `;
