import React from 'react';

function CoinsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
    >
      <ellipse
        cx="8.999"
        cy="9.999"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        rx="6.003"
        ry="2.501"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M15.002 13.501c0 1.381-2.688 2.501-6.003 2.501s-6.002-1.12-6.002-2.501M21.004 9.999c0 1.381-2.687 2.501-6.003 2.501"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M15.002 9.999v7.503c0 1.381-2.688 2.501-6.003 2.501s-6.002-1.12-6.002-2.5V9.998"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M21.004 6.498v7.503c0 1.382-2.687 2.501-6.003 2.501"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M9.5 7.509a1.442 1.442 0 01-.501-1.01c0-1.381 2.691-2.502 6.002-2.502 3.312 0 6.003 1.12 6.003 2.501 0 1.38-2.691 2.501-6.003 2.501-.17 0-.34 0-.5-.01"
      />
    </svg>
  );
}

export default CoinsIcon;
