import { Grid, Box, Typography, useTheme } from '@material-ui/core';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { PAYMENT_METHODS } from '@constants/reservations/paymentMethods';
import CurrencyTextField from 'components/TextFields/CurrencyTextField';
import formatPrice from 'utils/formatPrice';
import { ProjectContext } from 'providers/ProjectProvider';
import Picker from 'components/Picker';
import InformationBlock from 'components/InformationBlock';
import isEditionDisabled from '../utils/isEditionDisabled';
import validateEntrance from './utils/validateEntrance';
import getPaymentOptions from './utils/getPaymentOptions';

export default function PaymentInformation({
  reservation,
  setReservation,
  payment,
}) {
  const theme = useTheme();
  const editionDisabled = isEditionDisabled(reservation);
  const { discount } = useContext(ProjectContext);
  const {
    price,
    minimumEntrance,
    monthlyInstallmentsOptions,
    annuallyInstallmentsOptions,
  } = payment;

  const validEntrance = validateEntrance({
    entrance: reservation.entrance,
    price,
    minimumEntrance,
  });

  const paymentOptions = getPaymentOptions(discount);

  const onChangePaymentMethod = (e) => {
    setReservation({
      ...reservation,
      paymentMethod: e.target.value,
      discount: discount[e.target.value],
    });
  };

  const onChangeMonthlyInstallment = (e) =>
    setReservation({
      ...reservation,
      installments: e.target.value,
    });
  return (
    <Grid container spacing={4}>
      <Grid item md={12} style={{ paddingBottom: 0 }}>
        <Typography
          className="fw700"
          style={{ color: theme.palette.secondary.dark }}
        >
          Informações de pagamento
        </Typography>
      </Grid>
      <Picker
        label="Forma de pagamento"
        onChange={onChangePaymentMethod}
        value={reservation.paymentMethod}
        disabled={editionDisabled}
        native
      >
        {paymentOptions}
      </Picker>

      {reservation.paymentMethod !== PAYMENT_METHODS.CASH && (
        <>
          <Picker
            label="Parcelas mensais"
            onChange={onChangeMonthlyInstallment}
            value={reservation.installments}
            disabled={editionDisabled}
          >
            {monthlyInstallmentsOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Picker>
          <Grid item md={6}>
            <Typography
              variant="body2"
              style={{ color: theme.palette.secondary.main }}
            >
              Entrada
            </Typography>

            <Box mt={1}>
              <CurrencyTextField
                value={reservation.entrance}
                onChange={(_, value) =>
                  setReservation({ ...reservation, entrance: value })
                }
                error={!validEntrance}
                fullWidth
                disabled={editionDisabled}
              />
            </Box>

            <Typography
              variant="caption"
              style={{
                color: !validEntrance ? 'red' : theme.palette.secondary.main,
              }}
            >
              Valor mínimo: {formatPrice(minimumEntrance)}
            </Typography>
          </Grid>
        </>
      )}

      {reservation.paymentMethod === PAYMENT_METHODS.ANNUALLY && (
        <InformationBlock label="Parcelas anuais">
          {annuallyInstallmentsOptions.formattedValue}
        </InformationBlock>
      )}
    </Grid>
  );
}
PaymentInformation.propTypes = {
  reservation: PropTypes.shape({
    paymentMethod: PropTypes.string,
    entrance: PropTypes.number,
    installments: PropTypes.number,
  }),
  monthlyInstallmentsOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  annuallyInstallmentsOptions: PropTypes.shape({
    formattedValue: PropTypes.string,
  }).isRequired,
  setReservation: PropTypes.func.isRequired,
};

PaymentInformation.defaultProps = {
  reservation: null,
};
