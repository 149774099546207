import React, { useState, useEffect, useContext } from 'react';
import { Box, Button, Grid, Tab, Tabs } from '@material-ui/core';
import PropTypes from 'prop-types';

import BaseDialog from 'components/Dialogs/BaseDialog';

import { useBankAccountsListContext } from 'providers/ListProviders/BankAccountsListProvider';
import { ProjectContext } from 'providers/ProjectProvider';
import ErrorHandler from 'modules/ErrorHandlerModule';
import AlertModule from 'modules/AlertModule';

import CREATE_BANK_ACCOUNT_MUTATION from 'queries/createBankAccountMutation';

import useQuery from 'hooks/useQuery';

import BankInformation from './components/BankInformation';
import AddressInformation from './components/AddressInformation';
import validateInputs from './utils/validateInputs';

const DEFAULT_BANK_ACCOUNT = {
  companyName: '',
  companyCode: null,
  document: '',
  bankCode: 237,
  bankName: 'Bradesco S.A.',
  branchNumber: '',
  branchDigit: '',
  accountNumber: '',
  accountDigit: '',
  accountType: '',
};

export default function CreateBankAccountDialog({ open, onClose }) {
  const { project } = useContext(ProjectContext);
  const { updateList } = useBankAccountsListContext();
  const [bankAccount, setBankAccount] = useState(DEFAULT_BANK_ACCOUNT);
  const [executeCreateBankAccount] = useQuery(CREATE_BANK_ACCOUNT_MUTATION);
  const [step, setStep] = useState(0);

  const handleSubmit = (e) => {
    e.preventDefault();
    const error = validateInputs(bankAccount);
    if (error) {
      setStep(error.step);
      AlertModule.error(error.message);
      return;
    }
    const variables = {
      ...bankAccount,
      projectId: project._id,
      companyCode: Number(bankAccount.companyCode),
    };
    executeCreateBankAccount(variables)
      .then(() => updateList())
      .then(() => setStep(0))
      .then(() => onClose())
      .catch(ErrorHandler('Não foi possível cadastrar a conta bancária'));
  };

  useEffect(() => {
    if (!open) {
      setBankAccount(DEFAULT_BANK_ACCOUNT);
    }
  }, [open]);
  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      title="Adicionar conta bancária"
      maxWidth="md"
    >
      <form onSubmit={handleSubmit}>
        <Box mb={5}>
          <Tabs
            variant="fullWidth"
            value={step}
            onChange={(_, value) => setStep(value)}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="1. Conta" style={{ marginRight: 24 }} />
            <Tab label="2. Endereço" />
          </Tabs>
        </Box>
        <Grid container spacing={2}>
          {step === 0 && (
            <BankInformation
              bankAccount={bankAccount}
              setBankAccount={setBankAccount}
            />
          )}
          {step === 1 && (
            <AddressInformation
              bankAccount={bankAccount}
              setBankAccount={setBankAccount}
            />
          )}
        </Grid>
        <Box display="flex" justifyContent="flex-end" mt={4}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            size="large"
          >
            Cadastrar
          </Button>
        </Box>
      </form>
    </BaseDialog>
  );
}

CreateBankAccountDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

CreateBankAccountDialog.defaultProps = {
  open: false,
};
