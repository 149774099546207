import React from 'react';

export default function SuccessSvg() {
  return (
    <svg
      width="135"
      height="131"
      viewBox="0 0 135 131"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.24"
        d="M116.011 49.8206C127.508 62.3615 122.498 80.6422 112.698 92.8536C97.9223 111.266 61.6629 121.126 38.1576 106.362C20.3338 95.1634 8.1503 74.996 14.4248 57.239C19.3713 43.2588 34.6175 28.3249 53.359 24.4336C68.0605 21.3817 68.7767 29.9827 75.5885 36.0901C78.39 38.6011 83.15 38.6462 87.0818 38.9063C93.2444 39.3155 99.2467 39.7872 104.768 42.1525C109.557 44.1987 113.258 46.8172 116.011 49.8206Z"
        fill="#46BE46"
      />
      <path
        d="M87.1009 114.899C88.8446 112.803 89.8854 106.788 89.8854 106.788C91.3646 107.853 92.6613 109.138 93.7278 110.595C95.6699 110.314 97.5795 109.852 99.43 109.215C101.334 108.581 103.146 107.71 104.818 106.623C101.109 101.326 94.83 92.3011 90.9637 89.464C89.4691 88.3682 84.8384 87.2989 84.8384 87.2989C83.7908 86.3785 81.5045 87.2989 81.5045 87.2989C69.1345 90.3512 57.8223 86.3984 57.8223 86.3984C57.8223 86.3984 56.2184 85.68 54.9047 86.3984C53.6255 86.2612 52.3346 86.2612 51.0554 86.3984C48.2231 86.7758 45.1281 89.9142 43.8518 91.3411L32.9833 103.554C32.9833 103.554 32.6181 108.361 33.3859 108.996C39.5283 114.104 48.5849 102.498 48.5849 102.498C48.6429 102.521 48.7828 112.605 53.6318 117.879C53.6318 117.879 57.0681 119.401 70.4312 118.21C83.6919 117.028 87.1009 114.899 87.1009 114.899Z"
        fill="#FF6C4E"
      />
      <path
        opacity="0.2"
        d="M40.7496 99.945C38.7022 100.12 37.6273 99.6438 37.2178 98.7963C40.1798 95.4857 43.6365 91.5859 43.8515 91.3442L44.1928 90.9668C45.7284 94.3005 47.0046 99.4153 40.7496 99.945Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M49.85 110.464C49.1549 107.853 48.7308 105.18 48.584 102.486C48.584 102.486 50.0718 100.596 50.4847 98.6094C50.4847 98.6226 52.4161 106.095 49.85 110.464Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M92.5976 109.215C91.791 108.309 90.8809 107.495 89.8848 106.789C89.8848 106.789 90.0315 102.045 91.3965 100.608C92.4953 99.4526 93.6657 105.465 92.5976 109.215Z"
        fill="black"
      />
      <path
        d="M87.1006 114.9C88.8443 112.804 89.8851 106.789 89.8851 106.789C91.3643 107.854 92.6611 109.139 93.7275 110.596"
        stroke="#263238"
        strokeWidth="0.29971"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M104.818 106.623C101.109 101.326 94.83 92.3011 90.9637 89.464C89.4691 88.3682 84.8384 87.2989 84.8384 87.2989C83.7908 86.3785 81.5045 87.2989 81.5045 87.2989C69.1345 90.3512 57.8223 86.3984 57.8223 86.3984C57.8223 86.3984 56.2184 85.68 54.9047 86.3984C53.6255 86.2612 52.3346 86.2612 51.0554 86.3984C48.2231 86.7758 45.1281 89.9142 43.8518 91.3411L32.9833 103.554C32.9833 103.554 32.6181 108.361 33.3859 108.996C39.5283 114.104 48.5849 102.498 48.5849 102.498C48.6429 102.521 48.7828 112.605 53.6318 117.879"
        stroke="#263238"
        strokeWidth="0.29971"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M81.9565 84.3867L81.791 84.4536C81.8494 84.4398 81.9053 84.4172 81.9565 84.3867Z"
        fill="#263238"
      />
      <path
        d="M85.6375 75.4541C86.9342 75.6395 87.2515 74.7158 87.2515 74.7158C87.2515 74.7158 85.6374 75.4541 84.2076 72.6136C83.3955 70.9948 83.7982 68.0219 84.293 65.7641C85.2928 64.1651 86.7431 61.9536 86.9342 59.3317C87.2754 54.8293 85.0949 52.5483 82.5799 53.2171C82.5799 53.2171 82.3956 49.4132 78.857 48.0493C75.3183 46.6853 74.7621 47.8043 71.3496 46.9767C67.9372 46.149 64.5999 46.8707 63.3782 49.0127C62.1566 51.1546 61.7402 55.0445 59.6553 56.5508C57.5703 58.0571 53.9224 61.6822 55.0758 65.7376C56.2292 69.793 55.574 71.1802 54.0521 73.097C52.5301 75.0138 53.4583 78.7878 56.3623 79.973C56.3623 79.973 55.1304 79.3341 56.0006 78.2648C56.0006 78.2648 56.683 80.8073 58.9591 80.3438C61.2352 79.8803 64.1255 79.7247 65.5997 81.2906C67.0739 82.8565 72.4621 83.277 73.6769 82.0851C74.8917 80.8933 75.8336 81.9991 78.0346 83.0783C80.2356 84.1576 82.6789 83.2107 82.6789 83.2107C82.6789 83.2107 82.4741 84.1344 81.9486 84.4092C82.3956 84.2271 83.6344 83.6543 83.9961 82.7936C84.3578 81.9329 84.5625 81.1019 84.1292 79.0924C85.6545 78.5131 84.9515 77.5331 84.9515 77.5331C86.3916 78.3177 87.0741 76.0003 87.0741 76.0003C85.733 75.9805 85.6375 75.4541 85.6375 75.4541Z"
        fill="#263238"
      />
      <path
        d="M82.7433 87.7135C82.7433 87.7135 73.11 91.8716 70.015 92.0404C66.9199 92.2092 56.3926 86.7766 56.3926 86.7766L64.4186 84.6512C65.5106 82.6318 65.5379 80.2548 65.2751 77.4706C65.1932 76.6066 65.0874 75.7922 64.9714 75.0605C64.6984 73.296 64.3947 72.0645 64.3947 72.0645L75.7376 72.5213C75.5013 73.2206 75.3065 73.9324 75.1541 74.6533C74.8405 76.1484 74.6612 77.667 74.6183 79.1921C74.4887 82.9993 75.0961 85.1511 75.0961 85.1511L82.7433 87.7135Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.29971"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59.293 88.2061C59.293 88.2061 65.3671 89.2389 66.8754 89.0701C68.3837 88.9013 69.5678 90.3413 69.5678 90.3413"
        fill="white"
      />
      <path
        d="M59.293 88.2061C59.293 88.2061 65.3671 89.2389 66.8754 89.0701C68.3837 88.9013 69.5678 90.3413 69.5678 90.3413"
        stroke="#263238"
        strokeWidth="0.29971"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M80.6377 88.5791C80.6377 88.5791 74.4475 89.2412 72.9495 89.2743C71.4514 89.3074 71.0078 90.3403 71.0078 90.3403"
        fill="white"
      />
      <path
        d="M80.6377 88.5791C80.6377 88.5791 74.4475 89.2412 72.9495 89.2743C71.4514 89.3074 71.0078 90.3403 71.0078 90.3403"
        stroke="#263238"
        strokeWidth="0.29971"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M75.1534 74.6523C74.8398 76.1474 74.6605 77.666 74.6176 79.1911C73.6864 79.987 72.6085 80.6048 71.4407 81.0119C69.5331 81.6244 67.0045 79.3567 65.2744 77.4696C65.1925 76.6056 65.0867 75.7912 64.9707 75.0595L75.1534 74.6523Z"
        fill="#263238"
      />
      <path
        d="M61.7608 62.8012C61.7608 62.8012 60.8258 61.4075 59.9932 61.6889C59.1606 61.9703 59.1606 65.7377 59.2254 66.7309C59.2902 67.724 61.3275 69.9057 62.1806 69.3065"
        fill="white"
      />
      <path
        d="M61.7608 62.8012C61.7608 62.8012 60.8258 61.4075 59.9932 61.6889C59.1606 61.9703 59.1606 65.7377 59.2254 66.7309C59.2902 67.724 61.3275 69.9057 62.1806 69.3065"
        stroke="#263238"
        strokeWidth="0.29971"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M82.2945 65.737C82.2945 65.737 83.6015 64.6643 84.3181 65.1675C85.0347 65.6707 83.9086 69.2859 83.5537 70.2095C83.1988 71.1332 80.5952 72.6626 79.957 71.8482"
        fill="white"
      />
      <path
        d="M82.2945 65.737C82.2945 65.737 83.6015 64.6643 84.3181 65.1675C85.0347 65.6707 83.9086 69.2859 83.5537 70.2095C83.1988 71.1332 80.5952 72.6626 79.957 71.8482"
        stroke="#263238"
        strokeWidth="0.29971"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69.6081 80.1942C66.1718 79.7009 62.7593 74.0465 62.3464 72.4541C61.8892 70.6829 60.8825 61.9464 62.8447 57.1593C65.0525 51.7796 69.6695 49.5086 74.1705 50.1509C78.6715 50.7931 82.4183 54.2593 82.9336 60.0296C83.3908 65.1609 79.8283 73.2453 78.8626 74.8145C77.9958 76.2248 73.2355 80.7106 69.6081 80.1942Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.29971"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M77.3999 66.1788C77.3077 66.7979 76.946 67.2581 76.5945 67.2051C76.2431 67.1521 76.0383 66.6158 76.1304 65.9968C76.2226 65.3777 76.5843 64.9208 76.9358 64.9705C77.2873 65.0201 77.4954 65.5598 77.3999 66.1788Z"
        fill="#263238"
      />
      <path
        d="M66.18 64.5752C66.0879 65.191 66.296 65.734 66.6475 65.7836C66.999 65.8333 67.3573 65.3731 67.4494 64.754C67.5416 64.1349 67.3368 63.5953 66.9853 63.5457C66.6339 63.496 66.2756 63.9562 66.18 64.5752Z"
        fill="#263238"
      />
      <path
        d="M73.8643 72.9343C73.8643 72.9343 72.083 76.3243 69.8103 75.7681C67.5376 75.2119 67.5547 72.1265 67.5547 72.0371L67.8755 72.1563C68.7734 72.4826 69.7045 72.7156 70.6532 72.8515C71.5716 72.9685 72.499 73.004 73.4241 72.9574C73.7005 72.9475 73.8643 72.9343 73.8643 72.9343Z"
        fill="#757575"
      />
      <path
        d="M70.8993 65.8799C70.9881 68.0086 69.1044 68.3694 69.4661 69.1077C69.7229 69.5722 70.1563 69.9214 70.6741 70.081"
        fill="white"
      />
      <path
        d="M70.8993 65.8799C70.9881 68.0086 69.1044 68.3694 69.4661 69.1077C69.7229 69.5722 70.1563 69.9214 70.6741 70.081"
        stroke="#263238"
        strokeWidth="0.29971"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67.5547 72.0273C67.5547 72.0273 67.5342 75.2088 69.8103 75.765C72.0864 76.3211 73.8643 72.9278 73.8643 72.9278"
        stroke="#263238"
        strokeWidth="0.29971"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M64.1484 61.7649C64.1484 61.7649 66.1959 59.2555 69.3729 60.05"
        stroke="#263238"
        strokeWidth="0.29971"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M79.8755 64.0161C79.8755 64.0161 78.6573 61.0366 75.3848 60.9141"
        stroke="#263238"
        strokeWidth="0.29971"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M77.5428 54.7554C77.5428 54.7554 75.3827 57.5032 70.6941 58.2977C64.8213 59.2909 61.5795 64.217 61.6614 66.2729C60.5069 63.3895 60.1596 60.261 60.6547 57.2053C61.8218 49.6439 69.0936 47.4821 74.3795 48.0085C79.6653 48.5349 82.228 53.6762 82.228 53.6762C82.228 53.6762 86.1967 56.6756 85.6405 60.4562C85.0877 64.0747 84.1834 64.1773 83.7022 64.995C83.0402 66.1272 83.9104 66.6006 81.5899 68.4645C81.5899 68.4645 82.5079 63.4126 81.3681 59.6451C80.2284 55.8777 77.5428 54.7554 77.5428 54.7554Z"
        fill="#263238"
      />
      <path
        d="M78.9965 53.4883C78.9965 53.4883 76.9252 61.1622 65.1523 62.3606C65.1523 62.3606 73.0828 60.9701 76.7546 53.9418L78.9965 53.4883Z"
        fill="#263238"
      />
      <path
        d="M70.0624 74.1026C70.0624 74.1026 71.6799 73.5431 72.3044 74.1026C72.3044 74.1026 71.2397 75.7578 69.8713 75.3539C68.5029 74.9501 68.2129 73.3676 68.2129 73.3676C68.2129 73.3676 68.9534 73.02 70.0624 74.1026Z"
        fill="#BFBFBF"
      />
      <path
        d="M73.6491 72.9621L73.1884 73.4686C71.3725 73.6933 69.5283 73.4072 67.8752 72.6443L67.7148 72.0947C68.665 72.4441 69.6495 72.6981 70.6529 72.8528C71.6468 72.9695 72.649 73.006 73.6491 72.9621Z"
        fill="white"
      />
      <path
        d="M67.0469 71.8301C67.0469 71.8301 70.7459 73.5019 74.3222 72.8696"
        stroke="#263238"
        strokeWidth="0.29971"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M78.6822 51.5811C78.6822 51.5811 76.174 54.7691 70.0624 55.2558C63.9507 55.7424 62.1797 58.1989 62.1797 58.1989"
        stroke="#757575"
        strokeWidth="0.29971"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M64.9202 51.833C64.3538 51.9389 63.5553 52.1343 63.3027 52.6938"
        stroke="#757575"
        strokeWidth="0.29971"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M79.4374 49.1055C75.752 51.7771 70.9917 52.085 66.1562 51.7076"
        stroke="#757575"
        strokeWidth="0.29971"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56.0882 73.543C55.7368 73.8963 55.4611 74.3138 55.2771 74.7713C55.0932 75.2288 55.0047 75.7171 55.0167 76.208"
        stroke="#757575"
        strokeWidth="0.29971"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60.4944 58.6445C58.7882 59.588 57.4062 61.3228 57.314 63.2793C57.1605 66.5899 59.6004 69.5694 57.0684 72.4529"
        stroke="#757575"
        strokeWidth="0.29971"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59.7739 69.4102C59.7739 69.4102 61.6644 71.3501 60.4359 73.3232C59.2074 75.2963 58.5727 77.2959 61.0911 78.7526"
        stroke="#757575"
        strokeWidth="0.29971"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M73.5771 59.2285C73.1746 59.4729 72.7575 59.694 72.3281 59.8906"
        stroke="#757575"
        strokeWidth="0.29971"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M77.9941 54.3428C77.2604 55.9812 76.1082 57.4112 74.6465 58.4975"
        stroke="#757575"
        strokeWidth="0.29971"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M79.4365 54.6172C79.4365 54.6172 81.9924 55.9646 82.9752 58.1992C83.958 60.4339 82.5725 63.5789 83.2141 64.6648"
        stroke="#757575"
        strokeWidth="0.29971"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M83.4121 76.4141C83.7641 76.6289 84.1504 76.7856 84.5553 76.8775"
        stroke="#757575"
        strokeWidth="0.29971"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M81.3887 73.3223C81.5447 74.1296 81.8955 74.8897 82.4124 75.5403"
        stroke="#757575"
        strokeWidth="0.29971"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M80.3791 75.0996C80.3791 75.0996 80.1403 77.6222 81.7987 79.2908C83.4571 80.9593 82.9726 81.9988 82.9726 81.9988"
        stroke="#757575"
        strokeWidth="0.29971"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M77.6309 77.9863C77.6309 77.9863 78.6205 80.5421 77.0508 81.3929"
        stroke="#757575"
        strokeWidth="0.29971"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M81.3887 53.915C81.3887 53.915 85.1219 54.6467 85.6679 56.2324C86.2138 57.8182 85.6679 59.6423 85.6679 59.6423"
        stroke="#757575"
        strokeWidth="0.29971"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.3859 108.997C30.2192 107.292 30.8334 101.161 32.9286 93.5033C33.9524 89.7425 37.5081 80.2611 37.4364 79.9301C37.2448 79.2927 36.9817 78.6776 36.6516 78.096C36.6516 78.096 37.6753 77.083 38.1053 76.9009C39.9514 76.1362 43.5959 75.1728 45.2714 75.8448C45.7935 76.0534 46.551 77.2353 46.4452 77.7186C46.3395 78.2019 44.8448 79.791 44.9881 81.3139C45.1315 82.8367 43.7699 87.8622 43.8074 89.865C43.8074 90.2756 43.8313 90.792 43.8552 91.3548C43.8552 91.7123 43.8552 97.9759 41.371 101.174"
        fill="#FF6C4E"
      />
      <path
        d="M33.3859 108.997C30.2192 107.292 30.8334 101.161 32.9286 93.5033C33.9524 89.7425 37.5081 80.2611 37.4364 79.9301C37.2448 79.2927 36.9817 78.6776 36.6516 78.096C36.6516 78.096 37.6753 77.083 38.1053 76.9009C39.9514 76.1362 43.5959 75.1728 45.2714 75.8448C45.7935 76.0534 46.551 77.2353 46.4452 77.7186C46.3395 78.2019 44.8448 79.791 44.9881 81.3139C45.1315 82.8367 43.7699 87.8622 43.8074 89.865C43.8074 90.2756 43.8313 90.792 43.8552 91.3548C43.8552 91.7123 43.8552 97.9759 41.371 101.174"
        stroke="#263238"
        strokeWidth="0.29971"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.6826 61.4334C43.7508 62.0425 45.1055 66.3992 45.5253 70.8254C46.3816 70.5237 47.1867 70.0997 47.914 69.5674C48.7637 68.8457 50.5415 69.4218 50.4699 69.8985C50.3982 70.3752 48.7636 70.2957 47.3816 72.0967C45.9996 73.8976 45.7164 75.8939 43.8805 76.854C42.0446 77.814 39.1918 78.155 38.0794 76.8738C36.9669 75.5926 35.7316 68.7961 36.165 68.0876C36.5347 67.5705 36.997 67.122 37.53 66.7634C37.53 66.7634 37.9975 65.4392 38.7072 65.3365C39.417 65.2339 41.0959 65.2008 41.0959 65.2008C41.4882 64.9186 41.975 64.7887 42.4609 64.8366C42.4609 64.8366 41.9081 59.7384 41.4372 58.0268C40.9663 56.3152 43.1263 56.3252 43.6826 61.4334Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.29971"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.4837 64.8379C43.934 65.4735 44.5755 68.1484 44.5755 68.3405C44.2857 69.2913 43.9369 70.2244 43.5313 71.1346C43.1696 71.896 42.3882 71.1346 42.3882 71.1346C42.3882 71.1346 42.1493 72.1277 41.5999 71.6941C40.9004 71.1346 40.9004 69.3237 40.9004 69.3237C40.9004 69.3237 40.815 70.83 40.409 71.5649C40.0029 72.2999 39.1839 71.8099 39.3033 69.6349C39.3033 69.6349 37.6313 69.2376 37.5732 68.8404"
        stroke="#263238"
        strokeWidth="0.29971"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.8996 69.3227C40.4901 68.3031 38.917 67.3662 38.917 67.3662"
        stroke="#263238"
        strokeWidth="0.29971"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.3875 71.132C42.3875 71.132 43.2099 68.8146 42.9881 68.3379C42.7663 67.8612 41.3672 66.5469 41.3672 66.5469"
        stroke="#263238"
        strokeWidth="0.29971"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.5232 70.8359C45.5232 70.8359 43.9808 71.167 43.2949 73.0739"
        stroke="#263238"
        strokeWidth="0.29971"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.4355 79.917C37.4355 79.917 39.9266 81.9033 42.6054 80.248"
        stroke="#263238"
        strokeWidth="0.29971"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.5327 85.8135C39.0532 86.0854 38.5393 86.2958 38.0039 86.4392"
        stroke="#263238"
        strokeWidth="0.29971"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.5235 79.291C44.3201 81.4722 42.6986 83.4104 40.7461 85.0018"
        stroke="#263238"
        strokeWidth="0.29971"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M54.9062 86.3984C54.9062 86.3984 59.769 90.828 62.6149 92.0264"
        stroke="#263238"
        strokeWidth="0.29971"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.0566 91.1924C50.2761 91.7811 50.4205 92.3937 50.4866 93.0165"
        stroke="#263238"
        strokeWidth="0.29971"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48.3662 87.4375C48.803 88.2353 49.1988 89.0597 49.5571 89.8973"
        stroke="#263238"
        strokeWidth="0.29971"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M68.2129 93.504C68.2129 93.504 76.1706 93.6099 81.279 90.2266"
        stroke="#263238"
        strokeWidth="0.29971"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M84.8378 87.2979C84.1539 87.7488 83.5284 88.2779 82.9746 88.8737"
        stroke="#263238"
        strokeWidth="0.29971"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M88.8339 92.9561C88.0163 93.7788 87.0849 94.4874 86.0664 95.0616"
        stroke="#263238"
        strokeWidth="0.29971"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M90.568 90.8984C90.3089 91.2772 90.0286 91.6419 89.7285 91.9909"
        stroke="#263238"
        strokeWidth="0.29971"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48.584 102.484C48.584 102.484 50.5257 100.044 50.4847 97.1172"
        stroke="#263238"
        strokeWidth="0.29971"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M89.8852 106.788C89.8852 106.788 89.4688 101.879 93.0178 98.29"
        stroke="#263238"
        strokeWidth="0.29971"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M93.7267 100.995C93.8974 101.161 90.6965 103.869 89.9355 105.08"
        stroke="#263238"
        strokeWidth="0.29971"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M58.751 113.135C58.751 113.135 70.3976 117.624 76.4307 117.531"
        stroke="#263238"
        strokeWidth="0.29971"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M62.5603 30.9696C62.5603 29.4109 64.7507 27.5769 64.3544 26.1325C63.942 24.6388 61.0902 24.1219 60.311 22.8126C59.5318 21.5033 60.4744 18.8354 59.3631 17.7573C58.2519 16.6792 55.5233 17.6092 54.1523 16.8403C52.7813 16.0713 52.2779 13.3177 50.7328 12.9176C49.244 12.5331 47.3616 14.6581 45.7469 14.6581C44.1323 14.6581 42.2498 12.5331 40.761 12.9176C39.2213 13.3177 38.6885 16.0843 37.3389 16.8403C35.9893 17.5962 33.2393 16.6818 32.1281 17.7599C31.0168 18.838 31.9754 21.4851 31.1828 22.8152C30.3902 24.1452 27.5519 24.6336 27.1395 26.1325C26.7352 27.5769 28.9336 29.4109 28.9336 30.9696C28.9336 32.5283 26.7432 34.3623 27.1395 35.8067C27.5519 37.3004 30.4036 37.8174 31.1828 39.1267C31.9621 40.4359 31.0195 43.1039 32.1308 44.1819C33.242 45.26 35.9706 44.33 37.3416 45.099C38.7126 45.8679 39.216 48.6216 40.761 49.0216C42.2498 49.4061 44.1323 47.2811 45.7469 47.2811C47.3616 47.2811 49.244 49.4061 50.7328 49.0216C52.2725 48.6216 52.8054 45.8549 54.155 45.099C55.5045 44.343 58.2545 45.2574 59.3658 44.1793C60.4771 43.1013 59.5184 40.4541 60.311 39.1241C61.1036 37.794 63.942 37.3056 64.3544 35.8067C64.7587 34.3623 62.5603 32.5283 62.5603 30.9696Z"
        fill="#46BE46"
      />
      <path
        d="M45.0452 37.6778L37.7806 30.2169C37.6137 30.0447 37.5242 29.8152 37.5317 29.5789C37.5392 29.3426 37.6432 29.1189 37.8207 28.957C37.9983 28.7951 38.2349 28.7082 38.4784 28.7156C38.722 28.7229 38.9525 28.8237 39.1194 28.996L44.823 34.8566L52.026 24.7252C52.0915 24.6225 52.1778 24.5338 52.2797 24.4644C52.3816 24.395 52.497 24.3465 52.6188 24.3217C52.7406 24.2969 52.8664 24.2964 52.9884 24.3202C53.1105 24.3441 53.2262 24.3917 53.3287 24.4603C53.4312 24.5288 53.5182 24.6169 53.5845 24.7191C53.6508 24.8213 53.6951 24.9355 53.7145 25.0548C53.7339 25.1741 53.7281 25.296 53.6975 25.4131C53.6668 25.5301 53.612 25.6399 53.5363 25.7358L45.0452 37.6778Z"
        fill="#050506"
      />
    </svg>
  );
}
