import { Button } from '@material-ui/core';
import React from 'react';

export default function CreateRemittanceButton({ onClick, disabled }) {
  return (
    <Button
      variant="contained"
      size="small"
      color="primary"
      onClick={onClick}
      disabled={disabled}
      style={{ width: 144 }}
    >
      Gerar Remessa
    </Button>
  );
}
