import { Box, Button, Typography } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import RemittanceModule from 'modules/RemittanceModule';
import { ProjectContext } from 'providers/ProjectProvider';
import Icon from 'components/Icon';
import ICON_TYPES from '@constants/iconsPaths';
import useStyles from './styles';

export default function RemittancePopup({ hide, onCreate }) {
  const classes = useStyles();
  const history = useHistory();
  const { projectSlug } = useParams();

  const { project } = useContext(ProjectContext);

  const [loading, setLoading] = useState(false);

  const generateRemittance = async () => {
    setLoading(true);

    const onRedirect = () => history.push(`/${projectSlug}/financeiro`);
    RemittanceModule.generateRemittance(project._id, onRedirect)
      .then((remittance) => {
        if (remittance) {
          onCreate(remittance);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (hide) return null;

  return (
    <Box className={classes.card}>
      <Icon src={ICON_TYPES.POPUP.info} size={24} />

      <Typography className={classes.text} variant="body2">
        Novos títulos foram emitidos
      </Typography>

      <Button
        variant="contained"
        color="primary"
        onClick={generateRemittance}
        disabled={loading}
      >
        Gerar Remessa
      </Button>
    </Box>
  );
}

RemittancePopup.propTypes = {
  hide: PropTypes.bool.isRequired,
  onCreate: PropTypes.func.isRequired,
};
