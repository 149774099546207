import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  dialog1: {
    '& .MuiPaper-root': {
      width: '100%',

      '& .header': {
        '& .close-btn': {
          position: 'absolute',
          right: 0,
          top: 0,
        },
      },

      '& .dialog-title': {
        fontSize: 24,
        lineHeight: '30px',
        color: '#3E475B',
        marginBottom: 20
      },

      '& .MuiDialogContent-root': {
        padding: `${theme.spacing(3)}px`
      },

      '& .MuiDialogActions-root': {
        padding: `${theme.spacing(3)}px`
      }
    }
  },
}))
