import React from 'react';

function WalletIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M18.503 6.951l-2.366-3.154a2 2 0 00-2.71-.465L7.928 6.998"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M18.503 11.5h2.501a1 1 0 011 1v3.002a1 1 0 01-1 1h-2.501a2.501 2.501 0 01-2.501-2.501v0a2.501 2.501 0 012.501-2.501v0z"
        clipRule="evenodd"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M21.004 11.5v-2a2.501 2.501 0 00-2.501-2.502H5.498A2.501 2.501 0 002.997 9.5v9.004a2.501 2.501 0 002.5 2.5h13.006a2.501 2.501 0 002.501-2.5v-2.001"
      />
    </svg>
  );
}

export default WalletIcon;
