const { makeStyles } = require('@material-ui/core');

const useStyles = makeStyles((theme) => ({
  row: {
    backgroundColor: ({ hasSomethingSelected }) =>
      hasSomethingSelected ? theme.palette.grey[700] : 'transparent',

    '& .MuiTableCell-head': {
      paddingTop: 0,
      paddingBottom: 0,

      borderBottomColor: theme.palette.grey[500],

      color: ({ hasSomethingSelected }) =>
        hasSomethingSelected
          ? theme.palette.secondary.dark
          : theme.palette.secondary.light,
    },
  },

  checkbox: {
    '& svg': {
      color: theme.palette.secondary.main,
    },
  },
}));

export default useStyles;
