import React from 'react';
import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import Loading from 'components/Loading';
import SalesContractInvoicesZeroStateSvg from 'assets/images/ZeroStates/SalesContractInvoicesZeroStateImage';
import { useSalesContractInvoicesListContext } from 'providers/ListProviders/SalesContractInvoicesListProvider';

function SalesContractInvoicesListZeroState({ children }) {
  const { invoices, isLoading, variables } =
    useSalesContractInvoicesListContext();
  const { search, filterOptions } = variables;

  const hasData = Boolean(invoices.length);
  const hasSearchFilter = search;
  const hasStatusFilter = filterOptions.status !== 'all';
  const hasPaidAtFilter = filterOptions.paidAtStart || filterOptions.paidAtEnd;
  const hasDueDateFilter =
    filterOptions.dueDatestart || filterOptions.dueDateEnd;
  const hasTypeFilter = filterOptions.type !== null;

  const hasSomeFilter =
    hasSearchFilter ||
    hasStatusFilter ||
    hasPaidAtFilter ||
    hasDueDateFilter ||
    hasTypeFilter;

  const isZeroState = !hasData && !hasSomeFilter;

  const mainMessage = !isZeroState
    ? 'Não encontramos nenhum título, tente uma nova pesquisa'
    : 'Os títulos ainda estão sendo gerados, aguarde';

  if (isLoading) {
    return <Loading height={100} />;
  }
  if (hasData) {
    return children;
  }

  return (
    <Box width="100%" mt={10}>
      <Box display="flex" alignItems="center" justifyContent="center">
        <SalesContractInvoicesZeroStateSvg />
      </Box>

      <Box justifyContent="center" alignItems="center" display="flex">
        <Box
          color="#495058"
          justifyContent="center"
          alignItems="center"
          display="flex"
          mt={3}
          width="50%"
        >
          <Typography align="center" display="block" variant="h3">
            {mainMessage}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default SalesContractInvoicesListZeroState;

SalesContractInvoicesListZeroState.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};
