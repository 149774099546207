import Swal from 'sweetalert2';

export default (onTabChange) => ({
  INVALID_RESERVATION: () => {
    Swal.fire('Não foi possível encontrar a reserva.', '', 'error');
  },
  PROPERTY_RESERVED: () => {
    Swal.fire(
      'Não foi possível reativar a reserva porque o imóvel já foi reservado.',
      '',
      'error'
    );
  },
  INVALID_EXPIRATION: () => {
    Swal.fire(
      'A data de expiração deve ser maior que o horário atual.',
      '',
      'error'
    );
    onTabChange(0);
  },
  INVALID_ENTRANCE: () => {
    Swal.fire('Valor da entrada inválido.', '', 'error');
    onTabChange(1);
  },
  INVALID_INSTALLMENTS: () => {
    Swal.fire('Quantidade de parcelas mensais inválida.', '', 'error');
    onTabChange(1);
  },
  INVALID_FIRST_MONTHLY_INSTALLMENT: () => {
    Swal.fire('Data da primeira parcela mensal inválida.', '', 'error');
    onTabChange(1);
  },
  INVALID_FIRST_ANNUALLY_INSTALLMENT: () => {
    Swal.fire('Data da primeira parcela anual inválida.', '', 'error');
    onTabChange(1);
  },
  DEFAULT: () => {
    Swal.fire('Não foi possível salvar as alterações.', '', 'error');
  },
});
