/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box, ButtonBase, Typography } from '@material-ui/core';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import PropTypes from 'prop-types';
import { useStyles } from './styles';
import CalendarBody from './body';

function Calendar(props) {
  const classes = useStyles();

  const currentDateRef = useRef(null);
  const startDateRef = useRef(null);
  const endDateRef = useRef(null);

  const [currentDate, setCurrentDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const calendarTitle = useMemo(() => {
    let value = '';

    if (currentDate) {
      value = currentDate.toLocaleString('pt-BR', {
        month: 'long',
        year: 'numeric',
      });

      value = value.charAt(0).toUpperCase() + value.slice(1);
    }

    return value;
  }, [currentDate]);

  const handleSelect = (date) => {
    let newStartDate = startDateRef.current;
    let newEndDate = endDateRef.current;

    if (newStartDate && newEndDate) {
      newStartDate = null;
      newEndDate = null;
    }

    if (newStartDate) {
      newEndDate = date;
    } else {
      newStartDate = date;
    }

    if (
      newStartDate &&
      newEndDate &&
      newStartDate.getTime() > newEndDate.getTime()
    ) {
      newEndDate = newStartDate;
      newStartDate = date;
    }

    startDateRef.current = newStartDate;
    endDateRef.current = newEndDate;

    setStartDate(newStartDate);
    setEndDate(newEndDate);

    props.onChange({ startDate: newStartDate, endDate: newEndDate });
  };

  const handlePreviousMonth = (e) => {
    e.stopPropagation();

    const date = new Date(currentDate.getTime());
    date.setMonth(date.getMonth() - 1);

    setCurrentDate(date);
  };

  const handleNextMonth = (e) => {
    e.stopPropagation();

    const date = new Date(currentDate.getTime());
    date.setMonth(date.getMonth() + 1);

    setCurrentDate(date);
  };

  useEffect(() => {
    const date = new Date();
    date.setDate(1);
    date.setHours(0, 0, 0, 0);

    currentDateRef.current = date;
    setCurrentDate(date);
  }, []);

  useEffect(() => {
    if (
      props.currentDate &&
      props.currentDate.getTime() !== currentDateRef.current?.getTime()
    ) {
      const date = new Date(props.currentDate.getTime());
      date.setDate(1);

      currentDateRef.current = date;
      setCurrentDate(date);
    }
  }, [props.currentDate]);

  useEffect(() => {
    const isStartDateChange =
      startDateRef.current?.getTime() !== props.startDate?.getTime();

    if (isStartDateChange) {
      startDateRef.current = props.startDate;
      setStartDate(props.startDate);
    }
  }, [props.startDate]);

  useEffect(() => {
    const isEndDateChange =
      endDateRef.current?.getTime() !== props.endDate?.getTime();

    if (isEndDateChange) {
      endDateRef.current = props.endDate;
      setEndDate(props.endDate);
    }
  }, [props.endDate]);

  return (
    <Box className={classes.calendar}>
      <Box className={classes.header}>
        <Typography className="title" variant="body2">
          {calendarTitle}
        </Typography>

        <Box className="controls">
          <ButtonBase className="previous-month" onClick={handlePreviousMonth}>
            <FiChevronUp />
          </ButtonBase>
          <ButtonBase className="next-month" onClick={handleNextMonth}>
            <FiChevronDown />
          </ButtonBase>
        </Box>
      </Box>

      <Box className={classes.body}>
        <CalendarBody
          currentDate={currentDate}
          startDate={startDate}
          endDate={endDate}
          onSelect={handleSelect}
        />
      </Box>
    </Box>
  );
}

Calendar.propTypes = {
  endDate: PropTypes.instanceOf(Date),
  startDate: PropTypes.instanceOf(Date),
  currentDate: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired,
};

Calendar.defaultProps = {
  endDate: null,
  startDate: null,
  currentDate: null,
};

export default Calendar;
