export default `
    query GetProperty(
      $id: ID!
    ){
      getProperty(
        id: $id
      ) {
        _id
        projectId
        unity
        description
        observation
        status
        price
        m2price
        terrainDimension
        totalArea
        privateArea
        comparisonFront
        comparisonBottom
        comparisonLeft
        comparisonRight
        comparisonExtra
        measureFront
        measureBottom
        measureLeft
        measureRight
        mark {
          posX,
          posY
        }
        createdAt
        active
      }
    }
  `;
