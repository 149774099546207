const GET_INVITE_QUERY = `
    query GetInvite (
      $id: ID!
    ) {
      getInvite (id: $id) {
        _id
        email
        type
        expiration
        projectId
        project {
          _id
          title
          slug
        }
      }
    }
  `;

export default GET_INVITE_QUERY;
