import { useCallback, useEffect, useRef, useState } from 'react';

import useQuery from 'hooks/useQuery';
import AlertModule from 'modules/AlertModule';
import GET_CUSTOMERS_QUERY from 'queries/getCustomersQuery';

export default function useGetCustomers(variables, search) {
  const [pages, setPages] = useState(1);
  const [customers, setCustomers] = useState([]);
  const [executeGetCustomers, isLoading] = useQuery(GET_CUSTOMERS_QUERY);
  const isFirstRender = useRef(true);

  const onResolve = ({ getCustomers: data }) => {
    setPages(data.pages);
    setCustomers(data.customers);
  };

  const onReject = AlertModule.onError(
    'Não foi possível carregar a lista de reservas.'
  );

  const createGetter = (executeQuery) => () =>
    executeQuery({ ...variables, search })
      .then(onResolve)
      .catch(onReject);

  const getCustomersBySearch = useCallback(
    createGetter(executeGetCustomers.debounced),
    [variables, search]
  );

  const getCustomers = useCallback(createGetter(executeGetCustomers), [
    variables,
    search,
  ]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    getCustomersBySearch();
  }, [search]);

  useEffect(() => {
    getCustomers();
  }, [variables]);

  return { customers, pages, isLoading };
}
