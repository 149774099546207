export default function validateCostumerData(customer) {
  return (
    customer.rg &&
    customer.email &&
    customer.postalCode &&
    customer.address &&
    customer.addressNumber &&
    customer.district &&
    customer.city &&
    customer.state
  );
}
