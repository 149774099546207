import Url from 'services/url';
import EnvironmentModule from 'modules/EnvironmentModule';
import RestApi from './rest';
import GraphqlApi from './graphql';
import WebsocketApi from './websocket';

const API_SERVER = EnvironmentModule.get('API_SERVER');
const serverUrl = Url(API_SERVER);

const api = {
  ...WebsocketApi(serverUrl),
  ...GraphqlApi(serverUrl),
  ...RestApi,
};

export default api;
