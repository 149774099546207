import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  row: {
    background: ({ isSelected }) =>
      isSelected ? theme.palette.grey[700] : 'transparent',

    '&:hover': {
      background: ({ isClickable }) =>
        isClickable ? theme.palette.grey[700] : 'transparent',

      cursor: ({ isClickable }) => (isClickable ? 'pointer' : 'default'),
    },

    '& MuiTableCell-root': {
      borderBottomColor: theme.palette.grey[500],
    },
  },

  checkbox: {
    padding: '0px',
    '& svg': {
      color: theme.palette.secondary.main,
    },
  },
}));

export default useStyles;
