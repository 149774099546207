import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import viacep from 'services/viacep';
import AlertModule from 'modules/AlertModule';
import { ProjectContext } from 'providers/ProjectProvider';
import { useRealEstateAgentsListContext } from 'providers/ListProviders/RealEstateAgentsListProvider';

import BaseDialog from 'components/Dialogs/BaseDialog';
import validateInputs from './helpers/validate-inputs';
import Form from './components/Form';
import useCreateRealEstateAgent from './hooks/useCreateRealEstateAgent';

function CreateRealEstateAgentDialog({ open, onClose }) {
  const { project } = useContext(ProjectContext);
  const { updateList } = useRealEstateAgentsListContext();
  const [step, setStep] = useState(0);

  const [realEstateAgent, setRealEstateAgent] = useState({
    active: true,
    reuseEmail: false,
  });

  const createRealEstateAgent = useCreateRealEstateAgent(
    project._id,
    realEstateAgent
  );

  const handleSubmit = () => {
    const validate = validateInputs(realEstateAgent);

    if (!validate.success) {
      setStep(validate.step);
      AlertModule.error(validate.message);
    }

    createRealEstateAgent().then(() => {
      updateList();
      onClose();
    });
  };

  const searchAddress = () => {
    viacep.buscar(realEstateAgent.postalCode).then((data) => {
      setRealEstateAgent({
        ...realEstateAgent,
        address: data.logradouro,
        addressComplement: data.complemento,
        district: data.bairro,
        city: data.localidade,
        state: data.uf,
      });
    });
  };

  useEffect(() => {
    if (!open) {
      setRealEstateAgent({
        reservationLimit: 10,
        active: true,
      });

      setStep(0);
    }
  }, [open]);

  useEffect(() => {
    if (
      realEstateAgent &&
      realEstateAgent.reuseEmail &&
      realEstateAgent.active &&
      realEstateAgent.email !== realEstateAgent.username
    ) {
      setRealEstateAgent({
        ...realEstateAgent,
        username: realEstateAgent.email,
      });
    }
  }, [realEstateAgent]);

  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      title="Novo Corretor"
      maxWidth="sm"
    >
      <Form
        projectId={project._id}
        realEstateAgent={realEstateAgent}
        setRealEstateAgent={setRealEstateAgent}
        submit={handleSubmit}
        searchAddress={searchAddress}
        step={step}
        setStep={setStep}
        action="create"
      />
    </BaseDialog>
  );
}

CreateRealEstateAgentDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CreateRealEstateAgentDialog;
