import React, { useEffect, useState } from 'react';

import AlertModule from 'modules/AlertModule';
import { useRealEstatesListContext } from 'providers/ListProviders/RealEstatesListProvider';

import BaseDialog from 'components/Dialogs/BaseDialog';
import generateObjectChecksum from 'utils/generateObjectChecksum';
import Form from './components/Form';
import validateInputs from './helpers/validate-inputs';
import useGetRealEstate from './hooks/useGetRealEstate';
import useEditRealEstate from './hooks/useEditRealEstate';

export default function EditRealEstateDialog({ realEstateId, open, onClose }) {
  const [step, setStep] = useState(0);

  const { realEstate, isLoading, checksum, setRealEstateField } =
    useGetRealEstate(realEstateId);

  const { updateList } = useRealEstatesListContext();

  const onSuccess = () => {
    updateList();
    onClose();
  };

  const editRealEstate = useEditRealEstate(onSuccess);

  const handleClose = () => {
    const closeWithConfirmation =
      generateObjectChecksum(realEstate) !== checksum;

    if (closeWithConfirmation) {
      AlertModule.confirmation(
        'Deseja realmente fechar sem salvar as alterações da imobiliária?'
      ).then((result) => {
        if (result.isConfirmed) {
          onClose();
        }
      });
    } else {
      onClose();
    }
  };

  const handleSubmit = () => {
    const error = validateInputs(realEstate);
    if (error) {
      setStep(error.step);
      AlertModule.error(error.message);
      return;
    }
    AlertModule.wait();
    editRealEstate(realEstate);
  };

  useEffect(() => {
    setStep(0);
  }, []);

  return (
    <BaseDialog
      open={open}
      onClose={handleClose}
      title="Imobiliária"
      maxWidth="sm"
      loading={isLoading}
    >
      <Form
        realEstate={realEstate}
        setRealEstate={setRealEstateField}
        submit={handleSubmit}
        step={step}
        setStep={setStep}
        action="edit"
      />
    </BaseDialog>
  );
}
