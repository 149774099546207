/* eslint-disable react-hooks/exhaustive-deps */
import { MapInteractionCSS } from 'react-map-interaction';
import React, { useState, useRef, useEffect } from 'react';

import animate from 'utils/animate';
import Mark from './Mark';
import useStyles from './styles';
import mathBetween from './helpers/math-between';
import getContainerBounds from './coordinates/get-container-bounds';
import getCenterCoordinates from './coordinates/get-center-coordinates';
import getScaledMapCenter from './coordinates/get-scaled-map-center';
// If you want to have control over the scale and translation,
// then use the `scale`, `translation`, and `onChange` props.
export default function Map({
  defaultPosition,
  defaultScale,
  source,
  mapDimension,
  onMapClick,
  marks,
  selectedPropertyId,
  onMarkClick,
  animated = false,
}) {
  const classes = useStyles();
  const [showMarks, setShowMarks] = useState(true);

  const mapContainerRef = useRef(null);
  const [activeCoordinate, setActiveCoordinate] = useState(null);
  const [scale, setScale] = useState(defaultScale || 0.5);
  const [position, setPosition] = useState(
    defaultPosition || {
      x: 0,
      y: 0,
    }
  );
  let showMarkTimer;

  const delayedShowMarks = (timer = 750) => {
    clearTimeout(showMarkTimer);
    showMarkTimer = setTimeout(() => setShowMarks(true), timer);
  };
  const hideMarks = () => {
    setShowMarks(false);
    delayedShowMarks();
  };

  const withoutChange = (newScale, newPosition) =>
    newScale === scale &&
    newPosition.x === position.x &&
    newPosition.y === position.y;
  const onChange = ({ scale, translation, shouldHideMarks }) => {
    const bounds = getContainerBounds(
      mapContainerRef.current,
      mapDimension,
      scale
    );
    const newPosition = {
      x: mathBetween(translation.x, bounds.xMin, bounds.xMax),
      y: mathBetween(translation.y, bounds.yMin, bounds.yMax),
    };
    if (withoutChange(scale, newPosition)) {
      return;
    }
    if (shouldHideMarks) hideMarks();
    setScale(scale);
    setPosition(newPosition);
  };

  function handleMapClick(e) {
    if (onMapClick && !e.defaultPrevented) {
      const pos = {
        x: e.nativeEvent.offsetX,
        y: e.nativeEvent.offsetY,
      };
      setActiveCoordinate(pos);
      onMapClick(pos, 1);
    }
  }
  const handleMarkClick = function (e, mark) {
    if (onMarkClick && !e.defaultPrevented) {
      setActiveCoordinate({ x: mark.posX, y: mark.posY });
      onMarkClick(mark);
    }
  };

  const prevActiveMark = useRef({ value: activeCoordinate }).current;
  const centerToActiveMark = () => {
    const center = getCenterCoordinates(mapContainerRef.current);
    const animateValues = {
      scale: { from: scale, to: 1 },
      x: { from: position.x, to: center.x - activeCoordinate.x },
      y: { from: position.y, to: center.y - activeCoordinate.y },
    };

    if (animated) {
      animate.linear(500, animateValues, ({ scale, x, y }) =>
        onChange({
          scale,
          translation: { x, y },
          shouldHideMarks: true,
        })
      );
    } else {
      onChange({
        scale: 1,
        translation: { x: animateValues.x.to, y: animateValues.y.to },
        shouldHideMarks: true,
      });
    }

    return () => (prevActiveMark.value = activeCoordinate);
  };

  useEffect(() => {
    if (!activeCoordinate || prevActiveMark.value === activeCoordinate) return;
    return centerToActiveMark();
  }, [activeCoordinate, scale]);

  useEffect(() => {
    if (defaultPosition) return;
    onChange({
      scale,
      translation: getScaledMapCenter(
        mapContainerRef.current,
        mapDimension,
        scale
      ),
    });
  }, []);

  useEffect(() => {
    if (showMarks) return;
    const onChangeEnd = () => setShowMarks(true);

    // end gesture
    const touchAndMouseEndOptions = { capture: true };
    window.addEventListener('wheel', delayedShowMarks, touchAndMouseEndOptions);
    window.addEventListener('touchend', onChangeEnd, touchAndMouseEndOptions);
    window.addEventListener('mouseup', onChangeEnd, touchAndMouseEndOptions);

    return () => {
      window.removeEventListener('touchend', onChangeEnd);
      window.removeEventListener('mouseup', onChangeEnd);
      window.removeEventListener('wheel', delayedShowMarks);
    };
  }, [showMarks]);

  return (
    <div className={classes.root} ref={mapContainerRef}>
      <MapInteractionCSS
        value={{ scale, translation: position }}
        onChange={(changes) => {
          hideMarks();
          window.requestAnimationFrame(() => onChange(changes));
        }}
        minScale={0.5}
        maxScale={1}
      >
        <img
          src={source}
          alt="Property Map"
          onClick={handleMapClick}
          onTouchEnd={handleMapClick}
        />
        {showMarks &&
          marks &&
          marks.map((mark) => (
            <Mark
              key={mark.propertyId}
              status={mark.propertyStatus}
              selected={mark.propertyId === selectedPropertyId}
              left={mark.posX}
              top={mark.posY}
              size={24}
              onClick={(e) => handleMarkClick(e, mark)}
              onTouchEnd={(e) => handleMarkClick(e, mark)}
            />
          ))}
      </MapInteractionCSS>
    </div>
  );
}
