const INVITE_STATUS = {
  PENDING: {
    value: 'pending',
    label: 'Pendente',
  },
  ACCEPTED: {
    value: 'accepted',
    label: 'Aceito',
  },
  REJECTED: {
    value: 'rejected',
    label: 'Rejeitado',
  },
  EXPIRED: {
    value: 'expired',
    label: 'EXPIRADO',
  },
  CANCELED: {
    value: 'canceled',
    label: 'Cancelado',
  },
};

export const INVITE_STATUS_VALUES = Object.values(INVITE_STATUS);

export default INVITE_STATUS;
