const GET_REMITTANCES_QUERY = `
        query getRemittancesQuery (
            $projectId: ID!
            $page:Int
            $itemsPerPage:Int
            $search:String
        ) {
          getRemittances (
            projectId: $projectId
            page:$page
            search:$search
            itemsPerPage:$itemsPerPage
          ) {
              remittances{
                _id
                projectId
                name
                fileId
                createdAt
                invoicesIds
              }
              page
              pages
            } 
          }
    `;

export default GET_REMITTANCES_QUERY;
