import { TextField } from '@material-ui/core'
import React from 'react'
import validatePostalCode from 'utils/validatePostalCode'

export default function PostalCodeTextField(props) {
    const handleValue = () => {
        let value = String(props.value)

        value = value
            .replace(/[^0-9]/g, '')
            .replace(/^([0-9]{0,5})([0-9]{0,3})/g, '$1-$2')
            .replace(/[^0-9]+$/, '')
            .slice(0, 9)

        return value
    }

    const handleChange = (e) => {
        let value = String(e.target.value)

        value = value.replace(/[^0-9]/g, '')
            .slice(0, 8)

        props.onChange(e, value)
    }

    const handleError = () => {
        if (props.error) return true
        if (!props.value) return false

        return !validatePostalCode(props.value)
    }

    return <TextField {...props}
        value={handleValue()}
        onChange={handleChange}
        error={handleError()}
    />
}