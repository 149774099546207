import React, { useContext, useState } from 'react';
import {
  Box,
  Container,
  useTheme,
  useMediaQuery,
  Button,
} from '@material-ui/core';

import CreateCustomerDialog from 'components/Dialogs/CreateDialogs/CreateCustomerDialog';
import CustomersList from 'components/Lists/CustomersList';
import ProjectsLink from 'components/ProjectsLink';
import CustomersListFilters from 'components/ListFilters/CustomersListFilters';
import CustomersZeroState from 'components/ZeroState/CustomersZeroState';

import { ProjectContext } from 'providers/ProjectProvider';
import CustomersListProvider from 'providers/ListProviders/CustomersListProvider';

import EditCustomerDialog from 'components/Dialogs/EditDialogs/EditCustomerDialog';
import { useStyles } from './styles';

export default function Customers() {
  const classes = useStyles();

  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));

  const { project } = useContext(ProjectContext);

  const [showCreateCustomerDialog, setShowCreateCustomerDialog] =
    useState(false);
  const [showUpdateCustomerDialog, setShowUpdateCustomerDialog] =
    useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const selectCustomer = (customer) => {
    setSelectedCustomer(customer);
    setShowUpdateCustomerDialog(true);
  };
  return (
    <Container className={classes.container}>
      <Box
        display={!isSm ? 'flex' : 'none'}
        justifyContent="space-between"
        alignItems="center"
        mb={5}
        minHeight={40}
      >
        <ProjectsLink mb={0} />

        <Button
          variant="contained"
          name="create-customer-button"
          color="primary"
          onClick={() => setShowCreateCustomerDialog(true)}
          style={{ marginLeft: 'auto' }}
        >
          Cadastrar
        </Button>
      </Box>

      <CustomersListProvider>
        <CustomersListFilters />
        <CustomersZeroState>
          <CustomersList onRowClick={selectCustomer} />
        </CustomersZeroState>
      </CustomersListProvider>

      <EditCustomerDialog
        open={showUpdateCustomerDialog}
        customerId={selectedCustomer?._id}
        onClose={() => {
          setShowUpdateCustomerDialog(false);
          setSelectedCustomer(null);
        }}
      />

      <CreateCustomerDialog
        show={showCreateCustomerDialog}
        projectId={project._id}
        onClose={() => setShowCreateCustomerDialog(false)}
      />
    </Container>
  );
}
