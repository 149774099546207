import { ButtonBase, Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

import ICON_TYPES from '@constants/iconsPaths';
import Icon from 'components/Icon';
import useStyles from '../styles';

export default function ShowProfileButton({ onShowUserProfile }) {
  const classes = useStyles();

  return (
    <ButtonBase
      className={classes.profileButton}
      onClick={(e) => onShowUserProfile(e.currentTarget)}
      disableRipple
    >
      <Icon src={ICON_TYPES.BASE.userProfile} size={24} />
      <Typography>Perfil</Typography>
    </ButtonBase>
  );
}

ShowProfileButton.propTypes = {
  onShowUserProfile: PropTypes.func.isRequired,
};
