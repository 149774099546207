import { useEffect, useState } from 'react';

import WebSocketController from 'utils/WebSocketController';

const socket = new WebSocketController();

export default (onUpdate) => {
  const [hasUpdated, setHasUpdated] = useState(null);

  useEffect(() => {
    socket.onSalesContractUpdated(() => {
      setHasUpdated(true);
    });

    socket.onSalesContractInvoiceCreated(() => {
      setHasUpdated(true);
    });

    socket.onSalesContractInvoiceUpdated(() => {
      setHasUpdated(true);
    });

    socket.onInvoiceEmitted(() => {
      setHasUpdated(true);
    });

    return () => {
      socket.destroy();
    };
  }, []);

  useEffect(() => {
    if (hasUpdated) {
      onUpdate();
    }
  }, [hasUpdated]);

  return null;
};
