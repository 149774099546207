export default function validateFirstYearlyInstallmentDate(
  monthYear,
  day,
  firstMonthlyInstallmentFulldate
) {
  const dateStr = `${monthYear}-${String(day).padStart(2, '0')}`;
  const date = new Date(dateStr);

  if (Number.isNaN(Date.parse(dateStr))) return null;

  if (firstMonthlyInstallmentFulldate) {
    const minDatetime = new Date(firstMonthlyInstallmentFulldate);
    minDatetime.setMonth(minDatetime.getMonth() + 1);
    minDatetime.setDate(1);

    const maxDatetime = new Date(firstMonthlyInstallmentFulldate);
    maxDatetime.setMonth(maxDatetime.getMonth() + 13);
    maxDatetime.setDate(-1);

    const validate =
      date.getTime() >= minDatetime.getTime() &&
      date.getTime() <= maxDatetime.getTime();

    if (!validate) return null;
  }

  return dateStr;
}
