export default {
  back: '/assets/icons/chevron-left.svg',
  close: '/assets/icons/close.svg',
  search: '/assets/icons/search.svg',
  warning: '/assets/icons/warning.svg',
  dropdownArrow: '/assets/icons/dropdown-arrow.svg',
  userProfile: '/assets/icons/user-profile.svg',
  download: '/assets/icons/download.svg',
  downloadOrange: '/assets/icons/download[orange].svg',
};
