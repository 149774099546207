export default `
        query getSalesContractInvoicesByIds (
            $projectId: ID!
            $invoices: [ID]!
        ) {
          getSalesContractInvoicesByIds (
                  projectId: $projectId
                  invoices: $invoices
            ) {
              _id
              contractNumberId
              description
              value
              dueDate
              } 
          }
    `;
