/* eslint-disable react-hooks/exhaustive-deps */
import verifyAccountQuery from 'queries/verifyAccountQuery';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import api from 'services/api';
import Swal from 'sweetalert2';
import handlerError from 'utils/handleError';

function Verification({ match }) {
  const { verificationCode } = match.params;
  const history = useHistory();

  useEffect(() => {
    if (!verificationCode) {
      Swal.fire('Ops!', 'Código de verificação inválido.', 'error');
    }

    api
      .call(verifyAccountQuery(verificationCode))
      .then((data) => {
        history.push('/login');

        if (data && data.verifyAccount) {
          Swal.fire(
            'Pronto!',
            'Conta verificada com sucesso, bem-vindo!',
            'success'
          );
        }
      })
      .catch((error) => {
        handlerError(
          {
            ALREADY_ACTIVATED: () => {
              Swal.fire('Ops!', 'Sua conta já foi verificada!', 'warning');
            },
            DEFAULT: () => {
              Swal.fire('Ops!', 'Codigo de verificação inválido.', 'error');
            },
          },
          error.message
        );
      });
  }, []);
  /* eslint-disable-next-line */
  return <></>;
}

export default Verification;
