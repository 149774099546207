import { Box, Grid, Typography, useTheme } from '@material-ui/core';
import InformationBlock from 'components/InformationBlock';
import React from 'react';
import formatCpf from 'utils/formatCpf';

export default function CustomerData({ customer }) {
  const theme = useTheme();
  return (
    <Grid item md={6}>
      <Box marginTop={5}>
        <Box marginBottom={1}>
          <Typography
            style={{ fontWeight: '600', color: theme.palette.secondary.dark }}
          >
            Informações do cliente
          </Typography>
        </Box>
        <Grid container spacing={1}>
          <InformationBlock label="Nome">{customer.name}</InformationBlock>
          <InformationBlock label="CPF">
            {formatCpf(customer.cpf)}
          </InformationBlock>
        </Grid>
      </Box>
    </Grid>
  );
}
