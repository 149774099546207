import AlertModule from 'modules/AlertModule';
import getRealEstateAgentQuery from 'queries/getRealEstateAgentQuery';
import { useCallback } from 'react';
import api from 'services/api';

export default function useGetRealEstateAgent(id) {
  const execute = useCallback(
    () =>
      api
        .call(getRealEstateAgentQuery(id))
        .then(({ getRealEstateAgent: data }) => ({
          ...data,
          reuseEmail: data.email && data.email === data.username,
        }))
        .catch((error) => {
          AlertModule.error('Não foi possível encontrar o corretor.');
          throw error;
        }),
    [id]
  );

  return execute;
}
