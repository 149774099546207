import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  header: {
    borderBottom: '2px solid rgba(134, 139, 167, 0.1)',
  },

  links: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 16,
    paddingRight: 16,
  },

  link: {
    fontSize: 12,
    color: theme.palette.text.primary,
    textTransform: 'uppercase',
    display: 'inline-block',
    margin: '16px 20px',

    '&.active': {
      color: theme.palette.secondary.dark,
      textDecoration: 'underline',
    },

    '&:hover': {
      color: theme.palette.secondary.dark,
    },
  },

  projectTitle: {
    fontSize: 12,
    letterSpacing: 1,
    color: theme.palette.text.secondary,
  },
}));
