import EnvironmentModule from 'modules/EnvironmentModule';
import Url from 'services/url';
import { exportCsvFile } from 'utils/exportFile';
import onArrayBufferError from '../helpers/onArrayBufferError';

const API_SERVER = EnvironmentModule.get('API_SERVER');
const serverUrl = Url(API_SERVER);
const DOWNLOAD_API_ENDPOINT = serverUrl.appendPath('/downloads').toString();

export default (axios) => ({
  exportList(name, variables) {
    const url = `${DOWNLOAD_API_ENDPOINT}/${name}`;

    return axios
      .post(url, variables, {
        withCredentials: true,
        responseType: 'arraybuffer',
      })
      .then(({ data }) => {
        const blob = new Blob([data]);
        exportCsvFile(`${name}_${Date.now()}.csv`, blob);
      })
      .catch(onArrayBufferError);
  },

  exportInvoices({
    projectId,
    contractNumberId,
    search,
    sortOptions,
    filterOptions,
  }) {
    return this.exportList('invoices', {
      projectId,
      search,
      sortOptions,
      contractNumberId,
      filterOptions,
    });
  },

  exportProperties({ projectId, search, sortOptions, filterOptions }) {
    return this.exportList('properties', {
      projectId,
      search,
      sortOptions,
      filterOptions,
    });
  },

  exportReservations({ projectId, search, sortOptions, filterOptions }) {
    return this.exportList('reservations', {
      projectId,
      search,
      sortOptions,
      filterOptions,
    });
  },

  exportSalesContracts({ projectId, search, filterOptions }) {
    return this.exportList('salescontracts', {
      projectId,
      search,
      filterOptions,
    });
  },

  exportCustomers({ projectId, search, sortOptions }) {
    return this.exportList('customers', { projectId, search, sortOptions });
  },

  exportRealEstates({ projectId, search, sortOptions }) {
    return this.exportList('realestates', { projectId, search, sortOptions });
  },

  exportRealEstateAgents({ projectId, search, sortOptions }) {
    return this.exportList('realestateagents', {
      projectId,
      search,
      sortOptions,
    });
  },

  exportInvites({ projectId, search, sort }) {
    return this.exportList('invites', { projectId, search, sort });
  },
});
