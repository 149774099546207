export default {
  NOT_AUTHENTICATED: 'Você está desconectado, realize o login novamente',
  CNPJ_IN_USE: 'O CNPJ utilizado ja está em uso',
  INVALID_EMAIL: 'E-mail inválido',
  INVALID_USERNAME: 'E-mail de acesso inválido',
  USERNAME_IN_USE: 'Esse e-mail de acesso já está em uso.',
  INVALID_DOCUMENT: 'Documento do beneficiário inválido.',
  INVALID_MAP_DIMENSIONS:
    'A altura ou largura do mapa deve ter exatos 8192 pixels.',
};
