import React from 'react';
import { Typography, Box } from '@material-ui/core';
import formatDate from 'utils/formatDate';
import formatPrice from 'utils/formatPrice';
import TooltipInvoiceValues from 'components/Lists/SalesContractInvoicesList/components/TooltipInvoiceValues';

export default () => [
  {
    title: 'Contrato',
    field: 'contractNumberId',
    render: (rowData) => `#${rowData.contractNumberId}`,
  },
  {
    title: 'Detalhes',
    field: 'description',
    render: (rowData) => rowData.description,
  },

  {
    title: 'Vencimento',
    field: 'dueDate',
    render: (rowData) => formatDate(rowData.dueDate),
  },

  {
    title: 'Valor',
    field: 'value',
    render: (rowData) => (
      <Box>
        <Typography component="span">{formatPrice(rowData.value)}</Typography>

        <TooltipInvoiceValues salesContractInvoice={rowData} />
      </Box>
    ),
  },
];
