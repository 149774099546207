import { Redirect, Route, Switch, useParams } from 'react-router-dom';
import NotFound from 'components/NotFound';
import React from 'react';
import InteractiveMap from './InteractiveMap';
import Properties from './Properties';
import Reservations from './Reservations';

export default function Router() {
  const { projectSlug } = useParams();

  return (
    <Switch>
      <Route exact path="/:projectSlug">
        <Redirect to={`/${projectSlug}/imoveis`} />
      </Route>
      <Route exact path="/:projectSlug/imoveis" component={Properties} />
      <Route exact path="/:projectSlug/reservas" component={Reservations} />
      <Route exact path="/:projectSlug/mapa" component={InteractiveMap} />
      <Route path="*" component={NotFound} />
    </Switch>
  );
}
