import Loading from 'components/Loading';
import NotFound from 'components/NotFound';
import useQuery from 'hooks/useQuery';
import GET_PROJECT_BY_SLUG_QUERY from 'queries/getProjectBySlugQuery';
import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useContext,
  useCallback,
} from 'react';
import WebSocketController from 'utils/WebSocketController';
import { AuthContext } from './AuthProvider';

export const ProjectContext = React.createContext(null);

const socket = new WebSocketController();

export default function ProjectProvider({ slug, children }) {
  const listeningToProject = useRef(null);

  const { auth } = useContext(AuthContext);

  const [executeGetProjectBySlug] = useQuery(GET_PROJECT_BY_SLUG_QUERY);

  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);

  const listenToProject = (projectId) => {
    if (listeningToProject.current !== projectId) {
      if (projectId) {
        console.log(`Start to listening to the ${projectId} project`);
      } else {
        console.log('No longer listening to the project');
      }

      socket.joinProject(projectId);
      listeningToProject.current = projectId;
    }
  };

  const getProjectBySlug = useCallback((variables) => {
    executeGetProjectBySlug(variables)
      .then(({ getProjectBySlug: data }) => {
        setProject(data);
        listenToProject(data._id);
      })
      .catch(() => {
        setProject(null);
        listenToProject(null);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    socket.onReconnect(() => {
      const projectId = listeningToProject.current;

      if (projectId) {
        listenToProject(null);
        listenToProject(projectId);
      }
    });

    return () => {
      listenToProject(null);
      socket.destroy();
    };
  }, []);

  useEffect(() => {
    if (auth) {
      getProjectBySlug({ slug });
    } else {
      setProject(null);
      listenToProject(null);
    }
  }, [auth]);

  const contextValue = useMemo(
    () => ({
      project,
      discount: project?.discount,
    }),
    [project]
  );

  if (loading) return <Loading />;
  if (!project) return <NotFound />;

  return (
    <ProjectContext.Provider value={contextValue}>
      {children}
    </ProjectContext.Provider>
  );
}
