import { annuallyDueDays } from '@constants/annuallyDueDays';
import handlerError from 'utils/handleError';
import GET_RESERVATION_QUERY from 'queries/getReservationQuery';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import api from 'services/api';
import Swal from 'sweetalert2';
import generateObjectChecksum from 'utils/generateObjectChecksum';
import ReservationDataPreparer from 'utils/ReservationDataPreparer';

export default function useGetReservation({ onClose }) {
  const firstMonthlyInstallmentDateRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [reservation, setReservation] = useState(null);

  const [initialChecksum, setInitialChecksum] = useState(null);
  const [currentChecksum, setCurrentChecksum] = useState(null);

  const hasChanged = useMemo(
    () => initialChecksum !== currentChecksum,
    [currentChecksum, initialChecksum]
  );

  const getReservation = useCallback(
    (id) => {
      setReservation(null);
      setLoading(true);
      api
        .call(GET_RESERVATION_QUERY, { id })
        .then(({ getReservation: data }) => {
          if (data.isContractCreated) {
            throw new Error('CONTRACT_CREATED');
          }
          const Preparer = new ReservationDataPreparer(data);

          const preparedData = Preparer.getPreparedData();
          const status = data.status === 'expired' ? 'pending' : data.status;

          const newReservation = {
            status,
            initialStatus: status,
            ...preparedData,
          };

          setReservation(newReservation);
          setInitialChecksum(generateObjectChecksum(newReservation));
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          handlerError(
            {
              CONTRACT_CREATED: () => {
                Swal.fire(
                  'Você não pode editar uma reserva após o contrato de venda ser emitido.',
                  '',
                  'error'
                );
              },
              DEFAULT: () => {
                Swal.fire('Não foi possível encontrar a reserva.', '', 'error');
              },
            },
            error.message
          );
          setLoading(false);
          onClose();
        });
    },
    [onClose]
  );

  useEffect(() => {
    setCurrentChecksum(generateObjectChecksum(reservation));
  }, [reservation]);

  useEffect(() => {
    if (!reservation) return;

    if (
      firstMonthlyInstallmentDateRef.current !==
      reservation.firstMonthlyInstallmentDate
    ) {
      if (
        !reservation.firstMonthlyInstallmentDate &&
        reservation.firstAnnuallyInstallmentDate
      ) {
        setReservation((value) => ({
          ...value,
          firstAnnuallyInstallmentDate: null,
        }));
      }

      firstMonthlyInstallmentDateRef.current =
        reservation.firstMonthlyInstallmentDate;
    }
  }, [reservation]);

  useEffect(() => {
    if (!reservation) return;

    if (
      !annuallyDueDays.includes(reservation.annuallyDueDay) &&
      reservation.annuallyDueDay
    ) {
      setReservation((value) => ({ ...value, annuallyDueDay: null }));
    }
  }, [reservation]);

  return {
    reservation,
    setReservation,
    getReservation,
    hasChanged,
    loading,
  };
}
