import React, { useCallback, useMemo, useState } from 'react';

import useQuery from 'hooks/useQuery';
import ME_QUERY from 'queries/meQuery/superAdmin';

export const SuperAuthContext = React.createContext(null);

export default function SuperAuthProvider({ children }) {
  const [auth, setAuth] = useState(null);
  const [authError, setAuthError] = useState(null);
  const [execute, isLoading] = useQuery(ME_QUERY);

  const authenticate = useCallback(
    () => {
      execute()
        .then((data) => setAuth(data))
        .catch((error) => {
          setAuthError(error);
        });
    },
    // eslint-disable-next-line
    [execute]
  );
  const value = useMemo(
    () => ({ auth: auth?.me, authError, isLoading, authenticate }),
    [auth, authError, isLoading, authenticate]
  );

  return (
    <SuperAuthContext.Provider value={value}>
      {children}
    </SuperAuthContext.Provider>
  );
}
