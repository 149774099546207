import { useCallback, useEffect, useState } from 'react';

import useQuery from 'hooks/useQuery';
import AlertModule from 'modules/AlertModule';
import GET_PROJECT_USERS_QUERY from 'queries/getProjectUsersQuery';

export default function useGetUsers(variables) {
  const [pages, setPages] = useState(1);
  const [users, setUsers] = useState([]);
  const [executeGetUsers, isLoading] = useQuery(GET_PROJECT_USERS_QUERY);

  const onResolve = ({ getProjectUsers: data }) => {
    setUsers(() => data.map((item) => ({ ...item, _id: item.user._id })));
    setPages(Math.ceil(data.length / 50));
  };

  const onReject = AlertModule.onError(
    'Não foi possível carregar a lista de coordenação.'
  );

  const getUsers = useCallback(
    () =>
      executeGetUsers({ projectId: variables.projectId })
        .then(onResolve)
        .catch(onReject),
    [variables]
  );

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  return { users, pages, isLoading };
}
