/* eslint-disable react-hooks/exhaustive-deps */
import {
  Avatar,
  Box,
  Button,
  Container,
  CssBaseline,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import Swal from 'sweetalert2';
import React, { useState } from 'react';
import { BsArrowRight } from 'react-icons/bs';
import { useHistory, useLocation } from 'react-router-dom';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import Copyright from 'components/Copyright';
import api from 'services/api';
import recoverPasswordQuery from 'queries/recoverPasswordQuery';
import useThemeClasses from 'theme/useThemeClasses';
import handlerError from 'utils/handleError';
import { useStyles } from './styles';

export default function Recover() {
  const classes = useStyles();
  const themeClasses = useThemeClasses;
  const buttonClasses = themeClasses.button();

  const history = useHistory();
  const location = useLocation();

  const [recoverData, setRecoverData] = useState({
    username: location.state?.username || '',
    environment: location.state?.environment || 'administration',
    projectSlug: location.state?.projectSlug || '',
  });

  function handleSubmit(e) {
    e.preventDefault();

    Swal.fire({
      text: 'Aguarde...',
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
    });

    api
      .call(recoverPasswordQuery(recoverData))
      .then((data) => {
        if (data && data.recoverPassword) {
          Swal.fire(
            'Pronto!',
            'O e-mail de recuperação foi enviado com sucesso!',
            'success'
          );
        }
      })
      .catch((error) => {
        handlerError(
          {
            DEFAULT: () => {
              Swal.fire(
                'Ops!',
                'Não foi possível enviar a solicitação.',
                'error'
              );
            },
          },
          error.message
        );
      });
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />

      <Box maxWidth={400} mx="auto" my={10}>
        <Box display="flex" flexDirection="column" alignItems="center" mb={5}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography variant="h5" color="textPrimary">
            Recuperar senha
          </Typography>
        </Box>

        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                name="rf-username"
                label="Email"
                type="email"
                value={recoverData.username}
                onChange={(e) =>
                  setRecoverData({ ...recoverData, username: e.target.value })
                }
                InputLabelProps={{ shrink: true }}
                autoComplete="username"
                placeholder="Digite o seu e-mail"
                required
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl id="rf-type-control" fullWidth required>
                <InputLabel shrink>Tipo de Usuário</InputLabel>
                <Select
                  name="rf-type"
                  label="Tipo de Usuário"
                  value={recoverData.environment}
                  onChange={(e) =>
                    setRecoverData({
                      ...recoverData,
                      environment: e.target.value,
                    })
                  }
                >
                  <MenuItem value="administration">Coordenador</MenuItem>
                  <MenuItem value="sales">Corretor</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {recoverData.environment === 'sales' ? (
              <Grid item xs={12}>
                <TextField
                  name="rf-project"
                  label="Empreendimento"
                  value={recoverData.projectSlug}
                  onChange={(e) =>
                    setRecoverData({
                      ...recoverData,
                      projectSlug: e.target.value,
                    })
                  }
                  InputLabelProps={{ shrink: true }}
                  placeholder="Digite a identificação do projeto"
                  autoComplete="projectSlug"
                  required
                  fullWidth
                />
              </Grid>
            ) : null}

            <Grid item xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  className={buttonClasses.linkButton}
                  onClick={() => history.goBack()}
                  color="secondary"
                >
                  Voltar
                </Button>

                <Button
                  className={buttonClasses.buttonWithIcon}
                  endIcon={<BsArrowRight />}
                  name="rf-submit"
                  variant="contained"
                  color="primary"
                  type="submit"
                  style={{ width: 260 }}
                >
                  Confirmar
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>

      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
