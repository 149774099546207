import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';

import ICON_TYPES from '@constants/iconsPaths';
import Icon from 'components/Icon';

export default function UserDelete({ onClick }) {
  return (
    <Tooltip title="Remover">
      <IconButton size="small" onClick={onClick}>
        <Icon src={ICON_TYPES.LIST.delete} size={18} />
      </IconButton>
    </Tooltip>
  );
}
