import { FaUser } from 'react-icons/fa';
import React from 'react';
import { Avatar, Box, ButtonBase, Typography } from '@material-ui/core';
import useStyles from './style';

export default function SelectionButton({ title, id, onClick }) {
  const classes = useStyles();

  return (
    <ButtonBase className={classes.selectionButton} id={id} onClick={onClick}>
      <Box mr={3}>
        <Avatar style={{ width: 40, height: 40 }}>
          <FaUser />
        </Avatar>
      </Box>
      <Typography variant="h5" color="textPrimary">
        {title}
      </Typography>
    </ButtonBase>
  );
}
