/* eslint-disable react/jsx-no-constructed-context-values */

import React, { createContext, useContext, useState } from 'react';

import { ProjectContext } from 'providers/ProjectProvider';
import useWebhooks from './hooks/useWebhooks';
import useGetProperties from './hooks/useGetProperties';
import useGetSummary from './hooks/useGetSummary';

const PropertiesListContext = createContext();

export default function PropertiesListProvider({ children }) {
  const { project } = useContext(ProjectContext);
  const [search, setSearch] = useState('');
  const [variables, setVariables] = useState({
    projectId: project._id,
    page: 0,
    sortOptions: {
      field: 'unity',
      direction: 'asc',
    },
    filterOptions: { status: null, price: null },
    changedAt: Date.now(),
  });

  const summaryData = useGetSummary(variables, search);
  const { properties, pages, maxPrice, isLoading } = useGetProperties(
    variables,
    search
  );

  const setPage = (value) => setVariables({ ...variables, page: value });

  const setFilter = (value) => {
    const newFilterOptions = { ...variables.filterOptions, ...value };
    setVariables({ ...variables, page: 0, filterOptions: newFilterOptions });
  };

  const setSort = (option) =>
    setVariables({
      ...variables,
      page: 0,
      sortOptions: {
        field: option.value,
        direction: option.reverse ? 'desc' : 'asc',
      },
    });

  const updateList = () =>
    setVariables({ ...variables, changedAt: Date.now() });

  useWebhooks(properties, updateList);

  return (
    <PropertiesListContext.Provider
      value={{
        properties,
        summaryData,
        variables,
        pages,
        maxPrice,
        isLoading,
        setPage,
        setSearch,
        setFilter,
        setSort,
      }}
    >
      {children}
    </PropertiesListContext.Provider>
  );
}

export function usePropertiesListContext() {
  return useContext(PropertiesListContext);
}
