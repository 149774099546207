import React, { useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import ICON_TYPES from '@constants/iconsPaths';
import Icon from 'components/Icon';

import useStyles from './styles';

export default function DocumentButton({ name, onClick }) {
  const classes = useStyles();
  const [downloadIconSrc, setDownloadIconSrc] = useState(
    ICON_TYPES.BASE.download
  );

  const onMouseEnter = () => setDownloadIconSrc(ICON_TYPES.BASE.downloadOrange);
  const onMouseLeave = () => setDownloadIconSrc(ICON_TYPES.BASE.download);
  return (
    <Box
      onClick={onClick}
      className={classes.documentContainer}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box className={classes.documentIcon}>
          <Icon src={ICON_TYPES.FILE_TYPES.pdf} size={32} />
        </Box>
        <Typography variant="body2">{name}</Typography>
      </Box>
      <Icon src={downloadIconSrc} size={25} />
    </Box>
  );
}

DocumentButton.propTypes = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
