import React from 'react';
import { Typography } from '@material-ui/core';

import formatCpf from 'utils/formatCpf';
import RealEstateAgentSwitch from '../components/RealEstateAgentSwitch';

export default function useColumns() {
  const columns = [
    {
      title: 'Nome',
      field: 'name',
      render: (rowData) => (
        <Typography color="primary">{rowData.name}</Typography>
      ),
    },
    { title: 'CPF', field: 'cpf', render: (rowData) => formatCpf(rowData.cpf) },
    { title: 'RG', field: 'rg' },
    { title: 'CRECI', field: 'creci' },
    { title: 'Reservas', field: 'reservationLimit' },

    {
      title: 'Acesso',
      field: 'active',
      render: (rowData) => <RealEstateAgentSwitch rowData={rowData} />,
    },
  ];
  return columns;
}
