import all from './all';
import currentMonth from './current-month';
import currentYear from './current-year';
import lastMonth from './last-month';
import lastSixMonths from './last-six-months';
import lastThreeMonths from './last-three-months';
import lastYear from './last-year';
import nextMonth from './next-month';

export default function generatePredefinedDates() {
  const dates = [
    currentMonth(),
    lastMonth(),
    nextMonth(),
    lastThreeMonths(),
    lastSixMonths(),
    currentYear(),
    lastYear(),
    all(),
  ];

  return dates.map((date) => ({
    ...date,

    isSelected(startDate, endDate) {
      return (
        startDate?.getTime() === this.startDate?.getTime() &&
        endDate?.getTime() === this.endDate?.getTime()
      );
    },
  }));
}
