import { Typography } from '@material-ui/core';
import formatCpf from 'utils/formatCpf';
import formatPhone from 'utils/formatPhone';
import React from 'react';

export default function useColumns() {
  const columns = [
    {
      title: 'Nome',
      field: 'name',
      render: (rowData) => (
        <Typography color="primary">{rowData.name}</Typography>
      ),
    },
    {
      title: 'CPF',
      field: 'cpf',
      render: (rowData) => rowData.cpf && formatCpf(rowData.cpf),
    },
    { title: 'RG', field: 'rg' },
    { title: 'E-mail', field: 'email' },
    {
      title: 'Telefone',
      field: 'phone',
      render: (rowData) => (rowData.phone ? formatPhone(rowData.phone) : ''),
    },
  ];

  return columns;
}
