import React, { useEffect, useState } from 'react';
import {
  Box,
  TextField,
  CircularProgress,
  GridListTile,
  GridList,
} from '@material-ui/core';
import ICON_TYPES from '@constants/iconsPaths';
import BaseDialog from 'components/Dialogs/BaseDialog';
import useThemeClasses from 'theme/useThemeClasses';
import Icon from 'components/Icon';
import searchReservations from '../helpers/searchReservationsWithoutContract';
import ReservationCard from './ReservationCard';

const LIMIT_BASE_VALUE = 12;

export default function NewReservationModal({ open, data, onSelect, onClose }) {
  const themeClasses = useThemeClasses;
  const textFieldClasses = themeClasses.textField();
  const [search, setSearch] = useState('');
  const [limit, setLimit] = useState(LIMIT_BASE_VALUE);
  const [reservationsWithoutContract, setReservationsWithoutContract] =
    useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const loadMoreRef = (node) => {
    const options = {
      root: null,
      rootMargin: '20px',
      threshold: 0.8,
    };
    const observer = new IntersectionObserver((entities) => {
      if (entities[0].isIntersecting) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    }, options);

    if (node) {
      observer.observe(node);
    }
  };

  useEffect(() => {
    if (isVisible) {
      setTimeout(() => {
        const newLimitValue = limit + LIMIT_BASE_VALUE;
        const nextSetOfData = data.slice(limit, newLimitValue);
        if (nextSetOfData.length > 0) {
          setLimit(newLimitValue);
          setReservationsWithoutContract([
            ...reservationsWithoutContract,
            ...nextSetOfData,
          ]);
        } else {
          setIsLoading(false);
        }
      }, 500);
    }
  }, [isVisible]);

  useEffect(() => {
    const avaliableData = data.slice(0, limit);

    setReservationsWithoutContract(avaliableData);

    if (avaliableData.length <= data.length) {
      setIsLoading(true);
    }
  }, [data, open]);

  useEffect(() => {
    if (search) {
      setReservationsWithoutContract(() => searchReservations(search, data));
    }
  }, [search]);

  return (
    <BaseDialog
      open={open}
      title="Novas reservas foram aprovadas"
      onClose={() => {
        setSearch('');
        setLimit(LIMIT_BASE_VALUE);
        onClose();
      }}
      maxWidth="sm"
    >
      <Box maxHeight={456} row>
        <Box position="relative" mb={3}>
          <TextField
            className={textFieldClasses.textFieldOutlined1}
            variant="outlined"
            placeholder="Pesquisar"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            fullWidth
          />

          <Box position="absolute" top={10} right={36}>
            <Icon src={ICON_TYPES.BASE.search} />
          </Box>
        </Box>
        <GridList cols={3}>
          {reservationsWithoutContract.map((reservation) => (
            <GridListTile key={reservation._id}>
              <ReservationCard
                reservation={reservation}
                onClick={() => onSelect(reservation)}
              />
            </GridListTile>
          ))}
        </GridList>

        {isLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height={150}
          >
            <CircularProgress ref={loadMoreRef} />
          </Box>
        ) : null}
      </Box>
    </BaseDialog>
  );
}
