import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import AlertModule from 'modules/AlertModule';

import api from 'services/api';

import handlerError from 'utils/handleError';

import LOGIN_AND_ACCEPT_INVITE_MUTATION from 'queries/loginAndAcceptInviteQuery';
import REGISTER_AND_ACCEPT_INVITE_MUTATION from 'queries/registerAndAcceptInviteQuery';
import CHECK_USER_EMAIL_QUERY from 'queries/checkUserEmailQuery';

import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';
import validateInputs from './helpers/validate-inputs';

export default function UserForm({ invite, onDecline }) {
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [userExists, setUserExists] = useState(false);

  const checkUserEmail = useCallback(() => {
    api
      .call(CHECK_USER_EMAIL_QUERY, { email: invite.email })
      .then(({ checkUserEmail: result }) => {
        setUserExists(result);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [invite]);

  const handleLogin = (password) => {
    if (!password) {
      AlertModule.error('O campo Senha é obrigatório.');
      return;
    }

    AlertModule.wait();

    api
      .call(LOGIN_AND_ACCEPT_INVITE_MUTATION, { id: invite._id, password })
      .then(({ loginAndAcceptInvite: result }) => {
        if (!result) throw new Error('FAILED');

        AlertModule.success('Convite aceito com sucesso!');

        localStorage.removeItem('authToken');

        history.push('/');
      })
      .catch((error) => {
        handlerError(
          {
            INVALID_INVITATION: AlertModule.onError('Convite inválido.'),
            INVALID_LOGIN: AlertModule.onError('Email ou senha incorretos.'),
            UNVERIFIED_EMAIL: AlertModule.onError(
              'Verifique o seu e-mail antes de aceitar o convite.'
            ),
            USER_IN_PROJECT: AlertModule.onError(
              'O usuário já tem acesso ao projeto.'
            ),
            DEFAULT: AlertModule.onError('Não foi possível aceitar o convite'),
          },
          error.message
        );
      });
  };

  const handleRegister = (inputs) => {
    const validate = validateInputs(inputs);

    if (!validate.success) {
      AlertModule.error(validate.message);
      return;
    }

    const variables = {
      id: invite._id,
      name: inputs.name,
      password: inputs.password,
    };

    api
      .call(REGISTER_AND_ACCEPT_INVITE_MUTATION, variables)
      .then(({ registerAndAcceptInvite: result }) => {
        if (!result) throw new Error('FAILED');

        AlertModule.success('Convite aceito com sucesso!');

        localStorage.removeItem('authToken');

        history.push('/');
      })
      .catch((error) => {
        handlerError(
          {
            INVALID_INVITATION: AlertModule.onError('Convite inválido.'),
            NAME_REQUIRED: AlertModule.onError(
              'O campo "Nome Completo" é obrigatório.'
            ),
            EMAIL_IN_USE: AlertModule.onError('Esse e-mail já está em uso.'),
            REALESTATE_IN_PROJECT: AlertModule.onError(
              'A imobiliária já tem acesso ao projeto.'
            ),
            REALESTATE_AGENT_IN_PROJECT: AlertModule.onError(
              'O corretor já tem acesso ao projeto.'
            ),
            DEFAULT: AlertModule.onError(
              'Não foi possível concluir o cadastro.'
            ),
          },
          error.message
        );
      });
  };

  useEffect(() => {
    checkUserEmail();
  }, [checkUserEmail]);

  if (loading) return null;

  return userExists ? (
    <LoginForm
      email={invite.email}
      onLogin={handleLogin}
      onDecline={onDecline}
    />
  ) : (
    <RegisterForm
      email={invite.email}
      onSubmit={handleRegister}
      onDecline={onDecline}
    />
  );
}

UserForm.propTypes = {
  invite: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }),
  onDecline: PropTypes.func.isRequired,
};

UserForm.defaultProps = {
  invite: null,
};
