export default {
  dateFilter: '/assets/icons/lists/date-filter.svg',
  delete: '/assets/icons/lists/delete.svg',
  edit: '/assets/icons/lists/edit.svg',
  emit: '/assets/icons/lists/emit.svg',
  export: '/assets/icons/lists/export.svg',
  filter: '/assets/icons/lists/filter.svg',
  info: '/assets/icons/lists/info.svg',
  installmentFilter: '/assets/icons/lists/installment-filter.svg',
  moneyFilter: '/assets/icons/lists/money-filter.svg',
  print: '/assets/icons/lists/print.svg',
  sort: '/assets/icons/lists/sort.svg',
  warning: '/assets/icons/lists/warning.svg',
};
