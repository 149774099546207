import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import ICON_TYPES from '@constants/iconsPaths';
import Icon from 'components/Icon';
import useStyles from '../styles';

export default function ShowProfileListItem({ onShowUserProfile }) {
  const classes = useStyles();

  return (
    <ListItem
      className={classes.profileListItem}
      button
      onClick={(e) => onShowUserProfile(e.currentTarget)}
    >
      <ListItemIcon>
        <Icon src={ICON_TYPES.BASE.userProfile} />
      </ListItemIcon>
      <ListItemText primary="Perfil" />
    </ListItem>
  );
}

ShowProfileListItem.propTypes = {
  onShowUserProfile: PropTypes.func.isRequired,
};
