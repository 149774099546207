import React, { useRef } from 'react';
import api from 'services/api';
import { Box, Button } from '@material-ui/core';
import Alert from 'modules/AlertModule';
import PropTypes from 'prop-types';
import PROCESS_RETURN_FILE_ERROR_MESSAGES from '@constants/walletManagement/processReturnFileErrorMessages';

export default function UploadReturnFileButton({ projectId }) {
  const fileInput = useRef(null);

  const handleSelectFile = () => {
    fileInput.current.click();
  };

  const sendUpload = (e) => {
    const file = e.target.files[0];
    e.target.value = '';
    if (!file) return;

    api
      .uploadReturnFile(projectId, file)
      .then(Alert.onSuccess('Arquivo de retorno processado com sucesso'))
      .catch(({ response }) => {
        const message =
          response && response.data
            ? PROCESS_RETURN_FILE_ERROR_MESSAGES[response.data]
            : PROCESS_RETURN_FILE_ERROR_MESSAGES.DEFAULT;

        Alert.error(message);
      });
  };
  return (
    <Box>
      <input
        type="file"
        accept=".RET"
        id="fileInput"
        name="return"
        onChange={sendUpload}
        hidden
        ref={fileInput}
      />
      <Button
        variant="contained"
        size="small"
        color="primary"
        onClick={handleSelectFile}
        style={{ width: 144 }}
      >
        Carregar Retorno
      </Button>
    </Box>
  );
}

UploadReturnFileButton.propTypes = {
  projectId: PropTypes.string.isRequired,
};
