import validatePassword from 'utils/validatePassword';

const validateInputs = (variables) => {
  const checklist = [
    {
      message: 'O campo Nome é obrigatório.',
      validate: () => variables.name,
    },
    {
      message: 'O campo Senha é obrigatório.',
      validate: () => variables.password,
    },
    {
      message: 'O campo Confirme a senha é obrigatório.',
      validate: () => variables.password2,
    },
    {
      message: 'Senha inválida.',
      validate: () => validatePassword(variables.password),
    },
    {
      message: 'As senhas não coincidem.',
      validate: () => variables.password === variables.password2,
    },
  ];

  const validate = checklist.reduce((prev, item) => {
    if (prev) return prev;
    if (!item.validate()) return item;
    return null;
  }, null);

  return validate ? { success: false, ...validate } : { success: true };
};

export default validateInputs;
