import { useCallback, useEffect, useRef, useState } from 'react';

import AlertModule from 'modules/AlertModule';

import useQuery from 'hooks/useQuery';
import GET_INVITES_QUERY from 'queries/getInvitesQuery';

export default function useGetInvites(variables, search) {
  const [pages, setPages] = useState(1);
  const [invites, setInvites] = useState([]);
  const [executeGetInvites, isLoading] = useQuery(GET_INVITES_QUERY);
  const isFirstRender = useRef(true);

  const onResolve = ({ getInvites: data }) => {
    setPages(data.pages);
    setInvites(data.invites);
  };

  const onReject = AlertModule.onError(
    'Não foi possível carregar a lista de convites.'
  );

  const getInvitesBySearch = useCallback(
    () =>
      executeGetInvites
        .debounced({ ...variables, search })
        .then(onResolve)
        .catch(onReject),
    [variables, search]
  );
  const getInvites = useCallback(
    () =>
      executeGetInvites({ ...variables, search })
        .then(onResolve)
        .catch(onReject),
    [variables, search]
  );
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    getInvitesBySearch();
  }, [search]);

  useEffect(() => {
    getInvites();
  }, [variables]);

  return { invites, pages, isLoading };
}
