import React from 'react';
import { Box, Grid, Typography, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import useStyles from './styles';

export default function InformationBlock({ title, icon, children }) {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <Box>
      <Box mb={1}>
        <Typography style={{ color: theme.palette.secondary.dark }}>
          {title}
        </Typography>
      </Box>

      <Grid container spacing={1}>
        <Grid item xs="auto">
          <Box className={classes.iconBox}>{icon}</Box>
        </Grid>
        <Grid item xs style={{ alignSelf: 'center' }}>
          {children}
        </Grid>
      </Grid>
    </Box>
  );
}

InformationBlock.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

InformationBlock.defaultProps = {
  children: null,
};
