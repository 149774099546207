const lastYear = () => {
    const startDate = new Date()
    startDate.setDate(1)
    startDate.setMonth(0)
    startDate.setFullYear(startDate.getFullYear() - 1)
    startDate.setHours(0, 0, 0, 0)

    const endDate = new Date()
    endDate.setDate(1)
    endDate.setMonth(11)
    endDate.setDate(31)
    endDate.setFullYear(endDate.getFullYear() - 1)
    endDate.setHours(0, 0, 0, 0)

    return {
        id: 'last-year',
        label: 'Ano passado',
        startDate,
        endDate
    }
}

export default lastYear