import React, { useContext, useState } from 'react';
import { Box } from '@material-ui/core';

import { ProjectContext } from 'providers/ProjectProvider';
import ReturnFilesListProvider from 'providers/ListProviders/ReturnFilesListProvider';

import ReturnFilesList from 'components/Lists/ReturnFilesList';
import ReadReturnFileDialog from 'components/Dialogs/ReadDialogs/ReadReturnFileDialog';
import ReturnFilesListFilters from 'components/ListFilters/ReturnFilesListFilters.';
import ReturnFilesListZeroState from 'components/ZeroState/ReturnFilesListZeroState';

import RETURN_FILE_STATUS from '@constants/walletManagement/returnFileStatus';

import UploadReturnFileButton from './UploadReturnButton';

export default function Return() {
  const { project } = useContext(ProjectContext);
  const [selectedReturnFile, setSelectedReturnFile] = useState(null);

  const openReadReturnFileDialog =
    Boolean(selectedReturnFile) &&
    selectedReturnFile.status !== RETURN_FILE_STATUS.FAILED;

  return (
    <Box>
      <ReturnFilesListProvider>
        <Box display="flex" justifyContent="center" alignItems="center" mb={15}>
          <ReturnFilesListFilters />
          <UploadReturnFileButton projectId={project._id} />
        </Box>
        <ReturnFilesListZeroState>
          <ReturnFilesList onDetails={setSelectedReturnFile} />
        </ReturnFilesListZeroState>
      </ReturnFilesListProvider>

      <ReadReturnFileDialog
        open={openReadReturnFileDialog}
        returnFile={selectedReturnFile}
        onClose={() => setSelectedReturnFile(null)}
      />
    </Box>
  );
}
