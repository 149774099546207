import React from 'react';

import { makeStyles, useTheme } from '@material-ui/core';
import { getPropertyColors } from 'components/StatusLabel/BaseStatusLabel/colors';

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    zIndex: 1,
    '&.selected': {
      zIndex: 2,

      '& .circle': {
        transform: 'scale(2)',
        cursor: 'pointer',
        border: 0,
      },
    },
    '& .circle': {
      borderRadius: '50%',
      backgroundColor: 'red',
      boxSizing: 'border-box',
      border: '2px solid white',
      boxShadow: '0 1px 0 rgba(0,0,0,.25) , 0 1px 2px rgba(0,0,0,.4)',
      transition: 'all 0.1s ease-in',
      '&:hover': {
        transform: 'scale(2)',
        cursor: 'pointer',
      },
    },
    '&:hover': {
      zIndex: 2,
    },
  },
});

function Mark({ status, selected, left, top, size, onClick, onTouchEnd }) {
  const classes = useStyles();
  const theme = useTheme();

  const color = () => {
    const propertyColor = getPropertyColors(theme)[status];
    if (propertyColor) return propertyColor.backgroundColor;
    return 'black';
  };

  const getClasses = () => {
    const classNames = [classes.root];

    if (selected) {
      classNames.push('selected');
    }

    return classNames.join(' ');
  };

  return (
    <div className={getClasses()} style={{ left, top }}>
      <button
        type="button"
        label="markButton"
        className="circle"
        onClick={onClick}
        onTouchEnd={onTouchEnd}
        style={{
          backgroundColor: color(),
          width: size,
          height: size,
          marginLeft: size / -2,
          marginTop: size / -2,
        }}
      />
    </div>
  );
}

export default Mark;
