import React from 'react';
import { Typography, useTheme } from '@material-ui/core';

import ReturnFileStatusLabel from 'components/StatusLabel/ReturnFileStatusLabel';

export default () => {
  const theme = useTheme();

  return [
    {
      title: 'Arquivo',
      field: 'fileName',
      render: (rowData) => (
        <Typography color="primary">{rowData.name}</Typography>
      ),
    },
    {
      title: 'Data de envio',
      field: 'sentDate',
      render: (rowData) =>
        new Date(rowData.createdAt).toLocaleDateString('pt-BR', {
          hour: 'numeric',
          minute: 'numeric',
        }),
    },
    {
      title: 'Situação',
      field: 'status',
      render: (rowData) => (
        <ReturnFileStatusLabel
          theme={theme}
          errorMessage={rowData.message}
          status={rowData.status}
        />
      ),
    },
  ];
};
