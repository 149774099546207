import React from 'react';

export default function PropertyIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      fill="none"
      viewBox="0 0 24 25"
    >
      <path
        stroke="#FF6C4E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M12.617 14.651l1.521 1.521m1.013-4.055l1.521 1.52m1.014-4.055l1.521 1.52m-9.125 6.084l1.52 1.521"
      />
      <path
        stroke="#FF6C4E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M14.292 8.414l-5.378 5.378a3.803 3.803 0 115.378-5.378z"
        clipRule="evenodd"
      />
      <path
        stroke="#FF6C4E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M6.225 16.48l-.959.96a.9.9 0 000 1.267l2.532 2.535a.9.9 0 001.267 0L21.742 8.568a.9.9 0 000-1.267l-2.539-2.535a.9.9 0 00-1.267 0l-.959.958A7.605 7.605 0 106.225 16.481z"
        clipRule="evenodd"
      />
    </svg>
  );
}
