import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  paper1: {
    border: '1px solid rgba(134, 139, 167, 0.2)',
    borderRadius: 25,
    overflow: 'hidden',
    boxShadow: 'none'
  },
}))


