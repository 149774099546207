import React from 'react';
import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import { useUsersListContext } from 'providers/ListProviders/UsersListProvider';

import SearchListTextField from 'components/SearchListTextField';
import SortSelect from 'components/SortSelect';

import useStyles from '../styles';
import SORT_OPTIONS from './constants/sortOptions';

export default function UsersListFilters({ onClick }) {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'));
  const classes = useStyles();
  const { variables, setSort, search, setSearch } = useUsersListContext();

  const sortOption = SORT_OPTIONS.find(
    (option) => option.value === variables.sortOptions.field
  );

  return (
    <Box mb={5}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs md="auto">
          <Typography className="fw700" variant="h5" color="textPrimary">
            Coordenação
          </Typography>
        </Grid>

        <Grid item xs="auto">
          <Button
            variant="contained"
            color="primary"
            id="invite-button"
            size="small"
            onClick={onClick}
          >
            Convidar
          </Button>
        </Grid>

        {isMd ? <Grid item md /> : null}

        <Grid item xs={12} md="auto">
          <Box width={isMd ? 285 : '100%'}>
            <SearchListTextField
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md="auto">
          <Box className={classes.listFilters}>
            <SortSelect
              value={sortOption}
              options={SORT_OPTIONS}
              onChange={setSort}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
