import EnvironmentModule from "modules/EnvironmentModule"

const API_SERVER = EnvironmentModule.get('API_SERVER')

const urlBuilder = {
    map(projectId) {
        if (!projectId) return null
        return `${API_SERVER}/uploads/map/${projectId}`
    }
}

export default urlBuilder