const LOGIN_AND_ACCEPT_INVITE_MUTATION = `
    mutation LoginAndAcceptInvite (
      $id: ID!
      $password: String!
    ) {
      loginAndAcceptInvite (
        id: $id
        password: $password
      )
    }
  `;

export default LOGIN_AND_ACCEPT_INVITE_MUTATION;
