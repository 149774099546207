import React from 'react';
import {
  Box,
  Typography,
  useTheme,
  Select,
  MenuItem,
  TextField,
} from '@material-ui/core';
import PropTypes from 'prop-types';

import isEditionDisabled from '../utils/isEditionDisabled';

export default function ReservationInformation({
  reservation,
  setReservation,
}) {
  const theme = useTheme();
  const editionDisabled = isEditionDisabled(reservation);

  return (
    <Box mb={5}>
      <Box mb={1}>
        <Typography
          className="fw700"
          style={{ color: theme.palette.secondary.dark }}
        >
          Informações da reserva
        </Typography>
      </Box>

      <Box mb={3}>
        <Typography
          variant="body2"
          style={{ color: theme.palette.secondary.main }}
        >
          Situação
        </Typography>

        <Select
          labelId="rs-state-select-label"
          value={reservation.status}
          onChange={(e) =>
            setReservation({
              ...reservation,
              status: e.target.value,
            })
          }
          disabled={reservation.status === 'expired'}
          fullWidth
        >
          <MenuItem value="pending">Pendente</MenuItem>
          <MenuItem value="accepted">Aprovado</MenuItem>
          <MenuItem value="rejected">Rejeitado</MenuItem>

          {reservation.status === 'expired' && (
            <MenuItem value="expired">Expirado</MenuItem>
          )}
        </Select>
      </Box>

      <Box mb={3}>
        <Typography
          variant="body2"
          style={{ color: theme.palette.secondary.main }}
        >
          Data de expiração
        </Typography>

        <TextField
          type="datetime-local"
          value={reservation.expiration || ''}
          placeholder="Não expira"
          onChange={(e) => {
            setReservation({
              ...reservation,
              expiration: e.target.value,
            });
          }}
          fullWidth
          disabled={editionDisabled}
        />
      </Box>

      <Box mb={1}>
        <Typography
          className="fw700"
          style={{ color: theme.palette.secondary.dark }}
        >
          Ficou faltando alguma informação? Adicione aqui
        </Typography>
      </Box>

      <Box mb={3}>
        <Typography
          variant="body2"
          style={{ color: theme.palette.secondary.main }}
        >
          Observação
        </Typography>
        <TextField
          multiline
          rowsMax={4}
          placeholder="Nenhuma observação"
          value={reservation.observation || ''}
          onChange={(e) => {
            setReservation({
              ...reservation,
              observation: e.target.value,
            });
          }}
          fullWidth
          disabled={editionDisabled}
        />
      </Box>
    </Box>
  );
}

ReservationInformation.propTypes = {
  reservation: PropTypes.shape({
    observation: PropTypes.string,
    expiration: PropTypes.string,
    status: PropTypes.string,
  }),
  setReservation: PropTypes.func.isRequired,
};

ReservationInformation.defaultProps = {
  reservation: null,
};
