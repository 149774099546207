const CREATE_MANAGER_INVITE_QUERY = `
  mutation createManagerInvite (
    $projectId: ID!
    $email: String!
    $type: String!
  ) {
    createManagerInvite(
      projectId: $projectId
      email: $email
      type: $type
    )
  }
`;

export default CREATE_MANAGER_INVITE_QUERY;
