import React, { useState, useCallback, useEffect } from 'react';
import { Box, TextField, Button } from '@material-ui/core';
import useThemeClasses from 'theme/useThemeClasses';
import BaseList from 'components/Lists/BaseList';
import ICON_TYPES from '@constants/iconsPaths';
import BaseDialog from 'components/Dialogs/BaseDialog';
import PropTypes from 'prop-types';
import GET_SALES_CONTRACT_INVOICES_BY_IDS from 'queries/getSalesContractInvoicesByIdsQuery';
import useQuery from 'hooks/useQuery';
import Icon from 'components/Icon';
import preventDefault from 'utils/preventDefault';
import RemittanceModule from 'modules/RemittanceModule';
import useColumns from './utils/useColumns';

export default function ReadNewRemittanceDialog({
  projectId,
  open,
  remittance,
  onClose,
}) {
  const themeClasses = useThemeClasses;

  const textFieldClasses = themeClasses.textField();
  const [search, setSearch] = useState('');
  const [invoices, setInvoices] = useState(null);
  const columns = useColumns();
  const [executeGetSalesContractInvoicesByIds] = useQuery(
    GET_SALES_CONTRACT_INVOICES_BY_IDS
  );
  const loadInvoicesInRemittance = useCallback(() => {
    if (!remittance) {
      setInvoices([]);
      return;
    }
    executeGetSalesContractInvoicesByIds({
      projectId,
      invoices: remittance.invoicesIds,
    })
      .then(({ getSalesContractInvoicesByIds: invoicesArray }) => {
        setInvoices([...invoicesArray]);
      })
      .catch((err) => {
        console.log(err);
        setInvoices([]);
      });
  }, [projectId, remittance]);

  const downloadRemittance = preventDefault(() => {
    RemittanceModule.downloadRemittance(remittance.projectId, remittance.name);
  });

  useEffect(() => {
    loadInvoicesInRemittance();
  }, [loadInvoicesInRemittance]);

  return (
    <BaseDialog
      open={open}
      title="Arquivo de remessa"
      onClose={() => onClose(false)}
      maxWidth="md"
    >
      <Box position="relative" mb={3}>
        <TextField
          className={textFieldClasses.textFieldOutlined1}
          variant="outlined"
          placeholder="Pesquise por nome do cliente, código do contrato"
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          fullWidth
        />

        <Box position="absolute" top={10} right={36}>
          <Icon src={ICON_TYPES.BASE.search} />
        </Box>
      </Box>

      <Box height={300} mb={3} style={{ overflowY: 'auto' }}>
        <BaseList data={invoices} columns={columns} />
      </Box>

      <Box display="flex" justifyContent="flex-end">
        <Button
          onClick={downloadRemittance}
          variant="contained"
          color="primary"
        >
          Baixar remessa
        </Button>
      </Box>
    </BaseDialog>
  );
}

ReadNewRemittanceDialog.propTypes = {
  projectId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  remittance: PropTypes.shape({
    invoicesIds: PropTypes.arrayOf(PropTypes.string),
    projectId: PropTypes.string,
    name: PropTypes.string,
  }),
  onClose: PropTypes.func.isRequired,
};

ReadNewRemittanceDialog.defaultProps = {
  remittance: null,
};
