export const ADJUST_SALES_CONTRACT_INVOICES_MUTATION = `
  mutation AdjustSalesContractInvoices (
    $projectId: ID!
    $contractNumberId: Int!
    $percent: Float!
  ) {
    adjustSalesContractInvoices (
      projectId: $projectId
      contractNumberId: $contractNumberId
      percent: $percent
    )
  }
`;

export default {};
