export default `
    query  GetRealEstateQuery(
      $id:ID!
    ){
      getRealEstate(
        id: $id
      ) 
      {
        _id
        projectId
        name
        cnpj
        creci
        reservationLimit
        postalCode
        address
        addressNumber
        addressComplement
        district
        city
        state
        username
        phone
        phone2
        email
        active
      }
    }
  `;
