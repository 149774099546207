import { Box, IconButton } from '@material-ui/core';
import Icon from 'components/Icon';
import React from 'react';
import preventDefault from 'utils/preventDefault';
import ICON_TYPES from '@constants/iconsPaths';

export default function PropertyListActions({ data, onEdit, onDelete }) {
  return (
    <Box display="flex" justifyContent="center">
      <Box hidden={!onEdit}>
        <IconButton
          title="Editar"
          size="small"
          onClick={preventDefault(() => onEdit(data))}
        >
          <Icon src={ICON_TYPES.LIST.edit} size={21} />
        </IconButton>
      </Box>

      <Box hidden={!onDelete}>
        <IconButton
          title="Deletar"
          size="small"
          onClick={preventDefault(() => onDelete(data))}
        >
          <Icon src={ICON_TYPES.LIST.delete} size={19} />
        </IconButton>
      </Box>
    </Box>
  );
}
