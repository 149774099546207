import { useCallback, useEffect, useRef, useState } from 'react';

import useQuery from 'hooks/useQuery';
import AlertModule from 'modules/AlertModule';
import GET_REMITTANCES_QUERY from 'queries/getRemittancesQuery';

export default function useGetRemittances(variables, search) {
  const [pages, setPages] = useState(1);
  const [remittances, setRemittances] = useState([]);
  const [executeGetRemittances, isLoading] = useQuery(GET_REMITTANCES_QUERY);
  const isFirstRender = useRef(true);

  const onResolve = ({ getRemittances: data }) => {
    setPages(data.pages);
    setRemittances(data.remittances);

    return data;
  };
  const onReject = AlertModule.onError(
    'Não foi possível carregar a lista de remessas.'
  );

  const getRemittancesBySearch = useCallback(
    () =>
      executeGetRemittances
        .debounced({ ...variables, search })
        .then(onResolve)
        .catch(onReject),
    [variables, search]
  );
  const getRemittances = useCallback(
    () =>
      executeGetRemittances({ ...variables, search })
        .then(onResolve)
        .catch(onReject),
    [variables, search]
  );
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    getRemittancesBySearch();
  }, [search]);

  useEffect(() => {
    getRemittances();
  }, [variables]);

  return { remittances, pages, isLoading };
}
