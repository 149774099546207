import React from 'react';

function SalesContractInvoicesZeroStateImage() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="95"
      height="141"
      fill="none"
      viewBox="0 0 95 141"
    >
      <path
        fill="#868E96"
        fillOpacity="0.8"
        d="M47.185 137.254c26.06 0 47.185-4.489 47.185-10.026 0-5.536-21.125-10.025-47.185-10.025-26.06 0-47.185 4.489-47.185 10.025 0 5.537 21.125 10.026 47.185 10.026z"
      />
      <path
        fill="#FF6C4E"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.501"
        d="M32.526 47.5s1 4.751 1.09 5.132c.09.381-14.335 8.378-15.296 9.115-.96.737-2.75 1.203-2.684 2.029.065.826 11.13 24.741 11.965 29.571.835 4.83 2.5 13.044 2.5 13.044l.704 5.003h36.15l-.064-27.42s1.134-27.27 1-27.903c-.136-.633-1.086-1.078-1.28-1.143-.196-.064-16.426-2.296-18.536-2.87a4.445 4.445 0 01-1.939-1.12A4.38 4.38 0 0145 49.02c-.19-.698-4.794-2.217-7.87-2.598-3.075-.381-4.67.381-4.605 1.079z"
      />
      <path
        fill="#FF6C4E"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.501"
        d="M66.89 86.083l-39.665 5.8c.153.487.279.982.375 1.484.24 1.4.555 3.088.875 4.78l38.43-5.621-.015-6.443zM66.92 98.69l-37.276 5.444c.27 1.36.45 2.251.45 2.251l.566 4.028 36.275-5.305-.016-6.418zM67.055 79.873c.07-1.806.165-4.063.26-6.463L22.56 79.952a241.072 241.072 0 012.5 6.061l41.995-6.14z"
      />
      <path
        fill="#263238"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.501"
        d="M28.155 56.002c1.77 5.35 6.62 9.194 12.33 9.194 6.73 0 12.27-5.344 12.965-12.203-2.58-.395-4.7-.752-5.375-.935a4.446 4.446 0 01-1.939-1.12A4.38 4.38 0 0145 49.02c-.19-.698-4.795-2.217-7.87-2.598-3.075-.381-4.67.381-4.605 1.079 0 0 1 4.75 1.09 5.131.025.159-2.475 1.643-5.46 3.37zM84.525 103.258s-12.575 3.499-15.455 4.018c-2.88.52-6.945.777-6.945.777s-30.91-.777-31.695-.91c-.785-.134-15.85-6.869-15.85-6.869s29.08 23.579 32.22 25.019c3.14 1.44 9.04-.777 12.575-2.85s25.15-19.185 25.15-19.185z"
      />
      <path
        fill="#B6895F"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.501"
        d="M30.16 54.834c.725 2.474 3.43 8.798 11.805 7.244 6.67-1.236 9.045-6.027 9.89-9.287-1.76-.307-3.19-.574-3.78-.733a4.446 4.446 0 01-1.94-1.12A4.38 4.38 0 0145 49.02c-.19-.698-4.795-2.217-7.87-2.598-3.075-.381-4.67.381-4.605 1.079 0 0 1 4.75 1.09 5.131.02.119-1.435 1.015-3.455 2.202z"
      />
      <path
        fill="#263238"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.501"
        d="M32.525 47.5l.86 4.01c2.15.92 5.735 2.162 7.695 1.241a16.61 16.61 0 004.205-3.008A3.935 3.935 0 0145 49.02c-.03-.114-.18-.252-.425-.4l-1.685-.14-7.39-2.157c-2 .02-3 .619-2.975 1.178z"
      />
      <path
        fill="#B6895F"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.501"
        d="M27.796 18.32s-7.465 4.523-6.855 8.294c.61 3.77 3.35 11.139 3.35 11.139s-2.74-2.712-2.89.603c-.15 3.316 2.435 4.672 3.195 4.672a6.522 6.522 0 001.68-.297s3.805 4.369 5.33 4.948c1.525.579 7.315 3.167 9.295 3.167 1.98 0 5.18-.752 5.79-1.98.61-1.227 2.285-8.743 2-10.104-.285-1.36-3.2-13.568-3.81-15.077-.61-1.51-.76-4.672-5-6.636-4.24-1.965-12.085 1.272-12.085 1.272z"
      />
      <path
        fill="#263238"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.501"
        d="M39.836 17.114c-4.27-1.98-12.04 1.207-12.04 1.207s-7.465 4.523-6.855 8.294c.61 3.77 3.35 11.138 3.35 11.138s1.375 1.356 2.135.604c.76-.752-2-4.221-2-4.221s.15-1.36.15-2.865c0-1.504-.76-3.167-1.825-4.676-1.065-1.51 2-6.18 4.265-6.636 2.265-.455 5.635.302 7.465-.604 1.83-.905 1.83-2.108 1.83-2.108s.455-.301 2.435-.301c.372.006.742.063 1.1.168h-.01z"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="1.002"
        d="M36.266 29.791l-9.295 3.845s.75 3.439 1.545 4.161c.795.723 1.88 1.287 4.45.728 2.57-.56 4.945-2.163 4.895-3.365-.05-1.202-1.595-5.369-1.595-5.369zM38.944 28.915l9.63-2.934s1.645 3.117 1.5 4.176c-.145 1.059-.62 2.177-2.95 3.38-2.33 1.202-5.2 1.484-5.94.494-.74-.99-2.24-5.116-2.24-5.116zM36.266 29.79s1.335-1.192 2.68-.875M36.86 31.166s1.19-1.593 2.59-.99M26.97 33.635l-2.914 4.043M48.575 25.98l-2.41 1.935"
      />
      <path
        fill="#263238"
        d="M34.095 33.521a.662.662 0 00.665-.658.662.662 0 00-.665-.658.662.662 0 00-.665.658c0 .364.297.658.665.658zM43.855 30.3a.662.662 0 00.665-.658.662.662 0 00-.665-.658.662.662 0 00-.665.658c0 .364.298.659.665.659z"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="1.002"
        d="M33.57 28.118s-3.286-.965-4.336 2.425M39.94 27.153s.7-2.009 4.195-1.039"
      />
      <path
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.501"
        d="M40.08 32.621s1.05 2.217 1.89 2.563c.84.347 2.865.99 1.815 1.732-1.05.742-3.285 1.87-4.265 1.87a1.467 1.467 0 01-1.33-.761M42.67 41.137a8.35 8.35 0 01-4.76 1.109c-2.73-.208-3.22-1.455-3.22-1.455M35.25 40.168l-1.4 1.316"
      />
      <path
        fill="#B6895F"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.501"
        d="M8.82 80.273S5.065 97.701 5.065 98.468c0 .767 9.065 3.207 10.745 2.054 1.68-1.153 5.31-16.018 5.31-16.018l-12.3-4.23z"
      />
      <path
        fill="#263238"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.501"
        d="M7.925 84.46l12.385 3.226c.5-1.87.81-3.182.81-3.182l-12.3-4.23s-.38 1.761-.895 4.186z"
      />
      <path
        fill="#FF6C4E"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.501"
        d="M15.635 63.776L4.55 81.175l18.125 5.636 1.295-3.716-8.335-19.319z"
      />
      <path
        fill="#B6895F"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.501"
        d="M66.7 82.58l4.92 14.355 13.335-1.925-5.83-19.348L66.7 82.58z"
      />
      <path
        fill="#263238"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.501"
        d="M79.125 75.662L66.7 82.58l1.51 4.414 11.825-8.318-.91-3.014z"
      />
      <path
        fill="#FF6C4E"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.501"
        d="M67.89 56.071l14.215 18.824-15.22 9.08L67.89 56.07z"
      />
      <path
        fill="#B6895F"
        d="M14.905 89.245l20.46 16.146-5.57 3.959s-23.825-8.828-24.6-10.238c-.775-1.41 1.425-6.665 1.425-6.665"
      />
      <path
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.501"
        d="M14.905 89.245l20.46 16.146-5.57 3.959s-23.825-8.828-24.6-10.238c-.775-1.41 1.425-6.665 1.425-6.665"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.501"
        d="M38.05 104.803c.801.524 1.637.997 2.5 1.415.38.074 1.81 2.756 2.41 3.053.6.297 1.435.99 1 1.346-.435.356-1.205.891-1.73.742-.525-.148-1.66-1.563-2.035-2.014a19.919 19.919 0 00-2.035-1.638l-.11-2.904z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.501"
        d="M38.425 104.233a.475.475 0 00-.565-.302c-.8.208-2.5.678-2.37 1.02.15.445 2.184 7.155 2.71 7.229.524.074 2.5.445 2.5-.223s-1.675-6.032-2.276-7.724z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.501"
        d="M35.5 104.95s-2.106 0-2.26.742c-.156.743 2.334 6.859 2.714 7.23.38.371 2.255-.148 2.255-.742s-2.71-7.23-2.71-7.23z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.501"
        d="M33.23 105.692s-2.18.451-2.18 1.044c0 .594 2.256 6.433 2.5 6.562a2.697 2.697 0 001.5-.148c.3-.149.45-.99.45-.99l-2.27-6.468z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.501"
        d="M31.05 106.737s-2.035 1.42-1.66 2.687c.375 1.267 1.355 3.276 1.81 3.499a1.618 1.618 0 001.5-.446l.075-.222-.075-.371-1.65-5.147z"
      />
      <path
        fill="#B6895F"
        d="M75.87 89.245l-20.455 16.146 5.565 3.959s23.825-8.843 24.6-10.253c.775-1.41-1.425-6.665-1.425-6.665"
      />
      <path
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.501"
        d="M75.87 89.245l-20.455 16.146 5.565 3.959s23.825-8.843 24.6-10.253c.775-1.41-1.425-6.665-1.425-6.665"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.501"
        d="M52.725 104.803c-.801.524-1.636.997-2.5 1.415-.375.074-1.81 2.756-2.41 3.053-.6.297-1.43.99-1 1.346.43.356 1.205.891 1.735.742.53-.148 1.655-1.563 2.03-2.014a20.563 20.563 0 012.035-1.638l.11-2.904z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.501"
        d="M52.35 104.233a.475.475 0 01.565-.302c.805.208 2.5.678 2.37 1.02-.15.445-2.185 7.155-2.71 7.229-.525.074-2.5.445-2.5-.223s1.68-6.032 2.275-7.724z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.501"
        d="M55.285 104.95s2.11 0 2.26.742c.15.743-2.335 6.859-2.71 7.23-.375.371-2.26-.148-2.26-.742s2.71-7.23 2.71-7.23z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.501"
        d="M57.544 105.692s2.185.451 2.185 1.044c0 .594-2.26 6.433-2.5 6.562a2.716 2.716 0 01-1.5-.148c-.3-.149-.45-.99-.45-.99l2.265-6.468z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.501"
        d="M59.73 106.737s2.03 1.42 1.655 2.687c-.375 1.267-1.355 3.276-1.805 3.499a1.63 1.63 0 01-.809-.03 1.607 1.607 0 01-.691-.416l-.075-.222.075-.371 1.65-5.147zM63.96 129.297s6.68-.129 12.18-2.202 8.125-1.297 9.435.777c1.31 2.073.785 5.963-.135 7.65-.92 1.687-4.32 3.37-9.82 4.147-5.5.776-11.53-.391-11.53-.391l-.13-9.981z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.501"
        d="M79.46 138.911c3.256-.881 5.306-2.128 6-3.375.92-1.687 1.446-5.577.136-7.65-1.13-1.781-3.225-2.603-7.285-1.484l.21.183s4.454-1.297 5.24 1.425c.785 2.721 0 7.388-1.57 8.818a29.264 29.264 0 01-2.73 2.083zM39.795 127.124s-6.565 1.222-12.38.307c-5.815-.916-8.215.371-9.07 2.667-.855 2.296.46 5.997 1.71 7.462 1.25 1.465 4.925 2.425 10.47 2.073 5.545-.351 18.37-4.488 18.37-4.488l-9.1-8.021z"
      />
      <path
        fill="#263238"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.501"
        d="M36.4 129.994s3.884 3.33 2.33 5.894a30.582 30.582 0 01-1.796 2.622c5.675-1.336 11.96-3.365 11.96-3.365l-6.16-5.443-1.415-.232-4.92.524z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.501"
        d="M26.605 139.653c-3.365-.202-5.63-.989-6.55-2.093-1.25-1.484-2.565-5.166-1.71-7.462.735-1.979 2.62-3.201 6.825-2.924l-.17.222s-4.625-.366-4.835 2.475c-.21 2.84 1.53 7.229 3.36 8.308a28.433 28.433 0 003.08 1.474z"
      />
      <path
        fill="#263238"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.501"
        d="M78.355 102.644s-8.455 5.998-13.77 10.184c-5.315 4.186-11.415 8.412-12.425 8.907-1.01.495-2.25-.213-3.265.257a3.872 3.872 0 00-1.5 1.158s-2.45.579-2.84.614c-.39.035-.625 3.686-.04 6.111s4.585 4.716 5.255 4.924c.67.208 8.355-2.346 14.585-4.914 6.23-2.568 22.83-11.93 26.05-14.395 3.22-2.464 4.28-3.958 3.095-8.956-1.185-4.998-4.5-6.928-7.39-6.962-2.89-.035-7.755 3.072-7.755 3.072z"
      />
      <path
        fill="#263238"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.501"
        d="M50.86 124.774s-9.9-4.285-17.326-11.475c-7.425-7.19-17.12-13.692-20.535-13.93-3.415-.237-7.205 2.851-9.825 6.611-2.62 3.761 1.905 10.352 5.325 12.559 3.42 2.207 18.405 8.699 26.135 11.678 7.73 2.979 19.125 7.522 20.825 7.779 1.7.257 6.815 2.474 8 2.474 1.185 0 1.835-1.168 1.965-2.202.13-1.034-.26-7.393-.26-8.412a1.652 1.652 0 00-.444-1.284 1.693 1.693 0 00-1.26-.532c-1.046 0-2.5.777-2.88.777-.38 0-1.57-2.202-2.5-2.202-.93 0-1.83.391-2.096-.258-.265-.648-2.765-1.969-5.125-1.583z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.501"
        d="M71.36 82.327H22.937v31.011H71.36v-31.01z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.501"
        d="M71.36 82.327H22.937v29.037H71.36V82.327z"
      />
      <path
        fill="#263238"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.501"
        d="M42.93 102.169c10.77-6.27 16.6-16.156 28.43-18.685l-.004 27.874H22.93v-.455c3.215-.891 10.725-3.335 20-8.734z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.501"
        d="M49.535 96.84c0-.467-.14-.924-.403-1.313a2.385 2.385 0 00-1.072-.871 2.414 2.414 0 00-2.605.512 2.343 2.343 0 00-.518 2.578c.18.432.487.802.88 1.062a2.408 2.408 0 003.018-.295c.448-.443.7-1.045.7-1.672z"
      />
      <rect
        width="75.493"
        height="5.493"
        x="9.253"
        y="0.253"
        fill="#fff"
        stroke="#3D4657"
        strokeWidth="0.507"
        rx="2.747"
      />
      <rect
        width="9.493"
        height="5.493"
        x="9.253"
        y="0.253"
        fill="#FF6C4E"
        stroke="#3D4657"
        strokeWidth="0.507"
        rx="2.747"
      />
    </svg>
  );
}

export default SalesContractInvoicesZeroStateImage;
