import React, { useContext, useState } from 'react';
import { Container } from '@material-ui/core';

import { ProjectContext } from 'providers/ProjectProvider';
import ReservationsListProvider from 'providers/ListProviders/ReservationsListProvider';

import ReadReservationDialog from 'components/Dialogs/ReadDialogs/ReadReservationDialog';
import ReservationsListFilters from 'components/ListFilters/ReservationsListFilters';
import ReservationsListZeroState from 'components/ZeroState/ReservationsListZeroState';
import ReservationsList from 'components/Lists/ReservationsList';
import { useStyles } from './styles';

function Reservations() {
  const classes = useStyles();

  const { project } = useContext(ProjectContext);

  const [selectedReservationId, setSelectedReservationId] = useState(null);

  const [openReadReservationDialog, setOpenReadReservationDialog] =
    useState(false);

  if (!project) return null;

  return (
    <Container className={classes.container}>
      <ReservationsListProvider>
        <ReservationsListFilters />
        <ReservationsListZeroState>
          <ReservationsList
            onRead={(reservationId) => {
              setSelectedReservationId(reservationId);
              setOpenReadReservationDialog(true);
            }}
          />
        </ReservationsListZeroState>
      </ReservationsListProvider>

      <ReadReservationDialog
        open={openReadReservationDialog && Boolean(selectedReservationId)}
        reservationId={selectedReservationId}
        onClose={() => {
          setOpenReadReservationDialog(false);
          setSelectedReservationId(null);
        }}
      />
    </Container>
  );
}

export default Reservations;
