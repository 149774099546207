import {
  Box,
  Button,
  Menu,
  Slider,
  Tooltip,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import useThemeClasses from 'theme/useThemeClasses';
import formatPrice from 'utils/formatPrice';
import PropTypes from 'prop-types';
import FilterButton from './FilterButton';
import CurrencyTextField from './TextFields/CurrencyTextField';

export default function PriceSelect({ maxRange, onApply }) {
  const themeClasses = useThemeClasses;
  const textClasses = themeClasses.text();
  const buttonClasses = themeClasses.button();

  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState({ min: 0, max: null });

  const getSliderValue = () => [value.min, value.max];

  const handleSliderChange = (_, newValue) => {
    setValue({
      min: newValue[0],
      max: newValue[1],
    });
  };

  const handleReset = () => {
    const newValue = {
      min: 0,
      max: maxRange,
    };

    setValue(newValue);
    onApply(newValue);
    setAnchorEl(null);
  };

  const handleApply = () => {
    onApply(value);
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!value.max || value.max > maxRange) {
      setValue((_value) => ({ ..._value, max: maxRange }));
    }
  }, [value.max, maxRange]);

  return (
    <Box>
      <Typography component="div" className={textClasses.filterLabel}>
        <Box display="flex" alignItems="center">
          <Tooltip title="Filtrar por Preços">
            <FilterButton
              icon="price"
              label="Filtrar por preços"
              onClick={(e) => setAnchorEl(e.currentTarget)}
            />
          </Tooltip>
        </Box>
      </Typography>

      <Menu
        keepMounted
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <Box display="flex" flexDirection="column" p={1.5} width={182}>
          <Box mb={2}>
            <CurrencyTextField
              label="De:"
              value={value.min}
              onChange={(_, newValue) =>
                setValue((_value) => ({ ..._value, min: newValue }))
              }
              placeholder={formatPrice(0)}
              InputLabelProps={{ shrink: true }}
            />
          </Box>

          <Box mb={2}>
            <CurrencyTextField
              label="Para:"
              value={value.max}
              onChange={(_, newValue) =>
                setValue((_value) => ({ ..._value, max: newValue }))
              }
              placeholder={formatPrice(maxRange)}
              InputLabelProps={{ shrink: true }}
            />
          </Box>

          <Box px={0.5}>
            <Slider
              value={getSliderValue()}
              onChange={handleSliderChange}
              min={0}
              max={maxRange}
            />
          </Box>

          <Box display="flex">
            <Box mr={1}>
              <Button
                className={buttonClasses.linkButton}
                color="secondary"
                size="small"
                onClick={handleReset}
              >
                Limpar
              </Button>
            </Box>

            <Button
              variant="contained"
              color="primary"
              size="small"
              fullWidth
              onClick={handleApply}
            >
              Aplicar
            </Button>
          </Box>
        </Box>
      </Menu>
    </Box>
  );
}

PriceSelect.propTypes = {
  maxRange: PropTypes.number,
  onApply: PropTypes.func.isRequired,
};

PriceSelect.defaultProps = {
  maxRange: 0,
};
