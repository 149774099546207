import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  filterButton: {
    display: ({ fullWidth }) => (fullWidth ? 'flex' : 'inline-flex'),
    width: ({ fullWidth }) => (fullWidth ? '100%' : 'auto'),
    height: 30,
    paddingLeft: 8,
    paddingRight: 8,

    '& .icon svg': {
      marginRight: 8,
      fontSize: 17,
      color: theme.palette.secondary.dark,
      transform: 'translateY(1px)',
    },

    '& .caret svg': {
      marginLeft: 8,
      fontSize: 10,
    },

    '& .MuiTypography-root': {
      ...theme.typography.caption2,
      fontWeight: '500',
      color: theme.palette.secondary.dark,
      letterSpacing: '0.5',
    },
  },
}));

export default useStyles;
