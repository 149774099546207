import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  table1: {
    '& .MuiTableHead-root': {
      '& .MuiTableRow-root': {
        '& .MuiTableCell-root': {
          textTransform: 'uppercase',
          color: theme.palette.text.secondary,
          fontSize: 12,
          fontWeight: '700',
          lineHeight: '16px',
          borderBottom: 0,
          letterSpacing: 1,
          paddingBottom: 0,
        }
      }
    },

    '& .MuiTableRow-root': {
      '& .MuiTableCell-root': {
        '&:first-of-type': {
          paddingLeft: 0,
        },

        '&:last-of-type': {
          paddingRight: 0,
        }
      },
    },

    '& .MuiTableBody-root': {
      '& .MuiTableRow-root:last-of-type': {
        '& .MuiTableCell-root': {
          borderBottom: 0,
        }
      }
    }
  },
}))