export default `
  mutation CreateCustomerMutation (
    $projectId: ID!
    $name: String!
    $cpf: String!
    $rg: String 
    $maritalStatus: String!
    $occupation: String!
    $phone: String!
    $phone2: String 
    $email: String 
    $postalCode: String 
    $address: String 
    $addressNumber: String 
    $addressComplement: String 
    $district: String 
    $city: String 
    $state: String 
    $birthday: String
  ) {
    createCustomer(
      projectId: $projectId
      data: {
        name: $name
        cpf: $cpf
        rg: $rg
        maritalStatus: $maritalStatus
        occupation: $occupation
        phone: $phone
        phone2: $phone2
        email: $email
        postalCode: $postalCode
        address: $address
        addressNumber: $addressNumber
        addressComplement: $addressComplement
        district: $district
        city: $city
        state: $state
        birthday: $birthday
      }
    ) {
      _id
    }
  }
`;
