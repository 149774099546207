import {
  Avatar,
  Box,
  Button,
  IconButton,
  Typography,
  useTheme,
} from '@material-ui/core';
import React from 'react';
import { FaTimes } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';

import useStyles from './styles';
import LogoutButton from './buttons/LogoutButton';
import LogoutAsButton from './buttons/LogoutAsButton';

export default function Content({ access, projectTitle, onClose }) {
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();

  return (
    <Box minWidth={400}>
      <Box className={classes.currentSession}>
        <Box className="wrapper">
          <IconButton
            className="close"
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <FaTimes fontSize={18} />
          </IconButton>

          <Typography className="fw700" variant="h4">
            {projectTitle}
          </Typography>

          <Box my={2}>
            <Avatar />
          </Box>

          <Typography className="fw700" color="textPrimary">
            {access?.entityType === 'User' && 'Coordenador'}
            {access?.entityType === 'RealEstate' && 'Imobiliária'}
            {access?.entityType === 'RealEstateAgent' && 'Corretor'}
          </Typography>

          <Typography color="textSecondary">{access?.username}</Typography>

          <Box display="flex" justifyContent="center" width="100%" mt={2.5}>
            <Button variant="outlined" onClick={() => history.push('/account')}>
              Gerenciar Perfil
            </Button>

            <LogoutButton />
            <LogoutAsButton />
          </Box>
        </Box>
      </Box>

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        padding={3}
      >
        <Button
          className="policy-link"
          onClick={(e) => {
            e.preventDefault();
            window.open(
              'https://quadraa.com.br/legal/politicas-de-privacidade',
              '_blank'
            );
          }}
        >
          Políticas de Privacidade
        </Button>
        <Box
          width={5}
          height={5}
          mx={1}
          borderRadius="50%"
          bgcolor={theme.palette.text.primary}
        />
        <Button
          className="policy-link"
          onClick={(e) => {
            e.preventDefault();
            window.open(
              'https://quadraa.com.br/legal/termos-e-condicoes',
              '_blank'
            );
          }}
        >
          Termos de Uso
        </Button>
      </Box>
    </Box>
  );
}
