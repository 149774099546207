import { TextField } from '@material-ui/core'
import React from 'react'

export default function CurrencyTextField(props) {
    const handleValue = () => {
        if (!props.value) return ''

        return Number(props.value)
            .toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL'
            })
    }

    const handleChange = (e) => {
        let value = String(e.target.value)

        value = value.replace(/[^0-9]/g, '')
        value = Number(value) / 100

        if (isNaN(value)) {
            value = 0
        }

        props.onChange(e, value)
    }

    return <TextField {...props}
        value={handleValue()}
        onChange={handleChange}
    />
}