import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';

import SearchListTextField from 'components/SearchListTextField';
import SortSelect from 'components/SortSelect';
import ExportListButton from 'components/ExportListButton';

import { useCustomersListContext } from 'providers/ListProviders/CustomersListProvider';
import api from 'services/api';
import SORT_OPTIONS from './constants/sortOptions';
import useStyles from '../styles';
import getSelectedSortOption from '../utils/getSelectedSortOption';

export default function CustomersListFilters() {
  const classes = useStyles();
  const { variables, search, setSearch, setSort } = useCustomersListContext();

  const sortOption = getSelectedSortOption(SORT_OPTIONS, variables.sortOptions);

  const handleExport = () => {
    const exportVariables = { ...variables };
    delete exportVariables.page;
    api.exportCustomers(exportVariables);
  };

  return (
    <Box mb={5}>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs="auto">
          <Typography className="fw700" variant="h5" color="textPrimary">
            Clientes
          </Typography>
        </Grid>

        <Grid item xs={12} md="auto">
          <Box minWidth={285}>
            <SearchListTextField
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Pesquisar por nome, CPF ou RG"
            />
          </Box>
        </Grid>

        <Grid item xs={12} md>
          <Box className={classes.listFilters}>
            <SortSelect
              value={sortOption}
              options={SORT_OPTIONS}
              onChange={setSort}
            />

            <ExportListButton onClick={handleExport} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
