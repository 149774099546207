export const PAYMENT_METHODS = {
  CASH: 'cash',
  MONTHLY: 'monthly',
  ANNUALLY: 'annually',
};

export const PAYMENT_METHODS_LABELS = {
  [PAYMENT_METHODS.CASH]: 'À vista',
  [PAYMENT_METHODS.MONTHLY]: 'Mensal',
  [PAYMENT_METHODS.ANNUALLY]: 'Mensal e Anual',
};
