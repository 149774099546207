export default function formatPhone(value) {
    let phone = String(value)

    if (phone.length <= 10) {
        phone = phone.replace(/^([0-9]{0,2})([0-9]{0,4})([0-9]{0,4})/, '($1) $2-$3')
    } else {
        phone = phone.replace(/^([0-9]{0,2})([0-9]{0,1})([0-9]{0,4})([0-9]{0,4})/, '($1) $2 $3-$4')
    }

    return phone
}