import { useCallback, useEffect, useRef, useState } from 'react';

import useQuery from 'hooks/useQuery';
import AlertModule from 'modules/AlertModule';
import GET_RESERVATIONS_QUERY from 'queries/getReservationsQuery';

export default function useGetReservations(variables, search) {
  const [pages, setPages] = useState(1);
  const [reservations, setReservations] = useState([]);
  const [executeGetReservations] = useQuery(GET_RESERVATIONS_QUERY);
  const [isLoading, setIsLoading] = useState(false);
  const isFirstRender = useRef(true);

  const onResolve = (data) => {
    if (data.isLastQuery) {
      setPages(data.getReservations.pages);
      setReservations(data.getReservations.reservations);
      setIsLoading(false);
    }

    return data;
  };
  const onReject = AlertModule.onError(
    'Não foi possível carregar a lista de reservas.'
  );

  const createGetter = (executeQuery) => () =>
    executeQuery({ ...variables, search })
      .then(onResolve)
      .catch(onReject);

  const getReservationsBySearch = useCallback(
    createGetter(executeGetReservations.debounced),
    [variables, search]
  );
  const getReservations = useCallback(createGetter(executeGetReservations), [
    variables,
    search,
  ]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    setIsLoading(true);
    getReservationsBySearch().then((data) => {
      if (data.isLastQuery) setIsLoading(false);
    });
  }, [search]);

  useEffect(() => {
    setIsLoading(true);
    getReservations().then((data) => {
      if (data.isLastQuery) setIsLoading(false);
    });
  }, [variables]);

  return { reservations, pages, isLoading };
}
