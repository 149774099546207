import useQuery from 'hooks/useQuery';
import { useEffect, useState } from 'react';

import GET_PROPERTY_QUERY from 'queries/getPropertyQuery';
import ErrorHandler from 'modules/ErrorHandlerModule';
import useWebHooks from './useWebHooks';

export default function useGetProperty(id) {
  const [property, setProperty] = useState(null);

  const [executeGetProperty, isLoading] = useQuery(GET_PROPERTY_QUERY);

  const getPropertyError = ErrorHandler('Não foi possível encontrar o imóvel');

  const getProperty = () => {
    if (id) {
      executeGetProperty({ id })
        .then(({ getProperty: data }) => {
          setProperty(data);
        })
        .catch(getPropertyError);
    }
  };

  useEffect(() => {
    getProperty();
  }, [id]);

  useWebHooks(id, getProperty);

  return { property, isLoading };
}
