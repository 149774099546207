import { PAYMENT_METHODS } from '@constants/reservations/paymentMethods';
import InstallmentCalculatorModule from 'modules/InstallmentCalculatorModule';

export default function getInstallments(reservation) {
  const installmentsData = {};
  const { paymentMethod, entrance, annualQuota, installments } = reservation;

  const price = reservation.price - reservation.price * reservation.discount;

  const installmentCalculator = new InstallmentCalculatorModule({
    paymentMethod,
    totalPrice: price,
    entrance,
    annualQuota,
  });

  if (reservation.paymentMethod !== PAYMENT_METHODS.CASH) {
    installmentsData.monthlyInstallment =
      installmentCalculator.calculateMonthlyInstallment(installments);
  }

  if (reservation.paymentMethod === PAYMENT_METHODS.ANNUALLY) {
    installmentsData.annuallyInstallment =
      installmentCalculator.calculateAnnuallyInstallment(installments);
  }

  return installmentsData;
}
