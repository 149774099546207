import React, { useState } from 'react';
import { Box, Container, useMediaQuery, useTheme } from '@material-ui/core';

import InvoicesListProvider from 'providers/ListProviders/InvoicesListProvider';

import ProjectsLink from 'components/ProjectsLink';
import InvoicesList from 'components/Lists/InvoicesList';
import EditSalesContractInvoiceDialog from 'components/Dialogs/EditDialogs/EditSalesContractInvoiceDialog';
import InvoicesListFilters from 'components/ListFilters/InvoicesListFilters';
import InvoicesListZeroState from 'components/ZeroState/InvoicesListZeroState';
import InvoicesListSummary from './components/InvoicesListSummary';

import { useStyles } from './styles';

function SalesContractInvoices() {
  const classes = useStyles();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedInvoice, setSelectedInvoice] = useState(null);

  const selectInvoice = (invoice) => setSelectedInvoice(invoice);

  return (
    <Container className={classes.container}>
      <Box
        display={!isSm ? 'flex' : 'none'}
        justifyContent="space-between"
        alignItems="center"
        mb={5}
        minHeight={40}
      >
        <ProjectsLink mb={0} />
      </Box>
      <InvoicesListProvider>
        <InvoicesListFilters />
        <InvoicesListZeroState>
          <InvoicesListSummary />
          <InvoicesList onSelect={selectInvoice} />
        </InvoicesListZeroState>
      </InvoicesListProvider>

      <EditSalesContractInvoiceDialog
        open={Boolean(selectedInvoice)}
        invoiceId={selectedInvoice?._id}
        onClose={() => setSelectedInvoice(null)}
      />
    </Container>
  );
}

export default SalesContractInvoices;
