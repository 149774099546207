import React from 'react';
import { Box, Button, Grid } from '@material-ui/core';

import { useProjectConfigurationContext } from 'providers/ProjectConfigurationProvider';

import Discount from './Discount';
import General from './General';
import Reservation from './Reservation';

export default function Configuration() {
  const { variables, setVariables, changeDiscount, onUpdate } =
    useProjectConfigurationContext();

  return (
    <form onSubmit={onUpdate}>
      <Grid container spacing={1}>
        <Grid item xs={12} lg={5}>
          <General configuration={variables} setConfiguration={setVariables} />
          <Reservation
            configuration={variables}
            setConfiguration={setVariables}
          />
          <Discount configuration={variables} setDiscount={changeDiscount} />
          <Box marginTop={5}>
            <Button type="submit" variant="contained" color="primary">
              Salvar
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
}
