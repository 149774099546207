import { useEffect, useState } from 'react';
import WebSocketController from 'utils/WebSocketController';

const socket = new WebSocketController();

export default function useWebhooks(properties, onUpdate) {
  const [createdPropertyId, setCreatedPropertyId] = useState(null);
  const [updatedPropertyId, setUpdatedPropertyId] = useState(null);

  useEffect(() => {
    socket.onPropertyCreated((property) => {
      setCreatedPropertyId(property._id);
    });

    socket.onPropertyUpdated((property) => {
      setUpdatedPropertyId(property._id);
    });

    socket.onPropertyDeleted((property) => {
      setUpdatedPropertyId(property._id);
    });

    socket.onReservationCreated((reservation) => {
      setUpdatedPropertyId(reservation.propertyId);
    });

    socket.onReservationUpdated((reservation) => {
      setUpdatedPropertyId(reservation.propertyId);
    });

    return () => {
      socket.destroy();
    };
  }, []);

  useEffect(() => {
    if (createdPropertyId) {
      onUpdate();
      setCreatedPropertyId(null);
    }
  }, [createdPropertyId]);

  useEffect(() => {
    if (updatedPropertyId) {
      if (properties.find((property) => property._id === updatedPropertyId)) {
        onUpdate();
      }

      setUpdatedPropertyId(null);
    }
  }, [updatedPropertyId]);

  return null;
}
