import React from 'react';

function ReservationZeroStateImage() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="180"
      height="160"
      fill="none"
      viewBox="0 0 180 160"
    >
      <path
        fill="#F0F2F4"
        d="M166.53 44.033v-.056c-2.32-8.008-9.324-13.21-16.237-17.177-13.952-8.009-26.89-2.916-47.009 2.156-20.118 5.072-32.345-.138-50.604-5.757C25.398 14.81 4.848 40.687 8.578 66.382c1.764 12.105 7.224 23.258 9.436 35.264 3.24 17.6-9.518 25.73.6 40.574 10.117 14.844 37.404 18.234 55.607 15.366 11.028-1.725 20.576-7.897 31-11.493 9.777-3.373 20.403-2.64 30.189.297 9.078 2.721 19.838 5.4 27.532-1.949 8.75-8.354 11.674-18.238 7.383-29.581-3.718-9.82-10.696-18.169-13.865-28.209-2.674-8.448 2.415-13.158 6.348-19.993 3.804-6.612 5.869-15.138 3.722-22.625z"
      />
      <path
        fill="#F0F2F4"
        d="M166.53 44.033v-.056c-2.32-8.008-9.324-13.21-16.237-17.177-13.952-8.009-26.89-2.916-47.009 2.156-20.118 5.072-32.345-.138-50.604-5.757C25.398 14.81 4.848 40.687 8.578 66.382c1.764 12.105 7.224 23.258 9.436 35.264 3.24 17.6-9.518 25.73.6 40.574 10.117 14.844 37.404 18.234 55.607 15.366 11.028-1.725 20.576-7.897 31-11.493 9.777-3.373 20.403-2.64 30.189.297 9.078 2.721 19.838 5.4 27.532-1.949 8.75-8.354 11.674-18.238 7.383-29.581-3.718-9.82-10.696-18.169-13.865-28.209-2.674-8.448 2.415-13.158 6.348-19.993 3.804-6.612 5.869-15.138 3.722-22.625z"
        opacity="0.7"
      />
      <path
        fill="#000"
        d="M97.435 97.336H85.359V81.159h8.48l3.596 3.295v12.882z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M98.142 96.789H86.066V80.616h8.48l3.596 3.295V96.79z"
      />
      <path
        fill="#000"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M94.545 80.616l-.884 3.869 4.481-.574-3.597-3.295z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M94.545 80.616v3.295h3.597l-3.597-3.295z"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M88.374 86.287h6.883M88.374 87.974h6.883M88.374 89.66h6.883M88.374 91.35h6.883M88.374 93.036h6.883"
      />
      <path
        fill="#000"
        d="M155.513 97.336h-12.075V81.159h8.479l3.596 3.295v12.882z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M156.217 96.789h-12.076V80.616h8.479l3.597 3.295V96.79z"
      />
      <path
        fill="#000"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M152.62 80.616l-.88 3.869 4.476-.574-3.596-3.295z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M152.62 80.616v3.295h3.596l-3.596-3.295z"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M146.453 86.287h6.883M146.453 87.974h6.883M146.453 89.66h6.883M146.453 91.35h6.883M146.453 93.036h6.883"
      />
      <path
        fill="#000"
        d="M38.347 97.336H26.271V81.159h8.48l3.596 3.295v12.882z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M39.054 96.789H26.978V80.616h8.48l3.596 3.295V96.79z"
      />
      <path
        fill="#000"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M35.457 80.616l-.884 3.869 4.481-.574-3.597-3.295z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M35.458 80.616v3.295h3.596l-3.596-3.295z"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M29.29 86.287h6.883M29.29 87.974h6.883M29.29 89.66h6.883M29.29 91.35h6.883M29.29 93.036h6.883"
      />
      <path
        fill="#000"
        d="M97.435 137.756H85.359v-16.177h8.48l3.596 3.295v12.882z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M98.142 137.207H86.066v-16.173h8.48l3.596 3.295v12.878z"
      />
      <path
        fill="#000"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M94.545 121.034l-.884 3.869 4.481-.574-3.597-3.295z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M94.545 121.034v3.295h3.597l-3.597-3.295z"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M88.374 126.706h6.883M88.374 128.392h6.883M88.374 130.082h6.883M88.374 131.769h6.883M88.374 133.455h6.883"
      />
      <path
        fill="#000"
        d="M155.513 137.756h-12.075v-16.177h8.479l3.596 3.295v12.882z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M156.217 137.207h-12.076v-16.173h8.479l3.597 3.295v12.878z"
      />
      <path
        fill="#000"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M152.62 121.034l-.88 3.869 4.476-.574-3.596-3.295z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M152.62 121.034v3.295h3.596l-3.596-3.295z"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M146.453 126.706h6.883M146.453 128.392h6.883M146.453 130.082h6.883M146.453 131.769h6.883M146.453 133.455h6.883"
      />
      <path
        fill="#000"
        d="M38.347 137.756H26.271v-16.177h8.48l3.596 3.295v12.882z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M39.054 137.207H26.978v-16.173h8.48l3.596 3.295v12.878z"
      />
      <path
        fill="#000"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M35.457 121.034l-.884 3.869 4.481-.574-3.597-3.295z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M35.458 121.034v3.295h3.596l-3.596-3.295z"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M29.29 126.706h6.883M29.29 128.392h6.883M29.29 130.082h6.883M29.29 131.769h6.883M29.29 133.455h6.883"
      />
      <path
        fill="#000"
        d="M67.384 116.764H55.31v-16.172h8.483l3.592 3.295v12.877z"
      />
      <path
        fill="#FF6C4E"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M68.091 116.222H56.016v-16.177h8.478l3.597 3.295v12.882z"
      />
      <path
        fill="#000"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M64.495 100.045l-.88 3.868 4.477-.573-3.597-3.295z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M64.495 100.045v3.295h3.596l-3.596-3.295z"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M58.328 105.72h6.883M58.328 107.406h6.883M58.328 109.093h6.883M58.328 110.779h6.883M58.328 112.466h6.883"
      />
      <path
        fill="#000"
        d="M97.435 56.918H85.359V40.741h8.48l3.596 3.295v12.882z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M98.142 56.37H86.066V40.199h8.48l3.596 3.295v12.878z"
      />
      <path
        fill="#000"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M94.545 40.198l-.884 3.869 4.481-.574-3.597-3.295z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M94.545 40.198v3.295h3.597l-3.597-3.295z"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M88.374 45.868h6.883M88.374 47.556h6.883M88.374 49.241h6.883M88.374 50.928h6.883M88.374 52.62h6.883"
      />
      <path
        fill="#000"
        d="M126.472 35.928h-12.076V19.756h8.483l3.593 3.29v12.882z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M127.179 35.385h-12.075V19.208h8.478l3.597 3.295v12.882z"
      />
      <path
        fill="#000"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M123.582 19.208l-.879 3.869 4.476-.574-3.597-3.295z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M123.583 19.208v3.295h3.596l-3.596-3.295z"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M117.416 24.879h6.883M117.416 26.57h6.883M117.416 28.256h6.883M117.416 29.942h6.883M117.416 31.627h6.883"
      />
      <path
        fill="#000"
        d="M155.513 56.918h-12.075V40.741h8.479l3.596 3.295v12.882z"
      />
      <path
        fill="#FF6C4E"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M156.217 56.37h-12.076V40.199h8.479l3.597 3.295v12.878z"
      />
      <path
        fill="#000"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M152.62 40.198l-.88 3.869 4.476-.574-3.596-3.295z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M152.62 40.198v3.295h3.596l-3.596-3.295z"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M146.453 45.868h6.883M146.453 47.556h6.883M146.453 49.241h6.883M146.453 50.928h6.883M146.453 52.62h6.883"
      />
      <path
        fill="#000"
        d="M38.347 56.918H26.271V40.741h8.48l3.596 3.295v12.882z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M39.054 56.37H26.978V40.199h8.48l3.596 3.295v12.878z"
      />
      <path
        fill="#000"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M35.457 40.198l-.884 3.869 4.481-.574-3.597-3.295z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M35.458 40.198v3.295h3.596l-3.596-3.295z"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.535"
        d="M29.29 45.868h6.883M29.29 47.556h6.883M29.29 49.241h6.883M29.29 50.928h6.883M29.29 52.62h6.883"
      />
      <path
        fill="#FF6C4E"
        d="M120.68 137.208c11.847 0 21.451-1.846 21.451-4.123s-9.604-4.123-21.451-4.123c-11.847 0-21.451 1.846-21.451 4.123s9.604 4.123 21.451 4.123z"
      />
      <path
        fill="#fff"
        d="M120.68 137.208c11.847 0 21.451-1.846 21.451-4.123s-9.604-4.123-21.451-4.123c-11.847 0-21.451 1.846-21.451 4.123s9.604 4.123 21.451 4.123z"
        opacity="0.5"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M104.408 76.553a.368.368 0 01-.582-.112l-.862-1.815a.37.37 0 01.668-.315l.863 1.816a.365.365 0 01-.087.427z"
      />
      <path
        fill="#FF6C4E"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M105.185 81.036a5.023 5.023 0 012.518 1.095c1.208.988 3.838 2.303 7.241 3.07 3.403.769 4.274.864 4.274.864l-.862-4.714s-4.826.108-6.901-.22c-2.074-.328-4.498-2.191-5.606-3.179-1.109-.987-.664 3.084-.664 3.084z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M106.698 81.242a.554.554 0 01-.875-.168l-2.156-4.55a.564.564 0 01-.022-.423.555.555 0 011.022-.051l2.157 4.55a.565.565 0 01-.126.642z"
      />
      <path
        fill="#000"
        d="M105.12 73.527a5.76 5.76 0 01-8.82-1.192 5.758 5.758 0 019.113-6.946 5.763 5.763 0 01-.293 8.138zm-7.09-7.625a4.654 4.654 0 106.337 6.819 4.654 4.654 0 00-6.337-6.82z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M105.94 76.654s.599-.32.862.172.915 1.514.789 1.885c-.125.37-.823.431-1.164.047a7.17 7.17 0 01-.811-1.587l.324-.517z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M103.636 77.386l2.303-.733a.664.664 0 01.302.483c.048.345 1.018 2.264.91 2.748-.108.483-2.212 1.367-2.423 1.104-.212-.263-1.454-3.26-1.454-3.26s-.034-.23.362-.341z"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M106.289 77.481l-2.079.647M106.591 78.379l-1.893.733M106.94 79.21l-1.794 1.014"
      />
      <path
        fill="#FF6C4E"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M115.492 68.425s-1.867 3.506-2.083 7.017c-.215 3.51 2.959 3.946 3.399 4.273.439.328 2.083 14.146 2.083 14.146s-11.515 14.805-11.515 16.448c0 1.644-1.635 17.777-1.635 17.777l2.411.112s3.627-10.212 4.489-12.619c.863-2.406 1.204-6.141 1.204-6.141l10.639-9.98s1.203 9.76 2.303 11.843c1.1 2.083 8.66 17.109 8.66 17.109a11.68 11.68 0 001.975.327c.456-.02.903-.132 1.315-.327l-6.469-18.545s.328-16.888-1.095-19.631c-1.423-2.743-5.046-6.689-5.046-6.689s-3.07-12.718-3.51-14.693c-.44-1.976-2.959-3.619-4.166-3.619-1.208 0-2.959 3.192-2.959 3.192z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M113.504 66.229l1.535 2.41s-.862 3.731-.108 2.856c.755-.876 1.971-4.059 2.631-4.718.407-.435.703-.962.863-1.536l-3.265-1.846-1.656 2.834z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M106.694 58.186s-.194 2.014.462 3.019c.655 1.005.862 1.315.66 1.97-.203.656-1.208.66-.988 1.208.22.548 1.428.768 1.428.768s1.315 3.07 2.518 3.07c1.203 0 4.287-3.018 4.722-4.118.436-1.1-4.062-3.998-4.83-4.653-.767-.656-3.096-2.471-3.972-1.264z"
      />
      <path
        fill="#000"
        d="M109.377 62.006c.121.367.082.712-.091.768-.172.056-.431-.194-.53-.56-.099-.367-.086-.712.086-.773.173-.06.41.195.535.565z"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M109.041 65.431s1.06 0 1.414-1.53"
      />
      <path
        fill="#000"
        d="M107.238 53.072h-2.269c-1.457 0-1.617 2.91 1.458 6.15 3.075 3.238 9.225 7.12 12.787 7.12 3.562 0 6.637.97 7.931 4.532 1.294 3.563 3.399 6.797 9.712 5.503 6.314-1.293 7.931-9.871 3.399-17.155-4.533-7.285-10.523-5.99-13.598-4.533-3.075 1.458-8.255 1.94-9.225 0-.97-1.94-3.265-3.248-5.693-3.248-2.428 0-4.502 1.63-4.502 1.63z"
      />
      <path
        fill="#535B6A"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M138.737 128.405l-6.469-18.545s.328-16.888-1.095-19.631c-1.225-2.35-4.054-5.58-4.834-6.47l-8.415 2.493c.501 3.71.967 7.612.967 7.612s-11.515 14.806-11.515 16.449c0 1.643-1.635 17.772-1.635 17.772l2.411.113s3.627-10.213 4.489-12.619c.863-2.407 1.204-6.142 1.204-6.142l10.639-9.98s1.203 9.76 2.303 11.843c1.1 2.083 8.66 17.109 8.66 17.109.648.166 1.308.275 1.975.328a3.427 3.427 0 001.315-.332z"
      />
      <path
        fill="#FF6C4E"
        d="M120.098 68.533a2.048 2.048 0 00-2.415 1.315c-.656 1.755 2.083 3.29 4.166 5.607 2.083 2.316 8.116 7.017 8.116 7.017l3.511 11.187 1.643-.333s-2.208-12.73-2.208-13.058c0-.328-9.208-9.432-9.54-9.76-.332-.328-1.643-1.755-1.643-1.755"
      />
      <path
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M120.098 68.533a2.048 2.048 0 00-2.415 1.315c-.656 1.755 2.083 3.29 4.166 5.607 2.083 2.316 8.116 7.017 8.116 7.017l3.511 11.187 1.643-.333s-2.208-12.73-2.208-13.058c0-.328-9.208-9.432-9.54-9.76-.332-.328-1.643-1.755-1.643-1.755"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M133.476 93.645l-.66 2.85 1.095 2.084s.863.328 1.1-.112l.216-.432s1.099-.112 1.207-1.1c.108-.987-1.315-3.618-1.315-3.618l-1.643.328z"
      />
      <path
        fill="#CC563E"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M105.741 128.086s-5.373 3.95-5.606 4.606c-.233.655 7.236-1.096 7.896-1.208.66-.112.108-3.286.108-3.286l-2.398-.112z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M100.687 132.058a2.39 2.39 0 00-.548.621c-.22.66 7.237-1.095 7.897-1.207.224-.035.306-.431.319-.945l-7.668 1.531z"
      />
      <path
        fill="#CC563E"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M135.447 128.405s-4.494.768-3.946 1.423a4.665 4.665 0 003.07 1.294c1.096 0 1.536.328 2.523.863.988.534 1.644.547 1.971-.333.328-.879-.327-3.286-.327-3.286s-2.631.582-3.291.039z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M137.094 132.024c.983.548 1.643.548 1.971-.332.036-.1.06-.204.069-.31-1.634.823-3.14-1.135-4.511-1.135-.863 0-2.066-.478-2.864-.862-.258.146-.379.293-.258.431a4.668 4.668 0 003.07 1.294c1.096.034 1.536.362 2.523.914z"
      />
      <path
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="0.401"
        d="M110.308 58.41c.863 1.725 2.109 3.782 3.549 4.623M109.079 55.525s.194.565.548 1.398"
      />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0.401"
        d="M140.376 61.965c.384.308.724.668 1.009 1.07M106.271 54.995s1.519 5.175 6.599 7.582c5.08 2.406 7.129 1.897 13.572-.906 4.934-2.156 9.781-2.027 12.77-.461M131.371 72.13c-.082-1.67 1.626-1.428 1.824-1.363"
      />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0.401"
        d="M122.806 63.185s2.959-.53 8.72-.457c5.762.073 9.329 5.994 8.194 9.556-1.043 3.27-6.425 3.287-7.892 1.294"
      />
      <path
        fill="#FF6C4E"
        d="M61.316 86.862c11.848 0 21.452-1.846 21.452-4.123s-9.604-4.123-21.452-4.123c-11.847 0-21.45 1.846-21.45 4.123s9.603 4.123 21.45 4.123z"
      />
      <path
        fill="#fff"
        d="M61.316 86.862c11.848 0 21.452-1.846 21.452-4.123s-9.604-4.123-21.452-4.123c-11.847 0-21.45 1.846-21.45 4.123s9.603 4.123 21.45 4.123z"
        opacity="0.5"
      />
      <path
        fill="#000"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M71.96 16.721v-3.527c0-1.372 1.954-3.76 4.908-3.882 2.954-.12 7.763 3.744 6.995 5.814-.768 2.07-1.872.862-1.872.862s.79.889-.293 1.38c-1.082.492-1.833-1.53-1.833-1.53s-1.48-1.38-2.86-1.38-.982.098-1.97 1.474-1.872.988-1.872 1.48c0 .491.69 1.293-.392 1.293-1.083 0-.811-1.983-.811-1.983z"
      />
      <path
        fill="#CC563E"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M29.705 64.886s-3.222-.919-3.683-.643c-.462.276-1.014 7.37-.828 8.013.185.643 3.018-4.791 3.018-4.791l1.38-.181.113-2.398z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M26.505 64.194a.965.965 0 00-.483.048c-.462.276-1.014 7.37-.828 8.013.043.15.232-.035.5-.406l.81-7.655z"
      />
      <path
        fill="#535B6A"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M56.331 41.488a23.973 23.973 0 00-2.212 3.882 223.674 223.674 0 01-1.751 3.959l-5.434 11.976s-10.135 2.398-11.424 2.764c-1.29.367-5.805.828-5.805.828l-.09 2.398s12.618.367 15.568.367c2.95 0 3.502.552 4.606-.276 1.104-.828 9.302-14.771 9.302-14.771l3.226-.181s9.122 10.501 9.859 11.605c.737 1.104 3.221 8.475 4.511 10.873a23.43 23.43 0 002.764 4.05l2.027-2.304S77.166 62.75 76.597 59.8c-.57-2.95-7.923-12.433-7.923-12.433l.737-4.883s-11.885.384-13.08-.996z"
      />
      <path
        fill="#FF6C4E"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M72.348 23s-6.68-.384-7.642-.319c-.962.065-1.863.19-1.988.768-.125.578-6.805 17.846-6.805 19.001 0 1.156 3.721 2.7 7.189 2.7 2.23-.015 4.395-.76 6.163-2.122.319-.254 8.987-16.496 9.181-16.884.194-.388.125-.961-.513-1.54-.638-.577-5.585-1.604-5.585-1.604z"
      />
      <path
        fill="#FF6C4E"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M72.733 21.996s-1.726.384-2.183.962c-.457.578-1.798 5.779-1.798 5.779l3.45-.707 1.988 3.018s1.35-4.364 1.415-6.227c.064-1.863-1.91-3.04-2.872-2.825zM62.779 22.972a24.973 24.973 0 00-5.434 2.393c-2.308 1.475-9.031 5.53-9.031 5.53l4.313 1.103 7.922-3.13s1.678-3.503 2.23-5.896z"
      />
      <path
        fill="#FF6C4E"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M60.937 31.816s-4.24-.462-6.176-1.014-3.774-1.66-5.71-.461c-1.936 1.199-1.75 2.587-.552 3.502 1.199.914 8.108.09 9.488 0a4.958 4.958 0 002.303-.738l.647-1.29z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M59.923 31.915s4.235-1.565 4.511-1.565c.276 0 .833 0 .923.642.09.643.462 2.213.09 2.303-.37.09-2.302.832-2.764.832a2.842 2.842 0 01-.918-.185 2.177 2.177 0 01-2.118-.367c-1.013-.84-.28-1.393.276-1.66z"
      />
      <path
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M61.765 33.933l-.371-1.104"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M62.964 31.54l.642 2.394M64.068 30.802l.642 2.484M62.041 31.996l.643 2.122"
      />
      <path
        fill="#FF6C4E"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M78.14 24.882a8.48 8.48 0 012.036 2.786c.987 1.975 3.881 8.306 3.881 8.306s.927 2.156-.988 2.846c-1.914.69-2.156-1.16-2.962-2.208-.807-1.048-3.774-6.447-3.774-6.447l1.807-5.283z"
      />
      <path
        fill="#FF6C4E"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M80.245 35.508a3.019 3.019 0 012.38-.695c1.51.173 2.036 2.497 1.454 3.718-.583 1.22-2.157 1.682-2.674 1.45-.518-.234-2.204-1.165-1.16-4.473z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M80.965 35.207a.315.315 0 01-.108 0 .366.366 0 01-.241-.462l.604-1.919a.361.361 0 01.461-.237.365.365 0 01.242.462l-.604 1.919a.375.375 0 01-.354.237z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M79.434 40.2a.556.556 0 01-.526-.72l1.514-4.81a.552.552 0 011.052.332l-1.514 4.81a.547.547 0 01-.526.387z"
      />
      <path
        fill="#000"
        d="M83.553 33.472a5.758 5.758 0 115.757-5.758 5.766 5.766 0 01-5.757 5.758zm0-10.41a4.653 4.653 0 10-.01 9.305 4.653 4.653 0 00.01-9.306z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M79.55 35.047s-.642-.233-.815.289c-.172.522-.698 1.626-.521 1.975.176.35.862.289 1.16-.116a7.261 7.261 0 00.582-1.687l-.406-.461z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M81.93 35.451l-2.38-.405a.667.667 0 00-.233.522c0 .35-.698 2.38-.526 2.846.173.466 2.385 1.044 2.558.755.172-.29.987-3.429.987-3.429s0-.233-.405-.289z"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M79.318 35.917l2.148.345M79.14 36.845l1.976.465M78.908 37.716l1.919.755"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M72.534 20.624s-.833 3.338-.833 4.818c0 1.479 0 2.695.513 2.587.514-.108 2.247-3.45 2.566-4.744.32-1.294-.828-3.817-2.246-2.66z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M72.352 16.92s-.69-1.182-1.082-.397c-.393.785-.79 2.661-.198 3.252.59.591.789-.293.789-.293s.099 2.66 1.082 3.644c.984.983 2.07 1.48 3.649.79 1.578-.69 3.545-3.55 4.136-5.517a17.94 17.94 0 00.69-2.958s-.393-1.574-2.661-2.07c-2.269-.496-4.628.397-4.628 1.38 0 .983.492 1.087-.099 1.38-.304.168-.641.27-.988.297 0 0-.392 2.066-.69.492z"
      />
      <path
        fill="#000"
        d="M78.618 18.978c-.099.345-.297.586-.43.543-.135-.043-.186-.354-.087-.694.1-.341.297-.587.431-.544.134.043.186.35.087.695z"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M75.043 17.67l1.41.858M78.231 17.304s.979-.919 1.294.185M76.881 17.183s-.185-1.35-1.35-.983M77.864 18.283a1.725 1.725 0 00-.677 1.23c0 .732.613 1.41.06 1.53-.551.121-1.41-.487-1.41-.487M76.33 22.087s-1.597.245-1.778-.919"
      />
      <path
        fill="#CC563E"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M79.434 78.98s.923 2.587 1.566 2.587c.642 0 5.804-5.067 5.99-5.606.185-.54-4.791 1.293-4.791 1.293l-.738-.556-2.027 2.281z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeMiterlimit="10"
        strokeWidth="0.535"
        d="M85.972 75.995l-5.39 5.261c.15.181.297.306.43.306.647 0 5.805-5.067 5.99-5.606.061-.19-.387-.125-1.03.039z"
      />
    </svg>
  );
}

export default ReservationZeroStateImage;
