import React, { useContext } from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ProjectContext } from 'providers/ProjectProvider';

import Loading from 'components/Loading';
import SalesContractsZeroStateImage from 'assets/images/ZeroStates/SalesContractsZeroStateImage';
import { useSalesContractsContext } from 'providers/ListProviders/SalesContractsListProvider';

function SalesContractsZeroState({ children }) {
  const history = useHistory();
  const { salesContracts, variables, isLoading, search } =
    useSalesContractsContext();
  const { project } = useContext(ProjectContext);
  const hasData = salesContracts.length;

  const isSearchSet = search && !hasData;
  const isFilterSet = variables.filterOptions.status && !hasData;

  const mainMessage =
    isSearchSet || isFilterSet
      ? 'Nenhum contrato encontrado'
      : 'No momento não há contratos de vendas';
  const subMessage =
    isSearchSet || isFilterSet
      ? ''
      : 'Para acessar esse recurso, aceite as reservas';

  const reservationButton =
    (!hasData && isSearchSet) || isFilterSet ? (
      ''
    ) : (
      <Button
        onClick={() => history.push(`/${project.slug}/reservas`)}
        color="primary"
        variant="contained"
        size="large"
      >
        Ir para reservas
      </Button>
    );

  if (isLoading) {
    return <Loading height={100} />;
  }
  if (hasData) {
    return children;
  }
  return (
    <Box width="100%" mt={15}>
      <Box display="flex" alignItems="center" justifyContent="center">
        <SalesContractsZeroStateImage />
      </Box>
      <Box
        color="#495058"
        justifyContent="center"
        alignItems="center"
        display="flex"
        mt={5}
      >
        <Typography display="block" variant="h3">
          {mainMessage}
        </Typography>
      </Box>
      <Box
        color="#767C81"
        justifyContent="center"
        alignItems="center"
        display="flex"
        mt={2}
      >
        <Box width="35%">
          <Typography align="center">{subMessage}</Typography>
        </Box>
      </Box>
      <Box
        color="#767C81"
        justifyContent="center"
        alignItems="center"
        display="flex"
        mt={5}
      >
        {reservationButton}
      </Box>
    </Box>
  );
}

SalesContractsZeroState.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SalesContractsZeroState;
