import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import RealEstateAutoComplete from 'components/Autocompletes/RealEstateAutoComplete';
import CPFTextField from 'components/TextFields/CPFTextField';
import EmailTextField from 'components/TextFields/EmailTextField';
import PhoneTextField from 'components/TextFields/PhoneTextField';
import React from 'react';
import useStyles from './styles';

export default function GeneralTab({
  projectId,
  realEstateAgent,
  setRealEstateAgent,
  action,
}) {
  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      <Box width="100%" px={1.5} mb={-1}>
        <Typography className={classes.sectionTitle}>
          Informações Gerais
        </Typography>
      </Box>

      <Grid item xs={12} md={6}>
        <TextField
          autoFocus
          margin="dense"
          name="erea-name"
          label="Nome"
          type="text"
          value={realEstateAgent.name}
          onChange={(e) =>
            setRealEstateAgent({
              ...realEstateAgent,
              name: e.target.value,
            })
          }
          fullWidth
          required
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <FormControl margin="dense" fullWidth>
          <InputLabel id="gender-select-label">Gênero</InputLabel>
          <Select
            name="erea-gender"
            labelId="gender-select-label"
            value={realEstateAgent.gender || ''}
            onChange={(e) =>
              setRealEstateAgent({
                ...realEstateAgent,
                gender: e.target.value,
              })
            }
          >
            <MenuItem value="">Não informar</MenuItem>
            <MenuItem value="female">Feminino</MenuItem>
            <MenuItem value="male">Masculino</MenuItem>
            <MenuItem value="other">Outro</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} md={6}>
        <CPFTextField
          margin="dense"
          name="erea-cpf"
          label="CPF"
          type="text"
          value={realEstateAgent.cpf}
          onChange={(_, value) =>
            setRealEstateAgent({ ...realEstateAgent, cpf: value })
          }
          fullWidth
          disabled={action === 'edit'}
          required
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          margin="dense"
          name="erea-rg"
          label="RG com órgão expedidor"
          placeholder="00.000.000-0 XXX/UF"
          type="text"
          value={realEstateAgent.rg}
          onChange={(e) =>
            setRealEstateAgent({
              ...realEstateAgent,
              rg: e.target.value,
            })
          }
          fullWidth
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          margin="dense"
          name="erea-creci"
          label="CRECI"
          type="text"
          value={realEstateAgent.creci}
          onChange={(e) =>
            setRealEstateAgent({
              ...realEstateAgent,
              creci: e.target.value,
            })
          }
          fullWidth
          required
        />
      </Grid>

      {action === 'edit' ? (
        <Grid item xs={6}>
          <TextField
            margin="dense"
            name="erea-reservationLimit"
            label="Limite de Reservas"
            value={realEstateAgent.reservationLimit}
            fullWidth
            disabled
          />
        </Grid>
      ) : null}

      <Grid item xs={12}>
        <RealEstateAutoComplete
          projectId={projectId}
          label="Imobiliária *"
          value={realEstateAgent.realEstate}
          onChange={(value) =>
            setRealEstateAgent({
              ...realEstateAgent,
              realEstate: value,
              realEstateId: value ? value._id : null,
            })
          }
        />
      </Grid>

      <Box width="100%" px={1.5} pt={3} mb={-1}>
        <Typography className={classes.sectionTitle}>Contato</Typography>
      </Box>

      <Grid item xs={6}>
        <PhoneTextField
          margin="dense"
          name="crs-phone"
          label="Telefone 1"
          type="text"
          value={realEstateAgent.phone || ''}
          onChange={(_, value) =>
            setRealEstateAgent({ ...realEstateAgent, phone: value })
          }
          fullWidth
        />
      </Grid>

      <Grid item xs={6}>
        <PhoneTextField
          margin="dense"
          name="crs-phone2"
          label="Telefone 2"
          value={realEstateAgent.phone2 || ''}
          onChange={(_, value) =>
            setRealEstateAgent({ ...realEstateAgent, phone2: value })
          }
          type="text"
          fullWidth
        />
      </Grid>

      <Grid item xs={12}>
        <EmailTextField
          type="email"
          margin="dense"
          name="crs-email"
          label="E-mail"
          value={realEstateAgent.email || ''}
          onChange={(e) =>
            setRealEstateAgent({ ...realEstateAgent, email: e.target.value })
          }
          fullWidth
        />
      </Grid>

      <Box
        display="flex"
        alignItems="center"
        width="100%"
        px={1.5}
        pt={1.5}
        mb={-1}
      >
        <Typography className={classes.sectionTitle}>
          Permitir acesso do sistema
        </Typography>

        <Box ml={3}>
          <FormControlLabel
            control={
              <Switch
                name="crs-active"
                checked={realEstateAgent.active}
                color="primary"
                onChange={(e) =>
                  setRealEstateAgent({
                    ...realEstateAgent,
                    active: e.target.checked,
                  })
                }
              />
            }
          />
        </Box>
      </Box>

      {realEstateAgent.active ? (
        <Grid item xs={12}>
          <EmailTextField
            type="email"
            margin="dense"
            name="crs-email"
            label="E-mail de acesso"
            value={realEstateAgent.username || ''}
            onChange={(e) =>
              setRealEstateAgent({
                ...realEstateAgent,
                username: e.target.value,
              })
            }
            fullWidth
            required={realEstateAgent.active}
            disabled={realEstateAgent.reuseEmail}
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={realEstateAgent.reuseEmail}
                onChange={(e, checked) =>
                  setRealEstateAgent({
                    ...realEstateAgent,
                    reuseEmail: checked,
                  })
                }
                name="checkedB"
                color="primary"
              />
            }
            label="Usar o mesmo e-mail de contato"
          />
        </Grid>
      ) : null}
    </Grid>
  );
}
