export default function formatCpf(input) {
  let formattedCpf = '';
  if (input) {
    const cpf = String(input).replace(/[^\d]+/g, '');
    formattedCpf = `${cpf.slice(0, 3)}.${cpf.slice(3, 6)}.${cpf.slice(
      6,
      9
    )}-${cpf.slice(9, 11)}`;
  }
  return formattedCpf;
}
