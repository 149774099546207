import React from 'react';
import { Box, Button, Typography, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';

import Loading from 'components/Loading';
import BankInformationZeroStateImage from 'assets/images/ZeroStates/BankInformationZeroStateImage';
import { useBankAccountsListContext } from 'providers/ListProviders/BankAccountsListProvider';

export default function BankInformationZeroState({ children, onCreate }) {
  const theme = useTheme();
  const { bankAccounts, isLoading } = useBankAccountsListContext();

  const hasData = Boolean(bankAccounts.length);

  if (isLoading) return <Loading height={100} />;
  if (hasData) return children;

  return (
    <Box
      mt={8}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Box mb={5}>
        <BankInformationZeroStateImage />
      </Box>

      <Box display="flex" justifyContent="center">
        <Box mb={1}>
          <Typography className="fw700" align="center" variant="h3">
            Ainda não foi cadastrado <br />
            nenhuma conta bancária
          </Typography>
        </Box>
      </Box>

      <Box mb={4}>
        <Typography
          variant="body2"
          align="center"
          style={{ color: theme.palette.grey[300] }}
        >
          Comece cadastrando o seu banco, para <br />
          receber os valores dos seus títulos
        </Typography>
      </Box>

      <Button variant="contained" color="primary" onClick={onCreate}>
        Cadastrar banco
      </Button>
    </Box>
  );
}

BankInformationZeroState.propTypes = {
  onCreate: PropTypes.func,
};

BankInformationZeroState.defaultProps = {
  onCreate: () => {},
};
