import {
  Avatar,
  Box,
  Container,
  Typography,
  useTheme,
} from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import api from 'services/api';
import GET_INVITE_QUERY from 'queries/getInviteQuery';
import REJECT_INVITE_MUTATION from 'queries/rejectInviteQuery';
import INVITE_TYPES, { INVITE_TYPES_VALUES } from '@constants/invites/types';
import useStyles from './styles';
import SalesUserForm from './components/SalesUserForm';
import UserForm from './components/UserForm';

export default function Invitation() {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const { inviteId } = useParams();

  const [loading, setLoading] = useState(true);
  const [invite, setInvite] = useState(null);

  const inviteTypeLabel = useMemo(() => {
    if (!invite) return '';
    return INVITE_TYPES_VALUES.find((i) => i.value === invite.type)?.label;
  }, [invite]);

  const getInvite = useCallback(() => {
    api
      .call(GET_INVITE_QUERY, { id: inviteId })
      .then(({ getInvite: data }) => {
        setInvite(data);
        setLoading(false);
      })
      .catch(() => {
        Swal.fire('', 'Convite inválido!', 'error');
        history.push('/login');
      })
      .finally(() => {});
  }, [inviteId, history]);

  const handleDecline = useCallback(() => {
    api
      .call(REJECT_INVITE_MUTATION, { id: inviteId })
      .then(() => {
        Swal.fire('', 'Convite rejeitado com sucesso!', 'success');
        history.push('/');
      })
      .catch(() => {
        Swal.fire('', 'Não foi possível rejeitar o convite!', 'error');
      });
  }, [inviteId, history]);

  useEffect(() => {
    if (inviteId) {
      getInvite(inviteId);
    }
  }, [inviteId, getInvite]);

  const form = useMemo(() => {
    if (!invite) return null;

    if (invite.type === INVITE_TYPES.MANAGER.value) {
      return <UserForm invite={invite} onDecline={() => handleDecline()} />;
    }

    const salesTypes = [
      INVITE_TYPES.REAL_ESTATE.value,
      INVITE_TYPES.REAL_ESTATE_AGENT.value,
    ];

    if (salesTypes.includes(invite.type)) {
      return (
        <SalesUserForm
          inviteId={invite._id}
          email={invite.email}
          onDecline={() => handleDecline()}
        />
      );
    }

    return null;
  }, [invite, handleDecline]);

  const LoadingView = useMemo(
    () => (
      <Container>
        <Box my={4}>
          <Typography variant="h5">Carregando...</Typography>
        </Box>
      </Container>
    ),
    []
  );

  const NotFoundView = useMemo(
    () => (
      <Container>
        <Box my={4}>
          <Typography variant="h5">Convite não encontrado!</Typography>
        </Box>
      </Container>
    ),
    []
  );

  if (loading) {
    return LoadingView;
  }

  if (!invite) {
    return NotFoundView;
  }

  return (
    <Container>
      <Box maxWidth={400} mx="auto" my={10}>
        <Box display="flex" flexDirection="column" alignItems="center" mb={5}>
          <Avatar className={classes.avatar} />

          <Box mb={3}>
            <Typography
              className="fw700"
              variant="h5"
              style={{ color: theme.palette.secondary.dark }}
            >
              Convite Quadra A
            </Typography>
          </Box>

          <Typography align="center">
            {'Você foi convidado para participar do empreendimento '}
            <Typography
              component="span"
              style={{ color: theme.palette.secondary.dark }}
            >
              {invite.project.title}
            </Typography>
            {' como '}
            <Typography
              component="span"
              style={{ color: theme.palette.secondary.dark }}
            >
              {inviteTypeLabel}
            </Typography>
            .
          </Typography>
        </Box>

        {form}
      </Box>
    </Container>
  );
}
