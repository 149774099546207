import React, { useContext, useRef } from 'react';
import { Box, Button } from '@material-ui/core';

import api from 'services/api';
import { ProjectContext } from 'providers/ProjectProvider';
import ErrorHandler from 'modules/ErrorHandlerModule';
import AlertModule from 'modules/AlertModule';

function UploadButton() {
  const fileInput = useRef(null);

  const { project } = useContext(ProjectContext);
  const hasMap = project && project.mapFileId;

  const handleSelectFile = () => {
    fileInput.current.click();
  };

  const sendUpload = (file, recalculateMarks) =>
    Promise.resolve(AlertModule.wait())
      .then(() => api.uploadMap(project._id, file, recalculateMarks))
      .then(() => window.location.reload())
      .catch(
        ErrorHandler(
          'Não foi possível alterar o mapa do empreendimento, tente novamente.'
        )
      );

  const handleUpload = (e) => {
    let recalculateMarks = false;
    const file = e.target.files[0];
    e.target.value = '';

    if (!file) return;

    if (!hasMap) {
      sendUpload(file);
      return;
    }

    AlertModule.confirmation(
      'Deseja substituir o mapa do empreendimento?'
    ).then((response) => {
      if (!response.isConfirmed) return;
      AlertModule.confirmation('Deseja manter as marcações do mapa?').then(
        (result) => {
          if (result.isConfirmed) {
            recalculateMarks = true;
          }
          sendUpload(file, recalculateMarks);
        }
      );
    });
  };

  return (
    <Box>
      <input
        type="file"
        accept=".jpg, .png"
        id="fileInput"
        name="map"
        onChange={handleUpload}
        hidden
        ref={fileInput}
      />

      <Button
        variant="contained"
        color="primary"
        onClick={() => handleSelectFile()}
      >
        Carregar Mapa
      </Button>
    </Box>
  );
}

export default UploadButton;
