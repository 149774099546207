import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import SelectionButton from './SelectionButton';
import Links from './Links';

export default function SelectionView() {
  const history = useHistory();

  return (
    <Box>
      <Box mb={5}>
        <Typography variant="h4" color="textSecondary">
          Como deseja logar?
        </Typography>
      </Box>

      <Box>
        <SelectionButton
          title="Sou da Coordenação"
          id="ls-admin"
          onClick={() => history.push('/login/coordenador')}
        />
        <SelectionButton
          title="Sou Corretor/Imobiliária"
          id="ls-sales"
          onClick={() => history.push('/login/corretor')}
        />
      </Box>

      <Links mt={25} padding={2} />
    </Box>
  );
}
