import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, useTheme } from '@material-ui/core';

import CustomerAutoComplete from 'components/Autocompletes/CustomerAutoComplete';

import isEditionDisabled from '../utils/isEditionDisabled';

export default function CostumerInformation({ reservation, setReservation }) {
  const theme = useTheme();
  const editionDisabled = isEditionDisabled(reservation);
  return (
    <Box mb={5}>
      <Box mb={1}>
        <Typography
          className="fw700"
          style={{ color: theme.palette.secondary.dark }}
        >
          Informações do cliente
        </Typography>
      </Box>

      <Box mb={3}>
        <Typography
          variant="body2"
          style={{ color: theme.palette.secondary.main }}
        >
          Cliente
        </Typography>

        <CustomerAutoComplete
          placeholder="Nenhum cliente selecionado"
          projectId={reservation.projectId}
          value={reservation.customer}
          onChange={(_, value) => {
            setReservation({ ...reservation, customer: value });
          }}
          disabled={editionDisabled}
        />
      </Box>
    </Box>
  );
}

CostumerInformation.propTypes = {
  reservation: PropTypes.shape({
    projectId: PropTypes.string,
    customer: PropTypes.shape({}),
  }).isRequired,
};
