/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Grid, ThemeProvider } from '@material-ui/core';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

import darkTheme from 'theme/darkTheme';
import useFetch from 'hooks/useFetch';
import meQuery from 'queries/meQuery';
import useStyles from './style';
import LoginForm from './components/LoginForm';
import SelectionView from './components/SelectionView';

export default function Login() {
  const classes = useStyles();
  const history = useHistory();

  const [, error, isLoading] = useFetch(meQuery, null, false);
  const [authenticated, setAuthenticated] = useState(true);

  useEffect(() => {
    if (!isLoading) {
      if (error) {
        setAuthenticated(false);
      } else {
        history.push('/');
      }
    }
  }, [error, isLoading]);

  if (authenticated) return null;

  return (
    <Box component="main">
      <CssBaseline />

      <Grid className={classes.mainGrid} container spacing={0}>
        <Grid item xs={12} md={6}>
          <Box className={classes.banner}>
            <img
              className="brand"
              src="/assets/images/logo.svg"
              alt="Quadra A"
            />
            <Box className="wrapper">
              <Box maxWidth={560}>
                <Typography variant="h5" color="primary">
                  Conheça
                </Typography>

                <Box mt={1} mb={2}>
                  <Typography
                    className="fw900"
                    variant="h2"
                    color="textPrimary"
                  >
                    O melhor gerenciador de loteamentos do mercado brasileiro.
                  </Typography>
                </Box>

                <Typography variant="h4" color="textSecondary">
                  Confiável, transparente e seguro.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <ThemeProvider theme={darkTheme}>
            <Box
              className={classes.content}
              style={{ backgroundColor: darkTheme.palette.background.default }}
            >
              <Box className="wrapper">
                <Switch>
                  <Route exact path="/login" component={SelectionView} />
                  <Route path="/login/corretor">
                    <LoginForm environment="sales" />
                  </Route>
                  <Route path="/login/coordenador">
                    <LoginForm environment="administration" />
                  </Route>
                  <Route path="*">
                    <Redirect to="/login" />
                  </Route>
                </Switch>
              </Box>
            </Box>
          </ThemeProvider>
        </Grid>
      </Grid>
    </Box>
  );
}
