import React from 'react';
import PropTypes from 'prop-types';
import { GiSpy } from 'react-icons/gi';
import { Box, Button, Container, Grid, Typography } from '@material-ui/core';

import api from 'services/api';
import useQuery from 'hooks/useQuery';
import Alert from 'modules/AlertModule';
import LOGOUT_AS_QUERY from 'queries/logoutAsQuery';

const reload = () => {
  document.location = '/konoha';
};

export default function LogoutAsForm({ username }) {
  const [logout, isLoggingOut] = useQuery(LOGOUT_AS_QUERY);

  function handleLogout() {
    api.resetSocket();
    logout().then(reload).catch(Alert.onError('Falha ao sair :('));
  }

  return (
    <Container component="main" maxWidth="xs">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box
            my={10}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Typography variant="h2">Você está logado como:</Typography>
            <Box mb={3}>{username}</Box>
            <Button
              variant="contained"
              onClick={() => handleLogout()}
              disabled={isLoggingOut}
              color="primary"
              startIcon={<GiSpy />}
            >
              Sair
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

LogoutAsForm.propTypes = {
  username: PropTypes.string,
};

LogoutAsForm.defaultProps = {
  username: '',
};
