import React from 'react';
import { Typography } from '@material-ui/core';
import formatCnpj from 'utils/formatCnpj';
import RealEstateSwitch from '../components/RealEstateSwitch';

export default function useColumns() {
  const columns = [
    {
      title: 'Nome',
      field: 'name',
      render: (rowData) => (
        <Typography color="primary">{rowData.name}</Typography>
      ),
    },
    {
      title: 'CNPJ',
      field: 'cnpj',
      render: (rowData) => formatCnpj(rowData.cnpj),
    },
    { title: 'CRECI', field: 'creci' },
    { title: 'Reservas', field: 'reservationLimit' },

    {
      title: 'Acesso',
      field: 'active',
      render: (rowData) => <RealEstateSwitch rowData={rowData} />,
    },
  ];
  return columns;
}
