import React from 'react';

import { useTheme } from '@material-ui/core';
import { getInviteColors } from './BaseStatusLabel/colors';
import { inviteStatusTexts } from './BaseStatusLabel/texts';
import BaseStatusLabel from './BaseStatusLabel';

export default function InviteStatusLabel({ status }) {
  const theme = useTheme();
  const color = getInviteColors(theme)[status];

  return (
    <BaseStatusLabel
      size="small"
      label={inviteStatusTexts[status]}
      color={color}
    />
  );
}
