import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';

import { useSalesContractsContext } from 'providers/ListProviders/SalesContractsListProvider';
import api from 'services/api';

import FilterSelect from 'components/FilterSelect';
import SearchListTextField from 'components/SearchListTextField';
import ExportListButton from 'components/ExportListButton';

import FILTER_OPTIONS from './constants/filterOptions';
import useStyles from '../styles';
import getSelectedFilterOption from '../utils/getSelectedFilterOption';

export default function SalesContractsListFilters() {
  const classes = useStyles();
  const { search, setSearch, variables, setFilter } =
    useSalesContractsContext();

  const filterOption = getSelectedFilterOption(
    FILTER_OPTIONS,
    variables.filterOptions.status
  );

  const handleExport = () => {
    const exportVariables = { ...variables, search };
    delete exportVariables.page;
    api.exportSalesContracts(exportVariables);
  };
  return (
    <Box mb={5}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs="auto">
          <Typography className="fw700" variant="h5" color="textPrimary">
            Contratos de Venda
          </Typography>
        </Grid>

        <Grid item xs={12} md="auto">
          <Box minWidth={285}>
            <SearchListTextField
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md>
          <Box className={classes.listFilters}>
            <FilterSelect
              value={filterOption}
              options={FILTER_OPTIONS}
              onChange={(option) => setFilter(option.value)}
            />
            <ExportListButton onClick={handleExport} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
