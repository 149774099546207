import React from 'react';
import { MenuItem, TextField } from '@material-ui/core';

import InputField from 'components/InputField';
import Picker from 'components/Picker';
import DocumentTextField from 'components/TextFields/DocumentTextField';

export default function BankInformation({ bankAccount, setBankAccount }) {
  return (
    <>
      <Picker required disabled label="Banco" value={bankAccount.bankCode}>
        <MenuItem value={237}>Bradesco S. A.</MenuItem>
      </Picker>
      <InputField>
        <TextField
          label="Código da empresa"
          value={bankAccount.companyCode}
          placeholder="Identificação no sistema do banco"
          onChange={(e) => {
            setBankAccount((value) => ({
              ...value,
              companyCode: e.target.value.replace(/[^0-9]/g, ''),
            }));
          }}
          InputLabelProps={{ shrink: true, required: true }}
          fullWidth
        />
      </InputField>
      <InputField>
        <TextField
          label="Agência"
          placeholder="0000"
          value={bankAccount.branchNumber}
          onChange={(e) => {
            setBankAccount((value) => ({
              ...value,
              branchNumber: e.target.value.replace(/[^0-9]/g, ''),
            }));
          }}
          InputLabelProps={{ shrink: true, required: true }}
          fullWidth
        />
      </InputField>
      <InputField>
        <TextField
          label="Dígito da agência"
          placeholder="0"
          value={bankAccount.branchDigit}
          onChange={(e) => {
            setBankAccount((value) => ({
              ...value,
              branchDigit: e.target.value.replace(/[^0-9]/g, ''),
            }));
          }}
          InputLabelProps={{ shrink: true }}
          inputProps={{ maxLength: 1 }}
          fullWidth
        />
      </InputField>
      <InputField>
        <TextField
          label="Conta"
          placeholder="000000"
          value={bankAccount.accountNumber}
          onChange={(e) => {
            setBankAccount((value) => ({
              ...value,
              accountNumber: e.target.value.replace(/[^0-9]/g, ''),
            }));
          }}
          InputLabelProps={{ shrink: true, required: true }}
          fullWidth
        />
      </InputField>
      <InputField>
        <TextField
          label="Dígito da conta"
          placeholder="0"
          value={bankAccount.accountDigit}
          onChange={(e) => {
            setBankAccount((value) => ({
              ...value,
              accountDigit: e.target.value.replace(/[^0-9]/g, ''),
            }));
          }}
          InputLabelProps={{ shrink: true, required: true }}
          inputProps={{ maxLength: 1 }}
          fullWidth
        />
      </InputField>
      <InputField>
        <DocumentTextField
          label="Documento do beneficiário"
          value={bankAccount.document}
          placeholder="CPF/CNPJ"
          onChange={(e, value) => {
            setBankAccount((currentBankAccount) => ({
              ...currentBankAccount,
              document: value,
            }));
          }}
          InputLabelProps={{ shrink: true, required: true }}
          fullWidth
        />
      </InputField>
      <InputField>
        <TextField
          label="Razão social"
          value={bankAccount.companyName}
          placeholder="Empresa S.A."
          onChange={(e) => {
            setBankAccount((value) => ({
              ...value,
              companyName: e.target.value,
            }));
          }}
          InputLabelProps={{ shrink: true, required: true }}
          fullWidth
        />
      </InputField>
    </>
  );
}
