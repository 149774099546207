import React from 'react';

function RemittanceListZeroStateImage() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="161"
      height="160"
      fill="none"
      viewBox="0 0 161 160"
    >
      <path
        fill="#F0F2F4"
        d="M133.354 33.693c-2.809-4.775-6.736-8.685-11.702-11.875C105.111 11.2 82.635 7.36 63.31 10.259 49.505 12.32 40.302 21.706 34.417 33.94 27.46 48.378 21.143 62.752 13.227 76.8 4.135 92.89.058 109.626 19.809 123.52c17.088 12.026 38.522 8.906 58.112 8.522 6.49-.128 13.03.086 19.417 1.344 12.695 2.496 25.028 11.369 37.511 3.081 9.491-6.297 15.081-18.198 17.789-28.982 1.616-6.426 2.88-13.514 2.288-20.336-.656-7.472-4.103-13.098-7.648-19.46a113.354 113.354 0 01-10.352-25.417 34.902 34.902 0 00-3.572-8.58z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0.386"
        d="M108.276 28.48H27.06v111.775h81.216V28.48z"
      />
      <path
        fill="#fff"
        d="M36.127 52.88V36.383h-3.035v-2.564h9.506v2.564H39.61v16.499h-3.482zM42.907 52.88l3.81-19.063h3.722l3.81 19.064h-3.224l-.752-4.4h-3.32l-.772 4.4h-3.274zm4.4-6.614h2.588l-1.292-8.002-1.296 8.002zM54.862 52.88L58.7 42.76l-3.721-8.942h3.25l2.47 5.904 2.166-5.904h3.012l-3.67 9.602 3.952 9.46h-3.247l-2.7-6.401-2.352 6.402h-2.997z"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0.386"
        d="M96.71 73.787h7.073M85.75 73.787h9.784M65.48 73.787h18.619M38.726 73.787h25.577M31.973 73.787h5.342M87.87 79.129h15.913M58.41 79.129h28.05M45.09 79.129h11.434M31.973 79.129h11.235M69.37 84.474h34.413M56.17 84.474h11.496M39.285 84.474H55.11M31.973 84.474h6.283M103.355 88.747h-71.81v46.163h71.81V88.747zM50.867 97.509h52.276M31.761 97.509h17.335M31.761 103.707h71.382M93.648 109.908h9.495M31.761 109.908h61.062M61.002 116.105h42.141M31.761 116.105h28.18M49.097 122.303h54.046M31.761 122.303H46.86M62.298 128.501h40.845M31.761 128.501h29.121M87.543 127.124v7.574M87.543 94.362v31.466M87.543 89.175v3.186"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0.386"
        d="M106.517 140.892l-1.388 1.026s-2.414.979-2.777 1.184c-.362.204-.976.258-.821.721.154.463 1.184.463 2.418.563 1.234.1 1.593 0 2.931-.205 1.338-.204 2.364.155 2.877-.104.513-.258.567 0 .671-.617.065-.476.099-.957.1-1.438l-4.011-1.13z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0.386"
        d="M109.757 144.077c.517-.254.567 0 .671-.617.031-.189.05-.385.065-.594a6.278 6.278 0 01-2.071.544c-.979 0-1.388.259-3.652.413a14.011 14.011 0 01-3.259-.181.385.385 0 00.02.181c.154.463 1.184.463 2.418.563 1.234.1 1.593 0 2.931-.205 1.338-.204 2.364.162 2.877-.104zM130.941 140.892s-.308.717-.563 1.334c-.255.617-1.234 1.905-1.388 2.264-.155.359.1.721.821.771a11.73 11.73 0 002.16-.104c.204-.05 2.468-2.723 2.572-4.319.104-1.597-1.327-1.543-1.327-1.543l-2.275 1.597z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0.386"
        d="M134.555 140.838a1.96 1.96 0 00-.074-.694c0 .069-.038.138-.054.216-.084.451-1.261 2.676-1.712 3.424-.451.748-.833.964-1.778 1.049a6.73 6.73 0 01-1.974-.204c-.043.308.235.586.86.632.721.032 1.444-.003 2.159-.104.193-.042 2.457-2.723 2.573-4.319z"
      />
      <path
        fill="#535B6A"
        stroke="#263238"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0.386"
        d="M113.563 109.422l-.104 3.652s-6.425 14.243-6.579 15.072c-.155.829.871 12.341.871 12.341h-1.592l-.051.617 4.42 1.234.875-5.295c.051-.309.926-9.102.926-9.102l6.221-8.38s3.135 9.973 3.289 10.027c.155.054 8.793 10.59 8.793 10.59l-.254.463s-.104.413.463.721c.567.309 1.026.513 1.592-.054.567-.567 1.157-1.338 1.211-1.901.054-.563-.489-1.438-.902-2.21-.413-.771-1.184-2.005-1.184-2.005l-4.782-8.33-.706-13.687-.208-2.569-12.299-1.184z"
      />
      <path
        fill="#FF6C4E"
        stroke="#263238"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0.386"
        d="M108.33 91.67a2.344 2.344 0 00-.995 1.138c-.285.771.332 1.928.332 1.928s1.704 4.593 2.553 5.916c.848 1.323 2.51 2.603 3.98 2.414 1.469-.189 1.465-1.322 1.465-1.322s1.562-2.511.332-4.358c-1.23-1.848-6.398-6.522-7.667-5.716z"
      />
      <path
        fill="#FF6C4E"
        stroke="#263238"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0.386"
        d="M118.018 91.142s-2.314 6.197-2.469 7.563c-.154 1.365-2.468 10.189-2.572 10.609-.104.42.995.891 1.365.999.37.108 9.665 1.365 10.66.945.995-.421 1.261-.386 1.261-.841 0-.455 1.839-15.862.945-17.806-.895-1.944-1.867-3.96-2.403-4.03-.536-.07-6.787 2.56-6.787 2.56z"
      />
      <path
        fill="#FF6C4E"
        d="M121.018 97.081c1.184-.409 4.986-1.18 4.986-1.18s-3.802 2.414-5.757 3.444"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0.386"
        d="M121.018 97.081c1.184-.409 4.986-1.18 4.986-1.18s-3.802 2.414-5.757 3.444M126.109 96.672l-3.086 2.827"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0.386"
        d="M117.663 78.01s-1.242 1.678-.995 2.36c.151.33.194.7.123 1.057 0 0-1.542 1.801-1.242 1.986.261.224.473.5.621.81 0 0 .386 2.422.683 2.796.297.374 1.246.062 1.246.062l.62-.062.432 3.043 4.721-2.484v-2.314s3.108-3.108 3.047-4.724c-.062-1.616.061-3.105-.56-3.664-.621-.559-3.471-1.739-4.782-1.928-1.311-.189-2.052-.386-2.426-.123-.374.262-.991.682-.991.682s0-1.492-.748-.871c-.748.62-.621 2.888.251 3.374z"
      />
      <path
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0.386"
        d="M126.359 76.903c-.621-.559-3.471-1.739-4.782-1.928-1.311-.189-2.052-.386-2.426-.123-.374.262-.991.682-.991.682s0-1.492-.748-.871c-.748.62-.621 2.857.251 3.355l2.919.25a4.09 4.09 0 00-.436 1.304c-.061.621 1.057 1.118 1.057 1.118s-.312 1.74-.312 2.048c0 .309.686.386.81.124.123-.262-.189-.991.806-1.242.995-.25 1.492.56 1.43 1.307a1.64 1.64 0 01-.871 1.365l-.386.186c.451.175.855.452 1.18.81 0 0 3.109-3.109 3.047-4.725-.062-1.616.073-3.1-.548-3.66z"
      />
      <path
        fill="#263238"
        d="M118.033 81.655c0 .293-.108.528-.247.528s-.247-.235-.247-.528c0-.293.108-.529.247-.529s.247.24.247.529z"
      />
      <path
        fill="#FF6C4E"
        stroke="#263238"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0.386"
        d="M117.601 90.505l.309 1.867 6.895-3.791-.806-1.678-6.398 3.602z"
      />
      <path
        fill="#535B6A"
        stroke="#263238"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0.386"
        d="M140.34 122.812a4.69 4.69 0 01-3.488 1.134 4.68 4.68 0 01-3.235-1.728L98.739 78.905l3.744-3.263 38.18 40.429a4.68 4.68 0 01-.323 6.741z"
      />
      <path
        fill="#FF6C4E"
        stroke="#263238"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0.386"
        d="M106.868 79.283l-4.867 4.242a1.571 1.571 0 01-2.601-1.292c.028-.416.22-.805.534-1.08l4.867-4.242a1.563 1.563 0 011.14-.385 1.571 1.571 0 011.385 1.068 1.576 1.576 0 01-.458 1.689zM71.596 45.02a22.187 22.187 0 1029.167 33.442A22.187 22.187 0 0071.596 45.02zm27.25 31.24a19.282 19.282 0 11-25.384-29.033A19.282 19.282 0 0198.847 76.26z"
      />
      <path
        fill="#FF6C4E"
        d="M121.442 93.502s1.473-3.2 1.894-3.571c.42-.37 1.157-.313 1.835-.262.679.05 5.913 4.674 5.913 4.674s4.203 3.15 4.149 3.833c-.054.683-5.399 10.085-5.399 10.085l-2.221-1.365 1.157-7.675-6.356-3.57"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0.386"
        d="M121.442 93.502s1.473-3.2 1.894-3.571c.42-.37 1.157-.313 1.835-.262.679.05 5.913 4.674 5.913 4.674s4.203 3.15 4.149 3.833c-.054.683-5.399 10.085-5.399 10.085l-2.221-1.365 1.157-7.675-6.356-3.57"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0.386"
        d="M127.613 106.896l-.575.474s-.841-.262-1.261-.054c-.267.142-.525.3-.771.475 0 0-.891 0-1.049.208-.158.208-.317.474 0 .683.316.208 2.048-.105 2.206 0 .158.104-.209.632-.525 1.473-.316.84-.771 1.469-.524 1.731.246.263.682.421.891.212l.212-.212s1.157.945 1.573.841c.417-.104 1.365-.895 1.682-1.928.211-.826.333-1.671.366-2.522l-2.225-1.381zM106.378 89.352a4.97 4.97 0 00-.27-.995c-.266-.833-.655-1.114-.219-1.288.435-.173.802.243.929.617.083.24.181.474.293.702l-.578 1.342-.155-.378z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0.386"
        d="M107.215 87.266s-1.068 2.507-.999 2.738c.07.231 2.145 2.283 2.145 2.283l-.386.899c.135.432.419.8.802 1.041 1.138.52 1.705-.71 1.705-.71l-.706-1.018s.729-1.754.771-1.928c.043-.174-2.352-2.996-2.352-2.996s-.185-.857-.98-.309z"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0.386"
        d="M108.222 87.567l-1.365 3.166M108.958 88.53l-1.16 3.067M109.849 89.711l-1.145 2.306M108.361 92.287l1.295.706"
      />
    </svg>
  );
}

export default RemittanceListZeroStateImage;
