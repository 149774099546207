import React from 'react';
import { Grid, Typography, useTheme } from '@material-ui/core';
import InformationBlock from 'components/InformationBlock';

import formatDate from 'utils/formatDate';
import formatPrice from 'utils/formatPrice';

export default function Monthly({ reservation, monthlyInstallment }) {
  const theme = useTheme();
  if (!reservation.firstMonthlyInstallmentDate) return null;

  return (
    <>
      <Grid item xs={12}>
        <Typography
          variant="body2"
          style={{ color: theme.palette.secondary.dark }}
        >
          Mensal
        </Typography>
      </Grid>
      <InformationBlock label="Parcela">
        {reservation.installments} x {formatPrice(monthlyInstallment)}
      </InformationBlock>
      <InformationBlock label="1° prestação">
        {formatDate(reservation.firstMonthlyInstallmentDate)}
      </InformationBlock>
    </>
  );
}
