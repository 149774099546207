const PROPERTY_MARK_CREATED = 'propertyMarkCreated';
const PROPERTY_MARK_DELETED = 'propertyMarkDeleted';
const RESERVATION_UPDATED = 'reservationUpdated';
const RESERVATION_CREATED = 'reservationCreated';
const PROPERTY_UPDATED = 'propertyUpdated';
const PROPERTY_CREATED = 'propertyCreated';
const PROPERTY_DELETED = 'propertyDeleted';
const SALES_CONTRACT_CREATED = 'salesContractCreated';
const SALES_CONTRACT_INVOICE_CREATED = 'salesContractInvoiceCreated';
const SALES_CONTRACT_INVOICE_UPDATED = 'salesContractInvoiceUpdated';
const SALES_CONTRACT_UPDATED = 'salesContractUpdated';
const JOIN_PROJECT = 'joinProject';
const CUSTOME_CREATED = 'customerCreated';
const CUSTOMER_UPDATED = 'customerUpdated';
const INVOICE_EMITTED = 'invoiceEmitted';
const REMITTANCE_CREATED = 'remittanceCreated';
const RETURN_FILE_CREATED = 'returnFileCreated';
export default {
  PROPERTY_MARK_CREATED,
  PROPERTY_MARK_DELETED,
  RESERVATION_CREATED,
  RESERVATION_UPDATED,
  PROPERTY_CREATED,
  PROPERTY_DELETED,
  PROPERTY_UPDATED,
  SALES_CONTRACT_CREATED,
  SALES_CONTRACT_INVOICE_CREATED,
  SALES_CONTRACT_INVOICE_UPDATED,
  CUSTOMER_UPDATED,
  CUSTOME_CREATED,
  SALES_CONTRACT_UPDATED,
  JOIN_PROJECT,
  INVOICE_EMITTED,
  REMITTANCE_CREATED,
  RETURN_FILE_CREATED,
};
