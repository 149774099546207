import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  dateSelect: {
    '& .MuiSelect-select': {
      ...theme.typography.caption,
      color: theme.palette.grey[100],
    },

    '& .MuiInput-underline:before': {
      display: 'none',
    },

    '& .MuiInput-underline:after': {
      display: 'none',
    },

    '& .MuiSelect-icon': {
      top: 'calc(50% - 16px)',
    },
  },

  dateSelectMenuList: {
    '& .MuiPaper-root': {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
    },

    '& .MuiList-root': {
      height: 200,
      overflow: 'auto',
      padding: 0,
    },

    '& .MuiMenuItem-root': {
      ...theme.typography.body2,
      paddingRight: 32,

      '&.Mui-selected': {
        backgroundColor: theme.palette.grey[700],
        color: theme.palette.primary.main,
      },
    },
  },
}));

export default {};
