import { useCallback, useState } from 'react';
import filterInvoicesBySearch from '../utils/filterInvoicesBySearch';

export default (remittance, search, open) => {
  const [invoicesInRemittance, setInvoicesInRemittance] = useState([]);

  const getInvoices = useCallback(() => {
    if (!open) setInvoicesInRemittance([]);
    const customersByContracts = remittance.contracts.reduce(
      (memo, contract) => {
        const accumulator = memo;
        const contractNumberId = contract.numberId;
        accumulator[contractNumberId] = contract.customer;
        return accumulator;
      },
      []
    );

    const invoicesWithCustomers = remittance.invoices.map((invoice) => {
      const { contractNumberId } = invoice;
      const auxObject = invoice;
      auxObject.customerName = customersByContracts[contractNumberId].name;
      return auxObject;
    });
    if (search) {
      setInvoicesInRemittance(
        filterInvoicesBySearch(invoicesWithCustomers, search)
      );
    } else {
      setInvoicesInRemittance(invoicesWithCustomers);
    }
  }, [remittance, search, open]);

  return [invoicesInRemittance, getInvoices];
};
