/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';
import { createFilterOptions } from '@material-ui/lab';
import PropTypes from 'prop-types';

import AlertModule from 'modules/AlertModule';

import useQuery from 'hooks/useQuery';

import GET_PROPERTIES_QUERY from 'queries/getPropertiesQuery';

import BaseAutoComplete from './BaseAutoComplete';

export default function PropertyAutoComplete({
  id,
  projectId,
  label,
  value,
  onChange,
  required,
  ...props
}) {
  const [executeGetProperties] = useQuery(GET_PROPERTIES_QUERY);

  const getProperties = useCallback((search) => {
    const variables = {
      projectId,
      search,
      itemsPerPage: 10,
      filterOptions: {
        withMarks: false,
      },
    };

    return executeGetProperties(variables)
      .then(({ getProperties: data }) => data.properties)
      .catch(AlertModule.onError('Não foi possivel carregar as marcações'));
  }, []);

  const getOptionLabel = (property) => `${property.unity}`;

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option) => `${option.unity}`,
  });

  return (
    <BaseAutoComplete
      {...props}
      id={id}
      label={label}
      value={value}
      required={required}
      onQuery={getProperties}
      onChange={onChange}
      getOptionLabel={getOptionLabel}
      filterOptions={filterOptions}
    />
  );
}

PropertyAutoComplete.propTypes = {
  id: PropTypes.string,
  projectId: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
};

PropertyAutoComplete.defaultProps = {
  id: '',
  label: '',
  value: null,
  required: false,
};
