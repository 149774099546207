import WebSocketController from 'utils/WebSocketController';
import { useEffect, useState } from 'react';

const socket = new WebSocketController();

export default ({ reservationId, onUpdate }) => {
  const [updatedReservationId, setUpdatedReservationId] = useState(null);

  useEffect(() => {
    socket.onReservationUpdated((newReservation) => {
      setUpdatedReservationId(newReservation._id);
    });

    return () => {
      socket.destroy();
    };
  });

  useEffect(() => {
    if (updatedReservationId) {
      if (updatedReservationId === reservationId) {
        onUpdate();
      }

      setUpdatedReservationId(null);
    }
  }, [updatedReservationId, reservationId, onUpdate]);
};
