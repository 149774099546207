import {
  Box,
  Button,
  DialogActions,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
} from '@material-ui/core';
import React, { useState, useEffect, useContext } from 'react';

import constants from '@constants';
import CREATE_CUSTOMER_MUTATION from 'queries/createCustomerMutation';
import viacep from 'services/viacep';
import PhoneTextField from 'components/TextFields/PhoneTextField';
import CPFTextField from 'components/TextFields/CPFTextField';
import PostalCodeTextField from 'components/TextFields/PostalCodeTextField';
import EmailTextField from 'components/TextFields/EmailTextField';
import handlerError from 'utils/handleError';
import PropTypes from 'prop-types';
import { ProjectContext } from 'providers/ProjectProvider';
import BaseDialog from 'components/Dialogs/BaseDialog';
import AlertModule from 'modules/AlertModule';
import useQuery from 'hooks/useQuery';
import validateInputs from './helpers/validate-inputs';

function CreateCustomerDialog({ show, onClose, onUpdate }) {
  const { project } = useContext(ProjectContext);

  const [step, setStep] = useState(0);
  const [customer, setCustomer] = useState({});
  const [executeCreateCustomer] = useQuery(CREATE_CUSTOMER_MUTATION);

  const handleSubmit = (e) => {
    e.preventDefault();

    const validate = validateInputs(customer);

    if (!validate.success) {
      setStep(validate.step);
      AlertModule.error(validate.message);
      return;
    }

    const newCustomerData = {
      projectId: project._id,
      ...customer,
    };

    AlertModule.wait();

    executeCreateCustomer(newCustomerData)
      .then(() => {
        onUpdate();
        AlertModule.success('Cliente cadastrado com sucesso!').then(() =>
          onClose()
        );
      })
      .catch((error) => {
        handlerError(
          {
            INVALID_CPF: AlertModule.onError('CPF inválido.'),
            CPF_IN_USE: AlertModule.onError('O CPF já está em uso.'),
            INVALID_EMAIL: AlertModule.onError('E-mail inválido'),
            DEFAULT: AlertModule.onError(
              'Não foi possível efetuar o cadastro.'
            ),
          },
          error.message
        );
      });
  };

  useEffect(() => {
    if (!show) {
      setCustomer({});
      setStep(0);
    }
  }, [show]);

  const handleAddressSearch = () => {
    viacep.buscar(customer.postalCode).then((data) => {
      setCustomer({
        ...customer,
        address: data.logradouro,
        addressComplement: data.complemento,
        district: data.bairro,
        city: data.localidade,
        state: data.uf,
      });
    });
  };

  return (
    <BaseDialog
      open={show}
      onClose={onClose}
      title="Novo cliente"
      maxWidth="sm"
    >
      <form onSubmit={handleSubmit}>
        <Box style={{ overflow: 'visible' }}>
          <Box mb={5}>
            <Tabs
              variant="fullWidth"
              value={step}
              onChange={(_, value) => setStep(value)}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tab label="1. Geral" style={{ marginRight: 24 }} />
              <Tab label="2. Endereço" />
            </Tabs>
          </Box>

          {step === 0 && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  autoFocus
                  margin="dense"
                  name="cc-name"
                  label="Nome"
                  type="text"
                  value={customer.name || ''}
                  onChange={(e) =>
                    setCustomer({ ...customer, name: e.target.value })
                  }
                  InputLabelProps={{ required: true }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <CPFTextField
                  margin="dense"
                  name="cc-cpf"
                  label="CPF"
                  type="text"
                  value={customer.cpf || ''}
                  onChange={(_, value) =>
                    setCustomer({ ...customer, cpf: value })
                  }
                  InputLabelProps={{ required: true }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin="dense"
                  name="cc-rg"
                  label="RG com órgão expedidor"
                  type="text"
                  value={customer.rg || ''}
                  placeholder="00.000.000-0 XXX/UF"
                  onChange={(e) =>
                    setCustomer({ ...customer, rg: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin="dense"
                  label="Data de nascimento"
                  type="date"
                  value={customer.birthday || ''}
                  onChange={(e) =>
                    setCustomer({ ...customer, birthday: e.target.value })
                  }
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl margin="dense" fullWidth>
                  <InputLabel id="rs-marital-status-select-label" required>
                    Estado Civil
                  </InputLabel>
                  <Select
                    name="cc-marital-status"
                    labelId="rs-marital-status-select-label"
                    value={customer.maritalStatus || ''}
                    onChange={(e) =>
                      setCustomer({
                        ...customer,
                        maritalStatus: e.target.value,
                      })
                    }
                  >
                    <MenuItem value="single">Solteiro(a)</MenuItem>
                    <MenuItem value="married">Casado(a)</MenuItem>
                    <MenuItem value="separate">Separado(a)</MenuItem>
                    <MenuItem value="divorced">Divorciado(a)</MenuItem>
                    <MenuItem value="widowed">Viúvo(a)</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin="dense"
                  name="cc-occupation"
                  label="Profissão"
                  type="text"
                  value={customer.occupation || ''}
                  onChange={(e) =>
                    setCustomer({ ...customer, occupation: e.target.value })
                  }
                  InputLabelProps={{ required: true }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <PhoneTextField
                  margin="dense"
                  name="cc-phone"
                  label="Telefone 1"
                  type="text"
                  value={customer.phone || ''}
                  onChange={(_, value) =>
                    setCustomer({ ...customer, phone: value })
                  }
                  InputLabelProps={{ required: true }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <PhoneTextField
                  margin="dense"
                  name="cc-phone2"
                  label="Telefone 2"
                  value={customer.phone2 || ''}
                  onChange={(_, value) =>
                    setCustomer({ ...customer, phone2: value })
                  }
                  type="text"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <EmailTextField
                  margin="dense"
                  name="cc-email"
                  label="E-mail"
                  value={customer.email || ''}
                  onChange={(e) =>
                    setCustomer({ ...customer, email: e.target.value })
                  }
                  fullWidth
                />
              </Grid>
            </Grid>
          )}

          {step === 1 && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <PostalCodeTextField
                  margin="dense"
                  name="cc-postalcode"
                  label="CEP"
                  type="text"
                  value={customer.postalCode || ''}
                  onChange={(_, value) =>
                    setCustomer({ ...customer, postalCode: value })
                  }
                  onBlur={() => handleAddressSearch()}
                  fullWidth
                  autoFocus
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  margin="dense"
                  name="cc-address"
                  label="Logradouro"
                  type="text"
                  value={customer.address || ''}
                  onChange={(e) =>
                    setCustomer({ ...customer, address: e.target.value })
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  margin="dense"
                  name="cc-addressComplement"
                  label="Complemento"
                  type="text"
                  value={customer.addressComplement || ''}
                  onChange={(e) =>
                    setCustomer({
                      ...customer,
                      addressComplement: e.target.value,
                    })
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  margin="dense"
                  name="cc-addressNumber"
                  label="Número"
                  type="text"
                  value={customer.addressNumber || ''}
                  onChange={(e) =>
                    setCustomer({
                      ...customer,
                      addressNumber: e.target.value,
                    })
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  margin="dense"
                  name="cc-district"
                  label="Bairro"
                  type="text"
                  value={customer.district || ''}
                  onChange={(e) =>
                    setCustomer({ ...customer, district: e.target.value })
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  margin="dense"
                  name="cc-city"
                  label="Cidade"
                  type="text"
                  value={customer.city || ''}
                  onChange={(e) =>
                    setCustomer({ ...customer, city: e.target.value })
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl margin="dense" fullWidth>
                  <InputLabel id="rs-state-select-label">Estado</InputLabel>
                  <Select
                    name="cc-state"
                    labelId="rs-state-select-label"
                    value={customer.state || ''}
                    onChange={(e) =>
                      setCustomer({ ...customer, state: e.target.value })
                    }
                  >
                    {constants.BRASIL_STATES.map((state) => (
                      <MenuItem key={state.value} value={state.value}>
                        {state.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          )}
        </Box>
        <DialogActions>
          <Button
            variant="contained"
            name="cc-submit"
            color="primary"
            type="submit"
          >
            Cadastrar
          </Button>
        </DialogActions>
      </form>
    </BaseDialog>
  );
}

CreateCustomerDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpdate: PropTypes.func,
};

CreateCustomerDialog.defaultProps = {
  onUpdate: () => {},
};
export default CreateCustomerDialog;
