import handlerError from 'utils/handleError';
import updateReservationQuery from 'queries/updateReservationQuery';
import { useCallback } from 'react';
import api from 'services/api';
import AlertModule from 'modules/AlertModule';
import { PAYMENT_METHODS } from '@constants/reservations/paymentMethods';
import getEditReservationErrors from '../utils/getEditReservationErrors';

export default function useEditReservation({
  reservation,
  installmentsDates,
  onClose,
  onTabChange,
}) {
  const { firstMonthlyInstallmentFulldate, firstAnnuallyInstallmentFulldate } =
    installmentsDates;

  const editReservation = useCallback(async () => {
    const isPending = reservation.status === 'pending';
    const hasStatusChanged = reservation.status !== reservation.initialStatus;
    const expired = Date.parse(reservation.expiration) <= Date.now();
    const isPaymentCash = reservation.paymentMethod === PAYMENT_METHODS.CASH;

    if (isPending && hasStatusChanged && reservation.expiration && expired) {
      AlertModule.error(
        'A data de expiração deve ser maior que o horário atual.'
      );
      onTabChange(0);

      return;
    }

    AlertModule.wait();
    const expiration = hasStatusChanged ? null : reservation.expiration;

    const variables = {
      id: reservation._id,
      status: reservation.status,
      expiration,
      customerId: reservation.customer?._id || null,
      realEstateId: reservation.realEstate?._id || null,
      realEstateAgentId: reservation.realEstateAgent?._id || null,
      paymentMethod: reservation.paymentMethod,
      entrance: isPaymentCash ? 0 : reservation.entrance,
      installments: isPaymentCash ? 1 : Number(reservation.installments),
      observation: reservation.observation,
      discount: reservation.discount,
      firstMonthlyInstallmentDate: ['monthly', 'annually'].includes(
        reservation.paymentMethod
      )
        ? firstMonthlyInstallmentFulldate
        : null,
      firstAnnuallyInstallmentDate:
        reservation.paymentMethod === 'annually'
          ? firstAnnuallyInstallmentFulldate
          : null,
    };

    await api
      .call(updateReservationQuery, variables)
      .then(() => {
        AlertModule.success('Reserva atualizada com sucesso!');
        onClose();
      })
      .catch((error) => {
        handlerError(getEditReservationErrors(onTabChange), error.message);
      });
  }, [
    firstAnnuallyInstallmentFulldate,
    firstMonthlyInstallmentFulldate,
    onTabChange,
    onClose,
    reservation,
  ]);

  return editReservation;
}
