const getSalesContractQuery = `
    query GetSalesContract (
      $projectId: ID! 
      $numberId: Int!
    ) {
        getSalesContract (
          projectId:$projectId
          numberId: $numberId
        ) {
            _id
            projectId
            numberId
            reservationId
            property {
                _id
                unity
            }
            customer {
              _id
              projectId
              name
              cpf
              rg
              maritalStatus
              occupation
              postalCode
              address
              addressNumber
              addressComplement
              district
              city
              state
              email
              phone
              phone2
              createdAt
              active
            }
            status
            price
            entrance
            nextAdjustment
            contractTemplateId
            createdAt
        }
    }
`;

export default getSalesContractQuery;
