import { TableCell, TableRow } from '@material-ui/core';
import React from 'react';
import HeaderCheckbox from './HeaderCheckbox';
import useStyles from './styles';

export default function HeaderTableRow({
  canSelect,
  areAllSelected,
  hasSomethingSelected,
  toggleSelectAll,
  selectAll,
  deselectAll,
  columns,
}) {
  const classes = useStyles({ hasSomethingSelected });

  return (
    <TableRow className={classes.row}>
      {canSelect ? (
        <TableCell width="1px" align="left" variant="head">
          <HeaderCheckbox
            areAllSelected={areAllSelected}
            hasSomethingSelected={hasSomethingSelected}
            toggleSelectAll={toggleSelectAll}
            selectAll={selectAll}
            deselectAll={deselectAll}
          />
        </TableCell>
      ) : null}

      {columns.map((column) => (
        <TableCell
          key={column.field}
          align={column.align || 'left'}
          variant="head"
          width={column.fit ? '1px' : 'auto'}
        >
          {column.title}
        </TableCell>
      ))}
    </TableRow>
  );
}
