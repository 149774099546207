import { Box, Grid, Typography, useTheme } from '@material-ui/core';
import React from 'react';

export default function DescriptionData({ observation }) {
  const theme = useTheme();
  if (!observation) return null;
  return (
    <Grid item xs={6}>
      <Box marginTop={5}>
        <Typography
          style={{ fontWeight: '600', color: theme.palette.secondary.dark }}
        >
          Descrição
        </Typography>
        <Box marginTop={1} />
        <Box>
          <Typography variant="body2">{observation}</Typography>
        </Box>
      </Box>
    </Grid>
  );
}
