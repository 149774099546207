import React from 'react';
import PropTypes from 'prop-types';

import AlertModule from 'modules/AlertModule';
import { useReturnFilesListContext } from 'providers/ListProviders/ReturnFilesListProvider';

import useQuery from 'hooks/useQuery';
import getReturnFileQuery from 'queries/getReturnFileQuery';
import BaseList from '../BaseList';
import useColumns from './hooks/useColumns';

export default function ReturnFilesList({ onDetails }) {
  const { returnFiles, variables, pages, setPage } =
    useReturnFilesListContext();

  const [executeGetReturnFile] = useQuery(getReturnFileQuery);

  const columns = useColumns();

  const onRowClick = (rowData) => {
    executeGetReturnFile({ id: rowData._id })
      .then(({ getReturnFile: data }) => {
        onDetails(data);
      })
      .catch(
        AlertModule.onError(
          'Não foi possível obter as informações do arquivo de retorno.'
        )
      );
  };

  return (
    <BaseList
      columns={columns}
      currentPage={variables.page}
      pages={pages}
      onPagination={setPage}
      data={returnFiles}
      onRowClick={onRowClick}
    />
  );
}

ReturnFilesList.propTypes = {
  onDetails: PropTypes.func.isRequired,
};
