import { useEffect, useState } from 'react';
import WebSocketController from 'utils/WebSocketController';

const socket = new WebSocketController();

export default function useWebHooks(onUpdate) {
  const [isCustomerCreated, setisCustomerCreated] = useState(false);
  const [isCustomerUpdated, setisCustomerUpdated] = useState(false);

  useEffect(() => {
    socket.onCustomerCreated(() => {
      setisCustomerCreated(true);
    });

    socket.onCustomerUpdated(() => {
      setisCustomerUpdated(true);
    });

    return () => socket.destroy();
  }, []);
  useEffect(() => {
    if (isCustomerCreated || isCustomerUpdated) {
      onUpdate();
      setisCustomerCreated(false);
      setisCustomerUpdated(false);
    }
  }, [isCustomerCreated, isCustomerUpdated]);
}
