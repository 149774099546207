import { useCallback, useEffect, useRef, useState } from 'react';

import useQuery from 'hooks/useQuery';
import AlertModule from 'modules/AlertModule';
import GET_REAL_ESTATE_AGENTS_QUERY from 'queries/getRealEstateAgentsQuery';

export default function useGetRealEstateAgents(variables, search) {
  const [pages, setPages] = useState(1);
  const [realEstateAgents, setRealEstateAgents] = useState([]);
  const [executeGetRealEstateAgents, isLoading] = useQuery(
    GET_REAL_ESTATE_AGENTS_QUERY
  );

  const isFirstRender = useRef(true);

  const onResolve = ({ getRealEstateAgents: data }) => {
    setPages(data.pages);
    setRealEstateAgents(data.realEstateAgents);
  };

  const onReject = AlertModule.onError(
    'Não foi possível carregar a lista de corretores.'
  );

  const createGetter = (executeQuery) => () =>
    executeQuery({ ...variables, search })
      .then(onResolve)
      .catch(onReject);

  const getRealEstateAgentsBySearch = useCallback(
    createGetter(executeGetRealEstateAgents.debounced),
    [(variables, search)]
  );

  const getRealEstateAgents = useCallback(
    createGetter(executeGetRealEstateAgents),
    [variables, search]
  );

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    getRealEstateAgentsBySearch();
  }, [search]);

  useEffect(() => {
    getRealEstateAgents();
  }, [variables]);

  return { realEstateAgents, pages, isLoading };
}
