import React from 'react';
import { Grid, Typography, useTheme } from '@material-ui/core';

import Icon from 'components/Icon';
import ICON_TYPES from '@constants/iconsPaths';

import formatPrice from 'utils/formatPrice';
import formatDate from 'utils/formatDate';
import { PAYMENT_METHODS } from '@constants/reservations/paymentMethods';
import FieldSet from 'components/FieldSet';
import formatCreci from './utils/formatCreci';
import getPaymentMethodLabel from './utils/getPaymentMethodLabel';

export default function PaymentInformation({
  tabIndex,
  reservation,
  monthlyInstallment,
  annuallyInstallment,
}) {
  const theme = useTheme();

  if (tabIndex !== 1) return null;
  return (
    <FieldSet label="Informações do pagamento">
      <Grid container spacing={3}>
        <Grid item xs="auto">
          <Icon src={ICON_TYPES.DETAILS.realEstateAgent} size={32} />
        </Grid>
        <Grid item xs style={{ alignSelf: 'center' }}>
          <Typography
            variant="body2"
            style={{ color: theme.palette.grey[200] }}
          >
            {getPaymentMethodLabel(reservation.paymentMethod)}
          </Typography>

          {reservation.paymentMethod === PAYMENT_METHODS.CASH ? (
            <Typography
              variant="body2"
              style={{ color: theme.palette.grey[300] }}
            >
              {'Valor: '}
              <Typography
                component="span"
                variant="body2"
                style={{ color: theme.palette.grey[400] }}
              >
                {formatPrice(
                  reservation.price - reservation.price * reservation.discount
                )}
              </Typography>
            </Typography>
          ) : (
            <Typography
              variant="body2"
              style={{ color: theme.palette.grey[300] }}
            >
              {'Entrada: '}
              <Typography
                component="span"
                variant="body2"
                style={{ color: theme.palette.grey[400] }}
              >
                {formatPrice(reservation.entrance)}
              </Typography>
            </Typography>
          )}

          {['annually', 'monthly'].includes(reservation.paymentMethod) && (
            <>
              <Typography
                variant="body2"
                style={{ color: theme.palette.grey[300] }}
              >
                {'Parcela mensal: '}
                <Typography
                  component="span"
                  variant="body2"
                  style={{ color: theme.palette.grey[400] }}
                >
                  {monthlyInstallment.formattedValue}
                </Typography>
              </Typography>

              <Typography
                variant="body2"
                style={{ color: theme.palette.grey[400] }}
              >
                {'1ª prestação mensal em '}
                {formatDate(
                  reservation.firstMonthlyInstallmentDate,
                  'longDate'
                )}
              </Typography>
            </>
          )}

          {reservation.paymentMethod === 'annually' && (
            <>
              <Typography
                variant="body2"
                style={{ color: theme.palette.grey[300] }}
              >
                {'Parcela anual: '}
                <Typography
                  component="span"
                  variant="body2"
                  style={{ color: theme.palette.grey[400] }}
                >
                  {annuallyInstallment.formattedValue}
                </Typography>
              </Typography>

              <Typography
                variant="body2"
                style={{ color: theme.palette.grey[400] }}
              >
                {'1ª prestação anual em '}
                {formatDate(
                  reservation.firstAnnuallyInstallmentDate,
                  'longDate'
                )}
              </Typography>
            </>
          )}

          <Typography
            variant="body2"
            style={{ color: theme.palette.grey[300] }}
          >
            {formatCreci(reservation.realEstate?.creci)}
          </Typography>
        </Grid>
      </Grid>
    </FieldSet>
  );
}
