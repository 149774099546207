import { Box, Button, Tab, Tabs } from '@material-ui/core';
import React from 'react';
import AddressTab from './AddressTab';
import GeneralTab from './GeneralTab';

export default function Form({
  realEstate,
  setRealEstate,
  submit,
  step,
  setStep,
  action,
}) {
  const handleSubmit = (e) => {
    e.preventDefault();
    submit();
  };

  if (!realEstate) return null;
  const buttonLabel = action === 'edit' ? 'Salvar' : 'Cadastrar';
  return (
    <form onSubmit={handleSubmit}>
      <Box mb={5}>
        <Tabs
          variant="fullWidth"
          value={step}
          onChange={(_, value) => setStep(value)}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="1. Geral" style={{ marginRight: 24 }} />
          <Tab label="2. Endereço" />
        </Tabs>
      </Box>

      <Box hidden={step !== 0}>
        <GeneralTab
          realEstate={realEstate}
          setRealEstate={setRealEstate}
          action={action}
        />
      </Box>

      <Box hidden={step !== 1}>
        <AddressTab realEstate={realEstate} setRealEstate={setRealEstate} />
      </Box>

      <Box textAlign="right" mt={5}>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          style={{ width: 208 }}
        >
          {buttonLabel}
        </Button>
      </Box>
    </form>
  );
}
