import optionalStringQueryField from "./utils/optionalStringQueryField";

export default function recoverPasswordQuery({ username, environment, projectSlug }) {
  return `
    mutation {
      recoverPassword (
        username: "${String(username).toLowerCase()}"
        environment: "${environment}"
        ${optionalStringQueryField('projectSlug', projectSlug)}      
      )
    }
  `
}