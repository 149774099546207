import React from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import PropTypes from 'prop-types';
import useStyles from './styles';

export default function HeaderSection({ value }) {
  const classes = useStyles();

  return (
    <ListItem className={classes.section}>
      <ListItemIcon>{value?.icon}</ListItemIcon>
      <ListItemText primary={value.label} />
    </ListItem>
  );
}

HeaderSection.propTypes = {
  value: PropTypes.shape({
    href: PropTypes.string,
    label: PropTypes.string,
    icon: PropTypes.node,
  }).isRequired,
};
