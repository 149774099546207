import React from 'react';

function SortIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      fill="none"
      viewBox="0 0 16 17"
    >
      <path
        stroke="#323232"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.313"
        d="M11 6L8 3 5 6M5 10l3 3 3-3"
      />
    </svg>
  );
}

export default SortIcon;
