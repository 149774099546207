import InstallmentCalculatorModule from 'modules/InstallmentCalculatorModule';
import { PAYMENT_METHODS } from '@constants/reservations/paymentMethods';
import formatDate from './formatDate';
import formatPrice from './formatPrice';

export default (reservation) => {
  if (!reservation) return null;
  const {
    paymentMethod,
    price,
    entrance,
    annualQuota,
    installments,
    firstMonthlyInstallmentDate,
  } = reservation;

  const installmentCalculator = new InstallmentCalculatorModule({
    paymentMethod,
    totalPrice: price,
    entrance,
    annualQuota,
  });

  return {
    ...reservation,
    monthlyInstallment:
      [PAYMENT_METHODS.MONTHLY, PAYMENT_METHODS.ANNUALLY].includes(
        paymentMethod
      ) && installmentCalculator.calculateMonthlyInstallment(installments),

    annuallyInstallment:
      paymentMethod === PAYMENT_METHODS.ANNUALLY &&
      installmentCalculator.calculateAnnuallyInstallment(installments),

    localeString: {
      entrance: formatPrice(entrance),
      firstMonthlyInstallmentDate: formatDate(
        firstMonthlyInstallmentDate,
        'longDate'
      ),
    },
  };
};
