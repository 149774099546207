import React from 'react';
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  Select,
  useTheme,
} from '@material-ui/core';

export default function Picker({
  label,
  value,
  onChange,
  disabled,
  children,
  required,
  fullWidth,
  native,
}) {
  const theme = useTheme();
  const size = fullWidth ? 12 : 6;
  return (
    <Grid item md={size} xs={size}>
      <Box mt={0}>
        <FormControl fullWidth disabled={disabled}>
          <InputLabel
            style={{ color: theme.palette.secondary.main }}
            shrink
            required={required}
          >
            {label}
          </InputLabel>
          <Select native={native} value={value} onChange={onChange}>
            {children}
          </Select>
        </FormControl>
      </Box>
    </Grid>
  );
}
