import React, { useCallback, useMemo } from 'react';
import { Box, ButtonBase } from '@material-ui/core';

export default function CalendarBody({
  currentDate,
  startDate,
  endDate,
  onSelect,
}) {
  const isDaySelected = useCallback(
    (date) => {
      const startDateCheck = startDate
        ? date.getTime() === startDate.getTime()
        : false;

      const endDateCheck = endDate
        ? date.getTime() === endDate.getTime()
        : false;

      return startDateCheck !== endDateCheck;
    },
    [startDate, endDate]
  );

  const isDayBetweenDates = useCallback(
    (date) => {
      if (!startDate || !endDate) return false;

      const startDateCheck = startDate.getTime() <= date.getTime();
      const endDateCheck = endDate.getTime() >= date.getTime();

      return startDateCheck && endDateCheck;
    },
    [startDate, endDate]
  );

  const preDays = useMemo(() => {
    if (!currentDate) return null;

    const components = [];

    let date = new Date(currentDate.getTime());
    date.setDate(1);

    const firstDayOfWeek = date.getDay();

    for (let i = 0; i < firstDayOfWeek; i += 1) {
      const dayOffset = firstDayOfWeek - i;

      date = new Date(currentDate.getTime());
      date.setDate(date.getDate() - dayOffset);
      date.setHours(0, 0, 0, 0);

      let boxClassName = 'another-month ';

      if (isDayBetweenDates(date)) {
        boxClassName += 'marked ';
        if (date.getTime() === startDate?.getTime()) boxClassName += 'first';
        if (date.getTime() === endDate?.getTime()) boxClassName += 'last';
      }

      const buttonClassName = isDaySelected(date) ? 'selected' : '';

      components.push({
        id: date.getTime(),
        boxClassName,
        buttonClassName,
        date,
      });
    }

    return components;
  }, [currentDate, startDate, endDate, isDaySelected, isDayBetweenDates]);

  const days = useMemo(() => {
    if (!currentDate) return null;

    const components = [];

    let date = new Date(currentDate.getTime());
    date.setMonth(date.getMonth() + 1);
    date.setDate(0);

    const countDays = date.getDate();

    for (let i = 0; i < countDays; i += 1) {
      date = new Date(currentDate.getFullYear(), currentDate.getMonth(), i + 1);
      date.setHours(0, 0, 0, 0);

      let boxClassName = '';

      if (isDayBetweenDates(date)) {
        boxClassName += 'marked ';
        if (date.getTime() === startDate?.getTime()) boxClassName += ' first';
        if (date.getTime() === endDate?.getTime()) boxClassName += ' last';
      }

      const buttonClassName = isDaySelected(date) ? 'selected' : '';

      components.push({
        id: date.getTime(),
        boxClassName,
        buttonClassName,
        date,
      });
    }

    return components;
  }, [currentDate, startDate, endDate, isDaySelected, isDayBetweenDates]);

  const posDays = useMemo(() => {
    if (!currentDate) return null;

    const components = [];

    let date = new Date(currentDate.getTime());
    const firstDayOfWeek = date.getDay();

    date.setMonth(date.getMonth() + 1);
    date.setDate(0);

    const countDays = date.getDate();

    const countComponents = firstDayOfWeek + countDays;
    const countNextMonthDays = 42 - countComponents;

    for (let i = 0; i < countNextMonthDays; i += 1) {
      const dayOffset = i + 1;

      date = new Date(currentDate.getTime());
      date.setMonth(date.getMonth() + 1);
      date.setDate(date.getDate() + dayOffset - 1);
      date.setHours(0, 0, 0, 0);

      let boxClassName = 'another-month ';

      if (isDayBetweenDates(date)) {
        boxClassName += 'marked ';
        if (date.getTime() === startDate?.getTime()) boxClassName += 'first';
        if (date.getTime() === endDate?.getTime()) boxClassName += 'last';
      }

      const buttonClassName = isDaySelected(date) ? 'selected' : '';

      components.push({
        id: date.getTime(),
        boxClassName,
        buttonClassName,
        date,
      });
    }

    return components;
  }, [currentDate, startDate, endDate, isDaySelected, isDayBetweenDates]);

  if (!currentDate) return null;

  return (
    <>
      <Box className="day-of-week">Dom</Box>
      <Box className="day-of-week">Seg</Box>
      <Box className="day-of-week">Ter</Box>
      <Box className="day-of-week">Qua</Box>
      <Box className="day-of-week">Qui</Box>
      <Box className="day-of-week">Sex</Box>
      <Box className="day-of-week">Sáb</Box>

      {preDays.map((day) => (
        <Box className={day.boxClassName} key={day.id}>
          <ButtonBase
            className={day.buttonClassName}
            onClick={() => onSelect(day.date)}
          >
            {day.date.getDate()}
          </ButtonBase>
        </Box>
      ))}

      {days.map((day) => (
        <Box className={day.boxClassName} key={day.id}>
          <ButtonBase
            className={day.buttonClassName}
            onClick={() => onSelect(day.date)}
          >
            {day.date.getDate()}
          </ButtonBase>
        </Box>
      ))}

      {posDays.map((day) => (
        <Box className={day.boxClassName} key={day.id}>
          <ButtonBase
            className={day.buttonClassName}
            onClick={() => onSelect(day.date)}
          >
            {day.date.getDate()}
          </ButtonBase>
        </Box>
      ))}
    </>
  );
}
