import React from 'react';
import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import { useRealEstateAgentsListContext } from 'providers/ListProviders/RealEstateAgentsListProvider';

import Loading from 'components/Loading';
import TeamZeroStateImage from 'assets/images/ZeroStates/TeamZeroStateImage';

export default function RealEstateAgentsListZeroState({ children }) {
  const { realEstateAgents, isLoading, search } =
    useRealEstateAgentsListContext();

  const isFilterSet = search;
  const hasData = Boolean(realEstateAgents.length);
  if (isLoading) {
    return <Loading height={100} />;
  }
  if (hasData > 0) {
    return children;
  }

  const mainMessage =
    isFilterSet && !hasData
      ? 'Nenhum corretor encontrado. Pesquise novamente'
      : 'Você ainda não cadastrou nenhum corretor';

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      mt={15}
    >
      <Box>
        <TeamZeroStateImage />
      </Box>
      <Box color="#495058" mt={5} width="40%">
        <Typography align="center" variant="h3">
          {mainMessage}
        </Typography>
      </Box>
    </Box>
  );
}

RealEstateAgentsListZeroState.propTypes = {
  children: PropTypes.node.isRequired,
};

RealEstateAgentsListZeroState.defaultProps = {};
