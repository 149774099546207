export default `
    query GetReservations(
      $projectId: ID!
      $page: Int
      $itemsPerPage: Int
      $search: String
      $sortOptions:ReservationSort
      $filterOptions:ReservationFilter
    ){
      getReservations(
        projectId: $projectId
        page: $page
        itemsPerPage: $itemsPerPage
        search:$search
        sortOptions:$sortOptions
        filterOptions:$filterOptions
      ) {
        reservations {
          _id
          numberId
          projectId
          property {
            _id
            unity
            description
            price
            terrainDimension
            status
          }
          customer {
            _id
            name
            cpf
            rg
          }
          realEstate {
            _id
            name
            cnpj
            creci
          }
          realEstateAgent {
            _id
            name
            cpf
            rg
            creci
          }
          status
          expiration
          observation
          isContractCreated
          updatedAt
          createdAt
          active
          discount
        }
        page
        pages
      }
    }
  `;
