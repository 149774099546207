export default function validatePassword(value) {
  const whitespaceRegex = /\s/
  const uppercaseRegex = /[A-Z]/
  const lowercaseRegex = /[a-z]/
  const numericRegex = /[0-9]/
  const specialCharRegex = /[^a-zA-Z0-9\s]/

  return {
    length: value && String(value).length >= 8,
    notWhitespace: !whitespaceRegex.test(value),
    uppercase: uppercaseRegex.test(value),
    lowercase: lowercaseRegex.test(value),
    numeric: numericRegex.test(value),
    specialChar: specialCharRegex.test(value),
  }
}