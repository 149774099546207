/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useState, useContext } from 'react';

import { ProjectContext } from 'providers/ProjectProvider';

import useGetBankAccounts from './hooks/useGetBankAccounts';

const BankAccountsListContext = createContext();

export default function BankAccountsListProvider({ children }) {
  const { project } = useContext(ProjectContext);
  const [variables, setVariables] = useState({
    projectId: project._id,
    changedAt: Date.now(),
  });
  const { bankAccounts, isLoading } = useGetBankAccounts(variables);

  const updateList = () =>
    setVariables({ ...variables, changedAt: Date.now() });

  return (
    <BankAccountsListContext.Provider
      value={{
        bankAccounts,
        isLoading,
        variables,
        updateList,
      }}
    >
      {children}
    </BankAccountsListContext.Provider>
  );
}

export function useBankAccountsListContext() {
  return useContext(BankAccountsListContext);
}
