import validateCPF from 'utils/validateCpf';
import validateEmail from 'utils/validateEmail';
import validatePhone from 'utils/validatePhone';
import validatePostalCode from 'utils/validatePostalCode';

const validateInputs = (customer) => {
  const checklist = [
    {
      step: 0,
      message: 'O campo Nome é obrigatório.',
      validate: () => customer.name,
    },
    {
      step: 0,
      message: 'O campo CPF é obrigatório.',
      validate: () => customer.cpf,
    },
    {
      step: 0,
      message: 'CPF inválido.',
      validate: () => validateCPF(customer.cpf),
    },
    {
      step: 0,
      message: 'O campo Estado civil é obrigatório.',
      validate: () => customer.maritalStatus,
    },
    {
      step: 0,
      message: 'O campo Profissão é obrigatório.',
      validate: () => customer.occupation,
    },
    {
      step: 0,
      message: 'O campo Telefone é obrigatório.',
      validate: () => customer.phone,
    },
    {
      step: 0,
      message: 'Telefone inválido.',
      validate: () => validatePhone(customer.phone),
    },
    {
      step: 0,
      message: 'E-mail inválido.',
      validate: () => !customer.email || validateEmail(customer.email),
    },
    {
      step: 0,
      message: 'Telefone 2 inválido.',
      validate: () => !customer.phone2 || validatePhone(customer.phone2),
    },
    {
      step: 1,
      message: 'CEP inválido.',
      validate: () =>
        !customer.postalCode || validatePostalCode(customer.postalCode),
    },
  ];

  const validate = checklist.reduce((prev, item) => {
    if (prev) return prev;
    if (!item.validate()) return item;
    return null;
  }, null);

  return validate ? { success: false, ...validate } : { success: true };
};

export default validateInputs;
