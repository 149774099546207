export default `
    mutation createRealEstate(
        $projectId : ID!
        $name: String!
        $cnpj: String!
        $creci: String!
        $reservationLimit: Int!
        $active: Boolean!
        $username: String
        $phone: String
        $phone2: String
        $email: String
        $postalCode: String
        $address: String
        $addressNumber: String
        $addressComplement: String
        $district: String
        $city: String
        $state: String
    ) {
        createRealEstate(
          projectId: $projectId
          data: {
            name: $name
            cnpj: $cnpj
            creci: $creci
            active: $active
            reservationLimit: $reservationLimit
            username: $username
            phone: $phone
            phone2: $phone2
            email: $email
            postalCode: $postalCode
            address: $address
            addressNumber: $addressNumber
            addressComplement: $addressComplement
            district: $district
            city: $city
            state: $state
          } 
        ) {
          _id
          invited
        }
      }
  `;
