import React from 'react';
import { Box, Typography } from '@material-ui/core';

import Loading from 'components/Loading';
import CustomerZeroStateImage from 'assets/images/ZeroStates/CustomerZeroStateImage';
import { useCustomersListContext } from 'providers/ListProviders/CustomersListProvider';

function CustomersZeroState({ children }) {
  const { customers, isLoading, search } = useCustomersListContext();

  const hasData = Boolean(customers.length);
  const isSearchSet = search && !hasData;

  const mainMessage = isSearchSet
    ? 'Nenhum cliente encontrado. Tente uma nova busca'
    : 'Nenhum cliente cadastrado.';

  const subMessage = isSearchSet
    ? ''
    : 'Para acessar esse recurso, cadastre o primeiro cliente';
  const mainMessageWidth = {
    width: isSearchSet ? '60%' : '90%',
  };

  if (isLoading) {
    return <Loading height={100} />;
  }
  if (hasData) {
    return children;
  }
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="100%"
      flexDirection="column"
      mt={10}
    >
      <Box>
        <CustomerZeroStateImage />
      </Box>
      <Box justifyContent="center" alignItems="center" display="flex">
        <Box color="#495058" width={mainMessageWidth.width}>
          <Typography align="center" variant="h3">
            {mainMessage}
          </Typography>
        </Box>
      </Box>

      <Box
        color="#767C81"
        justifyContent="center"
        alignItems="center"
        display="flex"
        mt={2}
      >
        <Typography align="center">{subMessage}</Typography>
      </Box>
    </Box>
  );
}

export default CustomersZeroState;
