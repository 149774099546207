import React, { useState } from 'react';

import BankAccountsListProvider from 'providers/ListProviders/BankAccountsListProvider';

import BankAccountList from 'components/Lists/BankAccountList';
import CreateBankAccountDialog from 'components/Dialogs/BankAccountDialogs/Create';
import EditBankAccountDialog from 'components/Dialogs/BankAccountDialogs/Edit';
import BankInformationZeroState from 'components/ZeroState/BankInformationZeroState';

export default function BankInformation() {
  const [showCreateBankAccountDialog, setShowCreateBankAccountDialog] =
    useState(false);
  const [showEditBankAccountDialog, setShowEditBankAccountDialog] =
    useState(null);
  const [bankAccount, setBankAccount] = useState(null);

  const openCreateBankAccountDialog = () =>
    setShowCreateBankAccountDialog(true);
  const closeCreateBankAccountDialog = () =>
    setShowCreateBankAccountDialog(false);

  const openEditBankAccountDialog = () => setShowEditBankAccountDialog(true);
  const closeEditBankAccountDialog = () => setShowEditBankAccountDialog(false);

  return (
    <BankAccountsListProvider>
      <BankInformationZeroState onCreate={openCreateBankAccountDialog}>
        <BankAccountList
          onEdit={(account) => {
            setBankAccount(account);
            openEditBankAccountDialog();
          }}
        />
      </BankInformationZeroState>

      <CreateBankAccountDialog
        open={showCreateBankAccountDialog}
        onClose={closeCreateBankAccountDialog}
      />

      <EditBankAccountDialog
        open={showEditBankAccountDialog}
        bankAccount={bankAccount}
        onClose={closeEditBankAccountDialog}
      />
    </BankAccountsListProvider>
  );
}
