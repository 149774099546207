import React, { useContext, useMemo } from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ProjectContext } from 'providers/ProjectProvider';
import { useReservationsListContext } from 'providers/ListProviders/ReservationsListProvider';

import Loading from 'components/Loading';
import ReservationZeroStateImage from 'assets/images/ZeroStates/ReservationZeroStateImage';

export default function ReservationsListZeroState({ children, isSalesList }) {
  const history = useHistory();
  const { project } = useContext(ProjectContext);
  const { reservations, search, variables, isLoading } =
    useReservationsListContext();

  const hasData = reservations.length;

  const isSearchSet = search && !hasData;
  const isFilterSet = variables.filterOptions.status && !hasData;

  const mainMessage = useMemo(() => {
    if (isSalesList) {
      if (isFilterSet || isSearchSet) {
        return 'Nenhuma reserva foi encontrada';
      }
      return 'Você não possui nenhuma reserva';
    }
    return 'Nenhuma reserva foi encontrada';
  }, [isSalesList, isFilterSet, isSearchSet]);

  const subMessage = useMemo(() => {
    if (isSearchSet || isFilterSet) {
      return '';
    }

    return 'Você ainda não fez nenhuma reserva, comece vendo os imóveis disponíveis';
  }, [isSearchSet, isFilterSet]);

  const reservationsButton =
    !isSearchSet && !isFilterSet ? (
      <Button
        onClick={() => history.push(`/${project.slug}/imoveis`)}
        color="primary"
        variant="contained"
        size="large"
      >
        Ir para imóveis
      </Button>
    ) : (
      ''
    );

  if (isLoading) {
    return <Loading height={100} />;
  }
  if (hasData) {
    return children;
  }

  return (
    <Box width="100%" mt={10}>
      <Box display="flex" alignItems="center" justifyContent="center">
        <ReservationZeroStateImage />
      </Box>
      <Box
        color="#495058"
        justifyContent="center"
        alignItems="center"
        display="flex"
        mt={5}
      >
        <Typography display="block" variant="h3">
          {mainMessage}
        </Typography>
      </Box>
      <Box
        color="#767C81"
        justifyContent="center"
        alignItems="center"
        display="flex"
        mt={2}
      >
        <Box width="35%">
          <Typography align="center">{subMessage}</Typography>
        </Box>
      </Box>
      <Box
        color="#767C81"
        justifyContent="center"
        alignItems="center"
        display="flex"
        mt={5}
      >
        {reservationsButton}
      </Box>
    </Box>
  );
}

ReservationsListZeroState.propTypes = {
  children: PropTypes.node,
  isSalesList: PropTypes.bool,
};

ReservationsListZeroState.defaultProps = {
  children: null,
  isSalesList: false,
};
