import { TextField } from '@material-ui/core'
import React from 'react'

export default function PhoneTextField(props) {
    const handleValue = () => {
        if (!props.value) return ""

        if (props.value.length <= 10) {
            return String(props.value)
                .replace(/([0-9]{0,2})([0-9]{0,4})([0-9]{0,4})/, '($1) $2-$3')
                .replace(/[^0-9]*$/, '')
        } else {
            return String(props.value)
                .replace(/([0-9]{0,2})([0-9]{0,1})([0-9]{0,4})([0-9]{0,4})/, '($1) $2 $3-$4')
                .replace(/[^0-9]*$/, '')
        }
    }

    const handleChange = (e) => {
        const value = e.target.value
        const rawValue = value.replace(/[^0-9]/g, '').slice(0, 11)
        if (props.onChange) props.onChange(e, rawValue)
    }

    return <TextField {...props}
        value={handleValue()}
        onChange={handleChange}
    />
}