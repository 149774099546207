import palette from './palette';

const typography = {
  fontSize: 16,
  fontSizeSmall: 14,
  fontFamily: ['"Circular Std"', 'Roboto', 'sans-serif'].join(','),

  body1: {
    fontSize: 18,
    lineHeight: '27px',
  },

  body2: {
    fontSize: 16,
    lineHeight: '24px',
  },

  title5: {
    fontSize: 24,
    lineHeight: '31px',
    fontWeight: 800,
    color: palette.secondary.dark,
  },
  caption: {
    fontSize: 14,
    lineHeight: '21px',
  },

  caption2: {
    fontSize: 12,
    lineHeight: '17px',
  },

  h6: {
    fontSize: 14,
    lineHeight: '18px',
  },

  h5: {
    fontSize: 20,
    lineHeight: '26px',
  },

  h4: {
    fontSize: 24,
    lineHeight: '31px',
  },

  h3: {
    fontSize: 24,
    lineHeight: '31px',
  },

  h2: {
    fontSize: 28,
    lineHeight: '38px',
  },

  h1: {
    fontSize: 32,
    lineHeight: '38px',
  },
};

export default typography;
