import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core';
import PropTypes from 'prop-types';

import {
  PAYMENT_METHODS,
  PAYMENT_METHODS_LABELS,
} from '@constants/reservations/paymentMethods';

import CurrencyTextField from 'components/TextFields/CurrencyTextField';
import Loading from 'components/Loading';

import formatPrice from 'utils/formatPrice';
import Reservation from 'utils/Reservation';

import CustomerIcon from '../Icons/CustomerIcon';
import PaymentIcon from '../Icons/PaymentIcon';
import PropertyIcon from '../Icons/PropertyIcon';
import InformationBlock from '../InformationBlock';

function Form({ onSubmit, ...props }) {
  const theme = useTheme();

  const [observation, setObservation] = useState('');
  const [financedValue, setFinancedValue] = useState(0);
  const reservation = useMemo(
    () => Reservation(props.reservation),
    [props.reservation]
  );

  const handleSubmit = (e) => {
    e.preventDefault();

    onSubmit({
      reservationId: reservation._id,
      newFinancedValue: financedValue,
      observation,
    });
  };

  useEffect(() => {
    if (reservation) {
      setFinancedValue(reservation.price - reservation.entrance);
    }
  }, [reservation]);

  if (!reservation) return <Loading height={200} />;

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <InformationBlock
            title="Informações do cliente"
            icon={<CustomerIcon />}
          >
            <Typography
              variant="body2"
              style={{ color: theme.palette.grey[200] }}
            >
              {reservation.customer.name}
            </Typography>
          </InformationBlock>
        </Grid>

        <Grid item xs={12} md={6}>
          <InformationBlock
            title="Informações do imóvel"
            icon={<PropertyIcon />}
          >
            <Typography
              variant="body2"
              style={{ color: theme.palette.grey[200] }}
            >
              {reservation.property.unity}
            </Typography>
          </InformationBlock>
        </Grid>

        <Grid item xs={12} md={6}>
          <InformationBlock title="Pagamento" icon={<PaymentIcon />}>
            <Typography
              variant="body2"
              style={{ color: theme.palette.grey[200] }}
            >
              {PAYMENT_METHODS_LABELS[reservation.paymentMethod]}
            </Typography>
            <Typography
              variant="body2"
              style={{ color: theme.palette.grey[200] }}
            >
              {'Entrada: '}
              <Typography
                component="span"
                variant="body2"
                style={{ color: theme.palette.grey[300] }}
              >
                {reservation.localeString.entrance}
              </Typography>
            </Typography>
            {[PAYMENT_METHODS.MONTHLY, PAYMENT_METHODS.ANNUALLY].includes(
              reservation.paymentMethod
            ) ? (
              <Typography
                variant="body2"
                style={{ color: theme.palette.grey[200] }}
              >
                {'Parcela mensal: '}
                <Typography
                  component="span"
                  variant="body2"
                  style={{ color: theme.palette.grey[300] }}
                >
                  {reservation.monthlyInstallment.formattedValue}
                </Typography>
              </Typography>
            ) : null}
            {[PAYMENT_METHODS.MONTHLY, PAYMENT_METHODS.ANNUALLY].includes(
              reservation.paymentMethod
            ) ? (
              <Typography
                variant="body2"
                style={{ color: theme.palette.grey[300] }}
              >
                {'1ª parcela mensal em '}
                <Typography component="span" variant="body2">
                  {reservation.localeString.firstMonthlyInstallmentDate}
                </Typography>
              </Typography>
            ) : null}
          </InformationBlock>
        </Grid>

        <Grid item xs={12}>
          <Typography
            className="fw700"
            style={{ color: theme.palette.secondary.dark }}
          >
            Deseja editar alguma das informações abaixo?
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <CurrencyTextField
            label="Valor da venda (parte financiada)"
            value={financedValue}
            onChange={(_, newValue) => {
              setFinancedValue(newValue);
            }}
            placeholder={formatPrice(financedValue)}
            InputLabelProps={{ shrink: true }}
            fullWidth
            required
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            label="Observações"
            value={observation}
            placeholder="Em branco"
            onChange={(e) => setObservation(e.target.value)}
            InputLabelProps={{ shrink: true }}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <Box textAlign="right">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
            >
              Salvar
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
}

Form.propTypes = {
  reservation: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    entrance: PropTypes.number.isRequired,
    customer: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    property: PropTypes.shape({
      unity: PropTypes.string.isRequired,
    }).isRequired,
    paymentMethod: PropTypes.string.isRequired,
    localeString: PropTypes.shape({
      entrance: PropTypes.string,
      firstMonthlyInstallmentDate: PropTypes.string,
    }),
    monthlyInstallment: PropTypes.shape({
      formattedValue: PropTypes.string,
    }),
  }),
  onSubmit: PropTypes.func.isRequired,
};

Form.defaultProps = {
  reservation: null,
};

export default Form;
