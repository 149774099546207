import React, { useMemo, useState } from 'react';
import { Box, Link, MenuItem } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import useStyles from './styles';
import Menu from './Menu';

export default function Dropdown({ value }) {
  const classes = useStyles();
  const history = useHistory();
  const [showMenu, setShowMenu] = useState(false);

  const menuItens = useMemo(
    () =>
      value.items.map((item, index) => (
        <Box className={classes.menuItemContainer} key={item.id}>
          <MenuItem
            className={classes.menuItem}
            onClick={() => {
              history.push(item.href);
              setShowMenu(false);
            }}
          >
            {item.label}
          </MenuItem>
          <Box
            className={classes.menuItemDivider}
            hidden={index + 1 === value.items.length}
          />
        </Box>
      )),
    [value]
  );

  return (
    <div onMouseLeave={() => setShowMenu(false)} className={classes.dropdown}>
      <Link
        className={classes.link}
        href={value.href}
        onMouseOver={() => setShowMenu(true)}
      >
        {value.label}
      </Link>

      {showMenu && <Menu>{menuItens}</Menu>}
    </div>
  );
}

Dropdown.propTypes = {
  value: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        label: PropTypes.string,
        href: PropTypes.string,
      })
    ),
    href: PropTypes.string,
    label: PropTypes.string,
  }).isRequired,
};
