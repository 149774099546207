import Constants from '@constants';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import PostalCodeTextField from 'components/TextFields/PostalCodeTextField';
import React from 'react';

export default function AddressTab({
  realEstateAgent,
  setRealEstateAgent,
  searchAddress,
}) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <PostalCodeTextField
          margin="dense"
          name="crs-postalcode"
          label="CEP"
          type="text"
          value={realEstateAgent.postalCode || ''}
          onChange={(_, value) =>
            setRealEstateAgent({ ...realEstateAgent, postalCode: value })
          }
          onBlur={() => searchAddress()}
          fullWidth
          autoFocus
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          margin="dense"
          name="crs-address"
          label="Logradouro"
          type="text"
          value={realEstateAgent.address || ''}
          onChange={(e) =>
            setRealEstateAgent({ ...realEstateAgent, address: e.target.value })
          }
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          margin="dense"
          name="crs-addressComplement"
          label="Complemento"
          type="text"
          value={realEstateAgent.addressComplement || ''}
          onChange={(e) =>
            setRealEstateAgent({
              ...realEstateAgent,
              addressComplement: e.target.value,
            })
          }
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          margin="dense"
          name="crs-addressNumber"
          label="Número"
          type="text"
          value={realEstateAgent.addressNumber || ''}
          onChange={(e) =>
            setRealEstateAgent({
              ...realEstateAgent,
              addressNumber: e.target.value,
            })
          }
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          margin="dense"
          name="crs-district"
          label="Bairro"
          type="text"
          value={realEstateAgent.district || ''}
          onChange={(e) =>
            setRealEstateAgent({ ...realEstateAgent, district: e.target.value })
          }
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          margin="dense"
          name="crs-city"
          label="Cidade"
          type="text"
          value={realEstateAgent.city || ''}
          onChange={(e) =>
            setRealEstateAgent({ ...realEstateAgent, city: e.target.value })
          }
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl margin="dense" fullWidth>
          <InputLabel id="rs-state-select-label">Estado</InputLabel>
          <Select
            name="crs-state"
            labelId="rs-state-select-label"
            value={realEstateAgent.state || ''}
            onChange={(e) =>
              setRealEstateAgent({ ...realEstateAgent, state: e.target.value })
            }
          >
            {Constants.BRASIL_STATES.map((state) => (
              <MenuItem key={state.value} value={state.value}>
                {state.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
}
