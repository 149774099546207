const lastThreeMonths = () => {
    const startDate = new Date()
    startDate.setDate(startDate.getDate() - 90)
    startDate.setHours(0, 0, 0, 0)

    const endDate = new Date()
    endDate.setDate(endDate.getDate() - 1)
    endDate.setHours(0, 0, 0, 0)

    return {
        id: 'last-three-months',
        label: 'Últimos 3 meses',
        startDate,
        endDate
    }
}

export default lastThreeMonths