export default (remittance, open) => {
  const title = open ? 'Títulos da remessa' : null;
  const subtitle = open
    ? `${remittance.name} • ${new Date(remittance.createdAt)
        .toLocaleDateString('pt-BR', {
          hour: 'numeric',
          minute: 'numeric',
        })
        .replaceAll('/', '-')
        .replace(' ', ' at ')}`
    : null;

  return [title, subtitle];
};
