import React from 'react';
import { Box, Grid, Typography, useTheme } from '@material-ui/core';

import { PAYMENT_METHODS } from '@constants/reservations/paymentMethods';

import formatPrice from 'utils/formatPrice';
import InformationBlock from 'components/InformationBlock';
import getInstallments from './utils/getInstallments';
import Annualy from './Annualy';
import Monthly from './Monthly';

export default function PaymentData({ reservation }) {
  const theme = useTheme();
  const installments = getInstallments(reservation);
  const isCash = reservation.paymentMethod === PAYMENT_METHODS.CASH;

  return (
    <Grid item md={6}>
      <Box marginTop={5} marginBottom={1}>
        <Typography
          style={{ fontWeight: '600', color: theme.palette.secondary.dark }}
        >
          Dados do pagamento
        </Typography>
      </Box>
      <Grid container spacing={1}>
        {!isCash && (
          <InformationBlock label="Entrada">
            {formatPrice(reservation.entrance)}
          </InformationBlock>
        )}
        <InformationBlock label="Preço">
          {formatPrice(
            reservation.price - reservation.price * reservation.discount
          )}
        </InformationBlock>

        <Monthly
          monthlyInstallment={installments?.monthlyInstallment?.value}
          reservation={reservation}
        />
        <Annualy
          reservation={reservation}
          annuallyInstallment={installments?.annuallyInstallment?.value}
        />
      </Grid>
    </Grid>
  );
}
