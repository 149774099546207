const CREATE_BANK_ACCOUNT_MUTATION = `
  mutation CreateBankAccount (
    $projectId: ID!
    $companyName: String!
    $companyCode: Int!
    $document: String!
    $bankCode: Int!
    $bankName: String!
    $branchNumber: String!
    $branchDigit: String!
    $accountNumber: String!
    $accountDigit: String!
    $postalCode: String!
    $address: String!
    $addressNumber: String
    $addressComplement: String
    $district: String!
    $city: String!
    $state: String!
  ) {
    createBankAccount (
      data: {
        projectId: $projectId
        companyName: $companyName
        companyCode: $companyCode
        document: $document
        bankCode: $bankCode
        bankName: $bankName
        branchNumber: $branchNumber
        branchDigit: $branchDigit
        accountNumber: $accountNumber
        accountDigit: $accountDigit
        postalCode: $postalCode
        address: $address
        addressNumber: $addressNumber
        addressComplement: $addressComplement
        district: $district
        city: $city
        state: $state
      }
    ) {
      _id
    }
  }
`;

export default CREATE_BANK_ACCOUNT_MUTATION;
