export default `
  mutation LoginAs (
    $username: String!
    $projectSlug: String
  ) {
    loginAs (
      username: $username
      projectSlug: $projectSlug
    )
  }
`;
