import React from 'react';

import ListActions from '../components/ListActions';

export default function useColumns(project) {
  const columns = [
    { title: 'Nome', field: 'name', render: (rowData) => rowData.user.name },
    {
      title: 'E-mail',
      field: 'email',
      render: (rowData) => rowData.user.email,
    },

    {
      title: 'Função',
      field: 'type',
      render: (rowData) => {
        const isAdmin = rowData.user._id === project.ownerId;
        return isAdmin ? 'Administrador' : 'Proprietário';
      },
    },

    {
      title: '',
      field: '_actions',
      align: 'center',
      fit: true,
      render: (rowData) => <ListActions rowData={rowData} project={project} />,
    },
  ];

  return columns;
}
