import { Box, Button, Tabs, Tab } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import AlertModule from 'modules/AlertModule';
import WebSocketController from 'utils/WebSocketController';
import { PAYMENT_METHODS } from '@constants/reservations/paymentMethods';
import BaseDialog from 'components/Dialogs/BaseDialog';
import PaymentTab from './components/PaymentTab';
import useGetReservation from './hooks/useGetReservation';
import useEditReservation from './hooks/useEditReservation';
import GeneralTab from './components/GeneralTab';
import useGetInstallmentsDates from './hooks/useGetInstallmentsDates';

const socket = new WebSocketController();

export default function EditReservationDialog({
  open,
  reservationId,
  onClose,
}) {
  const isEditingRef = useRef(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [updatedPropertyId, setUpdatedPropertyId] = useState(null);
  const [updatedReservationId, setUpdatedReservationId] = useState(null);

  const { reservation, setReservation, getReservation, hasChanged, loading } =
    useGetReservation({
      onClose,
    });

  const installmentsDates = useGetInstallmentsDates(reservation);

  const editReservation = useEditReservation({
    reservation,
    installmentsDates,
    onClose,
    onTabChange: (value) => setTabIndex(value),
  });

  const handleClose = () => {
    if (hasChanged) {
      AlertModule.confirmation(
        'Deseja realmente fechar sem salvar as alterações da reserva?'
      ).then((result) => {
        if (result.isConfirmed) {
          onClose();
        }
      });
    } else {
      onClose();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      reservation.paymentMethod !== PAYMENT_METHODS.CASH &&
      !installmentsDates.firstMonthlyInstallmentFulldate
    ) {
      AlertModule.error(
        'Preencha o vencimento da primeira parcela mensal corretamente.'
      );

      setTabIndex(1);
      return;
    }
    if (
      reservation.paymentMethod === PAYMENT_METHODS.ANNUALLY &&
      !installmentsDates.firstAnnuallyInstallmentFulldate
    ) {
      AlertModule.error(
        'Preencha o vencimento da primeira parcela anual corretamente.'
      );

      setTabIndex(1);
      return;
    }

    isEditingRef.current = true;
    editReservation();
  };

  useEffect(() => {
    if (open && reservationId) {
      isEditingRef.current = false;
      getReservation(reservationId);
    } else {
      setTabIndex(0);
    }
  }, [open, getReservation, reservationId]);

  useEffect(() => {
    socket.onPropertyUpdated((property) => {
      if (isEditingRef.current) return;
      setUpdatedPropertyId(property._id);
    });

    socket.onPropertyDeleted((propertyId) => {
      if (isEditingRef.current) return;
      setUpdatedPropertyId(propertyId);
    });

    socket.onReservationUpdated((newReservation) => {
      if (isEditingRef.current) return;
      setUpdatedReservationId(newReservation._id);
    });

    return () => socket.destroy();
  }, []);

  useEffect(() => {
    if (updatedReservationId) {
      if (reservationId && reservation?._id === updatedReservationId) {
        AlertModule.confirmation(
          'Houve mudanças nessa reserva, deseja recarregar as informações?',
          { reverseButtons: true }
        ).then((result) => {
          if (result.isConfirmed) {
            getReservation(reservationId);
          }
        });
      }

      setUpdatedReservationId(null);
      isEditingRef.current = false;
    }
  }, [getReservation, reservation, reservationId, updatedReservationId]);

  useEffect(() => {
    if (updatedPropertyId) {
      if (reservationId && reservation?.propertyId === updatedPropertyId) {
        getReservation(reservationId);
      }

      setUpdatedPropertyId(null);
    }
  }, [getReservation, reservation, reservationId, updatedPropertyId]);

  return (
    <BaseDialog
      open={open}
      title={reservation ? `Reserva #${reservation.numberId}` : 'Reserva'}
      onClose={handleClose}
      maxWidth="md"
      loading={loading}
    >
      {reservation && (
        <form onSubmit={handleSubmit}>
          <Box mb={3}>
            <Tabs
              variant="fullWidth"
              value={tabIndex}
              indicatorColor="primary"
              textColor="primary"
              centered
              onChange={(e, value) => setTabIndex(value)}
            >
              <Tab label="Geral" />
              <Tab label="Pagamento" />
            </Tabs>
          </Box>

          <GeneralTab
            reservation={reservation}
            setReservation={setReservation}
            tabIndex={tabIndex}
          />

          <PaymentTab
            reservation={reservation}
            setReservation={setReservation}
            tabIndex={tabIndex}
            installmentsDates={installmentsDates}
          />

          <Box mt={3} textAlign="right">
            <Button onClick={handleSubmit} variant="contained" color="primary">
              Salvar
            </Button>
          </Box>
        </form>
      )}
    </BaseDialog>
  );
}

EditReservationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  reservationId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

EditReservationDialog.defaultProps = {
  reservationId: null,
};
