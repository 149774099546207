const lastMonth = () => {
    const startDate = new Date()
    startDate.setDate(1)
    startDate.setMonth(startDate.getMonth() - 1)
    startDate.setHours(0, 0, 0, 0)

    const endDate = new Date()
    endDate.setDate(1)
    endDate.setDate(0)
    endDate.setHours(0, 0, 0, 0)

    return {
        id: 'last-month',
        label: 'Mês passado',
        startDate,
        endDate
    }
}

export default lastMonth