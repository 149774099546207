import React from 'react';
import { Box, Tooltip, useTheme } from '@material-ui/core';

import returnFileStatus from '@constants/walletManagement/returnFileStatus';
import ICON_TYPES from '@constants/iconsPaths';

import Icon from 'components/Icon';

import { getReturnFileColors } from './BaseStatusLabel/colors';
import { returnFileStatusTexts } from './BaseStatusLabel/texts';
import BaseStatusLabel from './BaseStatusLabel';

export default function ReturnFileStatusLabel({
  status,
  errorMessage,
  size = 'small',
}) {
  const theme = useTheme();
  const color = getReturnFileColors(theme)[status];
  const showWarning = status === returnFileStatus.FAILED;
  return (
    <Box display="flex" alignItems="center">
      <BaseStatusLabel
        size={size}
        label={returnFileStatusTexts[status]}
        color={color}
      />
      {showWarning && (
        <Tooltip
          title={errorMessage}
          TransitionProps={{
            style: { backgroundColor: theme.palette.grey[200] },
          }}
        >
          <Box display="flex" ml={1}>
            <Icon src={ICON_TYPES.LIST.warning} size={19} />
          </Box>
        </Tooltip>
      )}
    </Box>
  );
}
