import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';
import { Box, Drawer, List } from '@material-ui/core';

import { AuthContext } from 'providers/AuthProvider';
import { ProjectContext } from 'providers/ProjectProvider';
import UserProfileListItem from 'components/UserProfile/buttons/ProfileListItem';

import HeaderLink from './link';
import useLinks from '../useLinks';
import HeaderSection from './section';
import HeaderSectionLink from './sectionLink';

export default function MobileMenu({ show, onClose, onShowUserProfile }) {
  const { project } = useContext(ProjectContext);
  const { auth } = useContext(AuthContext);

  const environment = auth?.environment;

  const links = useLinks(environment, project, true);

  const linkComponents = useMemo(
    () =>
      links.map((link) => {
        if (link.type === 'dropdown') {
          return (
            <Box key={link.id}>
              <HeaderSection value={link} />
              {link.items.map((item) => (
                <HeaderSectionLink
                  key={item.id}
                  value={item}
                  onClose={onClose}
                />
              ))}
            </Box>
          );
        }

        return <HeaderLink key={link.id} value={link} onClose={onClose} />;
      }),
    [links, onClose]
  );

  return (
    <Drawer anchor="right" open={show} onClose={onClose}>
      <Box width={250}>
        <List component="nav">
          {linkComponents}
          <UserProfileListItem onShowUserProfile={onShowUserProfile} />
        </List>
      </Box>
    </Drawer>
  );
}

MobileMenu.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onShowUserProfile: PropTypes.func.isRequired,
};
