import React, { useEffect, useRef, useState } from 'react';
import BaseDialog from 'components/Dialogs/BaseDialog';
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';

import api from 'services/api';

import paymentMethods from '@constants/salesContract/invoice/paymentMethods';
import types from '@constants/salesContract/invoice/types';

import SalesContractInvoiceStatusLabel from 'components/StatusLabel/SalesContractInvoiceStatusLabel';
import PercentField from 'components/Fields/PercentField';
import CurrencyField from 'components/TextFields/CurrencyTextField';

import { UPDATE_SALES_CONTRACT_INVOICE_MUTATION } from 'queries/updateSalesContractInvoiceQuery';
import { GET_SALES_CONTRACT_INVOICE_QUERY } from 'queries/getSalesContractInvoiceQuery';

import swalFire from 'utils/swalFire';
import formatDate from 'utils/formatDate';
import formatPrice from 'utils/formatPrice';
import convertUTCtoLocal from 'utils/convertUTCtoLocal';
import handlerError from 'utils/handleError';

import { useStyles } from './styles';

function EditSalesContractInvoiceDialog({ open, invoiceId, onClose }) {
  const classes = useStyles();

  const isMounted = useRef(false);

  const [invoice, setInvoice] = useState(null);
  const [loading, setLoading] = useState(true);

  const getInvoice = (id) => {
    api
      .call(GET_SALES_CONTRACT_INVOICE_QUERY, { id })
      // eslint-disable-next-line no-shadow
      .then(({ getSalesContractInvoice: invoice }) => {
        if (!isMounted.current) return;

        let paidAt = '';
        if (invoice.paidAt) {
          paidAt = convertUTCtoLocal(invoice.paidAt).split('T').shift();
        }

        setInvoice({
          ...invoice,
          paidAt,
        });

        setLoading(false);
      })
      .catch(() => {
        swalFire('', 'Não foi possível encontrar o título.', 'error');
        onClose();
      });
  };

  const handleSubmit = () => {
    const paidAt = invoice.paidAt
      ? new Date(invoice.paidAt).toISOString()
      : null;

    const variables = {
      id: invoiceId,
      paymentMethod: invoice.paymentMethod,
      fees: invoice.fees,
      paidValue: invoice.paidValue,
      discount: Number(invoice.discount),
      paidAt,
    };

    api
      .call(UPDATE_SALES_CONTRACT_INVOICE_MUTATION, variables)
      .then(() => {
        swalFire('', 'Título atualizado com sucesso!', 'success').then(() =>
          onClose()
        );
      })
      .catch((err) => {
        handlerError(
          {
            INVALID_PAYMENT_METHOD: () => {
              swalFire('', 'Método de pagamento inválido.', 'error');
            },
            DEFAULT: () => {
              swalFire('', 'Não foi possível atualizar o título.', 'error');
            },
          },
          err.message
        );
      });
  };

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (open) {
      getInvoice(invoiceId);
    } else {
      setInvoice(null);
      setLoading(true);
    }

    // eslint-disable-next-line
  }, [open, invoiceId]);

  return (
    <BaseDialog
      open={open}
      title="Atualização do título"
      maxWidth="md"
      onClose={onClose}
      loading={loading}
    >
      {invoice ? (
        <>
          <Grid container spacing={2}>
            <Grid item xs={6} md={3}>
              <Typography className={classes.fieldText}>Situação</Typography>
              <SalesContractInvoiceStatusLabel status={invoice.status} />
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography className={classes.fieldText}>
                Tipo de parcela
              </Typography>
              <Typography className={classes.valueText}>
                {types.find((type) => type.value === invoice.type)?.label}
              </Typography>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className={classes.fieldText}>Vencimento</Typography>
              <Typography className={classes.valueText}>
                {formatDate(invoice.dueDate)}
              </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography className={classes.fieldText}>Descrição</Typography>
              <Typography className={classes.valueText}>
                {invoice.description}
              </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography className={classes.fieldText}>Valor</Typography>
              <Typography className={classes.valueText}>
                {formatPrice(invoice.value)}
              </Typography>
            </Grid>
          </Grid>

          <Box my={4}>
            <Typography className={classes.title}>
              Deseja dar baixa no título?
            </Typography>
          </Box>

          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>Tipo de pagamento</InputLabel>
                <Select
                  value={invoice.paymentMethod}
                  onChange={(e) =>
                    setInvoice({ ...invoice, paymentMethod: e.target.value })
                  }
                >
                  {paymentMethods.map((paymentMethod) => (
                    <MenuItem
                      key={paymentMethod.value}
                      value={paymentMethod.value}
                    >
                      {paymentMethod.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box mb={1}>
                <CurrencyField
                  label="Juros"
                  placeholder="R$ 0,00"
                  value={invoice.fees}
                  onChange={(e, value) =>
                    setInvoice({ ...invoice, fees: value })
                  }
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box mb={1}>
                <CurrencyField
                  label="Valor recebido"
                  placeholder="R$ 0,00"
                  value={invoice.paidValue}
                  onChange={(e, value) =>
                    setInvoice({ ...invoice, paidValue: value })
                  }
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box mb={1}>
                <TextField
                  type="date"
                  label="Data de pagamento"
                  value={invoice.paidAt}
                  onChange={(e) =>
                    setInvoice({ ...invoice, paidAt: e.target.value })
                  }
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box mb={1}>
                <PercentField
                  label="Desconto"
                  value={invoice.discount}
                  onChange={(e) =>
                    setInvoice({ ...invoice, discount: e.target.value })
                  }
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                display="flex"
                height={58}
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <Box>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={handleSubmit}
                  >
                    Salvar
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </>
      ) : null}
    </BaseDialog>
  );
}

EditSalesContractInvoiceDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  invoiceId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

EditSalesContractInvoiceDialog.defaultProps = {
  invoiceId: null,
};

export default EditSalesContractInvoiceDialog;
