const TOGGLE_REAL_ESTATE_AGENT_MUTATION = `
    mutation (
      $id: ID!
    ){
      toggleRealEstateAgent(id: $id)
    }
  
`;

export default TOGGLE_REAL_ESTATE_AGENT_MUTATION;
