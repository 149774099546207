import { Box, Button, Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';
import ICON_TYPES from '@constants/iconsPaths';
import useStyles from './styles';

export default function NewReservationPopup({ hide, onClick }) {
  const classes = useStyles();

  if (hide) return null;

  return (
    <Box className={classes.card}>
      <Icon src={ICON_TYPES.POPUP.info} size={24} />

      <Typography className={classes.text} variant="body2">
        Novas reservas foram aprovadas
      </Typography>

      <Button variant="contained" color="primary" onClick={onClick}>
        Visualizar
      </Button>
    </Box>
  );
}

NewReservationPopup.propTypes = {
  hide: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};
