import validateEmail from 'utils/validateEmail';
import validatePhone from 'utils/validatePhone';
import validatePostalCode from 'utils/validatePostalCode';

const validateInputs = (realEstateAgent) => {
  const checklist = [
    {
      step: 0,
      message: 'O campo Nome é obrigatório.',
      validate: () => realEstateAgent.name,
    },

    {
      step: 0,
      message: 'O campo CRECI é obrigatório.',
      validate: () => realEstateAgent.creci,
    },
    {
      step: 0,
      message: 'O campo Imobiliária é obrigatório.',
      validate: () => realEstateAgent.realEstateId,
    },
    {
      step: 0,
      message: 'O campo E-mail é obrigatório.',
      validate: () => !realEstateAgent.active || realEstateAgent.username,
    },
    {
      step: 0,
      message: 'E-mail inválido.',
      validate: () =>
        !realEstateAgent.username || validateEmail(realEstateAgent.username),
    },
    {
      step: 0,
      message: 'Telefone inválido.',
      validate: () =>
        !realEstateAgent.phone || validatePhone(realEstateAgent.phone),
    },
    {
      step: 0,
      message: 'Telefone 2 inválido.',
      validate: () =>
        !realEstateAgent.phone2 || validatePhone(realEstateAgent.phone2),
    },
    {
      step: 0,
      message: 'Telefone 2 inválido.',
      validate: () =>
        !realEstateAgent.phone2 || validatePhone(realEstateAgent.phone2),
    },
    {
      step: 1,
      message: 'CEP inválido.',
      validate: () =>
        !realEstateAgent.postalCode ||
        validatePostalCode(realEstateAgent.postalCode),
    },
  ];

  const validate = checklist.reduce((prev, item) => {
    if (prev) return prev;
    if (!item.validate()) return item;
    return null;
  }, null);

  return validate ? { success: false, ...validate } : { success: true };
};

export default validateInputs;
