const GET_REAL_ESTATES_QUERY = `
    query GetRealEstates(
      $projectId: ID!
      $page: Int
      $search: String
      $sortOptions: RealEstateSort
    ){
      getRealEstates (
        projectId: $projectId
        page : $page
        search: $search
        sortOptions: $sortOptions
      ) {
        realEstates {
          _id
          projectId
          name
          cnpj
          creci
          reservationLimit
          postalCode
          address
          addressNumber
          addressComplement
          district
          city
          state
          username
          phone
          phone2
          email
          createdAt
          active
        }
        page
        pages
      }
    }
  `;

export default GET_REAL_ESTATES_QUERY;

export const GET_REAL_ESTATES_QUERY_2 = `
  query GetRealEstate (
    $projectId: ID!
    $search: String
  ) {
    getRealEstates (
      projectId: $projectId
      page: 0
      itemsPerPage: 10
      search: $search
      sort: { 
        field: "name"
        direction: "asc"
      }
    ) {
      realEstates {
        _id
        name
        cnpj
        creci
      }
      page
      pages
    }
  }
`;
