export default function validateFirstMonthlyInstallmentDate(monthYear, day) {
  const dateStr = `${monthYear}-${String(day).padStart(2, '0')}`;
  const now = new Date();

  if (Number.isNaN(Date.parse(dateStr))) return null;

  const month = Number(monthYear.split('-')[1]) - 1;
  if (month === now.getMonth() && day < now.getDate()) return null;

  return dateStr;
}
