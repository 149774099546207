import React from 'react';

import { useInvoicesListContext } from 'providers/ListProviders/InvoicesListProvider';

import BaseList from '../BaseList';

import useColumns from './hooks/useColumns';

function InvoicesList({ onSelect }) {
  const columns = useColumns(onSelect);

  const { invoices, pages, variables, setPage } = useInvoicesListContext();
  return (
    <BaseList
      columns={columns}
      data={invoices}
      currentPage={variables.page}
      pages={pages}
      onPagination={setPage}
      changedAt={variables.changedAt}
      onRowClick={onSelect}
    />
  );
}

export default InvoicesList;
