import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Grid, Typography } from '@material-ui/core';

import api from 'services/api';
import AlertModule from 'modules/AlertModule';
import { ProjectContext } from 'providers/ProjectProvider';

import deletePropertyMarkQuery from 'queries/deletePropertyMarkQuery';
import getPropertyQuery from 'queries/getPropertyQuery';

import PropertyStatusLabel from 'components/StatusLabel/PropertyStatusLabel';
import Discount from 'components/Discount';
import InformationBlock from 'components/InformationBlock';

import formatPrice from 'utils/formatPrice';
import formatArea from 'utils/formatArea';
import WebSocketController from 'utils/WebSocketController';
import handlerError from 'utils/handleError';

import useThemeClasses from 'theme/useThemeClasses';

const socket = new WebSocketController();

export default function PropertyDialog({ propertyId, onUpdate, onClose }) {
  const themeClasses = useThemeClasses;
  const buttonClasses = themeClasses.button();
  const { discount } = useContext(ProjectContext);
  const [property, setProperty] = useState(null);

  const handleDeleteMark = () => {
    AlertModule.confirmation(
      'Deseja realmente remover a marcação do imóvel?'
    ).then((result) => {
      if (result.isConfirmed) {
        api
          .call(deletePropertyMarkQuery(property._id))
          .then((data) => {
            if (data) {
              AlertModule.success('Marcação removida com sucesso!');
              onUpdate();
              onClose();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  const getProperty = () => {
    if (!propertyId) {
      onClose();
      return;
    }
    api
      .call(getPropertyQuery, { id: propertyId })
      .then((data) => {
        if (data?.getProperty) {
          setProperty(data.getProperty);
        }
      })
      .catch((error) => {
        handlerError(
          {
            DEFAULT: () => {
              AlertModule.error(
                'Não foi possível carregar as informações do imóvel.'
              );
              onClose();
            },
          },
          error.message
        );
      });
  };

  useEffect(() => {
    getProperty();
  }, []);

  useEffect(() => {
    socket.onPropertyUpdated(getProperty);
    socket.onPropertyDeleted(getProperty);
    socket.onReservationCreated(getProperty);
    socket.onReservationUpdated(getProperty);

    return () => socket.destroy();
  }, [property]);

  if (!property) return null;

  return (
    <Box padding={5}>
      <Typography className="fw700" variant="h5" color="textPrimary">
        Imóvel
      </Typography>

      <Box mt={2.5}>
        <Grid container spacing={3}>
          <InformationBlock fullWidth>
            <Discount price={property.price} discount={discount.cash} />
          </InformationBlock>

          <InformationBlock label="Unidade:">{property.unity}</InformationBlock>

          <InformationBlock label="Situação:">
            <PropertyStatusLabel status={property.status} />
          </InformationBlock>

          <InformationBlock label="Preço:">
            {formatPrice(property.price)}
          </InformationBlock>

          <InformationBlock label="Preço por m²:">
            {formatPrice(property.m2price)}
          </InformationBlock>

          <InformationBlock label="Area Total:">
            {formatArea(property.totalArea)}
          </InformationBlock>
        </Grid>
      </Box>

      <Box
        display="flex"
        justifyContent="space-between"
        alignContent="center"
        mt={5}
      >
        <Button
          className={buttonClasses.linkButton}
          onClick={() => onClose()}
          color="primary"
        >
          Fechar
        </Button>

        {property?.mark && (
          <Button
            name="delete-mark-button"
            variant="contained"
            color="primary"
            onClick={handleDeleteMark}
          >
            Remover Marcação
          </Button>
        )}
      </Box>
    </Box>
  );
}
