import React from 'react';

import { INVITE_TYPES_VALUES } from '@constants/invites/types';
import convertDateToTimeLeft from 'utils/convertDateToTimeLeft';

import InviteStatusLabel from 'components/StatusLabel/InviteStatusLabel';
import ListActions from '../components/ListActions';

export default function useColumns() {
  const getInviteTypeLabel = (type) =>
    INVITE_TYPES_VALUES.find((i) => i.value === type)?.label || '?';

  const columns = [
    { title: 'E-mail', field: 'email' },

    {
      title: 'Tipo',
      field: 'type',
      render: (rowData) => getInviteTypeLabel(rowData.type),
    },

    {
      title: 'Situação',
      field: 'status',
      render: (rowData) => <InviteStatusLabel status={rowData.status} />,
    },

    {
      title: 'Expira em',
      field: 'expiration',
      render: (rowData) =>
        rowData.status === 'pending'
          ? convertDateToTimeLeft(rowData.expiration)
          : '-',
    },

    {
      title: '',
      field: '_actions',
      align: 'center',
      fit: true,
      render: (rowData) => <ListActions rowData={rowData} />,
    },
  ];

  return columns;
}
