import getCenterCoordinates from './get-center-coordinates';
const getContainerBounds = (container, map, scale) => {
  const scaledMapWidth = map.width * scale
  const scaledMapHeight = map.height * scale
  const center = getCenterCoordinates(container)

  return {
    xMax: center.x,
    xMin: center.x - scaledMapWidth,
    yMax: center.y,
    yMin: center.y - scaledMapHeight
  }
}

export default getContainerBounds
