import React, { useMemo } from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import useStyles from './styles';

export default function HeaderLink({ value, onClose }) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const handleClick = (e) => {
    e.preventDefault();
    history.push(value.href);
    onClose();
  };

  const active = useMemo(
    () => location.pathname === value.href,
    [value, location]
  );

  const activeClass = useMemo(() => (active ? ' active' : ''), [active]);

  return (
    <ListItem
      button
      className={classes.link + activeClass}
      href={value.href}
      onClick={handleClick}
    >
      <ListItemIcon>{value?.icon}</ListItemIcon>
      <ListItemText primary={value.label} />
    </ListItem>
  );
}

HeaderLink.propTypes = {
  value: PropTypes.shape({
    href: PropTypes.string,
    label: PropTypes.string,
    icon: PropTypes.node,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};
