import React from 'react';
import { useHistory } from 'react-router-dom';

import { useSalesContractsContext } from 'providers/ListProviders/SalesContractsListProvider';

import BaseList from '../BaseList';
import useColumns from './hooks/useColumns';

function SalesContractsList({ onEdit, hasDocumentTemplate, projectSlug }) {
  const history = useHistory();

  const { salesContracts, pages, variables, setPage } =
    useSalesContractsContext();

  const columns = useColumns(hasDocumentTemplate, onEdit);

  const handleRowClick = (rowData) => {
    history.push(`/${projectSlug}/contratos/${rowData.numberId}`);
  };

  return (
    <BaseList
      columns={columns}
      data={salesContracts}
      currentPage={variables.page}
      pages={pages}
      onPagination={setPage}
      onRowClick={handleRowClick}
      changedAt={variables.changedAt}
    />
  );
}

export default SalesContractsList;
