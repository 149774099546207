import { useEffect, useState } from 'react';

import useQuery from 'hooks/useQuery';
import GET_REAL_ESTATE_QUERY from 'queries/getRealEstateQuery';
import generateObjectChecksum from 'utils/generateObjectChecksum';
import ErrorHandler from 'modules/ErrorHandlerModule';

export default function useGetRealEstate(id) {
  const [executeGetRealEstate, isLoading] = useQuery(GET_REAL_ESTATE_QUERY);
  const [realEstate, setRealEstate] = useState(null);
  const [checksum, setChecksum] = useState(null);

  const setRealEstateField = (field) =>
    setRealEstate({ ...realEstate, ...field });

  const onResolveRealEstate = ({ getRealEstate: data }) => ({
    ...data,
    reuseEmail: data.email && data.email === data.username,
  });

  const onGetRealEstateError = ErrorHandler(
    'Não foi possível encontrar a imobiliária.'
  );

  useEffect(() => {
    if (id) {
      executeGetRealEstate({ id })
        .then(onResolveRealEstate)
        .then((realEstateData) => {
          setChecksum(generateObjectChecksum(realEstateData));
          setRealEstate(realEstateData);
        })
        .catch(onGetRealEstateError);
    }
  }, [id]);

  return { realEstate, checksum, isLoading, setRealEstateField };
}
