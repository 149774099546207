import { makeStyles } from '@material-ui/core';
import darkTheme from 'theme/darkTheme';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: darkTheme.palette.grey[700],
    height: 90,
    border: '1px solid',
    borderRadius: '10px',
    marginBottom: '25px',
    borderColor: darkTheme.palette.grey[500],
  },

  grouped: {
    display: 'flex',
    alignItems: 'center',
  },
  item: {
    margin: '0px 35px',
    display: 'flex',
  },
  label: {
    color: darkTheme.palette.grey[300],
    fontWeight: 800,
  },
  value: {
    color: darkTheme.palette.background.default,
    fontWeight: 400,
  },

  divider: {
    height: 32,
    width: 1,
    backgroundColor: theme.palette.secondary.light,
    opacity: 0.4,
  },
}));
