import { Box, Container } from '@material-ui/core';
import React from 'react';
import UserProfileButton from 'components/UserProfile/buttons/ProfileButton';

export default function Header({ onShowUserProfile }) {
  return (
    <Box
      component="header"
      borderBottom="2px solid rgba(134, 139, 167, 0.1)"
      mb={5}
    >
      <Container>
        <Box display="flex" alignItems="center" height={104}>
          <img src="/assets/images/logo.svg" alt="Quadra A" width="140" />

          <Box display="flex" justifyContent="flex-end" ml="auto">
            <UserProfileButton onShowUserProfile={onShowUserProfile} />
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
