import Constants from '@constants';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import PostalCodeTextField from 'components/TextFields/PostalCodeTextField';
import React from 'react';
import viacep from 'services/viacep';

export default function AddressTab({ realEstate, setRealEstate }) {
  const getFullAddress = () =>
    viacep.buscar(realEstate.postalCode).then((address) => {
      setRealEstate({
        address: address.logradouro,
        addressComplement: address.complemento,
        district: address.bairro,
        city: address.localidade,
        state: address.uf,
      });
    });

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <PostalCodeTextField
          margin="dense"
          name="crs-postalcode"
          label="CEP"
          type="text"
          value={realEstate.postalCode || ''}
          onChange={(e) => setRealEstate({ postalCode: e.target.value })}
          onBlur={getFullAddress}
          fullWidth
          autoFocus
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          margin="dense"
          name="crs-address"
          label="Logradouro"
          type="text"
          value={realEstate.address || ''}
          onChange={(e) => setRealEstate({ address: e.target.value })}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          margin="dense"
          name="crs-addressComplement"
          label="Complemento"
          type="text"
          value={realEstate.addressComplement || ''}
          onChange={(e) =>
            setRealEstate({
              addressComplement: e.target.value,
            })
          }
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          margin="dense"
          name="crs-addressNumber"
          label="Número"
          type="text"
          value={realEstate.addressNumber || ''}
          onChange={(e) =>
            setRealEstate({
              addressNumber: e.target.value,
            })
          }
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          margin="dense"
          name="crs-district"
          label="Bairro"
          type="text"
          value={realEstate.district || ''}
          onChange={(e) => setRealEstate({ district: e.target.value })}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          margin="dense"
          name="crs-city"
          label="Cidade"
          type="text"
          value={realEstate.city || ''}
          onChange={(e) => setRealEstate({ city: e.target.value })}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl margin="dense" fullWidth>
          <InputLabel id="rs-state-select-label">Estado</InputLabel>
          <Select
            name="crs-state"
            labelId="rs-state-select-label"
            value={realEstate.state || ''}
            onChange={(e) => setRealEstate({ state: e.target.value })}
          >
            {Constants.BRASIL_STATES.map((state) => (
              <MenuItem key={state.value} value={state.value}>
                {state.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
}
