const GET_PROJECT_USERS_QUERY = `
    query GetProjectUsers ($projectId: ID!) {
      getProjectUsers (
        projectId: $projectId
      ) {
        user {          
          _id
          name
          email
          createdAt
        }
        active
      }
    }
  `;

export default GET_PROJECT_USERS_QUERY;
