import React from 'react';
import { Route, Switch, Redirect, useParams } from 'react-router-dom';

import NotFound from 'components/NotFound';
import Team from './Team';
import Customers from './Customers';
import Properties from './Properties';
import EditMap from './EditMap';
import Reservations from './Reservations';
import InteractiveMap from './InteractiveMap';
import ProjectConfiguration from './ProjectConfiguration';
import SalesContracts from './SalesContracts';
import SalesContract from './SalesContract';
import SalesContractsInvoices from './SalesContractsInvoices';
import Financial from './Financial';

export default function Router() {
  const { projectSlug } = useParams();

  return (
    <Switch>
      <Route exact path="/:projectSlug">
        <Redirect to={`/${projectSlug}/imoveis`} />
      </Route>
      <Route exact path="/:projectSlug/imoveis" component={Properties} />
      <Route exact path="/:projectSlug/reservas" component={Reservations} />
      <Route exact path="/:projectSlug/contratos" component={SalesContracts} />
      <Route
        exact
        path="/:projectSlug/contratos/:numberId"
        component={SalesContract}
      />
      <Route
        exact
        path="/:projectSlug/titulos"
        component={SalesContractsInvoices}
      />
      <Route exact path="/:projectSlug/financeiro" component={Financial} />
      <Route exact path="/:projectSlug/clientes" component={Customers} />
      <Route exact path="/:projectSlug/equipe" component={Team} />
      <Route exact path="/:projectSlug/mapa" component={InteractiveMap} />
      <Route exact path="/:projectSlug/mapa/editar" component={EditMap} />
      <Route
        exact
        path="/:projectSlug/configuracoes"
        component={ProjectConfiguration}
      />
      <Route path="*" component={NotFound} />
    </Switch>
  );
}
