import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  calendar: {
    width: 280,
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& .title': {
      color: theme.palette.grey[300],
    },

    '& .controls': {
      '& .MuiButtonBase-root': {
        width: 28,
        height: 28,
        borderRadius: '50%',
        fontSize: 24,
        color: theme.palette.grey[300],

        '&.previous-month': {
          marginRight: theme.spacing(1),
        },
      },
    },
  },

  body: {
    display: 'flex',
    flexWrap: 'wrap',

    '& > .MuiBox-root': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
      width: 40,
      height: 40,
      ...theme.typography.caption,
      color: theme.palette.grey[100],

      '&.day-of-week': {
        fontWeight: 'bold',
      },

      '&.marked': {
        backgroundColor: `${theme.palette.primary.main}20`,

        '&.first': {
          borderTopLeftRadius: '50%',
          borderBottomLeftRadius: '50%',
        },

        '&.last': {
          borderTopRightRadius: '50%',
          borderBottomRightRadius: '50%',
        },
      },

      '& > .MuiButtonBase-root': {
        width: 32,
        height: 32,
        borderRadius: '50%',
        ...theme.typography.caption,
        color: theme.palette.grey[100],
        backgroundColor: 'transparent',

        '&:hover': {
          backgroundColor: '#00000020',
        },

        '&.selected': {
          backgroundColor: theme.palette.primary.main,
          color: 'white',
          transform: 'scale(1.25)',
        },
      },

      '&.another-month': {
        '& > .MuiButtonBase-root': {
          width: 32,
          height: 32,
          borderRadius: '50%',
          ...theme.typography.caption,
          color: `${theme.palette.grey[100]}66`,
          backgroundColor: 'transparent',

          '&:hover': {
            backgroundColor: theme.palette.grey[500],
          },

          '&.selected': {
            backgroundColor: theme.palette.primary.main,
            color: '#FFFFFF90',
            transform: 'scale(1.25)',
          },
        },
      },
    },
  },
}));

export default {};
