const palette = {
  primary: {
    main: '#FF6C4E',
  },

  secondary: {
    main: '#868BA7',
    light: '#8C9BBA',
    dark: '#3D4657',
  },

  success: {
    main: '#24CCB8',
  },

  error: {
    main: '#FF637B',
  },

  warning: {
    main: '#FDA451',
  },

  info: {
    main: '#4A90E2',
  },

  grey: {
    black: '#050506',
    100: '#212529',
    200: '#495057',
    300: '#868E96',
    400: '#ADB5BD',
    500: '#CED4DA',
    600: '#E9ECEF',
    700: '#F8F9FA',

    white: '#FFFFFF',
  },
  greyLow: {
    700: '#343A40',
    800: '#1F2226',
  },
  text: {
    primary: '#5F697D',
    secondary: '#868BA7',
  },

  background: {
    default: '#FFFFFF',
  },

  propertyStatus: {
    unavailable: {
      backgroundColor: '#1a3bcf',
      color: '#FFFFFF',
    },
    available: {
      backgroundColor: '#24CCB8',
      color: '#FFFFFF',
    },
    reserved: {
      backgroundColor: '#FDA451',
      color: '#FFFFFF',
    },
    sold: {
      backgroundColor: '#FF637B',
      color: '#FFFFFF',
    },
  },

  reservationStatus: {
    rejected: {
      backgroundColor: '#BA68C8',
      color: '#FFFFFF',
    },
    pending: {
      backgroundColor: '#FDA451',
      color: '#FFFFFF',
    },
    accepted: {
      backgroundColor: '#FF637B',
      color: '#FFFFFF',
    },
    expired: {
      backgroundColor: '#5F697D',
      color: '#FFFFFF',
    },
  },

  inviteStatus: {
    canceled: {
      backgroundColor: '#FF637B',
      color: '#FFFFFF',
    },
    rejected: {
      backgroundColor: '#FF637B',
      color: '#FFFFFF',
    },
    pending: {
      backgroundColor: '#FDA451',
      color: '#FFFFFF',
    },
    accepted: {
      backgroundColor: '#24CCB8',
      color: '#FFFFFF',
    },
    expired: {
      backgroundColor: '#BA68C8',
      color: '#FFFFFF',
    },
  },

  salesContractStatus: {
    pending: {
      backgroundColor: '#FDA451',
      color: '#FFFFFF',
    },
    in_progress: {
      backgroundColor: '#5F697D',
      color: '#FFFFFF',
    },
    canceled: {
      backgroundColor: '#BA68C8',
      color: '#FFFFFF',
    },
    suspended: {
      backgroundColor: '#FF637B',
      color: '#FFFFFF',
    },
  },

  returnFileStatus: {
    pending: {
      backgroundColor: '#5F697D',
      color: '#050506',
    },
    processed: {
      backgroundColor: '#24CCB8',
      color: '#050506',
    },
    failed: {
      backgroundColor: '#FF637B',
      color: '#050506',
    },
  },

  returnFileOccurrenceStatus: {
    paid: {
      backgroundColor: '#24CCB8',
      color: '#050506',
    },
    error: {
      backgroundColor: '#FF637B',
      color: '#050506',
    },
    registered: {
      backgroundColor: '#BA68C8',
      color: '#050506',
    },
  },

  salesContractInvoiceStatus: {
    pending: {
      backgroundColor: '#5F697D',
      color: '#FFFFFF',
    },
    paid: {
      backgroundColor: '#24CCB8',
      color: '#FFFFFF',
    },
    expired: {
      backgroundColor: '#FF637B',
      color: '#FFFFFF',
    },
  },
};

export default palette;
