import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  buttonWithIcon: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },

  linkButton: {
    textDecoration: 'underline',

    '&:hover': {
      textDecoration: 'underline',
    },
  }
}))