import { useMemo } from 'react';

export default function useGetInstallmentsDates(reservation) {
  const firstMonthlyInstallmentFulldate = useMemo(() => {
    if (!reservation) return null;

    const dateStr = `${reservation.firstMonthlyInstallmentDate}-${String(
      reservation.monthlyDueDay
    ).padStart(2, '0')}`;
    const now = new Date(reservation.createdAt);

    if (Number.isNaN(Date.parse(dateStr))) return null;

    const month =
      Number(reservation.firstMonthlyInstallmentDate.split('-')[1]) - 1;
    if (month === now.getMonth() && reservation.monthlyDueDay < now.getDate()) {
      return null;
    }

    return dateStr;
  }, [reservation]);

  const firstAnnuallyInstallmentFulldate = useMemo(() => {
    if (!reservation) return null;

    const dateStr = `${reservation.firstAnnuallyInstallmentDate}-${String(
      reservation.annuallyDueDay
    ).padStart(2, '0')}`;
    const date = new Date(dateStr);

    if (Number.isNaN(Date.parse(dateStr))) return null;

    if (firstMonthlyInstallmentFulldate) {
      const minDatetime = new Date(firstMonthlyInstallmentFulldate);
      minDatetime.setMonth(minDatetime.getMonth() + 1);
      minDatetime.setDate(1);

      const maxDatetime = new Date(firstMonthlyInstallmentFulldate);
      maxDatetime.setMonth(maxDatetime.getMonth() + 13);
      maxDatetime.setDate(-1);

      const validate =
        date.getTime() >= minDatetime.getTime() &&
        date.getTime() <= maxDatetime.getTime();

      if (!validate) return null;
    }

    return dateStr;
  }, [reservation, firstMonthlyInstallmentFulldate]);

  return {
    firstMonthlyInstallmentFulldate,
    firstAnnuallyInstallmentFulldate,
  };
}
