/* eslint-disable import/no-anonymous-default-export */
import { makeStyles } from '@material-ui/core';
import palette from 'theme/lightTheme/palette';

const desktopHeight = 'calc(100vh - 106px)';

export default makeStyles((theme) => ({
  root: {
    position: 'relative',
    height: '100%',
    overflowX: 'hidden',

    [theme.breakpoints.up('md')]: {
      height: desktopHeight,
    },
  },
  propertyUnity: {
    fontWeight: 700,
    fontSize: 20,
    color: theme.palette.secondary.dark,
  },
  propertyPrice: {
    fontWeight: 500,
    fontSize: 24,
    color: theme.palette.greyLow[800],
  },
  monthlyInstallmentValue: {
    color: palette.greyLow[700],
    fontSize: 20,
  },

  close: {
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    marginBottom: 30,
    '&:hover': {
      filter: 'brightness(1.5)',
    },
  },
  header: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1),
  },

  overlay: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    zIndex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },

  mapContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },

  dialogContainer: {
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      left: 0,
      top: 0,
      width: 600,
      bottom: 0,
      zIndex: 2,
      background: theme.palette.grey.white,
      overflowY: 'auto',
    },
  },
}));
