import { Typography, Link } from '@material-ui/core';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';

export default function SideTabItem({ label, selected, onClick }) {
  const classes = useStyles();

  const className = useMemo(() => {
    const value = [classes.link];
    if (selected) value.push('active');
    return value.join(' ');
  }, [classes, selected]);

  return (
    <Link className={className} href="# " onClick={onClick}>
      <Typography variant="body2">{label}</Typography>
    </Link>
  );
}

SideTabItem.propTypes = {
  label: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};
