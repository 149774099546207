export default `
        query getRemittanceQuery (
            $remittanceId: ID!
        ) {
          getRemittance (
                  remittanceId: $remittanceId
          ){
              _id
              projectId
              name
              fileId
              createdAt
              invoices{
                _id
                projectId
                contractNumberId
                value
                dueDate
                description
              }
              invoicesIds
              contracts{
                _id
                projectId
                numberId
                reservationId
                status
                price
                nextAdjustment
                contractTemplateId
                createdAt
                customer{
                  _id
                  projectId
                  name
                }
              }      
            } 
          }
    `;
