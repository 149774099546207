import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  discountContainer: { display: 'flex', alignItems: 'center' },
  dash: {
    textDecoration: 'line-through',
    textDecorationColor: '#FF637B',
  },
  discountLabel: { color: '#46BE46' },
}));
