import { v4 as uuidv4 } from 'uuid';
import { useRef, useState } from 'react';
import debounce from 'lodash.debounce';

import api from 'services/api';

const useQuery = (query) => {
  const [isLoading, setIsLoading] = useState(false);
  const lastQueryId = useRef(null);

  const debounced = useRef(
    debounce((resolve, reject, method, ...args) => {
      method(...args)
        .then(resolve)
        .catch(reject);
    }, 300)
  ).current;

  const execute = (variables = {}) => {
    setIsLoading(true);
    const queryId = uuidv4();
    lastQueryId.current = queryId;

    return api
      .call(query, variables)
      .then((response) => ({
        ...response,
        isLastQuery: lastQueryId.current === queryId,
      }))
      .catch((error) => {
        console.error(error);
        throw error;
      })
      .finally(() => setIsLoading(false));
  };

  execute.debounced = (...args) => {
    setIsLoading(true);
    return new Promise((resolve, reject) => {
      debounced(resolve, reject, execute, ...args);
    });
  };
  return [execute, isLoading];
};
export default useQuery;
