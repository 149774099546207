import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  container: {
    paddingTop: 20,
    paddingBottom: 120,
  },
}));

export default {};
