const GET_REAL_ESTATE_AGENTS_QUERY = `
    query GetRealEstateAgents(
      $projectId: ID!
      $page: Int
      $itemsPerPage: Int
      $search: String
      $sortOptions: RealEstateAgentSort
      $filterOptions:RealEstateAgentFilter
    ){
      getRealEstateAgents (
        projectId: $projectId
        page: $page
        itemsPerPage : $itemsPerPage
        search : $search
        sortOptions :$sortOptions
        filterOptions:$filterOptions
      ) {
        realEstateAgents {
          _id
          projectId
          name
          gender
          cpf
          rg
          creci
          reservationLimit
          realEstateId
          postalCode
          address
          addressNumber
          addressComplement
          district
          city
          state
          username
          phone
          phone2
          email
          createdAt
          active
        }
        page
        pages
      }
    }
  `;

export default GET_REAL_ESTATE_AGENTS_QUERY;
