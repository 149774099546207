import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from '@material-ui/core';
import SalesContractStatusLabel from 'components/StatusLabel/SalesContractStatusLabel';
import formatPrice from 'utils/formatPrice';
import formatCpf from 'utils/formatCpf';
import formatDate from 'utils/formatDate';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';
import ICON_TYPES from '@constants/iconsPaths';
import preventDefault from 'utils/preventDefault';
import { useStyles } from '../styles';

export default function ContractDetails({ project, contract, onEdit }) {
  const theme = useTheme();
  const classes = useStyles();

  const printContract = () => {
    const SERVER_URL = process.env.REACT_APP_API_SERVER;
    window.open(
      `${SERVER_URL}/uploads/documents/sales-contract/${contract._id}`
    );
  };

  return (
    <Box minWidth={282} className={classes.contractDetails}>
      <Box mb={2}>
        <Typography variant="h5" className="fw700">
          Detalhes do Contrato
        </Typography>
      </Box>

      {contract ? (
        <>
          <Box mb={1}>
            <Typography variant="h4" color="primary">
              #{contract.numberId}
            </Typography>
          </Box>
          <Box mb={2}>
            <SalesContractStatusLabel status={contract.status} />
            <IconButton
              title="Editar"
              size="small"
              onClick={preventDefault(() => onEdit(contract))}
            >
              <Icon src={ICON_TYPES.DETAILS.edit} size={22} />
            </IconButton>
          </Box>

          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Typography
                variant="caption"
                style={{ color: theme.palette.grey[400] }}
              >
                valor de venda:
              </Typography>
              <Typography
                variant="body2"
                style={{ color: theme.palette.grey[200] }}
              >
                {formatPrice(contract.price - contract.entrance)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="caption"
                style={{ color: theme.palette.grey[400] }}
              >
                data de emissão:
              </Typography>
              <Typography
                variant="body2"
                style={{ color: theme.palette.grey[200] }}
              >
                {formatDate(contract.createdAt)}
              </Typography>
            </Grid>
          </Grid>

          <Box mt={4}>
            <Box mb={2}>
              <Typography style={{ color: theme.palette.secondary.dark }}>
                Informações do cliente
              </Typography>
            </Box>

            <Grid container spacing={2}>
              <Grid item xs="auto">
                <Box className={classes.iconBox}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="#FF6C4E"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                      d="M5 20c0-2.5 2-4.4 4.4-4.4h5.1c2.5 0 4.4 2 4.4 4.4M15 5.2c1.7 1.7 1.7 4.4 0 6-1.7 1.6-4.4 1.7-6 0-1.6-1.7-1.7-4.4 0-6 1.7-1.6 4.3-1.6 6 0"
                    />
                  </svg>
                </Box>
              </Grid>
              <Grid item xs>
                <Typography
                  variant="body2"
                  style={{ color: theme.palette.grey[200] }}
                >
                  {contract.customer.name}
                </Typography>
                <Typography
                  variant="body2"
                  style={{ color: theme.palette.grey[300] }}
                >
                  {formatCpf(contract.customer.cpf)}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Box mt={4}>
            <Box mb={2}>
              <Typography style={{ color: theme.palette.secondary.dark }}>
                Informações do imóvel
              </Typography>
            </Box>

            <Grid container spacing={2}>
              <Grid item xs="auto">
                <Box className={classes.iconBox}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="#FF6C4E"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                      d="M2.997 11.696l7.938-6.805a1.637 1.637 0 012.13 0l7.94 6.805"
                    />
                    <path
                      stroke="#FF6C4E"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                      d="M20.004 21.004V4.497a.5.5 0 00-.5-.5h-3.002a.5.5 0 00-.5.5v2.607M9.544 21.004V15.5a1 1 0 011-1h2.911a1 1 0 011 1v5.503M4.282 10.593v10.41M21.004 21.004H2.997"
                    />
                  </svg>
                </Box>
              </Grid>
              <Grid item xs>
                <Typography
                  variant="body2"
                  style={{ color: theme.palette.grey[200] }}
                >
                  {contract.property.unity}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Box mt={2} hidden={!project.hasDocumentTemplate}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => printContract()}
            >
              Imprimir Contrato
            </Button>
          </Box>
        </>
      ) : null}

      {!contract ? (
        <Box height={380}>
          <CircularProgress />
        </Box>
      ) : null}
    </Box>
  );
}

ContractDetails.propTypes = {
  project: PropTypes.shape({
    unity: PropTypes.string,
    hasDocumentTemplate: PropTypes.bool,
  }),
  contract: PropTypes.shape({
    property: PropTypes.shape({
      unity: PropTypes.string,
      hasDocumentTemplate: PropTypes.bool,
    }),
    customer: PropTypes.shape({
      cpf: PropTypes.string,
      name: PropTypes.string,
    }),
    createdAt: PropTypes.string,
    status: PropTypes.string,
    price: PropTypes.number,
    entrance: PropTypes.number,
    numberId: PropTypes.number,
    _id: PropTypes.string,
  }),
  onEdit: PropTypes.func.isRequired,
};

ContractDetails.defaultProps = {
  project: null,
  contract: null,
};
