import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import BaseDialog from 'components/Dialogs/BaseDialog';
import React, { useEffect, useState } from 'react';

export default function ChangePropertiesStatusDialog({
  open,
  onSubmit,
  onClose,
}) {
  const [status, setStatus] = useState('');
  const [error, setError] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!status) {
      setError(true);
      return;
    }
    onSubmit(status);
  };

  useEffect(() => {
    if (open) {
      setStatus('');
      setError(false);
    }
  }, [open]);

  return (
    <BaseDialog
      open={open}
      title="Editar situação"
      maxWidth="xs"
      onClose={onClose}
    >
      <form onSubmit={handleSubmit}>
        <Box mb={6}>
          <FormControl fullWidth>
            <InputLabel>Situação</InputLabel>
            <Select
              error={error}
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <MenuItem value="unavailable">Indisponível</MenuItem>
              <MenuItem value="available">Disponível</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            size="large"
            style={{ width: 208 }}
          >
            Salvar
          </Button>
        </Box>
      </form>
    </BaseDialog>
  );
}
