import React from 'react';
import { Box, IconButton, Grid } from '@material-ui/core';
import preventDefault from 'utils/preventDefault';
import PropTypes from 'prop-types';

import ICON_TYPES from '@constants/iconsPaths';
import Icon from 'components/Icon';
import SALES_CONTRACT_INVOICE_STATUS from '@constants/salesContract/invoice/status';

export default function SalesContractInvoicesListActions({
  data,
  emit,
  edit,
  print,
}) {
  const isInvoiceEmitted = data.isEmitted;
  const isInvoicePaid = data.status === SALES_CONTRACT_INVOICE_STATUS.paid;

  const showEmitAction = !isInvoiceEmitted && !isInvoicePaid;

  return (
    <Grid item xs={12} lg="auto">
      <Box display="flex" justifyContent="flex-end">
        {showEmitAction && (
          <IconButton
            title="Emitir"
            size="small"
            onClick={preventDefault(() => emit(data._id, data.projectId))}
          >
            <Icon src={ICON_TYPES.LIST.emit} size={21} />
          </IconButton>
        )}

        <Box display="flex" mx={1}>
          <IconButton
            title="Imprimir"
            size="small"
            onClick={preventDefault(() => print(data))}
          >
            <Icon src={ICON_TYPES.LIST.print} size={22} />
          </IconButton>
        </Box>
        {!isInvoiceEmitted && (
          <IconButton
            title="Editar"
            size="small"
            onClick={preventDefault(() => edit(data._id))}
          >
            <Icon src={ICON_TYPES.LIST.edit} size={22} />
          </IconButton>
        )}
      </Box>
    </Grid>
  );
}

SalesContractInvoicesListActions.propTypes = {
  emit: PropTypes.func,
  edit: PropTypes.func.isRequired,
  print: PropTypes.func.isRequired,
  data: PropTypes.shape({
    projectId: PropTypes.string,
    isEmitted: PropTypes.bool,
    _id: PropTypes.string,
  }).isRequired,
};

SalesContractInvoicesListActions.defaultProps = {
  emit: null,
};
