const UPDATE_PROJECT_CONFIGURATION_MUTATION = `mutation UpdateProjectConfigurationMutation (
        $projectId: ID!
        $entranceFee: Float!
        $annualQuota: Float!
        $installments: [Int!]!
        $discount: DiscountInput
        $cnpj: String
        $companyName: String
    ) {
        updateProjectConfiguration (
            projectId: $projectId
            data:{
              discount:$discount
              entranceFee: $entranceFee
              annualQuota: $annualQuota
              installments: $installments
              cnpj: $cnpj
              companyName:$companyName
            }
        )
    }
`;

export default UPDATE_PROJECT_CONFIGURATION_MUTATION;
