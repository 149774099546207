import React from 'react';
import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import Loading from 'components/Loading';
import TeamZeroStateImage from 'assets/images/ZeroStates/TeamZeroStateImage';
import { useInvitesListContext } from 'providers/ListProviders/InvitesListProvider.js';

export default function InvitesListZeroState({ children }) {
  const { variables, invites, search, isLoading } = useInvitesListContext();
  const hasData = Boolean(invites.length);
  const isFilterSet = search || variables.filterOptions.status;

  if (isLoading) {
    return <Loading height={100} />;
  }
  if (hasData > 0) {
    return children;
  }

  const mainMessage =
    isFilterSet && !hasData
      ? 'Nenhum convite encontrado. Tente uma nova pesquisa'
      : 'Você ainda não enviou nenhum convite';

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      mt={15}
    >
      <Box>
        <TeamZeroStateImage />
      </Box>
      <Box color="#495058" mt={5} width="40%">
        <Typography align="center" variant="h3">
          {mainMessage}
        </Typography>
      </Box>
    </Box>
  );
}

InvitesListZeroState.propTypes = {
  children: PropTypes.node.isRequired,
};
