import React from 'react';
import lightTheme from 'theme/lightTheme';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Home from 'pages/Home';
import Login from 'pages/Login';
import Account from 'pages/Account';
import Recover from 'pages/Recover';
import Recover2 from 'pages/Recover2';
import Invitation from 'pages/Invitation';
import ProjectMain from 'pages/ProjectMain';
import Verification from 'pages/Verification';
import ControlPanel from 'pages/ControlPanel/main';
import AuthProvider from 'providers/AuthProvider';

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <ThemeProvider theme={lightTheme}>
          <CssBaseline />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/login" component={Login} />
            <Route exact path="/invitation/:inviteId" component={Invitation} />
            <Route
              exact
              path="/verification/:verificationCode"
              component={Verification}
            />
            <Route exact path="/recover" component={Recover} />
            <Route exact path="/recover/:recoverCode" component={Recover2} />
            <Route exact path="/account" component={Account} />
            <Route path="/konoha" component={ControlPanel} />
            <Route path="/:projectSlug" component={ProjectMain} />
          </Switch>
        </ThemeProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
