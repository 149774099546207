import React from 'react';

import { PAYMENT_METHODS_LABELS } from '@constants/reservations/paymentMethods';

export default (discount) => {
  const formatLabel = (label, paymentType) => {
    const discountPercentage = discount[paymentType] * 100;
    const paymentMethodDiscount = `${label} (${discountPercentage}% de desconto)`;
    return discount[paymentType] > 0 ? paymentMethodDiscount : label;
  };

  return Object.entries(PAYMENT_METHODS_LABELS).map(([paymentType, label]) => (
    <option key={paymentType} value={paymentType}>
      {formatLabel(label, paymentType)}
    </option>
  ));
};
