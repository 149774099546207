const GET_RETURN_FILES_QUERY = `
        query getReturnFilesQuery (
            $projectId: ID!
            $page:Int
            $itemsPerPage:Int
            $search:String
        ) {
          getReturnFiles (
            projectId: $projectId
            page:$page
            itemsPerPage:$itemsPerPage
            search:$search
          ){
            returnFiles{
              _id
              projectId
              name
              fileId
              createdAt
              status
              message
            }
            pages
            page
          } 
        }
    `;

export default GET_RETURN_FILES_QUERY;
