import { Box, CircularProgress } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

function Loading({ height }) {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height={height}
    >
      <CircularProgress />
    </Box>
  );
}

Loading.propTypes = {
  height: PropTypes.number,
};

Loading.defaultProps = {
  height: 600,
};

export default Loading;
