/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useContext, useState } from 'react';

const RealEstateTabContext = createContext();

export default function RealEstateTabProvider({ children }) {
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [realEstateId, setRealEstateId] = useState(null);

  const openEditDialog = () => setShowEditDialog(true);

  const closeEditDialog = () => {
    setRealEstateId(null);
    setShowEditDialog(false);
  };

  const openCreateDialog = () => setShowCreateDialog(true);
  const closeCreateDialog = () => setShowCreateDialog(false);

  const selectRealEstate = (selectedRealEstate) => {
    setRealEstateId(selectedRealEstate._id);
    openEditDialog(true);
  };
  return (
    <RealEstateTabContext.Provider
      value={{
        showCreateDialog,
        showEditDialog,
        realEstateId,
        openCreateDialog,
        closeCreateDialog,
        openEditDialog,
        closeEditDialog,
        selectRealEstate,
      }}
    >
      {children}
    </RealEstateTabContext.Provider>
  );
}

export function useRealEstateTabContext() {
  return useContext(RealEstateTabContext);
}
