const TOGGLE_PROJECT_USER_ACCESS_MUTATION = `
  mutation ToggleProjectUserAccessMutation (
    $projectId: ID!
    $userId: ID!
  ) {
    toggleProjectUserAccess (
      projectId: $projectId
      userId: $userId
    )
  }
`;

export default TOGGLE_PROJECT_USER_ACCESS_MUTATION;
