import handlerError from 'utils/handleError';
import AlertModule from 'modules/AlertModule';

export default function errorHandler(error, type, history) {
  const propertyErrorHandlers = {
    NOT_AVAILABLE: AlertModule.onError(
      'Este imóvel está indisponível para ser reservado.'
    ),
    DEFAULT: AlertModule.onError(
      'Não foi possível localizar o imóvel a ser reservado.'
    ),
  };

  const reservationErrorHandlers = {
    NOT_AUTHENTICATED: () => {
      history.push('/login');
    },
    PROPERTY_NOT_FOUND: AlertModule.onError('O imóvel não foi encontrado.'),

    CUSTOMER_NOT_FOUND: AlertModule.onError('O cliente não foi encontrado.'),

    REALESTATE_NOT_FOUND: AlertModule.onError(
      'A imobiliária não foi encontrado.'
    ),
    REALESTATE_AGENT_NOT_FOUND: AlertModule.onError(
      'O corretor não foi encontrado.'
    ),
    INVALID_EXPIRATION: AlertModule.onError('Data de expiração inválida.'),

    PROPERTY_NOT_AVAILABLE: AlertModule.onError(
      'O imóvel não está disponível para ser reservado.'
    ),

    ALREADY_RESERVED: AlertModule.onError('O imóvel já foi reservado.'),

    LIMIT_EXCEEDED: AlertModule.onError('Limite de reservas atingido.'),

    INVALID_ENTRANCE: AlertModule.onError('Valor da entrada inválido.'),

    INVALID_FIRST_MONTHLY_INSTALLMENT: AlertModule.onError(
      'Primeira parcela mensal inválida.'
    ),

    INVALID_FIRST_ANNUALLY_INSTALLMENT: AlertModule.onError(
      'Primeira parcela anual inválida.'
    ),

    INVALID_PAYMENT_METHOD: AlertModule.onError(
      'Método de pagamento inválido.'
    ),

    INVALID_INSTALLMENTS: AlertModule.onError(
      'Quantidade de parcelas inválida.'
    ),

    DEFAULT: AlertModule.onError('Não foi possível criar a reserva.'),
  };

  const handler = {
    property: propertyErrorHandlers,
    reservation: reservationErrorHandlers,
  };

  handlerError(handler[type], error.message);
}
