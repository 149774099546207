import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core';
import CPFTextField from 'components/TextFields/CPFTextField';
import getCustomerByCPFQuery from 'queries/getCustomerByCPFQuery';
import React, { useEffect, useState } from 'react';
import api from 'services/api';
import PhoneTextField from 'components/TextFields/PhoneTextField';
import useThemeClasses from 'theme/useThemeClasses';
import { BsArrowRight } from 'react-icons/bs';
import validatePhone from 'utils/validatePhone';
import maritalStatus from '@constants/maritalStatus';

export default function CustomerFormStep({
  projectId,
  onBack,
  onSubmit,
  ...props
}) {
  const theme = useTheme();
  const themeClasses = useThemeClasses;
  const buttonClasses = themeClasses.button();

  const [customer, setCustomer] = useState({
    name: '',
    maritalStatus: 'single',
    occupation: '',
    phone: '',
    ...props.customer,
  });

  const [showError, setShowError] = useState(false);

  const handleSubmit = () => {
    setShowError(false);

    if (
      !customer.name ||
      !customer.maritalStatus ||
      !customer.occupation ||
      !customer.phone ||
      !validatePhone(customer.phone)
    ) {
      setShowError(true);
      return;
    }

    onSubmit(customer);
  };

  const handleBack = (event) => {
    if (event) event.preventDefault();
    if (onBack) onBack(customer);
  };

  const getCustomer = () => {
    const variables = {
      projectId,
      cpf: props.customer.cpf,
    };

    api
      .call(getCustomerByCPFQuery(variables))
      .then(({ getCustomerByCPF: selectedCustomer }) => {
        if (selectedCustomer) {
          setCustomer(selectedCustomer);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (!props.customer.name) {
      getCustomer();
    }

    // eslint-disable-next-line
  }, []);
  // eslint-disable-next-line
  if (!customer) return <></>;

  return (
    <Box>
      <Box mb={3}>
        <TextField
          autoFocus
          label="Nome completo"
          value={customer.name}
          onChange={(e) => setCustomer({ ...customer, name: e.target.value })}
          InputLabelProps={{ shrink: true }}
          error={showError && !customer.name}
          fullWidth
        />

        {showError && !customer.name ? (
          <Box mt={1}>
            <Typography
              variant="caption"
              style={{ color: theme.palette.error.main }}
            >
              Campo obrigatório
            </Typography>
          </Box>
        ) : null}
      </Box>

      <Box mb={3}>
        <CPFTextField
          margin="dense"
          label="CPF"
          type="text"
          value={customer.cpf}
          InputLabelProps={{ shrink: true }}
          fullWidth
          disabled
        />
      </Box>

      <Box mb={3}>
        <FormControl fullWidth>
          <InputLabel>Estado Civil</InputLabel>
          <Select
            native
            value={customer.maritalStatus}
            onChange={(e) =>
              setCustomer({ ...customer, maritalStatus: e.target.value })
            }
          >
            {maritalStatus.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box mb={3}>
        <TextField
          label="Profissão"
          value={customer.occupation}
          onChange={(e) =>
            setCustomer({ ...customer, occupation: e.target.value })
          }
          error={showError && !customer.occupation}
          InputLabelProps={{ shrink: true }}
          fullWidth
        />

        {showError && !customer.occupation ? (
          <Box mt={1}>
            <Typography
              variant="caption"
              style={{ color: theme.palette.error.main }}
            >
              Campo obrigatório
            </Typography>
          </Box>
        ) : null}
      </Box>

      <Box mb={3}>
        <PhoneTextField
          label="Telefone"
          type="text"
          value={customer.phone}
          placeholder="(00) 0 0000-0000"
          onChange={(_, value) => setCustomer({ ...customer, phone: value })}
          InputLabelProps={{ shrink: true }}
          error={
            showError && (!customer.phone || !validatePhone(customer.phone))
          }
          fullWidth
        />

        {showError && !customer.phone ? (
          <Box mt={1}>
            <Typography
              variant="caption"
              style={{ color: theme.palette.error.main }}
            >
              Campo obrigatório
            </Typography>
          </Box>
        ) : null}

        {showError && customer.phone && !validatePhone(customer.phone) ? (
          <Box mt={1}>
            <Typography
              variant="caption"
              style={{ color: theme.palette.error.main }}
            >
              Telefone inválido
            </Typography>
          </Box>
        ) : null}
      </Box>

      <Box display="flex" justifyContent="flex-end" mt={5}>
        <Button
          className={buttonClasses.linkButton}
          onClick={handleBack}
          color="secondary"
        >
          Voltar
        </Button>

        <Button
          className={buttonClasses.buttonWithIcon}
          endIcon={<BsArrowRight />}
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          style={{ width: 260, marginLeft: 8 }}
        >
          Próximo
        </Button>
      </Box>
    </Box>
  );
}
