const REMOVE_USER_FROM_PROJECT_MUTATION = `
    mutation RemoveUserFromProject(
      $projectId : ID!
      $userId: ID!
    ){
      removeUserFromProject(
        projectId: $projectId,
        userId: $userId
      )
    }
  `;
export default REMOVE_USER_FROM_PROJECT_MUTATION;
