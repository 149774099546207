const CHANGE_SALES_CONTRACT_STATUS_MUTATION = `
  mutation ChangeSalesContractStatus (
    $projectId: ID!
    $numberId: Int!
    $status: String!
  ) {
    changeSalesContractStatus (
      projectId: $projectId
      numberId: $numberId
      status: $status
    )
  }
`;

export default CHANGE_SALES_CONTRACT_STATUS_MUTATION;
