/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import ControlPanel from 'pages/ControlPanel';
import SuperAuthProvider from 'providers/SuperAuthProvider';

function Main() {
  return (
    <SuperAuthProvider>
      <ControlPanel />
    </SuperAuthProvider>
  );
}

export default Main;
