/* eslint-disable react/jsx-no-constructed-context-values */
import React from 'react';

import { ProjectContext } from 'providers/ProjectProvider';
import useGetUsers from './hooks/useGetUsers';
import useFilter from './hooks/useFilter';

const { createContext, useContext, useState } = require('react');

const UsersListContext = createContext();

export default function UsersListProvider({ children }) {
  const { project } = useContext(ProjectContext);
  const [search, setSearch] = useState('');
  const [variables, setVariables] = useState({
    projectId: project._id,
    page: 0,
    sortOptions: { field: 'name', direction: 'asc' },
    changedAt: Date.now(),
  });

  const { users, pages, isLoading } = useGetUsers(variables);

  const data = useFilter({
    users,
    search,
    page: variables.page,
    sortOptions: variables.sortOptions,
  });

  const setPage = (value) => setVariables({ ...variables, page: value });

  const setSort = (option) =>
    setVariables({
      ...variables,
      page: 0,
      sortOptions: {
        field: option.value,
        direction: option.reverse ? 'desc' : 'asc',
      },
    });

  const updateList = () =>
    setVariables({ ...variables, changedAt: Date.now() });

  return (
    <UsersListContext.Provider
      value={{
        data,
        pages,
        isLoading,
        search,
        variables,
        updateList,
        setPage,
        setSort,
        setSearch,
      }}
    >
      {children}
    </UsersListContext.Provider>
  );
}

export function useUsersListContext() {
  return useContext(UsersListContext);
}
