export default function getCustomerByCPFQuery({ projectId, cpf }) {
    return `
      query {
        getCustomerByCPF(
            projectId: "${projectId}"
            cpf: "${cpf}"
        ) {
          _id
          name
          cpf
          maritalStatus
          occupation
          phone
        }
      }
    `
}