/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Container, Grid } from '@material-ui/core';
import React, { useContext, useState, useEffect } from 'react';

import Header from 'components/Header';
import Loading from 'components/Loading';
import UserProfile from 'components/UserProfile';
import { SuperAuthContext } from 'providers/SuperAuthProvider';

import LoginAsForm from './components/LoginAsForm';
import LogoutAsForm from './components/LogoutAsForm';

function ControlPanel() {
  const { auth, authError, isLoading, authenticate } =
    useContext(SuperAuthContext);
  const access = auth?.access;

  const [userProfileAnchor, setUserProfileAnchor] = useState(null);

  useEffect(() => {
    authenticate();
  }, []);

  if (!auth || isLoading) return <Loading />;
  if (auth?.isLoggedAs) {
    return <LogoutAsForm username={access.username} />;
  }
  if (!access.isSuperAdmin || authError) return <>Forbidden!</>;

  return (
    <Box component="main">
      <Box mb={2.5}>
        <Header onShowUserProfile={(elem) => setUserProfileAnchor(elem)} />

        <UserProfile
          access={access}
          open={Boolean(userProfileAnchor)}
          anchor={userProfileAnchor}
          onClose={() => setUserProfileAnchor(null)}
        />
      </Box>

      <Container>
        This is Konoha!
        <br />
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <LoginAsForm />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default ControlPanel;
