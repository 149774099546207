import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  userModal: {
    '& .MuiAvatar-root': {
      width: 50,
      height: 50,
      backgroundColor: theme.palette.primary.main,
    },

    '& .MuiButton-root': {
      margin: `0 ${theme.spacing(1)}px`,
    },

    '& .MuiLink-root': {
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.fontSizeSmall,
      fontWeight: 500,
      color: theme.palette.text.primary,
    },

    '& .MuiList-root > div:focus': {
      outline: 0,
    },
  },

  currentSession: {
    padding: theme.spacing(3),
    boxShadow: `0 2px 6px ${theme.palette.divider}`,

    '& .wrapper': {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',

      '& .close': {
        position: 'absolute',
        right: theme.spacing(-2),
        top: theme.spacing(-2),
      },
    },
  },

  sessionList: {
    boxShadow: `0 2px 6px ${theme.palette.divider}`,
  },

  sessionListItem: {
    position: 'relative',

    '& .MuiAvatar-root': {
      width: 40,
      height: 40,
    },
  },

  addNewAccount: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    boxShadow: `0 2px 6px ${theme.palette.divider}`,
  },

  userProfilePaper: {
    transform: 'translateY(70px) !important',
    maxHeight: 'calc(100% - 146px)',
    '& > .MuiMenu-list': {
      padding: 0,
    },
  },
  userProfileMobilePaper: {},

  profileButton: {
    color: theme.palette.text.primary,

    '&:hover': {
      color: theme.palette.secondary.dark,
    },

    '& svg': {
      height: 24,
      width: 24,
    },

    '& .MuiTypography-root': {
      marginLeft: 8,
      fontSize: 14,
      transform: 'translateY(2px)',
    },
  },

  profileListItem: {
    color: theme.palette.text.primary,
    justifyContent: 'center',
    paddingTop: 0,
    paddingBottom: 0,
    marginTop: 16,

    '& .MuiListItemIcon-root': {
      minWidth: 40,
      color: 'inherit',

      '& svg': {
        height: 24,
        width: 24,
      },
    },

    '& .MuiTypography-root': {
      fontSize: 14,
    },

    '&:hover': {
      color: theme.palette.secondary.dark,
      background: 'none',
    },

    '&.active': {
      color: theme.palette.primary.main,
    },
  },
}));
