const createSalesContractInvoiceQuery = `
  mutation CreateSalesContractInvoice (
    $projectId: ID!
    $contractNumberId: Int!
    $paymentMethod: String!
    $value: Float!
    $discount: Float!
    $dueDate: String!
    $description: String!
  ) {
    createSalesContractInvoice (
      projectId: $projectId
      contractNumberId: $contractNumberId
      paymentMethod: $paymentMethod
      value: $value
      discount: $discount
      dueDate: $dueDate
      description: $description
    ) {
      _id
    }
  }
`;

export default createSalesContractInvoiceQuery;
