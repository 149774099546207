import { FormControl, MenuItem, Select } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useStyles } from './styles';

export default function YearSelect({ value, onChange }) {
  const classes = useStyles();

  const options = useMemo(() => {
    const startYear = 2000;
    const count = 100;

    return new Array(count).fill(0).map((_, index) => startYear + index);
  }, []);

  return (
    <FormControl className={classes.dateSelect} style={{ width: 65 }}>
      <Select
        value={value}
        onChange={onChange}
        MenuProps={{
          className: classes.dateSelectMenuList,
          variant: 'selectedMenu',
          anchorReference: null,
          anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
        }}
        displayEmpty
      >
        <MenuItem value="none">Ano</MenuItem>

        {options.map((year) => (
          <MenuItem key={year} value={year}>
            {year}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
