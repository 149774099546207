import { Box } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import GenerateRemittancePopup from 'components/Popups/GenerateRemittancePopup';
import NewReservationPopup from 'components/Popups/NewReservationPopup';
import useStyles from './styles';

export default function SalesContractsPopup({
  hideNewReservationPopup,
  hideRemittancePopup,
  openNewReservationModal,
  onCreateRemittance,
}) {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box className={classes.selectedInvoicesContainer}>
        <NewReservationPopup
          hide={hideNewReservationPopup}
          onClick={openNewReservationModal}
        />
      </Box>
      <GenerateRemittancePopup
        hide={hideRemittancePopup}
        onCreate={onCreateRemittance}
      />
    </Box>
  );
}

SalesContractsPopup.propTypes = {
  hideRemittancePopup: PropTypes.bool.isRequired,
  hideNewReservationPopup: PropTypes.bool.isRequired,
  openNewReservationModal: PropTypes.func.isRequired,
  onCreateRemittance: PropTypes.func.isRequired,
};

SalesContractsPopup.defaultProps = {};
