import React from 'react';
import { useTheme } from '@material-ui/core';

import { getSalesContractInvoiceColors } from './BaseStatusLabel/colors';
import { salesContractInvoiceStatusTexts } from './BaseStatusLabel/texts';
import BaseStatusLabel from './BaseStatusLabel';

export default function SalesContractInvoiceStatusLabel({
  status,
  size = 'small',
}) {
  const theme = useTheme();
  const color = getSalesContractInvoiceColors(theme)[status];

  return (
    <BaseStatusLabel
      size={size}
      label={salesContractInvoiceStatusTexts[status]}
      color={color}
    />
  );
}
