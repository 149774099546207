import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  documentContainer: {
    display: 'flex',
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 10,
    paddingBottom: 10,
    justifyContent: 'space-between',
    alignItems: 'center',
    color: theme.palette.grey[200],
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.grey[700],
      color: theme.palette.primary.main,
      borderRadius: 8,
    },
  },
  documentIcon: {
    padding: 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    backgroundColor: theme.palette.grey[600],
    marginRight: 5,
  },
}));

export default useStyles;
