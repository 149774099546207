import React, { useState } from 'react';
import { Box, Switch } from '@material-ui/core';

import useQuery from 'hooks/useQuery';
import AlertModule from 'modules/AlertModule';
import TOGGLE_PROJECT_USER_ACCESS_MUTATION from 'queries/toggleProjectUserAccessQuery';

export default function UserSwitch({ projectId, active, user }) {
  const [isChecked, setIsChecked] = useState(Boolean(active));
  const [executeToggleProjectUserAccess] = useQuery(
    TOGGLE_PROJECT_USER_ACCESS_MUTATION
  );

  const actionTitle = isChecked ? 'Desativar acesso' : 'Ativar acesso';

  const onChange = () => {
    setIsChecked((value) => !value);
    executeToggleProjectUserAccess({ projectId, userId: user._id }).catch(
      AlertModule.onError('Não foi possível modificar o acesso.')
    );
  };

  return (
    <Box title={actionTitle}>
      <Switch
        checked={isChecked}
        onClick={(e) => e.stopPropagation()}
        onChange={onChange}
        color="primary"
      />
    </Box>
  );
}
