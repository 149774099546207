import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useCallback,
} from 'react';
import { useHistory } from 'react-router-dom';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';

import { AuthContext } from 'providers/AuthProvider';
import { ProjectContext } from 'providers/ProjectProvider';
import AlertModule from 'modules/AlertModule';

import GET_PROPERTY_QUERY from 'queries/getPropertyQuery';
import CREATE_RESERVATION_MUTATION from 'queries/createReservationMutation';
import FREEZE_PROPERTY_PRICE_MUTATION from 'queries/freezePropertyPriceMutation';
import useQuery from 'hooks/useQuery';

import { PAYMENT_METHODS } from '@constants/reservations/paymentMethods';
import SYSTEM_ACCESS_ENTITY_TYPES from '@constants/systemAccess/entityTypes';
import WebSocketController from 'utils/WebSocketController';
import DueDateStep from './Steps/DueDateStep';
import ResumeStep from './Steps/ResumeStep';
import PaymentMethodStep from './Steps/PaymentMethodStep';
import CustomerFormStep from './Steps/CustomerFormStep';
import DocumentStep from './Steps/DocumentStep';
import errorHandler from './Handlers/errorHandler';

const socket = new WebSocketController();

export default function MakeReservationBase({
  open,
  propertyId,
  onClose,
  onStepChange,
  onPropertyReserved,
}) {
  const history = useHistory();

  const isMountedRef = useRef(false);

  const { project, discount } = useContext(ProjectContext);
  const { auth, authenticate } = useContext(AuthContext);
  const [isReserving, setIsReserving] = useState(false);
  const [isRealEstateAccess, setIsRealEstateAccess] = useState(false);
  const [step, setStep] = useState(0);
  const [property, setProperty] = useState(null);

  const [price, setPrice] = useState(0);
  const [entranceFee, setEntranceFee] = useState(0);
  const [annualQuota, setAnnualQuota] = useState(0);
  const [installmentOptions, setInstallmentsOptions] = useState([]);

  const [freezedPriceToken, setFreezedPriceToken] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('monthly');
  const [entrance, setEntrance] = useState(0);
  const [installments, setInstallments] = useState(0);
  const [monthlyInstallment, setMonthlyInstallment] = useState(null);
  const [annuallyInstallment, setAnnuallyInstallment] = useState(null);
  const [monthlyDueDay, setMonthlyDueDay] = useState(5);
  const [realEstateAgent, setRealEstateAgent] = useState(null);
  const [firstMonthlyInstallmentDate, setFirstMonthlyInstallmentDate] =
    useState('');
  const [annuallyDueDay, setAnnuallyDueDay] = useState(5);
  const [firstAnnuallyInstallmentDate, setFirstAnnuallyInstallmentDate] =
    useState('');
  const [customer, setCustomer] = useState({});
  const [observation, setObservation] = useState(null);

  const [updatedPropertyId, setUpdatedPropertyId] = useState(null);
  const [executeFreezePropertyPrice] = useQuery(FREEZE_PROPERTY_PRICE_MUTATION);
  const [executeGetProperty] = useQuery(GET_PROPERTY_QUERY);
  const [executeCreateReservation] = useQuery(CREATE_RESERVATION_MUTATION);

  const freezePropertyPrice = (projectIdParam, propertyIdParam) =>
    executeFreezePropertyPrice({
      projectId: projectIdParam,
      propertyId: propertyIdParam,
    }).then(({ freezePropertyPrice: data }) => {
      if (!isMountedRef.current) return;

      setPrice(data.price);
      setEntranceFee(data.entranceFee);
      setAnnualQuota(data.annualQuota);
      setInstallmentsOptions(data.installments);
      setFreezedPriceToken(data.token);
    });

  const getProperty = useCallback(
    (selectedPropertyId) => {
      if (!selectedPropertyId) return;

      executeGetProperty({ id: selectedPropertyId })
        .then(async (data) => {
          if (!isMountedRef.current) return;

          const { getProperty: selectedProperty } = data;

          if (selectedProperty.status !== 'available') {
            throw new Error('NOT_AVAILABLE');
          }

          setProperty(selectedProperty);

          await freezePropertyPrice(
            selectedProperty.projectId,
            selectedProperty._id
          );

          setStep(1);
        })
        .catch((error) => {
          errorHandler(error, 'property', history);
          onClose();
        });
    },
    [onClose]
  );

  const handleSubmit = () => {
    setIsReserving(true);
    const isCashPayment = paymentMethod === PAYMENT_METHODS.CASH;
    const variables = {
      projectId: property.projectId,
      propertyId: property._id,
      customerName: customer.name,
      customerCpf: customer.cpf,
      customerMaritalStatus: customer.maritalStatus,
      customerOccupation: customer.occupation,
      customerPhone: customer.phone,
      freezedPriceToken,
      paymentMethod,
      realEstateAgentId: realEstateAgent?._id,
      entrance: isCashPayment ? 0 : Number(entrance),
      installments: isCashPayment ? 1 : Number(installments),
      observation,
    };

    if (paymentMethod !== PAYMENT_METHODS.CASH) {
      variables.firstMonthlyInstallmentDate = `${firstMonthlyInstallmentDate}-${String(
        monthlyDueDay
      ).padStart(2, '0')}`;
    }

    if (paymentMethod === PAYMENT_METHODS.ANNUALLY) {
      variables.firstAnnuallyInstallmentDate = `${firstAnnuallyInstallmentDate}-${String(
        annuallyDueDay
      ).padStart(2, '0')}`;
    }
    AlertModule.wait();
    executeCreateReservation(variables)
      .then(({ createReservation: data }) => {
        AlertModule.close();
        onPropertyReserved(data);
        onClose();
      })
      .catch((error) => {
        setIsReserving(false);
        errorHandler(error, 'reservation', history);
      });
  };

  useEffect(() => {
    isMountedRef.current = true;
    authenticate();

    return () => {
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (open) {
      getProperty(propertyId);
    } else {
      setStep(0);
      setProperty(null);
    }
  }, [open, propertyId]);

  useEffect(() => {
    socket.onReservationCreated((reservation) => {
      setUpdatedPropertyId(reservation.propertyId);
    });

    socket.onPropertyUpdated((selectedProperty) => {
      setUpdatedPropertyId(selectedProperty._id);
    });

    socket.onPropertyDeleted((selectedPropertyId) => {
      setUpdatedPropertyId(selectedPropertyId);
    });

    return () => socket.destroy();
  }, []);

  useEffect(() => {
    if (property && updatedPropertyId) {
      const isSameProperty = property._id === updatedPropertyId;

      if (isSameProperty && isReserving) {
        getProperty(property._id);
      }

      setUpdatedPropertyId(null);
    }
  }, [updatedPropertyId]);

  useEffect(() => {
    if (onStepChange) onStepChange(step);
  }, [step, onStepChange]);

  useEffect(() => {
    if (auth.access.entityType !== SYSTEM_ACCESS_ENTITY_TYPES.USER) {
      if (auth.realEstate) {
        setIsRealEstateAccess(true);
      }
    }
  }, [auth, onClose]);

  if (!open) return null;

  return (
    <Box>
      {step === 1 ? (
        <PaymentMethodStep
          price={price}
          projectId={project._id}
          entranceFee={entranceFee}
          annualQuota={annualQuota}
          installmentOptions={installmentOptions}
          realEstateAgent={realEstateAgent}
          isRealEstateAccess={isRealEstateAccess}
          paymentMethod={paymentMethod}
          entrance={entrance}
          installments={installments}
          onBack={() => {
            onClose();
          }}
          onSubmit={(options) => {
            setPaymentMethod(options.paymentMethod);
            setEntrance(options.entrance);
            setInstallments(options.installments);
            setMonthlyInstallment(options.monthlyInstallment);
            setAnnuallyInstallment(options.annuallyInstallment);
            setRealEstateAgent(options.realEstateAgent);
            if (options.paymentMethod === PAYMENT_METHODS.CASH) {
              setStep(3);
            } else {
              setStep(2);
            }
          }}
        />
      ) : null}

      {step === 2 ? (
        <DueDateStep
          paymentMethod={paymentMethod}
          monthlyDueDay={monthlyDueDay}
          firstMonthlyInstallmentDate={firstMonthlyInstallmentDate}
          annuallyDueDay={annuallyDueDay}
          firstAnnuallyInstallmentDate={firstAnnuallyInstallmentDate}
          onBack={(options) => {
            setMonthlyDueDay(options.monthlyDueDay);
            setFirstMonthlyInstallmentDate(options.firstMonthlyInstallmentDate);
            setAnnuallyDueDay(options.annuallyDueDay);
            setFirstAnnuallyInstallmentDate(
              options.firstAnnuallyInstallmentDate
            );
            setStep(1);
          }}
          onSubmit={(options) => {
            setMonthlyDueDay(options.monthlyDueDay);
            setFirstMonthlyInstallmentDate(options.firstMonthlyInstallmentDate);
            setAnnuallyDueDay(options.annuallyDueDay);
            setFirstAnnuallyInstallmentDate(
              options.firstAnnuallyInstallmentDate
            );
            setStep(3);
          }}
        />
      ) : null}

      {step === 3 ? (
        <DocumentStep
          onBack={() => {
            if (paymentMethod === 'cash') {
              setStep(1);
            } else {
              setStep(2);
            }
          }}
          onSubmit={(document) => {
            if (customer.cpf !== document) {
              setCustomer({ cpf: document });
            }

            setStep(4);
          }}
        />
      ) : null}

      {step === 4 ? (
        <CustomerFormStep
          projectId={property.projectId}
          customer={customer}
          onBack={(selectedCustomer) => {
            setCustomer(selectedCustomer);
            setStep(3);
          }}
          onSubmit={(selectedCustomer) => {
            setCustomer(selectedCustomer);
            setStep(5);
          }}
        />
      ) : null}

      {step === 5 ? (
        <ResumeStep
          property={property}
          customer={customer}
          payment={{
            paymentMethod,
            entrance,
            installments,
            monthlyInstallment,
            annuallyInstallment,
            monthlyDueDay,
            firstMonthlyInstallmentDate,
            annuallyDueDay,
            firstAnnuallyInstallmentDate,
            discount: discount[paymentMethod],
          }}
          observation={observation}
          onObservationChange={(newObservation) => {
            setObservation(newObservation);
          }}
          onBack={() => {
            setStep(4);
          }}
          onSubmit={handleSubmit}
        />
      ) : null}
    </Box>
  );
}

MakeReservationBase.propTypes = {
  open: PropTypes.bool.isRequired,
  propertyId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onStepChange: PropTypes.func.isRequired,
  onPropertyReserved: PropTypes.func.isRequired,
};

MakeReservationBase.defaultProps = {
  propertyId: '',
};
