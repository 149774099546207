import * as React from 'react';

function PriceFilterIcon() {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 4v1M12.243 3.757a6 6 0 11-8.485 8.486 6 6 0 018.485-8.486M8 12v-1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 9.503v0a1.5 1.5 0 001.5 1.5h1.095c.776 0 1.405-.628 1.405-1.404v0c0-.644-.438-1.206-1.063-1.362l-1.874-.47A1.404 1.404 0 016 6.405v0C6 5.629 6.629 5 7.405 5H8.5A1.5 1.5 0 0110 6.5v0"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default PriceFilterIcon;
