const getPropertyColors = (theme) => theme.palette.propertyStatus;
const getReservationColors = (theme) => theme.palette.reservationStatus;
const getInviteColors = (theme) => theme.palette.inviteStatus;
const getSalesContractColors = (theme) => theme.palette.salesContractStatus;
const getSalesContractInvoiceColors = (theme) =>
  theme.palette.salesContractInvoiceStatus;
const getReturnFileColors = (theme) => theme.palette.returnFileStatus;
const getOccurrenceTypesColors = (theme) =>
  theme.palette.returnFileOccurrenceStatus;

export {
  getPropertyColors,
  getReservationColors,
  getInviteColors,
  getSalesContractColors,
  getSalesContractInvoiceColors,
  getReturnFileColors,
  getOccurrenceTypesColors,
};
