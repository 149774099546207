import { Box, IconButton } from '@material-ui/core';
import React from 'react';
import ICON_TYPES from '@constants/iconsPaths';
import Icon from 'components/Icon';
import PropTypes from 'prop-types';
import preventDefault from 'utils/preventDefault';
import AlertModule from 'modules/AlertModule';
import axios from 'axios';

export default function SalesContractsListActions({
  showPrintContractButton,
  onEdit,
  contract,
}) {
  const printContract = () => {
    const SERVER_URL = process.env.REACT_APP_API_SERVER;
    const URL = `${SERVER_URL}/uploads/documents/sales-contract/${contract._id}`;
    axios
      .get(URL, { withCredentials: true })
      .then(() =>
        window.open(
          `${SERVER_URL}/uploads/documents/sales-contract/${contract._id}`
        )
      )
      .catch((error) => {
        AlertModule.error(error.response.data);
      });
  };

  return (
    <Box display="flex" justifyContent="flex-end">
      <Box mr={1} hidden={!onEdit}>
        <IconButton
          title="Editar"
          size="small"
          onClick={preventDefault(() => onEdit(contract))}
        >
          <Icon src={ICON_TYPES.LIST.edit} size={21} />
        </IconButton>
      </Box>
      <Box hidden={!showPrintContractButton}>
        <IconButton
          title="Imprimir"
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            printContract(contract);
          }}
        >
          <Icon src={ICON_TYPES.LIST.print} size={24} />
        </IconButton>
      </Box>
    </Box>
  );
}

SalesContractsListActions.propTypes = {
  showPrintContractButton: PropTypes.bool.isRequired,
  contract: PropTypes.shape({
    _id: PropTypes.string,
  }).isRequired,
  onEdit: PropTypes.func.isRequired,
};
