import React, { useCallback, useContext } from 'react';
import { createFilterOptions } from '@material-ui/lab';
import PropTypes from 'prop-types';

import { AuthContext } from 'providers/AuthProvider';

import GET_REAL_ESTATE_AGENTS_QUERY from 'queries/getRealEstateAgentsQuery';
import useQuery from 'hooks/useQuery';

import BaseAutoComplete from './BaseAutoComplete';

export default function RealEstateAgentAutoComplete({
  projectId,
  label,
  value,
  onChange,
  placeholder,
  required,
}) {
  const { auth } = useContext(AuthContext);
  const realEstateId = auth?.realEstate?._id || null;

  const [executeGetRealEstateAgents] = useQuery(GET_REAL_ESTATE_AGENTS_QUERY);

  const handleChange = (_, _value) => {
    if (_value && _value._id) {
      onChange(_value);
    } else {
      onChange(null);
    }
  };

  const handleQuery = useCallback(
    (inputValue) => {
      const variables = {
        projectId,
        filterOptions: { realEstate: realEstateId },
        search: inputValue,
      };

      return new Promise((resolve, reject) => {
        executeGetRealEstateAgents(variables)
          .then(({ getRealEstateAgents: data }) => {
            resolve(data.realEstateAgents);
          })
          .catch((err) => reject(err));
      });
    },
    [projectId]
  );

  const getOptionLabel = (option) => {
    if (!option) return '';
    return `${option.name} - ${option.creci}`;
  };

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option) => `${option.name} - ${option.creci}`,
  });

  const getOptionSelected = (option, _value) => option?._id === _value?._id;

  return (
    <BaseAutoComplete
      required={required}
      placeholder={placeholder}
      label={label}
      value={value}
      onChange={handleChange}
      onQuery={handleQuery}
      getOptionLabel={getOptionLabel}
      filterOptions={filterOptions}
      getOptionSelected={getOptionSelected}
    />
  );
}

RealEstateAgentAutoComplete.propTypes = {
  projectId: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
};

RealEstateAgentAutoComplete.defaultProps = {
  label: '',
  value: null,
  placeholder: '',
  required: false,
};
