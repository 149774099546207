/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useContext, useState } from 'react';

import { ProjectContext } from 'providers/ProjectProvider';
import useGetRemittances from './hooks/useGetRemittances';
import useWebHooks from './hooks/useWebHooks';

const RemittancesListContext = createContext();

export default function RemittancesListProvider({ children }) {
  const { project } = useContext(ProjectContext);
  const [search, setSearch] = useState('');
  const [variables, setVariables] = useState({
    projectId: project._id,
    page: 0,
    changedAt: Date.now(),
  });
  const { remittances, pages, isLoading } = useGetRemittances(
    variables,
    search
  );

  const setPage = (value) => setVariables({ ...variables, page: value });

  const updateList = () =>
    setVariables({ ...variables, changedAt: Date.now() });

  useWebHooks(updateList);

  return (
    <RemittancesListContext.Provider
      value={{
        remittances,
        pages,
        isLoading,
        search,
        variables,
        updateList,
        setPage,
        setSearch,
      }}
    >
      {children}
    </RemittancesListContext.Provider>
  );
}

export function useRemittancesListContext() {
  return useContext(RemittancesListContext);
}
