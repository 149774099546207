import axios from 'axios';
import urlBuilder from 'utils/urlBuilder';
import { exportPdfFile, exportTextFile } from 'utils/exportFile';
import EnvironmentModule from 'modules/EnvironmentModule';
import Url from 'services/url';
import onArrayBufferError from '../helpers/onArrayBufferError';
import exportMethods from './exportMethods';

const API_SERVER = EnvironmentModule.get('API_SERVER');
const serverUrl = Url(API_SERVER);
const DOWNLOAD_API_ENDPOINT = serverUrl.appendPath('/downloads').toString();
const UPLOAD_API_ENDPOINT = serverUrl.appendPath('/uploads').toString();

const RestApi = {
  uploadMap(projectId, file, recalculateMarks) {
    const formData = new FormData();
    formData.append('map', file);
    formData.append('recalculate', recalculateMarks);
    const url = `${UPLOAD_API_ENDPOINT}/map/${projectId}`;

    return axios.post(url, formData, {
      withCredentials: true,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  uploadReturnFile(projectId, file) {
    const formData = new FormData();
    formData.append('return', file);

    const url = `${UPLOAD_API_ENDPOINT}/return/${projectId}`;
    return axios.post(url, formData, {
      withCredentials: true,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  getMap(projectId) {
    const url = urlBuilder.map(projectId);
    return axios.get(url, {
      withCredentials: true,
    });
  },
  getRemittance(projectId, file) {
    const [fileName, fileExt] = file.split('.');
    const url = `${UPLOAD_API_ENDPOINT}/remittance/${projectId}/${fileName}.${fileExt}`;
    axios
      .get(url, {
        withCredentials: true,
        responseType: 'blob',
      })
      .then(({ data }) => {
        exportTextFile(file, data);
      });
  },

  downloadReceipt(reservation) {
    const url = `${DOWNLOAD_API_ENDPOINT}/receipt/${reservation._id}`;
    return axios
      .get(url, {
        withCredentials: true,
        responseType: 'arraybuffer',
      })
      .then(({ data }) => {
        const blob = new Blob([data]);
        exportPdfFile(`Recibo da reserva #${reservation.numberId}.pdf`, blob);
      })
      .catch(onArrayBufferError);
  },
  downloadTickets(projectId, contractNumberId, invoicesIds) {
    const url = `${DOWNLOAD_API_ENDPOINT}/tickets`;

    return axios
      .post(
        url,
        {
          projectId,
          contractNumberId,
          invoicesIds,
        },
        {
          withCredentials: true,
          responseType: 'arraybuffer',
        }
      )
      .then(({ data }) => {
        const blob = new Blob([data]);
        exportPdfFile(`boletos_${Date.now()}.pdf`, blob);
      })
      .catch(onArrayBufferError);
  },

  ...exportMethods(axios),
};

export default RestApi;
