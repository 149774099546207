import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
    predefinedDateButton: {
        margin: theme.spacing(0.5),
        padding: '0px 16px',
        height: 32,

        '&.selected': {
            borderColor: theme.palette.primary.main,
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.main + '14',
            ...theme.typography.caption
        }
    }
}))