import InstallmentCalculatorModule from 'modules/InstallmentCalculatorModule';
import { PAYMENT_METHODS } from '@constants/reservations/paymentMethods';
import convertUTCtoLocal from './convertUTCtoLocal';

class ReservationDataPreparer {
  constructor(reservation) {
    this.reservation = reservation;
    this.installmentCalculator = new InstallmentCalculatorModule({
      paymentMethod: reservation.paymentMethod,
      totalPrice: reservation.price,
      entrance: reservation.entrance,
      annualQuota: reservation.annualQuota,
    });
  }

  isPaymentMethodCash() {
    return this.reservation.paymentMethod === PAYMENT_METHODS.CASH;
  }

  isPaymentMethodAnnually() {
    return this.reservation.paymentMethod === PAYMENT_METHODS.ANNUALLY;
  }

  getExpirationDate() {
    if (!this.reservation.expiration) return null;
    return convertUTCtoLocal(this.reservation.expiration);
  }

  getMonthlyInstallment() {
    if (this.isPaymentMethodCash()) return null;

    const monthlyInstallment =
      this.installmentCalculator.calculateMonthlyInstallment(
        this.reservation.installments
      );
    return monthlyInstallment;
  }

  getFirstMonthlyInstallmentDate() {
    if (this.isPaymentMethodCash()) return null;

    const firstMonthlyInstallmentDate = new Date(
      this.reservation.firstMonthlyInstallmentDate
    )
      .toISOString()
      .split('T')[0]
      .slice(0, -3);

    return firstMonthlyInstallmentDate;
  }

  getMonthlyDueDay() {
    if (this.isPaymentMethodCash()) return null;
    const monthlyDueDay = new Date(this.reservation.firstMonthlyInstallmentDate)
      .toISOString()
      .split('T')[0]
      .slice(-2);
    return monthlyDueDay;
  }

  getAnnuallyInstallment() {
    if (!this.isPaymentMethodAnnually()) return null;

    const annuallyInstallment =
      this.installmentCalculator.calculateAnnuallyInstallment(
        this.reservation.installments
      );
    return annuallyInstallment;
  }

  getFirstAnnuallyInstallmentDate() {
    if (!this.isPaymentMethodAnnually()) return null;

    const firstAnnuallyInstallmentDate = new Date(
      this.reservation.firstAnnuallyInstallmentDate
    )
      .toISOString()
      .split('T')[0]
      .slice(0, -3);

    return firstAnnuallyInstallmentDate;
  }

  getAnnuallyDueDay() {
    if (!this.isPaymentMethodAnnually()) return null;
    const annuallyDueDay = new Date(
      this.reservation.firstAnnuallyInstallmentDate
    )
      .toISOString()
      .split('T')[0]
      .slice(-2);
    return annuallyDueDay;
  }

  getPreparedData() {
    return {
      ...this.reservation,
      monthlyInstallment: this.getMonthlyInstallment(),
      monthlyDueDay: Number(this.getMonthlyDueDay()),
      firstMonthlyInstallmentDate: this.getFirstMonthlyInstallmentDate(),
      annuallyInstallment: this.getAnnuallyInstallment(),
      annuallyDueDay: Number(this.getAnnuallyDueDay()),
      firstAnnuallyInstallmentDate: this.getFirstAnnuallyInstallmentDate(),
      expiration: this.getExpirationDate(),
    };
  }
}

export default ReservationDataPreparer;
