import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  fieldText: {
    ...theme.typography.body2,
    color: theme.palette.grey[300],
  },
  valueText: {
    ...theme.typography.body2,
    color: theme.palette.secondary.dark,
  },
  title: {
    ...theme.typography.body1,
    color: theme.palette.secondary.dark,
    fontWeight: '700',
  },
}));

export default {};
