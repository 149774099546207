import React from 'react';

function FilterIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="16"
      fill="none"
      viewBox="0 0 15 16"
    >
      <path
        stroke="#3D4657"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.394"
        d="M13.014 4.746H1.859M11.154 8H3.718M9.296 11.254H5.578"
      />
    </svg>
  );
}

export default FilterIcon;
