import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import BaseDialog from 'components/Dialogs/BaseDialog';
import PercentField from 'components/Fields/PercentField';
import CurrencyTextField from 'components/TextFields/CurrencyTextField';
import createSalesContractInvoiceQuery from 'queries/createSalesContractInvoiceQuery';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import api from 'services/api';
import swalFire from 'utils/swalFire';
import PropTypes from 'prop-types';
import DescriptionError from './errors/DescriptionError';
import DiscountError from './errors/DiscountError';
import DueDateError from './errors/DueDateError';
import ValueError from './errors/ValueError';
import validateFields from './helpers/validate-fields';

function NewInvoiceModal({ open, contract, onClose }) {
  const history = useHistory();

  const openRef = useRef(false);

  const [invoice, setInvoice] = useState({
    paymentMethod: 'ticket',
    value: 0,
    discount: 0,
    dueDate: null,
    description: '',
  });

  const [valueErr, setValueErr] = useState(false);
  const [discountErr, setDiscountErr] = useState(false);
  const [dueDateErr, setDueDateErr] = useState(false);
  const [descriptionErr, setDescriptionErr] = useState(false);

  const handleSubmit = () => {
    const errors = validateFields(invoice);

    if (errors.any) {
      setValueErr(errors.valueErr);
      setDiscountErr(errors.discountErr);
      setDueDateErr(errors.dueDateErr);
      setDescriptionErr(errors.descriptionErr);
      return;
    }

    const discount = invoice.discount ? Number(invoice.discount) / 100 : 0;

    const variables = {
      ...invoice,
      projectId: contract.projectId,
      contractNumberId: contract.numberId,
      discount,
    };

    api
      .call(createSalesContractInvoiceQuery, variables)
      .then(() => {
        swalFire('Título criado com sucesso', '', 'success').then(() =>
          onClose()
        );
      })
      .catch((err) => {
        switch (err.message) {
          case 'NOT_AUTHENTICATED': {
            history.push('/login');
            break;
          }

          default: {
            swalFire('Não foi possível criar o título', '', 'error');
          }
        }
      });
  };

  useEffect(() => {
    if (open !== openRef && open) {
      setInvoice({
        paymentMethod: 'ticket',
        value: 0,
        discount: 0,
        dueDate: null,
        description: '',
      });
    }
  }, [open]);

  return (
    <BaseDialog
      open={open}
      title="Criar título único"
      maxWidth="xs"
      onClose={onClose}
    >
      <Box mb={3}>
        <FormControl fullWidth>
          <InputLabel required>Tipo de pagamento</InputLabel>
          <Select
            value={invoice.paymentMethod || ''}
            onChange={(e) =>
              setInvoice({ ...invoice, paymentMethod: e.target.value })
            }
          >
            <MenuItem value="ticket">Boleto</MenuItem>
            <MenuItem value="pix">Pix</MenuItem>
            <MenuItem value="bank_transfer">Transferência bancária</MenuItem>
            <MenuItem value="debit_card">Cartão de débito</MenuItem>
            <MenuItem value="credit_card">Cartão de crédito</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Box mb={3}>
        <CurrencyTextField
          label="Valor"
          value={invoice.value || ''}
          placeholder="R$ 0,00"
          onChange={(_, value) => setInvoice({ ...invoice, value })}
          InputLabelProps={{ required: true, shrink: true }}
          fullWidth
          error={Boolean(valueErr)}
        />

        <Box mt={1}>
          <ValueError message={valueErr} />
        </Box>
      </Box>

      <Box mb={3}>
        <PercentField
          label="Desconto (%)"
          value={invoice.discount || ''}
          onChange={(e) => setInvoice({ ...invoice, discount: e.target.value })}
          InputLabelProps={{ shrink: true }}
          fullWidth
          error={Boolean(discountErr)}
        />

        <Box mt={1}>
          <DiscountError message={discountErr} />
        </Box>
      </Box>

      <Box mb={3}>
        <TextField
          type="date"
          label="Data de vencimento"
          value={invoice.dueDate || ''}
          onChange={(e) => setInvoice({ ...invoice, dueDate: e.target.value })}
          InputLabelProps={{ required: true, shrink: true }}
          fullWidth
          error={Boolean(dueDateErr)}
        />

        <Box mt={1}>
          <DueDateError message={dueDateErr} />
        </Box>
      </Box>

      <Box mb={3}>
        <TextField
          label="Detalhes"
          value={invoice.description || ''}
          placeholder="Digite aqui"
          onChange={(e) =>
            setInvoice({ ...invoice, description: e.target.value })
          }
          InputLabelProps={{ required: true, shrink: true }}
          fullWidth
          error={Boolean(descriptionErr)}
        />

        <Box mt={1}>
          <DescriptionError message={descriptionErr} />
        </Box>
      </Box>

      <Box display="flex" justifyContent="flex-end">
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Salvar
        </Button>
      </Box>
    </BaseDialog>
  );
}

NewInvoiceModal.propTypes = {
  open: PropTypes.bool.isRequired,
  contract: PropTypes.shape({
    projectId: PropTypes.string,
    numberId: PropTypes.number,
  }),
  onClose: PropTypes.func.isRequired,
};

NewInvoiceModal.defaultProps = {
  contract: null,
};

export default NewInvoiceModal;
