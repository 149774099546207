/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';
import api from 'services/api';
import formatCnpj from 'utils/formatCnpj';
import { createFilterOptions } from '@material-ui/lab';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import { GET_REAL_ESTATES_QUERY_2 } from 'queries/getRealEstatesQuery';
import BaseAutoComplete from './BaseAutoComplete';

const debouncedGetRealEstates = debounce(
  (variables, resolve, reject) =>
    api.call(GET_REAL_ESTATES_QUERY_2, variables).then(resolve).catch(reject),
  300
);

export default function RealEstateAutoComplete({
  projectId,
  label,
  value,
  onChange,
  ...props
}) {
  const handleChange = (_, _value) => {
    if (_value && _value._id) {
      onChange(_value);
    } else {
      onChange(null);
    }
  };

  const handleQuery = useCallback(
    (search) => {
      const variables = {
        projectId,
        search,
      };

      return new Promise((resolve, reject) => {
        debouncedGetRealEstates(
          variables,
          ({ getRealEstates }) => {
            resolve(getRealEstates.realEstates);
          },
          reject
        );
      });
    },
    [projectId]
  );

  const getOptionLabel = (option) => {
    if (!option) return '';
    return `${option.name} - ${formatCnpj(option.cnpj)}`;
  };

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option) => `${option.name} - ${option.cnpj}`,
  });

  const getOptionSelected = (option, _value) => option?._id === _value?._id;

  return (
    <BaseAutoComplete
      {...props}
      label={label}
      value={value}
      onChange={handleChange}
      onQuery={handleQuery}
      getOptionLabel={getOptionLabel}
      filterOptions={filterOptions}
      getOptionSelected={getOptionSelected}
    />
  );
}

RealEstateAutoComplete.propTypes = {
  projectId: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired,
};

RealEstateAutoComplete.defaultProps = {
  label: '',
  value: null,
};
