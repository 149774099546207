import useQuery from 'hooks/useQuery';
import AlertModule from 'modules/AlertModule';
import { useInvitesListContext } from 'providers/ListProviders/InvitesListProvider.js';
import CANCEL_INVITE_MUTATION from 'queries/cancelInviteMutation';
import RESEND_INVITE_MUTATION from 'queries/resendInviteMutation';
import errorHandler from '../utils/errorHandler';

export default function useActions() {
  const { updateList } = useInvitesListContext();
  const [executeCancelInvite] = useQuery(CANCEL_INVITE_MUTATION);
  const [executeResendInvite] = useQuery(RESEND_INVITE_MUTATION);

  const handleResendInvite = (invite) => {
    AlertModule.wait();
    executeResendInvite({ id: invite._id })
      .then(() => {
        AlertModule.success('Convite reenviado com sucesso!');
        updateList();
      })
      .catch((error) => {
        errorHandler(error, 'resendInvite');
      });
  };

  const handleCancelInvite = (invite) => {
    AlertModule.wait();

    executeCancelInvite({ id: invite._id })
      .then(() => {
        AlertModule.success('Convite cancelado com sucesso!');
        updateList();
      })
      .catch((error) => {
        errorHandler(error, 'cancelInvite');
      });
  };

  return {
    cancel: handleCancelInvite,
    resend: handleResendInvite,
  };
}
