export default function changeReservationStatusQuery(reservationId, status) {
  return `
    mutation {
      changeReservationStatus(
        id: "${reservationId}"
        status: "${status}"
      ) {
        _id
      }
    }
  `
}