import React, { useEffect, useState } from 'react';
import { Box, Button, DialogActions, Tab, Tabs } from '@material-ui/core';
import PropTypes from 'prop-types';

import BaseDialog from 'components/Dialogs/BaseDialog';

import useThemeClasses from 'theme/useThemeClasses';

import GeneralTab from './components/GeneralTab';
import MeasurementTab from './components/MeasurementTab';
import useGetProperty from './hooks/useGetProperty';

function ReadPropertyDialog({ open, propertyId, onClose, onEdit, onReserve }) {
  const { property, isLoading } = useGetProperty(propertyId);

  const [step, setStep] = useState(0);

  const themeClasses = useThemeClasses;
  const buttonClasses = themeClasses.button();

  const TabContent = step === 0 ? GeneralTab : MeasurementTab;

  useEffect(() => {
    setStep(0);
  }, []);

  return (
    <BaseDialog
      open={open}
      title="Imóvel"
      maxWidth="sm"
      onClose={onClose}
      loading={isLoading}
    >
      {property ? (
        <>
          <Box mb={5}>
            <Tabs
              variant="fullWidth"
              value={step}
              indicatorColor="primary"
              textColor="primary"
              centered
              onChange={(e, value) => setStep(value)}
            >
              <Tab label="Geral" style={{ marginRight: 24 }} />
              <Tab label="Medidas" />
            </Tabs>
          </Box>

          <TabContent property={property} />

          <DialogActions>
            {property.status === 'available' ? (
              <Button
                variant="contained"
                name="make-reservation-button"
                color={onEdit ? 'secondary' : 'primary'}
                onClick={onReserve}
              >
                Reservar
              </Button>
            ) : null}

            <Box hidden={!onEdit}>
              <Button
                className={buttonClasses.buttonWithIcon}
                variant="contained"
                name="edit-property-button"
                color="primary"
                onClick={onEdit}
              >
                Editar
              </Button>
            </Box>
          </DialogActions>
        </>
      ) : null}
    </BaseDialog>
  );
}

ReadPropertyDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  propertyId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onEdit: PropTypes.func,
  onReserve: PropTypes.func,
  discount: PropTypes.shape({
    cash: PropTypes.number,
    monthly: PropTypes.number,
    annually: PropTypes.number,
  }).isRequired,
};

ReadPropertyDialog.defaultProps = {
  propertyId: null,
  onEdit: null,
  onReserve: null,
};

export default ReadPropertyDialog;
