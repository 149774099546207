import optionalStringQueryField from "./utils/optionalStringQueryField";

// eslint-disable-next-line import/no-anonymous-default-export
export default function resendVerificationCodeQuery({ username, environment, projectSlug }) {
  return `
      mutation {
        resendVerificationCode(
            username: "${String(username).toLowerCase()}"
            environment: "${environment}"
            ${optionalStringQueryField('projectSlug', projectSlug)}
        )
      }
    `
}