import { TextField } from '@material-ui/core'
import React from 'react'
import validatePassword from 'utils/validatePassword'
import PasswordValidateTooltip from './PasswordValidateTooltip'

export default function PasswordTextField(props) {
    const handleError = () => {
        if (props.error) return true
        if (!props.value) return false

        const validate = validatePassword(props.value)

        return !Object.keys(validate).reduce((object, value) => object && validate[value], true)
    }

    return (
        <PasswordValidateTooltip value={props.value}>
            <TextField {...props} type="password" error={handleError()} />
        </PasswordValidateTooltip>
    )
}