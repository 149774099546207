import React from 'react';

export default function DateFilterIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 16 17"
    >
      <rect
        width="12.005"
        height="12.005"
        x="1.997"
        y="2.497"
        stroke="#3D4657"
        strokeLinecap="round"
        strokeLinejoin="round"
        rx="2"
      />
      <path
        stroke="#3D4657"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M14.002 5.832H1.997M11.534 8.166a.033.033 0 11-.033-.033M11.501 8.133c.019 0 .034.015.034.034M9.2 8.166a.033.033 0 11-.034-.033M9.166 8.133c.018 0 .033.015.033.034M6.865 8.166a.033.033 0 11-.033-.033M6.832 8.133c.018 0 .033.015.033.034M4.53 10.168a.033.033 0 11-.032-.034M4.497 10.134c.019 0 .034.015.034.034M6.865 10.168a.033.033 0 11-.033-.034M6.832 10.134c.018 0 .033.015.033.034M9.2 10.168a.033.033 0 11-.034-.034M9.166 10.134c.018 0 .033.015.033.034M11.534 10.168a.033.033 0 11-.033-.034M11.501 10.134c.019 0 .034.015.034.034M11.534 12.168a.033.033 0 11-.033-.033M11.501 12.135c.019 0 .034.015.034.034M9.2 12.168a.033.033 0 11-.034-.033M9.166 12.135c.018 0 .033.015.033.034M6.865 12.168a.033.033 0 11-.033-.033M6.832 12.135c.018 0 .033.015.033.034M4.53 12.168a.033.033 0 11-.032-.033M4.497 12.135c.019 0 .034.015.034.034"
      />
    </svg>
  );
}
