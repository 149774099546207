export default [
  {
    value: 'ticket',
    label: 'Boleto',
  },
  {
    value: 'pix',
    label: 'PIX',
  },
  {
    value: 'bank_transfer',
    label: 'Transferência bancária',
  },
  {
    value: 'debit_card',
    label: 'Cartão de débito',
  },
  {
    value: 'credit_card',
    label: 'Cartão de crédito',
  },
];
