import React from 'react';
import { Box, Typography } from '@material-ui/core';

import formatPrice from 'utils/formatPrice';
import formatDate from 'utils/formatDate';

import PAYMENT_METHODS from '@constants/salesContract/invoice/paymentMethods';

import StatusLabel from 'components/StatusLabel/SalesContractInvoiceStatusLabel';
import TooltipInvoiceValues from '../components/TooltipInvoiceValues';
import EditButton from '../components/EditButton';

export default (onSelect) => {
  const columns = [
    {
      title: 'Detalhes',
      field: 'description',
      render: (rowData) => rowData.description,
    },
    {
      title: 'Contrato',
      field: 'numberId',
      render: (rowData) => `#${rowData.contractNumberId}`,
    },
    {
      title: 'Situação',
      field: 'status',
      render: (rowData) => <StatusLabel status={rowData.status} />,
    },

    {
      title: 'Vencimento',
      field: 'dueDate',
      render: (rowData) => formatDate(rowData.dueDate),
    },

    {
      title: 'Valor',
      field: 'value',
      render: (rowData) => (
        <Box>
          <Typography component="span">{formatPrice(rowData.value)}</Typography>

          <TooltipInvoiceValues salesContractInvoice={rowData} />
        </Box>
      ),
    },

    {
      title: 'Pagamento',
      field: 'paidAt',
      render: (rowData) =>
        PAYMENT_METHODS.find((item) => item.value === rowData.paymentMethod)
          ?.label,
    },
    {
      title: '',
      field: '_actions',
      align: 'center',
      fit: true,
      render: (rowData) => <EditButton invoice={rowData} onSelect={onSelect} />,
    },
  ];

  return columns;
};
